var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-fixed" }, [
      _c(
        "div",
        { staticClass: "title-top-box" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("编辑排号规则")]),
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleSaveInfo },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "detail-box mT16" }, [
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "tips" }, [
            _c("div", { staticClass: "tips-top flex align-items" }, [
              _c("span", { staticClass: "mL10 tips-title" }, [
                _vm._v(_vm._s(_vm.getButtonText(_vm.event.type))),
              ]),
              _c("span", { staticClass: "buttonStyle" }, [
                _vm._v(_vm._s(_vm.event.name) + " "),
                _c("span", {
                  staticClass: "qiqi",
                  domProps: { textContent: _vm._s(_vm.event.round_name) },
                }),
              ]),
            ]),
            _c("div", { staticClass: "tips-bottom" }, [
              _vm.event.event_status === 0
                ? _c("span", { staticClass: "status" }, [_vm._v("未开始")])
                : _vm._e(),
              _vm.event.event_status === 1
                ? _c("span", { staticClass: "status" }, [_vm._v("进行中")])
                : _vm._e(),
              _vm.event.event_status === 2
                ? _c("span", { staticClass: "status" }, [_vm._v("已结束")])
                : _vm._e(),
              _vm.event.round === 1
                ? _c("span", { staticClass: "mL10 tips-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.event.round_start_time * 1000
                          )
                        ) +
                        " ~ " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.event.round_end_time * 1000)
                        )
                    ),
                  ])
                : _c("span", { staticClass: "mL10 tips-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.event.start_time * 1000)
                        ) +
                        " ~ " +
                        _vm._s(_vm._f("dateFormat")(_vm.event.end_time * 1000))
                    ),
                  ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "from-box" },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "150px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "排号名称：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            "show-word-limit": "",
                            maxlength: "20",
                            placeholder: "将显示在摇号大屏上",
                          },
                          model: {
                            value: _vm.rand.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.rand, "name", $$v)
                            },
                            expression: "rand.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "排号方式：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.type_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_code", $$v)
                              },
                              expression: "rand.type_code",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$adminList.user_code_name))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.rand.type_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_code", $$v)
                              },
                              expression: "rand.type_code",
                            },
                          },
                          [_vm._v("签到顺序")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 3 },
                            model: {
                              value: _vm.rand.type_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_code", $$v)
                              },
                              expression: "rand.type_code",
                            },
                          },
                          [_vm._v("下单顺序")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 4 },
                            model: {
                              value: _vm.rand.type_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_code", $$v)
                              },
                              expression: "rand.type_code",
                            },
                          },
                          [_vm._v("房源号")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "轮次人数：" } }, [
                  _c(
                    "div",
                    { staticClass: "number" },
                    [
                      _c("span", [_vm._v("每轮")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.rand.rand_count,
                            callback: function ($$v) {
                              _vm.$set(_vm.rand, "rand_count", $$v)
                            },
                            expression: "rand.rand_count",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "1", value: 1 } }),
                          _c("el-option", { attrs: { label: "5", value: 5 } }),
                          _c("el-option", {
                            attrs: { label: "10", value: 10 },
                          }),
                          _c("el-option", {
                            attrs: { label: "20", value: 20 },
                          }),
                        ],
                        1
                      ),
                      _c("span", [_vm._v("人")]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "到号显示：" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-name",
                        attrs: { span: 24 },
                      },
                      [
                        _vm._v("显示元素 "),
                        _c("span", { staticClass: "tips" }, [
                          _vm._v("建议不超过3个元素"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.rand.show_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rand, "show_name", $$v)
                                      },
                                      expression: "rand.show_name",
                                    },
                                  },
                                  [_vm._v(" 姓名 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.rand.show_tel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rand, "show_tel", $$v)
                                      },
                                      expression: "rand.show_tel",
                                    },
                                  },
                                  [_vm._v(" 登录手机号 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.rand.show_audit_order,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.rand,
                                          "show_audit_order",
                                          $$v
                                        )
                                      },
                                      expression: "rand.show_audit_order",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$adminList.user_code_name) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.rand.show_card,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rand, "show_card", $$v)
                                      },
                                      expression: "rand.show_card",
                                    },
                                  },
                                  [_vm._v(" 身份证号 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                    },
                                    model: {
                                      value: _vm.rand.show_room,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.rand, "show_room", $$v)
                                      },
                                      expression: "rand.show_room",
                                    },
                                  },
                                  [_vm._v(" 房号 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }