/**
 * @Author: boyyang
 * @Date: 2022-02-23 10:54:04
 * @LastEditTime: 2022-02-23 11:19:26
 * @LastEditors: boyyang
 * @Description:
 * @FilePath: \yun-xk-admin\src\components\carMap\utils\options.js
 */
var options = {
  appName: '测试停车位',
  key: 'c5f9a6bcf1a10cdc832cb5a297135789',
  mapID: '1481538249635336193',
  ThemeName: '1484356865279909890',
  container: null,
  level: 0,
  viewMode: fengmap.FMViewMode.MODE_2D
};
export { options };