import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { selAllRule, setAllRule } from '@/api/lottery';
import { adminParameter } from '@/api/public';
import utils from '@/utils/utils';
export default {
  name: 'BatchRule',
  data: function data() {
    return {
      // 基本参数
      event: {},
      // 页面数据
      rand: {},
      // 单选框禁用
      disabled: false,
      // 全局接口 项目配置
      list: {},
      // 是否影响预设
      user_bool: false
    };
  },
  created: function created() {
    var _this = this;

    this.dataInfo(); //  项目配置

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this.list = list;

        if (list.sign_in === 1) {
          if (list.sign_type === 2) {
            _this.all_is_show = true;
          }
        }
      }
    });
  },
  methods: {
    // 选择轮
    handleRound: function handleRound(type) {
      if (type) {
        this.disabled = false;
      } else {
        this.disabled = true;
        this.rand.type_group_code = 0;
      }
    },
    // 选中按序排号
    handleNumber: function handleNumber() {
      this.rand.type_group = 2;
    },
    // 向下移动
    handleBottom: function handleBottom(index) {
      var _this$rand$level_sort;

      if (this.rand.level_sort.length - 1 === index) {
        this.$message({
          type: 'error',
          message: '已经是最后一个了！不能向下移动了'
        });
        return;
      }

      (_this$rand$level_sort = this.rand.level_sort).splice.apply(_this$rand$level_sort, [index + 1, 1].concat(_toConsumableArray(this.rand.level_sort.splice(index, 1, this.rand.level_sort[index + 1]))));
    },
    // 向上移动
    handleTop: function handleTop(index) {
      var _this$rand$level_sort2;

      if (index === 0) {
        this.$message({
          type: 'error',
          message: '已经是第一个了！不能往上移动了'
        });
        return;
      }

      (_this$rand$level_sort2 = this.rand.level_sort).splice.apply(_this$rand$level_sort2, [index - 1, 1].concat(_toConsumableArray(this.rand.level_sort.splice(index, 1, this.rand.level_sort[index - 1]))));
    },
    // 提交报错 修改摇号
    handleSaveInfo: function handleSaveInfo() {
      var _this2 = this;

      // 修改排序
      this.rand.level_sort.forEach(function (item, index) {
        item.sort = index + 1;
      }); // 必须选中一个摇号元素

      if (this.rand.show_name === 0 && this.rand.show_tel === 0 && this.rand.show_audit_order === 0 && this.rand.show_card === 0 && this.rand.show_other_remark === 0) {
        this.$message({
          type: 'warning',
          message: '除顺序号至少选择1个显示元素'
        });
        utils.jumpFun('jumpPeon');
        return;
      }

      var rand = _objectSpread(_objectSpread({}, this.rand), {}, {
        rand_count: Number(this.rand.rand_count)
      });

      if (JSON.parse(this.rands).type_group !== this.rand.type_group || JSON.parse(this.rands).type_rand_user !== this.rand.type_rand_user) {
        this.$confirm('此操作可能会影响预设, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          // 提交数据
          setAllRule({
            rand: rand
          }).then(function (res) {
            if (res.status === 200) {
              _this2.$message({
                type: 'success',
                message: res.data.msg
              });
            }
          });
        }).catch(function () {});
      } else {
        // 提交数据
        setAllRule({
          rand: rand
        }).then(function (res) {
          if (res.status === 200) {
            _this2.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    // 获取 摇号规则
    dataInfo: function dataInfo() {
      var _this3 = this;

      selAllRule({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              event = _res$data.event,
              rand = _res$data.rand,
              user_count = _res$data.user_count,
              user_bool = _res$data.user_bool;
          _this3.event = event;

          if (rand.type_round_code) {
            _this3.disabled = false;
          } else {
            _this3.disabled = true;
            _this3.rand.type_group_code = 0;
          }

          _this3.rands = JSON.stringify(rand);
          _this3.user_count = user_count;
          _this3.user_bool = user_bool;

          if (_this3.all_is_show) {
            rand.type_rand_user = 1;
          }

          if (!rand.rand_count) rand.rand_count = 5;
          _this3.rand = rand;
        }
      });
    }
  }
};