var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    _c(
      "div",
      { ref: "apptop", staticClass: "page-header" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-form",
              { attrs: { inline: true } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "版本号：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "请选择" },
                            on: { change: _vm.changeRound },
                            model: {
                              value: _vm.params.edition,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "edition", $$v)
                              },
                              expression: "params.edition",
                            },
                          },
                          _vm._l(_vm.config.edition, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.num, value: item.num },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "轮次号：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "请选择" },
                            model: {
                              value: _vm.params.round,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "round", $$v)
                              },
                              expression: "params.round",
                            },
                          },
                          _vm._l(_vm.round_list, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.num, value: item.num },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex align-items operate" },
          [
            _c("el-input", {
              attrs: {
                prop: "text",
                autocomplete: "on",
                size: "small",
                placeholder: "姓名\\手机\\" + _vm.list.user_code_name,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getData(true)
                },
              },
              model: {
                value: _vm.params.search,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.params,
                    "search",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "params.search",
              },
            }),
            _c(
              "div",
              { staticClass: "flex-shrink mL10 flex align-items" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "greens-btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.getData(true)
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnHollowReset",
                    attrs: { size: "small" },
                    on: { click: _vm.resetForm },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "condition-filter mT10" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "font-style" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "entry/export"],
                  expression: "['admin','entry/export']",
                },
              ],
              staticClass: "btnHollowGreen",
              attrs: { size: "small" },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出数据")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "table" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: {
                  height: _vm.tableHeight,
                  data: _vm.config.list,
                  "element-loading-background": "rgba(0, 0, 0, 0.5)",
                  "element-loading-text": "数据正在加载中",
                  "element-loading-spinner": "el-icon-loading",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { fixed: "", type: "index", label: "序号" },
                }),
                _c("el-table-column", {
                  attrs: { label: "轮次-序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.rand_round) +
                                "轮-第" +
                                _vm._s(scope.row.code) +
                                "号"
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "user_name", label: "姓名" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "user_card", label: "身份证" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "tel", label: "登录手机号" },
                }),
                _c("el-table-column", {
                  attrs: { label: "状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.is_waste == 1
                                    ? "废号"
                                    : scope.row.is_repeat > 0
                                    ? "重复号码"
                                    : "有效号码"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "created_at", label: "摇出时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.doTimeToDate(scope.row.created_at * 1000)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c("el-pagination", {
          attrs: {
            "page-sizes": _vm.sizes,
            "page-size": _vm.params.limit,
            layout: _vm.layouts,
            total: _vm.config.count,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }