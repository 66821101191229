import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import getters from "@/store/getters";
import { mapGetters } from "vuex";
export default {
  name: 'BaseTable',

  /**
   * props data
   * @param {object} data
   */
  props: {
    data: {
      type: Array,
      default: function _default() {}
    },
    basetype: {
      //选房套打还是认筹套打 还是 认筹  1,//选房 2。认筹
      type: Number,
      default: 1
    }
  },
  computed: _objectSpread({}, mapGetters(['adminParameter'])),
  methods: {
    /**
     * copyText 复制文字
     * @param {string}  text
     */
    copyText: function copyText(items) {
      var text;

      if (this.basetype === 1) {
        text = this.adminParameter.list.sign_up_type === 1 ? items.show_name : items.name;
      } else {
        text = this.adminParameter.audit.reg_sign_type === 0 ? items.show_name : items.name;
      }

      if (!text) {
        this.$message.error('复制失败');
        return;
      } // text是复制文本
      // 创建input元素


      var el = document.createElement('input'); // 给input元素赋值需要复制的文本

      el.setAttribute('value', text); // 将input元素插入页面

      document.body.appendChild(el); // 选中input元素的文本

      el.select(); // 复制内容到剪贴板

      document.execCommand('copy'); // 删除input元素

      document.body.removeChild(el);
      this.$message.success('复制成功');
    }
  }
};