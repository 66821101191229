import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { roomRelation } from '@/api/roomCar';
export default {
  data: function data() {
    return {
      tableData: [],
      cellIndex: null,
      // 两层合并的数据
      isCell: false
    };
  },
  created: function created() {
    this.getTable();
  },
  methods: {
    getTable: function getTable() {
      var _this = this;

      roomRelation().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;

          _this.dealTable(list.event);
        }
      });
    },
    dealTable: function dealTable(tableData) {
      var getDate = []; // 存储新表格数据

      var firstIndex = 0; // 储存需要设置hover背景色的单元行(两层)

      tableData.forEach(function (item, index) {
        if (item.rounds && item.rounds.length) {
          item.rounds.forEach(function (subV, i, typeData) {
            var obj = {
              id: item.id,
              name: item.name,
              type: subV.name,
              index: getDate.length,
              num: index + 1,
              end_time: subV.end_time,
              start_time: subV.start_time
            };

            if (i === 0) {
              obj.typeIndex = typeData.length;
              firstIndex = obj.index;
            } else {
              obj.firstIndex = firstIndex; // 存储第一行索引
            }

            getDate.push(obj);
          });
        }
      });
      console.log(getDate);
      this.tableData = getDate;
    },
    // 表格合并方法(两层数据)
    arraySpanMethod: function arraySpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0 || columnIndex === 1) {
        if (row.typeIndex) {
          // 如果有值,说明需要合并
          return [row.typeIndex, 1];
        } else return [0, 0];
      }
    },
    // 单元格移入事件
    handleMouse: function handleMouse(row, column, cell, event) {
      if (!row.typeIndex) {
        // 不符合合并行的条件手动加class
        // 只能给第一行加才有效, 把之前保存的第一行的索引存起来
        this.cellIndex = row.firstIndex;
        this.isCell = true;
      }
    },
    // 单元格移除事件
    handleMouseLeve: function handleMouseLeve() {
      this.isCell = false;
    },
    // 设置行样式
    setCellClass: function setCellClass(_ref2) {
      var row = _ref2.row,
          column = _ref2.column,
          rowIndex = _ref2.rowIndex,
          columnIndex = _ref2.columnIndex;

      // 给当前鼠标移入的合并行第一行加上样式名
      if (row.index === this.cellIndex) {
        return 'cellClass';
      }
    }
  }
};