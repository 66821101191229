/**
 * @author yang
 * @time 2021/4/16
 * @param {fn: func}
 * @param {delay: time}
 * @param {immediate: boolean}
 */
export function debounce(fn, delay, immediate) {
  var timer;
  return function () {
    var _self = this;

    var args = arguments;
    if (timer) clearTimeout(timer);

    if (immediate) {
      var callNow = !timer;
      timer = setTimeout(function () {
        timer = null;
      }, delay);
      if (callNow) fn.apply(_self, args);
    } else {
      timer = setTimeout(function () {
        fn.apply;
      }, delay);
    }
  };
}