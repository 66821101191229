// 摇号设置
import request from '@/utils/request'; // 获取摇号设置 样式

var getRoundStyle = function getRoundStyle(params) {
  return request({
    url: '/EventRand/randStyle',
    method: 'get',
    params: params
  });
}; // 设置摇号 样式


var setRoundStyle = function setRoundStyle(data) {
  return request({
    url: '/EventRand/setRandStyle',
    method: 'post',
    data: data
  });
}; // 设置摇号 副屏样式


var setSubStyle = function setSubStyle(data) {
  return request({
    url: '/EventRand/setSubStyle',
    method: 'post',
    data: data
  });
}; // 副屏数据


var getRound = function getRound(data) {
  return request({
    url: '/EventRand/getRound',
    method: 'post',
    data: data
  });
};

var getRowStyle = function getRowStyle(params) {
  return request({
    url: '/rowNumber/randStyle',
    method: 'get',
    params: params
  });
};

var setRowStyle = function setRowStyle(data) {
  return request({
    url: '/rowNumber/setRandStyle',
    method: 'post',
    data: data
  });
}; // 开始摇号


var beginRound = function beginRound(params) {
  return request({
    url: '/EventRand/randLedStart',
    method: 'get',
    params: params
  });
}; // 结束摇号


var endRound = function endRound(params) {
  return request({
    url: '/EventRand/randLedResult',
    method: 'get',
    params: params
  });
}; // 获取公示大屏样式


var getLedStyle = function getLedStyle(data) {
  return request({
    url: '/EventRand/getLedStyle',
    method: 'post',
    data: data
  });
}; // 设置公示大屏样式


var setLedStyle = function setLedStyle(data) {
  return request({
    url: '/EventRand/setLedStyle',
    method: 'post',
    data: data
  });
}; // 获取公示大屏数据


var getLedData = function getLedData(data) {
  return request({
    url: '/EventRand/getLedData',
    method: 'post',
    data: data
  });
};

export { getRoundStyle, setRoundStyle, setSubStyle, getRowStyle, getRound, setRowStyle, beginRound, endRound, getLedStyle, setLedStyle, getLedData };