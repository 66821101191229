import _toConsumableArray from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getProject, getToken } from '@/utils/auth';
import { carMapList, carMapSort, mapDel } from '@/api/roomCar';
export default {
  data: function data() {
    return {
      // 图片上传需要数据
      action: '',
      header: {
        token: ''
      },
      fileList: [],
      // 当前在哪一个分页（配置，排序，添加，修改）
      activeName: 'config',
      // 添加数据默认值
      addCart: {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
        radius: 12,
        rotationZ: 0,
        round: true,
        fontSize: 14,
        sort: 'left',
        isFont: false,
        fontColor: '#FFFFFF',
        ids: [],
        batch: 0,
        background_color: 1,
        xType: 'all',
        yType: 'all'
      },
      // 修改数据默认值
      setCart: {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
        radius: 12,
        rotationZ: 0,
        round: true,
        fontSize: 14,
        sort: 'left',
        isFont: false,
        fontColor: '#FFFFFF',
        background_color: 1,
        align: '',
        alignTarget: ''
      },
      addData: [],
      // 选中的车位房源数据
      sceneChildrens: [],
      // 添加模式中展开
      isAddUp: true,
      // 是否按下ctrl
      ctrlDown: false,
      // 排序时间
      sortData: [],
      // 撤回数据
      recallDatas: []
    };
  },
  created: function created() {
    this.doCarMapList();
  },
  mounted: function mounted() {
    this.action = this.host + '/upload/mapImg';
    this.header.token = getToken();
    var token = getToken();
    var project = JSON.parse(getProject());
    this.header = {
      token: token,
      project: project.project_id
    }; // 监听ctrl事件，车位选中需要按住ctrl才能进行累加数据

    var that = this;

    document.onkeydown = function (e) {
      if (e.keyCode === 17) that.ctrlDown = true; // ctrl加z  撤回事件

      if (that.ctrlDown && e.keyCode === 90) {
        that.doSetRecallDatas('recall');
      }
    };

    document.onkeyup = function (e) {
      if (e.keyCode === 17) that.ctrlDown = false;
    };
  },
  methods: {
    // 获取平面图列表数据
    doCarMapList: function doCarMapList() {
      var _this = this;

      carMapList({}, this.$parent.$parent.$parent.cate).then(function (res) {
        _this.sortData = res.data.info || [];
      });
    },
    // 文件上传前
    uploadBefore: function uploadBefore(e) {
      this.loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      /*let that = this;
                   return new Promise(function (resolve, reject) {
                       let img = new Image()
                       let fileBlobName = null
                       if (window.createObjectURL !== undefined) {
                           fileBlobName = window.createObjectURL(e)
                       } else if (window.URL !== undefined) {
                           fileBlobName = window.URL.createObjectURL(e)
                       } else if (window.webkitURL !== undefined) {
                           fileBlobName = window.webkitURL.createObjectURL(e)
                       }
                       img.src = fileBlobName
                       img.onload = () => {
                           if(img.width > 4000 || img.height > 4000){
                               that.$message({
                                   type: 'error',
                                   message: '图片宽高尺寸不能大于4000！'
                               })
                               reject(img)
                           }else{
                               that.loading = that.$loading({
                                   lock: true,
                                   text: '上传中...',
                                   spinner: 'el-icon-loading',
                                   background: 'rgba(0, 0, 0, 0.7)'
                               })
                               that.fileList = [e]
                               resolve()
                           }
                       }
                   })*/
    },
    // 上传失败
    uploadFail: function uploadFail(e) {
      this.$message({
        type: 'error',
        message: '图片上传失败！'
      });
      this.loading.close();
      this.fileList = [];
    },
    // 上传成功
    uploadSucess: function uploadSucess(e) {
      this.fileList = [];

      if (e.Data && e.Data.url) {
        this.$emit('onUpload', e.Data.url);
      } else {
        this.$message({
          type: 'error',
          message: '图片上传失败！'
        });
      }

      this.loading.close();
    },

    /**
     * 操作事件
     * @param key
     * @param point
     */
    onOper: function onOper(key, point) {
      var _this2 = this;

      // 重置
      if (key === 'addReset') {
        this.addCart = {
          x: 0,
          y: 0,
          width: 50,
          height: 50,
          radius: 12,
          round: true,
          rotationZ: 0,
          fontSize: 18,
          sort: 'left',
          isFont: false,
          fontColor: '#FFFFFF',
          ids: [],
          background_color: 1,
          xType: 'all',
          yType: 'all'
        };
      } // 确定添加
      else if (key === 'addSubmit') {
        if (this.addCart.ids.length || this.addCart.batch) {
          if (this.addCart.round && !this.addCart.radius) {
            this.$message({
              type: 'error',
              message: '请正确填写半径！'
            });
            return;
          } else if (!this.addCart.round && (!this.addCart.width || !this.addCart.height)) {
            this.$message({
              type: 'error',
              message: '请正确填写宽度和高度！'
            });
            return;
          }

          this.$confirm('请在平面图上点击需要生成' + (this.$parent.$parent.$parent.cate === 1 ? '房源' : '车位') + '号的位置，系统将自动生成！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(function () {
            _this2.$parent.$parent.$parent.isCkickAddData = true;
          });
        }
      } // 生成数据
      else if (key === 'addData') {
        var data = [],
            addCart = this.addCart,
            ids = [],
            list = addCart.ids,
            XBr = 0;

        if (addCart.batch) {
          list = this.$parent.$parent.$parent.threeCartList.splice(0, addCart.batch);
        } // if (addCart.ids.indexOf("all") >= 0) {
        //     list = this.$parent.$parent.$parent.threeCartList;
        // }


        var x = point.x,
            y = point.y;

        for (var i = 0; i < list.length; i++) {
          var item = [];

          if (addCart.batch) {
            item = [list[i].id, list[i].room];
          } else {
            item = this.addCart.ids[i].split('_room_');
          } //   计算数据块位置算法，已写好，不用动！！！！！！没看懂之前不要动


          if (i > 0) {
            // 计算每个方向的车位位置
            if (addCart.sort === 'left') {
              x += addCart.x + (addCart.round ? addCart.radius * 2 : addCart.width);

              if (addCart.yType === 'one') {
                y -= addCart.y;
              }
            } else if (addCart.sort === 'right') {
              x -= addCart.x + (addCart.round ? addCart.radius * 2 : addCart.width);

              if (addCart.yType === 'one') {
                y -= addCart.y;
              }
            } else if (addCart.sort === 'top') {
              if (addCart.xType === 'one') {
                x += addCart.x;
              }

              y -= addCart.y + (addCart.round ? addCart.radius * 2 : addCart.height);
            } else if (addCart.sort === 'bottom') {
              if (addCart.xType === 'one') {
                x += addCart.x;
              }

              y += addCart.y + (addCart.round ? addCart.radius * 2 : addCart.height);
            }
          } // 如果x和y超出平面图就重置位置


          if (x >= this.$parent.$parent.$parent.imgWidth / 2 - (addCart.round ? addCart.radius : addCart.width / 2)) {
            x = -(this.$parent.$parent.$parent.imgWidth / 2 - (addCart.round ? addCart.radius : addCart.width / 2));
            y -= addCart.round ? addCart.radius * 2 : addCart.height;

            if (addCart.yType === 'all' && (addCart.sort === 'left' || addCart.sort === 'right')) {
              y -= addCart.y;
            }
          } else if (x <= -(this.$parent.$parent.$parent.imgWidth / 2 - (addCart.round ? addCart.radius : addCart.width / 2))) {
            x = this.$parent.$parent.$parent.imgWidth / 2 - (addCart.round ? addCart.radius : addCart.width / 2);
            y -= addCart.round ? addCart.radius * 2 : addCart.height;

            if (addCart.yType === 'all' && (addCart.sort === 'left' || addCart.sort === 'right')) {
              y -= addCart.y;
            }
          }

          if (y >= this.$parent.$parent.$parent.imgHeight / 2 - (addCart.round ? addCart.radius : addCart.height / 2)) {
            y = -(this.$parent.$parent.$parent.imgHeight / 2 - (addCart.round ? addCart.radius : addCart.height / 2));
            x += addCart.round ? addCart.radius * 2 : addCart.width;

            if (addCart.yType === 'all' && (addCart.sort === 'top' || addCart.sort === 'bottom')) {
              x += addCart.x;
            }
          } else if (y <= -(this.$parent.$parent.$parent.imgHeight / 2 - (addCart.round ? addCart.radius : addCart.height / 2))) {
            y = this.$parent.$parent.$parent.imgHeight / 2 - (addCart.round ? addCart.radius : addCart.height / 2);
            x += addCart.round ? addCart.radius * 2 : addCart.width;

            if (addCart.yType === 'all' && (addCart.sort === 'top' || addCart.sort === 'bottom')) {
              x += addCart.x;
            }
          }

          ids.push(+item[0]);
          data.push({
            x: x,
            y: y,
            width: addCart.width,
            height: addCart.height,
            radius: addCart.radius,
            rotationZ: addCart.rotationZ < 0 ? 360 + addCart.rotationZ : addCart.rotationZ,
            round: addCart.round,
            id: +item[0],
            name: item[1],
            fontSize: addCart.fontSize,
            isFont: addCart.isFont,
            fontColor: addCart.fontColor,
            background_color: addCart.background_color
          });
        }

        this.$emit('doCkickAddData', {
          key: 'addCart',
          data: data
        });
        var threeCartList = JSON.parse(JSON.stringify(this.$parent.$parent.$parent.threeCartList));
        threeCartList = threeCartList.filter(function (item) {
          return !ids.includes(item.id);
        });
        this.$parent.$parent.$parent.threeCartList = threeCartList;
        this.addCart.ids = [];
      } // 获取修改车位数据
      else if (key === 'setData') {
        if (!this.ctrlDown) this.sceneChildrens = []; // 如果是框选数据

        if (point.type === 'selectCar') {
          var _this$sceneChildrens;

          (_this$sceneChildrens = this.sceneChildrens).push.apply(_this$sceneChildrens, _toConsumableArray(point.selectCarIds));

          if (this.sceneChildrens.length === point.selectCarIds.length) {
            this.onSetSelectCar('selectCar');
          }
        } // 点击车位获取数据
        else if (point.id) {
          this.sceneChildrens.indexOf(point.id) < 0 && this.sceneChildrens.push(point.id);
          this.sceneChildrens.indexOf(point.id) <= 0 && this.onSetSelectCar('selectCar');
        } // 删除车位
        else {
          this.sceneChildrens = [];
          this.setCart = point;
        }
      } // 删除
      else if (key === 'del') {
        this.$parent.$parent.$parent.isCarClick = false;
        this.$confirm('确定要删除 ' + (this.$parent.$parent.$parent.cate === 1 ? '房源：' : '车位：') + this.setCart.name + (this.sceneChildrens.length > 1 ? ' 等' + (this.sceneChildrens.length - 1) + '个' + (this.$parent.$parent.$parent.cate === 1 ? '房源' : '车位') : ' ') + '吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(function () {
          if (_this2.sceneChildrens.length === 1) {
            var _this2$$parent$$paren;

            _this2.$emit('doCkickSetData', {
              key: 'delCart',
              data: _this2.setCart
            });

            var cart = _this2.$parent.$parent.$parent.allCartList.filter(function (item) {
              return _this2.setCart.id === item.id;
            });

            (_this2$$parent$$paren = _this2.$parent.$parent.$parent.threeCartList).push.apply(_this2$$parent$$paren, _toConsumableArray(cart));

            _this2.$parent.$parent.$parent.generatedCount--;
          } else {
            var _this2$$parent$$paren2;

            _this2.$emit('doCkickSetData', {
              key: 'dels_id',
              ids: _this2.sceneChildrens
            });

            var _cart = _this2.$parent.$parent.$parent.allCartList.filter(function (item) {
              return _this2.sceneChildrens.indexOf(item.id) >= 0;
            });

            (_this2$$parent$$paren2 = _this2.$parent.$parent.$parent.threeCartList).push.apply(_this2$$parent$$paren2, _toConsumableArray(_cart));

            _this2.$parent.$parent.$parent.generatedCount -= _this2.sceneChildrens.length - 1;
          }

          _this2.sceneChildrens = [];
          _this2.setCart = {
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            radius: 12,
            rotationZ: 0,
            round: true,
            fontSize: 18,
            sort: 'left',
            isFont: false,
            fontColor: '#FFFFFF',
            background_color: 1
          }; // this.$parent.$parent.$parent.generatedCount-=(this.sceneChildrens.length - 1)
        }).finally(function () {
          _this2.$parent.$parent.$parent.isCarClick = true;
        });
      } // 确定修改
      else if (key === 'setSubmit') {
        // this.$parent.$parent.$parent.isCarClick = false;
        // this.$confirm("确定要修改" + (this.$parent.$parent.$parent.cate === 1 ? '房源： ' : '车位： ') + (this.setCart.name) + (this.sceneChildrens.length > 1 ? " 等" + (this.sceneChildrens.length - 1) + "个" + (this.$parent.$parent.$parent.cate === 1 ? '房源' : '车位') : " ") + " 吗？", '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'info'
        // }).then(() => {
        if (this.setCart.round) {
          if (!this.setCart.radius) {
            this.$message({
              type: 'error',
              message: '请填写半径！'
            });
            return;
          }
        } else {
          if (!this.setCart.width || !this.setCart.height) {
            this.$message({
              type: 'error',
              message: '请填写宽度和高度！'
            });
            return;
          }
        }

        if (this.sceneChildrens.length === 1) {
          this.$emit('doCkickSetData', {
            key: 'setCart',
            data: this.setCart
          });
          this.doSetRecallDatas('set', {
            key: 'setCart',
            data: this.setCart
          });
        } else {
          this.onSetSelectCar('submitSets');
        } // }).finally(()=>{
        //     this.$parent.$parent.$parent.isCarClick = true;
        // })

      }
    },

    /**
     * 车位多选
     * @param key
     */
    onSetSelectCar: function onSetSelectCar(key) {
      var _this3 = this;

      // 点击框选车位
      if (key === 'button') {
        this.$parent.$parent.$parent.selectCar = 'sets';
        this.$parent.$parent.$parent.isCarClick = true;
      } // 选择事件
      else if (key === 'selectCar') {
        if (this.sceneChildrens.indexOf('all') >= 0) {
          var sceneChildrens = [];

          for (var i = 0; i < this.$parent.$parent.$parent.sceneChildrens.length; i++) {
            sceneChildrens.push(this.$parent.$parent.$parent.sceneChildrens[i].Id);
          }

          this.sceneChildrens = sceneChildrens;
        } else if (this.sceneChildrens.indexOf('del') >= 0) {
          this.sceneChildrens = [];
          this.setCart = {
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            radius: 12,
            rotationZ: 0,
            round: true,
            fontSize: 18,
            sort: 'left',
            isFont: false,
            fontColor: '#FFFFFF',
            background_color: 1
          };
        }

        if (this.sceneChildrens.length) {
          for (var _i = 0; _i < this.$parent.$parent.$parent.sceneChildrens.length; _i++) {
            var item = this.$parent.$parent.$parent.sceneChildrens[_i];

            if (this.sceneChildrens[0] === item.Id) {
              this.setCart = {
                x: item.position.x,
                y: item.position.y,
                width: item.geometry.parameters.width,
                height: item.geometry.parameters.height,
                radius: item.geometry.parameters.radius,
                rotationZ: parseInt(item.rotation._z / 0.0175),
                round: !!item.geometry.parameters.radius,
                name: item.Name,
                id: item.Id,
                fontSize: item.fontSize,
                isFont: item.isFont,
                fontColor: item.fontColor,
                background_color: item.background_color,
                align: this.setCart.align,
                alignTarget: ''
              };
              break;
            }
          }
        }
      } // 确定修改
      else if (key === 'submitSets') {
        var data = {
          key: 'sets_children',
          children: [],
          data: []
        };
        var recallDatas = {
          key: 'sets_children',
          children: [],
          data: []
        }; // 如果选择对齐需要使用的位置

        var x, y;

        if (this.setCart.alignTarget || this.setCart.alignTarget === 0) {
          var _item = this.$parent.$parent.$parent.sceneChildrens.filter(function (item) {
            return item.Id === _this3.setCart.alignTarget;
          });

          if (_item[0]) {
            if (this.setCart.align === 'left') {
              x = _item[0].position.x;
            }

            if (this.setCart.align === 'top') {
              y = _item[0].position.y;
            }
          } else {
            this.$message({
              type: 'error',
              message: '对齐目标数据错误！'
            });
            return;
          }
        } else if (this.setCart.align === 'left') {
          x = this.setCart.x;
        } else if (this.setCart.align === 'top') {
          y = this.setCart.y;
        }

        for (var _i2 = 0; _i2 < this.$parent.$parent.$parent.sceneChildrens.length; _i2++) {
          var _item2 = this.$parent.$parent.$parent.sceneChildrens[_i2];

          if (this.sceneChildrens.indexOf(_item2.Id) >= 0) {
            recallDatas.data.push({
              x: _item2.position.x,
              y: _item2.position.y,
              width: _item2.geometry.parameters.width,
              height: _item2.geometry.parameters.height,
              radius: _item2.geometry.parameters.radius,
              rotationZ: parseInt(_item2.rotation._z / 0.0175),
              round: !!_item2.geometry.parameters.radius,
              name: _item2.Name,
              id: _item2.Id,
              fontSize: _item2.fontSize,
              isFont: _item2.isFont,
              fontColor: _item2.fontColor,
              background_color: _item2.background_color
            });
            recallDatas.children.push(_item2);
            data.children.push(_item2);
            data.data.push({
              x: x || _item2.position.x,
              y: y || _item2.position.y,
              width: this.setCart.width,
              height: this.setCart.height,
              radius: this.setCart.radius,
              rotationZ: this.setCart.rotationZ,
              round: this.setCart.round,
              name: _item2.Name,
              id: _item2.Id,
              fontSize: this.setCart.fontSize,
              isFont: this.setCart.isFont,
              fontColor: this.setCart.fontColor,
              background_color: this.setCart.background_color
            });
          }
        } // this.setCart.align = "";


        this.doSetRecallDatas('set', recallDatas);
        this.$emit('doCkickSetData', data);
      }
    },

    /**
     * 平面图排序
     * @param key
     * @param index
     */
    onSort: function onSort(key, index) {
      var _this4 = this;

      if (key === 'submit') {
        for (var i = 0; i < this.sortData.length; i++) {
          this.sortData[i].sort = i + 1;
        }

        var loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        carMapSort({
          maps: this.sortData
        }, this.$parent.$parent.$parent.cate).then(function (res) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });

          _this4.doCarMapList();
        }).catch(function (res) {
          _this4.$message({
            type: 'error',
            message: res.data.msg
          });
        }).finally(function (res) {
          loading.close();
        });
      } else if (key === 'bottom') {
        var _this$sortData;

        (_this$sortData = this.sortData).splice.apply(_this$sortData, [index + 1, 1].concat(_toConsumableArray(this.sortData.splice(index, 1, this.sortData[index + 1]))));
      } else if (key === 'top') {
        var _this$sortData2;

        (_this$sortData2 = this.sortData).splice.apply(_this$sortData2, [index - 1, 1].concat(_toConsumableArray(this.sortData.splice(index, 1, this.sortData[index - 1]))));
      }
    },
    // 删除平面图
    onDel: function onDel(row) {
      var _this5 = this;

      var params = {
        maps: [{
          id: row.id
        }]
      };
      console.log(params);
      mapDel(params).then(function (res) {
        _this5.$message.success(res.data.msg);

        _this5.doCarMapList();
      });
    },

    /**
     * 撤回数据操作
     * @param key
     * @param data
     */
    doSetRecallDatas: function doSetRecallDatas(key, data) {
      var _this6 = this;

      // 存入撤回数据
      if (key === 'set') {
        if (this.recallDatas.length >= 10) {
          // 删除最开始的一条数据
          this.recallDatas = this.recallDatas.slice(1);
        }

        this.recallDatas.push(data);
      } // 撤回操作
      else if (key === 'recall') {
        // 删除最近的一次数据并撤回
        if (this.recallDatas.length > 0) {
          this.$confirm('确定要撤回至上一次数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'info'
          }).then(function () {
            _this6.$emit('doCkickSetData', _this6.recallDatas[_this6.recallDatas.length - 1]);

            var sliceSetTimeout = setTimeout(function () {
              _this6.recallDatas = _this6.recallDatas.slice(0, -1);
              clearTimeout(sliceSetTimeout);
            }, 100);
          });
        }
      }
    }
  }
};