/**
 * @author WangChao
 * @version 2021年4月25日15:45:35
 */
import request from '@/utils/request'; // import qs from 'qs'

export default {
  // 获取选房端二维码
  getXfd: function getXfd(params) {
    return request({
      url: '/Index/getXfd',
      method: 'get',
      params: params
    });
  },
  // 获取助手端二维码
  getAssistant: function getAssistant(params) {
    return request({
      url: '/Index/getAssistant',
      method: 'get',
      params: params
    });
  },
  // 获取资格审核二维码
  getExamination: function getExamination(params) {
    return request({
      url: '/Index/getAudit',
      method: 'get',
      params: params
    });
  },
  // 获取开盘配置引导
  getOpenConfig: function getOpenConfig(params) {
    return request({
      url: '/Index/index',
      method: 'get',
      params: params
    });
  },
  // 获取待办
  stayDo: function stayDo(params) {
    return request({
      url: '/Index/stayDo',
      method: 'get',
      params: params
    });
  },
  // 获取异常提醒
  abnormal: function abnormal(params) {
    return request({
      url: '/Index/abnormal',
      method: 'get',
      params: params
    });
  },
  // 发送密码
  smsPassord: function smsPassord(params) {
    return request({
      url: '/Index/smsPassord',
      method: 'get',
      params: params
    });
  },
  // 导出模板
  exportTemplate: function exportTemplate(params) {
    return request({
      url: '/Index/exportTemplate',
      method: 'get',
      params: params
    });
  },
  // 导出复核
  exportCheck: function exportCheck(params) {
    return request({
      url: '/Index/exportCheck',
      method: 'get',
      params: params
    });
  },

  /**
   * 活动报告下载
   * */
  // 项目列表
  projectLists: function projectLists(params) {
    return request({
      url: '/Index/eventList',
      method: 'get',
      params: params
    });
  },
  // 房源意向统计
  houseIntention: function houseIntention(params) {
    return request({
      url: '/Index/exportCollect',
      method: 'get',
      params: params
    });
  },
  // 房源状态表
  houseStatus: function houseStatus(params) {
    return request({
      url: '/Index/exportRoomStatus',
      method: 'get',
      params: params
    });
  },
  // 不在线客户
  customOnline: function customOnline(params) {
    return request({
      url: '/Index/exportOnlineUser',
      method: 'get',
      params: params
    });
  },
  // 登录过但是未认购的客户
  noOrderCustom: function noOrderCustom(params) {
    return request({
      url: '/Index/exportOrderUser',
      method: 'get',
      params: params
    });
  },
  // 客户意向统计
  userCollect: function userCollect(params) {
    return request({
      url: '/Index/userCollect',
      method: 'get',
      params: params
    });
  },
  // 客户状态表
  userStatus: function userStatus(params) {
    return request({
      url: '/Index/userStatus',
      method: 'get',
      params: params
    });
  },
  // 订单数据监测
  check: function check(params) {
    return request({
      url: '/auth/check',
      method: 'get',
      params: params
    });
  },
  // redis 数据验证
  redisCheck: function redisCheck(params) {
    return request({
      url: '/Index/redisCheck',
      method: 'get',
      params: params
    });
  },
  // 已进行活动列表
  startEvent: function startEvent(params) {
    return request({
      url: 'Index/startEvent',
      method: 'get',
      params: params
    });
  },
  // 生成无操作客户
  generateLog: function generateLog(params) {
    return request({
      url: 'Index/generateLog',
      method: 'get',
      params: params
    });
  },
  // 下载无操作客户
  downloadLog: function downloadLog(params) {
    return request({
      url: 'Index/downloadLog',
      method: 'get',
      params: params
    });
  },
  // 认购信息导出
  userOrderExport: function userOrderExport(params) {
    return request({
      url: 'Index/userOrderExport',
      method: 'get',
      params: params
    });
  },
  // 楼栋去化率
  buildSale: function buildSale(data) {
    return request({
      url: 'Index/buildSale',
      method: 'post',
      data: data
    });
  },
  exportConfig: function exportConfig() {
    return request({
      url: 'Index/exportConfig',
      method: 'GET'
    });
  },
  // 导出装户分析
  analyse: function analyse(data) {
    return request({
      url: 'Index/analyse',
      method: 'post',
      data: data
    });
  },
  // 导出车位装户分析
  carAnalyse: function carAnalyse(data) {
    return request({
      url: 'Index/carAnalyse',
      method: 'post',
      data: data
    });
  },
  // 导出订单记录
  orderExport: function orderExport(data) {
    return request({
      url: 'Index/orderExport',
      method: 'POST',
      data: data
    });
  },
  // 房源销售清单导出
  roomSaleExport: function roomSaleExport(data) {
    return request({
      url: 'Index/roomSaleExport',
      method: 'POST',
      data: data
    });
  },
  // 房源销售清单导出
  removeSignIn: function removeSignIn(data) {
    return request({
      url: 'signUser/removeSignIn',
      method: 'POST',
      data: data
    });
  },
  // 车位销售清单导出
  carSaleExport: function carSaleExport(data) {
    return request({
      url: 'Index/carSaleExport',
      method: 'POST',
      data: data
    });
  },
  // 房源销售清单导出
  carPriceAnalysis: function carPriceAnalysis(data) {
    return request({
      url: 'Index/carPriceAnalysis',
      method: 'POST',
      data: data
    });
  },
  roomPriceAnalysis: function roomPriceAnalysis(data) {
    return request({
      url: 'Index/roomPriceAnalysis',
      method: 'POST',
      data: data
    });
  }
};