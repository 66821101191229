var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title-top-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("显示版式")]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "detail-box mT10" }, [
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "title-h2" }, [_vm._v("选择摇号背景*")]),
        _c(
          "div",
          { staticClass: "bg-box mT15" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      on: {
                        click: function ($event) {
                          return _vm.handleBg(0)
                        },
                      },
                    },
                    [
                      _vm.tabBg === 0
                        ? _c("i", { staticClass: "el-icon-check" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "image" },
                        [
                          _c("el-image", {
                            attrs: { src: _vm.imgUrl + _vm.url },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text" }, [_vm._v("背景1")]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mT10 block" }, [
        _c("div", { staticClass: "title-h2" }, [_vm._v("摇号结果显示*")]),
        _c(
          "div",
          {
            staticClass: "list",
            on: {
              click: function ($event) {
                return _vm.handleList(0)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "list-image" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        class: _vm.tabList === 0 ? "on" : "",
                        attrs: { span: 16, offset: 4, calse: "set" },
                      },
                      [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v(" 1.宫格布局 "),
                          _vm.tabList === 0
                            ? _c("i", { staticClass: "el-icon-check" })
                            : _vm._e(),
                        ]),
                        _c("el-image", {
                          attrs: {
                            src: require("../../../assets/image/list-1.png"),
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }