// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/image/title_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".report-sub-title[data-v-411b3d33] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.sub-title[data-v-411b3d33] {\n  height: 40px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  overflow: hidden;\n}\n.sub-title-text[data-v-411b3d33] {\n  font-size: 25px;\n  font-family: Source Han Sans CN;\n  font-weight: 400;\n  color: #ffffff;\n  background-color: #ba9545;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 0 24px;\n}\n.sub-end[data-v-411b3d33] {\n  width: 72px;\n  height: 40px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  margin-left: -2px;\n}\n.end-sub-title[data-v-411b3d33] {\n  font-size: 20px;\n  font-family: Source Han Sans CN;\n  font-weight: 400;\n  color: rgba(0, 0, 0, 0.5);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}", ""]);
// Exports
module.exports = exports;
