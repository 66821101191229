var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "record" }, [
          _c("div", { ref: "top", staticClass: "top" }, [
            _c(
              "span",
              [
                _c("event-select", {
                  attrs: { "event-list": _vm.eventList, is_round: false },
                  on: { select_round: _vm.selectRoundHandle },
                }),
              ],
              1
            ),
            _c("div", {
              ref: "showRollText",
              staticClass: "show-top-content",
              domProps: { innerHTML: _vm._s(_vm.topShowTitle) },
            }),
            _c(
              "div",
              { staticClass: "show-look", on: { click: _vm.viewMore } },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.titleContent,
                        expression: "titleContent",
                      },
                    ],
                  },
                  [_vm._v("查看更多")]
                ),
              ]
            ),
            _c("img", {
              class: [
                "refresh-room-btn",
                { refresh_loading: _vm.tableLoading },
              ],
              attrs: { src: require("@/assets/image/refresh-room-img.svg") },
              on: { click: _vm.doGetList },
            }),
          ]),
          _c("div", { staticClass: "record__container" }, [
            _c("div", { staticStyle: { margin: "0", padding: "1px 20px" } }, [
              _c("div", { ref: "recordSearch", staticClass: "record-search" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        on: { change: _vm.changeRadio },
                        model: {
                          value: _vm.search.is_in,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "is_in", $$v)
                          },
                          expression: "search.is_in",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v(
                            "未入场：" +
                              _vm._s(_vm.userNum.not_log_count) +
                              "人"
                          ),
                        ]),
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v(
                            "已入场：" + _vm._s(_vm.userNum.is_log_count) + "人"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "zhu" },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "0.5rem" } },
                          [_vm._v("轮次")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            attrs: {
                              filterable: "",
                              size: "small",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.search.numbers,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "numbers", $$v)
                              },
                              expression: "search.numbers",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "0" },
                            }),
                            _vm._l(_vm.activity, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("el-input", {
                      staticClass: "input-flex",
                      attrs: {
                        size: "small",
                        placeholder:
                          "姓名\\手机\\" +
                          _vm.$adminList.user_code_name +
                          ";产权人身份证",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.doGetList("search")
                        },
                      },
                      model: {
                        value: _vm.search.search,
                        callback: function ($$v) {
                          _vm.$set(_vm.search, "search", $$v)
                        },
                        expression: "search.search",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.doGetList("search")
                          },
                        },
                      },
                      [_vm._v("列表查询")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "table-box" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      ref: "tableTwo",
                      attrs: {
                        data: _vm.tableTwoData,
                        "header-cell-style": { background: "#fafafa" },
                        height: _vm.tableHeight,
                        border: "",
                        "element-loading-background": "rgba(0, 0, 0, 0.5)",
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "数据正在加载中",
                        size: "medium",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                        },
                      }),
                      _vm.search.is_in == 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "入场时间",
                              align: "center",
                              width: "160",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              scope.row.created_at * 1000
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3530601075
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          label: "轮次-序号",
                          align: "center",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    class: scope.row.current
                                      ? "currentRounds"
                                      : "",
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.rounds) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "客户姓名",
                          prop: "name",
                          align: "center",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "手机号",
                          prop: "TEL",
                          align: "center",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$adminList.user_code_name,
                          prop: "user_code",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", fixed: "right", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  { attrs: { content: "入场" } },
                                  [
                                    _vm.search.is_in == 2
                                      ? _c(
                                          "span",
                                          [
                                            _c("svg-icon", {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: [
                                                    "admin",
                                                    "admission/selecttono",
                                                  ],
                                                  expression:
                                                    "['admin', 'admission/selecttono']",
                                                },
                                              ],
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "pass",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getUserList(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  { attrs: { content: "取消入场" } },
                                  [
                                    scope.row.created_at
                                      ? _c(
                                          "span",
                                          [
                                            _c("svg-icon", {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: [
                                                    "admin",
                                                    "admission/cancel",
                                                  ],
                                                  expression:
                                                    "['admin', 'admission/cancel']",
                                                },
                                              ],
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "annul",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onCancel(scope.row)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.page.page,
                      layout: _vm.layouts,
                      "page-size": _vm.page.limit,
                      "page-sizes": _vm.sizes,
                      total: _vm.page.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "workbench" }, [
          _c("div", { staticClass: "class_title" }, [_vm._v("入场审核")]),
          _c("div", { staticClass: "workbench__container" }, [
            _c("div", { staticClass: "workbench__content" }, [
              _vm._m(0),
              _vm._m(1),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "admission/selecttono"],
                      expression: "['admin', 'admission/selecttono']",
                    },
                  ],
                  staticClass: "start_page-content-right",
                },
                [
                  _c(
                    "div",
                    { staticClass: "start_page-footer-form-wrapper" },
                    [
                      _c("el-input", {
                        staticClass: "start_page-footer-form-input",
                        attrs: {
                          clearable: "",
                          placeholder:
                            "姓名\\手机\\" +
                            _vm.$adminList.user_code_name +
                            ";产权人身份证",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getUserList($event)
                          },
                        },
                        model: {
                          value: _vm.searchParams.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "tel", $$v)
                          },
                          expression: "searchParams.tel",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "start_page-footer-form-btn",
                          attrs: {
                            round: "",
                            type: "primary",
                            loading: _vm.searchLoading,
                          },
                          on: { click: _vm.getUserList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "start_page-content-desc-wrapper" },
                [_c("id-card-drive")],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "user-list-container",
          attrs: {
            width: "800px",
            top: "10vh",
            visible: _vm.dialogConfig.visible1,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible1", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "user-list-popup" }, [
            _c("div", { staticClass: "popup-hander" }, [
              _c("div", { staticClass: "search" }, [
                _c("img", {
                  staticClass: "search_icon",
                  attrs: { src: require("../../../assets/image/suc.png") },
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v("找到" + _vm._s(_vm.userList.length || 0) + "个客户"),
                ]),
              ]),
              _c("div", { staticClass: "checke-user-number" }, [
                _vm._v("已选" + _vm._s(_vm.ckeckNumber) + "个"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "popup-container" },
              _vm._l(_vm.userList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "popup-content-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectUser(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "checked",
                          {
                            "current-checked": item.checked,
                            checked_disabled: !item.succeed,
                          },
                        ],
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.checked,
                              expression: "item.checked",
                            },
                          ],
                          attrs: {
                            src: require("../../../assets/image/check.png"),
                            alt: "勾选按钮",
                          },
                        }),
                      ]
                    ),
                    item.tips
                      ? _c("div", { staticClass: "signet" }, [
                          item.succeed
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_green.png"),
                                },
                              })
                            : item.error
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_red.png"),
                                },
                              })
                            : item.repetition
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_yellow.png"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "signet_tips",
                              style: {
                                color: item.succeed
                                  ? "#22A271"
                                  : item.error
                                  ? "#FF3131"
                                  : "#FFBF00",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.tips) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("div", { staticClass: "user-info-top" }, [
                        _c("div", { staticClass: "avatar-container" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: require("../../../assets/image/avatar_icon.png"),
                            },
                          }),
                        ]),
                        _c("div", [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            item.rand_name
                              ? _c("span", { staticClass: "rend_tag" }, [
                                  _vm._v(_vm._s(item.rand_name)),
                                ])
                              : _vm._e(),
                          ]),
                          item.code
                            ? _c("div", { staticClass: "user-code" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) +
                                    "：" +
                                    _vm._s(item.code)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-info-bottom" },
                        [
                          _c("span", [_vm._v(" 手机号：" + _vm._s(item.tel))]),
                          item.admin_name
                            ? _c("div", [
                                _vm._v(
                                  "置业顾问：" +
                                    _vm._s(item.admin_name) +
                                    " " +
                                    _vm._s(
                                      item.t_name ? "(" + item.t_name + ")" : ""
                                    )
                                ),
                              ])
                            : _vm._e(),
                          item.uao_list
                            ? _vm._l(item.uao_list, function (i, j) {
                                return _c("div", { key: j }, [
                                  _vm._v(
                                    "产权人" +
                                      _vm._s(j + 1) +
                                      "：" +
                                      _vm._s(i.name) +
                                      " " +
                                      _vm._s(i.card ? "(" + i.card + ")" : "")
                                  ),
                                ])
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "popup-footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "definite_entry",
                    attrs: { type: "primary", disabled: _vm.ckeckNumber == 0 },
                    on: { click: _vm.dialogConfirm },
                  },
                  [_vm._v("入场签到")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "user-error-container",
          attrs: {
            width: "1205px",
            top: "10vh",
            visible: _vm.dialogConfig.visible2,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible2", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "popup-err-container" }, [
            _c("img", {
              staticClass: "error_bg",
              attrs: { src: require("../../../assets/image/sign_in_err.png") },
            }),
            _c("div", { staticClass: "popup-top" }, [
              _c("img", {
                staticClass: "error_icon",
                attrs: { src: require("../../../assets/image/Sigh.png") },
              }),
              _c("span", [_vm._v("未找到客户")]),
            ]),
            _c("div", { staticClass: "hint" }, [
              _vm._v("该客户未录入系统，请确认客户信息后重新入场！"),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "handle-entrance-result",
          attrs: {
            width: "1205px",
            top: "10vh",
            visible: _vm.dialogConfig.visible3,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible3", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "result-container" }, [
            _c(
              "div",
              {
                class: [
                  "left-status-show",
                  "left-content-" + _vm.dialogConfig.status,
                ],
              },
              [
                _vm.dialogConfig.status === "war"
                  ? _c("img", {
                      staticClass: "left-bg",
                      attrs: {
                        src: require("../../../assets/image/sign_in_war.png"),
                      },
                    })
                  : _vm.dialogConfig.status === "err"
                  ? _c("img", {
                      staticClass: "left-bg",
                      attrs: {
                        src: require("../../../assets/image/sign_in_err.png"),
                      },
                    })
                  : _vm.dialogConfig.status === "suc"
                  ? _c("img", {
                      staticClass: "left-bg",
                      attrs: {
                        src: require("../../../assets/image/entrance.png"),
                      },
                    })
                  : _vm.dialogConfig.status === "warSuc"
                  ? _c("img", {
                      staticClass: "left-bg",
                      attrs: {
                        src: require("../../../assets/image/sign_in_warSuc.png"),
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "hint" }, [
                  _vm.dialogConfig.status === "war"
                    ? _c("img", {
                        attrs: {
                          src: require("../../../assets/image/war.png"),
                        },
                      })
                    : _vm._e(),
                  _vm.dialogConfig.status === "warSuc"
                    ? _c("img", {
                        attrs: {
                          src: require("../../../assets/image/war.png"),
                        },
                      })
                    : _vm.dialogConfig.status === "err"
                    ? _c("img", {
                        attrs: {
                          src: require("../../../assets/image/err.png"),
                        },
                      })
                    : _vm.dialogConfig.status === "suc"
                    ? _c("img", {
                        attrs: {
                          src: require("../../../assets/image/suc.png"),
                        },
                      })
                    : _vm._e(),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.dialogConfig.title) },
                  }),
                ]),
                _vm.dialogConfig.msg
                  ? _c("span", { staticClass: "msg" }, [
                      _vm._v(_vm._s(_vm.dialogConfig.msg)),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "right-user-list" },
              _vm._l(_vm.dialogConfig.userInfo, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "right-content-item" },
                  [
                    item.tips
                      ? _c("div", { staticClass: "signet" }, [
                          item.succeed
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_green.png"),
                                },
                              })
                            : item.error
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_red.png"),
                                },
                              })
                            : item.repetition
                            ? _c("img", {
                                attrs: {
                                  src: require("../../../assets/image/signet_yellow.png"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "signet_tips",
                              style: {
                                color: item.succeed
                                  ? "#22A271"
                                  : item.error
                                  ? "#FF3131"
                                  : "#FFBF00",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.tips) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("div", { staticClass: "user-info-top" }, [
                        _c("div", { staticClass: "avatar-container" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: require("../../../assets/image/avatar_icon.png"),
                            },
                          }),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "user-name" }, [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            item.rand_name
                              ? _c("span", { staticClass: "rend_tag" }, [
                                  _vm._v(" " + _vm._s(item.rand_name) + " "),
                                ])
                              : _vm._e(),
                          ]),
                          item.code
                            ? _c("div", { staticClass: "user-code" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) +
                                    "：" +
                                    _vm._s(item.code)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-info-bottom" },
                        [
                          _c("span", [
                            _vm._v(" 手机号：" + _vm._s(item.tel) + " "),
                          ]),
                          item.admin_name
                            ? _c("div", [
                                _vm._v(
                                  " 置业顾问： " +
                                    _vm._s(item.admin_name) +
                                    " " +
                                    _vm._s(
                                      item.t_name ? "(" + item.t_name + ")" : ""
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          item.owner
                            ? _vm._l(item.owner, function (i, j) {
                                return _c("div", { key: j }, [
                                  _vm._v(
                                    "产权人" +
                                      _vm._s(j + 1) +
                                      "：" +
                                      _vm._s(i.real_name) +
                                      " (" +
                                      _vm._s(i.card) +
                                      ")"
                                  ),
                                ])
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.moreVisible,
            width: "50%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.moreVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.moreShowData,
                    height: _vm.moreHeight,
                    "header-cell-style": { background: "#fafafa" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "轮次", prop: "rounds", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "未入场人数",
                      prop: "not_log_count",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "已入场人数",
                      prop: "user_log_count",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.moreVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("source", {
        attrs: {
          media: "(max-height: 700px)",
          srcset: require("@/assets/image/admission@x1.png"),
        },
      }),
      _c("source", {
        attrs: {
          media: "(max-height: 1024px)",
          srcset: require("@/assets/image/admission@x2.png"),
        },
      }),
      _c("source", {
        attrs: {
          media: "(max-height: 1920px)",
          srcset: require("@/assets/image/admission@x3.png"),
        },
      }),
      _c("img", {
        staticClass: "bgimage",
        attrs: { src: require("@/assets/image/admission.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "start_page-content-desc-wrapper" }, [
      _vm._v("请将"),
      _c("span", { staticStyle: { color: "#22a271" } }, [
        _vm._v("客户身份证放在读卡器"),
      ]),
      _vm._v("上，或手动查询"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }