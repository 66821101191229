var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("SwitchItem"),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                JSON.parse(_vm.user).avatar === ""
                  ? _c(
                      "span",
                      [
                        _c("el-image", {
                          staticClass: "user-avatar",
                          attrs: {
                            src: require("@/assets/image/avatar_icon.png"),
                          },
                        }),
                      ],
                      1
                    )
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: {
                        src:
                          JSON.parse(_vm.user).avatar +
                          "?imageView2/1/w/80/h/80",
                      },
                    }),
                _c("span", { staticClass: "userName mL10" }, [
                  _vm._v(_vm._s(JSON.parse(_vm.user).name)),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _vm._v(_vm._s(JSON.parse(_vm.user).tel)),
                  ]),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.download()
                        },
                      },
                    },
                    [_vm._v("驱动下载")]
                  ),
                  _c(
                    "a",
                    { attrs: { target: "_blank" } },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.buildPassword($event)
                            },
                          },
                        },
                        [_vm._v("修改密码")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.editPassword,
            width: "500px",
            center: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPassword = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.loginRules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "code" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      size: "small",
                      placeholder: "输入4~12位新密码，仅限数字、字母。",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.editPassword = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handlePostSave($event)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }