import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import items from './items.vue';
import { mapMutations, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
export default {
  name: 'canvasContent',
  components: {
    items: items,
    draggable: draggable
  },
  props: {// isChange:{
    // 	type:Boolean,
    // 	default:false
    // }
  },
  watch: {},
  computed: _objectSpread(_objectSpread({}, mapGetters(['currentConfing', 'background'])), {}, {
    isChange: {
      get: function get() {
        var _this$$store;

        return (_this$$store = this.$store) === null || _this$$store === void 0 ? void 0 : _this$$store.getters.isChange;
      },
      set: function set(value, test) {
        this.SET_CHANGE(value);
      }
    },
    isHide: {
      get: function get() {
        var _this$$store2;

        return (_this$$store2 = this.$store) === null || _this$$store2 === void 0 ? void 0 : _this$$store2.getters.isHide;
      },
      set: function set(value, test) {
        this.SET_RIGHT_HIDE(value);
      }
    },
    commontList: {
      get: function get() {
        var _this$$store3;

        return (_this$$store3 = this.$store) === null || _this$$store3 === void 0 ? void 0 : _this$$store3.getters.commontList;
      },
      set: function set(value, test) {
        this.DRAG_SORT({
          value: value
        });
      }
    }
  }),
  mounted: function mounted() {},
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['EDIT_COMMONT', 'REST_COMMONT', 'SWICTH_COMMONT', 'DRAG_SORT', 'SETBACKGROUND', 'SYNCSETTING', 'SET_CHANGE', 'SET_RIGHT_HIDE'])), {}, {
    // 	同步
    syncSetting: function syncSetting() {
      this.SYNCSETTING();
      this.$message.success('同步成功!');
    },
    // 保存当前配置
    save: function save() {
      if (!this.isChange) {
        this.SYNCSETTING();
      }

      this.$emit('save');
    },
    //右边整体背景色
    setBackgound: function setBackgound(val) {
      this.SETBACKGROUND(val);
    },
    // 	复制屏幕
    copyLed: function copyLed() {
      this.$emit('copyChange');
    },
    swicthInfo: function swicthInfo(type) {
      this.SWICTH_COMMONT(type);
    },
    //编辑组件右侧显示样式配置表单
    showCommontEdit: function showCommontEdit(item, index) {
      this.EDIT_COMMONT({
        currentForm: item.title,
        parentIndex: index
      });
    },
    // 重置
    resetting: function resetting() {
      var _this = this;

      this.$confirm("\u662F\u5426\u91CD\u7F6E".concat(this.currentConfing == 1 ? '开盘前的' : '开盘后的', "\u8BBE\u7F6E?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.REST_COMMONT();

        if (!_this.isChange) {
          _this.SYNCSETTING();
        }

        _this.$message.success('操作成功!');
      });
    }
  })
};