var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "padding-container" }, [
      _c("div", { staticClass: "top-fixed" }, [
        _vm.type === 1
          ? _c("div", { staticClass: "title" }, [_vm._v("修改认筹表")])
          : _c("div", { staticClass: "title" }, [_vm._v("修改附表")]),
      ]),
      _c("div", { staticClass: "block-box" }, [
        _c(
          "div",
          { staticClass: "scroll-box mT10" },
          [
            _c("input-box", {
              attrs: {
                userCreateInfoId: _vm.userCreateInfoId,
                "status-info": _vm.statusInfo,
                type: _vm.type,
              },
              on: {
                addPropertyOwner: _vm.addPropertyOwner,
                addPropertyBank: _vm.addPropertyBank,
                handleDelete: _vm.handleDelete,
                handleOtherImg: _vm.handleOtherImg,
                handleProperty: _vm.handleProperty,
                handlePropertyBank: _vm.handlePropertyBank,
                handleRadio: _vm.handleRadio,
                doShowParent: _vm.doShowParent,
                handlePopup: _vm.handlePopup,
                handleHouse: _vm.handleHouse,
                limit: _vm.onRoomList,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "block-bottom-box" },
      [
        _c(
          "el-button",
          { staticClass: "pink-btn2", on: { click: _vm.handleCancel } },
          [_vm._v("关闭")]
        ),
        _c(
          "el-button",
          { staticClass: "greens-btn", on: { click: _vm.handleSave } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }