var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "7vh",
            visible: _vm.centerDialogVisible,
            width: "1000px",
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { class: [_vm.hint.status] }, [
              _vm._v(_vm._s(_vm.hint.msg)),
            ]),
            _c("div", { staticClass: "video-container" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: [2].includes(_vm.hint.type),
                      expression: "[2].includes(hint.type)",
                    },
                  ],
                  staticClass: "video-duraiton",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("dateSecondsFormat")(_vm.videoTime)) +
                      " "
                  ),
                ]
              ),
              _c("video", {
                ref: "videoCamera",
                attrs: {
                  width: _vm.videoWidth,
                  height: _vm.videoHeight,
                  autoplay: "",
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mT10" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [0].includes(_vm.hint.type),
                        expression: "[0].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.getCompetence()
                      },
                    },
                  },
                  [_vm._v(" 打开摄像头")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [1].includes(_vm.hint.type),
                        expression: "[1].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.stopNavigator()
                      },
                    },
                  },
                  [_vm._v(" 关闭摄像头")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [1].includes(_vm.hint.type),
                        expression: "[1].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.startMakeVideo()
                      },
                    },
                  },
                  [_vm._v(" 开始录制")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [2].includes(_vm.hint.type),
                        expression: "[2].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.seveVideo()
                      },
                    },
                  },
                  [_vm._v(" 结束录制并保存视频至本地")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [2].includes(_vm.hint.type),
                        expression: "[2].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.uploadVideo()
                      },
                    },
                  },
                  [_vm._v(" 结束录制并保存视频至云端")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: [2].includes(_vm.hint.type),
                        expression: "[2].includes(hint.type)",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.anewVideo()
                      },
                    },
                  },
                  [_vm._v("重新录制")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.video_url,
                        expression: "video_url",
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.previewVideo()
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v(" 关闭弹窗 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览", visible: _vm.preview, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.preview = $event
            },
          },
        },
        [
          _c("video", {
            ref: "previewView",
            staticStyle: { width: "100%", height: "100%" },
            attrs: { controls: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }