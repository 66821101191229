/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-06 20:49,
 */
import request from '@/utils/request';
import qs from 'qs'; //  开盘客户列表

export function userList(data) {
  return request({
    url: 'User/index',
    method: 'post',
    data: data
  });
} // 获取场次列表

export function userSessionList(params) {
  return request({
    url: 'User/relationRound',
    method: 'get',
    params: params
  });
} // 移除客户

export function userDelList(data) {
  return request({
    url: 'User/delAll',
    method: 'POST',
    data: data
  });
} // 登录口令

export function CaptchaInfo(data) {
  return request({
    url: 'User/CaptchaInfo',
    method: 'POST',
    data: data
  });
}
export var login = function login(data) {
  return request({
    url: 'User/login',
    method: 'POST',
    data: data
  });
}; // 根据条件导出客户

export function userExport(data) {
  return request({
    url: 'User/export',
    method: 'post',
    data: data
  });
} // 根据条件导出客户

export function userType(data) {
  return request({
    url: 'User/disableEnable',
    method: 'post',
    data: data
  });
} // 新增 客户

export function userAddList(data) {
  return request({
    url: 'User/add',
    method: 'post',
    data: data
  });
} // 客户 详情

export function userDetails(params) {
  return request({
    url: 'User/details',
    method: 'get',
    params: params
  });
} // 修改 客户

export function userEdit(data) {
  return request({
    url: 'User/edit',
    method: 'post',
    data: data
  });
} // 获取置业顾问列表

export function userAdmin(data) {
  return request({
    url: 'User/admin',
    method: 'get',
    data: data
  });
} // 关联列表

export function userRelation(data) {
  return request({
    url: 'User/relationRound',
    method: 'get',
    data: data
  });
} // 重置关联

export function userdelConfirm(data) {
  return request({
    url: 'User/delConfirm',
    method: 'post',
    data: data
  });
} // 获取客户场次

export function userGroupUser(data) {
  return request({
    url: 'User/groupUser',
    method: 'POST',
    data: data
  });
} // 导出关联场次

export function userEventExport(data) {
  return request({
    url: 'User/eventExport',
    method: 'get',
    data: data
  });
} // 确认关联场次

export function userConfirm(data) {
  return request({
    url: 'User/confirm',
    method: 'post',
    data: data
  });
} // 获取楼层房间

export function roomTerminal(params) {
  return request({
    url: 'Room/terminal',
    method: 'get',
    params: params
  });
} // 获取别墅房间

export function roomVillaTerminal(params) {
  return request({
    url: 'RoomVilla/terminal',
    method: 'get',
    params: params
  });
}
export function userStatusList(data) {
  return request({
    url: 'User/disableEnableAll',
    method: 'post',
    data: data
  });
} // 审核列表

export function auditUserList(params) {
  return request({
    url: 'User/auditUserList',
    method: 'get',
    params: params
  });
} // 通过审核

export function userAdopt(data) {
  return request({
    url: 'User/adopt',
    method: 'post',
    data: data
  });
} // 驳回审核

export function userReject(data) {
  return request({
    url: 'User/reject',
    method: 'post',
    data: data
  });
} // 驳回审核

export function auditDetails(params) {
  return request({
    url: 'User/auditDetails',
    method: 'get',
    params: params
  });
} //  客户详情  意向房源

export function getPreference(params) {
  return request({
    url: 'User/preference',
    method: 'get',
    params: params
  });
} //  客户详情  活动数据

export function getEventData(params) {
  return request({
    url: 'User/eventData',
    method: 'get',
    params: params
  });
} //  客户详情  日志

export function getLogList(params) {
  return request({
    url: 'User/logList',
    method: 'get',
    params: params
  });
} //  客户详情  日志

export function setAdminAll(data) {
  return request({
    url: 'User/setAdminAll',
    method: 'post',
    data: data
  });
} //  导出 诚意金

export function priceExport(params) {
  return request({
    url: 'User/priceExport',
    method: 'get',
    params: params
  });
} //  导出 诚意金

export function userRandAll(data) {
  return request({
    url: 'User/isRandAll',
    method: 'post',
    data: data
  });
} //  导出 特殊客户名单

export function sleepExport(params) {
  return request({
    url: 'User/sleepExport',
    method: 'get',
    params: params
  });
} // 同步实名认证

export function autoAuth(params) {
  return request({
    url: 'User/autoAuth',
    method: 'GET',
    params: params
  });
} // 同步客户fid

export function syncFid(data) {
  return request({
    url: 'User/syncFid',
    method: 'POST',
    data: data
  });
} // 同步客户fid

export function syncNoUserFid(data) {
  return request({
    url: 'User/syncNoUserFid',
    method: 'POST',
    data: data
  });
} // 批量修改客户分类

export function updateLevels(data) {
  return request({
    url: 'User/updateLevels',
    method: 'post',
    data: data
  });
} // 批量增加客户限购次数

export function SetBuyCountAll(data) {
  return request({
    url: 'User/SetBuyCountAll',
    method: 'post',
    data: data
  });
} // 同步签到编号到诚意单号

export function syncSignCode(data) {
  return request({
    url: 'User/syncSignCode',
    method: 'post',
    data: data
  });
} // 同步码牌编码到诚意单号

export function syncQrCode(data) {
  return request({
    url: 'User/syncQrCode',
    method: 'post',
    data: data
  });
}