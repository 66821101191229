var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container review-details" },
    [
      _c("div", { ref: "topDiv" }, [
        _c("div", { staticClass: "top-fixed" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "title-left flex align-items" }, [
              _c("span", { staticClass: "audit-title" }, [
                _vm._v(_vm._s(_vm.auditInfo.title)),
              ]),
              _vm.auditInfo.status === -3
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("已过期"),
                    ]),
                  ])
                : _vm.auditInfo.status === -2
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("审核驳回"),
                    ]),
                  ])
                : _vm.auditInfo.status === -1
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("已撤销"),
                    ]),
                  ])
                : _vm.auditInfo.status === 0
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("未发起审核"),
                    ]),
                  ])
                : _vm.auditInfo.status === 1
                ? _c("div", { staticClass: "title-type yellow" }, [
                    _c("span", { staticClass: "button-type yellow" }, [
                      _vm._v("待审核"),
                    ]),
                  ])
                : _vm.auditInfo.status === 2
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type" }, [
                      _vm._v("审核通过"),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  float: "right",
                  "font-size": "25px",
                  cursor: "pointer",
                },
                on: {
                  click: function ($event) {
                    return _vm.open_close_drawer(false)
                  },
                },
              },
              [_c("i", { staticClass: "el-icon-close" })]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "switch-btn-box",
          staticStyle: { padding: "0 38px 10px 38px" },
        },
        [
          _c("switchBtn", {
            attrs: {
              "is-show-user": true,
              "is-show-schedule": _vm.showChangeSchedule,
            },
            on: { tabClick: _vm.tabClick },
          }),
          _vm.types === "rai0"
            ? [
                _vm.index === 1
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit0"],
                            expression:
                              "['admin', 'raisingfundsaudit/auditraiedit0']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改升筹筹表信息")]
                    )
                  : _vm.index === 4
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit0"],
                            expression:
                              "['admin', 'raisingfundsaudit/auditraiedit0']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改审核附表")]
                    )
                  : _vm._e(),
              ]
            : _vm.types === "rai1"
            ? [
                _vm.index === 1
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit1"],
                            expression:
                              "['admin','raisingfundsaudit/auditraiedit1']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改升筹表信息")]
                    )
                  : _vm.index === 4
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit1"],
                            expression:
                              "['admin', 'raisingfundsaudit/auditraiedit1']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改审核附表")]
                    )
                  : _vm._e(),
              ]
            : [
                _vm.index === 1
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit2"],
                            expression:
                              "['admin', 'raisingfundsaudit/auditraiedit2']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改升筹表信息")]
                    )
                  : _vm.index === 4
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "raisingfundsaudit/auditraiedit2"],
                            expression:
                              "['admin', 'raisingfundsaudit/auditraiedit2']",
                          },
                        ],
                        on: { click: _vm.handleEdit },
                      },
                      [_vm._v("修改审核附表")]
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "padding-container",
          style: { height: "calc(100vh - 215px)" },
        },
        [
          _c("div", { staticClass: "block-box" }, [
            _vm.index === 3
              ? _c(
                  "div",
                  { staticClass: "scroll-box mT15" },
                  [
                    _c("user-register", {
                      attrs: {
                        "audit-info": _vm.regData.auditInfo,
                        info: _vm.regData.info,
                        "info-tips": _vm.regData.infoTips,
                        "status-info": _vm.regData.statusInfo,
                        status: _vm.regData.statusInfo,
                        type: 1,
                      },
                    }),
                  ],
                  1
                )
              : _vm.index === 2
              ? _c(
                  "div",
                  { staticClass: "scroll-box mT15" },
                  [
                    _c("user-register", {
                      attrs: {
                        "audit-info": _vm.ideData.auditInfo,
                        info: _vm.ideData.info,
                        "info-tips": _vm.ideData.infoTips,
                        "status-info": _vm.ideData.statusInfo,
                        status: _vm.ideData.statusInfo,
                        type: 1,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "scroll-box mT15" },
                  [
                    _c("user-register", {
                      attrs: {
                        "audit-info": _vm.auditInfo,
                        info: _vm.info,
                        "info-tips": _vm.infoTips,
                        "status-info": _vm.statusInfo,
                        type: 1,
                      },
                    }),
                  ],
                  1
                ),
          ]),
        ]
      ),
      _c("div", { staticClass: "block-bottom-box" }, [
        _c("div", { staticClass: "po" }, [
          _c(
            "div",
            { staticClass: "bottom-btn-group" },
            [
              _vm.auditInfo.status !== 2 && _vm.auditInfo.status !== -2
                ? [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: _vm.showChangeSchedule ? 16 : 24 } },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  float: _vm.showChangeSchedule
                                    ? "right"
                                    : "none",
                                  "margin-right": _vm.showChangeSchedule
                                    ? "50px"
                                    : 0,
                                },
                              },
                              [
                                _vm.saasSetting.audit.refuse_type == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "pink-btn2",
                                        staticStyle: { width: "170px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleShowDialog(
                                              "refuse"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("驳回并拒绝再次提交 ")]
                                    )
                                  : _vm._e(),
                                _vm.saasSetting.audit.reject_type == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "pink-btn2",
                                        staticStyle: { width: "170px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleShowDialog(
                                              "return"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("驳回 ")]
                                    )
                                  : _vm._e(),
                                _vm.types === "rai0 " || "rai2"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "greens-btn",
                                        staticStyle: { width: "170px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleTurnDown("commit")
                                          },
                                        },
                                      },
                                      [_vm._v("通过 ")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "greens-btn",
                                        staticStyle: { width: "170px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleTurnDown("commit")
                                          },
                                        },
                                      },
                                      [_vm._v("通过并发起复审 ")]
                                    ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.auditInfo.status === 2
                ? [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            [
                              _vm.saasSetting.audit.refuse_type == 1
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "admin",
                                            "raisingfundsaudit/rejectagain",
                                          ],
                                          expression:
                                            "['admin', 'raisingfundsaudit/rejectagain']",
                                        },
                                      ],
                                      staticClass: "pink-btn2",
                                      staticStyle: { width: "200px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowDialog("refuse2")
                                        },
                                      },
                                    },
                                    [_vm._v("再次驳回并拒绝再次提交 ")]
                                  )
                                : _vm._e(),
                              _vm.saasSetting.audit.reject_type == 1
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "admin",
                                            "raisingfundsaudit/againrejected",
                                          ],
                                          expression:
                                            "['admin', 'raisingfundsaudit/againrejected']",
                                        },
                                      ],
                                      staticClass: "pink-btn2",
                                      staticStyle: { width: "200px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowDialog("return2")
                                        },
                                      },
                                    },
                                    [_vm._v("再次驳回 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              bottom: "0",
              float: "right",
              "margin-top": "-10px",
              "margin-right": "50px",
            },
          },
          [
            _c(
              "el-checkbox",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.auditInfo.status != -2,
                    expression: "auditInfo.status != -2",
                  },
                ],
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [_vm._v("继续下一个审核")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "textarea-box",
            "append-to-body": true,
            visible: _vm.isTips,
            center: "",
            title: "驳回理由",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isTips = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              maxlength: "100",
              placeholder: "请输入驳回理由（必填）",
              "show-word-limit": "",
              size: "small",
              type: "textarea",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer flex align-items ju-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isTips = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleChange },
                },
                [_vm._v("驳回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.isReason,
            center: "",
            title: "上次驳回原因",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isReason = $event
            },
          },
        },
        [
          _c("div", [
            _c("p", [_vm._v("驳回原因：" + _vm._s(_vm.turnInfo.err_note))]),
            _c("p", [
              _vm._v(
                "审核时间：" +
                  _vm._s(_vm._f("dateFormat")(_vm.turnInfo.audit_at * 1000))
              ),
            ]),
            _c("p", [_vm._v("审核人：" + _vm._s(_vm.turnInfo.name))]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer flex align-items ju-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isReason = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }