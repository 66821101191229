//
//
//
//
export default {
  name: 'RoundsBtn',
  props: {
    btnText: {
      type: String,
      default: '开始摇号'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    btnClick: function btnClick() {
      if (!this.isLoading) {
        this.$emit('btnClick');
      }
    }
  }
};