/**
 * @author: lm,
 * @date: 2021-12-30 10:23:20,
 */
import request from '@/utils/request'; // 码牌类型列表

export function qrcodeList(params) {
  return request({
    url: '/qrcode/list',
    method: 'get',
    params: params
  });
} // 添加码牌类型

export function qrcodeAdd(data) {
  return request({
    url: '/qrcode/add',
    method: 'post',
    data: data
  });
} // 修改码牌配置

export function qrcodeSet(data) {
  return request({
    url: '/qrcode/set',
    method: 'post',
    data: data
  });
} // 修改码牌背景图

export function qrcodeBgi(data) {
  return request({
    url: '/qrcode/bgi',
    method: 'post',
    data: data
  });
} // 删除码牌类型

export function qrcodeDel(params) {
  return request({
    url: '/qrcode/del',
    method: 'get',
    params: params
  });
} // 生成二维码

export function qrcodeCreate(params) {
  return request({
    url: '/qrcode/create',
    method: 'get',
    params: params
  });
} // 恢复默认配置

export function qrcodeDefault(params) {
  return request({
    url: '/qrcode/default',
    method: 'post',
    params: params
  });
} // 生产二维码生成参数

export function createQR(data) {
  return request({
    url: '/auth/QrCode',
    method: 'POST',
    data: data
  });
} // 上传客户二维码

export function qrCode(data) {
  return request({
    url: '/upload/qrCode',
    method: 'POST',
    data: data
  }, false);
}