var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.batchTimeData.show_day
      ? _c("span", { staticClass: "num", style: { color: _vm.color } }, [
          _vm._v(_vm._s(_vm.addZero(_vm.batchTimeData.day))),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_day
      ? _c("span", { staticClass: "text", style: { color: _vm.color } }, [
          _vm._v("天"),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_hour
      ? _c("span", { staticClass: "num", style: { color: _vm.color } }, [
          _vm._v(_vm._s(_vm.addZero(_vm.batchTimeData.hour))),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_hour
      ? _c("span", { staticClass: "text", style: { color: _vm.color } }, [
          _vm._v("时"),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_min
      ? _c("span", { staticClass: "num", style: { color: _vm.color } }, [
          _vm._v(_vm._s(_vm.addZero(_vm.batchTimeData.min))),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_min
      ? _c("span", { staticClass: "text", style: { color: _vm.color } }, [
          _vm._v("分"),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_sec
      ? _c("span", { staticClass: "num", style: { color: _vm.color } }, [
          _vm._v(_vm._s(_vm.addZero(_vm.batchTimeData.sec))),
        ])
      : _vm._e(),
    _vm.batchTimeData.show_sec
      ? _c("span", { staticClass: "text", style: { color: _vm.color } }, [
          _vm._v("秒"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }