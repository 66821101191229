var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isLog
      ? _c(
          "div",
          [
            _vm.statusInfo.length
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "20px",
                      border: "1px solid #22a271",
                      margin: "20px",
                      "border-radius": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "title-h2" }, [
                      _vm._v("最新审核状态"),
                    ]),
                    _vm._m(0),
                    _c("div", { staticClass: "detail-item" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v("最新审核状态:"),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.status === -1
                              ? "已驳回"
                              : _vm.statusTxt[_vm.status]
                          )
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._l(_vm.statusInfo, function (item, index) {
              return _c("div", { key: index }, [
                _vm.getType(item)
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "20px",
                          border: "1px solid #22a271",
                          margin: "20px",
                          "border-radius": "20px",
                        },
                      },
                      [
                        item.type === 1 && _vm.type === 1
                          ? [
                              _vm.type === 1
                                ? _c("div", { staticClass: "title-h2" }, [
                                    _vm._v(_vm._s(item.area_name)),
                                  ])
                                : _vm._e(),
                              _vm._m(1, true),
                              _c(
                                "div",
                                { staticClass: "propertyBox" },
                                _vm._l(item.owners, function (list, inx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "prop" + inx,
                                      staticClass: "propertyList",
                                    },
                                    [
                                      _c("div", { staticClass: "block-item" }, [
                                        _c("div", { staticClass: "name" }, [
                                          _c(
                                            "span",
                                            { staticClass: "buttons" },
                                            [
                                              _vm._v(
                                                "第" +
                                                  _vm._s(inx + 1) +
                                                  _vm._s(item.area_name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._l(list.forms, function (form, i) {
                                        return [
                                          form.fill_in_type === _vm.type &&
                                          form.parent_cause_show
                                            ? [
                                                form.owner_type === 41
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "block-item",
                                                      },
                                                      [_vm._m(2, true)]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "置业顾问"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getSelected(
                                                                  form.admins,
                                                                  form
                                                                    .normal_result
                                                                    .value
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "text" ||
                                                form.form_type === "number"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form
                                                                  .owner_result
                                                                  .value
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "file"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            form.owner_result
                                                              .value !== ""
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-block",
                                                                    },
                                                                    _vm._l(
                                                                      form.owner_result.value.split(
                                                                        ","
                                                                      ),
                                                                      function (
                                                                        img,
                                                                        imgi
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: imgi,
                                                                            staticClass:
                                                                              "img-box",
                                                                          },
                                                                          [
                                                                            !_vm.fileName(
                                                                              img
                                                                            )
                                                                              ? _c(
                                                                                  "el-image",
                                                                                  {
                                                                                    staticClass:
                                                                                      "imageSize",
                                                                                    attrs:
                                                                                      {
                                                                                        src:
                                                                                          _vm.imgUrl +
                                                                                          img,
                                                                                        "preview-src-list":
                                                                                          _vm.srcImgList,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        height:
                                                                                          "95px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        staticClass:
                                                                                          "imageSize",
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("../../../../assets/image/file.png"),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "105px",
                                                                                            "margin-top":
                                                                                              "-58px",
                                                                                            "font-size":
                                                                                              "12px",
                                                                                            color:
                                                                                              "white",
                                                                                            padding:
                                                                                              "0 5px",
                                                                                            "line-height":
                                                                                              "12px",
                                                                                            height:
                                                                                              "30px",
                                                                                            display:
                                                                                              "-webkit-box",
                                                                                            overflow:
                                                                                              "hidden",
                                                                                            "-webkit-line-clamp":
                                                                                              "2",
                                                                                            "-webkit-box-orient":
                                                                                              "vertical",
                                                                                            "text-align":
                                                                                              "left",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "block",
                                                                                                height:
                                                                                                  "30px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                href:
                                                                                                  _vm.imgUrl +
                                                                                                  img,
                                                                                                target:
                                                                                                  "_blank",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.fileName(
                                                                                                  img
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              : [
                                                                  _vm._v(
                                                                    "暂无图片"
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "radio"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (
                                                              radio,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "radio" +
                                                                    index,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form
                                                                                .owner_result
                                                                                .value ==
                                                                              radio.id,
                                                                            expression:
                                                                              "form.owner_result.value == radio.id",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          radio.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "select"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text select-text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (select) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "select" +
                                                                    select.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form.owner_result.value.includes(
                                                                                select.id
                                                                              ),
                                                                            expression:
                                                                              "form.owner_result.value.includes(select.id)",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          select.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  )
                                }),
                                0
                              ),
                            ]
                          : item.type === 2 && _vm.type === 1
                          ? [
                              _vm.type === 1
                                ? _c("div", { staticClass: "title-h2" }, [
                                    _vm._v(_vm._s(item.area_name)),
                                  ])
                                : _vm._e(),
                              _vm._m(3, true),
                              _c(
                                "div",
                                { staticClass: "propertyBox" },
                                _vm._l(item.banks, function (list, inx) {
                                  return _c(
                                    "div",
                                    {
                                      key: "prop" + inx,
                                      staticClass: "propertyList",
                                    },
                                    [
                                      _c("div", { staticClass: "block-item" }, [
                                        _c("div", { staticClass: "name" }, [
                                          _c(
                                            "span",
                                            { staticClass: "buttons" },
                                            [
                                              _vm._v(
                                                "第" +
                                                  _vm._s(inx + 1) +
                                                  _vm._s(item.area_name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._l(list.forms, function (form, i) {
                                        return [
                                          form.fill_in_type === _vm.type &&
                                          form.parent_cause_show
                                            ? [
                                                form.form_type === "置业顾问"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getSelected(
                                                                  form.admins,
                                                                  form
                                                                    .normal_result
                                                                    .value
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "text" ||
                                                form.form_type === "number" ||
                                                form.form_type === "map"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.bank_result
                                                                  .value
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "file"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            form.bank_result
                                                              .value !== ""
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-block",
                                                                    },
                                                                    _vm._l(
                                                                      form.bank_result.value.split(
                                                                        ","
                                                                      ),
                                                                      function (
                                                                        img,
                                                                        imgi
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: imgi,
                                                                            staticClass:
                                                                              "img-box",
                                                                          },
                                                                          [
                                                                            !_vm.fileName(
                                                                              img
                                                                            )
                                                                              ? _c(
                                                                                  "el-image",
                                                                                  {
                                                                                    staticClass:
                                                                                      "imageSize",
                                                                                    attrs:
                                                                                      {
                                                                                        src:
                                                                                          _vm.imgUrl +
                                                                                          img,
                                                                                        "preview-src-list":
                                                                                          _vm.srcImgList,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        height:
                                                                                          "95px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        staticClass:
                                                                                          "imageSize",
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("../../../../assets/image/file.png"),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "105px",
                                                                                            "margin-top":
                                                                                              "-58px",
                                                                                            "font-size":
                                                                                              "12px",
                                                                                            color:
                                                                                              "white",
                                                                                            padding:
                                                                                              "0 5px",
                                                                                            "line-height":
                                                                                              "12px",
                                                                                            height:
                                                                                              "30px",
                                                                                            display:
                                                                                              "-webkit-box",
                                                                                            overflow:
                                                                                              "hidden",
                                                                                            "-webkit-line-clamp":
                                                                                              "2",
                                                                                            "-webkit-box-orient":
                                                                                              "vertical",
                                                                                            "text-align":
                                                                                              "left",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "block",
                                                                                                height:
                                                                                                  "30px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                href:
                                                                                                  _vm.imgUrl +
                                                                                                  img,
                                                                                                target:
                                                                                                  "_blank",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.fileName(
                                                                                                  img
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              : [
                                                                  _vm._v(
                                                                    "暂无图片"
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "radio"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (
                                                              radio,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "radio" +
                                                                    index,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form
                                                                                .bank_result
                                                                                .value ==
                                                                              radio.id,
                                                                            expression:
                                                                              "form.bank_result.value == radio.id",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          radio.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "select"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text select-text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (select) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "select" +
                                                                    select.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form.bank_result.value.includes(
                                                                                select.id
                                                                              ),
                                                                            expression:
                                                                              "form.bank_result.value.includes(select.id)",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          select.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  )
                                }),
                                0
                              ),
                            ]
                          : [
                              _vm.getType(item)
                                ? _c("div", { staticClass: "title-h2" }, [
                                    _vm._v(_vm._s(item.area_name)),
                                  ])
                                : _vm._e(),
                              _vm._m(4, true),
                              _c(
                                "el-row",
                                {
                                  staticClass: "el-row",
                                  attrs: { gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _vm._l(item.forms, function (form, inx) {
                                        return [
                                          form.fill_in_type === _vm.type &&
                                          form.parent_cause_show
                                            ? [
                                                form.form_type === "置业顾问"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: inx,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getSelected(
                                                                  form.admins,
                                                                  form
                                                                    .normal_result
                                                                    .value
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "text" ||
                                                form.form_type === "number"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: inx,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form
                                                                  .normal_result
                                                                  .value
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "file" &&
                                                !form.is_show_tp
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: inx,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            form.normal_result
                                                              .value !== ""
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-block",
                                                                    },
                                                                    _vm._l(
                                                                      form.normal_result.value.split(
                                                                        ","
                                                                      ),
                                                                      function (
                                                                        img,
                                                                        imgi
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: imgi,
                                                                            staticClass:
                                                                              "img-box",
                                                                          },
                                                                          [
                                                                            !_vm.fileName(
                                                                              img
                                                                            )
                                                                              ? _c(
                                                                                  "el-image",
                                                                                  {
                                                                                    staticClass:
                                                                                      "imageSize",
                                                                                    attrs:
                                                                                      {
                                                                                        src:
                                                                                          _vm.imgUrl +
                                                                                          img,
                                                                                        "preview-src-list":
                                                                                          _vm.srcImgList,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        height:
                                                                                          "95px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        staticClass:
                                                                                          "imageSize",
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("../../../../assets/image/file.png"),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "105px",
                                                                                            "margin-top":
                                                                                              "-58px",
                                                                                            "font-size":
                                                                                              "12px",
                                                                                            color:
                                                                                              "white",
                                                                                            padding:
                                                                                              "0 5px",
                                                                                            "line-height":
                                                                                              "12px",
                                                                                            height:
                                                                                              "30px",
                                                                                            display:
                                                                                              "-webkit-box",
                                                                                            overflow:
                                                                                              "hidden",
                                                                                            "-webkit-line-clamp":
                                                                                              "2",
                                                                                            "-webkit-box-orient":
                                                                                              "vertical",
                                                                                            "text-align":
                                                                                              "left",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "block",
                                                                                                height:
                                                                                                  "30px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                href:
                                                                                                  _vm.imgUrl +
                                                                                                  img,
                                                                                                target:
                                                                                                  "_blank",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.fileName(
                                                                                                  img
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                ]
                                                              : [
                                                                  _vm._v(
                                                                    "暂无图片"
                                                                  ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "radio" &&
                                                !form.is_show_tp
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: inx,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (
                                                              radio,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "radio" +
                                                                    index,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form
                                                                                .normal_result
                                                                                .value ==
                                                                              radio.id,
                                                                            expression:
                                                                              "form.normal_result.value == radio.id",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          radio.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                form.form_type === "select"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: inx,
                                                        staticClass:
                                                          "detail-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                form.name
                                                              ) + ":"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text select-text",
                                                          },
                                                          _vm._l(
                                                            form.children,
                                                            function (select) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "select" +
                                                                    select.id,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              form.normal_result.value.includes(
                                                                                select.id
                                                                              ),
                                                                            expression:
                                                                              "form.normal_result.value.includes(select.id)",
                                                                          },
                                                                        ],
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          select.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            }),
            !_vm.statusInfo || !_vm.statusInfo.length
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "min-height": "400px",
                      "line-height": "400px",
                      "text-align": "center",
                      "font-size": "14px",
                      color: "#909399",
                    },
                  },
                  [_vm._v(" 暂无数据 ")]
                )
              : _c("div", [
                  _vm.logList && _vm.logList.length
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "20px",
                            border: "1px solid #22a271",
                            margin: "20px",
                            "border-radius": "20px",
                          },
                        },
                        [
                          _c("div", { staticClass: "title-h2" }, [
                            _vm._v("日志"),
                          ]),
                          _vm._m(5),
                          _c(
                            "div",
                            { staticClass: "record-box record-box-sb" },
                            _vm._l(_vm.logList, function (list, inx) {
                              return _c(
                                "div",
                                { key: inx, staticClass: "record-list" },
                                [
                                  _c("div", { staticClass: "record-title" }, [
                                    _vm._v(
                                      _vm._s(list.content) +
                                        " --- ( " +
                                        _vm._s(list.admin_name || "客户") +
                                        " )"
                                    ),
                                  ]),
                                  _c("div", { staticClass: "record-time" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          list.created_time * 1000
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.infoTips && _vm.infoTips.length
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "20px",
                            border: "1px solid #22a271",
                            margin: "20px",
                            "border-radius": "20px",
                          },
                        },
                        [
                          _c("div", { staticClass: "block-box" }, [
                            _c("div", { staticClass: "title-h2" }, [
                              _vm._v("信息"),
                            ]),
                            _vm._m(6),
                            _c("div", { staticClass: "record-box" }, [
                              _c(
                                "div",
                                { staticClass: "record-list record-list-sb" },
                                _vm._l(_vm.infoTips, function (info, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: "info" + i,
                                      staticClass: "record-title",
                                    },
                                    _vm._l(info, function (item, j) {
                                      return _c("span", { key: "infod" + j }, [
                                        _vm._v(
                                          _vm._s(item.name) +
                                            "： " +
                                            _vm._s(item.value)
                                        ),
                                      ])
                                    }),
                                    0
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
          ],
          2
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "record-box record-box-sb" },
            [
              _vm._l(_vm.data.log, function (list, inx) {
                return _c("div", { key: inx, staticClass: "record-list" }, [
                  _c("div", { staticClass: "record-title" }, [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(list.created_time * 1000))
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "margin-left": "-25px",
                          color: "black",
                        },
                      },
                      [_vm._v(_vm._s(list.content))]
                    ),
                    _c("span", { staticStyle: { "margin-left": "-50px" } }, [
                      _vm._v(
                        "( " + _vm._s(list.admin_name || _vm.data.name) + ") "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "record-time" }),
                ])
              }),
              !_vm.data.log || !_vm.data.log.length
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "min-height": "400px",
                        "line-height": "400px",
                        "text-align": "center",
                        "font-size": "14px",
                        color: "#909399",
                      },
                    },
                    [_vm._v(" 暂无数据 ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _c("span", { staticClass: "buttons" }, [_vm._v("配偶信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }