var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-box" },
    [
      _vm.number === 1
        ? [
            _vm.prefix != ""
              ? _c("div", { staticClass: "input-right" }, [
                  _vm._v(_vm._s(_vm.prefix)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "input-left" }, [
              _c("input", {
                staticClass: "inputStyle",
                class: _vm.disabled ? "disabled" : "",
                style: { width: _vm.inputW + "px" },
                attrs: {
                  onkeyup:
                    "if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                  onafterpaste:
                    "if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                  min: "1",
                  type: "number",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.textValue },
                on: { input: _vm.bindValue },
              }),
            ]),
            _c("div", { staticClass: "input-right" }, [
              _vm._v(_vm._s(_vm.inputText)),
            ]),
          ]
        : [
            _vm.prefix != ""
              ? _c("div", { staticClass: "input-right" }, [
                  _vm._v(_vm._s(_vm.prefix)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "input-left" }, [
              _c("input", {
                staticClass: "inputStyle",
                class: _vm.disabled ? "disabled" : "",
                style: { width: _vm.inputW + "px" },
                attrs: {
                  onkeyup:
                    "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                  onafterpaste:
                    "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                  min: "1",
                  type: "number",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.textValue },
                on: { input: _vm.bindValue },
              }),
            ]),
            _c("div", { staticClass: "input-right" }, [
              _vm._v(_vm._s(_vm.inputText)),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }