var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unit-box" }, [
    _vm.cardType == 0
      ? _c("div", { ref: "wrapper", staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "unit", attrs: { "data-id": _vm.itemDetail.id } },
            [
              _c("div", { staticClass: "unit-title" }, [
                _vm._v(
                  _vm._s(_vm.itemDetail.build_name) +
                    " " +
                    _vm._s(_vm.itemDetail.unit_room)
                ),
              ]),
              _c(
                "div",
                { staticClass: "unit-wrapper" },
                _vm._l(_vm.itemDetail.room, function (floorItem, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "unit-floor" },
                    _vm._l(floorItem, function (room, i2) {
                      return _c(
                        "div",
                        {
                          key: i2,
                          staticClass: "unit-house-num",
                          style: {
                            "--bgc":
                              room.room_status == 1 || room.room_status == 3
                                ? "red"
                                : "rgba(255, 255, 255, 0.2)",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(room.floor_name) +
                              _vm._s(room.no_name) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm.cardType == 1 && _vm.itemDetail.room_max != 0
      ? _c("div", { ref: "wrapper", staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "unit", attrs: { "data-id": _vm.itemDetail.id } },
            [
              _c("div", { staticClass: "unit-title" }, [
                _vm._v(
                  _vm._s(_vm.itemDetail.build_name) +
                    " " +
                    _vm._s(_vm.itemDetail.unit_room)
                ),
              ]),
              _c(
                "div",
                { staticClass: "unit-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "top-tip" },
                    _vm._l(1, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "unit-box-top",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_floor == 1,
                                  expression: "ledConfInfo.show_floor == 1",
                                },
                              ],
                              staticClass: "box-top-cell",
                            },
                            [_vm._v("楼层")]
                          ),
                          _c(
                            "div",
                            { staticClass: "box-top-cell box-room-num" },
                            [_vm._v("房号")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_room_cate == 1,
                                  expression: "ledConfInfo.show_room_cate == 1",
                                },
                              ],
                              staticClass: "box-top-cell",
                            },
                            [_vm._v("户型")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_build_area == 1,
                                  expression:
                                    "ledConfInfo.show_build_area == 1",
                                },
                              ],
                              staticClass: "box-top-cell",
                            },
                            [_vm._v("建筑面积")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_room_area == 1,
                                  expression: "ledConfInfo.show_room_area == 1",
                                },
                              ],
                              staticClass: "box-top-cell",
                            },
                            [_vm._v("套内面积")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.droom_info_pin_an_jie_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_an_jie_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_an_jie_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo
                                      .droom_info_pin_biao_zhun_jia == 1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_biao_zhun_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo
                                      .room_info_pin_biao_zhun_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.droom_info_pin_xian_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_xian_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_xian_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.droom_info_pin_yi_ci_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_yi_ci_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_yi_ci_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo
                                      .droom_info_pin_you_hui_jia == 1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_you_hui_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo
                                      .room_info_pin_you_hui_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.droom_info_pin_yuan_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.droom_info_pin_yuan_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 单(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_yuan_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.zroom_info_pin_an_jie_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_an_jie_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_an_jie_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo
                                      .zroom_info_pin_biao_zhun_jia == 1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_biao_zhun_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo
                                      .room_info_pin_biao_zhun_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.zroom_info_pin_xian_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_xian_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_xian_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.zroom_info_pin_yi_ci_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_yi_ci_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_yi_ci_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo
                                      .zroom_info_pin_you_hui_jia == 1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_you_hui_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo
                                      .room_info_pin_you_hui_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ledConfInfo.zroom_info_pin_yuan_jia ==
                                    1,
                                  expression:
                                    "ledConfInfo.zroom_info_pin_yuan_jia == 1",
                                },
                              ],
                              staticClass: "box-top-cell price-cell",
                            },
                            [
                              _vm._v(
                                " 总(" +
                                  _vm._s(
                                    _vm.projectRoomInfo.room_info_pin_yuan_jia
                                  ) +
                                  ") "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._l(_vm.itemDetail.room, function (floorItem, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "unit-box" },
                      _vm._l(floorItem, function (room, j) {
                        return _c(
                          "div",
                          { key: j, staticClass: "unit-room-detail" },
                          [
                            _c("div", { staticClass: "unit-box-bottom" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ledConfInfo.show_floor == 1,
                                      expression: "ledConfInfo.show_floor == 1",
                                    },
                                  ],
                                  staticClass: "box-bottom-cell room-floor",
                                  style: {
                                    "--color":
                                      room.room_status == 0
                                        ? "#545454"
                                        : room.room_status == -1
                                        ? "#545454"
                                        : room.room_status == 3 ||
                                          room.room_status == 1
                                        ? "red"
                                        : "",
                                    "--fontColor": _vm.fontColor,
                                  },
                                },
                                [_vm._v(" " + _vm._s(room.floor_name) + "层 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "box-bottom-cell room-num box-room-num",
                                  style: {
                                    "--color":
                                      room.room_status == 0
                                        ? _vm.headerColor
                                        : room.room_status == -1
                                        ? "#545454"
                                        : room.room_status == 3 ||
                                          room.room_status == 1
                                        ? "red"
                                        : "",
                                    "--fontColor": _vm.fontColor,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(room.floor_name) +
                                      _vm._s(room.no_name) +
                                      " "
                                  ),
                                ]
                              ),
                              true
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "unit-r-box",
                                      style: {
                                        "--saleOverBg":
                                          _vm.ledConfInfo.show_out == 1
                                            ? room.room_status == 1 ||
                                              room.room_status == 3
                                              ? "red"
                                              : "rgba(255, 255, 255, 0.2)"
                                            : "rgba(255, 255, 255, 0.2)",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .show_room_cate == 1,
                                              expression:
                                                "ledConfInfo.show_room_cate == 1",
                                            },
                                          ],
                                          staticClass: "box-bottom-cell",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(room.room_cate_name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .show_build_area == 1,
                                              expression:
                                                "ledConfInfo.show_build_area == 1",
                                            },
                                          ],
                                          staticClass: "box-bottom-cell",
                                        },
                                        [_vm._v(_vm._s(room.build_area) + "㎡")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .show_room_area == 1,
                                              expression:
                                                "ledConfInfo.show_room_area == 1",
                                            },
                                          ],
                                          staticClass: "box-bottom-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(room.room_area) +
                                              "/㎡"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_an_jie_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_an_jie_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            " ￥" +
                                              _vm._s(
                                                room.pin_an_jie_unit_price
                                              ) +
                                              "/㎡ "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_biao_zhun_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_biao_zhun_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            " ￥" +
                                              _vm._s(room.old_unit_price) +
                                              "/㎡ "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_xian_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_xian_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(room.old_unit_price) +
                                              "/㎡"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_yi_ci_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_yi_ci_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            " ￥" +
                                              _vm._s(room.one_off_unit_price) +
                                              "/㎡ "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_you_hui_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_you_hui_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(room.discount_price) +
                                              "/㎡"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .droom_info_pin_yuan_jia,
                                              expression:
                                                "ledConfInfo.droom_info_pin_yuan_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(room.old_unit_price) +
                                              "/㎡"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_an_jie_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_an_jie_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            " ￥" +
                                              _vm._s(
                                                room.pin_an_jie_total_price
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_biao_zhun_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_biao_zhun_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" + _vm._s(room.old_total_price)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_xian_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_xian_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" + _vm._s(room.old_total_price)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_yi_ci_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_yi_ci_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(room.one_off_total_price)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_you_hui_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_you_hui_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            " ￥" +
                                              _vm._s(
                                                room.discount_total_price
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo
                                                  .zroom_info_pin_yuan_jia,
                                              expression:
                                                "ledConfInfo.zroom_info_pin_yuan_jia",
                                            },
                                          ],
                                          staticClass:
                                            "box-bottom-cell price-cell",
                                        },
                                        [
                                          _vm._v(
                                            "￥" + _vm._s(room.old_total_price)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.ledConfInfo.show_out == 0 &&
                                                (room.room_status == 3 ||
                                                  room.room_status == 1),
                                              expression:
                                                "ledConfInfo.show_out == 0 && (room.room_status == 3 || room.room_status == 1)",
                                            },
                                          ],
                                          staticClass: "sale-over-wrapper",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.projectInfo.pin_gao_qing
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: room.room_status == -1,
                                              expression:
                                                "room.room_status == -1",
                                            },
                                          ],
                                          staticClass: "sale-over-wrapper-copy",
                                        },
                                        [_vm._v("非售")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }