var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isFullscreen
    ? _c(
        "div",
        { staticClass: "scree-box", on: { click: _vm.click } },
        [
          _c("svg-icon", {
            attrs: {
              "class-name": "full",
              "icon-class": _vm.isFullscreen ? "exit-fullscreen" : "fullscreen",
            },
          }),
          _vm.isShowText
            ? _c("span", [
                _vm._v(_vm._s(_vm.isFullscreen ? "退出全屏" : "全屏")),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }