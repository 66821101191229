var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-btn",
      on: {
        click: function ($event) {
          return _vm.$emit("btnChange")
        },
      },
    },
    [
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconType == "ADD",
            expression: "iconType == 'ADD'",
          },
        ],
        staticClass: "iconfont iconjiahao",
      }),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconType == "REDUCE",
            expression: "iconType == 'REDUCE'",
          },
        ],
        staticClass: "iconfont iconjian",
      }),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconType == "UP",
            expression: "iconType == 'UP'",
          },
        ],
        staticClass: "iconfont iconshuzhishangsheng up-down",
      }),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconType == "DOWN",
            expression: "iconType == 'DOWN'",
          },
        ],
        staticClass: "iconfont iconshuzhixiajiang up-down",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }