var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "item-num" }, [_vm._v(_vm._s(_vm.num))]),
    _c("div", { staticClass: "itme-text" }, [
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }