var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-fixed" }, [
      _c(
        "div",
        { staticClass: "title-top-box" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("编辑摇号规则")]),
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleSaveInfo },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "detail-box mT16" }, [
      _c("div", { staticClass: "block" }, [
        _c("div", { staticClass: "tips-box" }, [
          _c("div", { staticClass: "tips" }, [
            _c("div", { staticClass: "tips-top flex align-items" }, [
              _c("span", { staticClass: "buttonStyle" }, [
                _vm._v(_vm._s(_vm.getButtonText(_vm.event.type))),
              ]),
              _c("span", { staticClass: "mL10 tips-title" }, [
                _vm._v(_vm._s(_vm.event.name)),
              ]),
            ]),
            _c("div", { staticClass: "tips-bottom" }, [
              _vm.event.event_status === 0
                ? _c("span", { staticClass: "status" }, [_vm._v("未开始")])
                : _vm._e(),
              _vm.event.event_status === 1
                ? _c("span", { staticClass: "status" }, [_vm._v("进行中")])
                : _vm._e(),
              _vm.event.event_status === 2
                ? _c("span", { staticClass: "status" }, [_vm._v("已结束")])
                : _vm._e(),
              _vm.event.round === 1
                ? _c("span", { staticClass: "mL10 tips-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.event.round_start_time * 1000
                          )
                        ) +
                        " ~ " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.event.round_end_time * 1000)
                        )
                    ),
                  ])
                : _c("span", { staticClass: "mL10 tips-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(_vm.event.start_time * 1000)
                        ) +
                        " ~ " +
                        _vm._s(_vm._f("dateFormat")(_vm.event.end_time * 1000))
                    ),
                  ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "from-box" },
          [
            _c(
              "el-form",
              { attrs: { "label-width": "150px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号名称：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            "show-word-limit": "",
                            maxlength: "20",
                            placeholder: "将显示在摇号大屏上",
                          },
                          model: {
                            value: _vm.rand.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.rand, "name", $$v)
                            },
                            expression: "rand.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "模式：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type", $$v)
                              },
                              expression: "rand.type",
                            },
                          },
                          [_vm._v("随机摇号")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            on: { change: _vm.handleNumber },
                            model: {
                              value: _vm.rand.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type", $$v)
                              },
                              expression: "rand.type",
                            },
                          },
                          [_vm._v("按序排号")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "分组：" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.rand.type_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_group", $$v)
                              },
                              expression: "rand.type_group",
                            },
                          },
                          [_vm._v("根据客户分组 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.rand.type_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.rand, "type_group", $$v)
                              },
                              expression: "rand.type_group",
                            },
                          },
                          [_vm._v("不分组")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rand.type === 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "排号方式：" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.rand.type_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "type_code", $$v)
                                  },
                                  expression: "rand.type_code",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$adminList.user_code_name))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 2 },
                                model: {
                                  value: _vm.rand.type_code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.rand, "type_code", $$v)
                                  },
                                  expression: "rand.type_code",
                                },
                              },
                              [_vm._v("签到顺序")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rand.type_group === 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "等级组别顺序：" } },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              border: "",
                              "highlight-current-row": "",
                              size: "medium",
                              data: _vm.rand.level_sort,
                              height: 300,
                              "header-cell-style": { background: "#fafafa" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "index",
                                label: "排序",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "level",
                                label: "客户分类",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.level === ""
                                                ? "无分组"
                                                : scope.row.level
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1141317852
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "level_count",
                                label: "客户数",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "操作",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-button", {
                                          staticClass: "more-btn",
                                          attrs: {
                                            size: "mini",
                                            circle: "",
                                            icon: "el-icon-top",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleTop(scope.$index)
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          staticClass: "gift-btn",
                                          attrs: {
                                            size: "mini",
                                            circle: "",
                                            icon: "el-icon-bottom",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleBottom(
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2067094237
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("el-form-item", { attrs: { label: "轮次人数：" } }, [
                  _c(
                    "div",
                    { staticClass: "number" },
                    [
                      _c("span", [_vm._v("每轮")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.rand.rand_count,
                            callback: function ($$v) {
                              _vm.$set(_vm.rand, "rand_count", $$v)
                            },
                            expression: "rand.rand_count",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "1", value: 1 } }),
                          _c("el-option", { attrs: { label: "5", value: 5 } }),
                          _c("el-option", {
                            attrs: { label: "10", value: 10 },
                          }),
                          _c("el-option", {
                            attrs: { label: "20", value: 20 },
                          }),
                          _c("el-option", {
                            attrs: { label: "30", value: 30 },
                          }),
                          _c("el-option", {
                            attrs: { label: "40", value: 40 },
                          }),
                          _c("el-option", {
                            attrs: { label: "50", value: 50 },
                          }),
                        ],
                        1
                      ),
                      _c("span", [_vm._v("人")]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  { staticClass: "jumpPeon", attrs: { label: "到号显示：" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-name",
                        attrs: { span: 24 },
                      },
                      [_vm._v("1.显示元素")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 20, offset: 1 } },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              disabled: "",
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_rand_code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_rand_code",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_rand_code",
                                            },
                                          },
                                          [_vm._v(" 顺序号 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_name",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_name",
                                            },
                                          },
                                          [_vm._v(" 姓名 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_tel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_tel",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_tel",
                                            },
                                          },
                                          [_vm._v(" 登录手机号 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_audit_order,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_audit_order",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "rand.show_audit_order",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$adminList.user_code_name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.rand.show_card,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "show_card",
                                                  $$v
                                                )
                                              },
                                              expression: "rand.show_card",
                                            },
                                          },
                                          [_vm._v(" 身份证号 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "input-box input-name",
                        attrs: { span: 24 },
                      },
                      [_vm._v("2.顺序号生成规则")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c(
                          "el-row",
                          { staticClass: "mT15", attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { staticClass: "input-box", attrs: { span: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "input-name",
                                    attrs: { span: 1 },
                                  },
                                  [_vm._v("轮：")]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 23 } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: { change: _vm.handleRound },
                                        model: {
                                          value: _vm.rand.type_round_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.rand,
                                              "type_round_code",
                                              $$v
                                            )
                                          },
                                          expression: "rand.type_round_code",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("号码继承上一轮")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [_vm._v("不继承上一轮")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.rand.type === 1
                          ? _c(
                              "el-row",
                              { staticClass: "mT15", attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "input-box",
                                    attrs: { span: 20 },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "input-name",
                                        attrs: { span: 1 },
                                      },
                                      [_vm._v("组：")]
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 23 } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticStyle: { width: "100%" },
                                            model: {
                                              value: _vm.rand.type_group_code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.rand,
                                                  "type_group_code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "rand.type_group_code",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 20 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          label: 1,
                                                        },
                                                      },
                                                      [_vm._v("号码继承上一组")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          label: 0,
                                                        },
                                                      },
                                                      [_vm._v("不继承上一组")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "摇号客户：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.rand.type_rand_user,
                          callback: function ($$v) {
                            _vm.$set(_vm.rand, "type_rand_user", $$v)
                          },
                          expression: "rand.type_rand_user",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("全部客户"),
                            ]),
                          ],
                          1
                        ),
                        _vm.list.sign_in === 1
                          ? [
                              _vm.list.sign_type === 1
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("签到客户"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }