import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import vanSwitch from '/src/components/switch';
import Api from '../../../api/heat';
import { adminParameter } from '@/api/public';
import { mapGetters } from 'vuex';
import utils from '@/utils/utils';
import UploadExcel from '@/components/my-UploadExcel/index.vue';
export default {
  name: 'Heat',
  components: {
    UploadExcel: UploadExcel,
    vanSwitch: vanSwitch
  },
  data: function data() {
    return {
      isTips: false,
      // 楼栋数组
      buildArray: [],
      // 被选中的楼栋id
      buildId: '',
      // 住宅区 楼层 数组
      floorList: [],
      // 住宅区 单元，房间
      roomData: [],
      // 别墅区 房源数据
      roomVilla: [],
      // 干预热度弹框
      is_heat: false,
      // 页面高度
      appHeight: 0,
      // 非滑动区域
      topHeight: 0,
      // 干预热度
      heat: 1,
      // 住宅别墅切换
      tabType: 0,
      // 干预热度 选中id
      heatId: [],
      // 楼栋id
      build_id: 0,
      is_defect: false,
      msg: '',
      //  车位楼层数据
      s_unitList: [],
      //  车位 楼层id
      s_unit_id: 0,
      // 已选条数
      numbers: 0,
      switchList: [],
      name: '项目名称'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['customSetting', 'roles'])), {}, {
    divHeight: function divHeight() {
      return this.appHeight - this.topHeight - 100;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    var _this2 = this;

    adminParameter().then(function (res) {
      _this2.cate_room = res.data.list.cate_room;
      _this2.cate_car = res.data.list.cate_car;
      _this2.name = res.data.list.name;

      if (res.data.list.cate_room === 1 && res.data.list.cate_car === 1) {
        _this2.switchList = [{
          title: '房源',
          id: 0,
          num: ''
        }, {
          title: '车位',
          id: 1,
          num: ''
        }];
        _this2.tabType = 0;
      } else if (res.data.list.cate_room === 1) {
        _this2.switchList = [{
          title: '房源',
          id: 0,
          num: ''
        }];
        _this2.tabType = 0;
      } else if (res.data.list.cate_car === 1) {
        _this2.switchList = [{
          title: '车位',
          id: 1,
          num: ''
        }];
        _this2.tabType = 1;
      } // 获取楼栋


      if (_this2.roles.includes('roomhot/roomlist') || _this2.roles.includes('admin')) {
        _this2.getBuildList(res.data.list.cate_room === 1 ? 1 : 2);
      }
    });
  },
  methods: {
    // 刷新
    getRefresh: function getRefresh() {
      if (this.tabType === 1) {
        // 别墅
        if (this.roles.includes('roomhot/villalist') || this.roles.includes('admin')) {
          this.carList();
        }
      } else {
        // 住宅
        if (this.roles.includes('roomhot/roomlist') || this.roles.includes('admin')) {
          this.getRoomList(this.build_id);
        }
      }
    },
    // 提交干预id 干预热度
    handleSaveHeat: function handleSaveHeat() {
      var _this3 = this;

      Api.intervene({
        ids: this.getNumbers(),
        hot_count: this.heat
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          if (_this3.tabType === 1) {
            // 别墅
            _this3.carList();
          } else {
            // 住宅
            _this3.getRoomList(_this3.build_id);
          }

          _this3.is_heat = false;
        }
      });
    },
    //  获取 楼栋id
    bindSwitchBuild: function bindSwitchBuild(e) {
      this.build_id = e;

      if (this.roles.includes('roomhot/roomlist') || this.roles.includes('admin')) {
        this.getRoomList(e);
      }

      this.number = 0;
    },
    // 获取楼栋
    getBuildList: function getBuildList(type) {
      var _this4 = this;

      Api.buildList({
        cate: type
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              build = _res$data.build,
              is_defect = _res$data.is_defect,
              msg = _res$data.msg;
          _this4.is_defect = is_defect;
          _this4.msg = msg;
          _this4.buildArray = [];

          if (build != null && build.length > 0) {
            // 楼栋数据
            _this4.buildArray = build; //	默认选中第一个

            _this4.buildId = build[0].id;
            _this4.build_id = build[0].id;

            if (_this4.roles.includes('roomhot/roomlist') || _this4.roles.includes('admin')) {
              if (_this4.tabType == 0) {
                // 房源
                _this4.getRoomList(build[0].id);
              } else {
                // 车位楼栋id
                _this4.buildId = build[0].id; // 车位楼层数组

                _this4.s_unitList = build[0].unit; // 车位楼层id

                _this4.s_unit_id = build[0].unit[0].id;

                _this4.carList();
              }
            }
          }
        }
      });
    },
    // 获取住宅列表
    getRoomList: function getRoomList(id) {
      var _this5 = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api.roomList({
        build_id: id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$info = res.data.info,
              unit = _res$data$info.unit,
              floor = _res$data$info.floor;

          if (unit != null && unit.length > 0) {
            // 房间数据
            _this5.roomData = unit; // 楼层

            _this5.floorList = floor;
            loading.close();
          }
        }
      }).catch(function (err) {
        loading.close();
      });
    },
    // 获取车位列表
    carList: function carList() {
      var _this6 = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        unit_id: this.s_unit_id,
        build_id: this.buildId
      };
      Api.villaList(params).then(function (res) {
        if (res.status === 200) {
          var area = res.data.area;

          if (area != null && area.length > 0) {
            _this6.roomVilla = area;
          }
        }

        loading.close();
      });
    },
    // 车位楼栋
    carBuildClick: function carBuildClick(item, id) {
      var _this7 = this;

      this.s_unitList = [];
      item && item.forEach(function (element) {
        if (element.id == id) {
          _this7.buildId = id;
          _this7.s_unit_id = element.unit[0].id;
          _this7.s_unitList = element.unit;

          _this7.carList();
        }
      });
      this.numbers = 0;
    },
    // 车位楼层
    carUnitClick: function carUnitClick(item, id) {
      // item && item.forEach(element => {
      //     if (element.id == id) {
      this.s_unit_id = id;
      this.numbers = 0;
      this.carList(); //     }
      // })
    },
    // 获取别墅列表
    getVillaList: function getVillaList() {
      var _this8 = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Api.villaList().then(function (res) {
        if (res.status === 200) {
          var area = res.data.area;

          if (area != null && area.length > 0) {
            _this8.roomVilla = area;
          }

          loading.close();
        }
      });
    },
    handlePerfect: function handlePerfect() {
      if (this.tabType === 0) {
        this.$router.push({
          name: 'House'
        });
      } else {
        this.$router.push({
          name: 'Building'
        });
      }
    },
    // 获取 干预id
    postIdArray: function postIdArray() {
      var _this9 = this;

      var cancel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'intervene';

      //  住宅区
      if (this.getNumbers().length <= 0) {
        var msg = this.tabType == 0 ? '先选择要干预的房源' : '先选择要干预的车位';
        this.$message({
          type: 'warning',
          message: msg
        });
        return;
      } // 不等于取消 干预


      if (cancel !== 'cancel') {
        this.is_heat = true;
      } else {
        Api.cancelHot({
          ids: this.getNumbers()
        }).then(function (res) {
          if (res.status === 200) {
            _this9.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this9.tabType === 1) {
              // 别墅
              _this9.carList();
            } else {
              // 住宅
              _this9.getRoomList(_this9.build_id);
            }
          }
        });
      }
    },
    // 下载房源模板
    downloadRoomTem: function downloadRoomTem() {
      var url = this.host + "/template/collect/\u4E91\u9500\u63A7-\u610F\u5411\u5BFC\u5165\u6A21\u677F(\u4F4F\u5B85).xlsx";
      this.down(url, "".concat(this.name, "-\u610F\u5411\u5BFC\u5165(\u623F\u6E90)-").concat(new Date().getTime(), ".xlsx"));
    },
    // 下载车位模板
    downloadCarTem: function downloadCarTem() {
      var url = this.host + '/template/collect/云销控-意向导入模板(车位).xlsx';
      this.down(url, "".concat(this.name, "-\u610F\u5411\u5BFC\u5165(\u8F66\u4F4D)-").concat(new Date().getTime(), ".xlsx"));
    },
    // 下载文件
    down: function down(url, name) {
      utils.download_files(url, name);
    },
    exportCheck: function exportCheck() {
      this.isTips = true;
    },
    closeExport: function closeExport() {
      this.isTips = false;
    },
    importData: function importData() {
      Api.exportCancelHot().then(function (res) {
        var _res$data2 = res.data,
            data1 = _res$data2.data1,
            name1 = _res$data2.name1,
            suffix1 = _res$data2.suffix1;
        utils.download_files_base64(data1, name1, suffix1);
      });
    },
    // 点击选中 住宅 房间
    handleClickRoom: function handleClickRoom(index, inx, i, not_status) {
      if (not_status != -1) {
        this.roomData[index].floor[inx].room[i].is_show = !this.roomData[index].floor[inx].room[i].is_show;
        this.getNumbers();
      }
    },
    // 点击选中 别墅 房间
    handleClickVilla: function handleClickVilla(index, i, not_status) {
      if (not_status != -1) {
        this.roomVilla[index].room[i].is_show = !this.roomVilla[index].room[i].is_show;
        this.getNumbers();
      }
    },
    // 计算选中个数
    getNumbers: function getNumbers() {
      //  住宅区
      var idArray = [];

      if (this.tabType === 0) {
        this.roomData.map(function (item) {
          item.floor.map(function (info) {
            info.room.map(function (data) {
              if (data.is_show) {
                idArray.push(data.id);
              }
            });
          });
        });
      } else {
        //  别墅区
        this.roomVilla.map(function (item) {
          item.room.map(function (info) {
            if (info.is_show) {
              idArray.push(info.id);
            }
          });
        });
      }

      this.numbers = idArray.length;
      return idArray;
    },
    // 获取状态 返回颜色
    getColor: function getColor(type, not_status) {
      var color = null;

      switch (not_status) {
        case -1:
          color = 'gray';
          break;

        default:
          switch (type) {
            case 0:
              color = '';
              break;

            case 1:
              color = 'green';
              break;

            case 2:
              color = 'blue';
              break;

            case 3:
              color = 'orange';
              break;

            case 4:
              color = 'red';
              break;
          }

          break;
      }

      return color;
    },
    // 住宅，别墅 切换
    bindSwitch: function bindSwitch(e) {
      this.tabType = e;

      if (e === 0) {
        // 获取楼栋
        if (this.roles.includes('roomhot/roomlist') || this.roles.includes('admin')) {
          this.getBuildList(1);
        }
      } else {
        // 获取别墅
        if (this.roles.includes('roomhot/roomlist') || this.roles.includes('admin')) {
          this.getBuildList(2);
        }
      }

      this.numbers = 0;
    }
  }
};