//
//
//
//
//
//
// import  websocket  from '@/utils/screenSocket'
export default {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  mounted: function mounted() {
    var _this = this;

    var setFontSize = function setFontSize() {
      _this.$store.commit('base/SET_IS_MOBILE', document.documentElement.clientWidth <= 470);
    };

    setFontSize();
    window.addEventListener('resize', setFontSize);
  },
  methods: {
    reload: function reload() {
      var _this2 = this;

      this.isRouterAlive = false;
      this.$nextTick(function () {
        _this2.isRouterAlive = true;
      });
    }
  }
};