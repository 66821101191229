import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AuditIde0, AuditIde1, AuditIde2, exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, iedAuditStatus0, iedAuditStatus1, iedAuditStatus2 } from '@/api/sincerityRegister';
import moment from 'moment';
import { adminParameter } from '@/api/public';
import userRegister from '../components/user-register.vue'; // import { mapGetters } from 'vuex'

import toolReviewMixin from '../toolReviewMixin';
import switchBtn from './components/switchBtn';
export default {
  name: 'ReviewDetails',
  components: {
    userRegister: userRegister,
    switchBtn: switchBtn
  },
  mixins: [toolReviewMixin],
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: '0',
      // 审核内容
      statusInfo: [],
      // 审核信息
      auditInfo: [],
      index: 1,
      info: {},
      //  是否显示填写附表按钮
      is_button: true,
      // 是否填写附表
      showChangeSchedule: false,
      // 打开弹窗
      isTips: false,
      // 驳回原因
      textarea: '',
      // 上次驳回原因
      isReason: false,
      // 判断 审核方式
      types: 'ide1',
      // 驳回原因数据
      turnInfo: '',
      // 顶部div高度
      divHeight: 0,
      // 顶部button 判断
      intoInfo: {},
      // 日志info
      infoTips: [],
      checked: true,
      // saasSetting
      saasSetting: {
        audit: {}
      } // // 审核附表图片
      // attach_img_arr: [],
      // // 客户登记
      // img_arr: []

    };
  },
  created: function created() {
    this.getDataInfo();
  },
  // computed: {
  //   ...mapGetters(['toolReviewRow'])
  // },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.divHeight = _this.$refs.topDiv.offsetHeight;
    });
  },
  methods: {
    // 打开驳回弹窗
    handleShowDialog: function handleShowDialog(type) {
      this.retrunType = type;
      this.isTips = true;
    },
    // 确定驳回
    handleChange: function handleChange() {
      this.handleTurnDown(this.retrunType);
    },
    // 获取页面数据
    getDataInfo: function getDataInfo() {
      var _this2 = this;

      var row = this.toolReviewRow;
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var audit = res.data.audit;
          _this2.saasSetting = res.data;

          if (_this2.statusType === 1) {
            _this2.getSignUp(row.reg_types, audit, row.reg_audit_id);

            _this2.types = row.reg_types;
          } else {
            _this2.getIdeInfo(row.ide_types, audit, row.ide_audit_id);

            _this2.types = row.ide_types;
          }
        }
      });
    },
    getSignUp: function getSignUp(types, audit, id) {
      var _this3 = this;

      if (types === 'status0') {
        exportAuditStatus0({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data = res.data,
                auditInfo = _res$data.auditInfo,
                info = _res$data.info;
            _this3.statusInfo = _this3.getInfoData(info.dataList);
            _this3.auditInfo = auditInfo;
            _this3.info = info.logList;
            _this3.turnInfo = info.turnInfo;
            _this3.intoInfo = info.intoInfo;
            _this3.adminInfo = info.adminInfo;
            _this3.createInfo = info.createInfo;
            _this3.is_button = _this3.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this3.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this3.getList();

            _this3.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1) {
              _this3.showChangeSchedule = true;
            }
          }
        });
      } else if (types === 'status1') {
        exportAuditStatus1({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data2 = res.data,
                auditInfo = _res$data2.auditInfo,
                info = _res$data2.info;
            _this3.statusInfo = _this3.getInfoData(info.dataList);
            _this3.auditInfo = auditInfo;
            _this3.info = info.logList;
            _this3.turnInfo = info.turnInfo;
            _this3.intoInfo = info.intoInfo;
            _this3.createInfo = info.createInfo;
            _this3.adminInfo = info.adminInfo;
            _this3.is_button = _this3.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this3.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this3.getList();

            _this3.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1 && audit.register_schedule_admin == 1) {
              _this3.showChangeSchedule = true;
            }
          }
        });
      } else if (types === 'status2') {
        exportAuditStatus2({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data3 = res.data,
                auditInfo = _res$data3.auditInfo,
                info = _res$data3.info;
            _this3.statusInfo = _this3.getInfoData(info.dataList);
            _this3.auditInfo = auditInfo;
            _this3.info = info.logList;
            _this3.turnInfo = info.turnInfo;
            _this3.intoInfo = info.intoInfo;
            _this3.createInfo = info.createInfo;
            _this3.userInfo = info.userInfo;
            _this3.adminInfo = info.adminInfo;
            _this3.is_button = _this3.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this3.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this3.getList();

            _this3.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1 && audit.register_schedule_admin == 2) {
              _this3.showChangeSchedule = true;
            } else if (audit.register_schedule_admin === 1) {
              _this3.showChangeSchedule = true;
            }
          }
        });
      }
    },
    getIdeInfo: function getIdeInfo(types, audit, id) {
      var _this4 = this;

      if (types === 'ide0') {
        iedAuditStatus0({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data4 = res.data,
                auditInfo = _res$data4.auditInfo,
                info = _res$data4.info;
            _this4.statusInfo = _this4.getInfoData(info.dataList);
            _this4.auditInfo = auditInfo;
            _this4.info = info.logList;
            _this4.turnInfo = info.turnInfo;
            _this4.intoInfo = info.intoInfo;
            _this4.adminInfo = info.adminInfo;
            _this4.createInfo = info.createInfo;
            _this4.is_button = _this4.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this4.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this4.getList();

            _this4.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1) {
              _this4.showChangeSchedule = true;
            }
          }
        });
      } else if (types === 'ide1') {
        iedAuditStatus1({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data5 = res.data,
                auditInfo = _res$data5.auditInfo,
                info = _res$data5.info;
            _this4.statusInfo = _this4.getInfoData(info.dataList);
            _this4.auditInfo = auditInfo;
            _this4.info = info.logList;
            _this4.turnInfo = info.turnInfo;
            _this4.intoInfo = info.intoInfo;
            _this4.createInfo = info.createInfo;
            _this4.adminInfo = info.adminInfo;
            _this4.is_button = _this4.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this4.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this4.getList();

            _this4.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1 && audit.register_schedule_admin == 1) {
              _this4.showChangeSchedule = true;
            }
          }
        });
      } else if (types === 'ide2') {
        iedAuditStatus2({
          id: id
        }).then(function (res) {
          if (res.status === 200) {
            var _res$data6 = res.data,
                auditInfo = _res$data6.auditInfo,
                info = _res$data6.info;
            _this4.statusInfo = _this4.getInfoData(info.dataList);
            _this4.auditInfo = auditInfo;
            _this4.info = info.logList;
            _this4.turnInfo = info.turnInfo;
            _this4.intoInfo = info.intoInfo;
            _this4.createInfo = info.createInfo;
            _this4.userInfo = info.userInfo;
            _this4.adminInfo = info.adminInfo;
            _this4.is_button = _this4.getScheduleType(info.dataList); // 客户登记表 审核附表 图片

            _this4.CHANGE_ALL_IMG([info.attach_img_arr, info.img_arr]);

            _this4.getList();

            _this4.doShowParent(); // 判断是否显示填写附表


            if (audit.register_schedule == 1 && audit.register_schedule_admin == 2) {
              _this4.showChangeSchedule = true;
            } else if (audit.register_schedule_admin === 1) {
              _this4.showChangeSchedule = true;
            }
          }
        });
      }
    },
    // 获取
    getList: function getList() {
      var infoTips = [];
      var status = this.auditInfo.status;
      var types = this.row.types;
      var createInfo = this.createInfo;
      var userInfo = this.userInfo;
      var adminInfo = this.adminInfo;
      var intoInfo = this.intoInfo; // 待审核

      if (status === 1) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: this.getTel(createInfo.tel)
        }]];
      } // 已驳回、已通过


      if (status === -2 || status === 2) {
        infoTips = [[{
          name: '客户提交时间',
          value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '客户姓名',
          value: createInfo.name
        }, {
          name: '登录手机号',
          value: this.getTel(createInfo.tel)
        }], [{
          name: '审核时间',
          value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
        }, {
          name: '审核人',
          value: adminInfo.name
        }]];
      } // 复审


      if (types === 'ide2') {
        // 待审核
        if (status === 1) {
          // infoTips = [
          //   [
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '登录手机号', value: this.getTel(userInfo.tel) }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '登录手机号',
            value: this.getTel(createInfo.tel)
          }]];
        } // 已驳回、已通过


        if (status === -2 || status === 2) {
          // infoTips = [
          //   [
          //     { name: '客户姓名', value: userInfo.name },
          //     { name: '客户提交时间', value: moment(userInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '登录手机号', value: this.getTel(userInfo.tel) }
          //   ],
          //   [
          //     { name: '初审通过时间', value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: createInfo.name }
          //   ],
          //   [
          //     { name: '复审审核时间', value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm') },
          //     { name: '审核人', value: adminInfo.name }
          //   ]
          // ]
          infoTips = [[{
            name: '客户姓名',
            value: createInfo.name
          }, {
            name: '客户提交时间',
            value: moment(createInfo.created_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '登录手机号',
            value: this.getTel(createInfo.tel)
          }], [{
            name: '审核时间',
            value: moment(adminInfo.audit_at * 1000).format('YYYY-MM-DD HH:mm')
          }, {
            name: '审核人',
            value: adminInfo.name
          }]];
        }
      }

      if (this.intoInfo != undefined) {
        if (this.intoInfo.is_into) {
          infoTips.push([// { name: '转入开盘客户时间', value: moment(intoInfo.into_time * 1000).format('YYYY-MM-DD HH:mm') },
          {
            name: '操作人',
            value: intoInfo.name
          }]);
        }
      }

      this.infoTips = infoTips;
    },
    // 处理数据
    getInfoData: function getInfoData(data) {
      data.forEach(function (item) {
        item.typeNumber = 0;
        item.typeUserNumber = 0; // 其他类型

        if (item.type === 0) {
          item.forms.forEach(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }

            if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                // 婚姻状态
                if (forms.owner_type === 6) {
                  var index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });
                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[index].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  }); //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 9 && (forms.children[_index].name === '全款' || forms.children[_index].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 12 && forms.children[_index2].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                }
              }
            }
          });
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? 'owner' : 'bank';
          item[ownerBankKey + 's'].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }
            });
          });
        }
      });
      return data;
    },
    // 查看驳回原因
    checkReason: function checkReason() {
      this.isReason = true;
    },
    // 判断是否出现填写附表
    getScheduleType: function getScheduleType(data) {
      var type = true;
      data.forEach(function (item) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.fill_in_type === 2) {
              if (forms.normal_result.value !== '') {
                type = false;
              }
            }
          }); //  产权人
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? 'owner' : 'bank';
          item[ownerBankKey + 's'].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                if (forms[ownerBankKey + '_result'].value !== '') {
                  type = false;
                }
              }
            });
          });
        }
      });
      return true;
    },
    tabClick: function tabClick(tab) {
      this.CHANGE_BTN_TYPE(tab);

      if (tab == 0) {
        this.index = 1;
      } else if (tab == 1) {
        this.index = 2;
      }

      this.getInfoData(this.statusInfo);
    },
    // 跳转去填写附表
    handleSchedule: function handleSchedule() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(0); // this.$router.push({
      //   name: 'Schedule',
      //   query: { id: this.auditInfo.id, status: this.row.types }
      // })
    },
    // 修改登记变信息
    handleEdit: function handleEdit() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      row.index = this.index;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(2); // this.$router.push({
      //   name: 'EditReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.row.types, types: this.index },
      // })
    },
    // 驳回审核,提交审核
    handleTurnDown: function handleTurnDown(type) {
      var _this5 = this;

      var types = this.toolReviewRow.types;

      if (this.textarea === '' && (type === 'return' || type === 'refuse' || type === 'repulse')) {
        this.$message({
          type: 'error',
          message: '驳回原因必填'
        });
        return; // this.postSave(type)
      } else if (this.textarea !== '' && type === 'return') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'refuse') {
        this.postSave(type);
      } else if (this.textarea !== '' && type === 'repulse') {
        this.postSave(type);
      } else {
        var text = types === 'ide1' ? '是否确认通过审核？' : '是否确认通过审核？';
        this.$confirm(text, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.postSave(type);
        }).catch(function () {});
      }
    },
    postSave: function postSave(type) {
      var _this6 = this;

      var data = {
        id: parseInt(this.toolReviewRow.id),
        type: type,
        err_note: this.textarea,
        next: this.checked ? 1 : 0
      };
      var types = this.toolReviewRow.types;

      if (types === 'ide0') {
        AuditIde0(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this6.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this6.checked && JSON.stringify(next) !== '{}') {
              var row = _this6.toolReviewRow;
              row.id = next.id;

              _this6.CHANGE_ROW(row);

              _this6.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this6.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this6.checked && _this6.type === 'commit') {
                setTimeout(function () {
                  _this6.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this6.isTips = false;

            _this6.getDataInfo(); // 刷新列表页面


            _this6.$emit('handleRefresh');
          }
        });
      } else if (types === 'ide1') {
        AuditIde1(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this6.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this6.checked && JSON.stringify(next) !== '{}') {
              var row = _this6.toolReviewRow;
              row.id = next.id;

              _this6.CHANGE_ROW(row);

              _this6.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this6.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this6.checked && _this6.type === 'commit') {
                setTimeout(function () {
                  _this6.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this6.isTips = false;

            _this6.getDataInfo(); // 刷新列表页面


            _this6.$emit('handleRefresh');
          }
        });
      } else if (types === 'ide2') {
        AuditIde2(data).then(function (res) {
          if (res.status === 200) {
            var next = res.data.next;

            _this6.$message({
              type: 'success',
              message: res.data.msg
            });

            if (_this6.checked && JSON.stringify(next) !== '{}') {
              var row = _this6.toolReviewRow;
              row.id = next.id;

              _this6.CHANGE_ROW(row);

              _this6.CHANGE_DRAWER_TYPE(3);

              setTimeout(function () {
                _this6.CHANGE_DRAWER_TYPE(1);
              }, 300); // this.$router.push({
              //   name: 'ReviewDetails',
              //   query: { id: next.id, status: next.types },
              // })
            } else {
              if (_this6.checked && _this6.type === 'commit') {
                setTimeout(function () {
                  _this6.$message({
                    type: 'warning',
                    message: '已经是最后一条了'
                  });
                }, 1000);
              }
            }

            _this6.isTips = false;

            _this6.getDataInfo(); // 刷新列表页面


            _this6.$emit('handleRefresh');
          }
        });
      }
    },

    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param ownerBankIndex 产权人下标
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, ownerBankIndex) {
      var _this7 = this;

      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));

        if (ownerBankIndex || ownerBankIndex === 0) {
          for (var i = 0; i < forms.length; i++) {
            // 产权人或者银行资料
            if (forms[i].type === 1) {
              // 1为产权人 2为银行资料
              var ownerBankKey = forms[i].type == 1 ? 'owner' : 'bank';

              for (var a = 0; a < forms[i][ownerBankKey + 's'][ownerBankIndex].forms.length; a++) {
                var item = forms[i][ownerBankKey + 's'][ownerBankIndex].forms[a];

                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];

                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(',');
                  }

                  var parent_cause_show = false;

                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  } // 隐藏时清空之前的值


                  if (!parent_cause_show) {
                    item.normal_result.value = '';
                    item[ownerBankKey + '_result'].value = '';
                    item.normal_result.value2 = '';
                    item[ownerBankKey + '_result'].value2 = '';
                  }

                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }

                forms[i][ownerBankKey + 's'][ownerBankIndex].forms[a] = item;
              }
            }
          }
        } else {
          for (var _i = 0; _i < forms.length; _i++) {
            // 产权人或者银行资料
            if (forms[_i].type === 1 || forms[_i].type === 2) {
              // 1为产权人 2为银行资料
              var _ownerBankKey = forms[_i].type == 1 ? 'owner' : 'bank';

              for (var l = 0; l < forms[_i][_ownerBankKey + 's'].length; l++) {
                for (var _a = 0; _a < forms[_i][_ownerBankKey + 's'][l].forms.length; _a++) {
                  var _item2 = forms[_i][_ownerBankKey + 's'][l].forms[_a];

                  if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                    var _parent_ids = [];

                    if (_item2.parent_id) {
                      _parent_ids = _item2.parent_id.split(',');
                    }

                    var _parent_cause_show = false;

                    for (var _c = 0; _c < _parent_ids.length; _c++) {
                      if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                        _parent_cause_show = true;
                      }
                    } // 隐藏时清空之前的值


                    if (!_parent_cause_show) {
                      _item2.normal_result.value = '';
                      _item2[_ownerBankKey + '_result'].value = '';
                      _item2.normal_result.value2 = '';
                      _item2[_ownerBankKey + '_result'].value2 = '';
                    }

                    _item2.parent_cause_show = _parent_cause_show;
                  } else if (_item2.parent_pid === 0) {
                    _item2.parent_cause_show = true;
                  }

                  forms[_i][_ownerBankKey + 's'][l].forms[_a] = _item2;
                }
              }
            } // 其他类型
            else {
              for (var _a2 = 0; _a2 < forms[_i].forms.length; _a2++) {
                var _item3 = forms[_i].forms[_a2];

                if (parent_id === _item3.parent_pid && _item3.parent_pid !== 0) {
                  var _parent_ids2 = [];

                  if (_item3.parent_id) {
                    _parent_ids2 = _item3.parent_id.split(',');
                  }

                  var _parent_cause_show2 = false;

                  for (var _c2 = 0; _c2 < _parent_ids2.length; _c2++) {
                    if (parent_values.indexOf(_parent_ids2[_c2]) >= 0) {
                      _parent_cause_show2 = true;
                    }
                  } // 隐藏时清空之前的值


                  if (!_parent_cause_show2) {
                    _item3.normal_result.value = '';
                    _item3.normal_result.value2 = '';
                  }

                  _item3.parent_cause_show = _parent_cause_show2;
                } else if (_item3.parent_pid === 0) {
                  _item3.parent_cause_show = true;
                }

                forms[_i].forms[_a2] = _item3;
              }
            }
          }
        }

        this.statusInfo = JSON.parse(JSON.stringify(forms));
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];

              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }

                _this7.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }

                _this7.doShowParent(true, _item.id, selectId);
              }
            });
          } else if (item.type == 1) {
            // 1为产权人 2为银行资料
            var _ownerBankKey2 = item.type == 1 ? 'owner' : 'bank'; // 产权人或者银行资料，多个


            for (var _l = 0; _l < item[_ownerBankKey2 + 's'].length; _l++) {
              // 问题
              for (var _a3 = 0; _a3 < item[_ownerBankKey2 + 's'][_l].forms.length; _a3++) {
                var _item = item[_ownerBankKey2 + 's'][_l].forms[_a3];
                var selectId = [];

                if (_item.form_type == '置业顾问') {
                  // 置业顾问
                  if (_item[_ownerBankKey2 + '_result'].value) {
                    selectId = _item[_ownerBankKey2 + '_result'].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    // 默认选择
                    selectId = _item.admins[0].id.toString().split(',');
                  }

                  _this7.doShowParent(true, _item.id, selectId, _l);
                } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                  // 单选 || 多选
                  if (_item[_ownerBankKey2 + '_result'].value) {
                    selectId = _item[_ownerBankKey2 + '_result'].value.toString().split(',');
                  } else if (_item.value) {
                    selectId = _item.value.toString().split(',');
                  } else if (_item.select_value == 1) {
                    if (_item.form_type == 'radio') {
                      selectId = _item.children[0].id.toString().split(',');
                    } else {
                      _item[_ownerBankKey2 + '_result'].value = selectId.join(',');
                    }
                  }

                  _this7.doShowParent(true, _item.id, selectId, _l);
                }
              }
            }
          }
        });
      }
    }
  }
};