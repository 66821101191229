var state = {
  // 开盘报告数据
  activityReport: {}
};
var mutations = {
  // change 开盘报告数据
  CHANGEACITVITYREPORT: function CHANGEACITVITYREPORT(state, payload) {
    state.activityReport = payload;
  }
};
var actions = {
  changeActivityReport: function changeActivityReport(_ref, payload) {
    var commit = _ref.commit;
    console.log(payload);
    commit('CHANGEACITVITYREPORT', payload);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};