var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commont" },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "title" }, [_vm._v("组件库")]),
        _c(
          "div",
          { staticClass: "title-right" },
          [
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("el-input", {
                  staticClass: "inputStyle",
                  attrs: { type: "text", placeholder: "请输入组件名称" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchbtn($event)
                    },
                  },
                  model: {
                    value: _vm.commontName,
                    callback: function ($$v) {
                      _vm.commontName = $$v
                    },
                    expression: "commontName",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "searchbtn",
                    attrs: { type: "primary" },
                    on: { click: _vm.searchbtn },
                  },
                  [_vm._v("搜索")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "btnReset",
                staticStyle: { "border-radius": "20px" },
                attrs: { type: " reset" },
                on: { click: _vm.reset },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "tab-bar" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { fill: "#22A271", model: "tabText" },
                on: { change: _vm.tabClk },
                model: {
                  value: _vm.tabsValue,
                  callback: function ($$v) {
                    _vm.tabsValue = $$v
                  },
                  expression: "tabsValue",
                },
              },
              _vm._l(_vm.newcommonLIst, function (item, index) {
                return _c(
                  "el-radio-button",
                  {
                    key: index,
                    class: { "radio-button": _vm.tabsValue !== item.title },
                    attrs: { label: item.title },
                  },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
              1
            ),
            _c(
              "el-tabs",
              {
                staticClass: "tabStyle",
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.tabClk },
                model: {
                  value: _vm.tabsValue,
                  callback: function ($$v) {
                    _vm.tabsValue = $$v
                  },
                  expression: "tabsValue",
                },
              },
              _vm._l(_vm.newcommonLIst, function (item, index) {
                return _c(
                  "el-tab-pane",
                  {
                    key: index,
                    attrs: { name: item.title, label: item.title },
                  },
                  [
                    item.children.length
                      ? _c(
                          "div",
                          { staticClass: "type-content" },
                          _vm._l(item.children, function (itmes, index2) {
                            return _c(
                              "div",
                              {
                                key: index2,
                                class: [
                                  "type-item",
                                  itmes.type == "houseType" ? "maxheight" : "",
                                ],
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCommont(item, itmes)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "mb-btn" }, [
                                      _vm._v("添加组件"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "zj-document" },
                                  [
                                    _c("items", {
                                      attrs: {
                                        color: false,
                                        type: item.type,
                                        batchCurrent: "1",
                                        type2: itmes.type,
                                        subTitle: itmes.subTitle,
                                        title: itmes.title,
                                        lineBreak: itmes.lineBreak,
                                        lineBreaTitle: itmes.lineBreaTitle,
                                        unit: itmes.unit,
                                        value: itmes.value,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "zj-title" }, [
                                  _vm._v(_vm._s(itmes.subTitle || itmes.title)),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "no-list-msg" }, [
                          _vm._v("抱歉!没有您搜索的组件"),
                        ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择容器",
            visible: _vm.selectDialog,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        _vm._l(_vm.containerList, function (itme, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: itme.title } },
                            [_vm._v(_vm._s(itme.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.adds } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }