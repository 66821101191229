import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: 'ProjectRounds',
  props: {
    innertext: {
      type: [String, Number],
      default: ''
    },
    // 字体大小
    pFont: {
      type: [Number, String],
      default: 40
    },
    // 字体颜色
    pColor: {
      type: String,
      default: '#FFFFFF'
    },
    // 字体位置
    pPosition: {
      type: [Number, String],
      default: 30
    },
    laveNumber: {
      type: [Number, String],
      default: 0
    },
    isStayUser: {
      type: [Number, String],
      default: 0
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  }))
};