//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Api from '../../../api/rowNumber';
export default {
  name: 'EditNumber',
  data: function data() {
    return {
      // 基本参数
      event: {},
      // 页面数据
      rand: {},
      // 单选框禁用
      disabled: false,
      // 全局接口 项目配置
      list: {},
      // 是否影响预设
      user_bool: false
    };
  },
  created: function created() {
    this.dataInfo();
  },
  methods: {
    // 提交保存
    handleSaveInfo: function handleSaveInfo() {
      var _this = this;

      console.log(this.rand); // 提交数据

      Api.numberSetRule({
        rand: this.rand
      }).then(function (res) {
        if (res.status === 200) {
          _this.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    // 获取 摇号规则
    dataInfo: function dataInfo() {
      var _this2 = this;

      Api.numberSelRule({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              event = _res$data.event,
              rand = _res$data.rand;
          _this2.event = event;
          _this2.rand = rand;
        }
      });
    }
  }
};