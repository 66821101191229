var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-up" }, [
    _c(
      "div",
      { staticClass: "img-up-icon" },
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "upload-demo",
            attrs: {
              action: _vm.action,
              "before-upload": _vm.uploadBefore,
              "http-request": _vm.handleFileUploadOss,
              headers: _vm.header,
              limit: 1,
              "on-error": _vm.uploadFail,
              "on-success": _vm.uploadSucess,
              "show-file-list": false,
              accept: _vm.$imgSel,
            },
          },
          [
            _c("div", { staticClass: "img-icon" }, [
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "line-copy" }),
            ]),
          ]
        ),
        _c("div", { staticClass: "tip" }, [_vm._v("上传替换")]),
      ],
      1
    ),
    _vm.showText
      ? _c("div", { staticClass: "img-up-text" }, [
          _c("span", { staticClass: "text-top" }, [
            _vm._v("背景图片最佳尺寸比例:"),
          ]),
          _c("span", { staticClass: "text-bottom" }, [
            _vm._v(
              "（1920*1080）px，不大于" + _vm._s(_vm.imgSizeLimit) + "M。"
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }