var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.topHeight !== 0,
          expression: "topHeight !== 0",
        },
      ],
      staticClass: "project-content",
      style: {
        "--row": _vm.sRowNum,
        "--pos": _vm.sPosition,
        "--height": _vm.contentHeight
          ? _vm.contentHeight + "px"
          : "calc(100vh - (" + _vm.topHeight + " + 45) * 1px)",
        "--column": _vm.editFormatList.columnNum,
        "--margin": _vm.sMargin,
        "--leftMargin": _vm.sLeftMargin,
        "--topMargin": _vm.sTopMargin,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }