var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "付款时间编辑",
        visible: _vm.visible,
        width: "40%",
        top: "5vh",
        "close-on-click-modal": false,
        center: "",
      },
      on: { close: _vm.close },
    },
    [
      _vm.payInfo.length
        ? _c(
            "div",
            { staticClass: "dialog_content" },
            [
              _c(
                "el-form",
                _vm._l(_vm.payInfo, function (item, index) {
                  return _c("el-form-item", { key: index }, [
                    _c("label", [_vm._v(_vm._s(item.name) + ":")]),
                    _c(
                      "div",
                      { staticClass: "item-contetn" },
                      [
                        item.type == 0
                          ? [
                              _c(
                                "div",
                                { staticClass: "item-top" },
                                [
                                  _c("span", { staticClass: "two_lable" }, [
                                    _vm._v("付款时间:"),
                                  ]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.payInfo[index].pay_time_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payInfo[index],
                                            "pay_time_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payInfo[index].pay_time_type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 2 },
                                        },
                                        [_vm._v("约束时间")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 1 },
                                        },
                                        [_vm._v("仅作参考")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("不设置时间")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "flex item-middle" }, [
                                _c(
                                  "div",
                                  { staticClass: "house-type-wrapper" },
                                  [
                                    _c("span", [_vm._v("认购后第")]),
                                    _c(
                                      "div",
                                      { staticClass: "main_input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            min: "1",
                                            type: "number",
                                            onkeypress:
                                              "return(/[\\d]/.test(String.fromCharCode(event.keyCode)))",
                                          },
                                          model: {
                                            value:
                                              _vm.payInfo[index]
                                                .reservation_time,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.payInfo[index],
                                                "reservation_time",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "payInfo[index].reservation_time",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { width: "20px" } },
                                      [_vm._v("天")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        item.type == 1
                          ? [
                              _c(
                                "div",
                                { staticClass: "item-top" },
                                [
                                  _c("span", { staticClass: "two_lable" }, [
                                    _vm._v("付款时间:"),
                                  ]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.payInfo[index].pay_time_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payInfo[index],
                                            "pay_time_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payInfo[index].pay_time_type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 2 },
                                        },
                                        [_vm._v("约束时间")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 1 },
                                        },
                                        [_vm._v("仅作参考")]
                                      ),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "el-radio-width",
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("不设置时间")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "flex item-middle" }, [
                                _c(
                                  "div",
                                  { staticClass: "house-type-wrapper" },
                                  [
                                    _c("span", [_vm._v("认购后第")]),
                                    _c(
                                      "div",
                                      { staticClass: "main_input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            min: "1",
                                            type: "number",
                                            onkeypress:
                                              "return(/[\\d]/.test(String.fromCharCode(event.keyCode)))",
                                          },
                                          model: {
                                            value:
                                              _vm.payInfo[index]
                                                .reservation_time,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.payInfo[index],
                                                "reservation_time",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "payInfo[index].reservation_time",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { width: "20px" } },
                                      [_vm._v("天")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "house-type-wrapper mr-left20",
                                  },
                                  [
                                    _c("span", [_vm._v("付款比例")]),
                                    _c(
                                      "div",
                                      { staticClass: "main_input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: { type: "number" },
                                          model: {
                                            value:
                                              _vm.payInfo[index].pay_proportion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.payInfo[index],
                                                "pay_proportion",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "payInfo[index].pay_proportion",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { width: "20px" } },
                                      [_vm._v("%")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        item.type != 1 && item.type != 0
                          ? [
                              _c("div", { staticClass: "item-top flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "top_left" },
                                  [
                                    _c("span", { staticClass: "two_lable" }, [
                                      _vm._v("付款期数:"),
                                    ]),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.payInfo[index].by_stages_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.payInfo[index],
                                              "by_stages_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "payInfo[index].by_stages_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "el-radio-width",
                                            attrs: { label: 0 },
                                          },
                                          [_vm._v("设置固定期数")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "el-radio-width",
                                            attrs: { label: 1 },
                                          },
                                          [_vm._v("设置最大期数")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "top-right",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "house-type-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "main_input left_right_none",
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "80px" },
                                              attrs: {
                                                minlength: 1,
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.payInfo[index]
                                                    .by_stages_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.payInfo[index],
                                                    "by_stages_number",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "payInfo[index].by_stages_number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { width: "20px" } },
                                          [_vm._v("期")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "flex item-middle" }),
                              _c("div", { staticClass: "item-bottom" }, [
                                _c(
                                  "div",
                                  { staticClass: "bottom-items" },
                                  [
                                    _c("span", { staticClass: "two_lable" }, [
                                      _vm._v("付款时间:"),
                                    ]),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.payInfo[index].pay_time_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.payInfo[index],
                                              "pay_time_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "payInfo[index].pay_time_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "el-radio-width",
                                            attrs: { label: 2 },
                                          },
                                          [_vm._v("约束时间")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "el-radio-width",
                                            attrs: { label: 1 },
                                          },
                                          [_vm._v("仅作参考")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "el-radio-width",
                                            attrs: { label: 0 },
                                          },
                                          [_vm._v("不设置时间")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "bottom-items" },
                                  _vm._l(
                                    _vm.payInfo[index].pay_by_stages,
                                    function (item, index2) {
                                      return _c(
                                        "div",
                                        {
                                          key: index2,
                                          staticClass: "periods_items flex",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "perNumbe" },
                                            [
                                              _vm._v(
                                                "第" + _vm._s(index2 + 1) + "期"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "house-type-wrapper mr-left20",
                                            },
                                            [
                                              _c("span", [_vm._v("认购后第")]),
                                              _c(
                                                "div",
                                                { staticClass: "main_input" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "80px",
                                                    },
                                                    attrs: { type: "number" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onInput()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.reservation_time,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "reservation_time",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "item.reservation_time",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    width: "20px",
                                                  },
                                                },
                                                [_vm._v("天")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "house-type-wrapper mr-left20",
                                            },
                                            [
                                              _c("span", [_vm._v("建议付款")]),
                                              _c(
                                                "div",
                                                { staticClass: "main_input" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "80px",
                                                    },
                                                    attrs: { type: "number" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.onInput()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.pay_proportion,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "pay_proportion",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "item.pay_proportion",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    width: "20px",
                                                  },
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                          _vm.payInfo[index].by_stages_type == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "delt" },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "删除",
                                                        placement: "top-end",
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "class-name":
                                                            "greens",
                                                          "icon-class":
                                                            "delete",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deltstages(
                                                              index,
                                                              index2
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.payInfo[index].by_stages_type == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "add" },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "增加期数",
                                                        placement: "top-end",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          size: "mini",
                                                          icon: "el-icon-plus",
                                                          circle: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addstages(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cance } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }