//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { setPlateType } from '@/api/lottery';
export default {
  name: 'SetUp',
  data: function data() {
    return {
      // 选中的bg
      tabBg: 0,
      // 选中的 布局
      tabList: 0,
      url: 'public/img/712806dc-ca5e-11eb-9524-525400ce40ba.png'
    };
  },
  methods: {
    // 摇号布局
    handleList: function handleList(index) {
      this.tabList = index;
    },
    // 切换背景
    handleBg: function handleBg(index) {
      this.tabBg = index;
    },
    // 保持背景
    handleSave: function handleSave() {
      var _this = this;

      var data = {
        logo: 'public/img/cee02df5-afe0-11eb-9d25-525400ce40ba.png',
        show: 1
      };
      setPlateType(data).then(function (res) {
        if (res.status === 200) {
          _this.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    }
  }
};