import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { type } from '@/components/IntentionRoom/api.js';
import toolReviewMixin from '../toolReviewMixin';
export default {
  name: 'UserRegister',
  mixins: [toolReviewMixin],
  props: {
    statusInfo: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1
    },
    info: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // div 高度
    tableHeight: {
      type: Number,
      default: 0
    },
    // 日志数组
    infoTips: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: 1,
      isDoPriveImg: false,
      perViewUrl: '',
      showData: []
    };
  },
  watch: {
    // 监听数据变化 对数据进行格式后赋值显示
    statusInfo: function statusInfo(newVal) {
      var showData = newVal;
      showData.forEach(function (formsTyle) {
        if (formsTyle.type == 0) {
          // 普通表单
          formsTyle.forms.forEach(function (formsItem) {
            if (formsItem.owner_type == 103) {
              // 认筹金是否本人支付
              formsTyle.children.forEach(function (childrenItem) {
                if (formsTyle.normal_result.value == childrenItem.id) {
                  for (var _i = 0; _i < formsTyle.forms.length; _i++) {
                    if (formsTyle.forms[_i].owner_type == 106) {
                      // 代付承诺书
                      if (childrenItem.owner_type == 104) {
                        formsTyle.forms.splice(_i, 1);
                      }
                    }
                  }
                }
              });
            } else if (formsItem.form_type === 'popup') {
              var _formsTyle$forms;

              // 意向房源
              try {
                formsItem.normal_result.auto_value = JSON.parse(formsItem.normal_result.auto_value);
              } catch (e) {
                formsItem.normal_result.auto_value = [];
              } // 如果意向房源无数据


              (_formsTyle$forms = formsTyle.forms) === null || _formsTyle$forms === void 0 ? void 0 : _formsTyle$forms.forEach(function (ffi) {
                //   80 意向楼栋 81意向户型 82 意向面积 87 意向楼层
                if ([80, 81, 82, 87].includes(ffi.owner_type)) {
                  ffi.parent_cause_show = false;
                }
              });
            } else if (formsItem.form_type === '意向户型') {
              var _formsItem$normal_res;

              // 意向房源
              try {
                formsItem.normal_result.auto_value = JSON.parse(formsItem.normal_result.auto_value);
              } catch (e) {
                formsItem.normal_result.auto_value = [];
              }

              if (!((_formsItem$normal_res = formsItem.normal_result.auto_value) !== null && _formsItem$normal_res !== void 0 && _formsItem$normal_res.length)) {
                formsItem.parent_cause_show = false;
              }
            }
          });
        }
      });
      this.showData = showData;
      this.getPopupNumber();
    },
    immediate: true
  },
  methods: {
    // 获取选中的值
    getSelected: function getSelected(array, selectedValue) {
      if (array && array.length) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].id == selectedValue) {
            return array[i].name;
          }
        }
      }
    },
    getType: function getType(item) {
      if (this.type === 1) {
        return item.typeUserNumber > 0;
      } else {
        return item.typeNumber > 0;
      }
    },
    // 预览点击事件
    doPriveImg: function doPriveImg(url) {
      this.isDoPriveImg = true;
      this.perViewUrl = url;
    },
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'dxf', 'ufo', 'eps', 'raw', 'WMF', 'webp', 'avif', 'apng'];
      return images.indexOf(suffix) < 0 ? name : '';
    },
    getPopupNumber: function getPopupNumber() {
      var _this = this;

      type().then(function (result) {
        var res = result.data;
        var showData = JSON.parse(JSON.stringify(_this.showData));
        console.log(res.allCount);
        showData.forEach(function (form) {
          if (form.type == 0) {
            var _form$forms;

            // 普通表单
            (_form$forms = form.forms) === null || _form$forms === void 0 ? void 0 : _form$forms.forEach(function (_item) {
              // 遍历表单问题项
              if (_item.form_type === 'popup') {
                // 意向房源
                _item.parent_cause_show = !!res.allCount;
                _item.is_required = 1;
              } else if (_item.form_type === '意向户型') {
                _item.parent_cause_show = !res.allCount;
                _item.is_required = 1;
              }
            });
          }
        });
        _this.showData = showData;
        console.log(showData);
      });
    }
  }
};