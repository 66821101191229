var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card-container" }, [
      _c("div", { staticClass: "title-h2" }, [_vm._v("交款")]),
      _vm._m(0),
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "name" }, [_vm._v("交款状态:")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            _vm._s(
              _vm.pay.handled === -1
                ? "已退款"
                : _vm.priceStatus[_vm.pay.handled]
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "name" }, [_vm._v("认筹数量:")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "住宅" +
              _vm._s(_vm.pay.room_number) +
              ", 车位" +
              _vm._s(_vm.pay.car_number) +
              ", 商业" +
              _vm._s(_vm.pay.bus_number)
          ),
        ]),
      ]),
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "name" }, [_vm._v("应支付金额:")]),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.pay.yj_price))]),
      ]),
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "name" }, [_vm._v("已支付金额:")]),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.pay.price))]),
      ]),
      _c("div", { staticClass: "detail-item" }, [
        _c("div", { staticClass: "name" }, [_vm._v("支付时间:")]),
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm._f("dateFormat")(_vm.pay.pay_time * 1000))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-wrapper" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }