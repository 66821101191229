var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container" },
    [
      _c("div", { staticClass: "container-wrapper" }, [
        _c("div", { staticClass: "welcome-left" }, [
          _c("div", { staticClass: "card-box-user" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "div",
                { staticClass: "user-header" },
                [
                  _c("el-image", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.userInfo.avatar == "",
                        expression: "userInfo.avatar == ''",
                      },
                    ],
                    staticClass: "user-header-img",
                    attrs: { src: require("@/assets/image/avatar_icon.png") },
                  }),
                  _c("el-avatar", {
                    attrs: {
                      size: 80,
                      src:
                        _vm.userInfo.avatar == ""
                          ? require("@/assets/image/avatar_icon.png")
                          : _vm.userInfo.avatar,
                      icon: "el-icon-user-solid",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "sys-box" }, [
              _c("div", [
                _c("span", { staticClass: "hi" }, [_vm._v("Hi,")]),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.userInfo.name)),
                ]),
                _c("span", { staticClass: "welcome-text" }, [
                  _vm._v(_vm._s(_vm.timeTip) + "好！"),
                ]),
              ]),
              _c("div", { staticClass: "sys-tip" }, [
                _c("div", { staticClass: "sys-tip-top" }, [
                  _vm._v(_vm._s(_vm.defaultSettings.indexText)),
                ]),
                _c("div", { staticClass: "sys-tip-bottom" }, [
                  _vm._v(" 有任何疑问请拨打："),
                  _c(
                    "a",
                    {
                      staticStyle: { "font-weight": "900" },
                      attrs: { href: "tel:" + _vm.admin_tel },
                    },
                    [_vm._v(_vm._s(_vm.admin_tel))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "welcome-card" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "index/getxfd"],
                    expression: "['admin', 'index/getxfd']",
                  },
                ],
                staticClass: "card-box",
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "card-box-code" }, [
                  _c("div", { staticClass: "code-down" }, [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@/assets/image/down1.png"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadPicture(_vm.xfdQR, 1)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "code-scan center" }, [
                    _vm.xfdQR
                      ? _c("img", {
                          staticClass: "scan-img",
                          attrs: { src: _vm.xfdQR, alt: "" },
                        })
                      : _c("i", {
                          staticClass: "el-icon-picture empty-pictrue",
                        }),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "index/getassistant"],
                    expression: "['admin', 'index/getassistant']",
                  },
                ],
                staticClass: "card-box",
              },
              [
                _vm._m(1),
                _c("div", { staticClass: "card-box-code" }, [
                  _c("div", { staticClass: "code-down" }, [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@/assets/image/down2.png"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloadPicture(_vm.assistantQR, 2)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "code-scan center" }, [
                    _vm.assistantQR
                      ? _c("img", {
                          staticClass: "scan-img",
                          attrs: { src: _vm.assistantQR, alt: "" },
                        })
                      : _c("i", {
                          staticClass: "el-icon-picture empty-pictrue",
                        }),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "index/getaudit"],
                    expression: "['admin', 'index/getaudit']",
                  },
                ],
                staticClass: "card-box",
              },
              [
                _vm.auditData.id
                  ? [
                      _c("div", { staticClass: "card-name" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.auditData.audit_suffix)),
                        ]),
                        _c("span", { staticClass: "card-name-next" }, [
                          _vm._v("小程序码"),
                        ]),
                      ]),
                      _c("div", { staticClass: "card-box-code" }, [
                        _c("div", { staticClass: "code-down" }, [
                          _c("img", {
                            attrs: {
                              alt: "",
                              src: require("@/assets/image/download.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPicture(_vm.examinationQR, 3)
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "code-scan center" }, [
                          _vm.examinationQR
                            ? _c("img", {
                                staticClass: "scan-img",
                                attrs: { src: _vm.examinationQR, alt: "" },
                              })
                            : _c("i", {
                                staticClass: "el-icon-picture empty-pictrue",
                              }),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: [
                  "admin",
                  "index/index",
                  "index/smspassord",
                  "index/exporttemplate",
                  "index/exportcheck",
                  "index/exportcollect",
                  "index/exportroomstatus",
                  "index/usercollect",
                  "index/userstatus",
                  "report/data",
                ],
                expression:
                  "[\n\t\t\t\t\t'admin',\n\t\t\t\t\t'index/index',\n\t\t\t\t\t'index/smspassord',\n\t\t\t\t\t'index/exporttemplate',\n\t\t\t\t\t'index/exportcheck',\n\t\t\t\t\t'index/exportcollect',\n\t\t\t\t\t'index/exportroomstatus',\n\t\t\t\t\t'index/usercollect',\n\t\t\t\t\t'index/userstatus',\n\t\t\t\t\t'report/data',\n\t\t\t\t]",
              },
            ],
            staticClass: "welcome-center",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: [
                      "admin",
                      "index/index",
                      "index/smspassord",
                      "index/exporttemplate",
                      "index/exportcheck",
                    ],
                    expression:
                      "['admin', 'index/index', 'index/smspassord', 'index/exporttemplate', 'index/exportcheck']",
                  },
                ],
                staticClass: "setting-guide",
              },
              [
                _c(
                  "div",
                  { staticClass: "guide-top ver-center guid-padding" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "index/index"],
                            expression: "['admin', 'index/index']",
                          },
                        ],
                        staticClass: "title k-title",
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "setting-icon",
                          attrs: { "icon-class": "index-setting" },
                        }),
                        _vm._v(" 开盘配置引导 "),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm.openConfigList.is_sms
                        ? _c(
                            "div",
                            { staticClass: "send-btn-wrapper" },
                            [
                              _c("div", { staticClass: "tip-text" }, [
                                _vm._v(
                                  "还有" +
                                    _vm._s(_vm.openConfigList.sms_count) +
                                    "人未发送初始密码"
                                ),
                              ]),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "index/smspassord"],
                                      expression:
                                        "['admin', 'index/smspassord']",
                                    },
                                  ],
                                  staticClass: "send-msg-wrapper",
                                  attrs: { loading: _vm.sendLoading },
                                  on: { click: _vm.sendMes },
                                },
                                [_vm._v("立即发送 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "btn-box" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "index/exporttemplate"],
                                expression: "['admin', 'index/exporttemplate']",
                              },
                            ],
                            staticClass: "btn-wrapper center",
                            on: {
                              click: function ($event) {
                                return _vm.exportTempBtn(0)
                              },
                            },
                          },
                          [_vm._v("下载模板包")]
                        ),
                      ]),
                      _c("div", { staticClass: "btn-box" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "index/exportconfig"],
                                expression: "['admin', 'index/exportconfig']",
                              },
                            ],
                            staticClass: "btn-wrapper center",
                            on: {
                              click: function ($event) {
                                return _vm.exportKeywords()
                              },
                            },
                          },
                          [_vm._v("关键参数复核")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "index/exportcheck"],
                              expression: "['admin', 'index/exportcheck']",
                            },
                          ],
                          staticClass: "btn-wrapper center orange",
                          on: {
                            click: function ($event) {
                              return _vm.exportTempBtn(1)
                            },
                          },
                        },
                        [_vm._v("导出复核")]
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "dashedTop" }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "index/index"],
                        expression: "['admin', 'index/index']",
                      },
                    ],
                    staticClass: "progress-box ver-center guid-padding",
                  },
                  [_c("custom-progress", { ref: "customProgress" })],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "permission",
                rawName: "v-permission",
                value: [
                  "admin",
                  "index/exportcollect",
                  "index/exportroomstatus",
                  "index/usercollect",
                  "index/userstatus",
                  "report/data",
                  "index/analyse",
                ],
                expression:
                  "[\n\t\t\t\t\t'admin',\n\t\t\t\t\t'index/exportcollect',\n\t\t\t\t\t'index/exportroomstatus',\n\t\t\t\t\t'index/usercollect',\n\t\t\t\t\t'index/userstatus',\n\t\t\t\t\t'report/data',\n\t\t\t\t\t'index/analyse',\n\t\t\t\t]",
              },
            ],
            staticClass: "center-active",
          },
          [
            _c("div", { staticClass: "center-active-title" }, [
              _c(
                "div",
                { staticClass: "active-title-name" },
                [
                  _c("svg-icon", {
                    staticClass: "setting-icon",
                    attrs: { "icon-class": "index-port" },
                  }),
                  _vm._v(" 开盘报告下载 "),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "dashedTop" }),
            _c("div", { staticClass: "center-active-box" }, [
              _c("div", { staticClass: "class-box box-content" }, [
                _c(
                  "div",
                  { staticClass: "active-left" },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "index-custom-copy" },
                    }),
                    _c("span", { staticClass: "active-left-name" }, [
                      _vm._v("意向分析类"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "active-right" }, [
                  _c("div", { staticClass: "active-btn-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "active-btn",
                        on: {
                          click: function ($event) {
                            return _vm.showClassDiadlog("yxclass")
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "class-box box-content" }, [
                _c(
                  "div",
                  { staticClass: "active-left" },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "index-stat-copy" },
                    }),
                    _c("span", { staticClass: "active-left-name" }, [
                      _vm._v("销售进度类"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "active-right" }, [
                  _c("div", { staticClass: "active-btn-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "active-btn",
                        on: {
                          click: function ($event) {
                            return _vm.showClassDiadlog("xsclass")
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "class-box box-content" }, [
                _c(
                  "div",
                  { staticClass: "active-left" },
                  [
                    _c("svg-icon", {
                      attrs: { "icon-class": "index-price-copy" },
                    }),
                    _c("span", { staticClass: "active-left-name" }, [
                      _vm._v("开盘总结报告"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "active-right" }, [
                  _c("div", { staticClass: "active-btn-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "active-btn",
                        on: {
                          click: function ($event) {
                            return _vm.showClassDiadlog("kpclass")
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "class-box box-content" }, [
                _c(
                  "div",
                  { staticClass: "active-left" },
                  [
                    _c("svg-icon", { attrs: { "icon-class": "index-port" } }),
                    _c("span", { staticClass: "active-left-name" }, [
                      _vm._v("个性化报表"),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "active-right" }, [
                  _c("div", { staticClass: "active-btn-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "active-btn",
                        on: {
                          click: function ($event) {
                            return _vm.showClassDiadlog("gxclass")
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            false
              ? _c("div", { staticClass: "center-active-box" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/exportcollect"],
                          expression: "['admin', 'index/exportcollect']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-stat-copy" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v(
                              _vm._s(_vm.customSetting.title) + "意向统计"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(0)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/usercollect"],
                          expression: "['admin', 'index/usercollect']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-stat-copy" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("客户意向统计"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(4)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm.customSetting.cate_room === 1 ||
                  _vm.customSetting.cate_car === 1
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "index/analyse"],
                              expression: "['admin', 'index/analyse']",
                            },
                          ],
                          staticClass: "box-content",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "active-left" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "index-stat-copy" },
                              }),
                              _c("span", { staticClass: "active-left-name" }, [
                                _vm._v("装户综合分析"),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "active-right" }, [
                            _c("div", { staticClass: "active-btn-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "active-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(8)
                                    },
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("p", { staticClass: "division" }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/exportroomstatus"],
                          expression: "['admin', 'index/exportroomstatus']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-build-copy" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v(_vm._s(_vm.customSetting.title) + "状态表"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(1)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/userstatus"],
                          expression: "['admin', 'index/userstatus']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "offline" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("客户状态表"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(5)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/orderexport"],
                          expression: "['admin', 'index/orderexport']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-build-copy" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("订单记录表"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(9)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/roomsaleexport"],
                          expression: "['admin', 'index/roomsaleexport']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-build-copy" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("销售清单表"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: { click: _vm.salesListDownload },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("p", { staticClass: "division" }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/buildsale"],
                          expression: "['admin', 'index/buildsale']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-port" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("项目开盘去化"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(7)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  false
                    ? _c("div", { staticClass: "box-content" }, [
                        _c(
                          "div",
                          { staticClass: "active-left" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "index-custom-copy" },
                            }),
                            _c("span", { staticClass: "active-left-name" }, [
                              _vm._v("无操作客户"),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "active-right" }, [
                          _c("div", { staticClass: "active-btn-wrapper" }, [
                            _c(
                              "div",
                              {
                                staticClass: "active-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.download(2)
                                  },
                                },
                              },
                              [_vm._v("下载")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  false
                    ? _c("div", { staticClass: "box-content" }, [
                        _c(
                          "div",
                          { staticClass: "active-left" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "index-price-copy" },
                            }),
                            _c("span", { staticClass: "active-left-name" }, [
                              _vm._v("登录过但未认购客户"),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "active-right" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "rownumber/randindex"],
                                  expression:
                                    "['admin', 'rownumber/randindex']",
                                },
                              ],
                              staticClass: "active-btn-wrapper",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "active-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(3)
                                    },
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/userorderexport"],
                          expression: "['admin', 'index/userorderexport']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-port" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("建发认购明细FID"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.download(6)
                                },
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "report/data"],
                          expression: "['admin', 'report/data']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-port" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("开盘总结报告-详细"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: { click: _vm.openReportDialog },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "report/room"],
                          expression: "['admin', 'report/room']",
                        },
                      ],
                      staticClass: "box-content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "active-left" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "index-port" },
                          }),
                          _c("span", { staticClass: "active-left-name" }, [
                            _vm._v("开盘总结报告-概况"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "active-right" }, [
                        _c("div", { staticClass: "active-btn-wrapper" }, [
                          _c(
                            "div",
                            {
                              staticClass: "active-btn",
                              on: { click: _vm.openVerviewDialog },
                            },
                            [_vm._v("下载")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "welcome-right" }, [
          _c("div", { staticClass: "todo-tip" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "index/abnormal"],
                    expression: "['admin', 'index/abnormal']",
                  },
                ],
                staticClass: "todo-right-wrapper",
              },
              [
                _vm._m(2),
                _vm.abnormalList.length
                  ? _c(
                      "div",
                      { staticClass: "right-bottom" },
                      _vm._l(_vm.abnormalList, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "right-bottom-box",
                            on: {
                              click: function ($event) {
                                return _vm.goToAbnormal(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "point" }),
                            _c("span", [_vm._v(_vm._s(item.msg))]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "right-bottom right-bottom-empty" },
                      [_vm._v("暂无异常~")]
                    ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "index/staydo"],
                    expression: "['admin', 'index/staydo']",
                  },
                ],
                staticClass: "todo-left-wrapper",
              },
              [
                _vm._m(3),
                _vm.stayDoList.length
                  ? _c(
                      "div",
                      { staticClass: "left-bottom" },
                      _vm._l(_vm.stayDoList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "left-bottom-text" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "left-bottom-text-content",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToExamine(item)
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(index + 1) +
                                      "、" +
                                      _vm._s(item.content)
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "click-arrow",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToExamine(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "greens",
                                        "icon-class": "arrow",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "left-bottom-text-time" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(item.created_at * 1000)
                                      .format("YYYY/MM/DD HH:mm")
                                  )
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "left-bottom left-bottom-empty" },
                      [_vm._v("暂无待办~")]
                    ),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.exportReview,
            center: "",
            "custom-class": "exportDialog",
            title: "导出复核",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportReview = $event
            },
            close: function ($event) {
              return _vm.cancelExport(2)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-row",
                { staticClass: "check-all" },
                [
                  _c(
                    "el-col",
                    { staticClass: "ver-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            indeterminate:
                              _vm.reviewCheckList.length &&
                              _vm.reviewCheckList.length <
                                _vm.reviewOptions.length
                                ? true
                                : false,
                          },
                          on: { change: _vm.reviewCheckAllChange },
                          model: {
                            value: _vm.ReviewCheckAll,
                            callback: function ($$v) {
                              _vm.ReviewCheckAll = $$v
                            },
                            expression: "ReviewCheckAll",
                          },
                        },
                        [_vm._v("全选 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "check-group-box",
                          on: { change: _vm.reviewCheckListChange },
                          model: {
                            value: _vm.reviewCheckList,
                            callback: function ($$v) {
                              _vm.reviewCheckList = $$v
                            },
                            expression: "reviewCheckList",
                          },
                        },
                        [
                          _vm._l(_vm.reviewOptions, function (item, i) {
                            return [
                              item.buy_rand != 0 && !item.show
                                ? _c(
                                    "el-checkbox",
                                    { key: i, attrs: { label: item } },
                                    [_vm._v(_vm._s(item.name) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelExport(2)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: {
                    disabled: !_vm.reviewCheckList.length,
                    loading: _vm.exportLoading,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.exportHandle(2)
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.exportKeyword,
            center: "",
            title: "关键参数复核",
            width: "998px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportKeyword = $event
            },
            close: function ($event) {
              _vm.exportKeyword = false
            },
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { position: "absolute", right: "20px", top: "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.downloadKeys },
            },
            [_vm._v("导出复核 ")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.keysList, height: "50vh" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "info", label: "参数&功能描述" },
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "参数&功能描述" },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.exportKeyword = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.exportTemp,
            center: "",
            "custom-class": "exportDialog",
            title: "下载模板包",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exportTemp = $event
            },
            close: function ($event) {
              return _vm.cancelExport(1)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-row",
                { staticClass: "check-all" },
                [
                  _c(
                    "el-col",
                    { staticClass: "ver-center check-group-box" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            indeterminate:
                              _vm.tempCheckList.length &&
                              _vm.tempCheckList.length < _vm.tempOptions.length
                                ? true
                                : false,
                          },
                          on: { change: _vm.tempCheckAllChange },
                          model: {
                            value: _vm.tempCheckAll,
                            callback: function ($$v) {
                              _vm.tempCheckAll = $$v
                            },
                            expression: "tempCheckAll",
                          },
                        },
                        [_vm._v("全选 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "check-group-box",
                          on: { change: _vm.tempCheckListChange },
                          model: {
                            value: _vm.tempCheckList,
                            callback: function ($$v) {
                              _vm.tempCheckList = $$v
                            },
                            expression: "tempCheckList",
                          },
                        },
                        [
                          _vm._l(_vm.tempOptions, function (item, i) {
                            return [
                              item.buy_rand != 0
                                ? _c(
                                    "el-checkbox",
                                    { key: i, attrs: { label: item } },
                                    [_vm._v(_vm._s(item.name) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelExport(1)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: {
                    disabled: !_vm.tempCheckList.length,
                    loading: _vm.exportLoading,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.exportHandle(1)
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.customSetting.title + "状态表",
            visible: _vm.isShowHouseStatus,
            center: "",
            "custom-class": "exportDialog",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowHouseStatus = $event
            },
            close: function ($event) {
              _vm.isShowHouseStatus = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "house-status-radio",
                  model: {
                    value: _vm.houseStatusradio,
                    callback: function ($$v) {
                      _vm.houseStatusradio = $$v
                    },
                    expression: "houseStatusradio",
                  },
                },
                _vm._l(_vm.event_list, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: index } },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShowHouseStatus = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.exportLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportDetail(_vm.houseStatusradio)
                    },
                  },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isNoUSer,
            center: "",
            "custom-class": "exportDialog",
            title: "无操作客户",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isNoUSer = $event
            },
            close: function ($event) {
              _vm.isNoUSer = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.infoListId,
                    callback: function ($$v) {
                      _vm.infoListId = $$v
                    },
                    expression: "infoListId",
                  },
                },
                _vm._l(_vm.infoList, function (item, index) {
                  return _c(
                    "el-radio",
                    {
                      key: index,
                      staticClass: "house-status-radio",
                      attrs: { label: item.id },
                    },
                    [_vm._v(_vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isNoUSer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.generateLog(_vm.infoListId)
                    },
                  },
                },
                [_vm._v("下载报表")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isNoUSerDown,
            center: "",
            "custom-class": "userLoading",
            title: "无操作客户",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isNoUSerDown = $event
            },
            close: function ($event) {
              _vm.isNoUSerDown = false
            },
          },
        },
        [
          _c("p", [_vm._v("生成报告时间较长，请耐心等待")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isNoUSerDown = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.userLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadLog(_vm.infoListId)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.textTitle))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isUserStatus,
            center: "",
            "custom-class": "userLoading",
            title: "客户状态表",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isUserStatus = $event
            },
            close: function ($event) {
              _vm.isUserStatus = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "house-status-radio",
                  model: {
                    value: _vm.userStatusRadio,
                    callback: function ($$v) {
                      _vm.userStatusRadio = $$v
                    },
                    expression: "userStatusRadio",
                  },
                },
                _vm._l(_vm.event_list, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: i },
                    },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isUserStatus = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.exportUserStatusCopy },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isRenStatus,
            center: "",
            "custom-class": "userLoading",
            title: "认购信息导出",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isRenStatus = $event
            },
            close: function ($event) {
              _vm.isRenStatus = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "house-status-radio",
                  model: {
                    value: _vm.renStatusRadio,
                    callback: function ($$v) {
                      _vm.renStatusRadio = $$v
                    },
                    expression: "renStatusRadio",
                  },
                },
                _vm._l(_vm.event_list, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: i },
                    },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isRenStatus = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.handleRenDownload },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isBuildStatus,
            center: "",
            "custom-class": "userLoading",
            title: _vm.downloadIndex === 8 ? "装户综合分析" : "项目开盘去化",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isBuildStatus = $event
            },
            close: function ($event) {
              _vm.isBuildStatus = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "house-status-radio",
                  model: {
                    value: _vm.buildStatusRadio,
                    callback: function ($$v) {
                      _vm.buildStatusRadio = $$v
                    },
                    expression: "buildStatusRadio",
                  },
                },
                _vm._l(_vm.event_list, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: i },
                    },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isBuildStatus = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.downloadIndex === 8
                        ? _vm.doAnalyse(1)
                        : _vm.handleBuildDownload()
                    },
                  },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.selectEventVisbled,
            "custom-class": "userLoading",
            title: "选择活动",
            width: "470px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectEventVisbled = $event
            },
            close: function ($event) {
              _vm.selectEventVisbled = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "house-status-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "house-status-radio",
                  model: {
                    value: _vm.selectRadio,
                    callback: function ($$v) {
                      _vm.selectRadio = $$v
                    },
                    expression: "selectRadio",
                  },
                },
                _vm._l(_vm.event_list, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: i },
                    },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.selectEventVisbled = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.downLoadStatement },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("canvas", {
        staticClass: "qr_canvas",
        style: {
          width: _vm.canvasStyle.width * _vm.canvasStyle.scale + "px",
          height: _vm.canvasStyle.height * _vm.canvasStyle.scale + "px",
        },
        attrs: { id: "qr_canvas" },
      }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.reportShow, center: "", width: "45%" },
          on: {
            "update:visible": function ($event) {
              _vm.reportShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "report_centent" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.reportform } },
                [
                  _vm.sign_up
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "认购统计维度:" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.order_type,
                                callback: function ($$v) {
                                  _vm.order_type = $$v
                                },
                                expression: "order_type",
                              },
                            },
                            [_vm._v("按销控变红统计")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.order_type,
                                callback: function ($$v) {
                                  _vm.order_type = $$v
                                },
                                expression: "order_type",
                              },
                            },
                            [_vm._v("按签约完成统计")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "报告展示维度:" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.handleCheckedCitiesChange },
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "01开盘概况", disabled: "" },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "02销售情况", disabled: "" },
                          }),
                          _c("el-checkbox", { attrs: { label: "03客户情况" } }),
                          _c("el-checkbox", { attrs: { label: "04销售排名" } }),
                          _c("el-checkbox", { attrs: { label: "05开盘流程" } }),
                          _c("el-checkbox", { attrs: { label: "06落位转化" } }),
                          _c("el-checkbox", {
                            attrs: { label: "07总结及建议" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.summarizeShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "推进过程小结:" } },
                        [
                          _c(
                            "div",
                            { staticClass: "form_item" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder:
                                    "1）项目配合及实施推进的优点、不足建议等\n2）多点论述用@分隔",
                                },
                                model: {
                                  value: _vm.reportform.advance,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportform, "advance", $$v)
                                  },
                                  expression: "reportform.advance",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.advance,
                                    callback: function ($$v) {
                                      _vm.advance = $$v
                                    },
                                    expression: "advance",
                                  },
                                },
                                [_vm._v("暂无")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.summarizeShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "系统迭代建议:" } },
                        [
                          _c(
                            "div",
                            { staticClass: "form_item" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "多点论述用@分隔",
                                },
                                model: {
                                  value: _vm.reportform.iteration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportform, "iteration", $$v)
                                  },
                                  expression: "reportform.iteration",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.iteration,
                                    callback: function ($$v) {
                                      _vm.iteration = $$v
                                    },
                                    expression: "iteration",
                                  },
                                },
                                [_vm._v("暂无")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.summarizeShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开盘流程建议:" } },
                        [
                          _c(
                            "div",
                            { staticClass: "form_item" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "多点论述用@分隔",
                                },
                                model: {
                                  value: _vm.reportform.flowpath,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportform, "flowpath", $$v)
                                  },
                                  expression: "reportform.flowpath",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.flowpath,
                                    callback: function ($$v) {
                                      _vm.flowpath = $$v
                                    },
                                    expression: "flowpath",
                                  },
                                },
                                [_vm._v("暂无")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "其它展示维度:" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.xtRecommend,
                            callback: function ($$v) {
                              _vm.xtRecommend = $$v
                            },
                            expression: "xtRecommend",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { "true-label": 1, "false-label": 0 } },
                            [_vm._v("系统推荐总结")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.reportShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.openAcitveReport },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.salesDialogShow,
            center: "",
            "custom-class": "userLoading",
            title: "销售清单下载",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.salesDialogShow = $event
            },
            close: function ($event) {
              _vm.salesDialogShow = false
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载类型:" } },
                [
                  _vm.customSetting.cate_room === 1
                    ? _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { label: 1 },
                          model: {
                            value: _vm.salesListType,
                            callback: function ($$v) {
                              _vm.salesListType = $$v
                            },
                            expression: "salesListType",
                          },
                        },
                        [_vm._v("房源销售清单")]
                      )
                    : _vm._e(),
                  _vm.customSetting.cate_car === 1
                    ? _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { label: 2 },
                          model: {
                            value: _vm.salesListType,
                            callback: function ($$v) {
                              _vm.salesListType = $$v
                            },
                            expression: "salesListType",
                          },
                        },
                        [_vm._v("车位销售清单")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择活动:" } },
                _vm._l(_vm.event_list, function (item, i) {
                  return _c(
                    "el-radio",
                    {
                      key: item.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: i },
                      model: {
                        value: _vm.salesRadio,
                        callback: function ($$v) {
                          _vm.salesRadio = $$v
                        },
                        expression: "salesRadio",
                      },
                    },
                    [_vm._v(_vm._s(item.event_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.salesDialogShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.salesDownload },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.verviewDialogShow, center: "", width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.verviewDialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "report_centent" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.verviewform } },
                [
                  _vm.sign_up
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "认购统计维度:" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.order_type,
                                callback: function ($$v) {
                                  _vm.order_type = $$v
                                },
                                expression: "order_type",
                              },
                            },
                            [_vm._v("按销控变红统计")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.order_type,
                                callback: function ($$v) {
                                  _vm.order_type = $$v
                                },
                                expression: "order_type",
                              },
                            },
                            [_vm._v("按签约完成统计")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.sign_up
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "消控图展示:" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.type_show,
                                callback: function ($$v) {
                                  _vm.type_show = $$v
                                },
                                expression: "type_show",
                              },
                            },
                            [_vm._v("仅房源")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.type_show,
                                callback: function ($$v) {
                                  _vm.type_show = $$v
                                },
                                expression: "type_show",
                              },
                            },
                            [_vm._v("仅车位")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.type_show,
                                callback: function ($$v) {
                                  _vm.type_show = $$v
                                },
                                expression: "type_show",
                              },
                            },
                            [_vm._v("房源+车位")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "开盘总结:" } }, [
                    _c(
                      "div",
                      { staticClass: "form_item" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            placeholder: "1）开盘总结\n2）多点论述用@分隔",
                          },
                          model: {
                            value: _vm.verviewform.summarize,
                            callback: function ($$v) {
                              _vm.$set(_vm.verviewform, "summarize", $$v)
                            },
                            expression: "verviewform.summarize",
                          },
                        }),
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.advance,
                              callback: function ($$v) {
                                _vm.advance = $$v
                              },
                              expression: "advance",
                            },
                          },
                          [_vm._v("暂无")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.verviewDialogShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { loading: _vm.exportLoading, type: "button" },
                  on: { click: _vm.opendownloadverview },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.yxclass,
            center: "",
            "custom-class": "exportDialog",
            title: "意向分析类",
            width: "500",
          },
          on: {
            "update:visible": function ($event) {
              _vm.yxclass = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.classValue,
                    callback: function ($$v) {
                      _vm.classValue = $$v
                    },
                    expression: "classValue",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/exportcollect"],
                          expression: "['admin', 'index/exportcollect']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "0" },
                    },
                    [
                      _c("span", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v(_vm._s(_vm.customSetting.title) + "意向统计"),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/usercollect"],
                          expression: "['admin', 'index/usercollect']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "4" },
                    },
                    [_vm._v(" 客户意向统计 ")]
                  ),
                  _vm.customSetting.cate_room === 1 ||
                  _vm.customSetting.cate_car === 1
                    ? _c(
                        "el-checkbox",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "index/analyse"],
                              expression: "['admin', 'index/analyse']",
                            },
                          ],
                          staticClass: "check-block",
                          attrs: { label: "8" },
                        },
                        [
                          _vm._v(" 装户综合分析 "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "house-status-radio",
                              model: {
                                value: _vm.buildStatusRadio,
                                callback: function ($$v) {
                                  _vm.buildStatusRadio = $$v
                                },
                                expression: "buildStatusRadio",
                              },
                            },
                            _vm._l(_vm.event_list, function (item, i) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.id,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { label: i },
                                },
                                [_vm._v(_vm._s(item.event_name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.close("yxclass")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.downCheck },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.xsclass,
            center: "",
            "custom-class": "exportDialog",
            title: "销售进度类",
            width: "500",
          },
          on: {
            "update:visible": function ($event) {
              _vm.xsclass = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.classValue,
                    callback: function ($$v) {
                      _vm.classValue = $$v
                    },
                    expression: "classValue",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/exportroomstatus"],
                          expression: "['admin', 'index/exportroomstatus']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "1" },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.customSetting.title) + "状态表 "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "house-status-radio",
                          model: {
                            value: _vm.houseStatusradio,
                            callback: function ($$v) {
                              _vm.houseStatusradio = $$v
                            },
                            expression: "houseStatusradio",
                          },
                        },
                        _vm._l(_vm.event_list, function (item, index) {
                          return _c(
                            "el-radio",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: index },
                            },
                            [_vm._v(_vm._s(item.event_name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/userstatus"],
                          expression: "['admin', 'index/userstatus']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "5" },
                    },
                    [
                      _vm._v(" 客户状态表 "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "house-status-radio",
                          model: {
                            value: _vm.userStatusRadio,
                            callback: function ($$v) {
                              _vm.userStatusRadio = $$v
                            },
                            expression: "userStatusRadio",
                          },
                        },
                        _vm._l(_vm.event_list, function (item, i) {
                          return _c(
                            "el-radio",
                            {
                              key: item.id,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: i },
                            },
                            [_vm._v(_vm._s(item.event_name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/orderexport"],
                          expression: "['admin', 'index/orderexport']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "9" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm._v(" 订单记录表 "),
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "house-status-radio",
                              model: {
                                value: _vm.selectRadio,
                                callback: function ($$v) {
                                  _vm.selectRadio = $$v
                                },
                                expression: "selectRadio",
                              },
                            },
                            _vm._l(_vm.event_list, function (item, i) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.id,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { label: i },
                                },
                                [_vm._v(_vm._s(item.event_name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.close("xsclass")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.downCheck },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "report_centent",
          attrs: {
            visible: _vm.kpclass,
            center: "",
            title: "开盘总结报告",
            top: "10vh",
            width: "45%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.kpclass = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small", fill: "#22A271" },
                  model: {
                    value: _vm.kpRadio,
                    callback: function ($$v) {
                      _vm.kpRadio = $$v
                    },
                    expression: "kpRadio",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v(" 详细报告 "),
                  ]),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v(" 概况报告 "),
                  ]),
                ],
                1
              ),
              _vm.kpRadio == "0"
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.reportform } },
                        [
                          _vm.sign_up
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "认购统计维度:" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 0 },
                                      model: {
                                        value: _vm.order_type,
                                        callback: function ($$v) {
                                          _vm.order_type = $$v
                                        },
                                        expression: "order_type",
                                      },
                                    },
                                    [_vm._v("按销控变红统计")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      model: {
                                        value: _vm.order_type,
                                        callback: function ($$v) {
                                          _vm.order_type = $$v
                                        },
                                        expression: "order_type",
                                      },
                                    },
                                    [_vm._v("按签约完成统计")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "aaaaaaaa",
                              attrs: { label: "报告展示维度:" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "16px !important",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: _vm.handleCheckedCitiesChange,
                                      },
                                      model: {
                                        value: _vm.checkList,
                                        callback: function ($$v) {
                                          _vm.checkList = $$v
                                        },
                                        expression: "checkList",
                                      },
                                    },
                                    [
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: {
                                          label: "01开盘概况",
                                          disabled: "",
                                        },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: {
                                          label: "02销售情况",
                                          disabled: "",
                                        },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { label: "03客户情况" },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { label: "04销售排名" },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { label: "05开盘流程" },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { label: "06落位转化" },
                                      }),
                                      _c("el-checkbox", {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                        attrs: { label: "07总结及建议" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.summarizeShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "推进过程小结:" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form_item" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 4,
                                          placeholder:
                                            "1）项目配合及实施推进的优点、不足建议等\n2）多点论述用@分隔",
                                        },
                                        model: {
                                          value: _vm.reportform.advance,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reportform,
                                              "advance",
                                              $$v
                                            )
                                          },
                                          expression: "reportform.advance",
                                        },
                                      }),
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.advance,
                                            callback: function ($$v) {
                                              _vm.advance = $$v
                                            },
                                            expression: "advance",
                                          },
                                        },
                                        [_vm._v("暂无")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.summarizeShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "系统迭代建议:" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form_item" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 4,
                                          placeholder: "多点论述用@分隔",
                                        },
                                        model: {
                                          value: _vm.reportform.iteration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reportform,
                                              "iteration",
                                              $$v
                                            )
                                          },
                                          expression: "reportform.iteration",
                                        },
                                      }),
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.iteration,
                                            callback: function ($$v) {
                                              _vm.iteration = $$v
                                            },
                                            expression: "iteration",
                                          },
                                        },
                                        [_vm._v("暂无")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.summarizeShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "开盘流程建议:" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form_item" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 4,
                                          placeholder: "多点论述用@分隔",
                                        },
                                        model: {
                                          value: _vm.reportform.flowpath,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reportform,
                                              "flowpath",
                                              $$v
                                            )
                                          },
                                          expression: "reportform.flowpath",
                                        },
                                      }),
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.flowpath,
                                            callback: function ($$v) {
                                              _vm.flowpath = $$v
                                            },
                                            expression: "flowpath",
                                          },
                                        },
                                        [_vm._v("暂无")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "其它展示维度:" },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.xtRecommend,
                                    callback: function ($$v) {
                                      _vm.xtRecommend = $$v
                                    },
                                    expression: "xtRecommend",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                    },
                                    [_vm._v("系统推荐总结")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.verviewform } },
                        [
                          _vm.sign_up
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "认购统计维度:" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 0 },
                                      model: {
                                        value: _vm.order_type,
                                        callback: function ($$v) {
                                          _vm.order_type = $$v
                                        },
                                        expression: "order_type",
                                      },
                                    },
                                    [_vm._v("按销控变红统计")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      model: {
                                        value: _vm.order_type,
                                        callback: function ($$v) {
                                          _vm.order_type = $$v
                                        },
                                        expression: "order_type",
                                      },
                                    },
                                    [_vm._v("按签约完成统计")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.customSetting.cate_room === 1 ||
                          _vm.customSetting.cate_car == 1
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "aaaaaaaa bbbbbbbbb",
                                  attrs: { label: "销控图展示:" },
                                },
                                [
                                  _vm.customSetting.cate_room === 1
                                    ? _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 0 },
                                          model: {
                                            value: _vm.type_show,
                                            callback: function ($$v) {
                                              _vm.type_show = $$v
                                            },
                                            expression: "type_show",
                                          },
                                        },
                                        [_vm._v("仅房源")]
                                      )
                                    : _vm._e(),
                                  _vm.customSetting.cate_car == 1
                                    ? _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value: _vm.type_show,
                                            callback: function ($$v) {
                                              _vm.type_show = $$v
                                            },
                                            expression: "type_show",
                                          },
                                        },
                                        [_vm._v("仅车位")]
                                      )
                                    : _vm._e(),
                                  _vm.customSetting.cate_room === 1 &&
                                  _vm.customSetting.cate_car == 1
                                    ? _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 2 },
                                          model: {
                                            value: _vm.type_show,
                                            callback: function ($$v) {
                                              _vm.type_show = $$v
                                            },
                                            expression: "type_show",
                                          },
                                        },
                                        [_vm._v("房源+车位")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开盘总结:" } },
                            [
                              _c(
                                "div",
                                { staticClass: "form_item" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder:
                                        "1）开盘总结\n2）多点论述用@分隔",
                                    },
                                    model: {
                                      value: _vm.verviewform.summarize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.verviewform,
                                          "summarize",
                                          $$v
                                        )
                                      },
                                      expression: "verviewform.summarize",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: _vm.advance,
                                        callback: function ($$v) {
                                          _vm.advance = $$v
                                        },
                                        expression: "advance",
                                      },
                                    },
                                    [_vm._v("暂无")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "其它展示维度:" },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.xtRecommend,
                                    callback: function ($$v) {
                                      _vm.xtRecommend = $$v
                                    },
                                    expression: "xtRecommend",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                    },
                                    [_vm._v("系统推荐总结")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { padding: "0" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.close("kpclass")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.kpRadio == 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.openAcitveReport },
                    },
                    [_vm._v("下 载")]
                  )
                : _vm._e(),
              _vm.kpRadio == 1
                ? _c(
                    "el-button",
                    {
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.opendownloadverview },
                    },
                    [_vm._v("下 载")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.gxclass,
            center: "",
            "custom-class": "exportDialog",
            title: "个性化报表",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.gxclass = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "exportReview-wrapper" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.classValue,
                    callback: function ($$v) {
                      _vm.classValue = $$v
                    },
                    expression: "classValue",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/roomsaleexport"],
                          expression: "['admin', 'index/roomsaleexport']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "10" },
                    },
                    [
                      _vm._v(" 销售清单表 "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "house-status-radio",
                          model: {
                            value: _vm.salesRadio,
                            callback: function ($$v) {
                              _vm.salesRadio = $$v
                            },
                            expression: "salesRadio",
                          },
                        },
                        _vm._l(_vm.event_list, function (item, i) {
                          return _c(
                            "el-radio",
                            {
                              key: i,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: i },
                            },
                            [_vm._v(_vm._s(item.event_name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [
                            "admin",
                            "index/carpriceanalysis",
                            "index/roompriceanalysis",
                          ],
                          expression:
                            "['admin', 'index/carpriceanalysis', 'index/roompriceanalysis']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "11" },
                    },
                    [
                      _vm._v(" 项目货值分析 "),
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: { label: "选择活动" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "house-status-radio",
                                  staticStyle: { "margin-top": "12px" },
                                  model: {
                                    value: _vm.huozhi.salesEnevt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.huozhi, "salesEnevt", $$v)
                                    },
                                    expression: "huozhi.salesEnevt",
                                  },
                                },
                                _vm._l(_vm.event_list, function (item, i) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: i,
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { label: i },
                                    },
                                    [_vm._v(_vm._s(item.event_name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: { label: "定义已售" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "house-status-radio",
                                  staticStyle: { "margin-top": "12px" },
                                  model: {
                                    value: _vm.huozhi.sale_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.huozhi, "sale_type", $$v)
                                    },
                                    expression: "huozhi.sale_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { label: 0 },
                                    },
                                    [_vm._v("已下单")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { label: 1 },
                                    },
                                    [_vm._v("已交定金")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { label: 2 },
                                    },
                                    [_vm._v("已签约")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.customSetting.cate_room === 1
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: { label: "房源总价区间" },
                                },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "1em",
                                            },
                                          },
                                          [_vm._v("起始价")]
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "5em" },
                                          attrs: {
                                            size: "small",
                                            type: "number",
                                            min: 1,
                                          },
                                          on: { input: _vm.changeInput1 },
                                          model: {
                                            value:
                                              _vm.huozhi.room_total_interval
                                                .min,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.huozhi.room_total_interval,
                                                "min",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "huozhi.room_total_interval.min",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "1em",
                                            },
                                          },
                                          [_vm._v("万元")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" ， "),
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "1em",
                                            },
                                          },
                                          [_vm._v("区间段")]
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "5em" },
                                          attrs: {
                                            size: "small",
                                            type: "number",
                                            min: 1,
                                          },
                                          on: { input: _vm.changeInput2 },
                                          model: {
                                            value:
                                              _vm.huozhi.room_total_interval
                                                .interval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.huozhi.room_total_interval,
                                                "interval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "huozhi.room_total_interval.interval",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "1em",
                                            },
                                          },
                                          [_vm._v("万元")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.customSetting.cate_car == 1
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0px" },
                                  attrs: { label: "车位总价区间" },
                                },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "1em",
                                            },
                                          },
                                          [_vm._v("起始价")]
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "5em" },
                                          attrs: {
                                            size: "small",
                                            type: "number",
                                            min: 1,
                                          },
                                          on: { input: _vm.changeInput3 },
                                          model: {
                                            value:
                                              _vm.huozhi.car_total_interval.min,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.huozhi.car_total_interval,
                                                "min",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "huozhi.car_total_interval.min",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "1em",
                                            },
                                          },
                                          [_vm._v("万元")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" ， "),
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "1em",
                                            },
                                          },
                                          [_vm._v("区间段")]
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "5em" },
                                          attrs: {
                                            size: "small",
                                            type: "number",
                                            min: 1,
                                          },
                                          on: { input: _vm.changeInput4 },
                                          model: {
                                            value:
                                              _vm.huozhi.car_total_interval
                                                .interval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.huozhi.car_total_interval,
                                                "interval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "huozhi.car_total_interval.interval",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "1em",
                                            },
                                          },
                                          [_vm._v("万元")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/buildsale"],
                          expression: "['admin', 'index/buildsale']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "7" },
                    },
                    [
                      _vm._v(" 项目开盘去化 "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "house-status-radio",
                          model: {
                            value: _vm.buildStatusRadio,
                            callback: function ($$v) {
                              _vm.buildStatusRadio = $$v
                            },
                            expression: "buildStatusRadio",
                          },
                        },
                        _vm._l(_vm.event_list, function (item, i) {
                          return _c(
                            "el-radio",
                            {
                              key: i,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: i },
                            },
                            [_vm._v(_vm._s(item.event_name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "index/userorderexport"],
                          expression: "['admin', 'index/userorderexport']",
                        },
                      ],
                      staticClass: "check-block",
                      attrs: { label: "6" },
                    },
                    [
                      _vm._v(" 建发认购明细FID "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "house-status-radio",
                          model: {
                            value: _vm.renStatusRadio,
                            callback: function ($$v) {
                              _vm.renStatusRadio = $$v
                            },
                            expression: "renStatusRadio",
                          },
                        },
                        _vm._l(_vm.event_list, function (item, i) {
                          return _c(
                            "el-radio",
                            {
                              key: i,
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: i },
                            },
                            [_vm._v(_vm._s(item.event_name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.close("gxclass")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.downCheck },
                },
                [_vm._v("下 载")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-name" }, [
      _c("span", [_vm._v("客户端")]),
      _c("span", { staticClass: "card-name-next" }, [_vm._v("小程序码")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-name" }, [
      _c("span", [_vm._v("助手端")]),
      _c("span", { staticClass: "card-name-next" }, [_vm._v("小程序码")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-top" }, [
      _c("div", { staticClass: "right-btn" }, [
        _c("img", {
          attrs: { alt: "", src: require("@/assets/image/warning-icon.png") },
        }),
        _vm._v(" 异常提醒 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-top" }, [
      _c("div", { staticClass: "left-btn" }, [
        _c("img", {
          attrs: { alt: "", src: require("@/assets/image/todo.png") },
        }),
        _vm._v(" 待办 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }