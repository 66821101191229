//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ListItem',
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    getText: function getText(row) {
      if (row.log_rand_cound > 0 && row.user_count !== row.log_rand_cound) {
        return '继续排号';
      } else if (row.log_rand_cound > 0 && row.user_count === row.log_rand_cound) {
        return '排号结束';
      } else {
        return '开始排号';
      }
    }
  }
};