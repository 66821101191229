var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-wrapper" }, [
    _c("div", { staticClass: "footer-left" }),
    _c("div", { staticClass: "footer-text" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "footer-right" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }