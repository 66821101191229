import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { eventRandAdd, eventRandDel, eventRandEdit, lotteryPresetRoster, lotteryTelUser, orderQueryExport } from '@/api/lottery';
import { adminParameter } from '@/api/public';
import UploadExcel from '@/components/my-UploadExcel';
import utils from '@/utils/utils';
import { getpName } from '@/utils/auth';
import moment from 'moment';
export default {
  name: 'PresetList',
  components: {
    UploadExcel: UploadExcel
  },
  data: function data() {
    return {
      selectIndex: 0,
      // 接口加载中
      is_post: false,
      // 错误预设名单筛选
      filter: 0,
      // 导入姓名与系统姓名不一致
      filterUser: 0,
      // 是否显示 搜索出来的用户
      is_user: false,
      // 预设 弹框 title
      AddEdit: '增加一个预设客户',
      // 新增修改预设
      addEditForm: {
        tel: '',
        userList: '',
        code: ''
      },
      // 活动数据
      event_rend: {},
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      // 第一个页
      page: 1,
      // 分页 一页几条
      pageSize: 10,
      // 分页 总数
      total: 0,
      // 新增预设弹框
      isGrouping: false,
      //  客户列表
      tableData: [],
      // 页面搜索
      search: {
        inxId: 'all',
        round_id: 0,
        batch: 0,
        no_admin: 0,
        no_level: 0,
        no_round: 0,
        no_batch: 0,
        search: ''
      },
      // 列表搜索
      searchValue: '',
      list: {}
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 210;
    }
  },
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 初始化
  created: function created() {
    var _this2 = this;

    this.getTableList(); //  项目配置

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this2.list = list;
      }
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    getTableSearchList: function getTableSearchList() {
      this.page = 1;
      this.getTableList();
    },
    onOper: function onOper(key) {
      var _this3 = this;

      if (key === 'import') {
        this.page = 1;
        this.getTableList();
      } else if (key === 'export') {
        //  loading
        var loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
          id: this.$route.query.id
        });

        orderQueryExport(params).then(function (res) {
          if (res.status === 200) {
            _this3.$message({
              type: 'success',
              message: '导出成功'
            }); // 下载
            // window.location.href = this.host + res.data.path


            utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
            loading.close();
          }
        });
      }
    },
    resetForm: function resetForm() {
      this.page = 1;
      this.pageSize = 10;
      this.searchValue = '';
      this.filter = 0;
      this.filterUser = 0;
      this.getTableList();
    },
    handelFilterUser: function handelFilterUser() {
      this.getTableList();
    },
    // 筛选 预设名单
    handelFilter: function handelFilter() {
      this.getTableList();
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    // 打开错误原因
    handelError: function handelError(row) {
      this.$alert(row.err_status_info, '错误原因', {
        confirmButtonText: '确定',
        callback: function callback(action) {}
      });
    },
    // 打开修改弹框
    handelEditUser: function handelEditUser(row) {
      this.isGrouping = true;
      this.AddEdit = '修改预设'; // 查询 提交数据

      var data = {
        tel: row.tel,
        event_rand_id: this.$route.query.id
      }; // 查询 打开 user List

      this.is_user = true;
      this.addEditForm.tel = row.tel;
      this.addEditForm.code = row.code; // 当前行数据

      this.rowData = row; // 调用客户查询

      this.getUserList(data);
    },
    // 提交新增 修改
    handelAddPre: function handelAddPre() {
      var _this4 = this;

      // 判断 有没用户
      if (!this.addEditForm.userList || !this.is_user || !this.addEditForm.userList.length) {
        this.$message({
          type: 'error',
          message: '没有找到用户查看搜索条件'
        });
        return;
      } // 组装数据提交


      var data = {
        event_rand_id: this.$route.query.id,
        user_id: this.addEditForm.userList[this.selectIndex].id,
        code: this.addEditForm.code
      };

      if (this.AddEdit === '增加一个预设客户') {
        // 提交新增预设
        eventRandAdd(data).then(function (res) {
          if (res.status === 200) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            _this4.getTableList();

            _this4.isGrouping = false;

            _this4.$refs['formData'].resetFields();

            _this4.is_user = false;
          }
        });
      } else {
        // 修改加id
        data.id = this.rowData.id; // 提交修改预设

        eventRandEdit(data).then(function (res) {
          if (res.status === 200) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            _this4.getTableList();

            _this4.isGrouping = false;
          }
        });
      }
    },
    // 查找用户
    handelFind: function handelFind() {
      if (this.addEditForm.tel === '') {
        this.$message({
          type: 'error',
          message: '手机号码为空'
        });
        return;
      }

      this.is_user = true;
      var data = {
        tel: this.addEditForm.tel,
        event_rand_id: this.$route.query.id
      }; // 调用查找用户

      this.getUserList(data);
    },
    // 修改查找用户
    getUserList: function getUserList(data) {
      var _this5 = this;

      lotteryTelUser(data).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;

          if (JSON.stringify(list) == '{}') {
            _this5.addEditForm.userList = '';
          } else {
            _this5.addEditForm.userList = list;
          }
        }
      });
    },
    // 新增客户
    handelAddUser: function handelAddUser() {
      this.addEditForm = {
        tel: '',
        userList: '',
        code: ''
      };
      this.is_user = false;
      this.isGrouping = true;
      this.AddEdit = '增加一个预设客户';
    },
    // 删除 用户列表
    handelDelList: function handelDelList(id) {
      var _this6 = this;

      var data = {
        id: id,
        event_rand_id: this.$route.query.id
      };
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        eventRandDel(data).then(function (res) {
          if (res.status === 200) {
            _this6.$message({
              type: 'success',
              message: res.data.msg
            });

            _this6.getTableList();
          }
        });
      });
    },
    // 获取table 数据
    getTableList: function getTableList() {
      var _this7 = this;

      lotteryPresetRoster({
        id: this.$route.query.id,
        is_error: this.filter,
        is_name: this.filterUser,
        page: this.page,
        limit: this.pageSize,
        search: this.searchValue
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              count = _res$data.count,
              event = _res$data.event; // 表格数据

          _this7.tableData = list; // 活动数据

          _this7.event_rend = event; // 总数

          _this7.total = count; //  接口加载完成

          _this7.is_post = true;
        }
      });
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    },
    // 下载模板
    handleTemplate: function handleTemplate() {
      var url = this.host + '/template/rand/5.云销控-摇号预设导入模板.xlsx';
      utils.download_files(url, "".concat(getpName(), "-\u6447\u53F7\u9884\u8BBE\u5BFC\u5165-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
        showMessage: true
      });
    }
  }
};