var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "div",
        { ref: "top", staticClass: "search-box" },
        [
          _c(
            "el-form",
            { ref: "formData", attrs: { model: _vm.search } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "el-height", attrs: { span: 4 } },
                    [
                      _vm.is_level
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "组别",
                                "label-width": "50px",
                                prop: "level_id",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "全部",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.search.level_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "level_id", $$v)
                                    },
                                    expression: "search.level_id",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 0, label: "全部" },
                                  }),
                                  _vm._l(_vm.levelInfo, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.level,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "el-height", attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "轮次",
                            "label-width": "50px",
                            prop: "round_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "全部",
                                size: "small",
                              },
                              model: {
                                value: _vm.search.round_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "round_id", $$v)
                                },
                                expression: "search.round_id",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 0, label: "全部" },
                              }),
                              _vm._l(_vm.roundInfo, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.num, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否购房：" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small", placeholder: "请选择" },
                              model: {
                                value: _vm.search.is_order,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "is_order", $$v)
                                },
                                expression: "search.is_order",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: { label: "未选房", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "flex el-height", attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "is_set" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "false-label": 0, "true-label": 1 },
                              model: {
                                value: _vm.search.is_set,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "is_set", $$v)
                                },
                                expression: "search.is_set",
                              },
                            },
                            [_vm._v("预设客户")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mL10",
                          attrs: { prop: "is_admission" },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "false-label": 0, "true-label": 1 },
                              model: {
                                value: _vm.search.is_admission,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "is_admission", $$v)
                                },
                                expression: "search.is_admission",
                              },
                            },
                            [_vm._v("当前活动未入场")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "el-height", attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { prop: "search" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items" },
                          [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "on",
                                placeholder:
                                  "姓名\\手机\\" + _vm.list.user_code_name,
                                size: "small",
                                type: "text",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getTableInfo($event)
                                },
                              },
                              model: {
                                value: _vm.search.search,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "search", $$v)
                                },
                                expression: "search.search",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "flex-shrink mL10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "greens-btn",
                                    attrs: { size: "small" },
                                    on: { click: _vm.getTableInfo },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btnHollowReset",
                                    attrs: { size: "small" },
                                    on: { click: _vm.resetForm },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "block-bottom mT16" }, [
        _c(
          "div",
          {
            staticClass: "button-box flex space-between",
            staticStyle: { "margin-top": "-10px" },
          },
          [
            _c("div", [
              _c("div", { staticClass: "title flex align-items" }, [
                _c("span", { staticClass: "mL10" }, [
                  _vm._v(_vm._s(_vm.event.name)),
                ]),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "eventrand/syncuser"],
                        expression: "['admin', 'eventrand/syncuser']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.openTransfer },
                  },
                  [_vm._v("转入分批序号")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "eventrand/resetlog"],
                        expression: "['admin', 'eventrand/resetlog']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.onResetlog },
                  },
                  [_vm._v("批量作废")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.handleExport },
                  },
                  [_vm._v("导出数据")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  "header-cell-style": { background: "#fafafa" },
                  height: _vm.tableHeight,
                  border: "",
                  "highlight-current-row": "",
                  size: "medium",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "序号",
                    type: "index",
                    width: "50",
                  },
                }),
                _vm.is_level
                  ? _c("el-table-column", {
                      attrs: { align: "center", label: "组别", prop: "level" },
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: { align: "center", label: "轮次 - 序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c("span", [
                            _vm._v(
                              "第 " +
                                _vm._s(scoped.row.round_num) +
                                " 轮 - " +
                                _vm._s(scoped.row.code) +
                                " 号"
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "姓名", prop: "name" },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "登录手机号", prop: "tel" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: _vm.list.user_code_name,
                    prop: "cy_code",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "身份证号", prop: "card" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "入场情况",
                    prop: "rc_status",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "created_at",
                    label: "摇出时间",
                    "min-width": "120px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.doTimeToDate(scope.row.created_at * 1000)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "查看区块链",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      scope.row.baas_hash
                                        ? _c("svg-icon", {
                                            attrs: {
                                              "class-name": "green green-icon",
                                              "icon-class": "baas",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.lookBaas(scope.row)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                layout: _vm.layouts,
                "page-size": 10,
                "page-sizes": _vm.sizes,
                total: _vm.total,
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isDialog,
            center: "",
            "destroy-on-close": "",
            title: "批量作废",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog", staticStyle: { padding: "0px 20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "detail-item green",
                  staticStyle: { "justify-content": "space-between" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text", staticStyle: { margin: "0" } },
                    [_vm._v("作废成功")]
                  ),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.info.suc || 0) + "条"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "detail-item red",
                  staticStyle: { "justify-content": "space-between" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "text", staticStyle: { margin: "0" } },
                    [_vm._v("作废失败")]
                  ),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.info.err || 0) + "条"),
                  ]),
                ]
              ),
              _vm.info.err_user
                ? _c("div", { staticClass: "detail-item red" }, [
                    _c(
                      "span",
                      { staticClass: "text", staticStyle: { margin: "0" } },
                      [_vm._v("客户异常: " + _vm._s(_vm.info.err_user))]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "orange-btn",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isDialog = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.transferDialog,
            center: "",
            title: "转入分批序号",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.transferDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog", staticStyle: { padding: "0px 20px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.rand_type,
                    callback: function ($$v) {
                      _vm.rand_type = $$v
                    },
                    expression: "rand_type",
                  },
                },
                [_vm._v("按轮次顺序")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.rand_type,
                    callback: function ($$v) {
                      _vm.rand_type = $$v
                    },
                    expression: "rand_type",
                  },
                },
                [_vm._v("按序号顺序")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.transferDialog = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.confimtransfer },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }