var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "custom-focus-wrapper" }, [
    _c("div", { ref: "apptop", staticClass: "preference" }, [
      _vm.projectList.room_info_preference === 1
        ? _c(
            "div",
            { staticClass: "preference-wrapper" },
            [
              _c("div", { staticClass: "preference-title" }, [
                _vm._v("他的偏好"),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.customSetting.cate_room == 1 ||
                            _vm.customSetting.cate_villa == 1,
                          expression:
                            "customSetting.cate_room == 1 || customSetting.cate_villa == 1",
                        },
                      ],
                      attrs: { span: 16 },
                    },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("首付款："),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.preference.first_pay != undefined,
                                  expression:
                                    "preference.first_pay != undefined",
                                },
                              ],
                              staticClass: "text",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.preference.first_pay) +
                                  "成/" +
                                  _vm._s(
                                    _vm.preference.types == 1
                                      ? "我不贷款"
                                      : _vm.preference.types == 2
                                      ? "商业贷款"
                                      : "公积金贷款"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("总价预期："),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.preference.price)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(_vm.customSetting.custom_data.house_type) +
                                "偏好："
                            ),
                          ]),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.preference.cate_name)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.customSetting.cate_room == 1,
                          expression: "customSetting.cate_room == 1",
                        },
                      ],
                      attrs: { span: 16 },
                    },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v("楼层偏好："),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.preference.floor_yes_name,
                                  expression: "preference.floor_yes_name",
                                },
                              ],
                              staticClass: "text",
                            },
                            [
                              _vm._v(
                                "喜欢" + _vm._s(_vm.preference.floor_yes_name)
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "detail-item" }, [
                          _c("div", { staticClass: "name" }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.preference.floor_no_name,
                                  expression: "preference.floor_no_name",
                                },
                              ],
                              staticClass: "text",
                            },
                            [
                              _vm._v(
                                "不喜欢" + _vm._s(_vm.preference.floor_no_name)
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              "header-cell-style": { background: "#fafafa" },
              height: _vm.tableHeight,
              border: "",
              "highlight-current-row": "",
              size: "medium",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "意向排序",
                prop: "sort",
                width: "90",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.customSetting.custom_data.house_num,
                align: "justify",
                prop: "room",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.customSetting.custom_data.house_type,
                align: "justify",
                prop: "name",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "justify",
                label: "热度值",
                prop: "hot_status",
                width: "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.hot_status == 1
                                ? "低"
                                : scope.row.hot_status == 2
                                ? "中低"
                                : scope.row.hot_status == 3
                                ? "中高"
                                : "高"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "justify",
                label: "第一意向人数",
                prop: "one_count",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "justify",
                label: "其余意向人数",
                prop: "other_count",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "收藏时间", prop: "date" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            scope.row.created_at * 1000,
                            "YYYY-MM-DD HH:mm"
                          )
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "justify",
                label: "当前状态",
                prop: "status",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        _vm._s(
                          scope.row.status == 1
                            ? "可选"
                            : scope.row.status == 2
                            ? "售罄"
                            : "被此客户选定"
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }