var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "bgc" }, [
      _vm.config.rand.logo
        ? _c("img", {
            attrs: { src: _vm.imgUrl + _vm.config.rand.logo, alt: "" },
          })
        : _c("img", { attrs: { src: require("@/assets/page/bg.png") } }),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "page" }, [
        _c("div"),
        _c("div", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.config.rand.rand_name)),
        ]),
        _vm._m(0),
      ]),
      _c(
        "div",
        { staticClass: "project-content" },
        _vm._l(_vm.grouping, function (items, index) {
          return _c("rounds-vue", {
            key: index,
            staticClass: "rounds-list",
            style: { width: _vm.config.rand.user_class === 3 ? "100%" : "50%" },
            attrs: {
              flicker:
                _vm.config.repeat_num.includes(items.num) &&
                items.children.length < 2,
              "new-class": _vm.config.new_user_class,
              user_class: _vm.config.rand.user_class,
              "flicker-child": items.children.length > 1,
              ranking: "第" + items.num + "轮",
              "number-list": items.children,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-colour" }, [
      _c("div", [
        _c("span", { staticClass: "color-lump valid" }),
        _vm._v("有效号码"),
      ]),
      _c("div", [
        _c("span", { staticClass: "color-lump abandon" }),
        _vm._v("废号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }