var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "margin-top-20" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.outContent,
          expression: "outContent",
        },
      ],
      attrs: { id: _vm.id, name: "content" },
      domProps: { value: _vm.outContent },
      on: {
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.outContent = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }