import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { resLists, examine } from '@/api/reserve';
import { mapGetters } from 'vuex';
export default {
  name: 'ApprovalDetail',
  data: function data() {
    return {
      // 搜索关键字
      searchText: '',
      // 表格高度
      // tableHeight: '',
      appHeight: '',
      topHeight: '',
      // 无客户房源 导入姓名不一致
      checkList: [],
      // 头部背景色
      bg: '#bfbfbf',
      // 头部字体
      color: '',
      // 分页
      currentPage: 0,
      // 总数
      total: 0,
      // 页数 page
      page: 1,
      // 分页 一页几条
      pageSize: 10,
      // 条数限制
      limit: 10,
      // 表格选中数量
      checkedNum: 0,
      // 表格数据
      tableData: [],
      // 表格版本
      eventReserve: '',
      // 是否显示驳回弹框
      dialogVisible: false,
      // 驳回理由
      textarea: '',
      // 房间总数 客户总数
      roomCount: '',
      userCount: '',
      countText: '',
      // 是否需要审核 0 无需 1需要
      isNeedApproval: 1
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 94;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.apptop.offsetHeight;
    }; // --------接口相关------------


    this.getTableData();
  },
  methods: {
    // 表格选择改变
    selectionChange: function selectionChange(e) {
      // 选中条数
      var num = e.length;
      this.checkedNum = num; // 选中的列表

      var checkList = [];
      e.forEach(function (element) {
        checkList.push(element.id);
      });
      this.checkedList = checkList;
    },
    // 搜索
    subSearch: function subSearch() {
      this.getTableData();
    },
    // 清空搜索内容
    clear: function clear() {
      this.searchText = '';
      this.getTableData();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.limit = val;
      this.getTableData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page = val;
      this.getTableData();
    },
    // 表格头部 状态
    statusFun: function statusFun(type) {
      switch (type) {
        case 0:
          this.bg = 'rgba(191, 191, 191, 0.15)';
          this.color = 'rgb(191, 191, 191)';
          return '未提交审核';

        case 1:
          this.bg = 'rgba(255, 141, 55, 0.15)';
          this.color = 'rgb(255, 141, 55)';
          return '审批中';

        case 2:
          this.bg = 'rgba(91, 209, 113, 0.15)';
          this.color = 'rgb(91, 209, 113)';
          return '审批通过';

        case 3:
          this.bg = 'rgba(245, 34, 45, 0.15)';
          this.color = 'rgb(245, 34, 45)';
          return '审批驳回';

        case 4:
          this.bg = 'rgba(191, 191, 191, 0.15)';
          this.color = 'rgb(191, 191, 191)';
          return '未提交审核';
      }
    },
    // table 表格数据
    getTableData: function getTableData() {
      var _this2 = this;

      var params = {
        search: this.searchText,
        page: this.page,
        limit: this.limit
      };
      resLists(params).then(function (res) {
        _this2.total = res.data.count;
        _this2.tableData = res.data.eventReserveUser;
        _this2.eventReserve = res.data.eventReserve; // 是否需要审批

        _this2.isNeedApproval = res.data.ReservedAudit;
        _this2.roomCount = res.data.roomCount;
        _this2.userCount = res.data.userCount;

        _this2.getText(res.data.roomCount, res.data.roomCount2, res.data.userCount);
      });
    },
    getText: function getText(roomCount, roomCount2, userCount) {
      var text = [];

      if (roomCount && roomCount != 0 && roomCount != '') {
        text.push("".concat(roomCount, "\u5957\u623F\u6E90"));
      }

      if (roomCount2 && roomCount2 != 0 && roomCount2 != '') {
        text.push("".concat(roomCount2, "\u4E2A\u8F66\u4F4D"));
      }

      if (userCount && userCount != 0 && userCount != '') {
        text.push("".concat(userCount, "\u4E2A\u5BA2\u6237"));
      }

      this.countText = text.join(',');
    },
    // 通过  驳回
    adopt: function adopt() {
      var _this3 = this;

      var params = {
        status: 2
      };
      this.$confirm('通过审批, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        examine(params).then(function (res) {
          if (res.status === 200) {
            _this3.$message({
              type: 'success',
              message: res.data.msg
            });

            _this3.getTableData();
          }
        });
      }).catch(function () {
        return;
      });
    },
    reject: function reject() {
      this.dialogVisible = true;
    },
    cancelReject: function cancelReject() {
      this.textarea = '';
      this.dialogVisible = false;
    },
    //  驳回理由提交
    submit: function submit() {
      var _this4 = this;

      var params = {
        status: 3,
        desc: this.textarea
      };

      if (this.textarea.trim()) {
        examine(params).then(function (res) {
          if (res.status === 200) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            _this4.cancelReject();

            _this4.getTableData();
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: '请输入驳回理由'
        });
      }
    }
  }
};