/**
 * @author: lm,
 * @date: 2021-12-30 10:23:20,
 */
import request from '@/utils/request'; // 搜索登记用户

export function userList(data) {
  return request({
    url: '/sendNo/search',
    method: 'post',
    data: data
  });
} //  派号类型列表

export function numberList(params) {
  return request({
    url: '/sendNo/index',
    method: 'get',
    params: params
  });
} //  占/派号

export function bindNum(data) {
  return request({
    url: '/sendNo/bind',
    method: 'post',
    data: data
  });
} //  退、释放号

export function unbindNum(data) {
  return request({
    url: '/sendNo/unBind',
    method: 'post',
    data: data
  });
} // 号码详情

export function numInfo(data) {
  return request({
    url: '/sendNo/numInfo',
    method: 'post',
    data: data
  });
} // 打印认筹单

export function printInfo(params) {
  return request({
    url: '/sendNo/print',
    method: 'post',
    params: params
  });
} // 真实排号

export function sendNoSelect(params) {
  return request({
    url: '/sendNo/select',
    method: 'get',
    params: params
  });
} // 末位排号

export function sendNoAutoSelect(params) {
  return request({
    url: '/sendNo/autoSelect',
    method: 'get',
    params: params
  });
} // 末位排号

export function sendNoDelBind(data) {
  return request({
    url: 'sendNo/delBind',
    method: 'post',
    data: data
  });
}