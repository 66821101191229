import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import vueSeamlessScroll from 'vue-seamless-scroll';
import CountDown from '@/components/countDown';
import CountDownb from '@/components/countDownb';
import { getLedShow, getActiveTime } from '@/api/screen';
export default {
  name: 'ScreenRight',
  components: {
    vueSeamlessScroll: vueSeamlessScroll,
    CountDown: CountDown,
    CountDownb: CountDownb
  },
  props: {
    cardType: {
      // card显示户型图 list显示选房记录
      type: String,
      default: 'card'
    },
    listData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 显示配置
    ledInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 倒计时开始时间 结束时间
    beginTime: {
      type: Number,
      default: 0
    },
    endTime: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      countDown: 'countDownb'
    };
  },
  computed: {
    classOption: function classOption() {
      return {
        step: 0.3,
        // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)

      };
    }
  },
  methods: {
    // 倒计时结束
    countDownOver: function countDownOver() {
      this.$emit('countDownOver');
    }
  }
};