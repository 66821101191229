var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "block-bottom mT16", staticStyle: { "margin-top": "0" } },
      [
        _c("div", { staticClass: "button-box" }, [
          _c(
            "div",
            { staticClass: "flex-space-between" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm.LODOPExeName
                      ? _c(
                          "div",
                          {
                            staticClass: "start_page-content-desc-wrapper",
                            staticStyle: { "margin-top": "0" },
                          },
                          [
                            _c("span", [_vm._v("请检查打印驱动是否安装正常")]),
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "start_page-content-desc-download-text",
                                  attrs: {
                                    href: "打印驱动程序.zip",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("下载打印驱动程序")]
                              ),
                            ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: _vm.LODOPExeName ? 12 : 24 } },
                    [
                      _c("div", { staticClass: "right-wrapper" }, [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "btnHollowGreen",
                                on: {
                                  click: function ($event) {
                                    return _vm.onOper("preview")
                                  },
                                },
                              },
                              [_vm._v(" 打印预览 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOper("submit")
                                  },
                                },
                              },
                              [_vm._v(" 保存 ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "table-box" }, [
          _c(
            "div",
            {
              staticStyle: { display: "flex", "justify-content": "flex-start" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "300px",
                    "min-height": "50px",
                    padding: "0 10px 10px 10px",
                    border: "1px solid rgb(204, 204, 204)",
                    overflow: "hidden",
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "flex-start",
                      "flex-wrap": "nowrap",
                      height: "40px",
                    },
                  }),
                  _vm._l(_vm.signinprint.list, function (item, index) {
                    return [
                      item.var_name === "qr_code"
                        ? _c("div", { key: index }, [
                            _c("img", {
                              style: {
                                margin: item.spacing + "px 0px",
                              },
                              attrs: {
                                width: item.font_size,
                                height: item.font_size,
                                src: require("../../../../assets/qr_code.png"),
                                alt: "二维码示例",
                              },
                            }),
                          ])
                        : _c(
                            "div",
                            {
                              key: index,
                              style: {
                                fontSize: item.font_size * 2 + "px",
                                fontWeight: item.bold === 1 ? "bold" : "",
                                textAlign:
                                  item.alignment === 2
                                    ? "center"
                                    : item.alignment === 3
                                    ? "right"
                                    : "left",
                                margin: item.spacing / 2 + "px 0px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.title +
                                      (item.title &&
                                      _vm.signinprint.var_list[item.var_name]
                                        ? "："
                                        : "") +
                                      (_vm.signinprint.var_list[
                                        item.var_name
                                      ] || "")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "calc(100% - 300px)",
                    "min-height": "50px",
                  },
                },
                [
                  _vm._m(0),
                  _vm._l(_vm.signinprint.list, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "hover-list" },
                      [
                        _c("div", [_vm._v(_vm._s(index + 1))]),
                        _c("el-input", {
                          attrs: { placeholder: "标题" },
                          model: {
                            value: item.title,
                            callback: function ($$v) {
                              _vm.$set(item, "title", $$v)
                            },
                            expression: "item.title",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择内容" },
                            model: {
                              value: item.var_name,
                              callback: function ($$v) {
                                _vm.$set(item, "var_name", $$v)
                              },
                              expression: "item.var_name",
                            },
                          },
                          _vm._l(_vm.signinprint.var_list, function (ite, key) {
                            return _c("el-option", {
                              key: key,
                              attrs: { label: ite, value: key },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择位置" },
                            model: {
                              value: item.alignment,
                              callback: function ($$v) {
                                _vm.$set(item, "alignment", $$v)
                              },
                              expression: "item.alignment",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不设置", value: 0 },
                            }),
                            _c("el-option", {
                              attrs: { label: "居左", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "居中", value: 2 },
                            }),
                            _c("el-option", {
                              attrs: { label: "居右", value: 3 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择是否加粗" },
                            model: {
                              value: item.bold,
                              callback: function ($$v) {
                                _vm.$set(item, "bold", $$v)
                              },
                              expression: "item.bold",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不加粗", value: 0 },
                            }),
                            _c("el-option", {
                              attrs: { label: "加粗", value: 1 },
                            }),
                          ],
                          1
                        ),
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            min: 10,
                            max: 300,
                            placeholder: "字体大小",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.onInputBlur("font_size", index)
                            },
                          },
                          model: {
                            value: item.font_size,
                            callback: function ($$v) {
                              _vm.$set(item, "font_size", $$v)
                            },
                            expression: "item.font_size",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            max: 300,
                            placeholder: "行间距",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.onInputBlur("spacing", index)
                            },
                          },
                          model: {
                            value: item.spacing,
                            callback: function ($$v) {
                              _vm.$set(item, "spacing", $$v)
                            },
                            expression: "item.spacing",
                          },
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "向上换位",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-top",
                                  staticStyle: {
                                    "margin-right": "15px",
                                    padding: "10px 0",
                                    color: "#22a271",
                                    "font-size": "18px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOper("top", index)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "向下换位",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-bottom",
                                  staticStyle: {
                                    "margin-right": "15px",
                                    padding: "10px 0",
                                    color: "#22a271",
                                    "font-size": "18px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOper("bottom", index)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticStyle: {
                                    "margin-top": "10px",
                                    "font-size": "18px",
                                  },
                                  attrs: {
                                    "class-name": "reds",
                                    "icon-class": "delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOper("del", index)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "button-xian",
                      on: {
                        click: function ($event) {
                          return _vm.onOper("add")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _c("span", [_vm._v("新增")]),
                    ]
                  ),
                ],
                2
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "hover-list",
        staticStyle: { "background-color": "rgb(245, 245, 245)" },
      },
      [
        _c("div", [_vm._v("序号")]),
        _c("div", [_vm._v("标题")]),
        _c("div", [_vm._v("内容")]),
        _c("div", [_vm._v("字体位置")]),
        _c("div", [_vm._v("字体加粗")]),
        _c("div", [_vm._v("字体大小")]),
        _c("div", [_vm._v("行上下间距")]),
        _c("div", [_vm._v("操作")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }