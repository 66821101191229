var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "button-box flex space-between" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleBatchDel },
              },
              [_vm._v("批量删除 ")]
            ),
            _vm.isPC
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/reset"],
                        expression: "['admin', 'admins/reset']",
                      },
                    ],
                    staticClass: "greens-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.handleResetPasswords },
                  },
                  [_vm._v("批量重置密码 ")]
                )
              : _vm._e(),
            !_vm.isPC
              ? _c(
                  "el-button",
                  {
                    staticClass: "greens-btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.is_button = true
                      },
                    },
                  },
                  [_vm._v("更多 ")]
                )
              : _vm._e(),
            _vm.numbers > 0
              ? _c("span", { staticClass: "numberSpan mL10" }, [
                  _vm._v("已选中" + _vm._s(_vm.numbers) + "条"),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.isPC
          ? _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "jian-fa",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/syncjf"],
                        expression: "['admin', 'admins/syncjf']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { tag: "el-button" },
                    on: { click: _vm.syanGw },
                  },
                  [_vm._v(" 同步销售团队 ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/add"],
                        expression: "['admin', 'admins/add']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("新增一个人员 ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnHollowGreen mL10",
                    attrs: { size: "small" },
                    on: { click: _vm.handleDown },
                  },
                  [_vm._v("下载模板 ")]
                ),
                _c("UploadExcel", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "admins/import"],
                      expression: "['admin', 'admins/import']",
                    },
                  ],
                  staticClass: "mL10",
                  attrs: {
                    text: "导入",
                    url: "/admins/Import",
                    "data-type": "admin",
                    accept: _vm.$xmlSel,
                  },
                  on: { uploadSuccess: _vm.getListData },
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/send"],
                        expression: "['admin', 'admins/send']",
                      },
                    ],
                    staticClass: "btnHollowGreen mL10",
                    attrs: { size: "small" },
                    on: { click: _vm.handleInitialPassword },
                  },
                  [_vm._v("发送密码 ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/export"],
                        expression: "['admin', 'admins/export']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.getAdminExport },
                  },
                  [_vm._v("导出复核 ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              ref: "personnel",
              attrs: {
                border: "",
                "highlight-current-row": "",
                size: "medium",
                data: _vm.tableData,
                height: _vm.tableHeight,
                "header-cell-style": { background: "#fafafa" },
                "element-loading-background": "rgba(0, 0, 0, 0.5)",
                "element-loading-text": "数据正在加载中",
                "element-loading-spinner": "el-icon-loading",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "index",
                  label: "序号",
                  width: "70",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "justify", label: "姓名", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _c(
                          "span",
                          { class: scoped.row.is_saas === 0 ? "" : "red" },
                          [_vm._v(_vm._s(scoped.row.name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _c(
                          "span",
                          { class: scoped.row.is_saas === 0 ? "" : "red" },
                          [_vm._v(_vm._s(scoped.row.tel || scoped.row.code))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "justify", prop: "role_name", label: "岗位" },
              }),
              _c("el-table-column", {
                attrs: { align: "justify", prop: "team_name", label: "团队" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "justify",
                  prop: "is_online",
                  label: "登录状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scoped.row.is_online ? "已登录" : "未登录") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "justify", prop: "remarks", label: "其他信息" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "ren",
                  label: "账号状态",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "admins/status"],
                                expression: "['admin', 'admins/status']",
                              },
                            ],
                            staticClass: "flex align-items ju-center",
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                disabled: scoped.row.is_saas === 1,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleStatus(scoped.row)
                                },
                              },
                              model: {
                                value: scoped.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scoped.row, "status", $$v)
                                },
                                expression: "scoped.row.status",
                              },
                            }),
                            scoped.row.status
                              ? _c("span", { staticClass: "status1 mL10" }, [
                                  _vm._v("启用"),
                                ])
                              : _c("span", { staticClass: "status2 mL10" }, [
                                  _vm._v("禁用"),
                                ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "yue",
                  label: "操作",
                  width: "150",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "重置密码",
                              placement: "top-end",
                            },
                          },
                          [
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "admins/reset"],
                                  expression: "['admin', 'admins/reset']",
                                },
                              ],
                              attrs: {
                                "class-name": "greens",
                                "icon-class": "resetPass",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleResetPassword(scoped.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top-end",
                            },
                          },
                          [
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "admins/edit"],
                                  expression: "['admin', 'admins/edit']",
                                },
                              ],
                              attrs: {
                                "class-name": "greens",
                                "icon-class": "edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scoped.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "top-end",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled:
                                    scoped.row.is_saas === 1 || scoped.row.led,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(scoped.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "admins/del"],
                                      expression: "['admin', 'admins/del']",
                                    },
                                  ],
                                  class: {
                                    "disable-css":
                                      scoped.row.is_saas === 1 ||
                                      scoped.row.led,
                                  },
                                  attrs: {
                                    "class-name": "greens",
                                    "icon-class": "delete",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              small: _vm.isPC ? false : true,
              "page-sizes": _vm.sizes,
              "page-size": _vm.info.limit,
              "current-page": _vm.page,
              layout: _vm.isPC ? _vm.layouts : _vm.telLayout,
              total: _vm.total,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作按钮",
            visible: _vm.is_button,
            width: "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.is_button = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "admins/reset"],
                          expression: "['admin', 'admins/reset']",
                        },
                      ],
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.handleResetPasswords },
                    },
                    [_vm._v("批量重置密码 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "admins/add"],
                          expression: "['admin', 'admins/add']",
                        },
                      ],
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新增一个人员 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen mL10",
                      attrs: { size: "small" },
                      on: { click: _vm.handleDown },
                    },
                    [_vm._v("下载模板 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("UploadExcel", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "admins/import"],
                        expression: "['admin', 'admins/import']",
                      },
                    ],
                    staticClass: "mL10",
                    attrs: {
                      text: "导入",
                      url: "/admins/Import",
                      "data-type": "admin",
                    },
                    on: { uploadSuccess: _vm.getListData },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "admins/send"],
                          expression: "['admin', 'admins/send']",
                        },
                      ],
                      staticClass: "btnHollowGreen mL10",
                      attrs: { size: "small" },
                      on: { click: _vm.handleInitialPassword },
                    },
                    [_vm._v("发送密码 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "admins/export"],
                          expression: "['admin', 'admins/export']",
                        },
                      ],
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: { click: _vm.getAdminExport },
                    },
                    [_vm._v("导出复核 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editAddText,
            visible: _vm.dialogStaff,
            width: "500px",
            center: "",
            closeOnClickModal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStaff = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入内容" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "tel" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      type: "number",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "岗位", prop: "role" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        multiple: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.role,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form,
                            "role",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.role",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "其他", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入内容" },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "其他系统FID", prop: "fid" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "请输入内容" },
                    model: {
                      value: _vm.form.fid,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fid", $$v)
                      },
                      expression: "form.fid",
                    },
                  }),
                ],
                1
              ),
              _vm.editAddText === "editAddText"
                ? _c(
                    "el-form-item",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.is_send,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_send", $$v)
                            },
                            expression: "form.is_send",
                          },
                        },
                        [_vm._v("将初始密码通过短信发送给此人员 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStaff = false
                    },
                  },
                },
                [_vm._v("取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleAddEdit },
                },
                [_vm._v("确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }