var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.is_null
        ? _c(
            "div",
            { staticClass: "noData" },
            [
              _c("img", {
                staticClass: "onImage",
                attrs: { src: require("../../assets/image/icon-qs.png") },
              }),
              _c("div", { staticClass: "onTips" }, [
                _vm._v("还没完善活动计划哦~"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "mini" },
                  on: { click: _vm.handlePerfect },
                },
                [_vm._v("去完善")]
              ),
            ],
            1
          )
        : [
            _c("div", { staticClass: "top-fixed" }, [
              _c("div", { staticClass: "title-top-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("活动计划")]),
                _c(
                  "div",
                  [
                    _vm.type === "add"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "orange-btn",
                            attrs: { icon: "el-icon-plus", size: "small" },
                            on: { click: _vm.handelAddActivity },
                          },
                          [_vm._v(" 添加测试活动 ")]
                        )
                      : _vm._e(),
                    _vm.type === "add"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "event/preserve"],
                                expression: "['admin', 'event/preserve']",
                              },
                            ],
                            staticClass: "greens-btn",
                            attrs: { size: "small" },
                            on: { click: _vm.handelSaveIf },
                          },
                          [_vm._v("保存 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "event/preserve"],
                                expression: "['admin', 'event/preserve']",
                              },
                            ],
                            staticClass: "greens-btn",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.type = "add"
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "detail-box mT10", attrs: { id: "new_message" } },
              [
                _c("div", { staticClass: "block" }, [
                  _c(
                    "div",
                    { staticClass: "title-h2 flex space-between align-items" },
                    [_vm._v("活动时间设置")]
                  ),
                  _c(
                    "div",
                    { staticClass: "model" },
                    [
                      !_vm.isPC
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm.is_show
                                    ? _c("van-switch", {
                                        ref: "tabSwitch",
                                        staticClass: "van-switch",
                                        attrs: {
                                          type: "num",
                                          "switch-list": _vm.switchList,
                                        },
                                        on: { bindSwitch: _vm.handelTab },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "align-right",
                                  class: _vm.isPC ? "" : "mT10",
                                  attrs: { span: 24 },
                                },
                                [
                                  _vm.type === "add" && _vm.isPC
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "orange-btn",
                                          attrs: {
                                            icon: "el-icon-plus",
                                            size: "small",
                                          },
                                          on: { click: _vm.handelAddActivity },
                                        },
                                        [_vm._v(" 添加测试活动 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _vm.is_show
                                    ? _c("van-switch", {
                                        ref: "tabSwitch",
                                        staticClass: "van-switch",
                                        attrs: {
                                          type: "num",
                                          "switch-list": _vm.switchList,
                                        },
                                        on: { bindSwitch: _vm.handelTab },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _vm.is_show
                        ? _c("tested", {
                            attrs: {
                              list: _vm.moduleData,
                              type: _vm.type,
                              index: _vm.tabIndex,
                            },
                            on: {
                              handelRoundsDel: _vm.handelRoundsDel,
                              handelDel: _vm.handelDelActivity,
                              handelEdit: _vm.handelEditList,
                              handelIsSo: _vm.handelIsSo,
                              getFormalStartTime: _vm.getFormalStartTime,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.project.buy_type === 1 && _vm.buy_rand === 0
                  ? _c("div", { staticClass: "block label-width" }, [
                      _c("div", { staticClass: "title-h2" }, [
                        _vm._v("批次计划"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "model" },
                        [
                          _c(
                            "el-row",
                            { staticClass: "list-bg" },
                            [
                              _c(
                                "el-form",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-block flex align-items",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "left" },
                                        [
                                          _vm._v(" 分批认购: "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "",
                                                placement: "bottom-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 1.若想单独设置某个客户的认购时长（结束时间早于或晚于客户所在批次），请联系系统管理员。"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " 2.当最后一个批次的结束时间晚于活动结束时间，以活动时间为结束 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "tipss el-icon-question",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "right" },
                                        [
                                          _vm.type === "add"
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "flex" },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.batch.batch,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.batch,
                                                              "batch",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "batch.batch",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 1 },
                                                          },
                                                          [_vm._v("启用")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 0 },
                                                          },
                                                          [_vm._v("不启用")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _c(
                                                "span",
                                                { staticClass: "text6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.batch.batch == 1
                                                        ? "启用"
                                                        : "不启用"
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _vm.batch.batch == 1
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "item-block flex align-items",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left" },
                                              [
                                                _vm._v(" 间隔时长: "),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "下一批开始认购时间与上一批开始认购时间的间隔时长",
                                                      placement: "bottom-start",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipss el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm.type === "add"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("input-text", {
                                                            attrs: {
                                                              "text-value":
                                                                _vm.batch
                                                                  .interval_min,
                                                              "input-w": 100,
                                                              "input-text":
                                                                "分",
                                                            },
                                                            on: {
                                                              handleInputValue:
                                                                _vm.handleIntervalMin,
                                                            },
                                                          }),
                                                          _c("input-text", {
                                                            attrs: {
                                                              max: 60,
                                                              "text-value":
                                                                _vm.batch
                                                                  .interval_sec,
                                                              "input-w": 100,
                                                              "input-text":
                                                                "秒",
                                                            },
                                                            on: {
                                                              handleInputValue:
                                                                _vm.handleIntervalSec,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      [
                                                        _vm.batch
                                                          .interval_min != 0 ||
                                                        _vm.batch
                                                          .interval_sec != 0
                                                          ? [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.batch
                                                                    .interval_min
                                                                ) +
                                                                  " 分 " +
                                                                  _vm._s(
                                                                    _vm.batch
                                                                      .interval_sec
                                                                  ) +
                                                                  " 秒"
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "未填写"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "item-block flex align-items",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left" },
                                              [
                                                _vm._v(" 持续时长: "),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "每批次客户认购的持续时间",
                                                      placement: "bottom-start",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipss el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm.type === "add"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "align-items",
                                                          class: _vm.isPC
                                                            ? " flex"
                                                            : "iphone",
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.batch
                                                                    .is_group,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.batch
                                                                    .batch_keep,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.batch,
                                                                      "batch_keep",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "batch.batch_keep",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 1,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "同批次间隔时间"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 2,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "不限制（到本活动/场次结束）"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 3,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "自定义时间"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.batch
                                                            .batch_keep === 3
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mL10",
                                                                  class:
                                                                    _vm.isPC
                                                                      ? " flex"
                                                                      : "",
                                                                },
                                                                [
                                                                  _c(
                                                                    "input-text",
                                                                    {
                                                                      attrs: {
                                                                        "text-value":
                                                                          _vm
                                                                            .batch
                                                                            .batch_keep_min,
                                                                        "input-w": 100,
                                                                        "input-text":
                                                                          "分",
                                                                      },
                                                                      on: {
                                                                        handleInputValue:
                                                                          _vm.handleKeepMin,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "input-text",
                                                                    {
                                                                      attrs: {
                                                                        max: 60,
                                                                        "text-value":
                                                                          _vm
                                                                            .batch
                                                                            .batch_keep_sec,
                                                                        "input-w": 100,
                                                                        "input-text":
                                                                          "秒",
                                                                      },
                                                                      on: {
                                                                        handleInputValue:
                                                                          _vm.handleKeepSec,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      [
                                                        _vm.batch.batch_keep ==
                                                        1
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "同批次间隔时间"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm.batch
                                                              .batch_keep == 2
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "不限制"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "colorOrange text6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "自定义时间:"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.batch
                                                                        .batch_keep_min
                                                                    ) +
                                                                      " 分 " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .batch
                                                                          .batch_keep_sec
                                                                      ) +
                                                                      " 秒"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "item-block flex align-items",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left" },
                                              [
                                                _vm._v(" 副时间条: "),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "用于控制客户端副时间条是否展示",
                                                      placement: "bottom-start",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipss el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm.type === "add"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.batch
                                                                    .is_show_time,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.batch,
                                                                      "is_show_time",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "batch.is_show_time",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 0,
                                                                  },
                                                                },
                                                                [_vm._v("显示")]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 1,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "不显示"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      { staticClass: "text6" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.batch
                                                              .is_show_time == 1
                                                              ? "不显示"
                                                              : "显示"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "item-block flex align-items",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "left" },
                                              [
                                                _vm._v(" 批次分组: "),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "若没有分组的批次将默认按标准分批执行",
                                                      placement: "bottom-start",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipss el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm.type === "add"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              on: {
                                                                change:
                                                                  _vm.isGrouping,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.batch
                                                                    .is_group,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.batch,
                                                                      "is_group",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "batch.is_group",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 1,
                                                                  },
                                                                },
                                                                [_vm._v("启用")]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: 0,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "不启用"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      { staticClass: "text6" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.batch
                                                              .is_group == 1
                                                              ? "启用"
                                                              : "不启用"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _vm.batch.is_group &&
                                        !_vm.batch.is_show_time
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "item-block flex align-items",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left" },
                                                  [
                                                    _vm._v(" 分组名称: "),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "在副时间条上是否展示分组的自定义名称",
                                                          placement:
                                                            "bottom-start",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tipss el-icon-question",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "right" },
                                                  [
                                                    _vm.type === "add"
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio-group",
                                                                {
                                                                  on: {
                                                                    change:
                                                                      _vm.groupNameshow,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.batch
                                                                        .is_group_name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.batch,
                                                                          "is_group_name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "batch.is_group_name",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-radio",
                                                                    {
                                                                      attrs: {
                                                                        label: 0,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "显示"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-radio",
                                                                    {
                                                                      attrs: {
                                                                        label: 1,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "不显示"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.batch
                                                                  .is_group_name ==
                                                                  1
                                                                  ? "不显示"
                                                                  : "显示"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.batch.is_group &&
                                        !_vm.batch.is_show_time
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "item-block flex align-items",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left" },
                                                  [
                                                    _vm._v(" 批次号码: "),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "控制分组名称间的批次号码是否连续",
                                                          placement:
                                                            "bottom-start",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tipss el-icon-question",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "right" },
                                                  [
                                                    _vm.type === "add"
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex",
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio-group",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.batch
                                                                        .is_group_name,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.batch
                                                                        .type_group_num,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.batch,
                                                                          "type_group_num",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "batch.type_group_num",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-radio",
                                                                    {
                                                                      attrs: {
                                                                        label: 1,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "继承上一组"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-radio",
                                                                    {
                                                                      attrs: {
                                                                        label: 0,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "不继承上一组"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.batch
                                                                  .type_group_num ==
                                                                  1
                                                                  ? "继承上一组"
                                                                  : "不继承上一组"
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("batchGroups", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.batch.is_group,
                                              expression: "batch.is_group",
                                            },
                                          ],
                                          ref: "batchgroups",
                                          attrs: {
                                            interval_min:
                                              _vm.batch.interval_min,
                                            interval_sec:
                                              _vm.batch.interval_sec,
                                            batchList: _vm.batchscle,
                                            type: _vm.type,
                                            eventStartTime: 0,
                                            moduleData: _vm.listInfo.formal,
                                          },
                                        }),
                                        _c(
                                          "el-form-item",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: [
                                                  "admin",
                                                  "event/preserve",
                                                ],
                                                expression:
                                                  "['admin', 'event/preserve']",
                                              },
                                            ],
                                            staticClass: "mT15",
                                            attrs: {
                                              "label-width": "160px",
                                              label: "批次计划表：",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "upload-btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openBatchList()
                                                  },
                                                },
                                              },
                                              [_vm._v("查看批次计划表")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "block mb0" }, [
                  _c("div", { staticClass: "title-h2" }, [_vm._v("重要资料")]),
                  _c(
                    "div",
                    { staticClass: "model" },
                    [
                      _c(
                        "el-row",
                        { staticClass: "list-bg" },
                        [
                          _c(
                            "el-form",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mT15 userTime",
                                  attrs: {
                                    label: "客户端开放时间：",
                                    "label-width": "130px",
                                  },
                                },
                                [
                                  _vm.type === "add"
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            width: _vm.isPC ? "300px" : "100p%",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              format: "yyyy-MM-dd HH:mm:ss",
                                              "value-format": "timestamp",
                                              size: "small",
                                              type: "datetime",
                                              placeholder: "选择日期",
                                            },
                                            model: {
                                              value:
                                                _vm.important.opening_hours,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.important,
                                                  "opening_hours",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "important.opening_hours",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _vm.important.opening_hours
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "text6" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateFormat")(
                                                          _vm.important
                                                            .opening_hours
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "span",
                                                  { staticClass: "error" },
                                                  [_vm._v("未填写")]
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                ]
                              ),
                              _vm.project.buy_type === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mT15 jump3",
                                      attrs: {
                                        label: "客户端价格公布时间：",
                                        "label-width": "160px",
                                      },
                                    },
                                    [
                                      _vm.type == "add"
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "top" },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    on: {
                                                      change: _vm.handelNew,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.important
                                                          .show_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.important,
                                                          "show_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "important.show_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 1 } },
                                                      [
                                                        _vm._v(
                                                          "和" +
                                                            _vm._s(
                                                              _vm.customSetting
                                                                .custom_data
                                                                .house_res_copy
                                                            ) +
                                                            "信息一同公布 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("正式活动公布")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "控制自主认购模式下客户端的价格显示时间。",
                                                      placement: "right",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipsIcon el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.important.show_price == 2
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "mT15",
                                                    class: _vm.isPC
                                                      ? "flex"
                                                      : "",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "center" },
                                                      [
                                                        _c(
                                                          "el-radio-group",
                                                          {
                                                            on: {
                                                              change:
                                                                _vm.handelNew,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.important
                                                                  .show_price_type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.important,
                                                                    "show_price_type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "important.show_price_type",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "align-items",
                                                                class: _vm.isPC
                                                                  ? "flex"
                                                                  : "",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "align-items mR20",
                                                                    class:
                                                                      _vm.isPC
                                                                        ? "flex"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: 1,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "统一时间公布"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm
                                                                      .important
                                                                      .show_price_type ===
                                                                    1
                                                                      ? [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mR15 mL10 greens",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .important
                                                                                    .show_price_type ==
                                                                                    1
                                                                                    ? "正式活动开始前"
                                                                                    : "开始认购前"
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .show_price_type_min,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "分",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handlePriceMin,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  max: 60,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .show_price_type_sec,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "秒",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handlePriceSec,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "align-items",
                                                                    class:
                                                                      _vm.isPC
                                                                        ? "flex"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: 2,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.buy_rand ===
                                                                                0
                                                                                ? "根据认购时间公布"
                                                                                : "客户到号公布"
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm
                                                                      .important
                                                                      .show_price_type ===
                                                                      2 &&
                                                                    _vm.buy_rand ===
                                                                      0
                                                                      ? [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mR15 mL10 greens",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .important
                                                                                    .show_price_type ==
                                                                                    1
                                                                                    ? "正式活动开始前"
                                                                                    : "开始认购前"
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .show_price_type_min,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "分",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handlePriceMin,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  max: 60,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .show_price_type_sec,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "秒",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handlePriceSec,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : [
                                            _vm.important.show_price === 1
                                              ? _c(
                                                  "div",
                                                  { staticClass: "text6" },
                                                  [
                                                    _vm._v(
                                                      "和" +
                                                        _vm._s(
                                                          _vm.customSetting
                                                            .custom_data
                                                            .house_res_copy
                                                        ) +
                                                        "信息一同公布"
                                                    ),
                                                  ]
                                                )
                                              : _vm.important.show_price === 2
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "text6" },
                                                    [
                                                      _vm._v(
                                                        "正式活动公布, " +
                                                          _vm._s(
                                                            _vm.important
                                                              .show_price_type ===
                                                              1
                                                              ? "统一时间公布"
                                                              : _vm.buy_rand ===
                                                                0
                                                              ? "根据认购时间公布"
                                                              : "客户到号公布"
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.buy_rand === 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass: "text6",
                                                        },
                                                        [
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                ", " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .important
                                                                      .show_price_type ===
                                                                      1
                                                                      ? "正式活动开始前"
                                                                      : "开始认购前"
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .important
                                                                      .show_price_type_min
                                                                  ) +
                                                                  " 分 " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .important
                                                                      .show_price_type_sec
                                                                  ) +
                                                                  " 秒"
                                                              ),
                                                            ]),
                                                          ],
                                                        ],
                                                        2
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass: "text6",
                                                        },
                                                        [
                                                          _vm.important
                                                            .show_price_type ===
                                                          1
                                                            ? [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    ", " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .important
                                                                          .show_price_type ===
                                                                          1
                                                                          ? "正式活动开始前"
                                                                          : "开始认购前"
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .important
                                                                          .show_price_type_min
                                                                      ) +
                                                                      " 分 " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .important
                                                                          .show_price_type_sec
                                                                      ) +
                                                                      " 秒"
                                                                  ),
                                                                ]),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "error" },
                                                    [_vm._v("未填写")]
                                                  ),
                                                ]),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.project.buy_type === 2 &&
                              _vm.project.assist_room_price === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mT15 jump4",
                                      attrs: {
                                        label: "客户端价格公布时间：",
                                        "label-width": "160px",
                                      },
                                    },
                                    [
                                      _vm.type == "add"
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "top" },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    on: {
                                                      change: _vm.handelNew,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.important
                                                          .assist_show_price,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.important,
                                                          "assist_show_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "important.assist_show_price",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 1 } },
                                                      [
                                                        _vm._v(
                                                          " 和" +
                                                            _vm._s(
                                                              _vm.customSetting
                                                                .custom_data
                                                                .house_res_copy
                                                            ) +
                                                            "信息一同公布 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-radio",
                                                      { attrs: { label: 2 } },
                                                      [_vm._v("正式活动公布")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "控制协助认购模式下客户端的价格显示时间。",
                                                      placement: "right",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "tipsIcon el-icon-question",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.important.assist_show_price == 2
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "mT15",
                                                    class: _vm.isPC
                                                      ? "flex"
                                                      : "",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "center" },
                                                      [
                                                        _c(
                                                          "el-radio-group",
                                                          {
                                                            on: {
                                                              change:
                                                                _vm.handelNew,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.important
                                                                  .assist_show_price_type,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.important,
                                                                    "assist_show_price_type",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "important.assist_show_price_type",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "align-items",
                                                                class: _vm.isPC
                                                                  ? "flex"
                                                                  : "",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "align-items mR20",
                                                                    class:
                                                                      _vm.isPC
                                                                        ? "flex"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: 1,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "统一时间公布"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm
                                                                      .important
                                                                      .assist_show_price_type ===
                                                                    1
                                                                      ? [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mR15 mL10 greens",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .important
                                                                                    .assist_show_price_type ==
                                                                                    1
                                                                                    ? "正式活动开始前"
                                                                                    : "开始认购前"
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .assist_show_price_type_min,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "分",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handleAssistPriceMin,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "input-text",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  number: 0,
                                                                                  max: 60,
                                                                                  "text-value":
                                                                                    _vm
                                                                                      .important
                                                                                      .assist_show_price_type_sec,
                                                                                  "input-w": 80,
                                                                                  "input-text":
                                                                                    "秒",
                                                                                },
                                                                              on: {
                                                                                handleInputValue:
                                                                                  _vm.handleAssistPriceSec,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "align-items",
                                                                    class:
                                                                      _vm.isPC
                                                                        ? "flex"
                                                                        : "",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label: 2,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "入场公布"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : [
                                            _vm.important.assist_show_price == 1
                                              ? _c(
                                                  "div",
                                                  { staticClass: "text6" },
                                                  [
                                                    _vm._v(
                                                      " 和" +
                                                        _vm._s(
                                                          _vm.customSetting
                                                            .custom_data
                                                            .house_res_copy
                                                        ) +
                                                        "信息一同公布 "
                                                    ),
                                                  ]
                                                )
                                              : _vm.important
                                                  .assist_show_price == 2
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "text6" },
                                                    [
                                                      _vm._v(
                                                        "正式活动公布, " +
                                                          _vm._s(
                                                            _vm.important
                                                              .assist_show_price_type ==
                                                              1
                                                              ? "统一时间公布"
                                                              : "根据认购时间公布"
                                                          ) +
                                                          "," +
                                                          _vm._s(
                                                            _vm.important
                                                              .assist_show_price_type ==
                                                              1
                                                              ? "正式活动开始前"
                                                              : "开始认购前"
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text6" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.important
                                                            .assist_show_price_type_min
                                                        ) +
                                                          " 分 " +
                                                          _vm._s(
                                                            _vm.important
                                                              .assist_show_price_type_sec
                                                          ) +
                                                          " 秒"
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "error" },
                                                    [_vm._v("未填写")]
                                                  ),
                                                ]),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mT15 jump2",
                                  attrs: {
                                    label: "助手端价格公布时间：",
                                    "label-width": "160px",
                                  },
                                },
                                [
                                  _vm.type == "add"
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "top" },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: { change: _vm.handelNew },
                                                model: {
                                                  value:
                                                    _vm.important.zs_show_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.important,
                                                      "zs_show_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "important.zs_show_price",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [
                                                    _vm._v(
                                                      " 和" +
                                                        _vm._s(
                                                          _vm.customSetting
                                                            .custom_data
                                                            .house_res_copy
                                                        ) +
                                                        "信息一同公布 "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 2 } },
                                                  [_vm._v("正式活动公布")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.important.zs_show_price == 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "mT15",
                                                class: _vm.isPC ? "flex" : "",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mR15 mL10 tips",
                                                  },
                                                  [_vm._v("正式活动开始前")]
                                                ),
                                                _c("input-text", {
                                                  attrs: {
                                                    number: 0,
                                                    "text-value":
                                                      _vm.important
                                                        .zs_show_price_type_min,
                                                    "input-w": 100,
                                                    "input-text": "分",
                                                  },
                                                  on: {
                                                    handleInputValue:
                                                      _vm.priceMin,
                                                  },
                                                }),
                                                _c("input-text", {
                                                  attrs: {
                                                    number: 0,
                                                    max: 60,
                                                    "text-value":
                                                      _vm.important
                                                        .zs_show_price_type_sec,
                                                    "input-w": 100,
                                                    "input-text": "秒",
                                                  },
                                                  on: {
                                                    handleInputValue:
                                                      _vm.priceSec,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : [
                                        _vm.important.zs_show_price == 1
                                          ? _c(
                                              "div",
                                              { staticClass: "text6" },
                                              [
                                                _vm._v(
                                                  " 和" +
                                                    _vm._s(
                                                      _vm.customSetting
                                                        .custom_data
                                                        .house_res_copy
                                                    ) +
                                                    "信息一同公布 "
                                                ),
                                              ]
                                            )
                                          : _vm.important.zs_show_price == 2
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.important
                                                        .zs_show_price_type == 1
                                                        ? "和" +
                                                            _vm.customSetting
                                                              .custom_data
                                                              .house_res_copy +
                                                            "信息一同公布"
                                                        : "正式活动开始前"
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "text6" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.important
                                                        .zs_show_price_type_min
                                                    ) +
                                                      " 分 " +
                                                      _vm._s(
                                                        _vm.important
                                                          .zs_show_price_type_sec
                                                      ) +
                                                      " 秒 公布"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "error" },
                                                [_vm._v("未填写")]
                                              ),
                                            ]),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增活动",
            visible: _vm.isAddActivity,
            width: _vm.isPC ? "30%" : "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAddActivity = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "align-center",
              staticStyle: { width: "100%" },
              model: {
                value: _vm.addType,
                callback: function ($$v) {
                  _vm.addType = $$v
                },
                expression: "addType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("拟测")]),
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("内测")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("公测")]),
            ],
            1
          ),
          _vm.addType && _vm.tips !== ""
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "align-center mT15" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.checked,
                          callback: function ($$v) {
                            _vm.checked = $$v
                          },
                          expression: "checked",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tips) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "align-center mT15" },
                  [
                    _c(
                      "el-input",
                      {
                        staticStyle: { width: "150px" },
                        attrs: { disabled: !_vm.checked },
                        model: {
                          value: _vm.addTime,
                          callback: function ($$v) {
                            _vm.addTime = $$v
                          },
                          expression: "addTime",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("小时")])],
                      2
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isAddActivity = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.addLo, type: "primary", size: "small" },
                  on: { click: _vm.handleAddSuccess },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批次计划表",
            visible: _vm.isList,
            width: _vm.isPC ? "30%" : "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isList = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                size: "mini",
                data: _vm.batchList,
                "header-cell-style": { background: "#fafafa" },
                "empty-text": "还未导入批次哦",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "批次序号",
                  width: "100",
                  prop: "num",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "持续时长" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.batch_keep == 1
                          ? _c(
                              "div",
                              { class: scope.row.custom > 0 ? "error" : "" },
                              [_vm._v("同批次间隔时间")]
                            )
                          : scope.row.batch_keep == 2
                          ? _c(
                              "div",
                              { class: scope.row.custom > 0 ? "error" : "" },
                              [_vm._v("不限制")]
                            )
                          : _c(
                              "div",
                              { class: scope.row.custom > 0 ? "error" : "" },
                              [
                                _vm._v(
                                  _vm._s(scope.row.batch_keep_min) +
                                    "分 " +
                                    _vm._s(scope.row.batch_keep_sec) +
                                    "秒"
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.type == "add"
                ? _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "event/preserve"],
                                      expression: "['admin', 'event/preserve']",
                                    },
                                  ],
                                  staticClass: "colorStyle",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handelEditTime(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("| 修改时长 |")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      277197020
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "tips-box" }, [
            _c("p", [_vm._v("说明：")]),
            _c("p", [
              _vm._v("1.批量导入客户时，系统将根据批次规则自动创建批次计划；"),
            ]),
            _c("p", [_vm._v("2.可在此单独修改某个批次的认购持续时长；")]),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改认购时长",
            visible: _vm.isEditTime,
            width: _vm.isPC ? "30%" : "95%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isEditTime = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "批次持续时长" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.batchTime,
                        callback: function ($$v) {
                          _vm.batchTime = $$v
                        },
                        expression: "batchTime",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mT15" },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("同批次间隔时间"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mT15" },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("不限制（到本活动/场次结束）"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mT15 align-items",
                          class: _vm.isPC ? "flex" : " ",
                        },
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("自定义时间"),
                          ]),
                          void 0,
                          _c("input-text", {
                            attrs: {
                              number: 0,
                              disabled: _vm.batchTime == 3 ? false : true,
                              "text-value": _vm.batchMin,
                              "input-w": 70,
                              "input-text": "分",
                            },
                            on: { handleInputValue: _vm.handleBatchMin },
                          }),
                          _c("input-text", {
                            attrs: {
                              number: 0,
                              disabled: _vm.batchTime == 3 ? false : true,
                              "text-value": _vm.batchSec,
                              "input-w": 70,
                              "input-text": "秒",
                            },
                            on: { handleInputValue: _vm.handleBatchSec },
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isEditTime = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSaveBatch },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }