import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Oss from '@/utils/oss';
import moment from 'moment';
var thisVideo = null,
    mediaRecorder = null,
    MediaStream = null;
var startTime = null;
export default {
  name: 'VidiconPlayer',
  model: {
    prop: 'show',
    event: 'updateShow'
  },
  filters: {
    dateSecondsFormat: function dateSecondsFormat(dateStr) {
      return moment.utc(dateStr * 1000).format('HH:mm:ss');
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 10
    }
  },
  watch: {
    show: function show(val) {
      if (val) this.open();else this.close();
    }
  },
  data: function data() {
    return {
      centerDialogVisible: false,
      // 页面
      videoWidth: 960,
      videoHeight: 500,
      // 配置
      video: {
        width: 1220,
        height: 2714,
        transform: 'scaleX(-1)'
      },
      audio: true,
      audioBitsPerSecond: 128000,
      // 音频码率
      videoBitsPerSecond: 32000000,
      // 视频码率
      // 其余显示
      hint: {
        status: '',
        type: 0,
        // 0 未打开 1 打开摄像头 2录制中
        msg: '未使用'
      },
      videoTime: 0,
      video_url: '',
      preview: false
    };
  },
  // 销毁
  destroyed: function destroyed() {
    this.close();
  },
  methods: {
    // 打开录制弹窗
    open: function open() {
      this.centerDialogVisible = true;
      this.$emit('updateShow', true);
      this.init();
    },
    // 关闭弹窗
    close: function close() {
      this.video_url = '';
      this.clearTime();
      this.stopNavigator();
      this.centerDialogVisible = false;
      this.$emit('updateShow', false);
      this.$nextTick(function () {
        thisVideo = null;
        mediaRecorder = null;
        MediaStream = null;
      });
    },
    // 初始化
    init: function init() {
      var _this = this;

      this.$nextTick(function () {
        thisVideo = _this.$refs['videoCamera']; // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象

        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        } // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。


        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia; // 有些浏览器不支持，会返回错误信息
            // 保持接口一致

            if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            } // 否则，使用Promise将调用包装到旧的navigator.getUserMedia


            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
      });
    },
    getCompetence: function getCompetence() {
      var _this2 = this;

      navigator.mediaDevices.getUserMedia({
        video: this.video,
        audio: this.audio
      }).then(function (stream) {
        // 旧的浏览器可能没有srcObject
        if ('srcObject' in thisVideo) {
          thisVideo.srcObject = stream;
          MediaStream = stream;
          mediaRecorder = new MediaRecorder(stream, {
            audioBitsPerSecond: _this2.audioBitsPerSecond,
            // 音频码率
            videoBitsPerSecond: _this2.videoBitsPerSecond,
            // 视频码率
            mimeType: 'video/webm;codecs=vp8,opus' // 编码格式

          });
        } else {
          // 避免在新的浏览器中使用它，因为它正在被弃用。
          thisVideo.src = window.URL.createObjectURL(stream);
        }

        _this2.hint = {
          status: 'success',
          type: 1,
          msg: '摄像头已打开'
        };
      }).catch(function (err) {
        _this2.hint = {
          status: 'error',
          type: 0,
          msg: '打开摄像头失败, 请检查网页授权或应用授权以及摄像头是否正确打开 否则无法正常使用'
        };
      });
    },

    /**
     * @function  采集视频
     */
    startMakeVideo: function startMakeVideo() {
      mediaRecorder.start();
      this.video_url = '';
      this.hint = {
        status: 'warning',
        type: 2,
        msg: '视频录制中'
      };
      this.startMakeTime();
    },
    startMakeTime: function startMakeTime() {
      var _this3 = this;

      this.clearTime();
      startTime = setInterval(function () {
        _this3.videoTime++; // 0就无限录制

        if (_this3.videoTime >= _this3.duration) {
          _this3.uploadVideo();

          _this3.clearTime();
        }
      }, 1000);
    },
    // 清理定时器
    clearTime: function clearTime() {
      if (startTime) {
        clearInterval(startTime);
        this.videoTime = 0;
      }
    },

    /**
     * 关闭媒体流
     * @param stream {MediaStream} - 需要关闭的流
     */
    closeStream: function closeStream(stream) {
      if (typeof stream.stop === 'function') {
        stream.stop();
      } else {
        var trackList = [stream.getAudioTracks(), stream.getVideoTracks()];

        for (var i = 0; i < trackList.length; i++) {
          var tracks = trackList[i];

          if (tracks && tracks.length > 0) {
            for (var j = 0; j < tracks.length; j++) {
              var track = tracks[j];

              if (typeof track.stop === 'function') {
                track.stop();
              }
            }
          }
        }
      }
    },

    /**
     * @function  关闭摄像头
     */
    stopNavigator: function stopNavigator() {
      if (thisVideo) {
        var _thisVideo$srcObject;

        (_thisVideo$srcObject = thisVideo.srcObject) === null || _thisVideo$srcObject === void 0 ? void 0 : _thisVideo$srcObject.getTracks()[0].stop();
        thisVideo.srcObject = null;
        MediaStream && this.closeStream(MediaStream);
        this.hint = {
          status: '',
          type: 0,
          msg: '已关闭'
        };
      }
    },

    /**
     * @function  保存视频
     */
    seveVideo: function seveVideo() {
      var _this4 = this;

      this.clearTime();
      this.stopNavigator();
      this.hint = {
        status: 'success',
        type: 2,
        msg: '已保存至本地'
      };
      mediaRecorder.stop(); // 事件

      mediaRecorder.ondataavailable = function (e) {
        // 下载视频
        var blob = new Blob([e.data], {
          type: 'video/mp4'
        });
        _this4.video_url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = "".concat(moment().format('YYYY-MM-DD HH:mm:ss'), ".mp4");
        a.click();
      };
    },

    /**
     * @function  保存视频至存储
     */
    uploadVideo: function uploadVideo() {
      var _this5 = this;

      this.clearTime();
      this.stopNavigator();
      this.hint = {
        status: '',
        type: 1,
        msg: '已上传至云端'
      };
      var l = this.$loading({
        lock: true,
        text: '视频上传中···',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var time = new Date().getTime();
      mediaRecorder.stop(); // 事件

      mediaRecorder.ondataavailable = function (e) {
        var blob = new Blob([e.data], {
          type: 'video/mp4'
        });
        _this5.video_url = URL.createObjectURL(blob);
        Oss.onUploadFile({
          file: blob,
          onSuccess: function onSuccess(e) {
            _this5.$emit('uploadSuccess', e);

            l === null || l === void 0 ? void 0 : l.close();
          },
          onProgress: function onProgress(e) {
            _this5.$emit('uploadFail', e);

            l === null || l === void 0 ? void 0 : l.close();
          }
        }, "public/video/$id/".concat(time, ".mp4"), time);
      };
    },
    // 预览
    previewVideo: function previewVideo() {
      var _this6 = this;

      if (this.video_url) {
        this.preview = true;
        this.$nextTick(function () {
          var dom = _this6.$refs['previewView'];
          dom.setAttribute('src', _this6.video_url);
          dom.play();
        });
      } else {
        this.$message.error('还没有视频链接');
      }
    },
    // 重新录制
    anewVideo: function anewVideo() {
      this.video_url = '';
      this.clearTime();
      this.stopNavigator();
      this.getCompetence();
    }
  }
};