//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    subClick: function subClick() {
      this.$emit('subTitleClick');
    }
  }
};