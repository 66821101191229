import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import toolReviewMixin from '../toolReviewMixin';
export default {
  name: 'UserRegister',
  mixins: [toolReviewMixin],
  props: {
    statusInfo: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1
    },
    info: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // div 高度
    tableHeight: {
      type: Number,
      default: 0
    },
    // 日志数组
    infoTips: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: 1,
      isDoPriveImg: false,
      perViewUrl: '',
      showData: []
    };
  },
  methods: {
    getTypeText: function getTypeText(index, inx) {
      if (this.showData[index].type == 1) {
        var data = this.showData[index].owners[inx];
        var indexs = data.forms.findIndex(function (d) {
          return d.owner_type == 4;
        });
        var indexId = data.forms[indexs].children.findIndex(function (d) {
          return d.id == data.forms[indexs].owner_result.value;
        });

        if (data.forms[indexs].children[indexId].name == '身份证') {
          return true;
        } else {
          return false;
        }
      } else {
        var _data = this.showData[index] || [];

        var _indexs = _data.forms.findIndex(function (d) {
          return d.owner_type == 26;
        });

        var _indexId = _data.forms[_indexs].children.findIndex(function (d) {
          return d.id == _data.forms[_indexs].normal_result.value;
        });

        if (_data.forms[_indexs].children[_indexId].name == '身份证') {
          return true;
        } else {
          return false;
        }
      }
    },
    // 获取选中的值
    getSelected: function getSelected(array, selectedValue) {
      if (array && array.length) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].id == selectedValue) {
            return array[i].name;
          }
        }
      }
    },
    getType: function getType(item) {
      if (this.type === 1) {
        return item.typeUserNumber > 0;
      } else {
        return item.typeNumber > 0;
      }
    },
    // 预览点击事件
    doPriveImg: function doPriveImg(url) {
      this.isDoPriveImg = true;
      this.perViewUrl = url;
    },
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'dxf', 'ufo', 'eps', 'raw', 'WMF', 'webp', 'avif', 'apng'];
      return images.indexOf(suffix) < 0 ? name : '';
    }
  },
  watch: {
    // 监听数据变化 对数据进行格式后赋值显示
    statusInfo: function statusInfo(newVal) {
      var showData = newVal;
      showData.forEach(function (formsTyle) {
        if (formsTyle.type == 0) {
          // 普通表单
          formsTyle.forms.forEach(function (formsItem) {
            if (formsItem.owner_type == 6) {
              // 婚姻状态
              formsItem.children.forEach(function (item) {
                if (formsItem.normal_result.value == item.id) {
                  if (item.name == '已婚') {} else {
                    noSpouse = formsTyle.forms.filter(function (i) {
                      return !i.name.includes('配偶');
                    });
                  }
                }
              });
            } else if (formsItem.form_type === 'popup') {
              var _formsTyle$forms;

              // 意向房源
              if (!formsItem.normal_result.value) {
                formsItem.parent_cause_show = false;
              }

              (_formsTyle$forms = formsTyle.forms) === null || _formsTyle$forms === void 0 ? void 0 : _formsTyle$forms.forEach(function (ffi) {
                //   80 意向楼栋 81意向户型 82 意向面积 87 意向楼层
                if ([80, 81, 82, 87].includes(ffi.owner_type)) {
                  ffi.parent_cause_show = false;
                }

                if (ffi.form_type === '意向户型') {
                  console.log(formsItem.normal_result.value);
                  ffi.parent_cause_show = !formsItem.normal_result.value;
                }
              });
            } else if (formsItem.form_type === '意向户型') {
              // 意向房源
              if (!formsItem.normal_result.value) {
                formsItem.parent_cause_show = false;
              }
            }
          });
        }
      });
      this.showData = showData;
    },
    immediate: true
  }
};