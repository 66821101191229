import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { authGetEvent, adminParameter } from '@/api/public';
import { eventImport, lotteryPresetDel, lotteryPresetList, orderQueryExport, getErrCount, isTestRandUser } from '@/api/lottery';
import UploadExcel from '@/components/my-UploadExcel';
import moment from 'moment';
import utils from '@/utils/utils';
import { getProject, getProjectName, getpName } from '@/utils/auth';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import customSwitch from '@/components/customSwitch';
export default {
  name: 'LotteryPreset',
  components: {
    UploadExcel: UploadExcel,
    customSwitch: customSwitch,
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      appHeight: 0,
      tableData: [],
      currentPage4: 2,
      // 存储递归 次数
      listLength: 0,
      timeStamp: 0,
      is_test_rand_user: 0,
      select_event: {},
      // 选择的活动和场次id集
      eventList: [] // 项目活动列表

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - 90;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };

      if (this.roles.includes('eventranduser/index') || this.roles.includes('admin')) {
        this.getTableList();
      }
    },
    // 递归 获取 错误人数
    funErrCount: function funErrCount() {
      var _this2 = this;

      getErrCount({
        id: this.tableData[this.listLength].id
      }).then(function (res) {
        if (res.status === 200) {
          var errCount = res.data.errCount; // 替换数据

          _this2.tableData[_this2.listLength].err_code = errCount; // 更新时间戳

          _this2.timeStamp = new Date().valueOf(); // 跳入下一次

          _this2.listLength++;

          if (_this2.tableData.length > _this2.listLength) {
            _this2.funErrCount();
          } else {
            // 归零
            _this2.listLength = 0;
          }
        }
      });
    },
    // 导出
    handleExport: function handleExport(row) {
      var _this3 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        id: row.id
      });

      orderQueryExport(params).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.path


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    // 下载模板
    handleTemplate: function handleTemplate() {
      var url = this.host + '/template/rand/5.云销控-摇号预设导入模板.xlsx';
      utils.download_files(url, "".concat(getpName(), "-\u6447\u53F7\u9884\u8BBE\u5BFC\u5165-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
        showMessage: true
      });
    },
    // 跳转 预设名单
    handlePresetList: function handlePresetList(row) {
      this.$router.push({
        name: 'PresetList',
        query: {
          id: row.id,
          preinstall_number: row.preinstall_number
        }
      });
    },
    // 清楚预设
    handleDel: function handleDel(row) {
      var _this4 = this;

      this.$confirm('此操作不可复原，确认清除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        lotteryPresetDel({
          id: row.id
        }).then(function (res) {
          if (res.status === 200) {
            _this4.getTableList();

            _this4.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {});
    },
    // 获取预设列表
    getTableList: function getTableList() {
      var _this5 = this;

      var params = _objectSpread({}, this.select_event); // 获取预设是吧的数据


      var ArrayErrId = [];
      var ArrayErrName = [];
      lotteryPresetList(params).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              list = _res$data2.list,
              is_test_rand_user = _res$data2.is_test_rand_user; // 组织 错误人数 数据

          if (list != null && list.length > 0) {
            list.map(function (item) {
              item.err_code = '';
            });
            list.forEach(function (item) {
              if (item.user_rand_err) {
                ArrayErrId.push(item.id);

                if (item.round_id === 0) {
                  ArrayErrName.push(item.event_name);
                } else {
                  ArrayErrName.push("".concat(item.event_name, " / ").concat(item.round_name));
                }
              }
            });
          }

          _this5.tableData = list;
          _this5.is_test_rand_user = is_test_rand_user;
          ArrayErrName.push('预设摇号异常，请清空此预设后重新添加？'); // 调用获取！ 错误人数

          if (list != null && list.length > 0) {
            _this5.funErrCount();
          } // 判断是否有错误数据


          if (ArrayErrId.length > 0) {
            var newData = [];
            var h = _this5.$createElement;

            for (var i in ArrayErrName) {
              newData.push(h('p', null, ArrayErrName[i]));
            }

            _this5.$confirm('提示', {
              title: '提示',
              message: h('div', null, newData),
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(function () {
              for (var _i = 0; _i < ArrayErrId.length; _i++) {
                _this5.getPreset(ArrayErrId[_i], _i);
              }
            }).catch(function () {});
          }
        }
      });
      this.ArrayErrId = ArrayErrId;
    },
    // 清空 预设
    getPreset: function getPreset(ids, i) {
      var _this6 = this;

      lotteryPresetDel({
        id: ids
      }).then(function (res) {
        if (res.status === 200) {
          console.log(_this6.ArrayErrId.length, i);

          if (_this6.ArrayErrId.length === i + 1) {
            _this6.getTableList();

            _this6.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        }
      });
    },
    // table 操作按钮
    handleEdit: function handleEdit(e, data) {
      this.$router.push({
        name: 'EditRule',
        query: {
          id: data.name
        }
      });
    },
    changeSwitch: function changeSwitch(e) {
      var _this7 = this;

      this.is_test_rand_user = e ? 1 : 0;
      var params = {
        is_test_rand_user: this.is_test_rand_user
      };
      isTestRandUser(params).then(function (res) {
        _this7.$message.success(res.data.msg);
      });
    }
  }
};