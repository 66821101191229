var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("el-card", { staticClass: "content" }, [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            {
              staticStyle: {
                "padding-top": "10px",
                "padding-left": "15px",
                color: "#22a271",
              },
            },
            [_vm._v("版本号：" + _vm._s(_vm.setInfo.editon_sn))]
          ),
          _c("div", { staticClass: "top-title" }),
          _c(
            "div",
            { staticClass: "top-operation" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "entry/getlast"],
                      expression: "['admin', 'entry/getlast']",
                    },
                  ],
                  staticStyle: { height: "25px", width: "25px" },
                  on: {
                    click: function ($event) {
                      return _vm.goPath(
                        { path: "/lotteryShow", open: true },
                        true
                      )
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "摇号大屏",
                        placement: "top",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "pointer",
                        staticStyle: { height: "25px", width: "25px" },
                        attrs: {
                          src: require("../../../assets/page/start.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "entry/publicity"],
                      expression: "['admin', 'entry/publicity']",
                    },
                  ],
                  staticStyle: { height: "25px", width: "25px" },
                  attrs: { placement: "bottom" },
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.goPath(
                            {
                              path: "/lottery/open",
                              query: {
                                page: 1,
                                total: _vm.setInfo.rand.show_num,
                                refresh: parseInt(Math.random() * 1000000),
                              },
                              open: true,
                            },
                            true,
                            true
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "公示屏",
                            placement: "top",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "pointer",
                            staticStyle: { height: "25px", width: "25px" },
                            attrs: {
                              src: require("../../../assets/page/pc.png"),
                              alt: "",
                              srcset: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.setInfo.rand.show_num, function (num, index) {
                      return _c("el-dropdown-item", { key: index }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                _vm.goPath(
                                  {
                                    path: "/lottery/open",
                                    open: true,
                                    query: {
                                      page: num,
                                      total: _vm.setInfo.rand.show_num,
                                      refresh: parseInt(
                                        Math.random() * 1000000
                                      ),
                                    },
                                  },
                                  true
                                )
                              },
                            },
                          },
                          [_vm._v(" 第" + _vm._s(num) + "块屏幕 ")]
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.record
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "entry/list"],
                          expression: "['admin', 'entry/list']",
                        },
                      ],
                      staticStyle: { height: "25px", width: "25px" },
                      on: {
                        click: function ($event) {
                          return _vm.goPath("/lottery/record")
                        },
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "摇号记录明细",
                            placement: "top",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "pointer",
                            staticStyle: { height: "25px", width: "25px" },
                            attrs: {
                              src: require("../../../assets/page/look.png"),
                              alt: "",
                              srcset: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "entry/edition"],
                      expression: "['admin', 'entry/edition']",
                    },
                  ],
                  staticStyle: { height: "25px", width: "25px" },
                  on: {
                    click: function ($event) {
                      return _vm.goPath("/lottery/version")
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "版本管理",
                        placement: "top",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "pointer",
                        staticStyle: { height: "25px", width: "25px" },
                        attrs: {
                          src: require("../../../assets/page/set.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "entry/reset"],
                      expression: "['admin', 'entry/reset']",
                    },
                  ],
                  staticStyle: { height: "25px", width: "25px" },
                  on: { click: _vm.resetSet },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "重置摇号",
                        placement: "top",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "pointer",
                        staticStyle: { height: "25px", width: "25px" },
                        attrs: {
                          src: require("../../../assets/page/reset.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "entry/setrand"],
                      expression: "['admin', 'entry/setrand']",
                    },
                  ],
                  staticStyle: { height: "25px", width: "25px" },
                  on: {
                    click: function ($event) {
                      return _vm.showSet(true)
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "规则设置",
                        placement: "top",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-setting",
                        staticStyle: { "font-size": "25px", cursor: "pointer" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", [
          _c("div", [
            _vm.setInfo.rand.ball_num
              ? _c(
                  "div",
                  { staticClass: "input-number" },
                  _vm._l(_vm.inputSetShow, function (set, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        style: {
                          width:
                            "calc( 100% / " +
                            _vm.inputSetShow.length +
                            " - 30px)",
                        },
                      },
                      [
                        !set.noShow && !set.and
                          ? _c("input-block", {
                              ref: "inputBlock" + index,
                              refInFor: true,
                              attrs: {
                                width: "100%",
                                height: "40vh",
                                bgc: set.bgc,
                                brc: set.brc,
                                "fixed-value": set.fixedValue,
                                disabled: set.disabled,
                                "hint-text": _vm.unit[set.hint],
                              },
                              on: { change: _vm.achieve },
                              model: {
                                value: set.value,
                                callback: function ($$v) {
                                  _vm.$set(set, "value", $$v)
                                },
                                expression: "set.value",
                              },
                            })
                          : _vm._e(),
                        set.and && set.child
                          ? _c(
                              "div",
                              { staticClass: "showPAndY" },
                              _vm._l(set.child, function (item, index) {
                                return _c("input-block", {
                                  key: index,
                                  ref: "inputBlock",
                                  refInFor: true,
                                  attrs: {
                                    width: "100%",
                                    height: "50%",
                                    bgc: item.bgc,
                                    brc: item.brc,
                                    "fixed-value": item.fixedValue,
                                  },
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "input-number" }, [
                  _c("div", { staticClass: "vacancy" }, [
                    _vm._v("未配置规则，请先配置规则"),
                  ]),
                ]),
          ]),
          _c("div", { staticClass: "show-number" }, [
            _c("div", { staticClass: "show" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm._v(" 号码展示 "),
                  !!_vm.repeakMsg
                    ? [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              padding: "0px 10px",
                              "background-color": "#e6a33e",
                              "text-align": "center",
                              "border-radius": "30px",
                              color: "white",
                              "margin-left": "20px",
                            },
                          },
                          [_vm._v("!")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "19px",
                              "font-weight": "bold",
                              "margin-left": "5px",
                              color: "red",
                            },
                          },
                          [_vm._v("提醒")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "font-size": "16px",
                              color: "red",
                            },
                          },
                          [_vm._v(_vm._s(_vm.repeakMsg))]
                        ),
                      ]
                    : _vm._e(),
                  _vm.numSort.nums.length
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            float: "right",
                            cursor: "pointer",
                            "font-weight": "bold",
                            color: "rgb(32, 162, 113)",
                          },
                          on: { click: _vm.onNumSort },
                        },
                        [_vm._v(" 号码排序 ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "number-card" }, [
                  _vm.showNumner.length
                    ? _c(
                        "div",
                        { staticClass: "number-item" },
                        _vm._l(_vm.showNumner, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "number" },
                            [_vm._v(" " + _vm._s(item.code) + " ")]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "show-lack" }, [
                        _c("img", {
                          staticStyle: { "margin-right": "25px" },
                          attrs: {
                            src: require("../../../assets/page/lack.png"),
                            alt: "",
                          },
                        }),
                        _vm._v("还未生成号码～"),
                      ]),
                ]),
                _c(
                  "div",
                  { staticClass: "subbtn" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "entry/generate"],
                            expression: "['admin', 'entry/generate']",
                          },
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick",
                          },
                        ],
                        staticClass: "button",
                        attrs: {
                          type: "primary",
                          loading: _vm.generateSetTimeout,
                        },
                        on: { click: _vm.generate },
                      },
                      [_vm._v("确定生成")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("set-popup", { ref: "popup", on: { updata: _vm.updata } }),
      _c(
        "el-dialog",
        {
          attrs: { title: "号码排序", visible: _vm.numSort.show, width: "25%" },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.numSort, "show", $event)
            },
          },
        },
        [
          _vm.numSort.if
            ? _c("div", [
                _c(
                  "div",
                  { staticStyle: { margin: "0 10%" } },
                  [
                    _c("input-block", {
                      ref: "inputBlockSort",
                      attrs: {
                        width: "100%",
                        height: "45vh",
                        hintText: "十位排序",
                      },
                      on: {
                        onKeyupEnter: function ($event) {
                          return _vm.GenerateNumber(true)
                        },
                      },
                      model: {
                        value: _vm.numSort.index,
                        callback: function ($$v) {
                          _vm.$set(_vm.numSort, "index", $$v)
                        },
                        expression: "numSort.index",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { height: "50px", "margin-top": "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right", "margin-right": "10%" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.GenerateNumber(true)
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }