//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import defaultSettings from '@/settings';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '云销控管理系统',
      defaultSettings: defaultSettings
    };
  }
};