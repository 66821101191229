/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-03-23 17:12,
 */
import request from '@/utils/request';
import qs from 'qs'; //  获取参数

export function parameterInfo(params) {
  return request({
    url: 'project/index',
    method: 'get',
    params: params
  });
} // 参数保存

export function parameterSave(data) {
  return request({
    url: 'project/edit',
    method: 'post',
    data: data
  });
} // 参数设置报错 json

export function preserveSave(data) {
  return request({
    url: 'project/preserve',
    method: 'post',
    data: data
  });
} // 获取付款方式规则

export function getPayInfoRule(data) {
  return request({
    url: 'project/getPayInfoRule',
    method: 'post',
    data: data
  });
} // ----- 活动计划 -----
// 获取活动计划

export function eventIndex(params) {
  return request({
    url: 'Event/index',
    method: 'get',
    params: params
  });
} // 保存活动

export function eventreserve(data) {
  return request({
    url: 'Event/preserve',
    method: 'post',
    data: data
  });
} // 活动保存后数据校验

export function eventRedisCheck(params) {
  return request({
    url: 'Event/redisCheck',
    method: 'get',
    params: params
  });
} // 查询审核人员

export function eventAdmin(params) {
  return request({
    url: 'Event/admin',
    method: 'get',
    params: params
  });
} // 删除场次

export function delRound(data) {
  return request({
    url: 'Event/delRound',
    method: 'post',
    data: data
  });
} // 删除活动

export function delActivity(params) {
  return request({
    url: 'Event/del',
    method: 'get',
    params: params
  });
} // 修改批次时长

export function eventEditTime(data) {
  return request({
    url: 'Event/editTime',
    method: 'post',
    data: data
  });
} // 获取批次

export function selBatch(params) {
  return request({
    url: 'Event/selBatch',
    method: 'get',
    params: params
  });
} // ----- 图文资料 -----
// 获取图文资料

export function roomCateIndex(params) {
  return request({
    url: 'roomCate/index',
    method: 'get',
    params: params
  });
} // 保存图文资料

export function roomSave(data) {
  return request({
    url: 'roomCate/preserve',
    method: 'post',
    data: data
  }, false);
} // 删除户型

export function roomDel(params) {
  return request({
    url: 'roomCate/delCate',
    method: 'get',
    params: params
  });
} // 删除楼栋

export function buildDel(params) {
  return request({
    url: 'roomCate/delBulid',
    method: 'get',
    params: params
  });
} // 导出复核

export function roomCateExport(params) {
  return request({
    url: 'roomCate/export',
    method: 'get',
    params: params
  });
} // 同步付款方式

export function syncPayInfo(params) {
  return request({
    url: 'project/syncPayInfo',
    method: 'get',
    params: params
  });
} // 获取批次分组

export function selBatchGroup(params) {
  return request({
    url: 'Event/selBatchGroup',
    method: 'get',
    params: params
  });
} // 设置批次分组

export function setBatchGroup(data) {
  return request({
    url: 'Event/setBatchGroup',
    method: 'post',
    data: data
  });
}