//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ListItem',
  props: {
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    config: {
      type: Object,
      default: function _default(_) {
        return {
          is_test_rand_user: 0
        };
      }
    },
    showHint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showHintStaus: {
      get: function get() {
        var row = this.row,
            showHint = this.showHint; // 进行中

        var underway = row.user_count > row.log_rand_cound && row.is_start == 2; // 已结束

        var end = row.user_count === row.log_rand_cound && row.is_start == 2; // 允许显示 并且 (活动进行中 || 已结束)

        return showHint && (underway || end);
      },
      set: function set() {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    getText: function getText(row) {
      if (row.log_rand_cound > 0 && row.user_count !== row.log_rand_cound) {
        return '继续摇号';
      } else if (row.log_rand_cound > 0 && row.user_count === row.log_rand_cound) {
        return '摇号结束';
      } else {
        return '开始摇号';
      }
    }
  }
};