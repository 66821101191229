var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.editFormatList.host_json.card_content_mode === "row"
                ? "序号上下位置"
                : "整体内容上下位置"
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_info_code_position,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_info_code_position", $$v)
              },
              expression: "editFormatList.card_info_code_position",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editFormatList.host_json.card_content_mode === "column",
            expression:
              "editFormatList.host_json.card_content_mode === 'column'",
          },
        ],
        staticClass: "setting-wrapper",
      },
      [
        _c("div", { staticClass: "left" }, [_vm._v("客户信息上下位置")]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("el-input-number", {
              staticClass: "input-number__style",
              model: {
                value: _vm.editFormatList.card_info_user_spacing,
                callback: function ($$v) {
                  _vm.$set(_vm.editFormatList, "card_info_user_spacing", $$v)
                },
                expression: "editFormatList.card_info_user_spacing",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.rand.show_rand_code
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("序号字号")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.card_info_code_size,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList, "card_info_code_size", $$v)
                  },
                  expression: "editFormatList.card_info_code_size",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_rand_code
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("序号颜色")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-color-picker", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.editFormatList.card_info_code_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList, "card_info_code_color", $$v)
                  },
                  expression: "editFormatList.card_info_code_color",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("客户信息字号")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_info_user_size,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_info_user_size", $$v)
              },
              expression: "editFormatList.card_info_user_size",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("客户信息颜色")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-color-picker", {
            attrs: { size: "mini" },
            model: {
              value: _vm.editFormatList.card_info_user_color,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_info_user_color", $$v)
              },
              expression: "editFormatList.card_info_user_color",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.editFormatList.host_json.card_content_mode === "row"
                ? "客户信息上下位置"
                : "客户信息纵向间距"
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.card_info_code_positionButtom,
              callback: function ($$v) {
                _vm.$set(
                  _vm.editFormatList.host_json,
                  "card_info_code_positionButtom",
                  $$v
                )
              },
              expression:
                "editFormatList.host_json.card_info_code_positionButtom",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }