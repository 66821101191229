var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "custom-table" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": {
              "background-color": _vm.headerColor,
              border: _vm.isHeaderBorder ? "1px solid white" : "",
            },
            border: "",
            "show-summary": _vm.showSummary,
            "summary-method": _vm.getSummaries,
          },
        },
        _vm._l(_vm.tableHead, function (item, i) {
          return _c("el-table-column", {
            key: i,
            attrs: {
              label: item.label,
              "min-width": item.width,
              prop: item.prop,
              "render-header": _vm.renderheader,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      (item.prop == "avg_price" || item.prop == "max_price") &&
                      _vm.isTen
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row[item.prop] !== "未提供"
                                    ? Math.floor(
                                        (+scope.row[item.prop] / 10000) * 100
                                      ) / 100
                                    : "未提供"
                                ) +
                                " "
                            ),
                          ])
                        : _c(
                            "div",
                            [
                              !_vm.isRound
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (item.prop == "avg_price" ||
                                            item.prop == "max_price") &&
                                            scope.row[item.prop] !== "未提供"
                                            ? _vm.tenThousandTransCopy(
                                                scope.row[item.prop]
                                              )
                                            : scope.row[item.prop]
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.isRound
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (item.prop == "avg_price" ||
                                            item.prop == "max_price") &&
                                            scope.row[item.prop] !== "未提供"
                                            ? _vm.addCommas(
                                                Math.round(scope.row[item.prop])
                                              )
                                            : scope.row[item.prop]
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }