var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "list-item-header" }, [
      _c("div", { staticClass: "list-item-header-left" }, [
        _vm.row.select_user_count === 0
          ? _c("div", { staticClass: "header-left-status" }, [_vm._v("待抽号")])
          : _vm.row.user_count > _vm.row.select_user_count &&
            _vm.row.room_count > _vm.row.select_room_count
          ? _c(
              "div",
              { staticClass: "header-left-status header-left-status-active" },
              [_vm._v(" 抽号中 ")]
            )
          : _c("div", { staticClass: "header-left-status" }, [
              _vm._v("抽号结束"),
            ]),
        _c("div", { staticClass: "header-left-name" }, [
          _vm._v(_vm._s(_vm.row.name)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-item-header-right" },
        [
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  content: _vm.getText(_vm.row),
                  effect: "dark",
                  placement: "top-end",
                },
              },
              [
                _c("svg-icon", {
                  attrs: { "class-name": "greens", "icon-class": "yaohao" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleStartLottery", _vm.row)
                    },
                  },
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "list-item-box" }, [
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 已抽号: "),
            _c("span", [_vm._v(_vm._s(_vm.row.select_user_count) + "人")]),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.row.select_room_count) + "套"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 待抽号: "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.row.user_count - (_vm.row.select_user_count || 0)) +
                  "人"
              ),
            ]),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.row.room_count - (_vm.row.select_room_count || 0)
                  ) +
                  "套"
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(
              " 客户总数：全部客户 -（" +
                _vm._s(_vm.row.user_count) +
                "人 " +
                _vm._s(_vm.row.room_count) +
                "套） "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }