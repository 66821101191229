var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-wrapper" },
    [
      _vm.eventList.length
        ? _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.selectHandle } },
            [
              _c("el-button", { attrs: { type: "primary", round: "" } }, [
                _vm._v(" " + _vm._s(_vm.selectEvent.name)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.eventList, function (item) {
                  return _c(
                    "el-dropdown-item",
                    { key: item.id, attrs: { command: item } },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectEvent.round_list && _vm.selectEvent.round_list.length
        ? _c(
            "div",
            { staticClass: "round-select-wrapper" },
            [
              _c("span", { staticClass: "round-select-label" }, [
                _vm._v("场次："),
              ]),
              _c(
                "el-select",
                {
                  staticClass: "round-select",
                  attrs: { "value-key": "id" },
                  model: {
                    value: _vm.selectRound,
                    callback: function ($$v) {
                      _vm.selectRound = $$v
                    },
                    expression: "selectRound",
                  },
                },
                [
                  _vm.is_round
                    ? [
                        _c("el-option", {
                          attrs: { label: "不区分场次", value: { id: 0 } },
                        }),
                      ]
                    : _vm._e(),
                  _vm._l(_vm.selectEvent.round_list, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.name + " (" + _vm.statusFn(item) + ")",
                        value: item,
                      },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }