var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    _c(
      "div",
      { ref: "top", staticClass: "search-box" },
      [
        _c(
          "el-form",
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { staticClass: "flex el-height", attrs: { span: 7 } },
                  [
                    _c("van-switch", {
                      attrs: { "switch-list": _vm.switchList },
                      on: { bindSwitch: _vm.bindSwitch },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "flex align-items tu", attrs: { span: 17 } },
                  [
                    _c("div", { staticClass: "flex heatValue" }, [
                      _c("span", [_vm._v("热度值：")]),
                      _c("div", { staticClass: "left" }, [
                        _c("span", [_vm._v("低")]),
                        _c("span", [_vm._v("中低")]),
                        _c("span", [_vm._v("中高")]),
                        _c("span", [_vm._v("高")]),
                        _c("span", [_vm._v("非售")]),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex heatValue mR80" }, [
                      _c("span", [_vm._v("割块含义：")]),
                      _c("div", { staticClass: "flex align-items" }, [
                        _c("span", [_vm._v("真实")]),
                        _c("div", { staticClass: "progress-box" }, [
                          _c("div", { staticClass: "progress" }),
                        ]),
                        _c("span", [_vm._v("干预")]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { class: ["detail-box mT16"] },
      [
        _vm.is_defect
          ? _c("div", { staticClass: "noData" }, [
              _c("img", {
                staticClass: "onImage",
                attrs: { src: require("../../../assets/image/icon-qs.png") },
              }),
              _c("div", { staticClass: "onTips" }, [_vm._v(_vm._s(_vm.msg))]),
            ])
          : [
              _vm.buildArray.length === 0
                ? _c(
                    "div",
                    { staticClass: "noData" },
                    [
                      _c("img", {
                        staticClass: "onImage",
                        attrs: {
                          src: require("../../../assets/image/icon-qs.png"),
                        },
                      }),
                      _c("div", { staticClass: "onTips" }, [
                        _vm._v(
                          "暂无" +
                            _vm._s(_vm.tabType === 0 ? "房源" : "车位") +
                            "~"
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "greens-btn",
                          attrs: { size: "mini" },
                          on: { click: _vm.handlePerfect },
                        },
                        [_vm._v("去导入")]
                      ),
                    ],
                    1
                  )
                : [
                    _c(
                      "div",
                      {
                        staticClass:
                          "button-box flex align-items space-between",
                      },
                      [
                        _c("div", { staticClass: "font-style" }, [
                          _c("span", [
                            _vm._v("已选 " + _vm._s(_vm.numbers) + " 条"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "orange-btn",
                                attrs: { size: "small" },
                                on: { click: _vm.postIdArray },
                              },
                              [_vm._v("干预热度")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "btnHollowGreen",
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.postIdArray("cancel")
                                  },
                                },
                              },
                              [_vm._v("取消干预")]
                            ),
                            _vm.cate_room
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "collect/import"],
                                        expression:
                                          "['admin', 'collect/import']",
                                      },
                                    ],
                                    staticClass: "btnHollowGreen",
                                    attrs: { size: "small" },
                                    on: { click: _vm.downloadRoomTem },
                                  },
                                  [_vm._v("下载模板(房源)")]
                                )
                              : _vm._e(),
                            _vm.cate_car
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "collect/import"],
                                        expression:
                                          "['admin', 'collect/import']",
                                      },
                                    ],
                                    staticClass: "btnHollowGreen",
                                    attrs: { size: "small" },
                                    on: { click: _vm.downloadCarTem },
                                  },
                                  [_vm._v("下载模板(车位)")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "collect/import"],
                                    expression: "['admin', 'collect/import']",
                                  },
                                ],
                                staticClass: "btnHollowGreen",
                                attrs: { size: "small" },
                                on: { click: _vm.exportCheck },
                              },
                              [_vm._v("导入意向")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "collect/export"],
                                    expression: "['admin', 'collect/export']",
                                  },
                                ],
                                staticClass: "btnHollowGreen",
                                attrs: { size: "small" },
                                on: { click: _vm.importData },
                              },
                              [_vm._v("导出数据")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "btnHollowGreen",
                                attrs: { size: "small" },
                                on: { click: _vm.getRefresh },
                              },
                              [_vm._v("刷新")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.tabType === 1
                      ? _c("div", { staticClass: "select-box" }, [
                          _c(
                            "div",
                            { staticClass: "unit-btn-wrapper" },
                            _vm._l(_vm.buildArray, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  class: [
                                    "select-box-btn",
                                    {
                                      "select-box-btn-active":
                                        _vm.buildId == item.id,
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.carBuildClick(
                                        _vm.buildArray,
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.name == "" ? "无楼栋" : item.name
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "floor-btn-wrapper" },
                            _vm._l(_vm.s_unitList, function (item, j) {
                              return _c(
                                "div",
                                {
                                  key: j,
                                  class: [
                                    "select-box-btn",
                                    {
                                      "select-box-btn-active":
                                        _vm.s_unit_id == item.id,
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.carUnitClick(
                                        _vm.s_unitList,
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.name == "" ? "无楼层" : item.name
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.tabType === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "list-box",
                            style: { height: _vm.divHeight + "px" },
                          },
                          [
                            _c("div", { staticClass: "list-left" }, [
                              _c(
                                "div",
                                { staticClass: "left-top" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.bindSwitchBuild },
                                      model: {
                                        value: _vm.buildId,
                                        callback: function ($$v) {
                                          _vm.buildId = $$v
                                        },
                                        expression: "buildId",
                                      },
                                    },
                                    _vm._l(_vm.buildArray, function (item, i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "left-bottom" },
                                _vm._l(_vm.floorList, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "floor-list" },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "list-right" },
                              _vm._l(_vm.roomData, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "unit-list" },
                                  [
                                    _c("div", { staticClass: "unit-name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _vm._l(item.floor, function (info, inx) {
                                      return _c(
                                        "div",
                                        { key: inx, staticClass: "room-list" },
                                        _vm._l(info.room, function (data, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              class:
                                                "room  " +
                                                (data.is_show ? "roomOn" : ""),
                                              staticStyle: { opacity: "1" },
                                              style: {
                                                opacity:
                                                  data.not_status == -1 &&
                                                  data.not_desc === "不显示"
                                                    ? 0
                                                    : 1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClickRoom(
                                                    index,
                                                    inx,
                                                    i,
                                                    data.not_status
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(data.room) + " "
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "bottom-xian" },
                                                [
                                                  _c("span", {
                                                    class: _vm.getColor(
                                                      data.hot,
                                                      data.not_status
                                                    ),
                                                  }),
                                                  data.hot_count != 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          class: _vm.getColor(
                                                            data.hot_count,
                                                            data.not_status
                                                          ),
                                                        },
                                                        [_vm._v("1")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    }),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "list-box",
                            style: { height: _vm.divHeight - 60 + "px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "list-right" },
                              _vm._l(_vm.roomVilla, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "unit-list",
                                    style: {
                                      width:
                                        "calc(100% / " +
                                        _vm.roomVilla.length +
                                        ")",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "unit-name" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.name == "" ? "无区域" : item.name
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "room-list room-list2",
                                        staticStyle: { width: "100%" },
                                      },
                                      _vm._l(item.room, function (data, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            class:
                                              "room  " +
                                              (data.is_show ? "roomOn" : ""),
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickVilla(
                                                  index,
                                                  i,
                                                  data.not_status
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(data.room) + " "
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "bottom-xian" },
                                              [
                                                _c("span", {
                                                  class: _vm.getColor(
                                                    data.hot,
                                                    data.not_status
                                                  ),
                                                }),
                                                data.hot_count != 0
                                                  ? _c("span", {
                                                      class: _vm.getColor(
                                                        data.hot_count,
                                                        data.not_status
                                                      ),
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                  ],
            ],
        _c(
          "el-dialog",
          {
            attrs: {
              title: "干预热度",
              center: "",
              visible: _vm.is_heat,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.is_heat = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "radio-box" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.heat,
                      callback: function ($$v) {
                        _vm.heat = $$v
                      },
                      expression: "heat",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("低")]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("中低")]),
                    _c("el-radio", { attrs: { label: 3 } }, [_vm._v("中高")]),
                    _c("el-radio", { attrs: { label: 4 } }, [_vm._v("高")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.is_heat = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleSaveHeat },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "提示",
              visible: _vm.isTips,
              width: "400px",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.isTips = $event
              },
            },
          },
          [
            _c("div", { staticClass: "row-text" }, [
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("注意，此操作为替换导入！"),
              ]),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("新导入客户意向将完全替换客户意向"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "dialog-footer flex align-items ju-center",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnHollowReset",
                    attrs: { size: "small" },
                    on: { click: _vm.closeExport },
                  },
                  [_vm._v("取 消")]
                ),
                _c("UploadExcel", {
                  staticClass: "mL10",
                  attrs: {
                    text: "确定导入",
                    url: "/collect/import",
                    accept: _vm.$xmlSel,
                  },
                  on: { uploadSuccess: _vm.closeExport },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }