var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container", attrs: { id: "login" } },
    [
      _c(
        "div",
        { staticClass: "login-content" },
        [
          _c(
            "div",
            { class: _vm.isPC ? "login-content-left" : "login-content-left2" },
            [
              _c("div", { staticClass: "logo" }, [
                _vm.defaultSettings.LogInLogo
                  ? _c("img", {
                      attrs: {
                        src: require("../../assets/image/" +
                          _vm.defaultSettings.LogInLogo),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "c-text" }, [
                _c("span", { staticClass: "c-title" }, [
                  _vm._v(_vm._s(_vm.defaultSettings.title)),
                ]),
                _c("span", { staticClass: "c-title-bottom" }, [
                  _vm._v(_vm._s(_vm.defaultSettings.logoText)),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginRules,
                    autocomplete: "on",
                    "label-position": "left",
                  },
                },
                [
                  _c("div", { staticClass: "title-container" }),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-box-wrapper",
                      attrs: { prop: "username" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                        1
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.username,
                            expression: "loginForm.username",
                          },
                        ],
                        ref: "username",
                        staticClass: "password-input",
                        attrs: {
                          type: "text",
                          name: "username",
                          tabindex: "1",
                          placeholder: _vm.$t("login.username"),
                        },
                        domProps: { value: _vm.loginForm.username },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "username",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-box-wrapper",
                          attrs: { prop: "password" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "password" },
                              }),
                            ],
                            1
                          ),
                          _vm.passwordType === "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.password,
                                    expression: "loginForm.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "password-input",
                                attrs: {
                                  autocomplete: "on",
                                  tabindex: "2",
                                  placeholder: _vm.$t("login.password"),
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.loginForm.password)
                                    ? _vm._i(_vm.loginForm.password, null) > -1
                                    : _vm.loginForm.password,
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLogin($event)
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.loginForm.password,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.loginForm, "password", $$c)
                                    }
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.checkCapslock($event)
                                  },
                                },
                              })
                            : _vm.passwordType === "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.password,
                                    expression: "loginForm.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "password-input",
                                attrs: {
                                  autocomplete: "on",
                                  tabindex: "2",
                                  placeholder: _vm.$t("login.password"),
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.loginForm.password, null),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLogin($event)
                                  },
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.loginForm,
                                      "password",
                                      null
                                    )
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.checkCapslock($event)
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.loginForm.password,
                                    expression: "loginForm.password",
                                  },
                                ],
                                ref: "password",
                                staticClass: "password-input",
                                attrs: {
                                  autocomplete: "on",
                                  tabindex: "2",
                                  placeholder: _vm.$t("login.password"),
                                  type: _vm.passwordType,
                                },
                                domProps: { value: _vm.loginForm.password },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLogin($event)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.loginForm,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.checkCapslock($event)
                                  },
                                },
                              }),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "forget" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.is_password = true
                          },
                        },
                      },
                      [_vm._v("忘记密码")]
                    ),
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "login-btn",
                      attrs: { loading: _vm.loading },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "login-text" }, [
                        _vm._v("登录"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isPC
            ? _c("div", { staticClass: "login-content-right" }, [_vm._m(0)])
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "切换项目",
                align: "center",
                width: "500px",
                visible: _vm.is_projectList,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.is_projectList = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items" },
                [
                  _c("span", { staticClass: "name" }, [_vm._v("选择项目")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "border",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        size: "mini",
                        placeholder: "选择项目",
                      },
                      model: {
                        value: _vm.projectVal,
                        callback: function ($$v) {
                          _vm.projectVal = $$v
                        },
                        expression: "projectVal",
                      },
                    },
                    _vm._l(_vm.projectList, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        class: item.status === 1 ? "" : "sta",
                        attrs: {
                          label:
                            item.project_name +
                            (item.status === 1 ? "" : " (已失效)"),
                          value: item.project_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mT15" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 2, "false-label": 1 },
                      model: {
                        value: _vm.info.is_default,
                        callback: function ($$v) {
                          _vm.$set(_vm.info, "is_default", $$v)
                        },
                        expression: "info.is_default",
                      },
                    },
                    [_vm._v("下次登录默认进入此项目 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "orange-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.saveProject },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "忘记密码",
            align: "center",
            width: "500px",
            visible: _vm.is_password,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.is_password = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录手机号", prop: "tel" } },
                [
                  _c("el-input", {
                    attrs: { size: "small", placeholder: "" },
                    model: {
                      value: _vm.form.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tel", _vm._n($$v))
                      },
                      expression: "form.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "small",
                        type: _vm.passwordType2,
                        placeholder: "输入4~12位新密码，仅限数字、字母。",
                      },
                      model: {
                        value: _vm.form.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "show-password",
                          attrs: { slot: "suffix" },
                          on: { click: _vm.showPwd2 },
                          slot: "suffix",
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType2 === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "短信验证码", prop: "code" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { size: "small", placeholder: "" },
                      model: {
                        value: _vm.form.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "code", $$v)
                        },
                        expression: "form.code",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm.time > 0
                          ? _c("span", { staticClass: "e-code" }, [
                              _vm._v("再次获取（" + _vm._s(_vm.time) + "）s"),
                            ])
                          : _c(
                              "span",
                              {
                                staticClass: "el-code",
                                on: { click: _vm.postCode },
                              },
                              [_vm._v("获取短信验证码")]
                            ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.postNewPassword },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-right-box" }, [
      _c("img", {
        attrs: { src: require("../../assets/image/loginRight.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }