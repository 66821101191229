var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nobanner" }, [
    _c(
      "div",
      { staticClass: "item-box", style: { position: _vm.position } },
      _vm._l(_vm.houseList, function (items, i) {
        return _c("item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.carousels.length
                ? _vm.carousels.indexOf(+items.id) >= 0
                : true,
              expression:
                "carousels.length ? carousels.indexOf(+items.id) >= 0 : true",
            },
          ],
          key: i,
          ref: "docItems",
          refInFor: true,
          staticClass: "houseItems",
          style: {
            position: items.x == "" ? "static" : "absolute",
            "--top": items.y == "" ? 0 + "px" : items.y,
            "--left": items.x == "" ? 0 + "px" : items.x,
            "--index": items.z_index,
          },
          attrs: {
            cate: items.cate,
            "header-color": _vm.ledConfInfo.first_back,
            "font-color": _vm.ledConfInfo.first_size,
            "card-type": _vm.ledConfInfo.show_type,
            "item-detail": items,
            "led-conf-info": _vm.ledConfInfo,
            "project-room-info": _vm.projectRoomInfo,
            allColor: _vm.allColor,
            allSize: _vm.allSize[items.id + ""] || {},
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }