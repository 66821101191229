//
//
//
//
//
//
//
//
//
export default {
  name: 'ReportTitle',
  props: {
    num: {
      type: String,
      default: '01'
    },
    title: {
      type: String,
      default: '基础数据'
    }
  }
};