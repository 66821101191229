var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "form-item"
        ? [
            _c(
              "el-form-item",
              { attrs: { label: _vm.lable } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { size: _vm.size, placeholder: "请选择" },
                    model: {
                      value: _vm.ren,
                      callback: function ($$v) {
                        _vm.ren = $$v
                      },
                      expression: "ren",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "全部", value: 0 } }),
                    _vm._l(_vm.option, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "el-form",
              { staticClass: "operator", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.lable } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: _vm.size, placeholder: "请选择" },
                        model: {
                          value: _vm.ren,
                          callback: function ($$v) {
                            _vm.ren = $$v
                          },
                          expression: "ren",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "全部", value: 0 } }),
                        _vm._l(_vm.option, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }