var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header" }, [_vm._v("填写附表")]),
    _c("div", { staticClass: "padding-container" }, [
      _c(
        "div",
        { staticClass: "scroll-box" },
        [
          _c("inputBox", {
            attrs: { "status-info": _vm.statusInfo, type: 2 },
            on: {
              handleOtherImg: _vm.handleOtherImg,
              doShowParent: _vm.doShowParent,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "top-fixed" }, [
        _c(
          "div",
          { staticClass: "block-bottom-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "pink-btn2",
                on: {
                  click: function ($event) {
                    return _vm.open_close_drawer(false)
                  },
                },
              },
              [_vm._v("关闭")]
            ),
            _c(
              "el-button",
              { staticClass: "greens-btn", on: { click: _vm.handleSave } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }