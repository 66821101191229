var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top-fixed" }, [
      _c("div", { class: _vm.isPC ? "title-top-box" : "title-hon" }, [
        _c("div", { staticClass: "title flex" }, [
          _c("div", { staticClass: "title-h1" }, [_vm._v("付款方式设置")]),
          _c("div", { staticClass: "title-tips" }, [
            _c("span", { staticClass: "mL20" }, [
              _vm._v("客户端项目名称："),
              _c("span", { domProps: { textContent: _vm._s(_vm.info.name) } }),
            ]),
            _c("span", { staticClass: "mL40" }, [
              _vm._v("项目编码："),
              _c("span", { domProps: { textContent: _vm._s(_vm.info.code) } }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "title-status flex align-items" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["project/syncpayinfo"],
                    expression: "['project/syncpayinfo']",
                  },
                ],
                staticClass: "greens-btn mL10",
                attrs: { size: "small" },
                on: { click: _vm.samePayInfo },
              },
              [_vm._v(" 同步付款方式 ")]
            ),
            !_vm.roles.includes("project/syncpayinfo")
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "project/preserve"],
                        expression: "['admin','project/preserve']",
                      },
                    ],
                    staticClass:
                      "el-button btnHollowGreen el-button--default el-button--small mL10",
                    attrs: { size: "small", icon: "el-icon-plus" },
                    on: { click: _vm.addPay },
                  },
                  [_vm._v("新增")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "project/preserve"],
                    expression: "['admin','project/preserve']",
                  },
                ],
                staticClass: "greens-btn mL10",
                attrs: { size: "small" },
                on: { click: _vm.save },
              },
              [_vm._v(" 保存 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "detail-box mT10" }, [
      !_vm.info.jf_id
        ? _c("div", { staticClass: "block-box mT10" }, [
            _c("div", { staticClass: "title-h2" }, [
              _vm._v("付款方式适用范围规则"),
            ]),
            _c("div", { staticClass: "bottom-center" }, [
              _vm.info.cate_room
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("房源: ")]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            on: { change: _vm.getPayInfoRule },
                            model: {
                              value: _vm.pay_info_room_rule,
                              callback: function ($$v) {
                                _vm.pay_info_room_rule = $$v
                              },
                              expression: "pay_info_room_rule",
                            },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("全部房源"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("按小业态"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("按楼栋"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 3 } }, [
                                  _vm._v("按户型"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 4 } }, [
                                  _vm._v("按分类"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.info.cate_car
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("车位:")]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            on: { change: _vm.getPayInfoRule },
                            model: {
                              value: _vm.pay_info_car_rule,
                              callback: function ($$v) {
                                _vm.pay_info_car_rule = $$v
                              },
                              expression: "pay_info_car_rule",
                            },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("全部车位"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("按楼层区域"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("按类型（户型）"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 3 } }, [
                                  _vm._v("按分类"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pay-content" },
        _vm._l(_vm.pay_info, function (item, index) {
          return _c("div", { key: index, staticClass: "block-box mT10" }, [
            _c("div", { staticClass: "bottom-center" }, [
              _c(
                "div",
                { staticClass: "item-lsit", staticStyle: { margin: "15px 0" } },
                [
                  _c("div", { staticClass: "lable" }, [
                    _vm._v("付款方式名称:"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "item-content",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                        width: "100%",
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: _vm.isPC ? 8 : 12 } },
                        [
                          _c("el-input", {
                            model: {
                              value: item.show_name,
                              callback: function ($$v) {
                                _vm.$set(item, "show_name", $$v)
                              },
                              expression: "item.show_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.info.jf_id
                        ? _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 8 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: item.is_default,
                                    callback: function ($$v) {
                                      _vm.$set(item, "is_default", $$v)
                                    },
                                    expression: "item.is_default",
                                  },
                                },
                                [_vm._v("默认付款方式")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "btn-right" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "project/preserve"],
                                  expression: "['admin','project/preserve']",
                                },
                              ],
                              staticClass:
                                "el-button btnHollowGreen el-button--default el-button--small mL10",
                              attrs: { size: "small", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.dleatPay(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                          !_vm.roles.includes("project/syncpayinfo")
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "project/preserve"],
                                      expression:
                                        "['admin','project/preserve']",
                                    },
                                  ],
                                  staticClass:
                                    "el-button btnHollowGreen el-button--default el-button--small mL10",
                                  attrs: {
                                    size: "small",
                                    icon: "el-icon-document",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyPay(index)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "bottom-box" }),
              _c(
                "div",
                { staticClass: "item-lsit", staticStyle: { margin: "0px 0" } },
                [
                  _c("div", { staticClass: "lable" }, [
                    _vm._v("付款方式类型:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-content" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-box",
                          on: {
                            change: function ($event) {
                              return _vm.payTypeChange(index)
                            },
                          },
                          model: {
                            value: item.type,
                            callback: function ($$v) {
                              _vm.$set(item, "type", $$v)
                            },
                            expression: "item.type",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("一次性"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("按揭"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("分期"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "item-lsit" }, [
                _c("div", { staticClass: "lable" }, [_vm._v("适用范围: ")]),
                _c(
                  "div",
                  { staticClass: "item-content" },
                  [
                    item.business_type_id != "" && item.business_type_name
                      ? [
                          _c(
                            "div",
                            { staticClass: "jsSyfw" },
                            _vm._l(
                              item.business_type_name.split(","),
                              function (datas, i) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: i,
                                    attrs: { disabled: "", checked: "" },
                                  },
                                  [_vm._v(_vm._s(datas))]
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      : [
                          _vm.info.cate_room
                            ? _c(
                                "div",
                                {
                                  staticClass: "item-lsit",
                                  staticStyle: { "padding-left": "0" },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 3 : 12 } },
                                    [
                                      _c("div", { staticClass: "lables" }, [
                                        _vm._v("房源:"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-content flex-content",
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "radio-box",
                                          staticStyle: {
                                            width: "78% !important",
                                          },
                                          attrs: {
                                            disabled:
                                              item.business_type_id != "",
                                          },
                                          model: {
                                            value: item.roomRule,
                                            callback: function ($$v) {
                                              _vm.$set(item, "roomRule", $$v)
                                            },
                                            expression: "item.roomRule",
                                          },
                                        },
                                        [
                                          _vm.pay_info_room_rule == 0
                                            ? _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 3 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("全部房源")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._l(
                                                _vm.payInfoRule.room_list,
                                                function (datas, i) {
                                                  return _c(
                                                    "el-col",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        span: _vm.isPC ? 3 : 12,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox",
                                                        {
                                                          attrs: {
                                                            label: datas.id,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(datas.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.info.cate_car
                            ? _c(
                                "div",
                                {
                                  staticClass: "item-lsit",
                                  staticStyle: { "padding-left": "0" },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 3 : 12 } },
                                    [
                                      _c("div", { staticClass: "lables" }, [
                                        _vm._v("车位:"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "item-content" },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "radio-box",
                                          staticStyle: {
                                            width: "78% !important",
                                          },
                                          attrs: {
                                            disabled:
                                              item.business_type_id != "",
                                          },
                                          model: {
                                            value: item.carRule,
                                            callback: function ($$v) {
                                              _vm.$set(item, "carRule", $$v)
                                            },
                                            expression: "item.carRule",
                                          },
                                        },
                                        [
                                          _vm.pay_info_car_rule == 0
                                            ? _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 3 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("全部车位")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._l(
                                                _vm.payInfoRule.car_list,
                                                function (datas, i) {
                                                  return _c(
                                                    "el-col",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        span: _vm.isPC ? 3 : 12,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox",
                                                        {
                                                          attrs: {
                                                            label: datas.id,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(datas.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "item-lsit" }, [
                _c("div", { staticClass: "lable" }, [_vm._v("付款时间约束:")]),
                _c(
                  "div",
                  { staticClass: "item-content" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "radio-box",
                        model: {
                          value: item.pay_time_type,
                          callback: function ($$v) {
                            _vm.$set(item, "pay_time_type", $$v)
                          },
                          expression: "item.pay_time_type",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: _vm.isPC ? 3 : 12 } },
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("约束时间"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: _vm.isPC ? 3 : 12 } },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("仅做参考"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: _vm.isPC ? 3 : 12 } },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不设置时间"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              item.type == 0
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("认购后:")]),
                    _c("div", { staticClass: "house-type-wrapper" }, [
                      _c("span", [_vm._v("第")]),
                      _c(
                        "div",
                        { staticClass: "main_input" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            attrs: { min: "1", type: "number" },
                            model: {
                              value: item.reservation_time,
                              callback: function ($$v) {
                                _vm.$set(item, "reservation_time", _vm._n($$v))
                              },
                              expression: "item.reservation_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticStyle: { width: "20px" } }, [
                        _vm._v("天"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              item.type == 1
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }),
                    _c("div", { staticClass: "flex item-middle" }, [
                      _c("div", { staticClass: "house-type-wrapper" }, [
                        _c("span", [_vm._v("认购后第")]),
                        _c(
                          "div",
                          { staticClass: "main_input" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              attrs: { min: "1", type: "number" },
                              model: {
                                value: item.reservation_time,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "reservation_time",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "item.reservation_time",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { width: "20px" } }, [
                          _vm._v("天"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "house-type-wrapper mr-left20" },
                        [
                          _c("span", [_vm._v("付款比例")]),
                          _c(
                            "div",
                            { staticClass: "main_input" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80px" },
                                attrs: { type: "number" },
                                model: {
                                  value: item.pay_proportion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "pay_proportion",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "item.pay_proportion",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticStyle: { width: "20px" } }, [
                            _vm._v("%"),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              item.type != 0 && item.type != 1
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("期数:")]),
                    _c("div", { staticClass: "item-content" }, [
                      _c("div", { staticClass: "house-type-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "main_input left_right_none" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              attrs: { minlength: 1, type: "number" },
                              model: {
                                value: item.by_stages_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "by_stages_number",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "item.by_stages_number",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { width: "20px" } }, [
                          _vm._v("期"),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              item.type != 0 && item.type != 1
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("付款期数:")]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            model: {
                              value: item.by_stages_type,
                              callback: function ($$v) {
                                _vm.$set(item, "by_stages_type", $$v)
                              },
                              expression: "item.by_stages_type",
                            },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("设置固定期数"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("设置最大期数"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              item.type != 0 && item.type != 1
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("计算方式:")]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            model: {
                              value: item.by_stages_amount_type,
                              callback: function ($$v) {
                                _vm.$set(item, "by_stages_amount_type", $$v)
                              },
                              expression: "item.by_stages_amount_type",
                            },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("按比例"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 3 : 12 } },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("按金额"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              item.type != 0 && item.type != 1
                ? _c("div", { staticClass: "item-lsit" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("时间设置:")]),
                    _c(
                      "div",
                      { staticClass: "item-content" },
                      _vm._l(item.pay_by_stages, function (item2, index2) {
                        return _c(
                          "div",
                          {
                            key: index2,
                            class: [
                              "periods_items",
                              "flex",
                              { marginT0: item.pay_by_stages.length == 1 },
                            ],
                          },
                          [
                            _c("span", { staticClass: "perNumbe" }, [
                              _vm._v("第" + _vm._s(index2 + 1) + "期"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "house-type-wrapper mr-left20" },
                              [
                                _c("span", [_vm._v("认购后第")]),
                                _c(
                                  "div",
                                  { staticClass: "main_input" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80px" },
                                      attrs: { type: "number" },
                                      model: {
                                        value: item2.reservation_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item2,
                                            "reservation_time",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "item2.reservation_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticStyle: { width: "20px" } }, [
                                  _vm._v("天"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "house-type-wrapper mr-left20" },
                              [
                                _c("span", [_vm._v("建议付款")]),
                                !item.by_stages_amount_type
                                  ? _c(
                                      "div",
                                      { staticClass: "main_input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: { type: "number" },
                                          model: {
                                            value: item2.pay_proportion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item2,
                                                "pay_proportion",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "item2.pay_proportion",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.by_stages_amount_type
                                  ? _c(
                                      "div",
                                      { staticClass: "main_input" },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          attrs: {
                                            disabled:
                                              item2.late_stage_amount === 1,
                                            type: "number",
                                          },
                                          model: {
                                            value: item2.payment_amount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item2,
                                                "payment_amount",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "item2.payment_amount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("span", { staticStyle: { width: "20px" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item.by_stages_amount_type ? "%" : "元"
                                      )
                                  ),
                                ]),
                                index2 == item.pay_by_stages.length - 1 &&
                                item.by_stages_amount_type
                                  ? _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "false-label": 0,
                                          "true-label": 1,
                                        },
                                        on: {
                                          change: function (v) {
                                            _vm.lateChange(v, index, index2)
                                          },
                                        },
                                        model: {
                                          value: item2.late_stage_amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item2,
                                              "late_stage_amount",
                                              $$v
                                            )
                                          },
                                          expression: "item2.late_stage_amount",
                                        },
                                      },
                                      [_vm._v("成交总价-分期合计")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "delt" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "删除",
                                      placement: "top-end",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "greens",
                                        "icon-class": "delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.dleatByStages(
                                            index,
                                            index2
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "add" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "增加期数",
                                      placement: "top-end",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-plus",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addByStages(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }