var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-box",
      staticStyle: { height: "0" },
      style: {
        "--height":
          _vm.allSize.height ||
          (_vm.isShowscreen && _vm.height && !isNaN(_vm.height)
            ? _vm.height
            : _vm.itemDetail.height !== "" && _vm.ledinfo.is_banner == 0
            ? _vm.itemDetail.height
            : 30),
        "--width":
          _vm.allSize.width ||
          (_vm.isShowscreen && _vm.width && !isNaN(_vm.width)
            ? _vm.width
            : _vm.itemDetail.width !== "" && _vm.ledinfo.is_banner == 0
            ? _vm.itemDetail.width
            : 100),
        "--floor-num": _vm.itemDetail.floor_num,
      },
      attrs: { id: "unit-box-" + _vm.itemDetail.id },
    },
    [
      _vm.cardType == "0"
        ? _c(
            "div",
            {
              ref: "wrapper",
              on: {
                dblclick: function ($event) {
                  return _vm.ondblClick(_vm.itemDetail, "", "", "itemDetailId")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "unit",
                  style: {
                    backgroundColor: _vm.doGetStyle("", "border_")
                      .backgroundColor,
                  },
                  attrs: {
                    "data-id": _vm.itemDetail.id,
                    id: "unit-" + _vm.itemDetail.id,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "unit-title",
                      style: {
                        color: _vm.doGetStyle("", "border_").color,
                        fontSize: this.waitJson.buildingSize + "px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.itemDetail.build_name) +
                          " " +
                          _vm._s(_vm.itemDetail.unit_room) +
                          " "
                      ),
                    ]
                  ),
                  _vm.isDrag
                    ? _c("el-checkbox", {
                        staticStyle: {
                          float: "right",
                          "margin-top": "-25px",
                          "margin-right": "5px",
                        },
                        on: { change: _vm.onSelectIds },
                        model: {
                          value: _vm.SelectId,
                          callback: function ($$v) {
                            _vm.SelectId = $$v
                          },
                          expression: "SelectId",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "unit-wrapper",
                      style: _vm.doGetStyle("", ""),
                    },
                    _vm._l(_vm.itemDetail.floorSort, function (floorItem, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "unit-floor" },
                        _vm._l(
                          _vm.itemDetail.room[floorItem],
                          function (room, inx) {
                            return _c(
                              "div",
                              {
                                key: inx,
                                class: [
                                  "unit-house-num",
                                  { fontBold: _vm.waitJson.fhBold },
                                ],
                                style: {
                                  "--bgc":
                                    room.room_status == 0
                                      ? _vm.doGetStyle("", "forSale_")
                                          .backgroundColor ||
                                        "rgba(255, 255, 255, 0.2)"
                                      : room.room_status == -1
                                      ? _vm.doGetStyle("", "notForSale_")
                                          .backgroundColor ||
                                        "rgba(255, 255, 255, 0.2)"
                                      : room.room_status == 3 ||
                                        room.room_status == 1
                                      ? _vm.doGetStyle("", "sold_")
                                          .backgroundColor || "red"
                                      : "",
                                  color:
                                    room.room_status == 0
                                      ? _vm.doGetStyle("", "forSale_").color ||
                                        "white"
                                      : room.room_status == -1
                                      ? _vm.doGetStyle("", "notForSale_")
                                          .color || "white"
                                      : room.room_status == 3 ||
                                        room.room_status == 1
                                      ? _vm.doGetStyle("", "sold_").color ||
                                        "white"
                                      : "",
                                  opacity:
                                    room.room_status == -1 &&
                                    room.not_desc === "不显示"
                                      ? 0
                                      : 1,
                                  fontSize: _vm.waitJson.fhSize + "px",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      room.room_status == -1
                                        ? "非售"
                                        : room.no_name
                                    ) + " "
                                  ),
                                ]),
                                _vm.ledConfInfo.user_info_batch_code == 1
                                  ? _c("span", { staticClass: "fontSize" }, [
                                      _vm._v(_vm._s(room.code) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }),
                    0
                  ),
                  _vm.isDrag
                    ? _c("div", { attrs: { id: "right-drag" } })
                    : _vm._e(),
                  _vm.isDrag
                    ? _c("div", { attrs: { id: "bottom-drag" } })
                    : _vm._e(),
                  _vm.isDrag
                    ? _c("div", { attrs: { id: "r-b-drag" } })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.cardType == 1 && _vm.itemDetail.room_max != 0
        ? _c("div", { ref: "wrapper" }, [
            _c(
              "div",
              {
                staticClass: "unit",
                style: {
                  backgroundColor: _vm.doGetStyle("", "border_")
                    .backgroundColor,
                },
                attrs: {
                  "data-id": _vm.itemDetail.id,
                  id: "unit-" + _vm.itemDetail.id,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "unit-title",
                    style: {
                      color: _vm.doGetStyle("", "border_").color,
                      fontSize: this.waitJson.buildingSize + "px",
                    },
                    on: {
                      dblclick: function ($event) {
                        return _vm.ondblClick(
                          _vm.itemDetail,
                          "",
                          "",
                          "itemDetailId"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.itemDetail.build_name) +
                        " " +
                        _vm._s(_vm.itemDetail.unit_room) +
                        " "
                    ),
                  ]
                ),
                _vm.isDrag
                  ? _c("el-checkbox", {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-25px",
                        "margin-right": "5px",
                      },
                      on: { change: _vm.onSelectIds },
                      model: {
                        value: _vm.SelectId,
                        callback: function ($$v) {
                          _vm.SelectId = $$v
                        },
                        expression: "SelectId",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "unit-wrapper",
                    style: _vm.doGetStyle("", ""),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "top-tip" },
                      _vm._l(_vm.itemDetail.room_max, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "unit-box-top",
                            style: { display: "flex" },
                          },
                          [
                            _vm.ledConfInfo.show_floor == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell",
                                    style: _vm.doGetStyle(
                                      "floor_name",
                                      "title_"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "floor_name",
                                          "楼层"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 楼层 ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "box-top-cell box-room-num",
                                style: _vm.doGetStyle(
                                  "no_name",
                                  "title_",
                                  "room_number",
                                  "headSize"
                                ),
                                on: {
                                  dblclick: function ($event) {
                                    return _vm.ondblClick(
                                      _vm.itemDetail,
                                      "no_name",
                                      "房号"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.cate === 1
                                        ? _vm.ledConfInfo.room_num_name ||
                                            "房号"
                                        : _vm.ledConfInfo.car_num_name ||
                                            "车位号"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm.ledConfInfo.show_room_cate == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell",
                                    style: _vm.doGetStyle(
                                      "room_cate_name",
                                      "title_",
                                      "show_room_cate",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "room_cate_name",
                                          "户型"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 户型 ")]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.show_build_area == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell",
                                    style: _vm.doGetStyle(
                                      "build_area",
                                      "title_",
                                      "show_build_area",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "build_area",
                                          "建筑面积"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 建筑面积 ")]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.show_room_area == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell",
                                    style: _vm.doGetStyle(
                                      "room_area",
                                      "title_",
                                      "show_room_area",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "room_area",
                                          "套内面积"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 套内面积 ")]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_an_jie_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "pin_an_jie_unit_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "pin_an_jie_unit_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_an_jie_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_an_jie_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_biao_zhun_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_unit_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_unit_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_biao_zhun_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_biao_zhun_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_xian_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_unit_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_unit_price",
                                          _vm.ledConfInfo.room_info_pin_xian_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo.room_info_pin_xian_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_yi_ci_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "one_off_unit_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "one_off_unit_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_yi_ci_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_yi_ci_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_you_hui_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "discount_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "discount_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_you_hui_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_you_hui_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.droom_info_pin_yuan_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_unit_price",
                                      "title_",
                                      "show_price",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_unit_price",
                                          _vm.ledConfInfo.room_info_pin_yuan_jia
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo.room_info_pin_yuan_jia
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_an_jie_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "pin_an_jie_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "pin_an_jie_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_an_jie_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_an_jie_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_biao_zhun_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_biao_zhun_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_biao_zhun_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_xian_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_xian_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_xian_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_yi_ci_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "one_off_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "one_off_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_yi_ci_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_yi_ci_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_you_hui_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "discount_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "discount_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_you_hui_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_you_hui_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.zroom_info_pin_yuan_jia == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "old_total_price",
                                      "title_",
                                      "show_price2",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "old_total_price",
                                          _vm.ledConfInfo
                                            .room_info_pin_yuan_jia2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo
                                            .room_info_pin_yuan_jia2
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.user_info_batch_code == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle("code", "title_"),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "code",
                                          "批次号+诚意单号"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 批次号+" +
                                        _vm._s(_vm.$adminList.user_code_name) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.ledConfInfo.show_room_other == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "box-top-cell price-cell",
                                    style: _vm.doGetStyle(
                                      "code",
                                      "title_",
                                      "show_room_other",
                                      "headSize"
                                    ),
                                    on: {
                                      dblclick: function ($event) {
                                        return _vm.ondblClick(
                                          _vm.itemDetail,
                                          "other",
                                          "其他信息"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ledConfInfo.room_other_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._l(_vm.itemDetail.floorSort, function (floorItem, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "unit-box" },
                        _vm._l(
                          _vm.itemDetail.room[floorItem],
                          function (room, j) {
                            return _c(
                              "div",
                              {
                                key: j,
                                staticClass: "unit-room-detail",
                                style: {
                                  opacity:
                                    room.room_status == -1 &&
                                    room.not_desc === "不显示"
                                      ? 0
                                      : 1,
                                },
                              },
                              [
                                _c("div", { staticClass: "unit-box-bottom" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.ledConfInfo.show_floor == 1,
                                          expression:
                                            "ledConfInfo.show_floor == 1",
                                        },
                                      ],
                                      class: [
                                        "box-bottom-cell room-floor",
                                        {
                                          fontBold:
                                            _vm.waitJson.boldList.includes(
                                              "show_floor"
                                            ),
                                        },
                                      ],
                                      style: {
                                        "--color":
                                          room.room_status == 0
                                            ? _vm.headerColor ||
                                              _vm.doGetStyle(
                                                "floor_name",
                                                "forSale_"
                                              ).backgroundColor
                                            : room.room_status == -1
                                            ? _vm.doGetStyle(
                                                "floor_name",
                                                "notForSale_"
                                              ).backgroundColor || "#545454"
                                            : room.room_status == 3 ||
                                              room.room_status == 1
                                            ? _vm.doGetStyle(
                                                "floor_name",
                                                "sold_"
                                              ).backgroundColor || "red"
                                            : "",
                                        "--fontColor":
                                          _vm.doGetStyle(
                                            "floor_name",
                                            room.room_status == 0
                                              ? "forSale_"
                                              : room.room_status == -1
                                              ? "notForSale_"
                                              : "sold_"
                                          ).color || _vm.fontColor,
                                        "--width":
                                          _vm.doGetStyle("floor_name")[
                                            "--width"
                                          ],
                                        "--height":
                                          _vm.doGetStyle("floor_name")[
                                            "--height"
                                          ],
                                      },
                                      on: {
                                        dblclick: function ($event) {
                                          return _vm.ondblClick(
                                            _vm.itemDetail,
                                            "floor_name",
                                            "楼层"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(room.floor_name) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "box-bottom-cell room-num box-room-num",
                                      class: [
                                        "box-bottom-cell room-num box-room-num",
                                        {
                                          fontBold:
                                            _vm.waitJson.boldList.includes(
                                              "room_number"
                                            ),
                                        },
                                      ],
                                      style: {
                                        "--color":
                                          room.room_status == 0
                                            ? (_vm.ledConfInfo.show_floor == 0
                                                ? _vm.headerColor
                                                : "#545454") ||
                                              _vm.doGetStyle(
                                                "no_name",
                                                "forSale_"
                                              ).backgroundColor
                                            : room.room_status == -1
                                            ? _vm.doGetStyle(
                                                "no_name",
                                                "notForSale_"
                                              ).backgroundColor || "#545454"
                                            : room.room_status == 3 ||
                                              room.room_status == 1
                                            ? _vm.doGetStyle("no_name", "sold_")
                                                .backgroundColor || "red"
                                            : "#545454",
                                        "--fontColor":
                                          _vm.doGetStyle(
                                            "no_name",
                                            room.room_status == 0
                                              ? "forSale_"
                                              : room.room_status == -1
                                              ? "notForSale_"
                                              : "sold_"
                                          ).color ||
                                          (_vm.ledConfInfo.show_floor == 0
                                            ? _vm.fontColor
                                            : "#fff"),
                                        "--width":
                                          _vm.doGetStyle("no_name")["--width"],
                                        "--height":
                                          _vm.doGetStyle("no_name")["--height"],
                                        fontSize: _vm.doGetStyle(
                                          "no_name",
                                          "",
                                          "room_number",
                                          "contentSize"
                                        )["fontSize"],
                                      },
                                      on: {
                                        dblclick: function ($event) {
                                          return _vm.ondblClick(
                                            _vm.itemDetail,
                                            "no_name",
                                            "房间号"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(room.no_name) + " ")]
                                  ),
                                  true
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "unit-r-box",
                                          style: {
                                            "--saleOverBg":
                                              _vm.ledConfInfo.show_out == 1
                                                ? room.room_status == 1 ||
                                                  room.room_status == 3
                                                  ? "red"
                                                  : "rgba(255, 255, 255, 0.2)"
                                                : "rgba(255, 255, 255, 0.2)",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .show_room_cate == 1,
                                                  expression:
                                                    "ledConfInfo.show_room_cate == 1",
                                                },
                                              ],
                                              staticClass: "box-bottom-cell",
                                              class: [
                                                "box-bottom-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_room_cate"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "room_cate_name",
                                                "forSale_"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "room_cate_name",
                                                    "户型",
                                                    "show_room_cate",
                                                    "contentSize"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(room.room_cate_name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .show_build_area == 1,
                                                  expression:
                                                    "ledConfInfo.show_build_area == 1",
                                                },
                                              ],
                                              staticClass: "box-bottom-cell",
                                              class: [
                                                "box-bottom-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_build_area"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "build_area",
                                                "forSale_",
                                                "show_build_area",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "build_area",
                                                    "建筑面积"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    room.build_area == 0
                                                      ? "--"
                                                      : room.build_area
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .show_room_area == 1,
                                                  expression:
                                                    "ledConfInfo.show_room_area == 1",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_room_area"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "room_area",
                                                "forSale_"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "room_area",
                                                    "套内面积",
                                                    "show_room_area",
                                                    "contentSize"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    room.room_area == 0
                                                      ? "--"
                                                      : room.room_area
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_an_jie_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_an_jie_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "pin_an_jie_unit_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "pin_an_jie_unit_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_an_jie_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.pin_an_jie_unit_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.pin_an_jie_unit_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.pin_an_jie_unit_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_biao_zhun_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_biao_zhun_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_unit_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_unit_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_biao_zhun_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_unit_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_unit_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.old_unit_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_xian_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_xian_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_unit_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_unit_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_xian_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_unit_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_unit_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.old_unit_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_yi_ci_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_yi_ci_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "one_off_unit_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "one_off_unit_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_yi_ci_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.one_off_unit_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.one_off_unit_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.one_off_unit_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_you_hui_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_you_hui_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "discount_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "discount_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_you_hui_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.discount_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.discount_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.discount_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .droom_info_pin_yuan_jia,
                                                  expression:
                                                    "ledConfInfo.droom_info_pin_yuan_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_unit_price",
                                                "forSale_",
                                                "show_price",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_unit_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_yuan_jia
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_unit_price,
                                                      "unit"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_unit_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .unit_isunit,
                                                      expression:
                                                        "room.old_unit_price != 0 && waitJson.unit_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("/㎡")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_an_jie_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_an_jie_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "pin_an_jie_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "pin_an_jie_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_an_jie_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.pin_an_jie_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.pin_an_jie_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.pin_an_jie_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_biao_zhun_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_biao_zhun_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_biao_zhun_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.old_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_xian_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_xian_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_xian_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.old_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_yi_ci_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_yi_ci_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "one_off_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "one_off_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_yi_ci_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.one_off_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.one_off_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.one_off_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_you_hui_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_you_hui_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "discount_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "discount_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_you_hui_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.discount_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.discount_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.discount_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo
                                                      .zroom_info_pin_yuan_jia,
                                                  expression:
                                                    "ledConfInfo.zroom_info_pin_yuan_jia",
                                                },
                                              ],
                                              class: [
                                                "box-bottom-cell price-cell",
                                                {
                                                  fontBold:
                                                    _vm.waitJson.boldList.includes(
                                                      "show_price2"
                                                    ),
                                                },
                                              ],
                                              style: _vm.doGetStyle(
                                                "old_total_price",
                                                "forSale_",
                                                "show_price2",
                                                "contentSize"
                                              ),
                                              on: {
                                                dblclick: function ($event) {
                                                  return _vm.ondblClick(
                                                    _vm.itemDetail,
                                                    "old_total_price",
                                                    _vm.ledConfInfo
                                                      .room_info_pin_yuan_jia2
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.addCommas(
                                                      room.old_total_price
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        room.old_total_price !=
                                                          0 &&
                                                        _vm.waitJson
                                                          .total_isunit,
                                                      expression:
                                                        "room.old_total_price != 0 && waitJson.total_isunit",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.ledConfInfo.show_out ==
                                                      0 &&
                                                    (room.room_status == 3 ||
                                                      room.room_status == 1),
                                                  expression:
                                                    "ledConfInfo.show_out == 0 && (room.room_status == 3 || room.room_status == 1)",
                                                },
                                              ],
                                              staticClass: "sale-over-wrapper",
                                              style: _vm.doGetStyle(
                                                "",
                                                "sold_"
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.projectInfo.pin_gao_qing
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: room.room_status == -1,
                                                  expression:
                                                    "room.room_status == -1",
                                                },
                                              ],
                                              staticClass:
                                                "sale-over-wrapper-copy",
                                              style: _vm.doGetStyle(
                                                "",
                                                "notForSale_"
                                              ),
                                            },
                                            [_vm._v("非售")]
                                          ),
                                          _vm.ledConfInfo
                                            .user_info_batch_code == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "box-bottom-cell price-cell",
                                                  style: _vm.doGetStyle(
                                                    "code",
                                                    "forSale_"
                                                  ),
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.ondblClick(
                                                        _vm.itemDetail,
                                                        "code",
                                                        "批次号 + 诚意单号"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(room.code) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.ledConfInfo.show_room_other == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "box-bottom-cell price-cell",
                                                  style: _vm.doGetStyle(
                                                    "room_area",
                                                    "forSale_",
                                                    "show_room_other",
                                                    "contentSize"
                                                  ),
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      return _vm.ondblClick(
                                                        _vm.itemDetail,
                                                        "room_area",
                                                        "其他信息"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(room.other) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }),
                  ],
                  2
                ),
                _vm.isDrag
                  ? _c("div", { attrs: { id: "right-drag" } })
                  : _vm._e(),
                _vm.isDrag
                  ? _c("div", { attrs: { id: "bottom-drag" } })
                  : _vm._e(),
                _vm.isDrag
                  ? _c("div", { attrs: { id: "r-b-drag" } })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }