import request from '@/utils/request'; // 单步审核列表

var auditListAlone = function auditListAlone(params) {
  return request({
    url: 'identifyAudit/auditAloneList',
    method: 'get',
    params: params
  });
}; // 初审列表


var auditListFirst = function auditListFirst(params) {
  return request({
    url: '/identifyAudit/auditFirstList',
    method: 'get',
    params: params
  });
}; // 复审列表


var auditListSecond = function auditListSecond(params) {
  return request({
    url: '/identifyAudit/auditComplexList',
    method: 'get',
    params: params
  });
}; // 批量转入客户


var transferToCustomer = function transferToCustomer(data) {
  return request({
    url: '/identifyAudit/intoUserAll',
    method: 'post',
    data: data
  });
}; // 导出数据


var exportAuditUser = function exportAuditUser(params) {
  return request({
    url: '/identifyAudit/export',
    method: 'get',
    params: params
  });
}; // 项目配置


var adminSetting = function adminSetting(params) {
  return request({
    url: '/auth/parameter',
    method: 'get',
    params: params
  });
}; // 批量审核


var batchReview = function batchReview(data) {
  return request({
    url: '/identifyAudit/AllAudit',
    method: 'post',
    data: data
  });
}; // 批量删除


var auditDelAll = function auditDelAll(data) {
  return request({
    url: '/identifyAudit/AllDel',
    method: 'post',
    data: data
  });
}; //  派号列表


var sendList = function sendList(data) {
  return request({
    url: '/sendNo/list',
    method: 'post',
    data: data
  });
}; //  派号导出


var sendExport = function sendExport(data) {
  return request({
    url: '/sendNo/export',
    method: 'post',
    data: data
  });
};

export { auditListAlone, auditListFirst, auditListSecond, transferToCustomer, exportAuditUser, adminSetting, batchReview, auditDelAll, sendList, sendExport };