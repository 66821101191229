var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["items", _vm.flex1 ? "itemFlex" : ""] },
    [
      _vm.type == "time"
        ? [
            _c(
              "div",
              {
                staticClass: "time-box",
                style: "background:" + _vm.color.background,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    style:
                      "color:" +
                      _vm.color.title_Color +
                      ";font-size:" +
                      _vm.color.title_size +
                      "px",
                  },
                  [_vm._v(" " + _vm._s(_vm.title || _vm.subTitle) + " ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "itemValue",
                    style:
                      "background:" +
                      _vm.color.time_Bg +
                      ";font-size:" +
                      _vm.color.time_size +
                      "px" +
                      ";color:" +
                      _vm.color.time_Color,
                  },
                  [
                    _vm.isSlot
                      ? _vm._t("timeValue")
                      : _c(
                          "span",
                          [
                            _vm._l(_vm.value, function (t, i) {
                              return _vm.value
                                ? _c(
                                    "span",
                                    { class: _vm.isChinese(t) ? "text" : "" },
                                    [_vm._v(_vm._s(t))]
                                  )
                                : _vm._e()
                            }),
                            !_vm.value
                              ? _c("span", [_vm._v("2038/03/09 15:00:00")])
                              : _vm._e(),
                          ],
                          2
                        ),
                  ],
                  2
                ),
              ]
            ),
          ]
        : _vm.type == "amount"
        ? [
            _c("div", { staticClass: "amount-box" }, [
              _vm.type2 == "container"
                ? _c(
                    "div",
                    {
                      staticClass: "containers",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      _vm.children && _vm.children.length
                        ? _c(
                            "div",
                            { staticClass: "children" },
                            _vm._l(_vm.children, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "other children-list",
                                  on: {
                                    click: function ($event) {
                                      return _vm.childeClk(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      style:
                                        "color:" +
                                        item.styleForm.name_Color +
                                        ";font-size:" +
                                        item.styleForm.name_size +
                                        "px",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      style:
                                        "color:" +
                                        item.styleForm.data_Color +
                                        ";font-size:" +
                                        item.styleForm.data_size +
                                        "px",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.value || item.value === 0
                                              ? item.value
                                              : 16
                                          ) +
                                          " "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.unit == "room"
                                                ? "套"
                                                : item.unit == "car"
                                                ? "个"
                                                : item.unit == "people"
                                                ? "人"
                                                : ""
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", {
                            staticStyle: {
                              padding: "40px",
                              "text-align": "center",
                            },
                          }),
                    ]
                  )
                : _c("div", { staticClass: "other" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.value || _vm.value === 0 ? _vm.value : 16) +
                          " "
                      ),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.unit == "room"
                                ? "套"
                                : _vm.unit == "car"
                                ? "个"
                                : _vm.unit == "people"
                                ? "人"
                                : ""
                            )
                        ),
                      ]),
                    ]),
                  ]),
            ]),
          ]
        : _vm.type == "other"
        ? [
            _c("div", { staticClass: "other-box" }, [
              _vm.type2 == "name"
                ? _c(
                    "div",
                    {
                      staticClass: "projectName",
                      style:
                        "color:" +
                        _vm.color.title_Color +
                        ";font-size:" +
                        _vm.color.title_size +
                        "px;margin-bottom:10px",
                    },
                    [_vm._v(" " + _vm._s(_vm.value || "XXXXXX项目") + " ")]
                  )
                : _vm._e(),
              _vm.type2 == "watermark"
                ? _c(
                    "div",
                    {
                      staticClass: "watermark",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../../assets/image/logo.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "point" }),
                      _c(
                        "div",
                        {
                          staticClass: "logo-text",
                          style: "color:" + _vm.color.brand_Color,
                        },
                        [_vm._v("数字化开盘专家")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.type2 == "afterBatch" || _vm.type2 == "batch"
                ? _c(
                    "div",
                    {
                      staticClass: "batch-box",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      _c(
                        "div",
                        [
                          !_vm.isSlot || _vm.lineBreak
                            ? [
                                _vm.lineBreak
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "batch-title",
                                        style:
                                          "color:" +
                                          _vm.color.title_Color +
                                          ";font-size:" +
                                          _vm.color.title_size +
                                          "px",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.lineBreaTitle) + ": "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "batch-value",
                                    style:
                                      "color:" +
                                      _vm.color.data_Color +
                                      ";font-size:" +
                                      _vm.color.data_size +
                                      "px",
                                  },
                                  [_vm._v(_vm._s(_vm.value))]
                                ),
                              ]
                            : [_vm._t("bactch")],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.type2 == "houseType"
                ? _c(
                    "div",
                    {
                      staticClass: "houseType",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      !_vm.isSlot
                        ? _c(
                            "div",
                            {
                              staticClass: "house-type-items",
                              style: "background:" + _vm.color.record_bg,
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  style:
                                    "color:" +
                                    _vm.color.houseType_Color +
                                    ";font-size:" +
                                    _vm.color.houseType_size +
                                    "px",
                                },
                                [_vm._v("A1")]
                              ),
                              _vm._m(0),
                            ]
                          )
                        : _vm._e(),
                      _vm._t("houseScroll"),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.type2 == "flow"
                ? _c(
                    "div",
                    {
                      staticClass: "flow",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      !_vm.isSlot
                        ? _c(
                            "div",
                            {
                              staticClass: "flows",
                              style: "background:" + _vm.color.record_bg,
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "choice-history-header" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "img",
                                    attrs: { "icon-class": "avater0" },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "choice-history-mes" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "choice-history-name",
                                    style:
                                      "color:" +
                                      _vm.color.record_name_Color +
                                      ";font-size:" +
                                      _vm.color.record_name_size +
                                      "px",
                                  },
                                  [_vm._v(" XXX ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "choice-history-house",
                                    style:
                                      "color:" +
                                      _vm.color.record_house_Color +
                                      ";font-size:" +
                                      _vm.color.record_house_size +
                                      "px",
                                  },
                                  [_vm._v(" 1栋1单元101室 ")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._t("flowScroll"),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.type2 == "saleMoney"
                ? _c(
                    "div",
                    {
                      staticClass: "saleMoney",
                      style: "background:" + _vm.color.background,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sales-title",
                          style:
                            "color:" +
                            _vm.color.title_Color +
                            ";font-size:" +
                            _vm.color.title_size +
                            "px",
                        },
                        [_vm._v("销售额")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sales-value",
                          style:
                            "color:" +
                            _vm.color.data_Color +
                            ";font-size:" +
                            _vm.color.data_size +
                            "px",
                        },
                        [
                          _vm._t("salesVolume"),
                          !_vm.isSlot
                            ? _c("span", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-right": "10px",
                                      "font-size": "20px",
                                    },
                                  },
                                  [_vm._v("¥")]
                                ),
                                _vm._v(_vm._s(_vm.value)),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: {
          src: require("../../../../assets/image/default.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }