var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "div",
        {
          ref: "top",
          staticClass: "search-box title-top-box flex align-items ju-center",
          staticStyle: { "min-height": "72px", padding: "18px 20px" },
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("摇号预设")]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "font-style" }, [
              _c("div", [
                _c(
                  "span",
                  { staticClass: "mL10" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      },
                      [_vm._v("预设失败名单")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.filterUser,
                          callback: function ($$v) {
                            _vm.filterUser = $$v
                          },
                          expression: "filterUser",
                        },
                      },
                      [_vm._v("导入姓名与系统姓名不一致")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex align-items" },
                [
                  _c("el-input", {
                    staticClass: "mR15",
                    attrs: { placeholder: "系统姓名\\手机" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTableSearchList($event)
                      },
                    },
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "flex align-items" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "greens-btn",
                          attrs: { size: "small" },
                          on: { click: _vm.getTableSearchList },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btnHollowReset",
                          attrs: { size: "small" },
                          on: { click: _vm.resetForm },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "block-bottom mT16" }, [
        _c(
          "div",
          {
            staticClass: "tips",
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", { staticClass: "tips-top" }, [
              _c("span", { staticClass: "buttonStyle" }, [
                _vm._v(_vm._s(_vm.getButtonText(_vm.event_rend.type))),
              ]),
              _c("span", { staticClass: "mL10 tips-title" }, [
                _vm._v(_vm._s(_vm.event_rend.name) + " "),
                _c("span", {
                  staticClass: "qiqi",
                  domProps: { textContent: _vm._s(_vm.event_rend.round_name) },
                }),
              ]),
              _c("span", { staticClass: "all-person" }, [
                _vm._v(" (共" + _vm._s(_vm.total) + "人)"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "tips-bottom" },
              [
                _vm.event_rend.event_status == 1
                  ? _c("span", { staticClass: "buttonStyle2" }, [
                      _vm._v("进行中"),
                    ])
                  : _vm.event_rend.event_status == 2
                  ? _c("span", { staticClass: "buttonStyle2" }, [
                      _vm._v("已结束"),
                    ])
                  : _c("span", { staticClass: "buttonStyle2" }, [
                      _vm._v("未开始"),
                    ]),
                _vm.is_post
                  ? [
                      _vm.event_rend.round === 1
                        ? _c("span", { staticClass: "tips-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.event_rend.round_start_time * 1000
                                )
                              ) +
                                " ~ " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.event_rend.round_end_time * 1000
                                  )
                                )
                            ),
                          ])
                        : _c("span", { staticClass: "tips-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.event_rend.start_time * 1000
                                )
                              ) +
                                " ~ " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.event_rend.end_time * 1000
                                  )
                                )
                            ),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "button-box flex align-items space-between" },
          [
            _c("div", { staticClass: "font-style" }, [
              _c("div"),
              _c(
                "div",
                { staticClass: "flex align-items" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "eventranduser/add"],
                          expression: "['admin', 'eventranduser/add']",
                        },
                      ],
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.handelAddUser },
                    },
                    [_vm._v("新增预设 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen",
                      staticStyle: {
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                      attrs: { size: "small" },
                      on: { click: _vm.handleTemplate },
                    },
                    [_vm._v("下载模板 ")]
                  ),
                  _c("UploadExcel", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "eventranduser/import"],
                        expression: "['admin', 'eventranduser/import']",
                      },
                    ],
                    attrs: {
                      "data-type": "lottery",
                      size: "mini",
                      text: "导入预设",
                      url:
                        "/EventRandUser/import?event_rand_id=" +
                        _vm.$route.query.id,
                      accept: _vm.$xmlSel,
                    },
                    on: {
                      uploadSuccess: function ($event) {
                        return _vm.onOper("import")
                      },
                    },
                  }),
                  _vm.$route.query.preinstall_number > 0
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "eventranduser/export"],
                              expression: "['admin', 'eventranduser/export']",
                            },
                          ],
                          staticClass: "btnHollowGreen",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onOper("export")
                            },
                          },
                        },
                        [_vm._v("导出复核 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  border: "",
                  size: "medium",
                  data: _vm.tableData,
                  height: _vm.tableHeight,
                  "header-cell-style": { background: "#fafafa" },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", width: "55", label: "序号" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "name",
                    label: "系统姓名",
                    width: "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "rand_user_name",
                    label: "导入姓名",
                    width: "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "100",
                    prop: "tel",
                    label: "登录手机号",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "100",
                    prop: "cy_code",
                    label: _vm.list.user_code_name,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "160",
                    prop: "card",
                    label: "身份证号",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "摇号分组" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.type_group === 2
                            ? _c("span", [_vm._v("未启用摇号分组")])
                            : [
                                scoped.row.rand_group === ""
                                  ? _c("span", [_vm._v("无摇号分组")])
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(_vm._s(scoped.row.rand_group)),
                                ]),
                              ],
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "code",
                    label: "预设总顺序号",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "（计划）轮次 - 序号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.err_status === 1
                            ? _c(
                                "span",
                                { staticClass: "red" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: scoped.row.err_status_info,
                                      },
                                    },
                                    [_c("span", [_vm._v("预设失败")])]
                                  ),
                                ],
                                1
                              )
                            : [
                                scoped.row.type_group === 2
                                  ? _c("span", [
                                      _vm._v(
                                        "第 " +
                                          _vm._s(scoped.row.groups) +
                                          " 轮 - " +
                                          _vm._s(scoped.row.number) +
                                          " 号"
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "本组第 " +
                                          _vm._s(scoped.row.groups) +
                                          " 轮 - " +
                                          _vm._s(scoped.row.number) +
                                          " 号"
                                      ),
                                    ]),
                              ],
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "100",
                    prop: "status_info",
                    label: "摇号状态",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "编辑",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("svg-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "eventranduser/edit"],
                                    expression:
                                      "['admin', 'eventranduser/edit']",
                                  },
                                ],
                                attrs: {
                                  "class-name": "greens",
                                  "icon-class": "edit",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handelEditUser(scoped.row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "删除",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("svg-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["admin", "eventranduser/deluser"],
                                    expression:
                                      "['admin', 'eventranduser/deluser']",
                                  },
                                ],
                                attrs: {
                                  "class-name": "greens",
                                  "icon-class": "delete",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handelDelList(scoped.row.id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": _vm.sizes,
                "page-size": _vm.pageSize,
                layout: _vm.layouts,
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.AddEdit,
            visible: _vm.isGrouping,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isGrouping = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "formData", attrs: { model: _vm.addEditForm } },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "flexn" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户手机号", prop: "tel" } },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            size: "small",
                            placeholder:
                              "姓名\\手机号\\" +
                              _vm.list.user_code_name +
                              "\\身份证号",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handelFind()
                            },
                          },
                          model: {
                            value: _vm.addEditForm.tel,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addEditForm,
                                "tel",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addEditForm.tel",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex-shrink maL10" },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "greens-btn",
                            attrs: { size: "small" },
                            on: { click: _vm.handelFind },
                          },
                          [_vm._v("查找")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.is_user
                ? _c(
                    "div",
                    { staticClass: "user_list" },
                    [
                      _vm.addEditForm.userList == null
                        ? _c("div", { staticClass: "user_tips" }, [
                            _vm._v("抱歉，在此活动/场次没有找到此用户"),
                          ])
                        : _vm._l(
                            _vm.addEditForm.userList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "user-box",
                                  on: {
                                    click: function ($event) {
                                      _vm.selectIndex = index
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "user_image",
                                      staticStyle: { "margin-right": "15px" },
                                    },
                                    [
                                      _c("el-avatar", {
                                        attrs: {
                                          icon: "el-icon-user-solid",
                                          size: 60,
                                          src: _vm.host + item.avatar,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "user_text" }, [
                                    _c("p", [
                                      _vm._v("客 户 名：" + _vm._s(item.name)),
                                    ]),
                                    _c("p", [
                                      _vm._v("手 机 号：" + _vm._s(item.tel)),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.list.user_code_name) +
                                          "：" +
                                          _vm._s(item.code)
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "摇号分组：" + _vm._s(item.rand_group)
                                      ),
                                    ]),
                                  ]),
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: index == _vm.selectIndex,
                                        expression: "index == selectIndex",
                                      },
                                    ],
                                    staticClass: "right-select-icon",
                                  }),
                                ]
                              )
                            }
                          ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预设顺序号", prop: "code" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.addEditForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.addEditForm, "code", _vm._n($$v))
                              },
                              expression: "addEditForm.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "greens-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.handelAddPre },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }