import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var confing = require('./componentLibrary');

import items from './items.vue';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'commonLibrary',
  components: {
    items: items
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['commontList', 'adminParameter'])), {}, {
    newcommonLIst: function newcommonLIst() {
      var _this$adminParameter = this.adminParameter,
          _this$adminParameter$ = _this$adminParameter.sp,
          cate_car_name = _this$adminParameter$.cate_car_name,
          cate_room_name = _this$adminParameter$.cate_room_name,
          _this$adminParameter$2 = _this$adminParameter.list,
          cate_car = _this$adminParameter$2.cate_car,
          cate_room = _this$adminParameter$2.cate_room;
      this.commonLIst.map(function (item) {
        item.children.map(function (itmes) {
          if (itmes.subTitle) {
            itmes.subTitle = itmes.subTitle.replace(/房源&/g, cate_room_name).replace(/车位&/g, cate_car_name);
          }

          itmes.title = itmes.title.replace(/房源&/g, cate_room_name).replace(/车位&/g, cate_car_name);
        });
      });
      return this.commonLIst;
    }
  }),
  data: function data() {
    return {
      commontName: '',
      tabsValue: '时间组件',
      commonLIst: confing.list,
      containerList: [],
      radio: '',
      selectDialog: false
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['ADD_COMMONT'])), {}, {
    // 添加组件
    adds: function adds() {
      var _this = this;

      var isIndex = this.commontList.findIndex(function (item) {
        return item.title == _this.radio;
      });
      var subIS = this.commontList[isIndex].children.find(function (item) {
        return item.title == _this.sub.title;
      });

      if (subIS) {
        this.$message.error('该组件已存在');
      } else {
        this.ADD_COMMONT({
          data: this.sub,
          isAmount: true,
          parentIndex: isIndex
        });
        this.$message.success('添加成功');
        this.selectDialog = false;
      }
    },
    addCommont: function addCommont(parent, sub) {
      var is = this.commontList.find(function (item) {
        return item.title == sub.title;
      });

      if (is) {
        console.log(this.commontList, is, sub.title);
        this.$message.error('该组件已存在');
        return;
      }

      if (parent.type == 'amount') {
        //容器组件需特殊处理
        if (!sub.type) {
          var _is = this.commontList.filter(function (item) {
            return item.title.includes('指标容器');
          });

          if (!_is.length) {
            this.$message.error('请先添加指标容器');
            return;
          }

          if (_is.length == 1) {
            //只有一个容器
            var subIS = _is[0].children.find(function (item) {
              return item.title == sub.title;
            });

            if (subIS) {
              this.$message.error('该组件已存在');
            } else {
              this.ADD_COMMONT({
                data: sub,
                isAmount: true
              });
              this.$message.success('添加成功');
            }
          } else {
            //有多个容器
            this.containerList = _is;
            this.selectDialog = true;
            this.sub = sub;
          }
        } else {
          //添加容器
          var _add = {
            title: sub.title,
            subTitle: sub.subTitle,
            type: parent.type,
            type2: sub.type,
            value: sub.value || '',
            children: []
          };
          this.ADD_COMMONT({
            data: _add,
            isAmount: false
          });
        }

        return;
      }

      var add = {
        lineBreaTitle: sub.lineBreaTitle,
        lineBreak: sub.lineBreak,
        title: sub.title,
        subTitle: sub.subTitle,
        type: parent.type,
        type2: sub.type,
        value: sub.value || '',
        children: []
      };
      this.ADD_COMMONT({
        data: add,
        isAmount: false
      });
      this.$message.success('添加成功');
    },
    searchbtn: function searchbtn() {
      var _this2 = this;

      var list = JSON.parse(JSON.stringify(confing.list));

      if (!this.commontName) {
        this.commonLIst = list;
        return;
      }

      list.filter(function (item) {
        item.children = item.children.filter(function (itmes) {
          if (itmes.title == _this2.commontName || itmes.title.indexOf(_this2.commontName) != -1) {
            _this2.tabsValue = item.title;
            return itmes;
          }
        });
      });
      this.commonLIst = list;
    },
    reset: function reset() {
      //重置
      this.commontName = '';
      this.searchbtn();
      this.tabsValue = '时间组件';
    },
    tabClk: function tabClk() {// this.commonLIst = confing.list
    }
  })
};