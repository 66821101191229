var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "div",
        { ref: "apptop", staticClass: "page-header-wrapper" },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.search } },
            [
              _c("div", { staticClass: "page-header" }, [
                _c(
                  "div",
                  { staticClass: "page-header-left" },
                  [
                    _c("event-select", {
                      attrs: { "event-list": _vm.eventList },
                      on: { select_round: _vm.selectRoundHandle },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "page-header-right" }, [
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.is_radio
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "建筑类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { "max-width": "80px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleCate },
                                  model: {
                                    value: _vm.search.room_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "room_type", $$v)
                                    },
                                    expression: "search.room_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "房源", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "车位", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-input", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "order/selall"],
                            expression: "['admin', 'order/selall']",
                          },
                        ],
                        staticClass: "input-flex",
                        staticStyle: {
                          "margin-left": "10px",
                          "max-width": "230px",
                        },
                        attrs: {
                          placeholder:
                            "姓名\\手机\\订单号\\" +
                            _vm.$adminList.user_code_name +
                            (_vm.search.room_type == 2
                              ? "\\" +
                                _vm.customSetting.transaction_records.house_num
                              : "\\房号") +
                            (_vm.matching_order ? "\\配比编号" : ""),
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.search.no,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "no", $$v)
                          },
                          expression: "search.no",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "operate mL10" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "greens-btn",
                              attrs: { size: "small" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btnHollowReset",
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more",
                              on: { click: _vm.bindMore },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.moreText) + " "),
                              _c("i", { class: _vm.moreIcon }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.is_more
                ? _c(
                    "el-row",
                    { staticClass: "is_more", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.search.handled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "handled", $$v)
                                    },
                                    expression: "search.handled",
                                  },
                                },
                                _vm._l(_vm.orderStatusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.value.toString(),
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.is_price
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "定金" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.pay_status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "pay_status",
                                            $$v
                                          )
                                        },
                                        expression: "search.pay_status",
                                      },
                                    },
                                    [
                                      _vm.adminList.price_type === 1
                                        ? _vm._l(
                                            _vm.onLinePay,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          )
                                        : _vm._l(
                                            _vm.offlinePay,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.sign_up
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "打印认购书" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.is_printing,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "is_printing",
                                            $$v
                                          )
                                        },
                                        expression: "search.is_printing",
                                      },
                                    },
                                    _vm._l(_vm.printing, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.sign_up
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "签约" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.sign_status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "sign_status",
                                            $$v
                                          )
                                        },
                                        expression: "search.sign_status",
                                      },
                                    },
                                    _vm._l(_vm.onLine, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showSignConfirm
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.list.pin_ren_gou) +
                                        "确认 "
                                    ),
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.sign_confirm,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "sign_confirm",
                                            $$v
                                          )
                                        },
                                        expression: "search.sign_confirm",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: 0 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "已确认", value: 1 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "待确认", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.help === 1
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "核控" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.help_status,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "help_status",
                                            $$v
                                          )
                                        },
                                        expression: "search.help_status",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: 0 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "待审核", value: 1 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "已通过", value: 2 },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "已驳回", value: -2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.adminEvent.is_batch
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户批次" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.num,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "num", $$v)
                                        },
                                        expression: "search.num",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: 0 },
                                      }),
                                      _vm._l(
                                        _vm.activity.batch,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.num,
                                              value: item.num,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c("operator-user", {
                            attrs: { type: "form-item" },
                            model: {
                              value: _vm.search.admin_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "admin_id", $$v)
                              },
                              expression: "search.admin_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.sp.pin_you_hui
                        ? _c(
                            "el-col",
                            { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "优惠类型" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.search.you_hui_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.search,
                                            "you_hui_id",
                                            $$v
                                          )
                                        },
                                        expression: "search.you_hui_id",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: 0 },
                                      }),
                                      _vm._l(
                                        _vm.you_hui_rule,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.type_name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产权人身份证" } },
                            [
                              _c("el-input", {
                                staticClass: "input-flex",
                                attrs: {
                                  placeholder: "身份证号后6位或全部",
                                  size: "small",
                                },
                                model: {
                                  value: _vm.perSearch.tel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.perSearch, "tel", $$v)
                                  },
                                  expression: "perSearch.tel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "condition-filter mT10" },
        [
          _c("id-card-drive"),
          _c(
            "div",
            [
              _c(
                "el-tooltip",
                { attrs: { content: "将开盘订单数据传递至建发系统" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "order/sync"],
                          expression: "['admin', 'order/sync']",
                        },
                      ],
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: { click: _vm.syncOrder },
                    },
                    [_vm._v(" 传递订单数据 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "order/export"],
                      expression: "['admin', 'order/export']",
                    },
                  ],
                  staticClass: "btnHollowGreen",
                  attrs: { size: "small" },
                  on: { click: _vm.exportHistory },
                },
                [_vm._v("导出数据 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "table" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.tableData,
                    "header-cell-style": { background: "#fafafa" },
                    height: _vm.tableHeight,
                    border: "",
                    "element-loading-background": "rgba(0, 0, 0, 0.5)",
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-text": "数据正在加载中",
                    "highlight-current-row": "",
                    size: "medium",
                  },
                  on: { "sort-change": _vm.sortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单号",
                      prop: "order_sn",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.order_sn))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.matching_order !== 0
                    ? _c("el-table-column", {
                        key: "matching_sn",
                        attrs: {
                          sortable: "custom",
                          align: "center",
                          label: "配比编号",
                          prop: "matching_sn",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      sortable: "custom",
                      label:
                        _vm.search.room_type == 2
                          ? _vm.customSetting.transaction_records.house_num
                          : _vm.search.room_type === 1
                          ? "房号"
                          : "房号&" +
                            _vm.customSetting.transaction_records.house_num,
                      align: "center",
                      prop: "room",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "btn-text",
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toRoomDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.room))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "类型", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            scoped.row.cate === 1
                              ? _c("span", [_vm._v("房源")])
                              : scoped.row.cate === 2
                              ? _c("span", [_vm._v("车位")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "客户姓名",
                      prop: "name",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "btn-text",
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toUserDeatil(scope.row.user_id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.name))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "登录手机号",
                      prop: "tel",
                      width: "110",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.$adminList.user_code_name,
                      prop: "code",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "下单时间",
                      "min-width": "150",
                      prop: "created_at",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scop) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(scop.row.created_at * 1000)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.showSignConfirm
                    ? _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            prop: "next_person_user",
                            width: "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.sign_img_time > 0
                                            ? "已确认"
                                            : "待确认"
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2978841412
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _vm._v(
                              " " + _vm._s(_vm.list.pin_ren_gou) + "确认 "
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.help === 1
                    ? _c("el-table-column", {
                        key: "help_name",
                        attrs: { align: "center", label: "核控状态" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        content:
                                          "核控人: " + scope.row.help_name,
                                        effect: "dark",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      scope.row.help_status === 1
                                        ? _c("span", [_vm._v("待审核")])
                                        : _vm._e(),
                                      scope.row.help_status === 2
                                        ? _c("span", [_vm._v("审核通过")])
                                        : _vm._e(),
                                      scope.row.help_status === -2
                                        ? _c("span", [_vm._v("审核驳回")])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3103868268
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    key: "expires_type",
                    attrs: {
                      prop: "expires_type",
                      align: "center",
                      label: "订单状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            scoped.row.handled == -1
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      content:
                                        "失效类型：" +
                                        _vm.getTetx(scoped.row.expires_type),
                                      effect: "dark",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getStatus(scoped.row.handled)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getStatus(scoped.row.handled))
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.is_price
                    ? _c("el-table-column", {
                        key: "pay_handled",
                        attrs: { align: "center", label: "定金状态" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scoped) {
                                return [
                                  scoped.row.pay_handled === 0
                                    ? _c("span", [_vm._v("未交")])
                                    : scoped.row.pay_handled === 1
                                    ? _c("span", [_vm._v("已交")])
                                    : scoped.row.pay_handled === 2
                                    ? _c("span", [_vm._v("已交部分")])
                                    : scoped.row.pay_handled === -1
                                    ? _c("span", [_vm._v("已退款")])
                                    : _c("span", [_vm._v("未交")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1879849154
                        ),
                      })
                    : _vm._e(),
                  _vm.sign_up && _vm.adminList.sign_up_type !== 1
                    ? _c("el-table-column", {
                        key: "is_printing",
                        attrs: { label: "打印认购书", width: "90" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scoped) {
                                return [
                                  scoped.row.is_printing === 1
                                    ? _c("span", [_vm._v("未打印")])
                                    : scoped.row.is_printing === 2
                                    ? _c("span", [_vm._v("已打印")])
                                    : _c("span", [_vm._v("未打印")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1018993949
                        ),
                      })
                    : _vm._e(),
                  _vm.sign_up
                    ? _c("el-table-column", {
                        key: "sign_status",
                        attrs: { align: "center", label: "签约状态" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scoped) {
                                return [
                                  scoped.row.sign_status === -1
                                    ? _c("span", [_vm._v("已作废")])
                                    : scoped.row.sign_status === 0
                                    ? _c("span", [_vm._v("待签约")])
                                    : scoped.row.sign_status === 2
                                    ? _c("span", [_vm._v("签约完毕")])
                                    : scoped.row.sign_status === 1
                                    ? _c("span", [_vm._v("签约中")])
                                    : _c("span", [_vm._v("待签约")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          593103333
                        ),
                      })
                    : _vm._e(),
                  _vm.sp.pin_you_hui
                    ? _c("el-table-column", {
                        key: "yh_type_name",
                        attrs: {
                          align: "center",
                          label: "优惠类型",
                          width: "130",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scoped) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scoped.row.yh_type_name)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2066730472
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      label: "操作",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "order/release"],
                                        expression:
                                          "['admin', 'order/release']",
                                      },
                                    ],
                                    attrs: { span: 4 },
                                  },
                                  [
                                    scope.row.handled != -1 &&
                                    scope.row.handled != 1 &&
                                    scope.row.handled != 3
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: "取消订单",
                                              effect: "dark",
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "no-room",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.releaseHouseRes(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticStyle: { opacity: "0" } },
                                          [_vm._v("11")]
                                        ),
                                  ],
                                  1
                                ),
                                _vm.adminList.sign_up_type !== 1
                                  ? _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "order/printing"],
                                            expression:
                                              "['admin', 'order/printing']",
                                          },
                                        ],
                                        attrs: { span: 4 },
                                      },
                                      [
                                        scope.row.handled !== -1 &&
                                        ((_vm.matching_order &&
                                          scope.row.mo_handled === 1) ||
                                          !_vm.matching_order)
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  content: "打印认购书",
                                                  effect: "dark",
                                                  placement: "top-start",
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "class-name": "greens",
                                                    "icon-class": "printer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.printOrder(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "order/deposit"],
                                        expression:
                                          "['admin', 'order/deposit']",
                                      },
                                    ],
                                    attrs: { span: 4 },
                                  },
                                  [
                                    scope.row.handled !== -1 &&
                                    _vm.is_price &&
                                    _vm.adminList.price_type == 2 &&
                                    scope.row.pay_handled !== 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: "定金确认",
                                              effect: "dark",
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "SignIn",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDeposit(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticStyle: { opacity: "0" } },
                                          [_vm._v("11")]
                                        ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "order/sign"],
                                        expression: "['admin', 'order/sign']",
                                      },
                                    ],
                                    attrs: { span: 4 },
                                  },
                                  [
                                    scope.row.handled !== -1 &&
                                    scope.row.sign_status !== 2 &&
                                    ((_vm.matching_order &&
                                      scope.row.mo_handled === 1) ||
                                      !_vm.matching_order) &&
                                    (scope.row.pay_handled === 1 ||
                                      scope.row.pay_handled === 2) &&
                                    _vm.sign_up &&
                                    _vm.adminList.sign_up_type !== 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: "签约确认",
                                              effect: "dark",
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "SignUp",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.signContract(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticStyle: { opacity: "0" } },
                                          [_vm._v("11")]
                                        ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "order/detail"],
                                        expression: "['admin', 'order/detail']",
                                      },
                                    ],
                                    attrs: { span: 4 },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "详情",
                                          effect: "dark",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "info",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewDetails(scope.row)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.adminList.sign_up_type === 1
                                  ? _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "order/appsignup"],
                                            expression:
                                              "['admin', 'order/appsignup']",
                                          },
                                        ],
                                        attrs: { span: 4 },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "模拟签约",
                                              effect: "dark",
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "SignUpGray",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.SignSubmit(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              layout: _vm.layouts,
              "page-size": _vm.pageSize,
              "page-sizes": _vm.sizes,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "签约确认",
            width: "400px",
            top: "40vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialogVisibleText))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMoreHouse,
                      expression: "isMoreHouse",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.sureAll },
                },
                [_vm._v(_vm._s(_vm.btnTextOne))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.sureCurrent },
                },
                [_vm._v(_vm._s(_vm.btnTextTwo))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "user-list-container",
          attrs: {
            width: "800px",
            top: "10vh",
            visible: _vm.dialogConfig.visible1,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible1", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "user-list-popup" }, [
            _c("div", { staticClass: "popup-hander" }, [
              _c("div", { staticClass: "search" }, [
                _c("img", {
                  staticClass: "search_icon",
                  attrs: { src: require("../../assets/image/suc.png") },
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v("找到" + _vm._s(_vm.userList.length || 0) + "个客户"),
                ]),
              ]),
              _c("div", { staticClass: "checke-user-number" }, [
                _vm._v("已选" + _vm._s(_vm.ckeckNumber) + "个"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "popup-container" },
              _vm._l(_vm.userList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "popup-content-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectUser(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "checked",
                          {
                            "current-checked": item.checked,
                            checked_disabled: !item.succeed,
                          },
                        ],
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.checked,
                              expression: "item.checked",
                            },
                          ],
                          attrs: {
                            src: require("../../assets/image/check.png"),
                            alt: "勾选按钮",
                          },
                        }),
                      ]
                    ),
                    item.tips
                      ? _c("div", { staticClass: "signet" }, [
                          item.succeed
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_green.png"),
                                },
                              })
                            : item.error
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_red.png"),
                                },
                              })
                            : item.repetition
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_yellow.png"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "signet_tips",
                              style: {
                                color: item.succeed
                                  ? "#22A271"
                                  : item.error
                                  ? "#FF3131"
                                  : "#FFBF00",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.tips) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("div", { staticClass: "user-info-top" }, [
                        _c("div", { staticClass: "avatar-container" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: require("../../assets/image/avatar_icon.png"),
                            },
                          }),
                        ]),
                        _c("div", [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            item.rand_name
                              ? _c("span", { staticClass: "rend_tag" }, [
                                  _vm._v(_vm._s(item.rand_name)),
                                ])
                              : _vm._e(),
                          ]),
                          item.code
                            ? _c("div", { staticClass: "user-code" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) +
                                    "：" +
                                    _vm._s(item.code)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-info-bottom" },
                        [
                          _c("span", [
                            _vm._v(" 手机号：" + _vm._s(_vm.iphone(item.tel))),
                          ]),
                          item.admin_name
                            ? _c("div", [
                                _vm._v(
                                  "置业顾问：" +
                                    _vm._s(item.admin_name) +
                                    " " +
                                    _vm._s(
                                      item.t_name ? "(" + item.t_name + ")" : ""
                                    )
                                ),
                              ])
                            : _vm._e(),
                          item.uao_list
                            ? _vm._l(item.uao_list, function (i, j) {
                                return _c("div", { key: j }, [
                                  _vm._v(
                                    "产权人" +
                                      _vm._s(j + 1) +
                                      "：" +
                                      _vm._s(i.name) +
                                      " " +
                                      _vm._s(
                                        i.card
                                          ? "(" + _vm.card(i.card) + ")"
                                          : ""
                                      )
                                  ),
                                ])
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "popup-footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "definite_entry",
                    attrs: { type: "primary", disabled: _vm.ckeckNumber == 0 },
                    on: { click: _vm.dialogConfirm },
                  },
                  [_vm._v("确定 ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "user-error-container",
          attrs: {
            width: "1205px",
            top: "10vh",
            visible: _vm.dialogConfig.visible2,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible2", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "popup-err-container" }, [
            _c("img", {
              staticClass: "error_bg",
              attrs: { src: require("../../assets/image/sign_in_err.png") },
            }),
            _c("div", { staticClass: "popup-top" }, [
              _c("img", {
                staticClass: "error_icon",
                attrs: { src: require("../../assets/image/Sigh.png") },
              }),
              _c("span", [_vm._v("未找到客户")]),
            ]),
            _c("div", { staticClass: "hint" }, [
              _vm._v("该该客户无订单，请确认客户是否已认购！"),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialoadfjaskdfj",
          attrs: {
            title: _vm.partDeposit_dialog.title,
            visible: _vm.partDeposit_dialog.show,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.partDeposit_dialog, "show", $event)
            },
          },
          scopedSlots: _vm._u(
            [
              !_vm.partDeposit_dialog.html
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "dialog-footer-btn mR20",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeposit(null, "close")
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "greens-btn",
                                staticStyle: { height: "35px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeposit(
                                      null,
                                      "partDeposit_submit"
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 确认 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.partDeposit_dialog.html
            ? _c("div", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.partDeposit_dialog.html) },
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "margin-top": "25px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "dialog-footer-btn",
                        on: {
                          click: function ($event) {
                            return _vm.onDeposit(null, "close")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowGreen",
                        staticStyle: { height: "35px" },
                        on: {
                          click: function ($event) {
                            return _vm.onDeposit(null, "partDeposit_dialog")
                          },
                        },
                      },
                      [_vm._v("部分收款 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "greens-btn",
                        staticStyle: { height: "35px" },
                        on: {
                          click: function ($event) {
                            return _vm.onDeposit(null, "allDeposit")
                          },
                        },
                      },
                      [_vm._v("全额收款 ")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "el-form",
                { attrs: { model: { value: _vm.partDeposit_dialog.value } } },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _vm._v(
                        " 已收：" +
                          _vm._s(
                            _vm.partDeposit_dialog.allPrice -
                              _vm.partDeposit_dialog.maxPrice
                          )
                      ),
                      _c("span", {
                        staticStyle: { width: "20px", display: "flex" },
                      }),
                      _vm._v(
                        " 待收：" +
                          _vm._s(_vm.partDeposit_dialog.maxPrice) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "value", label: "本次实收金额" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入金额",
                          min: 0,
                          max: _vm.partDeposit_dialog.maxPrice,
                        },
                        model: {
                          value: _vm.partDeposit_dialog.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.partDeposit_dialog, "value", $$v)
                          },
                          expression: "partDeposit_dialog.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("vidicon-player", {
        attrs: { duration: _vm.adminList.print_video_min * 60 },
        on: { uploadSuccess: _vm.uploadSuccess },
        model: {
          value: _vm.showVideo,
          callback: function ($$v) {
            _vm.showVideo = $$v
          },
          expression: "showVideo",
        },
      }),
      _c(
        "printingInfoDialog",
        {
          attrs: {
            selectRoomInfo: _vm.roomInfo,
            title: "请确认付款方式",
            visible: _vm.prinvisible,
            payInfo: _vm.pay_info,
            orderMoney: _vm.orderMoney,
            subscriptionDate: _vm.subscriptionDate,
          },
          on: { close: _vm.close, save: _vm.save },
        },
        [
          _c(
            "template",
            { slot: "formList" },
            [
              _vm.help_name
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { label: "核控人:" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "", disabled: "" },
                        model: {
                          value: _vm.help_name,
                          callback: function ($$v) {
                            _vm.help_name = $$v
                          },
                          expression: "help_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }