var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview-box" }, [
    _c("div", { staticClass: "phone-top" }, [
      _vm.data.room_info_room == "1"
        ? _c(
            "div",
            { staticClass: "name" },
            [
              _vm._v(" 6栋-1单元-10层-1004 "),
              _vm.data.room_info_gift == "1"
                ? [
                    _c("span", { staticClass: "title-btn" }, [
                      _vm._v("有" + _vm._s(_vm.data.pin_fu_zen)),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "button-box" },
        [
          _vm.data.room_info_tag == "1"
            ? [_c("span", [_vm._v("A户型")])]
            : _vm._e(),
          _vm.data.room_info_hot == "1"
            ? [
                _c("div", { staticClass: "mL10" }, [_vm._v("综合热度：")]),
                _vm._m(0),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm.data.room_info_price == "1"
      ? _c("div", { staticClass: "phone-list" }, [
          _c(
            "div",
            { staticClass: "padding" },
            [
              _c("div", { staticClass: "phone-list-name" }, [_vm._v("价格")]),
              _vm.data.room_info_price_type == 1
                ? [_vm._m(1), _vm._m(2)]
                : _vm._e(),
              _vm.data.room_info_price_type == 2
                ? [_vm._m(3), _vm._m(4), _vm._m(5), _vm._m(6)]
                : _vm._e(),
              _vm.data.room_info_price_type == 3
                ? [_vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10)]
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.data.room_info_area == "1"
      ? _c("div", { staticClass: "phone-list" }, [_vm._m(11)])
      : _vm._e(),
    _vm.data.room_info_gift == "1"
      ? _c("div", { staticClass: "phone-list" }, [
          _c("div", { staticClass: "padding" }, [
            _c("div", { staticClass: "phone-list-name" }, [
              _vm._v(_vm._s(_vm.data.pin_fu_zen) + "信息"),
            ]),
            _c("div", { staticClass: "phone-list-content" }, [
              _c("div", { staticClass: "block" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.data.pin_fu_zen_product || "产品") + ":"),
                ]),
                _c("span", { staticClass: "text" }, [
                  _vm._v("一套****迷你车位，走过路过莫错过"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "phone-list-content" }, [
              _c("div", { staticClass: "block" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.data.pin_fu_zen_price || "价值") + ":"),
                ]),
                _c("span", { staticClass: "text" }, [_vm._v("¥32,253.00")]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.data.room_info_desc == "1"
      ? _c("div", { staticClass: "phone-list" }, [
          _c("div", { staticClass: "phone-list-name" }, [_vm._v("描述")]),
          _vm._m(12),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "flex ju-center" }, [
        _c("div", { staticClass: "progress-box" }, [
          _c("div", { staticClass: "progress" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("总价:")]),
        _c("span", { staticClass: "text" }, [_vm._v("124.23㎡")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("单价:")]),
        _c("span", { staticClass: "text" }, [_vm._v("124.23㎡")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥123,120.00/㎡")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [_c("span", [_vm._v("总价:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content ml0" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("优惠价:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥11,236,253.00")]),
      ]),
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("原价:")]),
        _c("span", { staticClass: "tips-error" }, [_vm._v("¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [_c("span", [_vm._v("单价:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content ml0" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("优惠价:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥123,120.00/㎡")]),
      ]),
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("原价:")]),
        _c("span", { staticClass: "tips-error" }, [_vm._v("¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("总价:")]),
        _c("span", { staticClass: "tips" }, [_vm._v("标准价：¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content ml0" }, [
      _c("div", { staticClass: "block" }, [
        _c("p", [_vm._v("一次性:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥32,236,253.00")]),
      ]),
      _c("div", { staticClass: "block" }, [
        _c("p", [_vm._v("按揭:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [_vm._v("单价:")]),
        _c("span", { staticClass: "tips" }, [_vm._v("标准价：¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content ml0" }, [
      _c("div", { staticClass: "block" }, [
        _c("p", [_vm._v("一次性:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥123,120.00/㎡")]),
      ]),
      _c("div", { staticClass: "block" }, [
        _c("p", [_vm._v("按揭:")]),
        _c("span", { staticClass: "num" }, [_vm._v("¥11,236,253.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "padding" }, [
      _c("div", { staticClass: "phone-list-name" }, [_vm._v("面积")]),
      _c("div", { staticClass: "phone-list-content" }, [
        _c("div", { staticClass: "block" }, [
          _c("span", [_vm._v("建面:")]),
          _c("span", { staticClass: "text" }, [_vm._v("124.23㎡")]),
        ]),
        _c("div", { staticClass: "block" }, [
          _c("span", [_vm._v("套面:")]),
          _c("span", { staticClass: "text" }, [_vm._v("124.23㎡")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "phone-list-content" }, [
      _c("div", { staticClass: "block" }, [
        _c("span", [
          _vm._v(
            "你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！你不知道的事，这房子贼棒！"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }