var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-item-container" }, [
    _c(
      "div",
      {
        staticClass: "cell-item-title",
        style: { width: _vm.cellItemWidth, textAlign: _vm.align },
      },
      [_vm._t("title", [_vm._v(" " + _vm._s(_vm.title))])],
      2
    ),
    _c(
      "div",
      { staticClass: "cell-item-value", on: { click: _vm.clickValue } },
      [_vm._t("default", [_vm._v(_vm._s(_vm.value))])],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }