var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-format" },
    [
      _vm.rand.is_sub_screen === 1 && _vm.options.type == "round"
        ? [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "主屏控制",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "setting-control-icon",
                    staticStyle: { right: "200px" },
                    on: {
                      click: function ($event) {
                        _vm.isOpenDrawer = true
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-s-platform",
                      staticStyle: { "font-size": "30px", color: "white" },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "副屏控制",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "setting-control-icon",
                    on: {
                      click: function ($event) {
                        return _vm.onSub_screenOper("drawer")
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "class-name": "setting-control",
                        "icon-class": "second_screen",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "主屏控制",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "setting-control-icon",
                    on: {
                      click: function ($event) {
                        _vm.isOpenDrawer = true
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-s-platform",
                      staticStyle: { "font-size": "30px", color: "white" },
                    }),
                  ]
                ),
              ]
            ),
          ],
      _c("div", { staticClass: "edit-format-bg" }, [
        _c("img", { attrs: { src: _vm.imgUrl + _vm.editFormatList.logo } }),
      ]),
      _c(
        "div",
        { staticClass: "edit-format-content", style: _vm.mainStyle() },
        [
          _c("div", { staticClass: "edit-format-wrapper" }, [
            _c(
              "div",
              { ref: "topHeight" },
              [
                _vm.editFormatList.name_show === 0
                  ? _c("project-title", {
                      attrs: {
                        "p-color": _vm.editFormatList.name_color,
                        "p-font": _vm.editFormatList.name_size,
                        "p-position": _vm.editFormatList.name_position,
                        innertext: "项目名称",
                      },
                    })
                  : _vm._e(),
                _vm.editFormatList.round_show == 0
                  ? _c("project-rounds", {
                      attrs: {
                        "p-color": _vm.editFormatList.round_color,
                        "p-font": _vm.editFormatList.round_size,
                        "p-position": _vm.editFormatList.round_position,
                        innertext: "第X轮",
                        "lave-number": _vm.itemNum,
                        "is-stay-user": _vm.is_stay_user,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-format-item" },
              [
                _c(
                  "project-content",
                  {
                    attrs: {
                      "s-column": 0,
                      "s-position": _vm.editFormatList.card_position,
                      "s-row-num": _vm.editFormatList.card_row_num,
                      "content-height": _vm.editFormatList.content_height,
                      "top-height": _vm.tbHeight,
                      "s-margin": _vm.editFormatList.card_margin,
                      "s-left-margin": _vm.editFormatList.card_info_left_margin,
                      "s-top-margin": _vm.editFormatList.card_info_top_margin,
                    },
                  },
                  _vm._l(_vm.itemNum, function (item, i) {
                    return _c(
                      "item-box",
                      {
                        key: i,
                        attrs: {
                          "is-show-num": _vm.rand.show_rand_code,
                          num: _vm.getSequenceNumber(i + 1),
                        },
                      },
                      [
                        _vm.rand.show_name
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json.name_width +
                                    "%",
                                },
                              },
                              [_vm._v("姓名")]
                            )
                          : _vm._e(),
                        _vm.rand.show_tel
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json.tel_width +
                                    "%",
                                },
                              },
                              [_vm._v("12345678900")]
                            )
                          : _vm._e(),
                        _vm.rand.show_audit_order
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json.code_width +
                                    "%",
                                },
                              },
                              [_vm._v("00045")]
                            )
                          : _vm._e(),
                        _vm.rand.show_card == 1
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json.card_width +
                                    "%",
                                },
                              },
                              [_vm._v("5148******1448")]
                            )
                          : _vm._e(),
                        _vm.rand.show_card == 2
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json.card_width +
                                    "%",
                                },
                              },
                              [_vm._v("258448")]
                            )
                          : _vm._e(),
                        _vm.rand.show_other_remark
                          ? _c(
                              "span",
                              {
                                staticClass: "mes",
                                style: {
                                  width:
                                    _vm.editFormatList.host_json
                                      .other_remark_width + "%",
                                },
                              },
                              [_vm._v("其他信息")]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { ref: "bottomHeight", staticClass: "edit-btn-group" },
              [
                _c(
                  "project-bottom",
                  {
                    attrs: {
                      "is-show-tip":
                        _vm.rand.type === 2 && _vm.rand.is_last_round_show == 0,
                      "s-margin": _vm.editFormatList.card_margin,
                      tipText: _vm.next_round_list,
                    },
                  },
                  [
                    _c("rounds-btn", { attrs: { "btn-text": "上一轮记录" } }),
                    _c("rounds-btn", { attrs: { "btn-text": "停止摇号" } }),
                    _c("rounds-btn", { attrs: { "btn-text": "取消" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            modal: false,
            visible: _vm.isOpenDrawer,
            "with-header": false,
            size: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-body" },
            [
              _c("top-title", { attrs: { title: "主屏整体布局" } }),
              _c("main-setting"),
              _c("top-title", {
                attrs: { "show-r": true },
                on: { resetBeforeImg: _vm.upSuccess },
              }),
              _c("div", { staticClass: "simple-setting" }, [
                _c(
                  "div",
                  [_c("img-up", { on: { upSuccess: _vm.upSuccess } })],
                  1
                ),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("卡片内容模式")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "input-number__style",
                          on: { change: _vm.changeFlex },
                          model: {
                            value:
                              _vm.editFormatList.host_json.card_content_mode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editFormatList.host_json,
                                "card_content_mode",
                                $$v
                              )
                            },
                            expression:
                              "editFormatList.host_json.card_content_mode",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "左右展示", value: "row" },
                          }),
                          _c("el-option", {
                            attrs: { label: "上下展示", value: "column" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting-wrapper" }, [
                  _c("div", { staticClass: "left" }, [_vm._v("单行卡片数量")]),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("el-input-number", {
                        staticClass: "input-number__style",
                        model: {
                          value: _vm.editFormatList.card_row_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.editFormatList, "card_row_num", $$v)
                          },
                          expression: "editFormatList.card_row_num",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("top-title", {
                attrs: {
                  title: "摇号名称",
                  showSwitch: true,
                  show: _vm.editFormatList.name_show,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(_vm.editFormatList, "name_show", $event)
                  },
                },
              }),
              _c("simple-setting"),
              _c("top-title", {
                attrs: {
                  title: "轮次",
                  showSwitch: true,
                  show: _vm.editFormatList.round_show,
                },
                on: {
                  "update:show": function ($event) {
                    return _vm.$set(_vm.editFormatList, "round_show", $event)
                  },
                },
              }),
              _c("round-setting"),
              _c("top-title", { attrs: { title: "卡片" } }),
              _c("simple-card"),
              _c("top-title", { attrs: { title: "卡片内信息" } }),
              _c("complex-card", { attrs: { rand: _vm.rand } }),
              _vm.editFormatList.host_json.card_content_mode === "row"
                ? _c("top-title", { attrs: { title: "左右信息间距" } })
                : _vm._e(),
              _c("complex-info-card", { attrs: { rand: _vm.rand } }),
              _vm.rand.type === 2 && _vm.rand.is_last_round_show == 0
                ? [
                    _c("top-title", { attrs: { title: "滚动条设置" } }),
                    _c("notice-card"),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "save-btn" }, [
                _c(
                  "div",
                  {
                    staticClass: "save-left",
                    on: {
                      click: function ($event) {
                        return _vm.saveOption(0)
                      },
                    },
                  },
                  [_vm._v("重置为默认值")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "save-right",
                    on: {
                      click: function ($event) {
                        return _vm.saveOption(1)
                      },
                    },
                  },
                  [_vm._v("保存当前设置")]
                ),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm.rand.is_sub_screen === 1
        ? _vm._l(_vm.sub_screen.data, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: {
                  border: "1px solid",
                  position: "absolute",
                  "z-index": "99",
                },
                style: _vm.subStyle(index),
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      textAlign: item.title.textAlign,
                      color: item.title.color,
                      fontSize: item.title.fontSize + "px",
                      marginTop: item.title.top + "px",
                    },
                  },
                  [_vm._v(" 第X轮 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "overflow-y-noshow",
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      width: "100%",
                      height: "100%",
                    },
                    style: {
                      width: "calc(100% - " + item.cardPosition.left + "px)",
                      marginLeft: item.cardPosition.left + "px",
                      height:
                        "calc(100% - " +
                        item.title.fontSize * 2 +
                        "px - " +
                        item.title.top +
                        "px - " +
                        item.cardPosition.top +
                        "px)",
                      marginTop: item.cardPosition.top + "px",
                    },
                  },
                  [
                    item.type === "card"
                      ? _vm._l(_vm.itemNum, function (ite, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticStyle: {
                                margin: "10px",
                                "border-radius": "10px",
                                "text-align": "center",
                                color: "white",
                                "line-height": "24px",
                                display: "flex",
                                "flex-wrap": "wrap",
                                "align-content": "center",
                              },
                              style: {
                                width:
                                  "calc(" +
                                  100 / item.row_num +
                                  "% - 20px" +
                                  ")",
                                "background-color": item.background_color,
                              },
                            },
                            [
                              _vm.rand.sub_show_rand_code
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size": item.code.fontSize + "px",
                                        color: item.code.color,
                                        "line-height":
                                          item.code.lineHeight + "px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(i + 1) + " ")]
                                  )
                                : _vm._e(),
                              _vm.rand.sub_show_name
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size": item.name.fontSize + "px",
                                        color: item.name.color,
                                        "line-height":
                                          item.name.lineHeight + "px",
                                      },
                                    },
                                    [_vm._v(" 姓名 ")]
                                  )
                                : _vm._e(),
                              _vm.rand.sub_show_tel
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size": item.tel.fontSize + "px",
                                        color: item.tel.color,
                                        "line-height":
                                          item.tel.lineHeight + "px",
                                      },
                                    },
                                    [_vm._v(" 12345678900 ")]
                                  )
                                : _vm._e(),
                              _vm.rand.sub_show_audit_order
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size":
                                          item.audit_order.fontSize + "px",
                                        color: item.audit_order.color,
                                        "line-height":
                                          item.audit_order.lineHeight + "px",
                                      },
                                    },
                                    [_vm._v(" 00045 ")]
                                  )
                                : _vm._e(),
                              _vm.rand.sub_show_card
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size": item.card.fontSize + "px",
                                        color: item.card.color,
                                        "line-height":
                                          item.card.lineHeight + "px",
                                      },
                                    },
                                    [
                                      _vm.rand.sub_show_card == 1
                                        ? [_vm._v("5148******1448")]
                                        : [_vm._v("258448")],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.rand.sub_show_other_remark
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { width: "100%" },
                                      style: {
                                        "font-size":
                                          item.other_remark.fontSize + "px",
                                        color: item.other_remark.color,
                                        "line-height":
                                          item.other_remark.lineHeight + "px",
                                      },
                                    },
                                    [_vm._v(" 其他信息 ")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        })
                      : item.type === "list"
                      ? _vm._l(item.row_num, function (ite, inde) {
                          return _c(
                            "div",
                            {
                              key: inde,
                              staticStyle: {
                                width: "100%",
                                margin: "10px",
                                padding: "10px 0",
                                height: "calc(100% - 20px)",
                              },
                              style: {
                                width:
                                  "calc(" +
                                  100 / item.row_num +
                                  "% - 20px" +
                                  ")",
                                "background-color": item.background_color,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "nowrap",
                                    width: "100%",
                                  },
                                },
                                [
                                  _vm.rand.sub_show_rand_code
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.code.fontSize + "px",
                                            color: item.code.color,
                                            width: item.code.width + "%",
                                          },
                                        },
                                        [_vm._v(" 顺序号 ")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.sub_show_name
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.name.fontSize + "px",
                                            color: item.name.color,
                                            width: item.name.width + "%",
                                          },
                                        },
                                        [_vm._v(" 姓名 ")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.sub_show_tel
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.tel.fontSize + "px",
                                            color: item.tel.color,
                                            width: item.tel.width + "%",
                                          },
                                        },
                                        [_vm._v(" 手机号 ")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.sub_show_audit_order
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.audit_order.fontSize + "px",
                                            color: item.audit_order.color,
                                            width: item.audit_order.width + "%",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$adminList.user_code_name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.rand.sub_show_card
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.card.fontSize + "px",
                                            color: item.card.color,
                                            width: item.card.width + "%",
                                          },
                                        },
                                        [_vm._v(" 身份证号 ")]
                                      )
                                    : _vm._e(),
                                  _vm.rand.sub_show_other_remark
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          style: {
                                            "font-size":
                                              item.other_remark.fontSize + "px",
                                            color: item.other_remark.color,
                                            width:
                                              item.other_remark.width + "%",
                                          },
                                        },
                                        [_vm._v(" 其他信息 ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-y-noshow",
                                  staticStyle: {
                                    height: "calc(100% - 30px)",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  inde === item.row_num - 1 &&
                                  _vm.itemNum % item.row_num
                                    ? _vm._l(
                                        _vm.itemNum % item.row_num,
                                        function (ite, ind) {
                                          return _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-wrap": "nowrap",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _vm.rand.sub_show_rand_code
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.code.fontSize +
                                                          "px",
                                                        color: item.code.color,
                                                        "line-height":
                                                          item.code.lineHeight +
                                                          "px",
                                                        width:
                                                          item.code.width + "%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(ind + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_name
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.name.fontSize +
                                                          "px",
                                                        color: item.name.color,
                                                        "line-height":
                                                          item.name.lineHeight +
                                                          "px",
                                                        width:
                                                          item.name.width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 姓名 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_tel
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.tel.fontSize +
                                                          "px",
                                                        color: item.tel.color,
                                                        "line-height":
                                                          item.tel.lineHeight +
                                                          "px",
                                                        width:
                                                          item.tel.width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 12345678900 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_audit_order
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.audit_order
                                                            .fontSize + "px",
                                                        color:
                                                          item.audit_order
                                                            .color,
                                                        "line-height":
                                                          item.audit_order
                                                            .lineHeight + "px",
                                                        width:
                                                          item.audit_order
                                                            .width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 00045 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_card
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.card.fontSize +
                                                          "px",
                                                        color: item.card.color,
                                                        "line-height":
                                                          item.card.lineHeight +
                                                          "px",
                                                        width:
                                                          item.card.width + "%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.rand
                                                              .sub_show_card ==
                                                              2
                                                              ? "258448"
                                                              : "5148******1448"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_other_remark
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.other_remark
                                                            .fontSize + "px",
                                                        color:
                                                          item.other_remark
                                                            .color,
                                                        "line-height":
                                                          item.other_remark
                                                            .lineHeight + "px",
                                                        width:
                                                          item.other_remark
                                                            .width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 其他信息 ")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._l(
                                        parseInt(_vm.itemNum / item.row_num),
                                        function (ite, ind) {
                                          return _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-wrap": "nowrap",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _vm.rand.sub_show_rand_code
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.code.fontSize +
                                                          "px",
                                                        color: item.code.color,
                                                        "line-height":
                                                          item.code.lineHeight +
                                                          "px",
                                                        width:
                                                          item.code.width + "%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(ind + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_name
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.name.fontSize +
                                                          "px",
                                                        color: item.name.color,
                                                        "line-height":
                                                          item.name.lineHeight +
                                                          "px",
                                                        width:
                                                          item.name.width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 姓名 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_tel
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.tel.fontSize +
                                                          "px",
                                                        color: item.tel.color,
                                                        "line-height":
                                                          item.tel.lineHeight +
                                                          "px",
                                                        width:
                                                          item.tel.width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 12345678900 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_audit_order
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.audit_order
                                                            .fontSize + "px",
                                                        color:
                                                          item.audit_order
                                                            .color,
                                                        "line-height":
                                                          item.audit_order
                                                            .lineHeight + "px",
                                                        width:
                                                          item.audit_order
                                                            .width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 00045 ")]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_card
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.card.fontSize +
                                                          "px",
                                                        color: item.card.color,
                                                        "line-height":
                                                          item.card.lineHeight +
                                                          "px",
                                                        width:
                                                          item.card.width + "%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.rand
                                                              .sub_show_card ==
                                                              2
                                                              ? "258448"
                                                              : "5148******1448"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.rand.sub_show_other_remark
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "word-break":
                                                          "break-all",
                                                        "text-align": "center",
                                                      },
                                                      style: {
                                                        "font-size":
                                                          item.other_remark
                                                            .fontSize + "px",
                                                        color:
                                                          item.other_remark
                                                            .color,
                                                        "line-height":
                                                          item.other_remark
                                                            .lineHeight + "px",
                                                        width:
                                                          item.other_remark
                                                            .width + "%",
                                                      },
                                                    },
                                                    [_vm._v(" 其他信息 ")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                ],
                                2
                              ),
                            ]
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          })
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            modal: false,
            visible: _vm.sub_screen.show,
            "with-header": false,
            size: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.sub_screen, "show", $event)
            },
          },
        },
        [
          _vm.sub_screen.show
            ? _c(
                "div",
                { staticClass: "drawer-body" },
                [
                  _c("div", { staticClass: "simple-setting" }, [
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass: "left",
                          staticStyle: { "font-weight": "bold" },
                        },
                        [_vm._v("选择副屏")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-number__style",
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.onSub_screenIndexChange },
                              model: {
                                value: _vm.sub_screen.index,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sub_screen, "index", $$v)
                                },
                                expression: "sub_screen.index",
                              },
                            },
                            _vm._l(_vm.sub_screen.data, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: "第" + (index + 1) + "块副屏",
                                  value: index,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "增加一个副屏",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("custom-btn", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { "icon-type": "ADD" },
                                on: {
                                  btnChange: function ($event) {
                                    return _vm.onSub_screenOper("add")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("top-title", {
                    attrs: {
                      title: "第" + (_vm.sub_screen.index + 1) + "块副屏控制",
                    },
                  }),
                  _c("div", { staticClass: "simple-setting" }, [
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("副屏位置-左右"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-number__style",
                              model: {
                                value: _vm.sub_screen.selectData.locationXy,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData,
                                    "locationXy",
                                    $$v
                                  )
                                },
                                expression: "sub_screen.selectData.locationXy",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "左方", value: "left" },
                              }),
                              _c("el-option", {
                                attrs: { label: "右方", value: "right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("副屏位置-上下"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-number__style",
                              model: {
                                value: _vm.sub_screen.selectData.locationTb,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData,
                                    "locationTb",
                                    $$v
                                  )
                                },
                                expression: "sub_screen.selectData.locationTb",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "上方", value: "top" },
                              }),
                              _c("el-option", {
                                attrs: { label: "下方", value: "bottom" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("横向坐标")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.x,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_screen.selectData, "x", $$v)
                              },
                              expression: "sub_screen.selectData.x",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("纵向坐标")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.y,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_screen.selectData, "y", $$v)
                              },
                              expression: "sub_screen.selectData.y",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("宽度缩放")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.width,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData,
                                  "width",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.width",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("高度缩放")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.height,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData,
                                  "height",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.height",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("展示轮次")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-number__style",
                              attrs: {
                                filterable: "",
                                "collapse-tags": "",
                                multiple: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.sub_screen.selectData.rounds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData,
                                    "rounds",
                                    $$v
                                  )
                                },
                                expression: "sub_screen.selectData.rounds",
                              },
                            },
                            _vm._l(100, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: "N-" + item + "轮",
                                  value: item,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("展示方式")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.changeShowModel },
                              model: {
                                value: _vm.sub_screen.selectData.type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData,
                                    "type",
                                    $$v
                                  )
                                },
                                expression: "sub_screen.selectData.type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "list" } }, [
                                _vm._v("列表"),
                              ]),
                              _c("el-radio", { attrs: { label: "card" } }, [
                                _vm._v("卡片"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("单行数量")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            attrs: {
                              disabled:
                                _vm.sub_screen.selectData.type === "list",
                            },
                            model: {
                              value: _vm.sub_screen.selectData.row_num,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData,
                                  "row_num",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.row_num",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("卡片上边距"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.cardPosition.top,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData.cardPosition,
                                  "top",
                                  $$v
                                )
                              },
                              expression:
                                "sub_screen.selectData.cardPosition.top",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("卡片左边距"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value:
                                _vm.sub_screen.selectData.cardPosition.left,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData.cardPosition,
                                  "left",
                                  $$v
                                )
                              },
                              expression:
                                "sub_screen.selectData.cardPosition.left",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("轮次切换时间(秒)"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.interval,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData,
                                  "interval",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.interval",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("边框颜色")]),
                      _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("el-color-picker", {
                            attrs: { "show-alpha": "" },
                            model: {
                              value: _vm.sub_screen.selectData.borderColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData,
                                  "borderColor",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("top-title", { attrs: { title: "轮次" } }),
                  _c("div", { staticClass: "simple-setting" }, [
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("轮次字号")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            attrs: { min: 12 },
                            model: {
                              value: _vm.sub_screen.selectData.title.fontSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData.title,
                                  "fontSize",
                                  $$v
                                )
                              },
                              expression:
                                "sub_screen.selectData.title.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("轮次颜色")]),
                      _c(
                        "div",
                        {
                          staticClass: "right",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("el-color-picker", {
                            attrs: { "show-alpha": "" },
                            model: {
                              value: _vm.sub_screen.selectData.title.color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData.title,
                                  "color",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.title.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [_vm._v("轮次位置")]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-number__style",
                              model: {
                                value:
                                  _vm.sub_screen.selectData.title.textAlign,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData.title,
                                    "textAlign",
                                    $$v
                                  )
                                },
                                expression:
                                  "sub_screen.selectData.title.textAlign",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "居左", value: "left" },
                              }),
                              _c("el-option", {
                                attrs: { label: "居中", value: "center" },
                              }),
                              _c("el-option", {
                                attrs: { label: "居右", value: "right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "setting-wrapper" }, [
                      _c("div", { staticClass: "left" }, [
                        _vm._v("轮次上边距"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("el-input-number", {
                            staticClass: "input-number__style",
                            model: {
                              value: _vm.sub_screen.selectData.title.top,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sub_screen.selectData.title,
                                  "top",
                                  $$v
                                )
                              },
                              expression: "sub_screen.selectData.title.top",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("top-title", { attrs: { title: "卡片内信息" } }),
                  _c(
                    "div",
                    { staticClass: "simple-setting" },
                    [
                      _c("div", { staticClass: "setting-wrapper" }, [
                        _c("div", { staticClass: "left" }, [
                          _vm._v("卡片背景颜色"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c("el-color-picker", {
                              attrs: { "show-alpha": "" },
                              model: {
                                value:
                                  _vm.sub_screen.selectData.background_color,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_screen.selectData,
                                    "background_color",
                                    $$v
                                  )
                                },
                                expression:
                                  "sub_screen.selectData.background_color",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.rand.sub_show_rand_code
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("序号字号"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.code.fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.code,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.code.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("序号行高"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.code
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.code,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.code.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("序号列宽"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.code.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.code,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.code.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("序号颜色"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.code.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.code,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.code.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.rand.sub_show_name
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("姓名字号"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.name.fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.name,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.name.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("姓名行高"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.name
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.name,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.name.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("姓名列宽"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.name.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.name,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.name.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("姓名颜色"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.name.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.name,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.name.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.rand.sub_show_tel
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("手机号字号"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.tel.fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.tel,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.tel.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("手机号行高"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.tel
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.tel,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.tel.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("手机号列宽"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.tel.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.tel,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.tel.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("手机号颜色"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.tel.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.tel,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.tel.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.rand.sub_show_audit_order
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) + "字号"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.audit_order
                                          .fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.audit_order,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.audit_order.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) + "行高"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.audit_order
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.audit_order,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.audit_order.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) + "列宽"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.audit_order
                                          .width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.audit_order,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.audit_order.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) + "颜色"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.audit_order
                                          .color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.audit_order,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.audit_order.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.rand.sub_show_card
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("身份证字号"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.card.fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.card,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.card.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("身份证行高"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.card
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.card,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.card.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("身份证列宽"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.card.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.card,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.card.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("身份证颜色"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.card.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData.card,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.card.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.rand.sub_show_other_remark
                        ? [
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("其他信息字号"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    attrs: { min: 12 },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.other_remark
                                          .fontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData
                                            .other_remark,
                                          "fontSize",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.other_remark.fontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("其他信息行高"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.other_remark
                                          .lineHeight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData
                                            .other_remark,
                                          "lineHeight",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.other_remark.lineHeight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("其他信息列宽"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "input-number__style",
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.other_remark
                                          .width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData
                                            .other_remark,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.other_remark.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "setting-wrapper" }, [
                              _c("div", { staticClass: "left" }, [
                                _vm._v("其他信息颜色"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { "show-alpha": "" },
                                    model: {
                                      value:
                                        _vm.sub_screen.selectData.other_remark
                                          .color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sub_screen.selectData
                                            .other_remark,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sub_screen.selectData.other_remark.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "save-btn",
                      staticStyle: {
                        position: "absolute",
                        bottom: "20px",
                        width: "360px",
                        "z-index": "10000",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "save-left",
                          on: {
                            click: function ($event) {
                              return _vm.onSub_screenOper("del")
                            },
                          },
                        },
                        [_vm._v("删除当前副屏")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "save-right",
                          on: {
                            click: function ($event) {
                              return _vm.onSub_screenOper("save")
                            },
                          },
                        },
                        [_vm._v("保存所有副屏")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }