import request from '@/utils/request';

var exportAuditStatus0 = function exportAuditStatus0(params) {
  return request({
    url: 'identifyAudit/AuditInfo0',
    method: 'get',
    params: params
  });
}; // 初审详情


var exportAuditStatus1 = function exportAuditStatus1(params) {
  return request({
    url: 'identifyAudit/AuditInfo1',
    method: 'get',
    params: params
  });
}; // 初审详情


var exportAuditStatus2 = function exportAuditStatus2(params) {
  return request({
    url: 'identifyAudit/AuditInfo2',
    method: 'get',
    params: params
  });
}; // 修改表单


var AuditStatusEdit0 = function AuditStatusEdit0(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit0',
    method: 'post',
    data: data
  });
};

var AuditStatusEdit1 = function AuditStatusEdit1(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit1',
    method: 'post',
    data: data
  });
};

var AuditStatusEdit2 = function AuditStatusEdit2(data) {
  return request({
    url: '/identifyAudit/AuditIdeEdit2',
    method: 'post',
    data: data
  });
}; // 报名详情接口


var auditDetail = function auditDetail(params) {
  return request({
    url: 'identifyAudit/auditDetail',
    method: 'get',
    params: params
  });
}; // 认筹
// 升筹详情


var raiauditDetail = function raiauditDetail(params) {
  return request({
    url: 'raisingFundsAudit/auditDetail',
    method: 'get',
    params: params
  });
};

var exportRaiStatus0 = function exportRaiStatus0(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo0',
    method: 'get',
    params: params
  });
}; // 初审详情


var exportRaiStatus1 = function exportRaiStatus1(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo1',
    method: 'get',
    params: params
  });
};

var exportRaiStatus2 = function exportRaiStatus2(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo2',
    method: 'get',
    params: params
  });
}; // 修改表单


var RaiStatusEdit0 = function RaiStatusEdit0(data) {
  return request({
    url: '/raisingFundsAudit/AuditRaiEdit0',
    method: 'post',
    data: data
  });
};

var RaiStatusEdit1 = function RaiStatusEdit1(data) {
  return request({
    url: '/raisingFundsAudit/AuditRaiEdit1',
    method: 'post',
    data: data
  });
};

var RaiStatusEdit2 = function RaiStatusEdit2(data) {
  return request({
    url: '/raisingFundsAudit/AuditRaiEdit2',
    method: 'post',
    data: data
  });
};

export { exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, AuditStatusEdit0, AuditStatusEdit1, AuditStatusEdit2, auditDetail, raiauditDetail, exportRaiStatus1, exportRaiStatus2, RaiStatusEdit0, RaiStatusEdit1, RaiStatusEdit2 };