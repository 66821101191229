var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData && _vm.tableData.length,
            expression: "tableData && tableData.length",
          },
        ],
        staticClass: "page-header",
      },
      [
        _c(
          "div",
          { staticClass: "page-header-left" },
          [
            _c("event-select", {
              attrs: { "event-list": _vm.eventList, is_round: false },
              on: { select_round: _vm.selectRoundHandle },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "block-bottom mT10" },
      _vm._l(_vm.tableData, function (row, i) {
        return _c("list-item", {
          key: i,
          attrs: { row: row },
          on: { handleStartLottery: _vm.handleStartLottery },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }