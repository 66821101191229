import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
import api from '@/api/lotteryEntry';
import { mapGetters } from 'vuex';
export default {
  name: 'LotteryPreset',
  data: function data() {
    return {
      params: {
        page: 1,
        limit: 10,
        search: ''
      },
      loading: false,
      config: {
        count: 20,
        // 总页数
        list: [],
        // 列表
        edition: [],
        round_list: [] // 轮次列表

      },
      round_list: [],
      appHeight: 0,
      topHeight: 0,
      lockingShow: false,
      lockingValue: {
        id: '',
        user_class: ''
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 120;
    }
  },
  created: function created() {
    this.getData(true);
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight; // this.topHeight = this.$refs.apptop.offsetHeight

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight; // this.topHeight = this.$refs.apptop.offsetHeight
    };
  },
  methods: {
    // 获取数据
    getData: function getData(isQuery) {
      var _this2 = this;

      if (isQuery) {
        this.params.limit = 10;
        this.params.page = 1;
      }

      this.loading = true;
      api.editionList(this.params).then(function (result) {
        _this2.config = result.data.data; // 前端实现搜索

        if (_this2.params.search) {
          var list = result.data.data.list || [];
          _this2.config.list = list.filter(function (item) {
            return item.name.indexOf(_this2.params.search) >= 0;
          });
        }
      }).finally(function () {
        _this2.loading = false;
      });
    },
    // 重置表单
    resetForm: function resetForm() {
      this.params = {
        page: 1,
        limit: 10,
        search: ''
      };
      this.getData(true);
    },
    handleSizeChange: function handleSizeChange(s) {
      this.params.limit = s;
      this.getData(false);
    },
    handleCurrentChange: function handleCurrentChange(s) {
      this.params.page = s;
      this.getData(false);
    },
    doTimeToDate: function doTimeToDate(time) {
      return utils.formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    },

    /**
     * 表格数据操作
     * @param key
     * @param row
     */
    onOper: function onOper(key, row) {
      var _this3 = this;

      if (key === 'locking') {
        this.lockingShow = true;
        this.lockingValue = {
          id: row.id,
          user_class: ''
        };
      } // 确定锁定
      else if (key === 'lockingSubmit') {
        if (!this.lockingValue.user_class) {
          this.$message({
            type: 'error',
            message: '请选择类别！'
          });
          return;
        } else if (!this.lockingValue.id) {
          this.$message({
            type: 'error',
            message: '数据错误！'
          });
          this.lockingShow = false;
          return;
        }

        api.locking(this.lockingValue).then(function (res) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });
        }).catch(function (res) {
          _this3.$message({
            type: 'error',
            message: res.data.msg
          });
        }).finally(function (res) {
          _this3.resetForm();

          _this3.lockingShow = false;
        });
      } else {
        var keyText = '';
        if (key === 'eliminate') keyText = '确认清除此版本摇号数据?';else if (key === 'delEdition') keyText = '确认删除此版本及其摇号数据?';else if (key === 'unlock') keyText = '确认解锁此版本?';
        this.$confirm(keyText, '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(function () {
          var postFunction = key === 'delEdition' ? api.delEdition({
            id: row.id
          }) : key === 'eliminate' ? api.eliminate({
            id: row.id
          }) : api.unlock({
            id: row.id
          });
          postFunction.then(function (res) {
            _this3.$message({
              type: 'success',
              message: res.data.msg
            });
          }).catch(function (res) {
            _this3.$message({
              type: 'error',
              message: res.data.msg
            });
          }).finally(function (res) {
            _this3.resetForm();
          });
        });
      }
    }
  }
};