import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getActiveTime } from '@/api/screen'
export default {
  name: 'YunXkAdminCurrenttime',
  props: {
    times: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    color: {
      type: String,
      default: ''
    },
    fontSize: {
      type: Number,
      default: ''
    }
  },
  data: function data() {
    return {
      time: null,
      mountedTime: null,
      currentTime: 0,
      content: {
        year: '0',
        month: '0',
        day: '0',
        hour: '0',
        minute: '0',
        second: '0'
      }
    };
  },
  beforeDestroy: function beforeDestroy() {
    this.setCurrentTime && clearInterval(this.setCurrentTime);
  },
  methods: {
    // 小于10加0
    addZero: function addZero(s) {
      return s < 10 ? '0' + s : s;
    }
  }
};