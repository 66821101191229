//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SubTitle',
  props: {
    title: {
      type: String,
      default: '销售占比'
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
};