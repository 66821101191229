/*
 * @author: Wang,
 * @date: 2021-11-16 11:27:20,
 */
import request from '@/utils/request';
export default {
  getUserList: function getUserList(data) {
    // 搜索用户
    return request({
      url: '/help/Search',
      method: 'post',
      data: data
    });
  },
  startChoose: function startChoose(params) {
    // 带入用户id开始选房
    return request({
      url: '/help/Scan',
      method: 'get',
      params: params
    });
  },
  stopChoose: function stopChoose(params) {
    // 停止选房（后端关闭计时器）
    return request({
      url: '/help/ScanOut',
      method: 'get',
      params: params
    });
  },
  getBuildList: function getBuildList(params) {
    // 获取楼栋列表
    return request({
      url: '/help/BuildIndex',
      method: 'get',
      params: params
    });
  },
  getRoomList: function getRoomList(params) {
    // 获取房间列表
    return request({
      url: '/help/RoomIndex',
      method: 'get',
      params: params
    });
  },
  getUserInfo: function getUserInfo(params) {
    // 获取用户信息
    return request({
      url: '/help/UserInfo',
      method: 'get',
      params: params
    });
  },
  getReserveList: function getReserveList(params) {
    // 获取预留列表
    return request({
      url: '/help/ReserveRooms',
      method: 'get',
      params: params
    });
  },
  getCollectList: function getCollectList(params) {
    // 获取意向单列表
    return request({
      url: '/help/UserCollect',
      method: 'get',
      params: params
    });
  },
  getAdminsList: function getAdminsList(params) {
    // 获取核控人列表
    return request({
      url: '/help/Admins',
      method: 'get',
      params: params
    });
  },
  getReserveStatus: function getReserveStatus(params) {
    // 获取下单预留状态
    return request({
      url: '/help/ReserveUser',
      method: 'get',
      params: params
    });
  },
  getOrderRoomInfo: function getOrderRoomInfo(params) {
    // 获取告罄房源订单信息
    return request({
      url: '/help/OrderRoomInfo',
      method: 'get',
      params: params
    });
  },
  searchRoomList: function searchRoomList(data) {
    // 搜索房源列表
    return request({
      url: '/help/SearchRoom',
      method: 'post',
      data: data
    });
  },
  buyRoom: function buyRoom(data) {
    // 认购房源
    return request({
      url: '/help/OrderRoom',
      method: 'post',
      data: data
    });
  },
  Print: function Print(params) {
    // 认购房源
    return request({
      url: '/help/Print',
      method: 'get',
      params: params
    });
  }
};