export default {
  data: function data() {
    return {
      // 房源状态
      customerStatus: [{
        key: 'login_status',
        title: '登录',
        icon: require('@/assets/image/login-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'participate_status',
        title: '参与情况',
        icon: require('@/assets/image/join-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'sign_in_status',
        title: '签到',
        icon: require('@/assets/image/sign-in-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'rand_batch_status',
        title: '选房摇号',
        icon: require('@/assets/image/to-no-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'order_status',
        title: '选房',
        icon: require('@/assets/image/to-no-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'pay_status',
        title: '交定金',
        icon: require('@/assets/image/deposit-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'row_status',
        title: '签约排号',
        icon: require('@/assets/image/sign-no-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'printing_status',
        title: '打印认购书',
        icon: require('@/assets/image/print-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'sgs_status',
        title: '实名认证',
        icon: require('@/assets/image/sgs-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'contract_status',
        title: '签约',
        icon: require('@/assets/image/sign-no-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'archive_status',
        title: '资料存档',
        icon: require('@/assets/image/archive-icon.svg'),
        isDone: false,
        enable: true
      }]
    };
  }
};