var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notice-bar", on: { click: _vm.tipClick } }, [
    _vm.title != ""
      ? _c("div", { staticClass: "notice-bar__icon" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ])
      : _vm._e(),
    _c("div", { ref: "wrap", staticClass: "notice-bar__wrap" }, [
      _vm.text != ""
        ? _c(
            "div",
            {
              ref: "content",
              staticClass: "notice-bar__content",
              staticStyle: { "font-size": "40px" },
              style: _vm.contentStyle,
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }