import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { adminParameter } from '@/api/public';
import eventSelect from '@/components/eventSelect';
import mixins from '../../assistHouse/mixins/card_socket';
import Api_index from '@/api';
import { getTimingList, TimingCancel, TimingExport } from '@/api/signUser';
import { exportChooseHistory } from '@/api/houseHistory';
import utils from '@/utils/utils';
export default {
  name: 'timing',
  components: {
    eventSelect: eventSelect
  },
  mixins: [mixins],
  data: function data() {
    return {
      eventList: [],
      // 项目活动列表
      // container 高度
      appHeight: '',
      // 顶部高度
      topHeight: '',
      search: {
        search: '',
        status: 1,
        sign_status: 0,
        ids: [],
        start_time: '',
        end_time: ''
      },
      page: {
        limit: 10,
        page: 1,
        total: 0
      },
      tableLoading: false,
      tableData: [],
      // 展开收起图片
      moreIcon: 'el-icon-arrow-down',
      // 是否展开text
      moreText: '展开',
      is_more: false,
      timingLists: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 145;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.apptop.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.apptop.offsetHeight;
    }; // -------接口相关----------


    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    adminParameter().then(function (res) {
      if (res.status === 200) {
        var timing = res.data.timing;
        var timingObj = [],
            timingLists = [];

        if (timing.timing_list && timing.timing_list.length) {
          for (var i = 0; i < timing.timing_list.length; i++) {
            var item = timing.timing_list[i];

            if (!timingObj[item.day]) {
              timingObj[item.day] = {
                value: item.id,
                label: item.day,
                children: []
              };
            }

            timingObj[item.day].children.push({
              value: item.id,
              label: utils.formatDate(new Date(item.start_time * 1000), 'hh:mm') + '至' + utils.formatDate(new Date(item.end_time * 1000), 'hh:mm')
            });
          }
        }

        for (var k in timingObj) {
          timingLists.push(timingObj[k]);
        }

        _this.timingLists = timingLists;
      }
    });
    this.doGetList();
  },
  methods: {
    // 查询
    handleSearch: function handleSearch() {
      this.page.page = 1;
      this.doGetList();
    },
    // 展开& 收起
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 重置
    handleReset: function handleReset() {
      this.page = {
        limit: 10,
        page: 1,
        total: 0
      };
      this.search = {
        search: '',
        status: 1,
        sign_status: 0,
        ids: [],
        start_time: '',
        end_time: ''
      };
      this.doGetList();
    },
    // 获取数据列表
    doGetList: function doGetList() {
      var _this2 = this;

      if (this.search.start_time) {
        this.search.start_time = new Date(this.search.start_time).getTime();
      } else {
        this.search.start_time = 0;
      }

      if (this.search.end_time) {
        this.search.end_time = new Date(this.search.end_time).getTime();
      } else {
        this.search.end_time = 0;
      }

      var params = _objectSpread({
        page: this.page.page,
        limit: this.page.limit
      }, this.search);

      this.tableLoading = true;
      getTimingList(params).then(function (res) {
        if (res.status === 200) {
          _this2.tableData = res.data.list;
          _this2.page.total = res.data.count;
        } else {
          _this2.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      }).finally(function () {
        _this2.tableLoading = false;
      });
    },
    // 数据分页相关
    handleSizeChange: function handleSizeChange(val) {
      this.page.limit = val;
      this.doGetList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page.page = val;
      this.doGetList();
    },
    // 导出
    exportHistory: function exportHistory() {
      TimingExport(_objectSpread({
        page: this.page.page,
        limit: this.page.limit
      }, this.search)).then(function (res) {
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix); // window.location.href = this.host + res.data.path
      });
    },
    onTimingCancel: function onTimingCancel(row) {
      var _this3 = this;

      this.$confirm('确定要取消吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        TimingCancel({
          id: row.id
        }).then(function (res) {
          _this3.$message({
            type: res.status === 200 ? 'success' : 'error',
            message: res.data.msg
          });
        }).catch(function () {
          _this3.tableLoading = false;
        });
      });
    },

    /**
     * 转换时间格式
     * @param time  时间
     * @param format  格式
     */
    doFormatDate: function doFormatDate(time, format) {
      return utils.formatDate(new Date(time), format);
    },
    _indexMethod: function _indexMethod(index) {
      return (this.page.page - 1) * this.page.limit + index + 1;
    }
  }
};