var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container review-details" },
    [
      _c("div", { ref: "topDiv" }, [
        _c("div", { staticClass: "top-fixed" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "title-left flex align-items" }, [
              _c("span", { staticClass: "audit-title" }, [
                _vm._v(_vm._s(_vm.auditInfo.title)),
              ]),
              _vm.auditInfo.status === -3
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("已过期"),
                    ]),
                  ])
                : _vm.auditInfo.status === -2
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("审核驳回"),
                    ]),
                  ])
                : _vm.auditInfo.status === -1
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("已撤销"),
                    ]),
                  ])
                : _vm.auditInfo.status === 0
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type hui" }, [
                      _vm._v("未发起审核"),
                    ]),
                  ])
                : _vm.auditInfo.status === 1
                ? _c("div", { staticClass: "title-type yellow" }, [
                    _c("span", { staticClass: "button-type yellow" }, [
                      _vm._v("待审核"),
                    ]),
                  ])
                : _vm.auditInfo.status === 2
                ? _c("div", { staticClass: "title-type" }, [
                    _c("span", { staticClass: "button-type" }, [
                      _vm._v("审核通过"),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "padding-container",
          style: { height: "calc(100vh - 145px)" },
        },
        [
          _c("div", { staticClass: "block-box" }, [
            _c(
              "div",
              { staticClass: "switch-btn-box" },
              [
                _c("switchBtn", {
                  attrs: {
                    "link-name": this.statusType === 1 ? 0 : 1,
                    "is-show-schedule": _vm.showChangeSchedule,
                  },
                  on: { tabClick: _vm.tabClick },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scroll-box mT15" },
              [
                _c("user-register", {
                  attrs: {
                    "audit-info": _vm.auditInfo,
                    info: _vm.info,
                    "info-tips": _vm.infoTips,
                    "status-info": _vm.statusInfo,
                    type: _vm.index,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "block-bottom-box" }, [
        _c("div", { staticClass: "po" }, [
          _c(
            "div",
            { staticClass: "bottom-btn-group" },
            [
              _c(
                "el-button",
                {
                  staticClass: "pink-btn2-copy pink-btn2",
                  on: {
                    click: function ($event) {
                      return _vm.open_close_drawer(false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "textarea-box",
            "append-to-body": true,
            visible: _vm.isTips,
            center: "",
            title: "驳回理由",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isTips = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              maxlength: "100",
              placeholder: "请输入驳回理由（必填）",
              "show-word-limit": "",
              size: "small",
              type: "textarea",
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer flex align-items ju-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isTips = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleChange },
                },
                [_vm._v("驳回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.isReason,
            center: "",
            title: "上次驳回原因",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isReason = $event
            },
          },
        },
        [
          _c("div", [
            _c("p", [_vm._v("驳回原因：" + _vm._s(_vm.turnInfo.err_note))]),
            _c("p", [
              _vm._v(
                "审核时间：" +
                  _vm._s(_vm._f("dateFormat")(_vm.turnInfo.audit_at * 1000))
              ),
            ]),
            _c("p", [_vm._v("审核人：" + _vm._s(_vm.turnInfo.name))]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer flex align-items ju-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnHollowReset",
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isReason = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }