var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process-item" }, [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "left-icon" }, [
        _c("div", { staticClass: "icon" }, [_vm._t("icon")], 2),
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c("div", { staticClass: "right-text" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "right-content-text" },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEndLine,
                  expression: "isEndLine",
                },
              ],
              staticClass: "line",
            }),
            _vm._t("content"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }