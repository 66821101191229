import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'NoticeBar',
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    text: {
      type: [String, Number],
      default: ''
    },
    speed: {
      type: Number,
      default: 50
    },
    defaultWidth: {
      type: Number,
      default: 500
    },
    calc: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      contentStyle: {
        transitionDuration: '0s',
        transform: 'translateX(0px)'
      },
      wrapWidth: 0,
      contentWidth: 0,
      time: 0,
      timer: null,
      convertSpeed: 1,
      DOMWidth: 0
    };
  },
  watch: {
    text: function text(value) {
      this.main(value);
    },
    DOMWidth: function DOMWidth() {
      this.main(this.text);
    }
  },
  mounted: function mounted() {
    var _this = this;

    window.onresize = function () {
      _this.DOMWidth = window.innerWidth;
    };

    this.main(this.text);
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    main: function main(text) {
      var _this2 = this;

      var _width = window.innerWidth;
      this.convertSpeed = _width / this.defaultWidth * this.speed; // 根据分辨率转化成不同的速度

      this.wrapWidth = this.$refs.wrap.offsetWidth;
      this.contentWidth = this.$refs.content.offsetWidth;
      this.calcLength(text, this.calc).then(function (is) {
        if (is) _this2.init();else {
          clearInterval(_this2.timer);
          _this2.timer = null;
        }
      });
    },
    init: function init() {
      var _this3 = this;

      this.startAnimate();
      this.timer = setInterval(function () {
        _this3.startAnimate();
      }, this.time * 1000);
      this.$once('hook:beforeDestroy', function () {
        clearInterval(_this3.timer);
        _this3.timer = null;
      });
    },
    startAnimate: function startAnimate() {
      var _this4 = this;

      this.contentStyle.transitionDuration = '0s';
      this.contentStyle.transform = 'translateX(' + this.wrapWidth + 'px)';
      this.time = (this.wrapWidth + this.contentWidth) / this.convertSpeed;
      setTimeout(function () {
        _this4.contentStyle.transitionDuration = _this4.time + 's';
        _this4.contentStyle.transform = 'translateX(-' + _this4.contentWidth + 'px)';
      }, 200);
    },
    tipClick: function tipClick() {
      this.$emit('click');
    },
    calcLength: function calcLength(text, isCalc) {
      var _this5 = this;

      return new Promise(function (resolve) {
        if (!isCalc) resolve(true);
        resolve(_this5.contentWidth > _this5.wrapWidth);
      });
    }
  }
};