import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ListItem',
  props: {
    num: {
      type: String | Number,
      default: 0
    },
    content: {
      type: Array,
      default: function _default() {
        return ['1、住宅：项目开盘活动未认购的房源，单价2000~2550 之间有125套余房、单价2550~3100之间有125套余房、单价3100~3650之间有125套余房', '2、别墅：项目开盘活动未认购的房源单价集中在2000~2300之间。'];
      }
    }
  }
};