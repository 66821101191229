var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-header-left" },
        [
          _c("event-select", {
            attrs: { "event-list": _vm.eventList, is_round: false },
            on: { select_round: _vm.selectRoundHandle },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-header-right" },
        [
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.handleTemplate },
            },
            [_vm._v("下载模板")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "block-bottom mT10" }, [
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c(
            "el-table",
            {
              key: _vm.timeStamp,
              attrs: {
                border: "",
                "highlight-current-row": "",
                size: "medium",
                data: _vm.tableData,
                height: _vm.tableHeight,
                "header-cell-style": { background: "#fafafa" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "序号",
                  type: "index",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "justify", label: "活动/场次" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.event_type === 1
                          ? _c("span", { staticClass: "buttonStyle" }, [
                              _vm._v(
                                _vm._s(_vm.getButtonText(scope.row.event_type))
                              ),
                            ])
                          : _c("span", { staticClass: "buttonStyle2" }, [
                              _vm._v(
                                _vm._s(_vm.getButtonText(scope.row.event_type))
                              ),
                            ]),
                        _vm._v(
                          " " +
                            _vm._s(scope.row.event_name) +
                            " " +
                            _vm._s(
                              scope.row.round_id === 0
                                ? ""
                                : " / " + scope.row.round_name
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "justify", prop: "name", label: "标题" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "rand_count",
                  label: "轮次人数",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "status",
                  label: "摇号状态",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.status === 1 ? "已摇号" : "未摇号")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", label: "预设名额", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.preinstall_number) + " 人"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", label: "预设到号人数", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.ower_code) + " 人"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "预设失败", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.err_code === ""
                          ? [
                              _c("span", [
                                _c("i", { staticClass: "el-icon-loading" }),
                              ]),
                            ]
                          : [
                              _c("span", { staticClass: "colorRed" }, [
                                _vm._v(_vm._s(scope.row.err_code) + " 人"),
                              ]),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "xu",
                  label: "操作",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex align-items ju-center trHeight",
                          },
                          [
                            _c("UploadExcel", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "eventranduser/import"],
                                  expression:
                                    "['admin', 'eventranduser/import']",
                                },
                              ],
                              attrs: {
                                "data-type": "lottery",
                                type: "icon",
                                size: "mini",
                                text: "导入预设",
                                accept: _vm.$xmlSel,
                                url:
                                  "/EventRandUser/import?event_rand_id=" +
                                  scope.row.id,
                              },
                              on: {
                                uploadSuccess: function ($event) {
                                  return _vm.getTableList()
                                },
                              },
                            }),
                            scope.row.preinstall_number > 0
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "导出预设",
                                      placement: "top-end",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: [
                                            "admin",
                                            "eventranduser/export",
                                          ],
                                          expression:
                                            "['admin', 'eventranduser/export']",
                                        },
                                      ],
                                      attrs: {
                                        "class-name": "greens",
                                        "icon-class": "export",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExport(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "预设名单",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "eventranduser/roster"],
                                      expression:
                                        "['admin', 'eventranduser/roster']",
                                    },
                                  ],
                                  attrs: {
                                    "class-name": "greens",
                                    "icon-class": "info",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePresetList(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "清除预设",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "eventranduser/del"],
                                      expression:
                                        "['admin', 'eventranduser/del']",
                                    },
                                  ],
                                  attrs: {
                                    "class-name": "greens",
                                    "icon-class": "clear",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDel(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }