import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import JsonViewer from 'vue-json-viewer';
export default {
  components: {
    JsonViewer: JsonViewer
  },
  props: {
    tableHeight: {
      type: Number,
      default: 0
    },
    tableData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      showContent: {
        code: '200',
        msg: '成功！',
        data: {
          refund: 0
        }
      }
    };
  },
  methods: {
    open: function open(str) {
      this.showContent = JSON.parse(str) || str;
      this.dialogVisible = true;
    }
  }
};