var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "canvas_box" }, [
    _c("div", { staticClass: "left-content" }, [
      _c(
        "div",
        { staticClass: "swicth" },
        [
          !_vm.isChange
            ? [
                _c("div", { staticStyle: { color: "#0c1e2b" } }, [
                  _vm._v("开盘前后"),
                ]),
              ]
            : [
                _c(
                  "div",
                  {
                    class: _vm.currentConfing == 1 ? "active" : "",
                    staticStyle: { color: "#0c1e2b" },
                    on: {
                      click: function ($event) {
                        return _vm.swicthInfo(1)
                      },
                    },
                  },
                  [_vm._v("开盘前")]
                ),
                _c(
                  "div",
                  {
                    class: _vm.currentConfing == 2 ? "active" : "",
                    staticStyle: { color: "#0c1e2b" },
                    on: {
                      click: function ($event) {
                        return _vm.swicthInfo(2)
                      },
                    },
                  },
                  [_vm._v("开盘后")]
                ),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "left-bottom" },
        [
          _c(
            "el-checkbox",
            {
              attrs: { "false-label": 0, "true-label": 1 },
              model: {
                value: _vm.isChange,
                callback: function ($$v) {
                  _vm.isChange = $$v
                },
                expression: "isChange",
              },
            },
            [_vm._v(" 开盘前后不同组件 ")]
          ),
          _c(
            "el-checkbox",
            {
              attrs: { "false-label": 0, "true-label": 1 },
              model: {
                value: _vm.isHide,
                callback: function ($$v) {
                  _vm.isHide = $$v
                },
                expression: "isHide",
              },
            },
            [_vm._v(" 整体隐藏右侧区域 ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "canvas-wrapper", style: "background: " + _vm.background },
      [
        _c(
          "draggable",
          {
            staticClass: "wrapper",
            attrs: { filter: ".unDrag" },
            model: {
              value: _vm.commontList,
              callback: function ($$v) {
                _vm.commontList = $$v
              },
              expression: "commontList",
            },
          },
          [
            _c(
              "transition-group",
              { staticClass: "group", staticStyle: { height: "100%" } },
              _vm._l(_vm.commontList, function (item, index2) {
                return _c(
                  "div",
                  {
                    key: index2,
                    class: [
                      "type-item",
                      item.type2 == "flow" || item.type2 == "houseType"
                        ? "flex11 unDrag"
                        : item.type2 == "watermark"
                        ? "unDrag"
                        : "",
                    ],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "zj-document",
                        on: {
                          click: function ($event) {
                            return _vm.showCommontEdit(item, index2)
                          },
                        },
                      },
                      [
                        _c("items", {
                          attrs: {
                            type: item.type,
                            type2: item.type2,
                            title: item.title,
                            subTitle: item.subTitle,
                            value: item.value,
                            children: item.children,
                            batchCurrent: "1",
                            lineBreak: item.lineBreak,
                            lineBreaTitle: item.lineBreaTitle,
                            parentIndex: index2,
                            flex1:
                              item.type2 == "flow" || item.type2 == "houseType"
                                ? true
                                : false,
                            color: item.styleForm,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            staticClass: "cz-btn",
            attrs: { type: "primary" },
            on: { click: _vm.resetting },
          },
          [_vm._v("重置")]
        ),
        _c(
          "el-button",
          {
            staticClass: "cz-btn",
            attrs: { type: "primary" },
            on: { click: _vm.copyLed },
          },
          [_vm._v("复制其他屏幕")]
        ),
        _vm.isChange
          ? _c(
              "el-button",
              {
                staticClass: "cz-btn",
                attrs: { type: "primary" },
                on: { click: _vm.syncSetting },
              },
              [
                _vm._v(
                  "同步至" +
                    _vm._s(_vm.currentConfing == 1 ? "开盘后" : "开盘前")
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "background" },
      [
        _c(
          "el-button",
          { staticClass: "cz-btn orange-btn", on: { click: _vm.save } },
          [_vm._v("保存设置")]
        ),
        _c(
          "div",
          { staticClass: "bg-box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("背景颜色")]),
            _c("el-color-picker", {
              attrs: { "color-format": "rgb" },
              on: { change: _vm.setBackgound },
              model: {
                value: _vm.background,
                callback: function ($$v) {
                  _vm.background = $$v
                },
                expression: "background",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }