import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import roundsVue from '../component/rounds.vue';
import noticeBar from '../component/notice.vue';
import api from '../../../api/lotteryEntry';
import { getWsToken } from '@/utils/auth';
export default {
  components: {
    roundsVue: roundsVue,
    noticeBar: noticeBar
  },
  data: function data() {
    return {
      config: {
        last_repeat: true,
        // 最后一轮是否重复
        list: [],
        // 列表
        rand: {},
        // 录入式摇号规则
        repeat_msg: '',
        // 提示与几轮重复
        repeat_num: [],
        // 最新一轮与那一轮重复 重复轮轮都要高亮 （这是个数组）
        round: {},
        // 轮次记录
        round_all: [] // 该公示屏幕所有轮次

      },
      grouping: [],
      urlParams: {
        page: '',
        refresh: '',
        total: ''
      },
      maxFontNum: 0
    };
  },
  watch: {
    $route: function $route() {
      var _this$$router, _this$$router$history, _this$$router$history2;

      this.urlParams = ((_this$$router = this.$router) === null || _this$$router === void 0 ? void 0 : (_this$$router$history = _this$$router.history) === null || _this$$router$history === void 0 ? void 0 : (_this$$router$history2 = _this$$router$history.current) === null || _this$$router$history2 === void 0 ? void 0 : _this$$router$history2.query) || {};
      this.getData();
    }
  },
  created: function created() {
    var _this$$router2, _this$$router2$histor, _this$$router2$histor2;

    document.title = '公示大屏';
    this.urlParams = ((_this$$router2 = this.$router) === null || _this$$router2 === void 0 ? void 0 : (_this$$router2$histor = _this$$router2.history) === null || _this$$router2$histor === void 0 ? void 0 : (_this$$router2$histor2 = _this$$router2$histor.current) === null || _this$$router2$histor2 === void 0 ? void 0 : _this$$router2$histor2.query) || {};
    this.getData();
    this.main();
  },
  methods: {
    main: function main() {
      var _this = this;

      // ----------------websocket------------------------
      var ws;
      var tt;
      var lockReconnect = false; // 避免重复连接

      var self = this;
      var websocket = {
        Init: function Init() {
          ws = new WebSocket("".concat(_this.wsUrl, "?token=").concat(getWsToken()));

          ws.onmessage = function (e) {
            var msg = JSON.parse(e.data);
            self.socketListen(msg); // 处理数据

            heart.start();
          };

          ws.onclose = function () {
            retry();
          };

          ws.onopen = function () {
            heart.start();
          };

          ws.onerror = function () {
            retry();
          };
        }
      }; // 重连

      function retry() {
        if (lockReconnect) return;
        lockReconnect = true;
        tt && clearTimeout(tt);
        tt = setTimeout(function () {
          websocket.Init();
          lockReconnect = false;
        }, 4000);
      } // 心跳


      var heart = {
        timeout: 1000 * 100,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function start() {
          var _this2 = this;

          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(function () {
            ws.send('{"type":"ping"}');
            _this2.serverTimeoutObj = setTimeout(function () {
              if (ws.readyState != 1) ws.close();
            }, _this2.timeout);
          }, this.timeout);
        }
      };
      websocket.Init();
    },
    // socket 信息监听
    socketListen: function socketListen(msg) {
      var round = this.config.round;
      var type = msg.data ? msg.data.type : msg.mag;

      if (type == 'entry_help' || type == 'entry_reset') {
        if (round.num == 0) round.num = 1;else round.num += 1;
        this.getData();
      }
    },
    // 获取数据
    getData: function getData() {
      var _this3 = this;

      var page = this.urlParams.page;
      api.publicity({
        page: Number(page || 1)
      }).then(function (result) {
        _this3.config = result.data.data;
        if (!_this3.config.repeat_num) _this3.config.repeat_num = [];

        _this3.dispose(_this3.config.list || [], _this3.config.round_all || []);
      });
    },
    // 处理数据
    dispose: function dispose(data, showData) {
      if (!data) data = [];
      if (!showData) showData = [];

      for (var i = 0; i < showData.length; i++) {
        var ai = showData[i];
        ai.children = [];

        for (var j = 0; j < data.length; j++) {
          if (ai.num == data[j].rand_round) ai.children.push(data[j]);
        }
      } // for (let i = 0; i < showData.length; i++) {
      //   let size = 9999
      //   var result = []
      //   for (let j = 0, len = showData[i].children.length; j < len; j += size) {
      //     result.push(showData[i].children.slice(j, j + size))
      //   }
      //   showData[i].children = result
      // }
      // console.log(showData)


      this.grouping = showData;
    },
    // 数字转汉字数字
    convertToChinaNum: function convertToChinaNum(num) {
      var chinese = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      var unit = ['', '十', '百', '千', '万', '十', '百', '千', '亿']; // 可继续追加更高位转换值

      if (!num || isNaN(num)) return '零';
      var english = num.toString().split('');
      var result = '';

      for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i; // 倒序排列设值

        result = unit[i] + result;
        var chinese_index = english[des_i];
        result = chinese[chinese_index] + result;
      } // 将【零千、零百】换成【零】 【十零】换成【十】


      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十'); // 合并中间多个零为一个零

      result = result.replace(/零+/g, '零'); // 将【零亿】换成【亿】【零万】换成【万】

      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万'); // 将【亿万】换成【亿】

      result = result.replace(/亿万/g, '亿'); // 移除末尾的零

      result = result.replace(/零+$/, ''); // 将【一十】换成【十】

      result = result.replace(/^一十/g, '十');
      return result;
    }
  }
};