//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      defualt: '100%'
    },
    height: {
      type: String,
      default: '90%'
    },
    close: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    hidePopup: function hidePopup() {
      this.$emit('update:visible', false);
    }
  }
};