var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "header space-between" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("基础设置")]),
          _c(
            "el-button",
            {
              staticClass: "greens-btn",
              attrs: { size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "detail-box" }, [
        _c("div", { staticClass: "sale-setting" }, [
          _c(
            "div",
            { staticClass: "sale-setting-top ver-center" },
            [
              _c(
                "div",
                {
                  staticClass: "sub-title-big m-g",
                  staticStyle: { "--m-g-r": "15" },
                },
                [_vm._v("显示设置")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "upload-btn",
                  on: {
                    click: function ($event) {
                      return _vm.toPreview(0)
                    },
                  },
                },
                [_vm._v("预览")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "sale-setting-content" }, [
            _c(
              "div",
              {
                staticClass: "house-comp",
                staticStyle: { "margin-top": "-20px" },
              },
              [
                _c("div", { staticClass: "sale-setting-box" }, [
                  _c(
                    "div",
                    { staticClass: "min-title" },
                    [
                      _vm._v(" 默认颜色 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "right",
                            content:
                              "自定义保存颜色后，此处默认颜色配置将无效！",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "tips el-icon-question",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "select-box" }, [
                    _c(
                      "div",
                      { staticClass: "base-mes" },
                      [
                        _c("div"),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            on: { change: _vm.houseResChange },
                            model: {
                              value: _vm.ledConfInfo.color_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ledConfInfo, "color_type", $$v)
                              },
                              expression: "ledConfInfo.color_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("高贵黑"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("科技蓝"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _c("div", { staticClass: "min-title" }, [_vm._v("元素展示")]),
                  _c("div", { staticClass: "select-box" }, [
                    _c(
                      "div",
                      { staticClass: "base-mes" },
                      [
                        _c("div"),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-box",
                            on: { change: _vm.houseResChange },
                            model: {
                              value: _vm.ledConfInfo.show_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ledConfInfo, "show_type", $$v)
                              },
                              expression: "ledConfInfo.show_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("一格内展示"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("多列拓展"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.ledConfInfo.show_type == 1
                      ? _c("div", { staticClass: "base-mes ver-center" }, [
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v("基本信息"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "radio-box radio-box2 " },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                  attrs: { gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        span:
                                          _vm.customSetting.cate_room &&
                                          _vm.customSetting.cate_car
                                            ? 12
                                            : 7,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: {
                                            "margin-right": "0",
                                            width: "100px",
                                          },
                                          attrs: { value: true, disabled: "" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "3px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.customSetting.custom_data
                                                    .hosue_car_num
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                          _vm.customSetting.cate_room
                                            ? [
                                                _c("span", [
                                                  _vm._v("“房号”命名: "),
                                                ]),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    maxlength: "6",
                                                    "show-word-limit": "",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ledConfInfo
                                                        .room_num_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ledConfInfo,
                                                        "room_num_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ledConfInfo.room_num_name",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.customSetting.cate_car
                                            ? [
                                                _c("span", [
                                                  _vm._v("“车位号”命名: "),
                                                ]),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    maxlength: "6",
                                                    "show-word-limit": "",
                                                    size: "small",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ledConfInfo
                                                        .car_num_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ledConfInfo,
                                                        "car_num_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ledConfInfo.car_num_name",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.customSetting.cate_room == 1
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 3 } },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                "false-label": 0,
                                                "true-label": 1,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.setBoldArray(
                                                    "show_floor",
                                                    _vm.ledConfInfo.show_floor,
                                                    "房源楼层"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.ledConfInfo.show_floor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ledConfInfo,
                                                    "show_floor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ledConfInfo.show_floor",
                                              },
                                            },
                                            [_vm._v("房源楼层 ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                          attrs: {
                                            "false-label": 0,
                                            "true-label": 1,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setBoldArray(
                                                "show_room_cate",
                                                _vm.ledConfInfo.show_room_cate,
                                                _vm.customSetting.custom_data
                                                  .house_type
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.ledConfInfo.show_room_cate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ledConfInfo,
                                                "show_room_cate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ledConfInfo.show_room_cate",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.customSetting.custom_data
                                                .house_type
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            "false-label": 0,
                                            "true-label": 1,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setBoldArray(
                                                "show_room_other",
                                                _vm.ledConfInfo.show_room_other,
                                                "其他信息"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.ledConfInfo.show_room_other,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ledConfInfo,
                                                "show_room_other",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ledConfInfo.show_room_other",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("“其他信息”命名："),
                                          ]),
                                          _c("el-input", {
                                            staticStyle: { width: "100px" },
                                            attrs: {
                                              maxlength: "6",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.ledConfInfo.room_other_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ledConfInfo,
                                                  "room_other_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ledConfInfo.room_other_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.ledConfInfo.show_type == 1
                      ? _c("div", { staticClass: "base-mes ver-center" }, [
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v("面积"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "radio-box" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setBoldArray(
                                        "show_build_area",
                                        _vm.ledConfInfo.show_build_area,
                                        "建筑面积"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ledConfInfo.show_build_area,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "show_build_area",
                                        $$v
                                      )
                                    },
                                    expression: "ledConfInfo.show_build_area",
                                  },
                                },
                                [_vm._v("建筑面积 ")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setBoldArray(
                                        "show_room_area",
                                        _vm.ledConfInfo.show_room_area,
                                        "套内面积"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ledConfInfo.show_room_area,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "show_room_area",
                                        $$v
                                      )
                                    },
                                    expression: "ledConfInfo.show_room_area",
                                  },
                                },
                                [_vm._v("套内面积 ")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.ledConfInfo.show_type == 1
                      ? _c("div", { staticClass: "base-mes price-mes" }, [
                          _c(
                            "div",
                            { staticClass: "price-setting-header" },
                            [
                              _c("span", { staticClass: "tip-text" }, [
                                _vm._v("价格"),
                              ]),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.unitCheckedChange(
                                        "show_price",
                                        _vm.ledConfInfo.show_price,
                                        "单价"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ledConfInfo.show_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "show_price",
                                        $$v
                                      )
                                    },
                                    expression: "ledConfInfo.show_price",
                                  },
                                },
                                [_vm._v("单价 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_price == 1,
                                  expression: "ledConfInfo.show_price == 1",
                                },
                              ],
                              staticClass: "price-box",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "brackets-l brackets" },
                                [_vm._v("(")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        1,
                                      expression:
                                        "projectRoom.room_info_price_type == 1",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.droom_info_pin_xian_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_xian_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_xian_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_xian_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        2,
                                      expression:
                                        "projectRoom.room_info_price_type == 2",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.droom_info_pin_yuan_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_yuan_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_yuan_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_yuan_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        2,
                                      expression:
                                        "projectRoom.room_info_price_type == 2",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo
                                        .droom_info_pin_you_hui_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_you_hui_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_you_hui_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_you_hui_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo
                                        .droom_info_pin_biao_zhun_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_biao_zhun_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_biao_zhun_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom
                                        .room_info_pin_biao_zhun_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.droom_info_pin_an_jie_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_an_jie_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_an_jie_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_an_jie_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.droom_info_pin_yi_ci_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "droom_info_pin_yi_ci_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.droom_info_pin_yi_ci_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_yi_ci_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "brackets" }, [
                                _vm._v(")"),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "price-setting" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 1 &&
                                _vm.ledConfInfo.droom_info_pin_xian_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 1 && ledConfInfo.droom_info_pin_xian_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(_vm.projectRoom.room_info_pin_xian_jia) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_xian_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_xian_jia",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.room_info_pin_xian_jia",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 2 &&
                                _vm.ledConfInfo.droom_info_pin_yuan_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 2 && ledConfInfo.droom_info_pin_yuan_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(_vm.projectRoom.room_info_pin_yuan_jia) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_yuan_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_yuan_jia",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.room_info_pin_yuan_jia",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 2 &&
                                _vm.ledConfInfo.droom_info_pin_you_hui_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 2 && ledConfInfo.droom_info_pin_you_hui_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_you_hui_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_you_hui_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_you_hui_jia",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_you_hui_jia",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.droom_info_pin_biao_zhun_jia ==
                                  1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.droom_info_pin_biao_zhun_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_biao_zhun_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.ledConfInfo.room_info_pin_biao_zhun_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_biao_zhun_jia",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_biao_zhun_jia",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.droom_info_pin_an_jie_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.droom_info_pin_an_jie_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_an_jie_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_an_jie_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_an_jie_jia",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_an_jie_jia",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.droom_info_pin_yi_ci_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.droom_info_pin_yi_ci_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_yi_ci_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_yi_ci_jia,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_yi_ci_jia",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.room_info_pin_yi_ci_jia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.ledConfInfo.show_price == 1 &&
                              _vm.ledConfInfo.show_type == 1,
                            expression:
                              "ledConfInfo.show_price == 1 && ledConfInfo.show_type == 1",
                          },
                        ],
                        staticClass: "price-setting",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "price-name-box" },
                          [
                            _c("span", { staticClass: "price-name-text" }, [
                              _vm._v("单价取整:"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.waitJson.unit_isround,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "unit_isround", $$v)
                                  },
                                  expression: "waitJson.unit_isround",
                                },
                              },
                              [_vm._v("取整")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.waitJson.unit_isround,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "unit_isround", $$v)
                                  },
                                  expression: "waitJson.unit_isround",
                                },
                              },
                              [_vm._v("两位小数")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "price-name-box",
                            staticStyle: { "margin-left": "50px" },
                          },
                          [
                            _c("span", { staticClass: "price-name-text" }, [
                              _vm._v("单价单位:"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.waitJson.unit_isunit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "unit_isunit", $$v)
                                  },
                                  expression: "waitJson.unit_isunit",
                                },
                              },
                              [_vm._v("显示")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.waitJson.unit_isunit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "unit_isunit", $$v)
                                  },
                                  expression: "waitJson.unit_isunit",
                                },
                              },
                              [_vm._v("不显示")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.ledConfInfo.show_type == 1
                      ? _c("div", { staticClass: "base-mes price-mes" }, [
                          _c(
                            "div",
                            { staticClass: "price-setting-header" },
                            [
                              _c("span", { staticClass: "tip-text" }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.totalCheckedChange(
                                        "show_price2",
                                        _vm.ledConfInfo.show_price2,
                                        "总价"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.ledConfInfo.show_price2,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "show_price2",
                                        $$v
                                      )
                                    },
                                    expression: "ledConfInfo.show_price2",
                                  },
                                },
                                [_vm._v("总价 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ledConfInfo.show_price2 == 1,
                                  expression: "ledConfInfo.show_price2 == 1",
                                },
                              ],
                              staticClass: "price-box",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "brackets-l brackets" },
                                [_vm._v("(")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        1,
                                      expression:
                                        "projectRoom.room_info_price_type == 1",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.zroom_info_pin_xian_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_xian_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_xian_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_xian_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        2,
                                      expression:
                                        "projectRoom.room_info_price_type == 2",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.zroom_info_pin_yuan_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_yuan_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_yuan_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_yuan_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        2,
                                      expression:
                                        "projectRoom.room_info_price_type == 2",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo
                                        .zroom_info_pin_you_hui_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_you_hui_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_you_hui_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_you_hui_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo
                                        .zroom_info_pin_biao_zhun_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_biao_zhun_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_biao_zhun_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom
                                        .room_info_pin_biao_zhun_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.zroom_info_pin_an_jie_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_an_jie_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_an_jie_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_an_jie_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.projectRoom.room_info_price_type ==
                                        3,
                                      expression:
                                        "projectRoom.room_info_price_type == 3",
                                    },
                                  ],
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value:
                                      _vm.ledConfInfo.zroom_info_pin_yi_ci_jia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "zroom_info_pin_yi_ci_jia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.zroom_info_pin_yi_ci_jia",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.projectRoom.room_info_pin_yi_ci_jia
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "brackets" }, [
                                _vm._v(")"),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "price-setting" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 1 &&
                                _vm.ledConfInfo.zroom_info_pin_xian_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 1 && ledConfInfo.zroom_info_pin_xian_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(_vm.projectRoom.room_info_pin_xian_jia) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_xian_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_xian_jia2",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.room_info_pin_xian_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 2 &&
                                _vm.ledConfInfo.zroom_info_pin_yuan_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 2 && ledConfInfo.zroom_info_pin_yuan_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(_vm.projectRoom.room_info_pin_yuan_jia) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "8",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_yuan_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_yuan_jia2",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.room_info_pin_yuan_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 2 &&
                                _vm.ledConfInfo.zroom_info_pin_you_hui_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 2 && ledConfInfo.zroom_info_pin_you_hui_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_you_hui_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_you_hui_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_you_hui_jia2",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_you_hui_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.zroom_info_pin_biao_zhun_jia ==
                                  1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.zroom_info_pin_biao_zhun_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_biao_zhun_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value:
                                _vm.ledConfInfo.room_info_pin_biao_zhun_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_biao_zhun_jia2",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_biao_zhun_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.zroom_info_pin_an_jie_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.zroom_info_pin_an_jie_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_an_jie_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_an_jie_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_an_jie_jia2",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_an_jie_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectRoom.room_info_price_type == 3 &&
                                _vm.ledConfInfo.zroom_info_pin_yi_ci_jia == 1,
                              expression:
                                "projectRoom.room_info_price_type == 3 && ledConfInfo.zroom_info_pin_yi_ci_jia == 1",
                            },
                          ],
                          staticClass: "price-name-box",
                        },
                        [
                          _c("span", { staticClass: "price-name-text" }, [
                            _vm._v(
                              '"' +
                                _vm._s(
                                  _vm.projectRoom.room_info_pin_yi_ci_jia
                                ) +
                                '"命名'
                            ),
                          ]),
                          _c("el-input", {
                            staticClass: "price-setting-name",
                            attrs: {
                              clearable: "",
                              maxlength: "10",
                              "show-word-limit": "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ledConfInfo.room_info_pin_yi_ci_jia2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "room_info_pin_yi_ci_jia2",
                                  $$v
                                )
                              },
                              expression:
                                "ledConfInfo.room_info_pin_yi_ci_jia2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.ledConfInfo.show_price2 == 1 &&
                              _vm.ledConfInfo.show_type == 1,
                            expression:
                              "ledConfInfo.show_price2 == 1 && ledConfInfo.show_type == 1",
                          },
                        ],
                        staticClass: "price-setting",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "price-name-box" },
                          [
                            _c("span", { staticClass: "price-name-text" }, [
                              _vm._v("总价取整:"),
                            ]),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.waitJson.total_isround,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "total_isround", $$v)
                                  },
                                  expression: "waitJson.total_isround",
                                },
                              },
                              [_vm._v("取整")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.waitJson.total_isround,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "total_isround", $$v)
                                  },
                                  expression: "waitJson.total_isround",
                                },
                              },
                              [_vm._v("两位小数")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "price-name-box",
                            staticStyle: { "margin-left": "50px" },
                          },
                          [
                            _c("span", { staticClass: "price-name-text" }, [
                              _vm._v("总价单位:"),
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.waitJson.total_isunit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.waitJson, "total_isunit", $$v)
                                  },
                                  expression: "waitJson.total_isunit",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("显示"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("不显示"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.ledConfInfo.show_type == 1
                      ? _c("div", { staticClass: "base-mes ver-center" }, [
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v("客户信息"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "radio-box" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value: _vm.ledConfInfo.user_info_batch_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "user_info_batch_code",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.user_info_batch_code",
                                  },
                                },
                                [
                                  _vm._v(
                                    "批次号 + " +
                                      _vm._s(_vm.$adminList.user_code_name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.ledConfInfo.show_type == 0
                      ? _c("div", { staticClass: "base-mes ver-center" }, [
                          _c("span", { staticClass: "tip-text" }),
                          _c(
                            "div",
                            { staticClass: "radio-box" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 1, "true-label": 0 },
                                  model: {
                                    value: _vm.ledConfInfo.show_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "show_type",
                                        $$v
                                      )
                                    },
                                    expression: "ledConfInfo.show_type",
                                  },
                                },
                                [_vm._v("房号（车位号） ")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "false-label": 0, "true-label": 1 },
                                  model: {
                                    value: _vm.ledConfInfo.user_info_batch_code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ledConfInfo,
                                        "user_info_batch_code",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ledConfInfo.user_info_batch_code",
                                  },
                                },
                                [
                                  _vm._v(
                                    "客户批次+" +
                                      _vm._s(_vm.$adminList.user_code_name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ledConfInfo.show_type == 1,
                          expression: "ledConfInfo.show_type == 1",
                        },
                      ],
                      staticClass:
                        "min-title first-col m-g-t-15 first-color-title",
                    },
                    [
                      _vm._v(" 单元首列颜色 "),
                      _c(
                        "div",
                        {
                          staticClass: "color-default",
                          on: { click: _vm.backDeafult },
                        },
                        [_vm._v("默认颜色")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ledConfInfo.show_type == 1,
                          expression: "ledConfInfo.show_type == 1",
                        },
                      ],
                      staticClass: "select-box",
                    },
                    [
                      _c("div", { staticClass: "unit-color ver-center" }, [
                        _c(
                          "div",
                          { staticClass: "base-mes bgc ver-center" },
                          [
                            _c("span", { staticClass: "m-g-r-15 tip-text" }, [
                              _vm._v("底色"),
                            ]),
                            _c("el-color-picker", {
                              attrs: {
                                predefine: _vm.bgc,
                                "show-alpha": "",
                                size: "mini",
                              },
                              model: {
                                value: _vm.bgColor,
                                callback: function ($$v) {
                                  _vm.bgColor = $$v
                                },
                                expression: "bgColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "base-mes font-c ver-center" },
                          [
                            _c(
                              "span",
                              { staticClass: "m-g-r-15 m-g-l-15 tip-text" },
                              [_vm._v("字体色")]
                            ),
                            _c("el-color-picker", {
                              attrs: {
                                predefine: _vm.fontC,
                                "show-alpha": "",
                                size: "mini",
                              },
                              model: {
                                value: _vm.fontColor,
                                callback: function ($$v) {
                                  _vm.fontColor = $$v
                                },
                                expression: "fontColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ledConfInfo.show_type == 1,
                          expression: "ledConfInfo.show_type == 1",
                        },
                      ],
                      staticClass: "min-title m-g-t-15",
                    },
                    [_vm._v("告罄显示")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ledConfInfo.show_type == 1,
                          expression: "ledConfInfo.show_type == 1",
                        },
                      ],
                      staticClass: "select-box",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "base-mes ver-center" },
                        [
                          _c("span", { staticClass: "tip-text" }),
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.saleOverChange },
                              model: {
                                value: _vm.saleOver,
                                callback: function ($$v) {
                                  _vm.saleOver = $$v
                                },
                                expression: "saleOver",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v(
                                  "遮盖除" +
                                    _vm._s(
                                      _vm.customSetting.custom_data
                                        .hosue_car_num
                                    ) +
                                    "外其他元素 "
                                ),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("不遮盖"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "min-title m-g-t-15" }, [
                    _vm._v("元素加粗"),
                  ]),
                  _c("div", { staticClass: "select-box" }, [
                    _c(
                      "div",
                      { staticClass: "base-mes ver-center" },
                      [
                        _c("span", { staticClass: "tip-text" }),
                        _vm.ledConfInfo.show_type == 1
                          ? [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.waitJson.boldList,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.waitJson, "boldList", $$v)
                                    },
                                    expression: "waitJson.boldList",
                                  },
                                },
                                _vm._l(_vm.boldArray, function (item, index) {
                                  return _c(
                                    "el-checkbox",
                                    { key: index, attrs: { label: item.type } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ]
                          : [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.waitJson.fhBold,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.waitJson, "fhBold", $$v)
                                    },
                                    expression: "waitJson.fhBold",
                                  },
                                },
                                [_vm._v(" 房号或车位号 ")]
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "min-title first-col m-g-t-15" }, [
                    _vm._v(" 元素字号 "),
                    _c(
                      "div",
                      {
                        staticClass: "color-default",
                        on: { click: _vm.fontsizeDeafult },
                      },
                      [_vm._v("默认字号")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "select-box size-box" },
                    [
                      _c("div", { staticClass: "size-list" }, [
                        _c(
                          "div",
                          { staticClass: "tabl-hed tabl-box" },
                          [
                            _c("span", { staticClass: "lable" }, [
                              _vm._v("楼栋单元："),
                            ]),
                            _c("el-input", {
                              staticClass: "size-input",
                              attrs: {
                                type: "number",
                                "show-word-limit": "",
                                size: "small",
                              },
                              model: {
                                value: _vm.waitJson.buildingSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.waitJson, "buildingSize", $$v)
                                },
                                expression: "waitJson.buildingSize",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.ledConfInfo.show_type == 1
                        ? _vm._l(_vm.boldArray, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "size-list" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tabl-hed tabl-box" },
                                  [
                                    _c("span", { staticClass: "lable" }, [
                                      _vm._v(
                                        _vm._s(item.name2 || item.name) +
                                          "表头："
                                      ),
                                    ]),
                                    _c("el-input", {
                                      staticClass: "size-input",
                                      attrs: {
                                        type: "number",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: item.headSize,
                                        callback: function ($$v) {
                                          _vm.$set(item, "headSize", $$v)
                                        },
                                        expression: "item.headSize",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tabl-content tabl-box" },
                                  [
                                    _c("span", { staticClass: "lable" }, [
                                      _vm._v(
                                        _vm._s(item.name2 || item.name) +
                                          "内容："
                                      ),
                                    ]),
                                    _c("el-input", {
                                      staticClass: "size-input",
                                      attrs: {
                                        type: "number",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: item.contentSize,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contentSize", $$v)
                                        },
                                        expression: "item.contentSize",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          })
                        : [
                            _c("div", { staticClass: "size-list" }, [
                              _c(
                                "div",
                                { staticClass: "tabl-hed tabl-box" },
                                [
                                  _c("span", { staticClass: "lable" }, [
                                    _vm._v("房号或车位号："),
                                  ]),
                                  _c("el-input", {
                                    staticClass: "size-input",
                                    attrs: {
                                      type: "number",
                                      "show-word-limit": "",
                                      size: "small",
                                    },
                                    model: {
                                      value: _vm.waitJson.fhSize,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.waitJson, "fhSize", $$v)
                                      },
                                      expression: "waitJson.fhSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _c("div", { staticStyle: { height: "30px" } }),
          ]),
        ]),
        _c("div", { staticClass: "countdown-setting" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "countdown-box" },
            [
              _c("div", { staticClass: "min-title m-g-t-15 m-g-b-15" }, [
                _vm._v("显示内容"),
              ]),
              _c(
                "el-form",
                [
                  _c("el-form-item", [
                    _c("div", { staticClass: "before-open-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "before-open-check" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-img",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "1900px",
                                    height: "1000px",
                                    transform: "scale(0.1)",
                                    "margin-left": "-855px",
                                    "margin-top": "-450px",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "1900px",
                                      height: "1000px",
                                      position: "absolute",
                                      top: "0",
                                      "z-index": "-10",
                                    },
                                    attrs: {
                                      src: _vm.imgUrl + _vm.waitJson.imgSrc,
                                    },
                                  }),
                                  _vm.waitJson.title.show
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "1127px",
                                            height: "145px",
                                            "line-height": "145px",
                                            "text-align": "center",
                                            position: "absolute",
                                            top: "100px",
                                            left: "386.5px",
                                            "border-radius": "30px",
                                            "font-size": "70px",
                                            color: "white",
                                          },
                                          style: {
                                            "background-color":
                                              "rgba(255,255,255," +
                                              _vm.waitJson.title.opacity +
                                              ")",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.waitJson.title.text) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.waitJson.wait.show
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "1127px",
                                            height: "400px",
                                            "line-height": "400px",
                                            "text-align": "center",
                                            position: "absolute",
                                            top: "380px",
                                            left: "386.5px",
                                            "border-radius": "30px",
                                            "font-size": "100px",
                                            color: "white",
                                          },
                                          style: {
                                            "background-color":
                                              "rgba(255,255,255," +
                                              _vm.waitJson.wait.opacity +
                                              ")",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.waitJson.wait.text) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("el-checkbox", {
                            attrs: {
                              value: true,
                              disabled: "",
                              label: "文字等待页面",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "before-open-check" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-img",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "1900px",
                                    height: "1000px",
                                    transform: "scale(0.1)",
                                    "margin-left": "-855px",
                                    "margin-top": "-450px",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "1900px",
                                      height: "1000px",
                                      position: "absolute",
                                      top: "0",
                                      "z-index": "-10",
                                    },
                                    attrs: {
                                      src: _vm.imgUrl + _vm.waitJson.imgSrc,
                                    },
                                  }),
                                  _vm.waitJson.title.show
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "1127px",
                                            height: "145px",
                                            "line-height": "145px",
                                            "text-align": "center",
                                            position: "absolute",
                                            top: "100px",
                                            left: "386.5px",
                                            "border-radius": "30px",
                                            "font-size": "70px",
                                            color: "white",
                                          },
                                          style: {
                                            "background-color":
                                              "rgba(255,255,255," +
                                              _vm.waitJson.title.opacity +
                                              ")",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.waitJson.title.text) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.waitJson.wait.show
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "420px",
                                            left: "450px",
                                            "font-size": "30px",
                                            color: "white",
                                          },
                                        },
                                        [_vm._v(" 距离开盘还有 ")]
                                      )
                                    : _vm._e(),
                                  _vm.waitJson.wait.show
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "1127px",
                                            height: "400px",
                                            "line-height": "400px",
                                            "text-align": "center",
                                            position: "absolute",
                                            top: "380px",
                                            left: "386.5px",
                                            "border-radius": "30px",
                                            "font-size": "100px",
                                            color: "white",
                                          },
                                          style: {
                                            "background-color":
                                              "rgba(255,255,255," +
                                              _vm.waitJson.wait.opacity +
                                              ")",
                                          },
                                        },
                                        [_vm._v(" 1 小时 06 分 32 秒 ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("el-checkbox", {
                            attrs: {
                              "false-label": 0,
                              "true-label": 1,
                              label: "倒计时等待页面",
                            },
                            model: {
                              value: _vm.ledConfInfo.show_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ledConfInfo, "show_time", $$v)
                              },
                              expression: "ledConfInfo.show_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "before-open-check" },
                        [
                          _c("div", { staticClass: "top-img" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/image/temp-show-room.png"),
                              },
                            }),
                          ]),
                          _c("el-checkbox", {
                            attrs: {
                              "false-label": 0,
                              "true-label": 1,
                              label: "开盘" + _vm.customSetting.title + "列表",
                            },
                            model: {
                              value: _vm.ledConfInfo.show_room_switch,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ledConfInfo,
                                  "show_room_switch",
                                  $$v
                                )
                              },
                              expression: "ledConfInfo.show_room_switch",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "before-open-check" },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "before-upload": _vm.beforeAvatarUpload,
                                "file-list": _vm.fileList,
                                limit: 1,
                                multiple: false,
                                "on-preview": _vm.handlePictureCardPreview,
                                "http-request": _vm.handleFileUploadOss,
                                "on-remove": _vm.handleRemove,
                                "on-success": _vm.imgupSuccess,
                                accept: _vm.$imgSel,
                                "list-type": "picture-card",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "line-height": "36px",
                                    height: "100px",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("span", [_vm._v("替换背景图")]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "图片最佳尺寸为（1920*1080）px，不大于4M"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ledConfInfo.show_word_switch == 1,
                      expression: "ledConfInfo.show_word_switch == 1",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "min-title m-g-t-15 m-g-b-15",
                      staticStyle: { display: "flex", height: "45px" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("div", { staticStyle: { width: "131px" } }, [
                            _vm._v("等待页面-标题框"),
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "开始倒计时之前的显示文本",
                                effect: "dark",
                                placement: "right",
                              },
                            },
                            [_c("i", { staticClass: "tips el-icon-question" })]
                          ),
                          _c("custom-switch", {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-top": "-3px",
                            },
                            attrs: {
                              svalue: _vm.waitJson.title.show,
                              trueLabel: "显示",
                              falseLabel: "隐藏",
                            },
                            on: { change: _vm.titleSwitchChange },
                          }),
                          _c("custom-switch", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.waitJson.title.show,
                                expression: "waitJson.title.show",
                              },
                            ],
                            staticStyle: {
                              "margin-left": "20px",
                              "margin-top": "-3px",
                            },
                            attrs: {
                              svalue: _vm.waitJson.title.opacity === 0.1,
                              trueLabel: "半透明",
                              falseLabel: "全透明",
                            },
                            on: { change: _vm.opacitySwitchChange },
                          }),
                        ],
                        1
                      ),
                      _vm.waitJson.title.show
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "45px",
                                "margin-top": "-10px",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                { attrs: { model: _vm.waitJson.title } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        rules: [
                                          {
                                            required: true,
                                            message:
                                              "未开售的标题等待文字不能为空",
                                          },
                                        ],
                                        prop: "text",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "280px" },
                                        attrs: {
                                          clearable: "",
                                          maxlength: "20",
                                          minlength: "0",
                                          placeholder: "请输入内容",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.waitJson.title.text,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.waitJson.title,
                                              "text",
                                              $$v
                                            )
                                          },
                                          expression: "waitJson.title.text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "min-title m-g-t-15 m-g-b-15",
                      staticStyle: { display: "flex", height: "45px" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("div", { staticStyle: { width: "131px" } }, [
                            _vm._v("等待页面-内容框"),
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "开始倒计时之前的显示文本",
                                effect: "dark",
                                placement: "right",
                              },
                            },
                            [_c("i", { staticClass: "tips el-icon-question" })]
                          ),
                          _c("custom-switch", {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-top": "-3px",
                            },
                            attrs: {
                              svalue: _vm.waitJson.wait.show,
                              trueLabel: "显示",
                              falseLabel: "隐藏",
                            },
                            on: { change: _vm.waitTitleSwitchChange },
                          }),
                          _c("custom-switch", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.waitJson.wait.show,
                                expression: "waitJson.wait.show",
                              },
                            ],
                            staticStyle: {
                              "margin-left": "20px",
                              "margin-top": "-3px",
                            },
                            attrs: {
                              svalue: _vm.waitJson.wait.opacity === 0.1,
                              trueLabel: "半透明",
                              falseLabel: "全透明",
                            },
                            on: { change: _vm.waitOpacitySwitchChange },
                          }),
                        ],
                        1
                      ),
                      _vm.waitJson.wait.show
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "-10px",
                                "margin-left": "45px",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                { attrs: { model: _vm.waitJson.wait } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        rules: [
                                          {
                                            required: true,
                                            message: "未开售的等待文字不能为空",
                                          },
                                        ],
                                        prop: "text",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "280px" },
                                        attrs: {
                                          clearable: "",
                                          maxlength: "20",
                                          minlength: "0",
                                          placeholder: "请输入内容",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.waitJson.wait.text,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.waitJson.wait,
                                              "text",
                                              $$v
                                            )
                                          },
                                          expression: "waitJson.wait.text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ledConfInfo.show_time == 1,
                      expression: "ledConfInfo.show_time == 1",
                    },
                  ],
                  staticClass: "min-title m-g-t-15 m-g-b-15",
                },
                [
                  _vm._v(" 倒计时等待页面 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content:
                          "在“开盘" +
                          _vm.customSetting.title +
                          "列表”出现前的指定时间出现",
                        effect: "dark",
                        placement: "right",
                      },
                    },
                    [_c("i", { staticClass: "tips el-icon-question" })]
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ledConfInfo.show_time == 1,
                      expression: "ledConfInfo.show_time == 1",
                    },
                  ],
                  ref: "timeValidataForm_one",
                  attrs: {
                    model: _vm.timeValidataForm,
                    rules: _vm.timeValRule_one,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "ver-center" },
                    [
                      _c(
                        "el-col",
                        { attrs: { offset: 1, span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "hour_c" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  max: 100,
                                  min: 0,
                                  maxlength: "3",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.timeValidataForm.hour_c,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.timeValidataForm,
                                      "hour_c",
                                      $$v
                                    )
                                  },
                                  expression: "timeValidataForm.hour_c",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("时")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "min_c" } },
                            [
                              _c("el-input", {
                                attrs: { max: 59, min: 0, type: "number" },
                                model: {
                                  value: _vm.timeValidataForm.min_c,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.timeValidataForm, "min_c", $$v)
                                  },
                                  expression: "timeValidataForm.min_c",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("分钟")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "sec_c" } },
                            [
                              _c("el-input", {
                                attrs: { max: 59, min: 0, type: "number" },
                                model: {
                                  value: _vm.timeValidataForm.sec_c,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.timeValidataForm, "sec_c", $$v)
                                  },
                                  expression: "timeValidataForm.sec_c",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("秒")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.ledConfInfo.show_room_switch == 1
                ? _c(
                    "div",
                    { staticClass: "min-title m-g-t-15 m-g-b-15" },
                    [
                      _vm._v(
                        " 开盘" + _vm._s(_vm.customSetting.title) + "列表 "
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: "在活动开始前的指定时间出现",
                            effect: "dark",
                            placement: "right",
                          },
                        },
                        [_c("i", { staticClass: "tips el-icon-question" })]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ledConfInfo.show_room_switch == 1,
                      expression: "ledConfInfo.show_room_switch == 1",
                    },
                  ],
                  ref: "timeValidataForm_two",
                  attrs: {
                    model: _vm.timeValidataForm,
                    rules: _vm.timeValRule_two,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "ver-center" },
                    [
                      _c(
                        "el-col",
                        { attrs: { offset: 1, span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "hour_r" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  max: 100,
                                  min: 0,
                                  maxlength: "3",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.timeValidataForm.hour_r,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.timeValidataForm,
                                      "hour_r",
                                      $$v
                                    )
                                  },
                                  expression: "timeValidataForm.hour_r",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("时")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "min_r" } },
                            [
                              _c("el-input", {
                                attrs: { max: 59, min: 0, type: "number" },
                                model: {
                                  value: _vm.timeValidataForm.min_r,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.timeValidataForm, "min_r", $$v)
                                  },
                                  expression: "timeValidataForm.min_r",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("分钟")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "sec_r" } },
                            [
                              _c("el-input", {
                                attrs: { max: 59, min: 0, type: "number" },
                                model: {
                                  value: _vm.timeValidataForm.sec_r,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.timeValidataForm, "sec_r", $$v)
                                  },
                                  expression: "timeValidataForm.sec_r",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "center m-b-20 time-text",
                          attrs: { span: 1 },
                        },
                        [_vm._v("秒")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "over-setting" }, [
          _vm._m(1),
          _c(
            "div",
            { staticStyle: { "margin-left": "50px" } },
            [
              _c(
                "el-row",
                { staticClass: "over-setting-content" },
                [
                  _c(
                    "div",
                    { staticClass: "sub-title m-b-25" },
                    [
                      _vm._v(" 正式活动结束弹窗 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "right",
                            content:
                              "设置为不显示只对正式活动生效，测试活动必定会弹出结束提示！",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "tips el-icon-question",
                            staticStyle: { "margin-left": "5px" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "m-g-b-15", attrs: { offset: 1 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ledConfInfo.show_end,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ledConfInfo, "show_end", $$v)
                                },
                                expression: "ledConfInfo.show_end",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("显示"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不显示"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "over-setting-content",
                  staticStyle: { "padding-bottom": "140px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sub-title m-b-25",
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        "justify-content": "space-between",
                        "line-height": "35px",
                      },
                    },
                    [
                      _vm._v(" 活动结束弹窗样式 "),
                      _c(
                        "el-button",
                        {
                          staticClass: "btnHollowGreen",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.doGetBaseSetting(true)
                            },
                          },
                        },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        margin: "-10px 30px",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.ledActivityEndTemList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            width: "20%",
                            height: "138px",
                            overflow: "hidden",
                            position: "relative",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                width: "192px",
                                height: "138px",
                                "margin-left": "calc(50% - 96px)",
                              },
                              attrs: { label: item.id, border: "" },
                              model: {
                                value: _vm.ledConfInfo.img_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ledConfInfo, "img_id", $$v)
                                },
                                expression: "ledConfInfo.img_id",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-16px",
                                    "padding-left": "30px",
                                  },
                                },
                                [_vm._v("默认" + _vm._s(index + 1))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-15px",
                                    "text-align": "right",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "编辑",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "edit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onSetLedActivityEnd(
                                              item.id
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "1920px",
                                    height: "1080px",
                                    transform: "scale(0.1)",
                                    "margin-left": "-864px",
                                    "margin-top": "-486px",
                                    position: "absolute",
                                  },
                                },
                                [
                                  _c("setLedActivityEnd", {
                                    attrs: { isSet: false, Id: item.id },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        margin: "10px 30px",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.ledActivityEndList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            width: "20%",
                            height: "138px",
                            overflow: "hidden",
                            position: "relative",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: {
                                width: "192px",
                                height: "138px",
                                "margin-left": "calc(50% - 96px)",
                              },
                              attrs: { label: item.id, border: "" },
                              model: {
                                value: _vm.ledConfInfo.img_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ledConfInfo, "img_id", $$v)
                                },
                                expression: "ledConfInfo.img_id",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-16px",
                                    "padding-left": "30px",
                                  },
                                },
                                [_vm._v("自定义" + _vm._s(index + 1))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-15px",
                                    "text-align": "right",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "编辑",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "edit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onSetLedActivityEnd(
                                              item.id
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "删除",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onDelLedActivityEnd(
                                              item.id
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "1920px",
                                    height: "1080px",
                                    transform: "scale(0.1)",
                                    "margin-left": "-864px",
                                    "margin-top": "-486px",
                                    position: "absolute",
                                  },
                                },
                                [
                                  _c("setLedActivityEnd", {
                                    attrs: { isSet: false, Id: item.id },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.basePreview,
            title: "大屏显示预览",
            width: "100%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.basePreview = $event
            },
          },
        },
        [
          _c("div", { staticClass: "preview-content" }, [
            _c(
              "div",
              { staticClass: "preview-left" },
              [
                _c("screen-item", {
                  ref: "docItems",
                  staticClass: "houseItems",
                  attrs: {
                    "card-type": _vm.ledConfInfo.show_type,
                    "font-color": _vm.fontColor,
                    "header-color": _vm.bgColor,
                    "item-detail": _vm.itemDetail,
                    "led-conf-info": _vm.ledConfInfo,
                    "project-info": _vm.projectInfo,
                    "project-room-info": _vm.projectRoom,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "preview-right" },
              [
                _c("screen-right", {
                  attrs: {
                    "begin-time": 0,
                    "end-time": 100000,
                    "led-conf-info": _vm.ledConfInfo,
                    "card-type": "card",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.countDownPreview,
            title: "大屏显示倒计时预览",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.countDownPreview = $event
            },
            close: _vm.dialogClose,
          },
        },
        [
          _c("div", { staticClass: "preview-screen" }, [
            _c("div", { staticClass: "begin-countdown" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("div", { staticClass: "active-title" }, [
                  _vm._v("项目名称"),
                ]),
              ]),
              _c("div", { staticClass: "content-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "active-content" },
                  [
                    _vm.display == false
                      ? _c("span", { staticClass: "content-tip" }, [
                          _vm._v("距离开盘还有"),
                        ])
                      : _vm._e(),
                    _vm.display == true
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.saleValidataForm.saleText)),
                        ])
                      : _vm._e(),
                    _vm.display == false
                      ? _c(_vm.countDown, {
                          tag: "component",
                          attrs: {
                            "begin-time": 0,
                            "end-time":
                              (Number(_vm.timeValidataForm.hour * 3600) +
                                Number(_vm.timeValidataForm.min * 60) +
                                Number(_vm.timeValidataForm.sec)) *
                              1000,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imgPreviewDialogVisible,
            title: "图片预览",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgPreviewDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "img-wrapper" }, [
            _c("img", {
              attrs: { src: _vm.imgUrl + _vm.waitJson.imgSrc, alt: "" },
            }),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.imgPreviewDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sale-setting-top ver-center" }, [
      _c(
        "div",
        { staticClass: "sub-title-big m-g", staticStyle: { "--m-g-r": "15" } },
        [_vm._v("开盘前设置")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sale-setting-top ver-center" }, [
      _c(
        "div",
        { staticClass: "sub-title-big m-g", staticStyle: { "--m-g-r": "15" } },
        [_vm._v("结束设置")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }