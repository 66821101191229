//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SwitchBtn',
  props: {
    isShowSchedule: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      index: 0
    };
  },
  methods: {
    btnChange: function btnChange(index) {
      this.index = index;
      this.$emit('tabClick', this.index);
    }
  }
};