var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    !_vm.tableData || !_vm.tableData.length
      ? _c("div", { staticClass: "default-bg" }, [
          _vm._m(0),
          _c("div", { staticClass: "default-tip" }, [
            _vm._v("活动\\摇号环节尚未设置~"),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData && _vm.tableData.length,
            expression: "tableData && tableData.length",
          },
        ],
        staticClass: "page-header",
      },
      [
        _c(
          "div",
          { staticClass: "page-header-left" },
          [
            _c("event-select", {
              attrs: { "event-list": _vm.eventList, is_round: false },
              on: { select_round: _vm.selectRoundHandle },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "page-header-right" },
          [
            _c(
              "span",
              {
                on: {
                  mouseover: function ($event) {
                    return _vm.show()
                  },
                  mouseout: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "eventrand/setallrule"],
                        expression: "['admin', 'eventrand/setallrule']",
                      },
                    ],
                    staticClass: "orange-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.handleEditRule },
                  },
                  [_vm._v("摇号规则设置 ")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "eventrand/setplatetype"],
                    expression: "['admin', 'eventrand/setplatetype']",
                  },
                ],
                staticClass: "greens-btn mL10",
                attrs: { size: "small" },
                on: { click: _vm.handleSet },
              },
              [_vm._v("摇号画面设置 ")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "eventrand/setledstyle"],
                    expression: "['admin', 'eventrand/setledstyle']",
                  },
                ],
                staticClass: "greens-btn mL10",
                attrs: { size: "small" },
                on: { click: _vm.handlePublicitySet },
              },
              [_vm._v("公示画面设置 ")]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "block-bottom mT10" },
      [
        _vm._l(_vm.tableData, function (row, i) {
          return _c("list-item", {
            key: i,
            attrs: { row: row, config: _vm.list, "show-hint": _vm.showHint },
            on: {
              handleGrouping: _vm.handleGrouping,
              handleRecord: _vm.handleRecord,
              handlePrintUp: _vm.handlePrintUp,
              handleReset: _vm.handleReset,
              handleStartLottery: _vm.handleStartLottery,
              lotterySplitScreen: _vm.lotterySplitScreen,
              lotteryEntryIndex: _vm.lotteryEntryIndex,
              lotteryPublicity: _vm.lotteryPublicity,
            },
          })
        }),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.isGrouping,
              center: "",
              title: "摇号分组",
              width: "700px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.isGrouping = $event
              },
            },
          },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.selGroup,
                  "header-cell-style": { background: "#fafafa" },
                  border: "",
                  height: "250",
                  "highlight-current-row": "",
                  size: "medium",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "序号",
                    type: "index",
                    width: "50",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "组别", prop: "level" },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "状态", prop: "index" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status === 2
                            ? [_c("span", [_vm._v("已结束")])]
                            : [
                                scope.row.not_rand_user === 0
                                  ? [_c("span", [_vm._v("已结束")])]
                                  : [
                                      scope.row.rand_user_count === 0
                                        ? _c("span", [_vm._v("未开始")])
                                        : _c("span", [_vm._v("进行中")]),
                                    ],
                              ],
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "已摇号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(_vm._s(scope.row.rand_user_count) + "人"),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "待摇号" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(_vm._s(scope.row.not_rand_user) + "人")]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.status === 2
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mab10",
                                    attrs: {
                                      disabled:
                                        scoped.row.status === 2 ? true : false,
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEnd(scoped.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("结束本组摇号 ")]
                                ),
                              ]
                            : [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mab10",
                                    attrs: {
                                      disabled:
                                        scoped.row.not_rand_user === 0
                                          ? true
                                          : false,
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEnd(scoped.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("结束本组摇号 ")]
                                ),
                              ],
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium" },
                    on: {
                      click: function ($event) {
                        _vm.isGrouping = false
                      },
                    },
                  },
                  [_vm._v("关 闭")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.printUp.show,
              "close-on-click-modal": false,
              center: "",
              title: "打印摇号记录",
              width: "700px",
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.printUp, "show", $event)
              },
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "text-align": "right" } },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: "刷新数据",
                      effect: "dark",
                      placement: "top-end",
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { "class-name": "greens", "icon-class": "reset" },
                      on: {
                        click: function ($event) {
                          return _vm.doGetPrintRoundList()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                " 已到号" +
                  _vm._s(_vm.printUp.count) +
                  "轮" +
                  _vm._s(
                    _vm.printUp.selectIds.length > 0
                      ? "，已选" + _vm.printUp.selectIds.length + "轮"
                      : ""
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.printUp.loading,
                        expression: "printUp.loading",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      border: "",
                      size: "medium",
                      data: _vm.printUp.dataList,
                      "header-cell-style": { background: "#fafafa" },
                      height: "400px",
                      "highlight-current-row": "",
                      "element-loading-background": "rgba(0, 0, 0, 0.5)",
                      "element-loading-text": "数据正在加载中",
                      "element-loading-spinner": "el-icon-loading",
                    },
                    on: {
                      "selection-change": _vm.handlePrintUpSelection,
                      "cell-click": function (e) {
                        return _vm.toggleSelection([e])
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "selection",
                        width: "50",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "轮次" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scoped.row.level
                                      ? scoped.row.level + "-"
                                      : ""
                                  ) +
                                  _vm._s(scoped.row.num) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "摇出时间" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.doGetTime(+scoped.row.created_at * 1000)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "打印状态" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scoped.row.is_print === 0
                                      ? "未打印"
                                      : "已打印"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": _vm.sizes,
                    "page-size": _vm.printUp.limit,
                    "current-page": _vm.printUp.page,
                    "pager-count": 5,
                    layout: _vm.layouts,
                    total: _vm.printUp.count,
                  },
                  on: {
                    "size-change": _vm.handlePrintUpSize,
                    "current-change": _vm.handlePrintUpCurrente,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  display: "flex",
                  width: "50%",
                  margin: "0 auto",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btnHollowGreen mL10",
                    staticStyle: { flex: "1" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.printUp.show = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "eventrand/print"],
                        expression: "['admin', 'eventrand/print']",
                      },
                    ],
                    staticClass: "greens-btn mL10",
                    staticStyle: { "margin-left": "20px", flex: "3" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.handlePrintUp()
                      },
                    },
                  },
                  [_vm._v("打印 ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "default-img" }, [
      _c("img", {
        staticClass: "d-img",
        attrs: {
          alt: "还没有创建预留名单哦~",
          src: require("../../../assets/image/icon-qs.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }