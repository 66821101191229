import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import topTitle from '@/views/editFormat/components/title';
import imgUp from '@/views/editFormat/components/imgUp';
import { getLedStyle, setLedStyle } from '@/api/projectRound';
export default {
  name: 'publicitySet',
  components: {
    topTitle: topTitle,
    imgUp: imgUp
  },
  data: function data() {
    return {
      style: {
        title: {
          show: 1,
          top: 44,
          color: '#FFFFFF',
          fontSize: 50,
          bottom: 100
        },
        box: {
          opacity: 0.1,
          top: 30,
          round: {
            show: 1,
            color: '#FFFFFF',
            fontSize: 40,
            position: 'top',
            width: 0,
            top: 150
          },
          itemBox: {
            width: 1000,
            margin: 0,
            marginTop: 0,
            height: 100
          },
          item: {
            leftMargin: 10,
            topMargin: 10,
            topPadding: 10,
            numFontSize: 30,
            backgroundColor: '#FFFFFF',
            numColor: '#1D1D1D',
            numTop: 0,
            fontSize: 20,
            color: '#1D1D1D',
            lineHeight: 20,
            contentTop: 0
          }
        },
        logo: 'rand/lottery.png'
      },
      // 布局样式 一行显示几个轮次
      round_row_num: 1,
      // 公示模式  0：全部，1：最新模式   X   轮
      publicity_type: 0,
      // 最新模式   X   轮
      max_round_length: 3,
      // 每页展示轮次
      round_length: 10,
      // 每行展示数量
      row_num: 5,
      // 公示屏切换间隔 (秒)
      carousel_interval: 3,
      // 设置弹框
      isOpenDrawer: false,
      rand: {
        rand_count: 10
      },
      rand_name: ''
    };
  },
  watch: {
    'style.box.round.show': function styleBoxRoundShow(newVal, oldVal) {
      var _this = this;

      this.$nextTick(function () {
        _this.doGetWidthHeight();
      });
    }
  },
  mounted: function mounted() {
    this.doGetLedStyle();
  },
  methods: {
    // 获取公示大屏样式
    doGetLedStyle: function doGetLedStyle() {
      var _this2 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getLedStyle().then(function (res) {
        _this2.rand = res.data.data.rand; // 布局样式 一行显示几个轮次

        _this2.round_row_num = res.data.data.led_style.round_row_num; // 公示模式  0：全部，1：最新模式   X   轮

        _this2.publicity_type = res.data.data.led_style.publicity_type; // 最新模式   X   轮

        _this2.max_round_length = res.data.data.led_style.max_round_length; // 每页展示轮次

        _this2.round_length = res.data.data.led_style.round_length; // 每行展示数量

        _this2.row_num = res.data.data.led_style.row_num; // 公示屏切换间隔 (秒)

        _this2.carousel_interval = res.data.data.led_style.carousel_interval;
        _this2.style = res.data.data.led_style.style ? JSON.parse(res.data.data.led_style.style) : {
          title: {
            show: 1,
            top: 44,
            color: '#FFFFFF',
            fontSize: 50,
            bottom: 100
          },
          box: {
            opacity: 0.1,
            top: 30,
            round: {
              show: 1,
              color: '#FFFFFF',
              fontSize: 40,
              position: 'top',
              width: 0,
              top: 150
            },
            itemBox: {
              width: 1000,
              margin: 0,
              marginTop: 0,
              height: 100
            },
            item: {
              leftMargin: 10,
              topMargin: 10,
              topPadding: 10,
              numFontSize: 30,
              backgroundColor: '#FFFFFF',
              numColor: '#1D1D1D',
              numTop: 0,
              fontSize: 20,
              color: '#1D1D1D',
              lineHeight: 20,
              contentTop: 0
            }
          },
          logo: 'rand/lottery.png'
        };

        _this2.$nextTick(function () {
          _this2.doGetWidthHeight();
        });

        _this2.style.logo = _this2.style.logo || _this2.rand.logo || '/rand/lottery.png';
        _this2.rand_name = res.data.data.rand.name;
        loading.close();
      }).catch(function (res) {
        loading.close();
      });
    },
    // 获取宽高
    doGetWidthHeight: function doGetWidthHeight() {
      var _this3 = this;

      var round = document.getElementsByClassName('round');
      var box = document.getElementsByClassName('box');
      this.style.box.round.width = round[0] ? round[0].clientWidth : 0; // this.style.box.itemBox.width = box[0].scrollWidth - this.style.box.round.width;

      this.$nextTick(function () {
        _this3.style.box.itemBox.height = document.getElementsByClassName('box-content')[0].clientHeight || 0;
      });
    },
    //  恢复默认图片
    resetBeforeImg: function resetBeforeImg() {
      this.style.logo = this.rand.logo || '/rand/lottery.png';
    },
    // 保存成功
    upSuccess: function upSuccess(e) {
      this.style.logo = e.Data.url;
    },
    // 保存样式
    onSetLedStyle: function onSetLedStyle(key) {
      var _this4 = this;

      var data = {}; // 重置

      if (key === 0) {
        data = {
          style: {
            title: {
              show: 1,
              top: 44,
              color: '#FFFFFF',
              fontSize: 50,
              bottom: 100
            },
            box: {
              opacity: 0.1,
              top: 30,
              round: {
                show: 1,
                color: '#FFFFFF',
                fontSize: 40,
                position: 'top',
                width: 0,
                top: 150
              },
              itemBox: {
                width: 1000,
                margin: 0,
                marginTop: 0,
                height: 100
              },
              item: {
                leftMargin: 10,
                topMargin: 10,
                topPadding: 10,
                numFontSize: 30,
                backgroundColor: '#FFFFFF',
                numColor: '#1D1D1D',
                numTop: 0,
                fontSize: 20,
                color: '#1D1D1D',
                lineHeight: 20,
                contentTop: 0
              }
            },
            logo: this.rand.logo || '/rand/lottery.png'
          },
          // 布局样式 一行显示几个轮次
          round_row_num: 1,
          // 公示模式  0：全部，1：最新模式   X   轮
          publicity_type: 0,
          // 最新模式   X   轮
          max_round_length: 3,
          // 每页展示轮次
          round_length: 10,
          // 每行展示数量
          row_num: 5,
          // 公示屏切换间隔 (秒)
          carousel_interval: 3
        };
      } // 保存
      else {
        data = {
          // 布局样式 一行显示几个轮次
          round_row_num: this.round_row_num,
          // 公示模式  0：全部，1：最新模式   X   轮
          publicity_type: this.publicity_type,
          // 最新模式   X   轮
          max_round_length: this.max_round_length,
          // 每页展示轮次
          round_length: this.round_length,
          // 每行展示数量
          row_num: this.row_num,
          // 公示屏切换间隔 (秒)
          carousel_interval: this.carousel_interval,
          style: this.style
        };
      }

      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setLedStyle(data).then(function (res) {
        _this4.$message({
          type: 'success',
          message: res.data.msg
        });

        _this4.doGetLedStyle();

        loading.close();
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};