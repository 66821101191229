import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { activeSelect, addScreen, delScreen, getPersonNum, getScreenSetting, screenLists, setPersonNum, setScreenSetting } from '@/api/screen';
import { mapGetters } from 'vuex';
import { debounce } from '@/utils';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import { adminParameter } from '@/api/public';
export default {
  name: 'Screen',
  components: {
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      // container高度
      appHeight: '',
      topHeight: '',
      // 下拉框 数据
      options: [],
      // 下拉框 选中值
      selectValue: '',
      // 屏幕数量
      screenCount: '',
      // 表格数据
      tableData: [],
      // 控制人数弹框
      isCtrPersonShow: false,
      // 屏幕设置弹框
      isScreenShow: false,
      // 控制人数 表单
      ruleForm: {
        maxPerson: '',
        basePerson: ''
      },
      // 表单验证
      rules: {
        maxPerson: [{
          required: true,
          message: '请输入在线人数上限',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字'
        }],
        basePerson: [{
          required: true,
          message: '请输入在线人数基数',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字'
        }]
      },
      // 屏幕设置 房号选择
      roomList: [],
      // 屏幕设置 轮播
      radioCarousel: 0,
      // 轮播时长
      carousel_num: 5,
      // 屏幕设置 title
      title: '',
      // 屏幕设置 屏幕详情
      screenDetail: [],
      // 屏幕设置 房源详情
      screenHouseRes: [],
      // 服务器返回的勾选数据ID
      ledRoomListIds: [],
      // screenHouseResCopy: [],
      // 活动id 场次id
      eventID: '',
      roundID: '',
      // 设置弹框 是否全选
      isCheckAll: false,
      multipleSelection: [],
      // 在线人数
      onlineNum: '',
      // 在线人数控制
      onlineData: '',
      onlineConf: '',
      // 外显人数
      outlinePerson: '',
      // 别墅户数设置
      isShowVilaSetting: false,
      // 选择批次时间方式
      show_time_type: 0,
      select_event: {},
      // 选择的活动和场次id集
      eventList: [],
      // 项目活动列表
      // 平面图数据
      allMaplist: [],
      mapIds: [],
      isMap: false,
      projectList: {},
      tableLoad: false,
      isUpdateScreen: false,
      sp: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 24;
    },
    formatText: function formatText() {
      var str = '';

      if (this.projectList.cate_room && this.projectList.cate_car) {
        str = this.sp.cate_room_name + '&' + this.sp.cate_car_name;
      } else if (this.projectList.cate_room) {
        str = this.sp.cate_room_name;
      } else {
        str = this.sp.cate_car_name;
      }

      return str;
    },
    floorNumCount: function floorNumCount() {
      var count = 0;

      if (this.screenHouseRes.length) {
        for (var i = 0; i < this.screenHouseRes.length; i++) {
          if (this.screenHouseRes[i].isChecked) {
            count += this.screenHouseRes[i].floor_num;
          }
        }
      }

      return count;
    }
  }),
  watch: {
    // 控制人数 基数改变
    'ruleForm.basePerson': {
      handler: function handler(newVal, oldVal) {
        var maxPerson = this.ruleForm.maxPerson;
        var numberOnline = this.onlineData.numberOnline;
        var online_max = this.onlineConf.online_max;
        var online_min = this.onlineConf.online_min;
        var true_num = numberOnline - online_min;

        if (newVal + true_num > maxPerson) {
          this.outlinePerson = maxPerson;
        } else if (newVal <= maxPerson) {
          this.outlinePerson = newVal + true_num;
        }
      },
      immediate: false
    },
    // 控制人数 最大
    'ruleForm.maxPerson': {
      handler: function handler(newVal, oldVal) {
        var outlinePerson = this.outlinePerson;
        var basePerson = this.ruleForm.basePerson;
        var numberOnline = this.onlineData.numberOnline;
        var online_max = this.onlineConf.online_max;
        var online_min = this.onlineConf.online_min;
        var true_num = numberOnline - online_min;

        if (newVal <= basePerson) {
          this.outlinePerson = newVal;
        } else if (newVal >= basePerson) {
          this.outlinePerson = basePerson + true_num;
        }
      },
      immediate: false
    },
    'screenDetail.show_count': {
      handler: function handler(newVal, oldVal) {
        if (newVal == 1) {
          this.screenDetail.show_cate = 0;
        }
      }
    },
    'screenDetail.show_cate': {
      handler: function handler(newVal, oldVal) {
        if (newVal == 1) {
          this.screenDetail.show_count = 0;
        }
      }
    },
    tableData: {
      handler: function handler() {
        var _this = this;

        this.$nextTick(function () {
          var mores = document.getElementsByClassName('more') || [];
          var textContainer = document.getElementsByClassName('textContainer');

          for (var i = 0; i < mores.length; i++) {
            var threeHeight = mores[i].scrollHeight * 3 || 99;
            var h = textContainer[i].scrollHeight;
            _this.tableData.ledList[i].isShowZK = h > threeHeight ? 1 : 0;
          }

          _this.tableData.ledList = JSON.parse(JSON.stringify(_this.tableData.ledList));
        });
      }
    },
    screenHouseRes: {
      handler: function handler() {
        var _this2 = this;

        if (!this.isUpdateScreen) {
          this.$nextTick(function () {
            var mores = document.getElementsByClassName('moreRoom') || [];
            var textContainer = document.getElementsByClassName('textContainerRoom');

            for (var i = 0; i < mores.length; i++) {
              var threeHeight = mores[i].scrollHeight * 3 || 99;
              var h = textContainer[i].scrollHeight;
              _this2.screenHouseRes[i].isShowZK = h > threeHeight ? 1 : 0;
            }

            _this2.isUpdateScreen = true;
            _this2.screenHouseRes = JSON.parse(JSON.stringify(_this2.screenHouseRes));
          });
        } else {
          this.isUpdateScreen = false;
        }
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    // 表格高度自适应
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this3.$refs.app.offsetHeight;
      that.topHeight = _this3.$refs.apptop.offsetHeight;
    }; // ----------接口相关---------------
    // 下拉选项


    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this3.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    adminParameter().then(function (res) {
      // 全局配置
      _this3.projectList = res.data.list;
      _this3.sp = res.data.sp;
    });
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 大屏右侧组件自定义
    rightSetting: function rightSetting(scope) {
      var id = scope.row.ledInfo.id;
      var project_id = scope.row.ledInfo.project_id;

      var routeParams = _objectSpread({
        project_id: project_id,
        id: id
      }, this.select_event);

      var path = this.$router.resolve({
        path: '/rightSetting',
        query: routeParams
      });
      window.open("".concat(path.href), '_blank');
    },
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };

      if (this.roles.includes('led/list') || this.roles.includes('admin')) {
        this.getScreenLists();
      }
    },
    // 大屏数量增加
    screenCountChange: debounce(function () {
      this.addScreenEvent();
    }, 1000, true),
    // 大屏
    screenShow: function screenShow(scope) {
      var id = scope.row.ledInfo.id;
      var project_id = scope.row.ledInfo.project_id;

      var routeParams = _objectSpread({
        project_id: project_id,
        id: id
      }, this.select_event);

      var path = this.$router.resolve({
        path: '/screenShow',
        query: routeParams
      });
      var win = window.open("".concat(path.href), '_blank');
    },
    // 大屏拖拽
    drag: function drag(scope) {
      var id = scope.row.ledInfo.id;
      var project_id = scope.row.ledInfo.project_id;

      var routeParams = _objectSpread({
        project_id: project_id,
        id: id
      }, this.select_event);

      var path = this.$router.resolve({
        path: '/dragScreen',
        query: routeParams
      });
      window.open("".concat(path.href), '_blank');
    },
    // 控制在线人数
    ctrPerson: function ctrPerson() {
      var _this4 = this;

      var params = _objectSpread({}, this.select_event);

      getPersonNum(params).then(function (res) {
        _this4.onlineData = res.data;
        _this4.onlineConf = res.data.ledConfInfo;

        if (!res.data.ledConfInfo.online_max) {
          _this4.onlineConf.online_max = 99999;
        }

        if (!res.data.ledConfInfo.online_min) {
          _this4.onlineConf.online_min = 0;
        }

        _this4.ruleForm.maxPerson = res.data.ledConfInfo.online_max || 99999;
        _this4.ruleForm.basePerson = res.data.ledConfInfo.online_min || 0;
        _this4.onlineNum = res.data.numberOnline;
        _this4.tableData.numberOnline = res.data.numberOnline;
      });
      this.isCtrPersonShow = true;
    },
    // 控制在线人数弹框 保存
    submit: debounce(function (formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var params = {
            online_max: _this5.ruleForm.maxPerson,
            online_min: _this5.ruleForm.basePerson
          };

          if (_this5.ruleForm.maxPerson >= _this5.ruleForm.basePerson) {
            setPersonNum(params).then(function (res) {
              if (res.status == 200) {
                _this5.$message({
                  type: 'success',
                  message: res.data.msg
                });

                _this5.isCtrPersonShow = false;

                _this5.getScreenLists();
              } else {
                _this5.$message({
                  type: 'warning',
                  message: '修改失败！'
                });
              }
            });
          } else {
            _this5.$message({
              type: 'warning',
              message: '在线人数基数不能大于在线人数上限'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 1000, true),
    // 屏幕设置弹框
    screenSave: debounce(function (type) {
      var _this6 = this;

      var list = [];
      this.screenHouseRes.forEach(function (item, i) {
        var no_ids = [];

        if (item.isChecked == true) {
          if (item.noList != undefined) {
            item.noList.forEach(function (listItem) {
              if (listItem != undefined) {
                if (listItem.isChecked == true) {
                  no_ids.push(listItem.id);
                }
              }
            }); // list.push({
            //   build_id: item.build_id,
            //   unit_id: item.id,
            //   no_ids: no_ids
            // })

            item.no_ids = no_ids;
            item.floor_num = item.is_floor_num0 ? 0 : parseInt(item.floor_num);
            if (_this6.ledRoomListIds.indexOf(item.build_id + '_' + item.unit_id) < 0) item.id = 0;
            list.push(item);
          }
        }
      });
      var params = {
        id: this.screenDetail.id,
        is_banner: this.radioCarousel,
        // 显示轮播
        carousel_num: this.carousel_num || 5,
        // 轮播时长
        show_cate: this.screenDetail.show_cate,
        // 户型展示
        show_count: this.screenDetail.show_count,
        // 选房流水
        show_room: this.screenDetail.show_room,
        // 房源列表
        show_title: this.screenDetail.show_title,
        // 项目名称
        show_online: this.screenDetail.show_online,
        // 在线人数
        show_event_time: this.screenDetail.show_event_time,
        // 活动倒计时
        show_sale: this.screenDetail.show_sale,
        // 可售数量
        show_amount: this.screenDetail.show_amount,
        // 销售额
        show_now_time: this.screenDetail.show_now_time,
        // 销售额
        show_batch_time: this.screenDetail.show_batch_time,
        // 销售额
        show_time_type: this.show_time_type,
        // 显示
        title: this.screenDetail.title,
        // 项目名称
        is_map: this.isMap ? 1 : 0,
        maps: this.mapIds.join(','),
        list: list
      };

      if (this.isMap) {
        if (!this.mapIds.length) {
          this.$message({
            type: 'warning',
            message: '请选择平面图！'
          });
          return;
        }
      } else {
        var isPass = 0;

        if (type == 1) {
          list && list.forEach(function (element) {
            if (element.cate === 2 && (isNaN(element.floor_num) || element.floor_num <= 0)) {
              isPass = 1;
            } else if (element.cate === 1 && (isNaN(element.floor_num) || element.floor_num < 0)) {
              isPass = 2;
            }
          });
        }

        if (isPass === 1) {
          this.$message({
            type: 'warning',
            message: '车位区域户数不能为空和0'
          });
          return;
        } else if (isPass === 2) {
          this.$message({
            type: 'warning',
            message: '房源区域户数不能为空或者小于0'
          });
          return;
        }
      } //  调用接口


      setScreenSetting(params).then(function (res) {
        if (res.status == 200) {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });

          if (type == 0) {
            _this6.isScreenShow = false;
          } else {
            _this6.isShowVilaSetting = false;
          }

          _this6.getScreenLists();
        } else {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    }, 1000, true),
    // 屏幕设置 房号改变
    noListChange: function noListChange(e) {},
    // 屏幕设置
    screenSetting: function screenSetting(id, scope) {
      var _this7 = this;

      this.screenHouseRes = [];
      this.isCheckAll = false;
      this.isScreenShow = true;
      var params = {
        id: id
      };
      getScreenSetting(params).then(function (res) {
        var screenDetail = res.data.ledInfo; // 屏幕设置详情

        var screenHouseRes = res.data.sourceList; // 房源列表

        var screenHouseList = JSON.parse(JSON.stringify(res.data.ledRoomList)); // 勾选房源列表

        _this7.radioCarousel = screenDetail.is_banner; // 是否显示轮播

        _this7.carousel_num = screenDetail.carousel_num || 5; // 轮播时长

        _this7.show_time_type = screenDetail.show_time_type;
        _this7.title = "\u8BBE\u7F6E".concat(scope.$index + 1, "\u53F7\u5C4F\u5E55").concat(_this7.sp.cate_room_name, "\u548C").concat(_this7.sp.cate_car_name); // 弹框标题

        _this7.screenDetail = screenDetail; // 平面图设置

        if (_this7.projectList.car_list_show_type !== 1) {
          _this7.mapIds = screenDetail.maps ? screenDetail.maps.split(',') : [];
          _this7.isMap = screenDetail.is_map === 1;
          _this7.allMaplist = res.data.maplist || [];
        } else {
          _this7.mapIds = [];
          _this7.isMap = false;
          _this7.allMaplist = [];
        }

        if (screenHouseList == undefined) {
          // 未勾选任何房源
          // 处理数据
          screenHouseRes.forEach(function (element) {
            element.isChecked = false;

            if (element.cate === 1) {
              element.floor_num = 0;
            } else {
              element.floor_num = 6;
            }

            if (element.noList != undefined) {
              element.noList.forEach(function (item) {
                item.isChecked = false;
              });
            }

            if (element.floor_num) element.is_floor_num0 = false;else element.is_floor_num0 = true;
          });
          _this7.screenHouseRes = screenHouseRes; // this.screenHouseResCopy = JSON.parse(JSON.stringify(screenHouseRes))
        } else {
          screenHouseRes.forEach(function (element) {
            element.isChecked = false;

            if (element.cate === 1) {
              element.floor_num = 0;
            } else {
              element.floor_num = 6;
            }

            screenHouseList.forEach(function (item) {
              // item.isChecked = false
              if (element.build_id == item.build_id && element.id == item.unit_id) {
                element.isChecked = true;
                var ids = item.no_ids;

                if (element.noList != undefined && ids != undefined) {
                  element.noList.forEach(function (no_item) {
                    if (ids.includes(no_item.id)) {
                      no_item.isChecked = true;
                    }
                  });
                }

                element = Object.assign(element, item);
              }
            });
            if (element.floor_num) element.is_floor_num0 = false;else element.is_floor_num0 = true;
          });
          _this7.screenHouseRes = screenHouseRes; // this.screenHouseResCopy = JSON.parse(JSON.stringify(screenHouseRes))
        }

        for (var i = 0; i < (res.data.ledRoomList || []).length; i++) {
          _this7.ledRoomListIds.push(res.data.ledRoomList[i].build_id + '_' + res.data.ledRoomList[i].unit_id);
        }
      });
    },
    // 跳转基础设置
    toBasic: function toBasic() {
      this.$router.push('basicsetting');
    },
    // 全选
    checkAllChange: function checkAllChange(e) {
      if (this.screenHouseRes != undefined) {
        this.screenHouseRes.forEach(function (item) {
          item.isChecked = e;

          if (item.noList != undefined) {
            item.noList.forEach(function (items) {
              items.isChecked = e;
            });
          }
        });
        this.screenHouseRes = JSON.parse(JSON.stringify(this.screenHouseRes)); // this.screenHouseResCopy = JSON.parse(JSON.stringify(this.screenHouseRes))
      }
    },
    // 接口相关-----------------
    // 屏幕列表
    getScreenLists: function getScreenLists() {
      var _this8 = this;

      this.tableLoad = true;

      if (this.roles.includes('led/list') || this.roles.includes('admin')) {
        var params = _objectSpread({}, this.select_event);

        screenLists(params).then(function (res) {
          var tableData = res.data;
          _this8.tableData = tableData;

          if (tableData.ledList == null) {
            _this8.screenCount = 0;
          } else {
            _this8.screenCount = tableData.ledList.length;
          }
        }).finally(function () {
          _this8.tableLoad = false;
        });
      }
    },
    // 添加屏幕
    addScreenEvent: function addScreenEvent() {
      var _this9 = this;

      var params = _objectSpread({}, this.select_event);

      addScreen(params).then(function (res) {
        if (res.status == 200) {
          _this9.$message({
            type: 'success',
            message: res.data.msg
          });

          _this9.getScreenLists();
        } else {
          _this9.$message({
            type: 'warning',
            message: res.data.msg
          });
        }
      });
    },
    // 删除屏幕
    delScreenEvent: function delScreenEvent(id) {
      var _this10 = this;

      this.$confirm('删除当前屏幕, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          id: id
        };
        delScreen(param).then(function (res) {
          if (res.status == 200) {
            _this10.$message({
              type: 'success',
              message: res.data.msg
            });

            _this10.getScreenLists();
          } else {
            _this10.$message({
              type: 'warning',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        return;
      });
    },
    // 屏幕设置 楼栋单元点击 全选
    screenSettingUnitChange: function screenSettingUnitChange(uid, bid) {
      var id = uid;
      var build_id = bid;
      var screenHouseRes = this.screenHouseRes;
      screenHouseRes.forEach(function (element) {
        if (element.build_id == build_id && element.id == id) {
          if (element.isChecked) {
            element.isChecked = !element.isChecked;
            element.noList.forEach(function (item) {
              item.isChecked = false;
            });
          } else {
            element.isChecked = !element.isChecked;
            element.noList.forEach(function (item) {
              item.isChecked = true;
            });
          }
        }
      });
      this.screenHouseRes = JSON.parse(JSON.stringify(screenHouseRes)); // this.screenHouseResCopy = JSON.parse(JSON.stringify(screenHouseRes))
    },
    // 房间号点击 选中楼栋单元
    roomNumClick: function roomNumClick(scope, ischecked) {
      var isChecked = ischecked;
      var build_id = scope.row.build_id;
      var id = scope.row.id;
      var screenHouseRes = this.screenHouseRes;

      if (isChecked) {
        screenHouseRes.forEach(function (element) {
          if (element.build_id == build_id && element.id == id) {
            element.isChecked = true;
          }
        });
      }

      this.screenHouseRes = JSON.parse(JSON.stringify(screenHouseRes)); // this.screenHouseResCopy = JSON.parse(JSON.stringify(screenHouseRes))
    },
    // 退出设置区域户数
    quitVilaSetting: function quitVilaSetting() {},
    // 展开或者收起
    onBtnIsShowZK: function onBtnIsShowZK(index, isShowZK) {
      this.tableData.ledList[index].isShowZK = isShowZK;
      this.tableData.ledList = JSON.parse(JSON.stringify(this.tableData.ledList));
    },
    // 屏幕设置展开或者收起
    onBtnRoomIsShowZK: function onBtnRoomIsShowZK(index, isShowZK) {
      this.isUpdateScreen = true;
      this.screenHouseRes[index].isShowZK = isShowZK;
      this.screenHouseRes = JSON.parse(JSON.stringify(this.screenHouseRes));
    }
  }
};