/**
 * @author yang
 * @time 2021/4/22
 * @return {api} 大屏显示接口
 * */
import request from '@/utils/request'; //  --------------选房记录接口-------------------
// 项目配置

export function getAdminParameter(params) {
  return request({
    url: '/auth/parameter',
    method: 'get',
    params: params
  });
} //  选房记录列表

export function getHistoryLists(data) {
  return request({
    url: '/Order/index',
    method: 'post',
    data: data
  });
} // 模拟发起签约

export function appSignUp(data) {
  return request({
    url: '/Order/appSignUp',
    method: 'post',
    data: data
  });
} // 订单详情

export function getOrderDetail(data) {
  return request({
    url: '/Order/detail',
    method: 'post',
    data: data
  });
} // 订单详情编辑付款方式

export function editPayInfo(data) {
  return request({
    url: '/Order/editPayInfo',
    method: 'post',
    data: data
  });
} // 订单详情编辑优惠类型

export function editYouhuiType(data) {
  return request({
    url: '/Order/editYouHui',
    method: 'post',
    data: data
  });
} // 保存视频

export function saveVideo(data) {
  return request({
    url: '/Order/saveVideo',
    method: 'POST',
    data: data
  });
} //  选房记录列表

export function getUserList(data) {
  return request({
    url: '/Order/Search',
    method: 'POST',
    data: data
  });
} // 楼栋 单元 户型

export function getProjectMes(params) {
  return request({
    url: '/project/configure',
    method: 'get',
    params: params
  });
} // 打印认购书

export function printingOrder(params) {
  return request({
    url: '/Order/printing',
    method: 'post',
    data: params
  });
} // 释放房源

export function releaseOrder(params) {
  return request({
    url: '/Order/release',
    method: 'get',
    params: params
  });
} // 签约确认

export function signOrder(data) {
  return request({
    url: '/Order/sign',
    method: 'post',
    data: data
  });
} // 打印和签约提示

export function selmsgOrder(params) {
  return request({
    url: '/Order/selMsg',
    method: 'get',
    params: params
  });
} // 定金确认

export function orderDeposit(params) {
  return request({
    url: '/Order/deposit',
    method: 'get',
    params: params
  });
} // 计算定金

export function orderCompute(params) {
  return request({
    url: 'Order/compute',
    method: 'get',
    params: params
  });
}
export function exportChooseHistory(data) {
  return request({
    url: '/Order/export',
    method: 'post',
    data: data
  });
}
export function sync(data) {
  return request({
    url: 'Order/sync',
    method: 'post',
    data: data
  });
}
export function getPayInfo(params) {
  return request({
    url: '/Order/getPayInfo',
    method: 'post',
    data: params
  });
}
export function smsOrder(data) {
  return request({
    url: '/Order/smsOrder',
    method: 'post',
    data: data
  });
}