import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapMutations } from 'vuex';
export default {
  name: 'items',
  props: {
    type: {
      //组件类型 三大类
      type: String,
      default: ''
    },
    type2: {
      //组件类型 小类
      type: String,
      default: ''
    },
    title: {
      //次选组件标题
      type: String,
      default: ''
    },
    subTitle: {
      //首选组件标题
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    parentIndex: {
      type: Number,
      default: 0
    },
    children: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flex1: {
      //组件是否沾满剩余空间
      type: Boolean,
      default: false
    },
    color: {
      type: [Boolean, Object],
      default: function _default() {
        return {};
      }
    },
    eventStart: {
      type: Boolean,
      default: false
    },
    isSlot: {
      //
      type: Boolean,
      default: false
    },
    batchCurrent: {
      //批次
      type: String,
      default: ''
    },
    lineBreak: {
      //换行 显示 当前批次和接下来三个批次 老板提的shi需求
      type: Boolean,
      default: false
    },
    lineBreaTitle: {
      //换行 显示 当前批次和接下来三个批次 老板提的shi需求
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['EDIT_SUB_COMMONT'])), {}, {
    isChinese: function isChinese(str) {
      return /[\u4e00-\u9fa5]/.test(str);
    },
    childeClk: function childeClk(item) {
      //容器子组件点击
      this.EDIT_SUB_COMMONT({
        parentIndex: this.parentIndex,
        title: item.title
      });
    }
  })
};