var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "app", staticClass: "container" }, [
    _c(
      "div",
      { ref: "top", staticClass: "search-box" },
      [
        _c(
          "el-form",
          { ref: "formData", attrs: { model: _vm.search } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 5 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "轮次",
                          prop: "round_id",
                          "label-width": "50px",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                            },
                            model: {
                              value: _vm.search.round_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "round_id", $$v)
                              },
                              expression: "search.round_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 0, label: "全部" },
                            }),
                            _vm._l(_vm.roundInfo, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { value: item.id, label: item.num },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "flex", attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "reach" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.search.reach,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "reach", $$v)
                              },
                              expression: "search.reach",
                            },
                          },
                          [_vm._v("未到号")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mL10", attrs: { prop: "not_reach" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.search.not_reach,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "not_reach", $$v)
                              },
                              expression: "search.not_reach",
                            },
                          },
                          [_vm._v("已到号")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mL10", attrs: { prop: "is_admission" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.search.is_admission,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "is_admission", $$v)
                              },
                              expression: "search.is_admission",
                            },
                          },
                          [_vm._v("未入场 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 3, offset: 5 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "search" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "输入姓名或手机号查询",
                          },
                          model: {
                            value: _vm.search.search,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "search", $$v)
                            },
                            expression: "search.search",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 3 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "greens-btn",
                        attrs: { size: "small" },
                        on: { click: _vm.getTableInfo },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowReset",
                        attrs: { size: "small" },
                        on: { click: _vm.resetForm },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "block-bottom mT16" }, [
      _c("div", { staticClass: "button-box flex space-between" }, [
        _c("div", [
          _c("div", { staticClass: "title flex align-items" }, [
            _c("span", { staticClass: "buttonStyle" }, [
              _vm._v(_vm._s(_vm.getButtonText(_vm.event.type))),
            ]),
            _c("span", { staticClass: "mL10" }, [
              _vm._v(_vm._s(_vm.event.name)),
            ]),
          ]),
          _c("div", { staticClass: "tips flex mT15 align-items" }, [
            _vm.event.event_status === 0
              ? _c("div", { staticClass: "status" }, [_vm._v("未开始")])
              : _vm._e(),
            _vm.event.event_status === 1
              ? _c("div", { staticClass: "status" }, [_vm._v("进行中")])
              : _vm._e(),
            _vm.event.event_status === 2
              ? _c("div", { staticClass: "status" }, [_vm._v("已结束")])
              : _vm._e(),
            _vm.event.round === 1
              ? _c("div", { staticClass: "time mL10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("dateFormat")(_vm.event.round_start_time * 1000)
                      ) +
                      " ~ " +
                      _vm._s(
                        _vm._f("dateFormat")(_vm.event.round_end_time * 1000)
                      ) +
                      " "
                  ),
                ])
              : _c("div", { staticClass: "time mL10" }, [
                  _vm._v(
                    _vm._s(_vm._f("dateFormat")(_vm.event.start_time * 1000)) +
                      " ~ " +
                      _vm._s(_vm._f("dateFormat")(_vm.event.end_time * 1000))
                  ),
                ]),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleExport },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                "highlight-current-row": "",
                size: "medium",
                data: _vm.tableData,
                height: _vm.tableHeight,
                "header-cell-style": { background: "#fafafa" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "序号",
                  type: "index",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "log_status",
                  label: "排号状态",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "轮次 - 序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scoped) {
                      return [
                        _c("span", [
                          _vm._v(
                            "第 " +
                              _vm._s(scoped.row.round_num) +
                              " 轮 - " +
                              _vm._s(scoped.row.code) +
                              " 号"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "name", label: "姓名" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "tel", label: "登录手机号" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "cy_code",
                  label: _vm.$adminList.user_code_name,
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "card", label: "身份证号" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "rc_status",
                  label: "入场情况",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": _vm.sizes,
              "page-size": 10,
              layout: _vm.layouts,
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }