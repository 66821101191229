var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tableStyle" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "span-method": _vm.arraySpanMethod,
            "row-class-name": _vm.isCell ? _vm.setCellClass : "",
            border: "",
          },
          on: {
            "cell-mouse-enter": _vm.handleMouse,
            "cell-mouse-leave": _vm.handleMouseLeve,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "num", label: "序号", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "活动名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "场次名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "场次时间",
              align: "center",
              width: "450",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("dateFormat")(scope.row.start_time * 1000)
                        ) +
                        " - " +
                        _vm._s(
                          _vm._f("dateFormat")(scope.row.end_time * 1000)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "room_count", align: "center", label: "房源数量" },
          }),
          _c(
            "el-table-column",
            { attrs: { label: "操作", align: "center" } },
            [
              [
                _c(
                  "el-button",
                  { staticClass: "orange-btn", attrs: { size: "mini" } },
                  [_vm._v("关联客户")]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }