var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "linkswicth" },
    [
      _vm.from.length && _vm.from.length > 1 && _vm.type == "from"
        ? [
            _c(
              "el-radio-group",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  fill: "#22A271",
                  "text-color": "#FFFFFF",
                  size: "small",
                },
                on: { change: _vm.swicthForm },
                model: {
                  value: _vm.linkVal,
                  callback: function ($$v) {
                    _vm.linkVal = $$v
                  },
                  expression: "linkVal",
                },
              },
              _vm._l(_vm.from, function (item, index) {
                return _c(
                  "el-radio-button",
                  { key: index + "2", attrs: { label: item.type } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm.pay.length && _vm.pay.length > 1 && _vm.type == "pay"
        ? [
            _c(
              "el-radio-group",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  fill: "#22A271",
                  "text-color": "#FFFFFF",
                  size: "small",
                },
                on: { change: _vm.swicthForm },
                model: {
                  value: _vm.linkVal,
                  callback: function ($$v) {
                    _vm.linkVal = $$v
                  },
                  expression: "linkVal",
                },
              },
              _vm._l(_vm.pay, function (item, index) {
                return _c(
                  "el-radio-button",
                  { key: index + "2", attrs: { label: item.type } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm.sign.length && _vm.sign.length > 1 && _vm.type == "sign"
        ? [
            _c(
              "el-radio-group",
              {
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  fill: "#22A271",
                  "text-color": "#FFFFFF",
                  size: "small",
                },
                on: { change: _vm.swicthForm },
                model: {
                  value: _vm.linkVal,
                  callback: function ($$v) {
                    _vm.linkVal = $$v
                  },
                  expression: "linkVal",
                },
              },
              _vm._l(_vm.sign, function (item, index) {
                return _c(
                  "el-radio-button",
                  { key: index + "2", attrs: { label: item.type } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }