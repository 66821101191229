/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-06-17 09:33,
 */
import request from '@/utils/request';
import qs from 'qs';
export default {
  // 获取房源列表
  roomIndex: function roomIndex(data) {
    return request({
      url: '/RoomStatus/index',
      method: 'post',
      data: data
    });
  },
  // 获取 楼栋单元户型
  configure: function configure(params) {
    return request({
      url: '/project/configure',
      method: 'get',
      params: params
    });
  },
  // 获取 别墅区域
  villaArea: function villaArea(params) {
    return request({
      url: '/project/villaArea',
      method: 'get',
      params: params
    });
  },
  // 获取 客户状态列表
  customLists: function customLists(data) {
    return request({
      url: '/UserStatus/index',
      method: 'post',
      data: data
    });
  },
  // 获取 别墅区域
  roomExport: function roomExport(data) {
    return request({
      url: '/RoomStatus/export',
      method: 'post',
      data: data
    });
  },
  // 导出 客户状态列表
  exportCustomLists: function exportCustomLists(data) {
    return request({
      url: '/UserStatus/export',
      method: 'post',
      data: data
    });
  },
  // 客户详情 关注情况 活动数据
  customDetail: function customDetail(params) {
    return request({
      url: '/User/details',
      method: 'get',
      params: params
    });
  },
  // 批量禁用启用
  disableEnableAll: function disableEnableAll(data) {
    return request({
      url: 'UserStatus/disableEnableAll',
      method: 'post',
      data: data
    });
  },
  // 批量登录
  login: function login(data) {
    return request({
      url: '/UserStatus/login',
      method: 'post',
      data: data
    });
  },
  // 批量登录
  joins: function joins(data) {
    return request({
      url: '/UserStatus/join',
      method: 'post',
      data: data
    });
  }
};