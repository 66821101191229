var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cell-group-container",
      style: { "--cell-background": _vm.bg },
    },
    [
      _c(
        "div",
        { staticClass: "cell-group-title" },
        [_vm._t("title", [_vm._v(" " + _vm._s(_vm.title))])],
        2
      ),
      _c("div", { staticClass: "cell-group-content" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }