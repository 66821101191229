import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { adminParameter } from '@/api/public';
export default {
  data: function data() {
    return {
      projectInfo: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    adminParameter().then(function (res) {
      _this.projectInfo = _objectSpread({}, res.data.list);
    });
  },
  methods: {
    startChoose: function startChoose() {
      var path = this.$router.resolve({
        path: '/assistHouse'
      });
      window.open("".concat(path.href), '_blank');
    }
  }
};