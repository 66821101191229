//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
export default {
  name: 'info',
  data: function data() {
    return {
      isPC: utils.isPC()
    };
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  methods: {
    doformatDate: function doformatDate(date, format) {
      return utils.formatDate(new Date(date), format);
    }
  }
};