var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-box",
      style: { "--width": _vm.width, "--height": _vm.height },
    },
    [
      _c("div", { ref: "wrapper", staticClass: "wrapper" }, [
        _c("div", { staticClass: "unit", attrs: { "data-id": _vm.id } }, [
          _c("div", { staticClass: "unit-title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "unit-wrapper" }, [
            _c("div", {
              ref: "car-map-container",
              staticClass: "car-map-container",
            }),
          ]),
          _c("div", {
            attrs: { id: "car-right-drag" },
            on: {
              mousedown: function (e) {
                return _vm.mousedown(e, "R")
              },
            },
          }),
          _c("div", {
            attrs: { id: "car-bottom-drag" },
            on: {
              mousedown: function (e) {
                return _vm.mousedown(e, "B")
              },
            },
          }),
          _c("div", {
            attrs: { id: "car-r-b-drag" },
            on: {
              mousedown: function (e) {
                return _vm.mousedown(e, "RB")
              },
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }