var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user_audit" }, [
    _c("div", { staticClass: "user" }, [
      _vm._m(0),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "row" }, [
          _vm._v(" " + _vm._s(_vm.nowUser.name || "未知客户") + " "),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._v("手机号：" + _vm._s(_vm.nowUser.tel)),
        ]),
        _vm.nowUser.value
          ? _c("div", { staticClass: "row" }, [
              _vm._v("身份证号：" + _vm._s(_vm.nowUser.value)),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm.showAudit
      ? _c(
          "div",
          { staticClass: "audit" },
          [
            [
              _c("auditState", {
                attrs: { auditType: 0, state: _vm.nowUser.register_status },
              }),
            ],
            _vm.project.list.is_identify === 1
              ? [
                  _c("auditState", {
                    attrs: { auditType: 1, state: _vm.nowUser.identify_status },
                  }),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.showOwner && _vm.nowUser.owner
      ? _c(
          "div",
          { staticClass: "owner_list" },
          _vm._l(_vm.nowUser.owner, function (item, index) {
            return _c("div", { key: index, staticClass: "owner_list_item" }, [
              _vm._v(
                " 产权人" + _vm._s(index + 1) + " : " + _vm._s(item.real_name)
              ),
              item.card
                ? _c("span", [_vm._v("(" + _vm._s(item.card) + ")")])
                : _vm._e(),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar" }, [
      _c("img", { attrs: { src: require("@/assets/image/avatar_icon.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }