var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-user-box" }, [
    _c("img", {
      style: _vm.bgStyle,
      attrs: { src: require("./bg.png"), alt: "背景" },
    }),
    _c("img", {
      staticClass: "search-user-search_icon",
      style: _vm.searchStyle,
      attrs: { src: require("./search.png"), alt: "搜索按钮" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }