import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Api from '../../../api/rowNumber';
import { debounce } from '@/utils/debounce';
import utils from '@/utils/utils';
export default {
  name: 'NumberAnnal',
  data: function data() {
    return {
      // 数据走哪条数
      total: 0,
      // 当前每页条数
      pageSize: 10,
      // 当前页
      page: 1,
      // 轮次数组
      roundInfo: [],
      // 是否有组别
      is_level: false,
      // 搜索提交
      search: {
        round_id: 0,
        reach: 0,
        is_admission: 0,
        search: '',
        level_id: 0,
        not_reach: 0
      },
      // table 高度
      appHeight: 0,
      // 搜索烂高度
      topHeight: 0,
      // 表格数据
      tableData: [],
      // 打开分组弹框
      isGrouping: false,
      // 分组列表
      selGroup: [],
      // 页面 活动数据
      event: {},
      activity: {
        project: {},
        batch: {}
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 165;
    }
  },
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化页面
  created: function created() {
    this.getTableInfo();
  },
  methods: {
    // 导出数据
    handleExport: function handleExport() {
      var _this2 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var data = {
        id: this.$route.query.id
      }; // 导出数据

      Api.numberExport(Object.assign(this.search, data)).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.msg


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    //  获取表格数据  分组 轮次
    getTableInfo: debounce(function () {
      var _this3 = this;

      var data = {
        id: this.$route.query.id,
        page: this.page,
        limit: this.pageSize
      };
      Api.numberRandList(Object.assign(this.search, data)).then(function (res) {
        console.log(res);

        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              level = _res$data.level,
              rand_round = _res$data.rand_round,
              count = _res$data.count,
              event = _res$data.event;
          _this3.total = count;
          _this3.tableData = list;
          _this3.event = event;
          _this3.levelInfo = level;
          _this3.roundInfo = rand_round;
        }
      });
    }, 200, true),
    // 重置搜索项
    resetForm: function resetForm() {
      this.$refs['formData'].resetFields();
      this.getTableInfo();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableInfo();
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableInfo();
    }
  }
};