import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
export default {
  name: 'AuthRedirect',
  created: function created() {
    var hash = window.location.search.slice(1);

    if (window.localStorage) {
      window.localStorage.setItem('x-admin-oauth-code', hash);
      window.close();
    }
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};