import request from '@/utils/request';
export default {
  // 报名交款列表
  regPay: function regPay(data) {
    return request({
      url: '/regPay/index',
      method: 'POST',
      data: data
    });
  },
  // 报名交款详情
  regDetail: function regDetail(params) {
    return request({
      url: '/regPay/detail',
      method: 'GET',
      params: params
    });
  },
  // 报名退款
  regRefund: function regRefund(params) {
    return request({
      url: '/regPay/refund',
      method: 'GET',
      params: params
    });
  },
  // 认筹交款列表
  idePay: function idePay(data) {
    return request({
      url: '/idePay/index',
      method: 'POST',
      data: data
    });
  },
  // 认筹交款详情
  ideDetail: function ideDetail(params) {
    return request({
      url: '/idePay/detail',
      method: 'GET',
      params: params
    });
  },
  // 报名交款退款
  ideRefund: function ideRefund(params) {
    return request({
      url: '/idePay/refund',
      method: 'GET',
      params: params
    });
  },
  // 升筹交款列表
  raiPay: function raiPay(data) {
    return request({
      url: '/raiPay/index',
      method: 'POST',
      data: data
    });
  },
  // 升筹交款详情
  raiDetail: function raiDetail(params) {
    return request({
      url: '/raiPay/detail',
      method: 'GET',
      params: params
    });
  },
  // 升筹退款
  raiRefund: function raiRefund(params) {
    return request({
      url: '/raiPay/refund',
      method: 'GET',
      params: params
    });
  }
};