import request from '@/utils/request';
export function SignIndex(data) {
  // 搜索用户
  return request({
    url: '/signUser/SignIndex',
    method: 'post',
    data: data
  });
}
export function SignUpIndex(data) {
  // 搜索用户
  return request({
    url: '/signUser/SignUpIndex',
    method: 'post',
    data: data
  });
}
export function getUserList(data) {
  var sign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  // 搜索用户
  var url = 'signUser/Search'; // 开盘签到

  if (sign) url = 'signUser/SearchUp'; // 签约签到

  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export function getSignLog(data) {
  return request({
    url: '/signUser/SignLog',
    method: 'post',
    data: data
  });
}
export function SignUpLog(data) {
  return request({
    url: '/signUser/SignUpLog',
    method: 'post',
    data: data
  });
}
export function SignIn(data) {
  return request({
    url: '/signUser/SignIn',
    method: 'post',
    data: data
  });
}
export function SignUp(data) {
  return request({
    url: '/signUser/SignUp',
    method: 'post',
    data: data
  });
}
export function SignInCancel(data) {
  return request({
    url: '/signUser/SignInCancel',
    method: 'post',
    data: data
  });
}
export function SignUpCancel(data) {
  return request({
    url: '/signUser/SignUpCancel',
    method: 'post',
    data: data
  });
}
export function SignInExport(data) {
  return request({
    url: '/signUser/SignInExport',
    method: 'post',
    data: data
  });
}
export function SignUpExport(data) {
  return request({
    url: '/signUser/SignUpExport',
    method: 'post',
    data: data
  });
}
export function SignInPrint(data) {
  return request({
    url: '/signUser/SignInPrint',
    method: 'post',
    data: data
  });
}
export function SignUpPrint(data) {
  return request({
    url: '/signUser/SignUpPrint',
    method: 'post',
    data: data
  });
}
export function GetPrint(data) {
  return request({
    url: '/signUser/GetPrint',
    method: 'get',
    data: data
  });
}
export function SetPrint(data) {
  return request({
    url: '/signUser/SetPrint',
    method: 'post',
    data: data
  });
}
export function SetAddress(data) {
  return request({
    url: '/signUser/SetAddress',
    method: 'post',
    data: data
  });
}