import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";

// 数据判空
var isNullNum = function isNullNum(params) {
  if (params) {
    return params;
  } else {
    return 0;
  }
}; // 价格判空


var isNullPrice = function isNullPrice(params) {
  if (params) {
    return params;
  } else {
    return '0.00';
  }
};

var addCommas = function addCommas(val) {
  if (!val) {
    return;
  }

  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }

  return val;
}; // 万元转化


var tenThousandTrans = function tenThousandTrans(params) {
  if (params) {
    var price = Number(params) / 10000;

    if (price == 0) {
      return '0.00';
    }

    if (price > 1) {
      var p_before = String(price).split('.')[0];
      var p_after = String(price).split('.')[1];

      if (p_after) {
        return addCommas(parseInt(price)) + '.' + p_after.slice(0, 2);
      } else {
        return addCommas(parseInt(price)) + '.00';
      }
    }

    return price;
  } else {
    return '0.00';
  }
};

var tenThousandTransCopy = function tenThousandTransCopy(params) {
  if (params) {
    var price = Number(params);

    if (price == 0) {
      return '0.00';
    }

    if (price > 1) {
      var p_before = String(price).split('.')[0];
      var p_after = String(price).split('.')[1];

      if (p_after) {
        return addCommas(parseInt(price)) + '.' + p_after.slice(0, 2);
      } else {
        return addCommas(parseInt(price)) + '.00';
      }
    }

    return price;
  } else {
    return '0.00';
  }
};

var isArray = function isArray(o) {
  return Object.prototype.toString.call(o) == '[object Array]';
};

export { isNullNum, isNullPrice, tenThousandTrans, tenThousandTransCopy, isArray, addCommas };