var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "salesTeam" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: _vm.isPC ? 9 : 24 } }, [
            _c("div", { staticClass: "button-box align-right" }, [
              _c(
                "div",
                { staticClass: "line-32" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "team/add"],
                          expression: "['admin', 'team/add']",
                        },
                      ],
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.handleTeam },
                    },
                    [_vm._v(" 新增一个团队 ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    attrs: {
                      border: "",
                      "highlight-current-row": "",
                      size: "medium",
                      data: _vm.teamList,
                      height: _vm.tableHeight + 48,
                      "header-cell-style": { background: "#fafafa" },
                    },
                    on: { "row-click": _vm.handleRow },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "name",
                        label: "团队名称",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "count",
                        width: "50",
                        label: "人数",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "账号状态",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "team/status"],
                                      expression: "['admin', 'team/status']",
                                    },
                                  ],
                                  staticClass: "flex align-items ju-center",
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleStatus(scoped.row)
                                      },
                                    },
                                    model: {
                                      value: scoped.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scoped.row, "status", $$v)
                                      },
                                      expression: "scoped.row.status",
                                    },
                                  }),
                                  scoped.row.status
                                    ? _c(
                                        "span",
                                        { staticClass: "status1 mL10" },
                                        [_vm._v("启用")]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "status2 mL10" },
                                        [_vm._v("禁用")]
                                      ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "yue", label: "操作", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "添加人员",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "team/addmember"],
                                        expression:
                                          "['admin', 'team/addmember']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "addUser",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleAddStaff(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "编辑",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "team/edit"],
                                        expression: "['admin', 'team/edit']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "edit",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleEditTeam(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "删除",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "team/del"],
                                        expression: "['admin', 'team/del']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "delete",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleDelTeam(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: _vm.isPC ? 15 : 24 } }, [
            _c(
              "div",
              { staticClass: "button-box flex align-items space-between" },
              [
                _c("div", { staticClass: "font-style line-32" }, [
                  _vm.selectedUserNum > 0
                    ? _c("span", [
                        _vm._v("已选 " + _vm._s(_vm.selectedUserNum) + " 条"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "line-32" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "team/del"],
                            expression: "['admin', 'team/del']",
                          },
                        ],
                        staticClass: "greens-btn",
                        attrs: { size: "small" },
                        on: { click: _vm.handleBatchDel },
                      },
                      [_vm._v("批量移除 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    ref: "userTable",
                    attrs: {
                      border: "",
                      "highlight-current-row": "",
                      size: "medium",
                      data: _vm.staffList,
                      height: _vm.tableHeight + 48,
                      "header-cell-style": { background: "#fafafa" },
                    },
                    on: {
                      "row-click": _vm.handleUserRow,
                      "selection-change": _vm.handleUserSelection,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "序号",
                            type: "index",
                            width: "50",
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "name", label: "姓名" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "span",
                                { class: scoped.row.is_saas ? "red" : "" },
                                [_vm._v(_vm._s(scoped.row.name))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "tel",
                            label: "手机号",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scoped) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        class: scoped.row.is_saas ? "red" : "",
                                      },
                                      [_vm._v(_vm._s(scoped.row.tel))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1805369106
                          ),
                        })
                      : _vm._e(),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "role_list",
                            label: "岗位",
                          },
                        })
                      : _vm._e(),
                    _vm.isPC
                      ? _c("el-table-column", {
                          attrs: {
                            align: "center",
                            prop: "ren",
                            label: "账号状态",
                            width: "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scoped) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: ["admin", "team/status"],
                                            expression:
                                              "['admin', 'team/status']",
                                          },
                                        ],
                                        staticClass:
                                          "flex align-items ju-center",
                                      },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            disabled: scoped.row.is_saas
                                              ? true
                                              : false,
                                            "active-value": 1,
                                            "inactive-value": 0,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleUserStatus(
                                                scoped.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scoped.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scoped.row,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression: "scoped.row.status",
                                          },
                                        }),
                                        scoped.row.status
                                          ? _c(
                                              "span",
                                              { staticClass: "status1 mL10" },
                                              [_vm._v("启用")]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "status2 mL10" },
                                              [_vm._v("禁用")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            544869084
                          ),
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "yue",
                        label: "操作",
                        width: "100",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "移出",
                                    placement: "top-end",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "team/memberdel"],
                                        expression:
                                          "['admin', 'team/memberdel']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "delete",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleDelStaff(scoped.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addEditPostText,
            visible: _vm.dialogTeam,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTeam = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addEditTeam",
              attrs: { model: _vm.addEditTeam, rules: _vm.addEditPostRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "团队名称*", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "16",
                      "show-word-limit": "",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.addEditTeam.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addEditTeam,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addEditTeam.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.dialogTeam = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleTeamSave($event)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加人员",
            visible: _vm.addStaff,
            width: "800px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addStaff = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "font-style number" }, [
                  _vm.selectedNum > 0
                    ? _c("span", [
                        _vm._v("已选 " + _vm._s(_vm.selectedNum) + " 条"),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "16",
                      "show-word-limit": "",
                      placeholder: "请输入内容",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.addStaffSearch($event)
                      },
                    },
                    model: {
                      value: _vm.addStaffVal,
                      callback: function ($$v) {
                        _vm.addStaffVal =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addStaffVal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "orange-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.addStaffSearch },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.addStaffReset },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-box mT15" },
            [
              _c(
                "el-table",
                {
                  ref: "memberListTable",
                  attrs: {
                    border: "",
                    "highlight-current-row": "",
                    size: "medium",
                    data: _vm.memberList,
                    height: 300,
                    "header-cell-style": { background: "#fafafa" },
                  },
                  on: {
                    "row-click": _vm.handleMemberList,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "name",
                      label: "姓名",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            _c(
                              "span",
                              { class: scoped.row.is_saas ? "red" : "" },
                              [_vm._v(_vm._s(scoped.row.name))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "tel", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            _c(
                              "span",
                              { class: scoped.row.is_saas ? "red" : "" },
                              [_vm._v(_vm._s(scoped.row.tel))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "role_name",
                      label: "岗位",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "team_name",
                      label: "团队",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "账号状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scoped) {
                          return [
                            scoped.row.status === 1
                              ? _c("span", [_vm._v("启用")])
                              : _c("span", [_vm._v("停用")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50, 100, 200, 500],
                      "page-size": 10,
                      layout: "total, prev, pager, next, sizes, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.addStaff = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSaveStaff },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }