var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm.showTop
      ? _c("div", { staticClass: "card-top" }, [
          _c("div", [_vm._t("top")], 2),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBottom,
                  expression: "isBottom",
                },
              ],
              staticClass: "bottom-circle",
            },
            [
              _c("div", { staticClass: "circle" }),
              _c("div", { staticClass: "circle" }),
            ]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBottom,
            expression: "isBottom",
          },
        ],
        staticClass: "card-bottom",
      },
      [
        _c("div", [_vm._t("bottom")], 2),
        _vm.showTop
          ? _c("div", { staticClass: "top-circle" }, [
              _c("div", { staticClass: "circle" }),
              _c("div", { staticClass: "circle" }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }