var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", style: _vm.styleObject },
    [
      _c("screenfull"),
      _c(
        "div",
        { ref: "topHeight", staticClass: "topHeight" },
        [
          _vm.editFormatList.name_show === 0
            ? _c("project-title", {
                attrs: {
                  innertext: _vm.rand.name,
                  "p-color": _vm.editFormatList.name_color,
                  "p-font": _vm.editFormatList.name_size,
                  "p-position": _vm.editFormatList.name_position,
                },
              })
            : _vm._e(),
          _vm.editFormatList.round_show === 0
            ? _c("project-rounds", {
                attrs: {
                  innertext: "第" + _vm.roundNumber + "轮",
                  "p-color": _vm.editFormatList.round_color,
                  "p-font": _vm.editFormatList.round_size,
                  "p-position": _vm.editFormatList.round_position,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "project-content",
            {
              attrs: {
                "s-column": 0,
                "s-position": _vm.editFormatList.card_position,
                "s-row-num": _vm.editFormatList.card_row_num,
                "content-height": _vm.editFormatList.content_height,
                "top-height": _vm.tbHeight,
                "s-margin": _vm.editFormatList.card_margin,
                "s-left-margin": _vm.editFormatList.card_info_left_margin,
                "s-top-margin": _vm.editFormatList.card_info_top_margin,
              },
            },
            _vm._l(_vm.lottery, function (item, i) {
              return _c(
                "item-box",
                {
                  key: i,
                  attrs: {
                    "is-show-num": _vm.rand.show_rand_code,
                    num: _vm.getSequenceNumber(i, _vm.roundNumber),
                  },
                },
                [
                  _vm.rand.show_name
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(
                          _vm._s(
                            _vm.rand.show_name === 2
                              ? item.name
                              : _vm.nickName(item.name)
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm.rand.show_tel
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(_vm.getTel(item.tel))),
                      ])
                    : _vm._e(),
                  _vm.rand.show_audit_order
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.code)),
                      ])
                    : _vm._e(),
                  _vm.rand.show_card
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(
                          _vm._s(_vm.getCard(item.card, _vm.rand.show_card))
                        ),
                      ])
                    : _vm._e(),
                  _vm.rand.show_other_remark
                    ? _c("span", { staticClass: "mes" }, [
                        _vm._v(_vm._s(item.other_remark)),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "bottomHeight", staticClass: "edit-btn-group" },
        [
          _c(
            "project-bottom",
            { attrs: { "is-show-tip": false, "flex-pos": "center" } },
            [_c("div", { staticStyle: { height: "80px" } })]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }