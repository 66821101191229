var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "start_page-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "start_page-content" }, [
      _c("div", { staticClass: "start_page-content-left" }, [
        _c("div", { staticClass: "start_page-content-img-wrapper" }, [
          _c("img", {
            staticClass: "start_page-content-img",
            attrs: { src: require("@/assets/image/IDCard-img.png"), alt: "" },
          }),
          _c(
            "div",
            {
              class: [
                "search-img",
                { "searching-wrapper": _vm.$parent.searchLoading },
              ],
            },
            [
              _c("img", {
                class: ["search-img", { searching: _vm.$parent.searchLoading }],
                attrs: {
                  alt: "",
                  src: require("@/assets/image/searching-img.png"),
                },
              }),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "start_page-content-desc-wrapper" },
          [
            _vm.$parent.socketLoading
              ? [
                  _c("span", [
                    _vm._v("正在连接身份证识别器驱动程序，请稍候..."),
                  ]),
                ]
              : [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$parent.socketConnect
                          ? _vm.$parent.searchLoading
                            ? "正在查找客户..."
                            : "请将身份证放在读卡器上以查找客户"
                          : "请检查设备驱动是否安装正常"
                      )
                    ),
                  ]),
                  !_vm.$parent.socketConnect
                    ? [
                        _c(
                          "a",
                          {
                            staticClass:
                              "start_page-content-desc-download-text",
                            attrs: { href: "" + _vm.driveUrl },
                          },
                          [_vm._v("下载身份证识别器驱动程序")]
                        ),
                      ]
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "start_page-content-right" }, [
        _c(
          "div",
          { staticClass: "start_page-footer-form-wrapper" },
          [
            _c("el-input", {
              staticClass: "start_page-footer-form-input",
              attrs: {
                clearable: "",
                placeholder: "或输入产权人姓名\\手机号\\身份证\\诚意单号查询",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.$parent.getUserList($event)
                },
              },
              model: {
                value: _vm.$parent.searchParams.tel,
                callback: function ($$v) {
                  _vm.$set(_vm.$parent.searchParams, "tel", $$v)
                },
                expression: "$parent.searchParams.tel",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "start_page-footer-form-btn",
                attrs: {
                  round: "",
                  type: "primary",
                  loading: _vm.$parent.searchLoading,
                },
                on: { click: _vm.$parent.getUserList },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "start_page-header" }, [
      _c("span", [_vm._v("查找客戶")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }