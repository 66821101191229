// 中建配置
var privatisation = {
  // 项目名称  页面title，登录页面文字
  // title: '苏州兴格置业开盘系统',
  title: '中建七局地产',
  // 登录页 logo  //必须放在 src/assets/image，文件下
  LogInLogo: 'white.png',
  // LogInLogo: '',
  // 首页欢迎字段
  // indexText: '欢迎您来到在线开盘系统',
  indexText: '欢迎您来到在线开盘系统',
  // tab栏 logo // 必须放在 src/icons/svg, 文件下，不用跟后缀
  // logoSvg: '',
  logoSvg: '',
  // 页面标签 图标
  // ico: 'xinxiwan.ico',
  ico: 'zjdc_icon.ico',
  // powered by 自定义
  // powered: '',
  powered: '',
  // 首页提示语
  // logoText: '',
  logoText: '开盘系统',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false   关闭 logo 模块
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether support pinyin search in headerSearch
   * Bundle size minified 47.3kb,minified + gzipped 63kb
   */
  supportPinyinSearch: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
}; // 云销控配置

var production = {
  // 项目名称  页面title，登录页面文字
  // title: '苏州兴格置业开盘系统',
  title: '云销控',
  // 登录页 logo  //必须放在 src/assets/image，文件下
  // LogInLogo: 'white.png',
  LogInLogo: 'logo (2).png',
  // 首页欢迎字段
  // indexText: '欢迎您来到在线开盘系统',
  // indexText: '欢迎您来到云销控在线开盘系统',
  indexText: '欢迎您来到云销控开盘系统~',
  // tab栏 logo // 必须放在 src/icons/svg, 文件下，不用跟后缀
  // logoSvg: '',
  logoSvg: 'logo',
  // 页面标签 图标
  // ico: 'xinxiwan.ico',
  ico: 'icon.ico',
  // powered by 自定义
  // powered: '',
  powered: 'powered by 云销控',
  // 首页提示语
  // logoText: '',
  logoText: '房地产“数智化”开盘服务商',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether support pinyin search in headerSearch
   * Bundle size minified 47.3kb,minified + gzipped 63kb
   */
  supportPinyinSearch: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
};
module.exports = process.env.VUE_APP_BUILD_TAG === 'privatisation' ? privatisation : production;