import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import JSZip from 'jszip';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { AwesomeQR } from 'awesome-qr';
import { getProject, getpName } from '@/utils/auth.js';
import { qrcodeList, qrcodeAdd, qrcodeSet, qrcodeBgi, qrcodeDel, qrcodeCreate, qrcodeDefault } from '@/api/qrCodeGeneration';
import qrList from './components/qrList';
var zip = {};
var file = {};
var timer = {};
var isQQBrowser = navigator.userAgent.includes('QQ');
var scale = 1; // window.devicePixelRatio

export default {
  components: {
    qrList: qrList
  },
  data: function data() {
    return {
      // 码牌类型尺寸/px
      qrTypeSize: 210,
      // 预览码牌尺寸/px
      showTypeSize: 300,
      // 码牌下载列表显示尺寸/px
      downShowSize: 180,
      // 预览码牌类型
      showTypeList: [],
      // 码牌类型列表
      qrTypeList: [],
      // 右侧配置项
      option: {
        bgstyle: 1,
        qrx: 1,
        qry: 0,
        qrs: 0,
        fontx: 0,
        fonty: 0,
        fonts: 0,
        fontc: 'rgba(255, 255, 255, 1)'
      },
      // 编码长度
      codeLength: 4,
      // 编码补零
      codeFill: '',
      // 默认背景色
      defaultBgColor: '#528CFF',
      // 遮罩层类型（1预览二维码  2下载弹窗  3下载状态
      maskType: 0,
      // 当前预览的码牌截图
      showNowTypeImg: '',
      // 渲染的码牌宽高
      renderSize: 400,
      // 码牌文件列表
      file: {},
      // 码牌起始编号
      startNum: 0,
      // 已渲染个数
      renderNum: 0,
      // 总渲染长度
      renderLength: 0,
      // 绘制计算结果缓存
      draw: {},
      // 时间戳-用于重新请求背景图片
      timestamp: ''
    };
  },
  computed: {},
  watch: {
    codeLength: function codeLength(val) {
      this.updateCodeFill(val);
    },
    'option.fontc': function optionFontc(val) {
      this.setListOption('fontc', val);
    },
    'option.bgstyle': function optionBgstyle(val) {
      this.setListOption('bgstyle', val);
    }
  },
  created: function created() {
    this.updateCodeFill(this.codeLength);
    this.reqQrList();
    this.getNowProjectID();
    this.getNowProjectName();
    this.initZipFile();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.initZipFile();
  },
  methods: {
    // 初始化zip实例
    initZipFile: function initZipFile() {
      zip = new JSZip();
      file = zip.folder('');
      this.resetState();
    },
    // 下载压缩包
    download: function download() {
      var _this = this;

      zip.generateAsync({
        type: 'blob'
      }).then(function (content) {
        var fileName = "".concat(_this.project_name, "-\u7801\u724C-").concat(moment().format('YYYYMMDDHHmmss'));
        saveAs(content, fileName);
        setTimeout(function () {
          _this.$message({
            type: 'success',
            message: '下载成功'
          });

          _this.resetState();
        }, 500);
      });
    },
    // 恢复初始状态
    resetState: function resetState() {
      timer = {};
      file.files = {};
      this.maskType = 0;
      this.renderNum = 0;
      this.renderLength = 0;
      this.draw = {};
    },
    // 计算字符串所占存储大小
    getStorageSize: function getStorageSize(dataLength) {
      var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var size = dataLength || 0;
      var sizeUnit = 0;
      var arr = ['bytes', 'KB', 'MB', 'GB', 'TB'];

      while (size > 1024) {
        size /= 1024;
        ++sizeUnit;
      }

      console.log("".concat(num, "\u5927\u5C0F\u4E3A\uFF1A").concat(size.toFixed(2)).concat(arr[sizeUnit]));
    },
    // 更新补零字符串
    updateCodeFill: function updateCodeFill(val) {
      this.codeFill = Array(val).fill(0).join('');
    },
    // 填补编号
    fillNum: function fillNum(num) {
      if (!Number.isInteger(num)) return num;
      return (this.codeFill + num).slice(-this.codeLength);
    },
    // 获取百分比
    getPercentage: function getPercentage(num, allNum) {
      return parseInt(num / allNum * 100);
    },
    // 创建码牌数量
    createNum: function createNum(fn) {
      var _this2 = this;

      var startNum = this.startNum + 1;
      var endNum = 0;
      this.qrTypeList.forEach(function (type, index) {
        if (type.choose && type.create_num) {
          endNum = startNum + type.create_num;

          _this2.setQrNum(startNum, endNum, index);

          startNum = endNum;
        }
      });
      fn();
    },
    // 给码牌设置生成编号
    setQrNum: function setQrNum(start, end, index) {
      var _this3 = this;

      setTimeout(function () {
        var arr = [];

        for (var i = start; i < end; i++) {
          arr.push(i);

          if (i == end - 1) {
            _this3.qrTypeList[index].numArr = arr;
          }
        }
      }, 5);
    },
    // 计算编码真实尺寸
    getFontSize: function getFontSize(itemSize, scale) {
      return itemSize * (scale / 100);
    },
    // 获取码牌列表（需要更改默认值为百分比
    reqQrList: function reqQrList() {
      var _this4 = this;

      qrcodeList().then(function (res) {
        var list = res.data.list || []; // 提前加入特殊字段（避免无法更新

        list.forEach(function (item, index) {
          // 选中状态
          item.choose = true; // 生成数量

          item.create_num = 0; // 编号列表

          item.numArr = []; // 显示类型名称

          item.name = "".concat(index + 1, "\u7C7B\u80CC\u666F\u56FE"); // 背景图节点ID

          item.bg_img_id = "bg_".concat(item.id);
        });
        _this4.qrTypeList = list; // 将列表第一项的配置作为右侧修改基准（方便后续扩展

        if (_this4.qrTypeList[0]) {
          Object.keys(_this4.option).forEach(function (key) {
            _this4.option[key] = _this4.qrTypeList[0][key];
          });
        }
      }).catch(function (err) {
        console.error('获取列表出错：', err);
      });
    },
    // 添加二维码类型
    addQrType: function addQrType() {
      var _this5 = this;

      qrcodeAdd().then(function (res) {
        _this5.$message({
          type: 'success',
          message: res.data.msg
        });

        _this5.reqQrList();
      });
    },
    // 上传失败回调
    uploadError: function uploadError(e) {
      console.error('上传失败：', e);
    },
    // 设置默认背景
    setDefaultBg: function setDefaultBg(item) {
      var _this6 = this;

      // 清空已上传的文件列表
      this.fileList = [];
      qrcodeBgi({
        id: item.id,
        bg: ''
      }).then(function (res) {
        _this6.$message({
          type: 'success',
          message: res.data.msg
        });

        _this6.reqQrList();
      });
    },
    // 替换码牌背景
    changeQrTypeBg: function changeQrTypeBg(e, item) {
      var _this7 = this;

      qrcodeBgi({
        id: item.id,
        bg: e.Data.url
      }).then(function (res) {
        _this7.$message({
          type: 'success',
          message: res.data.msg
        });

        _this7.reqQrList();
      });
    },
    // 删除二维码类型
    removeQrType: function removeQrType(item) {
      var _this8 = this;

      if (this.qrTypeList.length <= 1) return;
      qrcodeDel({
        id: item.id.toString()
      }).then(function (res) {
        _this8.$message({
          type: 'success',
          message: res.data.msg
        });

        _this8.reqQrList();
      });
    },
    // 重置为默认配置
    setDefaultOption: function setDefaultOption() {
      var _this9 = this;

      qrcodeDefault().then(function (res) {
        _this9.$message({
          type: 'success',
          message: res.data.msg
        });

        _this9.reqQrList();
      });
    },
    // 修改右侧配置
    changeOption: function changeOption(key, type) {
      var option = this.option;

      switch (type) {
        case '+':
          option[key] += 1;
          break;

        case '-':
          option[key] -= 1;
          break;

        default:
          console.warn('未知操作类型：', type, key);
      } // 将修改保存到统一配置中


      this.option = option;
      this.setListOption(key, option[key]);
    },
    // 实时修改列表中对应的配置
    setListOption: function setListOption(key, val) {
      var _this10 = this;

      this.qrTypeList.forEach(function (item, index) {
        _this10.qrTypeList[index][key] = val;
      });
      clearTimeout(timer.option);
      timer.option = setTimeout(function () {
        _this10.saveOption();
      }, 300);
    },
    // 保存右侧配置
    saveOption: function saveOption() {
      qrcodeSet(this.option).catch(function (err) {
        console.error('保存失败：', err);
      });
    },
    // 切换选中要下载的码牌
    chooseItem: function chooseItem(index) {
      this.qrTypeList[index].choose = !this.qrTypeList[index].choose;
    },
    // 显示遮罩层
    showMask: function showMask(type, item) {
      var _this11 = this;

      switch (type) {
        // 预览二维码
        case 1:
          this.showTypeList = [item];
          this.maskType = type;
          break;
        // 下载弹窗

        case 2:
          // 如果列表为空，就弹出提示
          if (!this.qrTypeList.length) {
            this.$message.warning('请新增码牌类型！');
            return;
          } // 都重新请求一次图片列表，保证不因为缓存导致canvas绘制图片时跨域！


          this.timestamp = new Date().getTime();
          this.maskType = type;
          break;
        // 下载状态

        case 3:
          {
            /**
             * 点击下载时，需要统计出生成总数
             * 将总数传给接口，获取起始值
             *
             * 用起始值，生成对应的编码列表
             * 有了编码列表后，开启页面的渲染开关，
             * 页面自动渲染每一个编码对应的码牌
             *
             * 累计渲染成功数量，等于本次最大编码时
             * 打包下载，打印计时
             */
            // 如果下载数量全部为0，就弹出提示
            if (!this.qrTypeList.some(function (item) {
              return item.create_num > 0;
            })) {
              this.$message.warning('下载数量需大于0！');
              return;
            }

            var num = 0;
            this.qrTypeList.forEach(function (type, index) {
              if (type.choose && type.create_num) {
                num += type.create_num;
              }
            });
            this.renderLength = num;
            qrcodeCreate({
              num: num
            }).then(function (res) {
              _this11.startNum = res.data.start;

              _this11.createNum(function () {
                _this11.maskType = type;
                file = zip.folder('');
                setTimeout(function () {
                  _this11.createAllQR();

                  console.time('生成耗时');
                }, 100);
              });
            }).catch(function () {});
            break;
          }
      }
    },
    // 关闭遮罩层
    closeMask: function closeMask() {
      switch (this.maskType) {
        case 1:
          this.showTypeList = [];
          break;

        case 2:
          break;

        case 3:
          break;
      }

      this.maskType = 0;
    },
    // 创建所有的二维码
    createAllQR: function createAllQR() {
      var _this12 = this;

      this.qrTypeList.forEach(function (item, index) {
        if (item.choose && item.create_num) {
          var size = _this12.renderSize * (item.qrs / 100);
          item.numArr.forEach(function (num) {
            var code = _this12.fillNum(num);

            new AwesomeQR({
              text: "".concat(_this12.project_id, "-").concat(code),
              size: size,
              margin: 5,
              correctLevel: 2
            }).draw().then(function (dataUrl) {
              _this12.drawCardBG(item, code, dataUrl, size);
            }).catch(function (err) {
              console.error('生成二维码异常：', err);

              _this12.$message.warning('请刷新页面重试！');
            });
          });
        }
      });
    },
    // 绘制码牌背景图样式
    drawCardBG: function drawCardBG(item, qrNum, qrUrl, qrSize) {
      var rsize = this.renderSize;
      var cvs = document.createElement('canvas');
      cvs.width = rsize * scale;
      cvs.height = rsize * scale;
      var ctx = cvs.getContext('2d');
      ctx.scale(scale, scale);
      ctx.save(); // 绘制填充区域

      var _ref = this.draw[item.id] || {},
          radius = _ref.radius,
          cx = _ref.cx,
          cy = _ref.cy;

      if (!radius) {
        radius = rsize / 2;
        cx = 0 + radius;
        cy = 0 + radius; // 存储圆形坐标大小（避免重复计算

        this.draw[item.id] = _objectSpread(_objectSpread({}, this.draw[item.id]), {}, {
          radius: radius,
          cx: cx,
          cy: cy
        });
      } // 圆形


      if (item.bgstyle == 1) {
        ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
      } // 圆角矩形
      else {
        this.drawRoundRect(ctx, 0, 0, rsize, rsize, 4);
      }

      ctx.strokeStyle = 'transparent';
      ctx.stroke();
      ctx.clip(); // 绘制填充背景色

      if (!item.bg) {
        ctx.fillStyle = this.defaultBgColor;
        ctx.fillRect(0, 0, rsize, rsize);
        this.drawQrAndText(ctx, cvs, item, rsize, qrUrl, qrNum, qrSize);
      } // 绘制填充背景图
      else {
        /**
         * 优化思路：
         * 提前在页面中渲染出每一种类型的图片
         * 然后通过获取img节点的方式
         * 拿到img，以及宽高，存储到缓存中
         * 获取缓存中的img做渲染，无需二次获取
         *
         * 这样可以避免多次访问对象存储上的图片了
         * 也减少了图片的请求，以及处理耗时
         */
        var _ref2 = this.draw[item.id] || {},
            w = _ref2.w,
            h = _ref2.h,
            sX = _ref2.sX,
            sY = _ref2.sY,
            sW = _ref2.sW,
            img = _ref2.img;

        if (!img) {
          img = document.getElementById(item.bg_img_id);
          w = img.width;
          h = img.height; // 等比短边裁切

          if (w > h) {
            sX = (w - h) / 2;
            sY = 0;
            sW = h;
          } else {
            sX = 0;
            sY = (h - w) / 2;
            sW = w;
          } // 存储背景图坐标大小（避免重复计算


          this.draw[item.id] = _objectSpread(_objectSpread({}, this.draw[item.id]), {}, {
            img: img,
            // 已获取的背景图节点
            sX: sX,
            // 背景图裁切X轴起始点
            sY: sY,
            // 背景图裁切Y轴起始点
            sW: sW,
            // 背景图裁切大小(宽高
            w: w,
            // 背景图宽度
            h: h // 背景图高度

          });
        }

        ctx.drawImage(img, sX, sY, sW, sW, 0, 0, rsize, rsize);
        this.drawQrAndText(ctx, cvs, item, rsize, qrUrl, qrNum, qrSize);
      }
    },
    // 绘制圆角矩形
    drawRoundRect: function drawRoundRect(ctx, x, y, w, h, r) {
      if (w < 2 * r) r = w / 2;
      if (h < 2 * r) r = h / 2;
      ctx.moveTo(x + r, y);
      ctx.arcTo(x + w, y, x + w, y + h, r);
      ctx.arcTo(x + w, y + h, x, y + h, r);
      ctx.arcTo(x, y + h, x, y, r);
      ctx.arcTo(x, y, x + w, y, r);
    },
    // 绘制二维码和编号（需手动计算位置大小
    drawQrAndText: function drawQrAndText(ctx, cvs, item, rsize, qrUrl, qrNum, qrSize) {
      var _this13 = this;

      var qrImg = new Image();
      qrImg.crossOrigin = 'Anonymous';

      qrImg.onload = function () {
        var d_qrx = rsize * (item.qrx / 100) - qrSize / 2;
        var d_qry = rsize * (item.qry / 100) - qrSize / 2;
        /**
         * 一定要注意截取的是图像大小
         * 不然会截取出错
         * 渲染出来可能尺寸不对
         */

        ctx.drawImage(qrImg, 0, 0, qrImg.width, qrImg.height, d_qrx, // x坐标
        d_qry, // y坐标
        qrSize, // 绘制宽度
        qrSize // 绘制高度
        );
        /**
         * ctx.textAlign = "center";
         * 会导致绘制强制居中
         *
         * Y轴距离需要加上字体的高度！
         */

        var d_fonts = _this13.getFontSize(rsize, item.fonts);

        var lineHeight = 1.8695652173913043 * d_fonts;
        ctx.font = "normal normal 400 ".concat(d_fonts, "px/").concat(lineHeight, "px Microsoft YaHei");
        ctx.fillStyle = "".concat(item.fontc);
        ctx.textBaseline = 'middle';
        /**
         * ctx.measureText(qrNum).fontBoundingBoxAscent
         * 上述仅Chrome支持，兼容性不高
         *
         * 采用与超哥一致的绘制方案：
         * 字体大小/行高作为绘制大小
         * 基线居中
         * 顶部 + 0.03的比例
         *
         * 0.8695652173913043 为字体在浏览器中实际大小/高度比
         * 0.03 为按照设计图手动调整的距离
         */

        var d_fontw = ctx.measureText(qrNum).width;
        var d_fontx = rsize * (item.fontx / 100) - d_fontw / 2;
        var d_fonty = rsize * ((item.fonty + d_fonts * 0.03) / 100); // 单独处理QQ浏览器的计算（不严谨，后续优化

        if (isQQBrowser) d_fonty = rsize * (item.fonty / 100);
        ctx.fillText(qrNum, d_fontx, d_fonty);
        var base64 = cvs.toDataURL('image/png', 1);

        _this13.addFile(base64.replace(/^data:image\/(png|jpg);base64,/, ''), qrNum, item.name);

        ctx.restore();
        ctx.clearRect(0, 0, rsize, rsize);
      };

      qrImg.src = qrUrl;
    },
    // 添加文件
    addFile: function addFile(base64, id, name) {
      file.file("".concat(name, "/").concat(id, ".png"), base64, {
        base64: true
      });
      this.renderNum += 1;

      if (this.renderNum == this.renderLength) {
        console.timeEnd('生成耗时');
        this.download();
      }
    },
    // 获取当前项目ID
    getNowProjectID: function getNowProjectID() {
      var str = getProject();

      if (str) {
        var p = JSON.parse(str);
        this.project_id = p.project_id;
      }
    },
    // 获取当前项目名称
    getNowProjectName: function getNowProjectName() {
      this.project_name = getpName();
    }
  }
};