var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-card", style: { background: "rgba(255,255,255, 0.1)" } },
    [
      _c("div", { staticClass: "flex", class: { max: _vm.max > 10 } }, [
        _c("span", { style: { color: _vm.cardbgc } }, [
          _vm._v(_vm._s(_vm.after) + _vm._s(_vm.number)),
        ]),
      ]),
      _vm.hint
        ? _c("div", {
            staticClass: "hint",
            class: { maxHint: _vm.max > 10 },
            style: { color: _vm.cardbgc },
            domProps: { innerHTML: _vm._s(_vm.hint) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }