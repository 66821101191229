var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state
    ? _c(
        "div",
        {
          class: [
            "list",
            { edit_list: _vm.state == 1 },
            { down_list: _vm.state == 2 },
            { show_list: _vm.state == 3 },
          ],
        },
        [
          _vm._l(_vm.qrTypeList, function (item, i) {
            return _c(
              "div",
              {
                key: i,
                class: ["item", { choose: item.choose }],
                on: {
                  click: function ($event) {
                    return _vm.chooseItem(i)
                  },
                },
              },
              [
                _vm.state == 1 || _vm.state == 2
                  ? _c("div", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    class: [
                      "ewm_box",
                      { round: _vm.$parent.option.bgstyle == 1 },
                    ],
                    style: {
                      width: _vm.itemSize + "px",
                      height: _vm.itemSize + "px",
                      backgroundImage: item.bg
                        ? "url(" + (_vm.imgUrl + item.bg) + ")"
                        : "",
                      backgroundColor: item.bg
                        ? ""
                        : _vm.$parent.defaultBgColor,
                    },
                    attrs: { id: item.node_id },
                  },
                  [
                    _c("img", {
                      staticClass: "ewm",
                      style: {
                        top: item.qry + "%",
                        left: item.qrx + "%",
                        width: item.qrs + "%",
                        height: item.qrs + "%",
                      },
                      attrs: {
                        src: require("../../../../../assets/image/show_ewm.png"),
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "num",
                        style: {
                          color: item.fontc,
                          top: item.fonty + "%",
                          left: item.fontx + "%",
                          fontSize:
                            _vm.$parent.getFontSize(_vm.itemSize, item.fonts) +
                            "px",
                        },
                      },
                      [_vm._v(" 0000 ")]
                    ),
                  ]
                ),
                _vm.state == 1
                  ? _c(
                      "div",
                      { staticClass: "options" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.$parent.setDefaultBg(item)
                              },
                            },
                          },
                          [_vm._v(" 默认背景 ")]
                        ),
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: "",
                              "on-success": function ($event) {
                                return _vm.$parent.changeQrTypeBg($event, item)
                              },
                              "http-request": _vm.handleFileUploadOss,
                              "on-error": _vm.$parent.uploadError,
                              "show-file-list": false,
                              accept: _vm.$imgSel,
                            },
                          },
                          [
                            _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("替换背景"),
                            ]),
                          ],
                          1
                        ),
                        _c("i", {
                          staticClass: "icon el-icon-zoom-in",
                          on: {
                            click: function ($event) {
                              return _vm.$parent.showMask(1, item)
                            },
                          },
                        }),
                        _c("i", {
                          class: [
                            "icon",
                            "el-icon-delete",
                            { disable: _vm.qrTypeList.length == 1 },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.$parent.removeQrType(item)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.state == 2
                  ? _c("i", { staticClass: "icon el-icon-success" })
                  : _vm._e(),
              ]
            )
          }),
          _vm.state == 1
            ? _c(
                "div",
                {
                  staticClass: "item add_item",
                  on: { click: _vm.$parent.addQrType },
                },
                [
                  _c("i", { staticClass: "icon el-icon-plus" }),
                  _c("span", [_vm._v("再新增一类背景")]),
                ]
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }