import { render, staticRenderFns } from "./car.vue?vue&type=template&id=53c126be&"
import script from "./car.vue?vue&type=script&lang=js&"
export * from "./car.vue?vue&type=script&lang=js&"
import style1 from "./car.vue?vue&type=style&index=1&id=53c126be&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_24/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53c126be')) {
      api.createRecord('53c126be', component.options)
    } else {
      api.reload('53c126be', component.options)
    }
    module.hot.accept("./car.vue?vue&type=template&id=53c126be&", function () {
      api.rerender('53c126be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/roomSite/car.vue"
export default component.exports