var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "permission",
          rawName: "v-permission",
          value: ["admin", "room/mapindex", "car/mapindex"],
          expression: "['admin', 'room/mapindex', 'car/mapindex']",
        },
      ],
      staticClass: "planeConter",
      staticStyle: { overflow: "hidden" },
    },
    [
      _vm.selectCar && _vm.divData.width && _vm.divData.height
        ? _c("div", {
            staticStyle: {
              position: "absolute",
              border: "3px solid red",
              "z-index": "1000",
            },
            style: _vm.divData,
          })
        : _vm._e(),
      !_vm.isCkickAddData && !!_vm.imgPath && !_vm.selectCar
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap"],
                  expression: "['admin', 'car/setmap']",
                },
              ],
              staticStyle: {
                width: "100px",
                position: "absolute",
                right: "25%",
                top: "0",
                "z-index": "99",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "点击此处保存" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "数据，" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "图和颜色！",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "greens-btn",
                      staticStyle: { width: "100px", height: "37px" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("submit")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectCar
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap"],
                  expression: "['admin', 'car/setmap']",
                },
              ],
              staticStyle: {
                width: "100px",
                position: "absolute",
                right: "25%",
                top: "0",
                "z-index": "99",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "点击此处取消" +
                      (_vm.selectCar === "sets" ? "修改" : "删除") +
                      "" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "！",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "greens-btn",
                      staticStyle: { width: "100px" },
                      on: {
                        click: function ($event) {
                          _vm.selectCar = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "取消" +
                          _vm._s(_vm.selectCar === "sets" ? "修改" : "删除")
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isCkickAddData && !!_vm.imgPath && !_vm.selectCar
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap"],
                  expression: "['admin', 'car/setmap']",
                },
              ],
              staticStyle: {
                width: "100px",
                position: "absolute",
                right: "calc(25% + 120px)",
                top: "0",
                "z-index": "99",
              },
            },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { size: "small" },
                  on: { command: _vm.onDropdownDel },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "danger" },
                    },
                    [
                      _vm._v(" 删除 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "all" } }, [
                        _vm._v(
                          "删除全部" +
                            _vm._s(_vm.cate === 1 ? "房源" : "车位") +
                            "和背景图"
                        ),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "allCar" } }, [
                        _vm._v(
                          "删除全部" + _vm._s(_vm.cate === 1 ? "房源" : "车位")
                        ),
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "selectDelCar" } },
                        [
                          _vm._v(
                            "框选删除" +
                              _vm._s(_vm.cate === 1 ? "房源" : "车位")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isCkickAddData && !!_vm.imgPath && !_vm.selectCar
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100px",
                position: "absolute",
                right: "calc(25% + 250px)",
                top: "0",
                "z-index": "99",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "点击让平面图居中！",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen mL10",
                      staticStyle: { width: "100px", height: "37px" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("reset")
                        },
                      },
                    },
                    [_vm._v("画面居中")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isCkickAddData && !!_vm.imgPath && !_vm.selectCar
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap"],
                  expression: "['admin', 'car/setmap']",
                },
              ],
              staticStyle: {
                width: "100px",
                position: "absolute",
                right: "calc(25% + 370px)",
                top: "0",
                "z-index": "99",
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "点击此处更新" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "，以便删除项目" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "后平面图没有删除" +
                      (_vm.cate === 1 ? "房源" : "车位") +
                      "！",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btnHollowGreen mL10",
                      staticStyle: { width: "100px", height: "37px" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("updateCars")
                        },
                      },
                    },
                    [_vm._v("更新" + _vm._s(_vm.cate === 1 ? "房源" : "车位"))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "点击此处框选车位以修改！",
            placement: "bottom",
          },
        },
        [
          !_vm.isCkickAddData && !!_vm.imgPath && !_vm.selectCar
            ? _c(
                "el-button",
                {
                  staticClass: "orange-btn fixed-btn-left",
                  attrs: { disabled: !_vm.sceneChildrens.length },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit("carClick")
                    },
                  },
                },
                [_vm._v("框选" + _vm._s(_vm.cate === 1 ? "房源" : "车位"))]
              )
            : _vm._e(),
        ],
        1
      ),
      !!_vm.imgPath
        ? _c("div", { staticClass: "schedule" }, [
            _c("span", [_vm._v("总数:" + _vm._s(_vm.listTotal) + "个")]),
            _c(
              "div",
              { staticClass: "progress" },
              [
                _c("el-progress", {
                  attrs: {
                    "text-inside": "",
                    format: _vm.format,
                    "stroke-width": 20,
                    percentage: _vm.progress,
                  },
                }),
              ],
              1
            ),
            _c("span", [
              _vm._v(
                "未生成:" + _vm._s(_vm.listTotal - _vm.generatedCount) + "个"
              ),
            ]),
          ])
        : _vm._e(),
      !!_vm.imgPath
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100px",
                  height: "30px",
                  position: "absolute",
                  top: "20px",
                  left: "50px",
                  "text-align": "center",
                  "line-height": "30px",
                  "z-index": "99",
                  "font-weight": "bold",
                  "font-size": "20px",
                  color: "black",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(Math.round((_vm.cameraPositionZ / 516) * 100)) +
                    " "
                ),
              ]
            ),
            _c("div", {
              staticStyle: {
                width: "100px",
                height: "10px",
                border: "2px black solid",
                "border-top": "none",
                position: "absolute",
                top: "40px",
                left: "50px",
                "z-index": "99",
              },
            }),
          ])
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              !!_vm.imgPath
                ? _c("left_page", {
                    ref: "left_page",
                    attrs: { span: 18 },
                    on: {
                      doCkickAddData: _vm.doCkickAddData,
                      doCkickSetData: _vm.doCkickSetData,
                      onSubmit: _vm.onSubmit,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!!_vm.imgPath && !_vm.isOnCart,
                      expression: "!!!imgPath && !isOnCart",
                    },
                  ],
                  staticClass: "left_isShow",
                },
                [_vm._v("请先选择平面图位置！")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!!_vm.imgPath && _vm.isOnCart,
                      expression: "!!!imgPath && isOnCart",
                    },
                  ],
                  staticClass: "left_isShow",
                },
                [
                  _vm._v(
                    "请先上传平面图图片再添加" +
                      _vm._s(_vm.cate === 1 ? "房源" : "车位")
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _vm.isCkickAddData
                ? _c("div", { staticClass: "right_isCkickAddData" }, [
                    _vm._v(
                      "请在左边平面图中点击以添加" +
                        _vm._s(_vm.cate === 1 ? "房源" : "车位") +
                        "！"
                    ),
                  ])
                : _vm._e(),
              _vm.selectCar
                ? _c("div", { staticClass: "right_isCkickAddData" }, [
                    _vm._v(
                      "请在左边平面图中长按鼠标左键并拖动红框选择" +
                        _vm._s(_vm.cate === 1 ? "房源" : "车位") +
                        "！"
                    ),
                  ])
                : _vm._e(),
              _c("right_page", {
                ref: "right_page",
                on: {
                  doCkickAddData: _vm.doCkickAddData,
                  doCkickSetData: _vm.doCkickSetData,
                  onUpload: _vm.onUpload,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }