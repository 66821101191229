var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowText,
            expression: "isShowText",
          },
        ],
        staticClass: "time-wrapper",
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.content.show_day,
                expression: "content.show_day",
              },
            ],
            class: { num: _vm.isChangeStyle },
          },
          [_vm._v(_vm._s(_vm._f("formatZero")(_vm.content.day)))]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.content.show_day,
                expression: "content.show_day",
              },
            ],
            class: { text: _vm.isChangeStyle },
          },
          [_vm._v("天")]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.content.show_hour,
                expression: "content.show_hour",
              },
            ],
            class: { num: _vm.isChangeStyle },
          },
          [_vm._v(_vm._s(_vm._f("formatZero")(_vm.content.hour)))]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.content.show_hour,
                expression: "content.show_hour",
              },
            ],
            class: { text: _vm.isChangeStyle },
          },
          [_vm._v("时")]
        ),
        _c("span", { class: { num: _vm.isChangeStyle } }, [
          _vm._v(_vm._s(_vm._f("formatZero")(_vm.content.min))),
        ]),
        _c("span", { class: { text: _vm.isChangeStyle } }, [_vm._v("分")]),
        _c("span", { class: { num: _vm.isChangeStyle } }, [
          _vm._v(_vm._s(_vm._f("formatZero")(_vm.content.sec))),
        ]),
        _c("span", { class: { text: _vm.isChangeStyle } }, [_vm._v("秒")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }