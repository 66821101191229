//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'YunXkAdminCountdown',
  props: {
    batchTimeData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    ledInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 字体颜色
    color: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    //  倒计时小于 10 补0
    addZero: function addZero(s) {
      return s < 10 ? '0' + s : s;
    }
  }
};