var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no_list", attrs: { id: "no_list" } },
    [
      _c("div", { attrs: { id: "top_node" } }),
      _vm._l(_vm.rows, function (row, r_idx) {
        return _c(
          "div",
          { key: r_idx, staticClass: "row", attrs: { id: r_idx } },
          _vm._l(row, function (item, n_idx) {
            return _c(
              "div",
              {
                key: n_idx,
                class: [
                  "item",
                  { hide: !item.num },
                  { has: _vm.listObj[item.num] === 0 },
                  { use: _vm.listObj[item.num] > 0 },
                  { uses: _vm.listObj[item.num] === -1 },
                  { check: item.num == _vm.nowNum },
                ],
                on: {
                  click: function ($event) {
                    return _vm.selectNum(item.num, item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }