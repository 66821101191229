var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell" }, [
    _c("div", { staticClass: "cell-item" }, [
      _vm.title != ""
        ? _c("div", { staticClass: "cell-title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm.subTitle != ""
        ? _c(
            "div",
            { staticClass: "cell-right-text", on: { click: _vm.subClick } },
            [_vm._v(" " + _vm._s(_vm.subTitle) + " ")]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "cell-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }