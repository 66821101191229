var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("底色")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.editFormatList.card_background_color,
                callback: function ($$v) {
                  _vm.$set(_vm.editFormatList, "card_background_color", $$v)
                },
                expression: "editFormatList.card_background_color",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("半透明")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("完全透明")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("卡片高度")]),
      _c(
        "div",
        { staticClass: "right", staticStyle: { display: "flex" } },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.content_height,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "content_height", $$v)
              },
              expression: "editFormatList.content_height",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("整体上下位置")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_position,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_position", $$v)
              },
              expression: "editFormatList.card_position",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("左右页边距")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_margin,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_margin", $$v)
              },
              expression: "editFormatList.card_margin",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("左右列边距")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_info_left_margin,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_info_left_margin", $$v)
              },
              expression: "editFormatList.card_info_left_margin",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("上下列边距")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.card_info_top_margin,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "card_info_top_margin", $$v)
              },
              expression: "editFormatList.card_info_top_margin",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }