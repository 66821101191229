//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VanSwitch',
  props: {
    switchList: {
      type: Array,
      default: function _default() {
        return [{
          title: '测试活动',
          id: 0,
          num: ''
        }, {
          title: '正式活动',
          id: 1,
          num: ''
        }];
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      // 初始值
      switchTab: 0
    };
  },
  created: function created() {
    for (var i = 0; i < this.switchList.length; i++) {
      if (this.switchList[i].num > 0) {
        this.switchTab = i;
        return;
      }
    }
  },
  methods: {
    // 正式&测试活动 切换
    bindSwitch: function bindSwitch(tabId, index) {
      // 组织多次点击
      if (this.switchTab === index) {
        return;
      }

      this.switchTab = index;
      this.$emit('bindSwitch', tabId);
    },
    // 添加活动 父组件传过来的参数
    handleSwitch: function handleSwitch(tabId) {
      this.bindSwitch(tabId, tabId);
    }
  }
};