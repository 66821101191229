import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: '中央壹号·公园'
    },
    subtitle: {
      type: String,
      default: '恭喜您，开盘成功！'
    },
    time: {
      type: String | Number,
      default: function _default() {
        return new Date().getTime() / 1000;
      }
    }
  }
};