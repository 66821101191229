import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { adminParameter } from '@/api/public';
import { randRoomList } from '@/api/lottery';
import { debounce } from '@/utils/debounce';
import listItem from './compontens/listItem.vue';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import utils from '@/utils/utils';
export default {
  name: 'LotteryList',
  components: {
    listItem: listItem,
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      appHeight: 0,
      // 表格数据
      tableData: [],
      // eventData
      eventData: '',
      // 800毫秒过后开始出现 loading
      hm: 800,
      select_event: {},
      // 选择的活动和场次id集
      eventList: [] // 项目活动列表

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles'])), {}, {
    tableHeight: function tableHeight() {
      // 90
      return this.appHeight - 95;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    var _this2 = this;

    //  项目配置
    adminParameter().then(function (res) {
      if (res.status === 200) {
        var event = res.data.event;
        _this2.eventData = event;
      }
    });
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };

      if (this.roles.includes('randroom/list') || this.roles.includes('admin')) {
        this.getTableList();
      }
    },
    // 跳转开始摇号
    handleStartLottery: debounce(function (row) {
      var isOk = true;

      for (var i = 0; i < this.eventList.length; i++) {
        if (this.eventList[i].type === 1 && this.eventList[i].end_time * 1000 <= new Date().getTime()) {
          isOk = false;
          break;
        }
      }

      if (!isOk) {
        this.$message({
          type: 'warning',
          message: '活动已结束'
        });
        return;
      }

      var _this$$router$resolve = this.$router.resolve({
        name: 'RandRoom',
        query: {
          id: row.id
        }
      }),
          href = _this$$router$resolve.href;

      window.open(href, '_blank');
    }, 200, true),
    // 改变时间格式
    doGetTime: function doGetTime(time) {
      return utils.formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    },
    // 获取表格数据
    getTableList: function getTableList() {
      var _this3 = this;

      var params = _objectSpread({}, this.select_event);

      randRoomList(params).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this3.tableData = list;
        }
      });
    }
  }
};