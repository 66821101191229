import "core-js/modules/es.json.stringify.js";
import { debounce } from '@/utils';
export default {
  data: function data() {
    return {
      socket: '',
      socketLoading: true,
      socketConnect: false,
      // socket是否已连接
      sendMsg: {
        // socket消息模板
        module: 'idcard',
        function: '',
        // autoreadcard 自动读身份信息 cancelreadcard 关闭自动读
        parameter: {
          dev: '1',
          repeat: '1',
          readtype: '1'
        }
      },
      socketConnect_debounce: ''
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.socketConnect_debounce = debounce(function () {
      var connect_timer = setTimeout(function () {
        clearTimeout(connect_timer);
        _this.socketLoading = true;
      }, 800); // 创建socket连接

      _this.socket = new WebSocket('ws://127.0.0.1:24012'); // 打开连接

      _this.socket.onopen = function (res) {
        console.log(res, '建立连接');
        clearTimeout(connect_timer);
        _this.socketConnect = true;
        _this.socketLoading = false;

        _this.send('open');
      }; // 连接失败


      _this.socket.onerror = function (res) {
        console.log(res, '失败');
        var leave_delay = setTimeout(function () {
          clearTimeout(connect_timer);
          clearTimeout(leave_delay);
          _this.socketConnect = false;
          _this.socketLoading = false;
        }, 400);
      }; // 连接断开


      _this.socket.onclose = function (res) {
        console.log(res, '断开');
        var leave_delay = setTimeout(function () {
          clearTimeout(connect_timer);
          clearTimeout(leave_delay);
          _this.socketConnect = false;
          _this.socketLoading = false;
        }, 400);
      }; // 接收消息


      _this.socket.onmessage = function (res) {
        try {
          var msg = JSON.parse(res.data);

          if (msg.event == 'onreadcard') {
            _this.searchParams.tel = '';
            _this.searchParams.card = msg.Certificate.IDNumber;

            _this.getUserList();
          }
        } catch (error) {
          _this.$message({
            type: 'error',
            message: error
          });
        }
      };
    }, 800, true);
  },
  methods: {
    wsConnect: function wsConnect() {
      this.socketConnect_debounce && this.socketConnect_debounce();
    },
    // 发消息 type: open 开启自动读取身份证 close 关闭自动读取
    send: function send(type) {
      if (!this.socketConnect || this.socketLoading || !this.socket) {
        console.log('身份证识别器没连接上');
        return;
      }

      if (type == 'open') {
        this.sendMsg.function = 'autoreadcard';
      } else {
        this.sendMsg.function = 'cancelreadcard';
      }

      this.socket.send(JSON.stringify(this.sendMsg));
    },
    // 主动关闭连接
    close: function close() {
      if (!this.socket) return;
      this.socket.close();
    }
  }
};