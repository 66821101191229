var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "block-box" }, [
    _c("div", { staticClass: "title-h2" }, [_vm._v("环节设置")]),
    _c("div", { staticClass: "link mT15" }, [
      _vm.data.show_audit_in
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: {
                      "class-name": "register",
                      "icon-class": "register",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0, label: 1 },
                      model: {
                        value: _vm.data.audit_in,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "audit_in", $$v)
                        },
                        expression: "data.audit_in",
                      },
                    },
                    [_vm._v("诚意登记")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "search-list-top" }, [
              _c("div", { staticClass: "i-box", style: _vm.style }, [
                _c("div", { staticClass: "tips-text", style: _vm.style }, [
                  _vm._v("诚意登记"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.data.show_is_sign_in
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: { "class-name": "SignIn", "icon-class": "SignIn" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0, label: 1 },
                      model: {
                        value: _vm.data.sign_in,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "sign_in", $$v)
                        },
                        expression: "data.sign_in",
                      },
                    },
                    [_vm._v("签到")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "search-list-top" }, [
              _c("div", { staticClass: "i-box", style: _vm.style }, [
                _vm.info.sign_type === 1
                  ? _c("div", { staticClass: "tips-text", style: _vm.style }, [
                      _vm._v("开盘签到"),
                    ])
                  : _c(
                      "div",
                      { staticClass: "tips-text style", style: _vm.style },
                      [_vm._v("签约签到")]
                    ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.data.show_is_buy
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: { "class-name": "room", "icon-class": "room" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: {
                        disabled: "",
                        "true-label": 1,
                        "false-label": 0,
                        label: 1,
                      },
                      on: { change: _vm.chooseRoom },
                      model: {
                        value: _vm.data.buy,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "buy", $$v)
                        },
                        expression: "data.buy",
                      },
                    },
                    [_vm._v("选房 ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "search-list-top" },
              [
                _vm.data.show_is_buy_rand
                  ? _c(
                      "div",
                      { staticClass: "i-box" },
                      [
                        _vm.info.buy_type === 2
                          ? _c("div", { staticClass: "tips-text" }, [
                              _vm._v("协助选房"),
                            ])
                          : [
                              _c("div", { staticClass: "tips-text" }, [
                                _vm._v(
                                  "自主选房" +
                                    _vm._s(_vm.info.buy_type === 2 ? ":" : "")
                                ),
                              ]),
                            ],
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "i-box", style: _vm.style },
                      [
                        _vm.info.buy_type === 2
                          ? _c("div", { staticClass: "tips-text" }, [
                              _vm._v("协助选房"),
                            ])
                          : [
                              _c(
                                "div",
                                { staticClass: "tips-text", style: _vm.style },
                                [
                                  _vm._v(
                                    "自主选房" +
                                      _vm._s(_vm.info.buy_type === 2 ? ":" : "")
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                _vm.data.show_is_buy_rand
                  ? _c(
                      "el-checkbox",
                      {
                        attrs: {
                          disabled: _vm.info.buy_type === 2 ? true : false,
                          "true-label": 1,
                          "false-label": 0,
                          label: 1,
                        },
                        on: { change: _vm.chaoseBuy },
                        model: {
                          value: _vm.data.buy_rand,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "buy_rand", $$v)
                          },
                          expression: "data.buy_rand",
                        },
                      },
                      [_vm._v(" 选房摇号 ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.data.show_is_price
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: { "class-name": "Deposit", "icon-class": "Deposit" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0, label: 1 },
                      on: { change: _vm.handlePrice },
                      model: {
                        value: _vm.data.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "price", $$v)
                        },
                        expression: "data.price",
                      },
                    },
                    [_vm._v("交定金 ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "search-list-top" },
              [
                _vm.data.price_type === 1
                  ? _c("div", { staticClass: "i-box", style: _vm.style }, [
                      _c(
                        "div",
                        { staticClass: "tips-text", style: _vm.style },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.data.price_type === 1
                                ? "线上交纳"
                                : "线下交纳"
                            )
                          ),
                        ]
                      ),
                    ])
                  : [
                      _vm.data.show_is_price_rand
                        ? _c("div", { staticClass: "i-box" }, [
                            _c("div", { staticClass: "tips-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.data.price_type === 1
                                    ? "线上交纳:"
                                    : "线下交纳:"
                                )
                              ),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "i-box", style: _vm.style },
                            [
                              _c(
                                "div",
                                { staticClass: "tips-text", style: _vm.style },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.data.price_type === 1
                                        ? "线上交纳"
                                        : "线下交纳"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                _vm.data.price_type === 2 && _vm.data.show_is_price_rand
                  ? _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0, label: 1 },
                        on: { change: _vm.handlePriceRand },
                        model: {
                          value: _vm.data.price_rand,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "price_rand", $$v)
                          },
                          expression: "data.price_rand",
                        },
                      },
                      [_vm._v(" 签约排号 ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.data.show_is_sign_up
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: { "class-name": "SignUp", "icon-class": "SignUp" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0, label: 1 },
                      on: { change: _vm.handleSignUP },
                      model: {
                        value: _vm.data.sign_up,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "sign_up", $$v)
                        },
                        expression: "data.sign_up",
                      },
                    },
                    [_vm._v("签约 ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "search-list-top" },
              [
                _vm.info.sign_up_type === 1
                  ? _c("div", { staticClass: "i-box", style: _vm.style }, [
                      _c(
                        "div",
                        { staticClass: "tips-text", style: _vm.style },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.info.sign_up_type === 1
                                ? "线上签约"
                                : "线下签约"
                            )
                          ),
                        ]
                      ),
                    ])
                  : [
                      _vm.data.show_is_sign_up_rand
                        ? _c("div", { staticClass: "i-box" }, [
                            _c("div", { staticClass: "tips-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.info.sign_up_type === 1
                                    ? "线上签约:"
                                    : "线下签约:"
                                )
                              ),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "i-box", style: _vm.style },
                            [
                              _c(
                                "div",
                                { staticClass: "tips-text", style: _vm.style },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.info.sign_up_type === 1
                                        ? "线上签约"
                                        : "线下签约"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                _vm.info.sign_up_type === 2 && _vm.data.show_is_sign_up_rand
                  ? [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            "true-label": 1,
                            "false-label": 0,
                            label: 1,
                          },
                          on: { change: _vm.handleSignUPRand },
                          model: {
                            value: _vm.data.sign_up_rand,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sign_up_rand", $$v)
                            },
                            expression: "data.sign_up_rand",
                          },
                        },
                        [_vm._v("签约排号 ")]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "交定金和签约都采用线下模式且都需要排号功能时，两个环节共用一场排号。",
                            placement: "bottom-start",
                          },
                        },
                        [_c("i", { staticClass: "tips el-icon-question" })]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.data.show_data_file
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-list-top" }, [
              _c(
                "div",
                { staticClass: "i-box" },
                [
                  _c("svg-icon", {
                    staticClass: "iconfont",
                    attrs: {
                      "class-name": "material",
                      "icon-class": "material",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-box" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0, label: 1 },
                      model: {
                        value: _vm.data.data_file,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "data_file", $$v)
                        },
                        expression: "data.data_file",
                      },
                    },
                    [_vm._v("资料存档 ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "search-list-top" }, [
              _c("div", { staticClass: "i-box", style: _vm.style }, [
                _c("div", { staticClass: "tips-text", style: _vm.style }, [
                  _vm._v("资料存档"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }