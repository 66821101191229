var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch" }, [
    _c(
      "div",
      { staticClass: "switch-box" },
      [
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        _c(
          "el-dropdown",
          { on: { command: _vm.handleCommand } },
          [
            _c("div", { staticClass: "switch-button" }, [_vm._v("切换项目")]),
            _c(
              "el-dropdown-menu",
              {
                staticStyle: { height: "500px", "overflow-y": "scroll" },
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "div",
                  { staticClass: "dropdown-box" },
                  [
                    _vm._l(_vm.projectList, function (item) {
                      return [
                        _c(
                          "el-dropdown-item",
                          { key: item.id, attrs: { command: item.id } },
                          [
                            _c(
                              "span",
                              { class: item.status === 2 ? "fail" : "" },
                              [
                                _vm._v(
                                  _vm._s(item.project_name) +
                                    _vm._s(
                                      item.status === 2 ? "（已失效）" : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }