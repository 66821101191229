//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tenThousandTrans } from '../util';
export default {
  props: {
    // 头部颜色
    headerColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.04)'
    },
    // 是否有border
    isHeaderBorder: {
      type: Boolean,
      default: true
    },
    // 头部数据
    tableHead: {
      type: Array,
      default: function _default() {
        return [{
          label: '姓名',
          prop: 'name'
        }, {
          label: '时间',
          prop: 'date'
        }, {
          label: '地址',
          prop: 'address'
        }];
      }
    },
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      headerCellStyleOne: {
        'background-color': 'rgba(0, 0, 0, 0.04)',
        color: 'rgb(103, 194, 58)',
        'border-bottom': '1px rgb(103, 194, 58) solid'
      },
      tenThousandTrans: null
    };
  },
  created: function created() {
    this.tenThousandTrans = tenThousandTrans;
  }
};