var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plane",
      staticStyle: {
        margin: "10px",
        "box-shadow": "0 5px 5px 5px rgba(0, 0, 0, 0.1)",
        padding: "10px",
        height: "calc(100vh - 20px)",
      },
    },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: {
            "margin-bottom": "15px",
            display: "flex",
            width: "100%",
            "flex-direction": "row",
            "justify-content": "center",
          },
          attrs: { fill: "#22A271", model: "tabText" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-radio-button",
            {
              class: { "radio-button": _vm.activeName !== "config" },
              staticStyle: { width: "25%" },
              attrs: { label: "config" },
            },
            [_vm._v("基础配置")]
          ),
          _c(
            "el-radio-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap", "room/setmap"],
                  expression: "['admin', 'car/setmap', 'room/setmap']",
                },
              ],
              class: { "radio-button": _vm.activeName !== "sort" },
              staticStyle: { width: "25%" },
              attrs: { label: "sort" },
            },
            [
              _c("span", { staticStyle: { padding: "0 10px" } }, [
                _vm._v("图层维护"),
              ]),
            ]
          ),
          _c(
            "el-radio-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap", "room/setmap"],
                  expression: "['admin', 'car/setmap', 'room/setmap']",
                },
              ],
              class: { "radio-button": _vm.activeName !== "add" },
              staticStyle: { width: "25%" },
              attrs: { label: "add" },
            },
            [
              _vm._v(
                "添加" +
                  _vm._s(
                    _vm.$parent.$parent.$parent.cate === 1 ? "房源" : "车位"
                  )
              ),
            ]
          ),
          _c(
            "el-radio-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "car/setmap", "room/setmap"],
                  expression: "['admin', 'car/setmap', 'room/setmap']",
                },
              ],
              class: { "radio-button": _vm.activeName !== "set" },
              staticStyle: { width: "25%" },
              attrs: { label: "set" },
            },
            [
              _vm._v(
                "修改" +
                  _vm._s(
                    _vm.$parent.$parent.$parent.cate === 1 ? "房源" : "车位"
                  )
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "config",
              expression: "activeName === 'config'",
            },
          ],
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "10px",
                  "font-size": "18px",
                  "font-weight": "bold",
                  "background-color": "rgb(245, 245, 245)",
                },
              },
              [_vm._v("平面图位置")]
            ),
            _vm.$parent.$parent.$parent.map_car.select.build &&
            _vm.$parent.$parent.$parent.map_car.select.build.length
              ? _c(
                  "div",
                  { staticStyle: { padding: "10px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _c(
                            "div",
                            { staticStyle: { "line-height": "30px" } },
                            [_vm._v("楼栋")]
                          ),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 14 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  size: "small",
                                  placeholder: "请选择楼栋",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$parent.$parent.$parent.onChange(
                                      "build"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$parent.$parent.$parent.map_car.build,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$parent.$parent.$parent.map_car,
                                      "build",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$parent.$parent.$parent.map_car.build",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "请选择", value: 0 },
                                }),
                                _vm._l(
                                  _vm.$parent.$parent.$parent.map_car.select
                                    .build,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$parent.$parent.$parent.map_car.select.floor &&
            _vm.$parent.$parent.$parent.map_car.select.floor.length
              ? _c(
                  "div",
                  { staticStyle: { padding: "10px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _c(
                            "div",
                            { staticStyle: { "line-height": "30px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$parent.$parent.$parent.cate === 1
                                      ? "单元"
                                      : "楼层"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 14 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  size: "small",
                                  placeholder:
                                    "请选择" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "单元"
                                      : "楼层"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$parent.$parent.$parent.onChange(
                                      "floor"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$parent.$parent.$parent.map_car.floor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$parent.$parent.$parent.map_car,
                                      "floor",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$parent.$parent.$parent.map_car.floor",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "请选择", value: 0 },
                                }),
                                _vm._l(
                                  _vm.$parent.$parent.$parent.map_car.select
                                    .floor,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$parent.$parent.$parent.map_car.select.region &&
            _vm.$parent.$parent.$parent.map_car.select.region.length
              ? _c(
                  "div",
                  { staticStyle: { padding: "10px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 10 } }, [
                          _c(
                            "div",
                            { staticStyle: { "line-height": "30px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$parent.$parent.$parent.cate === 1
                                      ? "楼层"
                                      : "区域"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 14 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  size: "small",
                                  placeholder:
                                    "请选择" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "楼层"
                                      : "区域"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$parent.$parent.$parent.onChange(
                                      "region"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$parent.$parent.$parent.map_car.region,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$parent.$parent.$parent.map_car,
                                      "region",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$parent.$parent.$parent.map_car.region",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "请选择", value: 0 },
                                }),
                                _vm._l(
                                  _vm.$parent.$parent.$parent.map_car.select
                                    .region,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "car/mapindex", "room/mapindex"],
                    expression: "['admin', 'car/mapindex', 'room/mapindex']",
                  },
                ],
                staticStyle: { padding: "10px" },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8, offset: 8 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "success", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$parent.$parent.$parent.onCart()
                              },
                            },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.$parent.$parent.$parent.isOnCart &&
          ((_vm.$parent.$parent.$parent.map_car.select.build.length &&
            _vm.$parent.$parent.$parent.map_car.build) ||
            (_vm.$parent.$parent.$parent.map_car.select.floor.length &&
              _vm.$parent.$parent.$parent.map_car.floor) ||
            (_vm.$parent.$parent.$parent.map_car.select.region.length &&
              _vm.$parent.$parent.$parent.map_car.region))
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "car/setmap", "room/setmap"],
                      expression: "['admin', 'car/setmap', 'room/setmap']",
                    },
                  ],
                  staticStyle: { "margin-top": "10px", width: "100%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "10px",
                        "font-size": "18px",
                        "font-weight": "bold",
                        "background-color": "rgb(245, 245, 245)",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$parent.$parent.$parent.cate === 1
                              ? "房源"
                              : "车位"
                          ) +
                          "图 "
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top" },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c("div", [
                              _vm._v("上传成功后需要点击保存才会生效！"),
                            ]),
                          ]),
                          _c("i", {
                            staticClass: "tips el-icon-question",
                            staticStyle: { "margin-left": "10px" },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { padding: "10px" } }, [
                    _c(
                      "div",
                      { staticClass: "img-up-icon" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.action,
                              "before-upload": _vm.uploadBefore,
                              headers: _vm.header,
                              limit: 1,
                              "on-error": _vm.uploadFail,
                              "on-success": _vm.uploadSucess,
                              "show-file-list": false,
                              accept: _vm.$imgSel,
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c("div", { staticClass: "img-icon" }, [
                              _c("div", { staticClass: "line" }),
                              _c("div", { staticClass: "line-copy" }),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "tip" }, [_vm._v("上传替换")]),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { width: "100%", "margin-top": "10px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "10px",
                    "font-size": "18px",
                    "font-weight": "bold",
                    "background-color": "rgb(245, 245, 245)",
                  },
                },
                [
                  _vm._v(" 颜色 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "修改后需要点击保存才会生效！",
                        placement: "top",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "tips el-icon-question",
                        staticStyle: { "margin-left": "10px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._l(
                _vm.$parent.$parent.$parent.room_status_color,
                function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { padding: "10px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "30px" } },
                              [_vm._v(" " + _vm._s(item.text) + " ")]
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value: item.color,
                                  callback: function ($$v) {
                                    _vm.$set(item, "color", $$v)
                                  },
                                  expression: "item.color",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 15 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "7px",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: true },
                                    model: {
                                      value: item.show,
                                      callback: function ($$v) {
                                        _vm.$set(item, "show", $$v)
                                      },
                                      expression: "item.show",
                                    },
                                  },
                                  [_vm._v("显示")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: false },
                                    model: {
                                      value: item.show,
                                      callback: function ($$v) {
                                        _vm.$set(item, "show", $$v)
                                      },
                                      expression: "item.show",
                                    },
                                  },
                                  [_vm._v("隐藏")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          ),
          _c("div", { staticStyle: { width: "100%", "margin-top": "10px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "10px",
                  "font-size": "18px",
                  "font-weight": "bold",
                  "background-color": "rgb(245, 245, 245)",
                },
              },
              [_vm._v("灵敏度")]
            ),
            _c(
              "div",
              { staticStyle: { padding: "10px" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 10 } }, [
                      _c("div", { staticStyle: { "line-height": "30px" } }, [
                        _vm._v("滚轮缩放"),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 14 } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              size: "small",
                              placeholder: "请选择滚轮缩放灵敏度",
                            },
                            model: {
                              value:
                                _vm.$parent.$parent.$parent.sensitivity.zoom,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$parent.$parent.$parent.sensitivity,
                                  "zoom",
                                  $$v
                                )
                              },
                              expression:
                                "$parent.$parent.$parent.sensitivity.zoom",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "慢", value: 0.02 },
                            }),
                            _c("el-option", {
                              attrs: { label: "正常", value: 0.1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "快", value: 0.3 },
                            }),
                            _c("el-option", {
                              attrs: { label: "特快", value: 0.6 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { padding: "10px" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 10 } }, [
                      _c("div", { staticStyle: { "line-height": "30px" } }, [
                        _vm._v("鼠标移动整体"),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 14 } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              size: "small",
                              placeholder: "请选择滚轮缩放灵敏度",
                            },
                            model: {
                              value:
                                _vm.$parent.$parent.$parent.sensitivity.move,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$parent.$parent.$parent.sensitivity,
                                  "move",
                                  $$v
                                )
                              },
                              expression:
                                "$parent.$parent.$parent.sensitivity.move",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "慢", value: 0.5 },
                            }),
                            _c("el-option", {
                              attrs: { label: "正常", value: 2 },
                            }),
                            _c("el-option", {
                              attrs: { label: "快", value: 4 },
                            }),
                            _c("el-option", {
                              attrs: { label: "特快", value: 6 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "add",
              expression: "activeName === 'add'",
            },
          ],
          staticStyle: { height: "calc(100vh - 110px)", "overflow-y": "auto" },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { "line-height": "35px" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$parent.$parent.$parent.cate === 1
                                ? "房源"
                                : "车位"
                            ) +
                            "名称 "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "仅对选房端生效！",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "tips el-icon-question",
                              staticStyle: { "margin-left": "10px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "7px" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: true },
                            model: {
                              value: _vm.addCart.isFont,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "isFont", $$v)
                              },
                              expression: "addCart.isFont",
                            },
                          },
                          [_vm._v("显示")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: false },
                            model: {
                              value: _vm.addCart.isFont,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "isFont", $$v)
                              },
                              expression: "addCart.isFont",
                            },
                          },
                          [_vm._v("不显示")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.addCart.isFont
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("字体大小"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { min: 4 },
                            model: {
                              value: _vm.addCart.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "fontSize", $$v)
                              },
                              expression: "addCart.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.addCart.isFont
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("字体颜色"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.addCart.fontColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "fontColor", $$v)
                              },
                              expression: "addCart.fontColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticStyle: { "line-height": "35px" } }, [
                      _vm._v("形状"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.addCart.round,
                            callback: function ($$v) {
                              _vm.$set(_vm.addCart, "round", $$v)
                            },
                            expression: "addCart.round",
                          },
                        },
                        [_vm._v("四边形")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.addCart.round,
                            callback: function ($$v) {
                              _vm.$set(_vm.addCart, "round", $$v)
                            },
                            expression: "addCart.round",
                          },
                        },
                        [_vm._v("圆形")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { "line-height": "35px" } },
                      [
                        _vm._v(" 模拟状态 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "可以模拟当前" +
                                (_vm.$parent.$parent.$parent.cate === 1
                                  ? "房源"
                                  : "车位") +
                                "在该状态时的背景颜色，状态背景色取于基础设置中的颜色！",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "tips el-icon-question",
                              staticStyle: { "margin-left": "10px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            size: "small",
                            placeholder: "请选择状态",
                          },
                          model: {
                            value: _vm.addCart.background_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.addCart, "background_color", $$v)
                            },
                            expression: "addCart.background_color",
                          },
                        },
                        _vm._l(
                          _vm.$parent.$parent.$parent.room_status_color,
                          function (item, index) {
                            return item.show
                              ? _c("el-option", {
                                  key: index,
                                  attrs: { label: item.text, value: index },
                                })
                              : _vm._e()
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.addCart.round
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("宽度"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { min: 10 },
                            model: {
                              value: _vm.addCart.width,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "width", $$v)
                              },
                              expression: "addCart.width",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.addCart.round
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("高度"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { min: 10 },
                            model: {
                              value: _vm.addCart.height,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "height", $$v)
                              },
                              expression: "addCart.height",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.addCart.round
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("半径"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { min: 5 },
                            model: {
                              value: _vm.addCart.radius,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "radius", $$v)
                              },
                              expression: "addCart.radius",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$parent.$parent.$parent.threeCartList.length
            ? _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("批量选择"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "collapse-tags": "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.addCart.batch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCart, "batch", $$v)
                                },
                                expression: "addCart.batch",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "请选择", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "10个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位"),
                                  value: 10,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "20个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位"),
                                  value: 20,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "30个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位"),
                                  value: 30,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "50个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位"),
                                  value: 50,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label:
                                    "100个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位"),
                                  value: 100,
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "全部", value: 1000000000 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticStyle: { "line-height": "35px" } }, [
                      _vm._v(
                        "选择" +
                          _vm._s(
                            _vm.$parent.$parent.$parent.cate === 1
                              ? "房源"
                              : "车位"
                          )
                      ),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !!_vm.addCart.batch,
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.addCart.ids,
                            callback: function ($$v) {
                              _vm.$set(_vm.addCart, "ids", $$v)
                            },
                            expression: "addCart.ids",
                          },
                        },
                        _vm._l(
                          _vm.$parent.$parent.$parent.threeCartList,
                          function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.room, value: item.id_room },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { "line-height": "35px" } },
                      [
                        _vm._v(" 排序方式 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "多个" +
                                (_vm.$parent.$parent.$parent.cate === 1
                                  ? "房源"
                                  : "车位") +
                                "的渲染方向！",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "tips el-icon-question",
                              staticStyle: { "margin-left": "10px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            size: "small",
                            placeholder: "请选择排序方式",
                          },
                          model: {
                            value: _vm.addCart.sort,
                            callback: function ($$v) {
                              _vm.$set(_vm.addCart, "sort", $$v)
                            },
                            expression: "addCart.sort",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { label: "→ 从左到右", value: "left" } },
                            [
                              _c("i", { staticClass: "el-icon-right" }),
                              _vm._v(" 从左到右 "),
                            ]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "← 从右到左", value: "right" } },
                            [
                              _c("i", { staticClass: "el-icon-back" }),
                              _vm._v(" 从右到左 "),
                            ]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "↓ 从上到下", value: "top" } },
                            [
                              _c("i", { staticClass: "el-icon-bottom" }),
                              _vm._v(" 从上到下 "),
                            ]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "↑ 从下到上", value: "bottom" } },
                            [
                              _c("i", { staticClass: "el-icon-top" }),
                              _vm._v(" 从下到上 "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "btn_isShowZK1" }, [
                _vm._v(" --------其他参数 "),
                _vm.isAddUp
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.isAddUp = false
                          },
                        },
                      },
                      [
                        _vm._v(" 展开"),
                        _c("i", { staticClass: "el-icon-arrow-down" }),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.isAddUp = true
                          },
                        },
                      },
                      [
                        _vm._v(" 收起"),
                        _c("i", { staticClass: "el-icon-arrow-up" }),
                      ]
                    ),
                _vm._v(" -------- "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isAddUp,
                  expression: "!isAddUp",
                },
              ],
            },
            [
              _vm.addCart.sort === "top" || _vm.addCart.sort === "bottom"
                ? _c(
                    "div",
                    { staticStyle: { padding: "10px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "35px" } },
                              [
                                _vm._v(" X的类型 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "每一个" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "的左右间距类型！",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "10px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    size: "small",
                                    placeholder: "请选择类型",
                                  },
                                  model: {
                                    value: _vm.addCart.xType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addCart, "xType", $$v)
                                    },
                                    expression: "addCart.xType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label:
                                        "单个" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "间距",
                                      value: "one",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label:
                                        "整列" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "间距",
                                      value: "all",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticStyle: { "line-height": "35px" } },
                          [
                            _vm._v(" X "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "每一个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位") +
                                    "的左右间距！",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "tips el-icon-question",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.addCart.x,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "x", $$v)
                              },
                              expression: "addCart.x",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.addCart.sort === "left" || _vm.addCart.sort === "right"
                ? _c(
                    "div",
                    { staticStyle: { padding: "10px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "35px" } },
                              [
                                _vm._v(" Y的类型 "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "每一个" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "的上下间距类型！",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                      staticStyle: { "margin-left": "10px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    size: "small",
                                    placeholder: "请选择类型",
                                  },
                                  model: {
                                    value: _vm.addCart.yType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addCart, "yType", $$v)
                                    },
                                    expression: "addCart.yType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label:
                                        "单个" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "间距",
                                      value: "one",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label:
                                        "整行" +
                                        (_vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位") +
                                        "间距",
                                      value: "all",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "div",
                          { staticStyle: { "line-height": "35px" } },
                          [
                            _vm._v(" Y "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "每一个" +
                                    (_vm.$parent.$parent.$parent.cate === 1
                                      ? "房源"
                                      : "车位") +
                                    "的上下间距！",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "tips el-icon-question",
                                  staticStyle: { "margin-left": "10px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.addCart.y,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "y", $$v)
                              },
                              expression: "addCart.y",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { padding: "10px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticStyle: { "line-height": "35px" } }, [
                          _vm._v("旋转角度"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { min: -360, max: 360 },
                            model: {
                              value: _vm.addCart.rotationZ,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCart, "rotationZ", $$v)
                              },
                              expression: "addCart.rotationZ",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "success", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onOper("addSubmit")
                        },
                      },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "warning", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onOper("addReset")
                        },
                      },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "set",
              expression: "activeName === 'set'",
            },
          ],
        },
        [
          _c(
            "div",
            { staticStyle: { height: "50px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 15 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: !(
                              _vm.$parent.$parent.$parent.sceneChildrens
                                .length &&
                              !_vm.$parent.$parent.$parent.isCkickAddData &&
                              !!_vm.$parent.$parent.$parent.imgPath
                            ),
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSetSelectCar("selectCar")
                            },
                          },
                          model: {
                            value: _vm.sceneChildrens,
                            callback: function ($$v) {
                              _vm.sceneChildrens = $$v
                            },
                            expression: "sceneChildrens",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "all" },
                          }),
                          _c("el-option", {
                            attrs: { label: "清空", value: "del" },
                          }),
                          _vm._l(
                            _vm.$parent.$parent.$parent.sceneChildrens,
                            function (item) {
                              return _c("el-option", {
                                key: item.Id,
                                attrs: { label: item.Name, value: item.Id },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "orange-btn fixed-btn",
                          staticStyle: {
                            width: "90%",
                            "margin-left": "10%",
                            height: "37px",
                          },
                          attrs: {
                            disabled: !(
                              _vm.$parent.$parent.$parent.sceneChildrens
                                .length &&
                              !_vm.$parent.$parent.$parent.isCkickAddData &&
                              !!_vm.$parent.$parent.$parent.imgPath
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSetSelectCar("button")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "框选" +
                              _vm._s(
                                _vm.$parent.$parent.$parent.cate === 1
                                  ? "房源"
                                  : "车位"
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 160px)",
                "overflow-y": "auto",
                width: "100%",
              },
            },
            [
              _vm.sceneChildrens.length && _vm.sceneChildrens[0]
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-weight": "bold",
                          "font-size": "25px",
                          padding: "10px",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.setCart.name +
                                (_vm.sceneChildrens.length > 1
                                  ? " 等" +
                                    (_vm.sceneChildrens.length - 1) +
                                    "个"
                                  : "")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { padding: "10px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "35px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$parent.$parent.$parent.cate === 1
                                          ? "房源"
                                          : "车位"
                                      ) +
                                      "名称 "
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "仅对选房端生效！",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tips el-icon-question",
                                        staticStyle: { "margin-left": "10px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "7px" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: true },
                                      model: {
                                        value: _vm.setCart.isFont,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "isFont", $$v)
                                        },
                                        expression: "setCart.isFont",
                                      },
                                    },
                                    [_vm._v("显示")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: false },
                                      model: {
                                        value: _vm.setCart.isFont,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "isFont", $$v)
                                        },
                                        expression: "setCart.isFont",
                                      },
                                    },
                                    [_vm._v("不显示")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.setCart.isFont
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("字体大小")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { min: 4 },
                                      model: {
                                        value: _vm.setCart.fontSize,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "fontSize", $$v)
                                        },
                                        expression: "setCart.fontSize",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setCart.isFont
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("字体颜色")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-color-picker", {
                                      model: {
                                        value: _vm.setCart.fontColor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.setCart,
                                            "fontColor",
                                            $$v
                                          )
                                        },
                                        expression: "setCart.fontColor",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticStyle: { padding: "10px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "35px" } },
                                [_vm._v("形状")]
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "7px" } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: false },
                                      model: {
                                        value: _vm.setCart.round,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "round", $$v)
                                        },
                                        expression: "setCart.round",
                                      },
                                    },
                                    [_vm._v("四边形")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: true },
                                      model: {
                                        value: _vm.setCart.round,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "round", $$v)
                                        },
                                        expression: "setCart.round",
                                      },
                                    },
                                    [_vm._v("圆形")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { padding: "10px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "35px" } },
                                [
                                  _vm._v(" 模拟状态 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "可以模拟当前" +
                                          (_vm.$parent.$parent.$parent.cate ===
                                          1
                                            ? "房源"
                                            : "车位") +
                                          "在该状态时的背景颜色，状态背景色取于基础设置中的颜色！",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tips el-icon-question",
                                        staticStyle: { "margin-left": "10px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      size: "small",
                                      placeholder: "请选择状态",
                                    },
                                    model: {
                                      value: _vm.setCart.background_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.setCart,
                                          "background_color",
                                          $$v
                                        )
                                      },
                                      expression: "setCart.background_color",
                                    },
                                  },
                                  _vm._l(
                                    _vm.$parent.$parent.$parent
                                      .room_status_color,
                                    function (item, index) {
                                      return item.show
                                        ? _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.text,
                                              value: index,
                                            },
                                          })
                                        : _vm._e()
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.setCart.round
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("宽度")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { min: 10 },
                                      model: {
                                        value: _vm.setCart.width,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "width", $$v)
                                        },
                                        expression: "setCart.width",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.setCart.round
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("高度")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { min: 10 },
                                      model: {
                                        value: _vm.setCart.height,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "height", $$v)
                                        },
                                        expression: "setCart.height",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.setCart.round
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("半径")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: { min: 5 },
                                      model: {
                                        value: _vm.setCart.radius,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "radius", $$v)
                                        },
                                        expression: "setCart.radius",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.sceneChildrens.length === 1
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [
                                      _vm._v(" X "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "当前" +
                                              (_vm.$parent.$parent.$parent
                                                .cate === 1
                                                ? "房源"
                                                : "车位") +
                                              "的水平位置！",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "tips el-icon-question",
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.setCart.x,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "x", $$v)
                                        },
                                        expression: "setCart.x",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.sceneChildrens.length === 1
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [
                                      _vm._v(" Y "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "当前" +
                                              (_vm.$parent.$parent.$parent
                                                .cate === 1
                                                ? "房源"
                                                : "车位") +
                                              "的垂直位置！",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "tips el-icon-question",
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.setCart.y,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.setCart, "y", $$v)
                                        },
                                        expression: "setCart.y",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticStyle: { padding: "10px" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "35px" } },
                                [_vm._v("旋转角度")]
                              ),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { min: -360, max: 360 },
                                  model: {
                                    value: _vm.setCart.rotationZ,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.setCart, "rotationZ", $$v)
                                    },
                                    expression: "setCart.rotationZ",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.sceneChildrens.length !== 1
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [_vm._v("对齐目标")]
                                  ),
                                ]),
                                _c("el-col", { attrs: { span: 16 } }, [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            size: "small",
                                            placeholder: "请选择对齐目标",
                                          },
                                          model: {
                                            value: _vm.setCart.alignTarget,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.setCart,
                                                "alignTarget",
                                                $$v
                                              )
                                            },
                                            expression: "setCart.alignTarget",
                                          },
                                        },
                                        _vm._l(
                                          _vm.$parent.$parent.$parent
                                            .sceneChildrens,
                                          function (item) {
                                            return _c("el-option", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.sceneChildrens.indexOf(
                                                      item.Id
                                                    ) >= 0,
                                                  expression:
                                                    "sceneChildrens.indexOf(item.Id) >= 0",
                                                },
                                              ],
                                              key: item.Id,
                                              attrs: {
                                                label: item.Name,
                                                value: item.Id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.sceneChildrens.length !== 1
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [
                                      _vm._v(" 对齐方式 "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "对齐目标”的中心点为标准进行纵向(左右对齐)、横向(上下对齐)的对齐！",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "tips el-icon-question",
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("el-col", { attrs: { span: 16 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "7px" } },
                                    [
                                      _c("el-checkbox", {
                                        attrs: {
                                          label: "左右对齐",
                                          "true-label": "left",
                                          "false-label": "",
                                        },
                                        model: {
                                          value: _vm.setCart.align,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.setCart, "align", $$v)
                                          },
                                          expression: "setCart.align",
                                        },
                                      }),
                                      _c("el-checkbox", {
                                        attrs: {
                                          label: "上下对齐",
                                          "true-label": "top",
                                          "false-label": "",
                                        },
                                        model: {
                                          value: _vm.setCart.align,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.setCart, "align", $$v)
                                          },
                                          expression: "setCart.align",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticStyle: { padding: "10px" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { type: "success", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOper("setSubmit")
                                  },
                                },
                              },
                              [_vm._v("确定 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8, offset: 2 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { type: "danger", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOper("del")
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4, offset: 2 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "仅支持最近10次操作撤回（支持Ctrl+Z）",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "orange-btn",
                                    staticStyle: {
                                      width: "100%",
                                      height: "37px",
                                      "text-align": "center",
                                      padding: "0",
                                    },
                                    attrs: {
                                      disabled: !_vm.recallDatas.length,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doSetRecallDatas("recall")
                                      },
                                    },
                                  },
                                  [_vm._v("撤回")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "setMsg" }, [
                    _vm._v(
                      "请先在左边平面图中点击" +
                        _vm._s(
                          _vm.$parent.$parent.$parent.cate === 1
                            ? "房源"
                            : "车位"
                        ) +
                        "或者上方批量选择"
                    ),
                  ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "sort",
              expression: "activeName === 'sort'",
            },
          ],
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                size: "medium",
                data: _vm.sortData,
                "header-cell-style": { background: "#fafafa" },
                "highlight-current-row": "",
                "element-loading-background": "rgba(0, 0, 0, 0.5)",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", prop: "name", label: "平面图" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "name",
                  label: "排序",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.$index !== 0
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "向上换位",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-top",
                                  staticStyle: {
                                    "margin-right": "15px",
                                    color: "#22a271",
                                    "font-size": "18px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSort("top", scope.$index)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _c("span", { staticStyle: { padding: "15px" } }),
                        scope.$index !== _vm.sortData.length - 1
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "向下换位",
                                  placement: "top-end",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-bottom",
                                  staticStyle: {
                                    "margin-right": "15px",
                                    color: "#22a271",
                                    "font-size": "18px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSort("bottom", scope.$index)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "name",
                  label: "操作",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tooltip", { attrs: { content: "删除平面图" } }, [
                          _c("i", {
                            staticClass: "el-icon-delete greens",
                            on: {
                              click: function ($event) {
                                return _vm.onDel(scope.row)
                              },
                            },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { type: "success", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.onSort("submit")
                },
              },
            },
            [_vm._v("保存排序")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }