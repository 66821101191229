var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-wrapper" }, [
    _c(
      "div",
      { ref: "app", staticClass: "container" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.reserved,
                expression: "reserved",
              },
            ],
            staticClass: "default-bg",
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "default-tip" }, [
              _vm._v("还没有创建预留名单哦~"),
            ]),
            _c(
              "div",
              { staticClass: "create-btn-wrapper" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "greens-btn",
                    attrs: { round: "", size: "mini" },
                    on: { click: _vm.toCreate },
                  },
                  [_vm._v("去创建")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "reserved" }, [
          _c("div", { ref: "apptop", staticClass: "content-top" }, [
            _c("div", { staticClass: "content-top-title" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "top-title-right" },
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.checkGroupChange },
                      model: {
                        value: _vm.isNoUser,
                        callback: function ($$v) {
                          _vm.isNoUser = $$v
                        },
                        expression: "isNoUser",
                      },
                    },
                    [_vm._v("无客户" + _vm._s(_vm.customSetting.title) + " ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.checkGroupChange },
                      model: {
                        value: _vm.isSameName,
                        callback: function ($$v) {
                          _vm.isSameName = $$v
                        },
                        expression: "isSameName",
                      },
                    },
                    [_vm._v("导入姓名和系统姓名不一致")]
                  ),
                  _c(
                    "div",
                    { staticClass: "top-title-input" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "姓名\\导入手机号\\系统手机号\\" +
                            _vm.customSetting.custom_data.house_num_search,
                          size: "small",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.subSearch($event)
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "greens-btn",
                          attrs: { size: "small" },
                          on: { click: _vm.subSearch },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btnHollowReset",
                          attrs: { size: "small" },
                          on: { click: _vm.clear },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "content-top-status mT16" }, [
              _vm.isNeedApproval == 1
                ? _c(
                    "div",
                    {
                      staticClass: "header-status",
                      style: [{ "--bg": _vm.bg }, { "--fontcolor": _vm.color }],
                    },
                    [
                      _vm.isNeedApproval == 1
                        ? _c("div", { staticClass: "title" }, [
                            _vm.eventReserve.status == 0 ||
                            _vm.eventReserve.status == 4
                              ? _c("span", [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.eventReserve.CreatedAt * 1000,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.eventReserve.status == 1 ||
                            _vm.eventReserve.status == 2 ||
                            _vm.eventReserve.status == 3
                              ? _c("span", [
                                  _vm._v(
                                    "发起时间：" +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.eventReserve.time * 1000,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "header-status-name" }, [
                        _vm._v(_vm._s(_vm.statusFun(_vm.eventReserve.status))),
                      ]),
                      _c("span", { staticClass: "title-right-count" }, [
                        _c("span", [
                          _vm._v(
                            "共" +
                              _vm._s(_vm.roomCount) +
                              " " +
                              _vm._s(_vm.customSetting.unit) +
                              " " +
                              _vm._s(_vm.customSetting.title) +
                              "，" +
                              _vm._s(_vm.userCount) +
                              "个客户"
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "header-status",
                      style: [
                        { "--bg": "rgba(34, 162, 113, 0.15)" },
                        { "--fontcolor": "rgb(34, 162, 113)" },
                      ],
                    },
                    [
                      _vm.isNeedApproval == 0
                        ? _c("div", { staticClass: "title" }, [
                            _vm.eventReserve.status == 0 ||
                            _vm.eventReserve.status == 4
                              ? _c("span", [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.eventReserve.CreatedAt * 1000,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "header-status-name" }, [
                        _vm._v("无需审批"),
                      ]),
                      _c("span", { staticClass: "title-right-count" }, [
                        _c("span", [
                          _vm._v(
                            "共" +
                              _vm._s(_vm.roomCount) +
                              " " +
                              _vm._s(_vm.customSetting.unit) +
                              " " +
                              _vm._s(_vm.customSetting.title) +
                              "，" +
                              _vm._s(_vm.userCount) +
                              "个客户"
                          ),
                        ]),
                      ]),
                    ]
                  ),
            ]),
            _c("div", { staticClass: "button-box align-right" }, [
              _c(
                "div",
                { staticClass: "btn-left" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.eventReserve.status == 0 ||
                            _vm.eventReserve.status == 4 ||
                            _vm.isNeedApproval == 0,
                          expression:
                            "eventReserve.status == 0 || eventReserve.status == 4 || isNeedApproval == 0",
                        },
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "reserv/delete"],
                          expression: "['admin', 'reserv/delete']",
                        },
                      ],
                      staticClass: "greens-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.batchDelete },
                    },
                    [_vm._v("批量删除 ")]
                  ),
                  _vm.checkedNum > 0
                    ? _c("span", { staticClass: "checked-num" }, [
                        _vm._v("已选 " + _vm._s(_vm.checkedNum) + " 条"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "btn-right" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "reserv/status"],
                        expression: "['admin', 'reserv/status']",
                      },
                    ],
                    staticClass: "switch-btn",
                  },
                  [
                    _c("custom-switch", {
                      attrs: {
                        svalue: _vm.eventReserve.is_test == 1,
                        ltext: "测试活动",
                      },
                      on: { change: _vm.switchChange },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (_vm.eventReserve.status !== 1 &&
                                _vm.eventReserve.status !== 2) ||
                              _vm.isNeedApproval == 0,
                            expression:
                              "(eventReserve.status !== 1 && eventReserve.status !== 2) || isNeedApproval == 0",
                          },
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "reserv/add"],
                            expression: "['admin', 'reserv/add']",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.addNewDiglogClick(0)
                          },
                        },
                      },
                      [_vm._v("新增预留 ")]
                    ),
                    _vm.projectList.cate_room === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "reserv/import"],
                                expression: "['admin', 'reserv/import']",
                              },
                            ],
                            staticClass: "btnHollowGreen",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadtemp(1)
                              },
                            },
                          },
                          [_vm._v("下载模板(房源) ")]
                        )
                      : _vm._e(),
                    [
                      _vm.projectList.cate_villa === 1 ||
                      _vm.projectList.cate_shops === 1 ||
                      _vm.projectList.cate_car === 1
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "reserv/import"],
                                  expression: "['admin', 'reserv/import']",
                                },
                              ],
                              staticClass: "btnHollowGreen",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadtemp(2)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "下载模板(" +
                                  _vm._s(
                                    _vm.customSetting.villa_setting.house_name
                                  ) +
                                  ") "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    _c(
                      "el-upload",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.eventReserve.status == 0 ||
                              _vm.eventReserve.status == 4 ||
                              _vm.isNeedApproval == 0,
                            expression:
                              "eventReserve.status == 0 || eventReserve.status == 4 || isNeedApproval == 0",
                          },
                        ],
                        ref: "upload",
                        staticClass: "el-upload",
                        attrs: {
                          action: _vm.local,
                          headers: _vm.header,
                          limit: 1,
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-change": _vm.uploadChange,
                          "on-success": _vm.uploadSucess,
                          "on-error": _vm.handleError,
                          "show-file-list": false,
                          accept: _vm.$xmlSel,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["admin", "reserv/import"],
                                expression: "['admin', 'reserv/import']",
                              },
                            ],
                            staticClass: "btnHollowGreen",
                            attrs: { size: "small" },
                          },
                          [_vm._v("导入 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "reserv/export"],
                            expression: "['admin', 'reserv/export']",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small" },
                        on: { click: _vm.exportAllData },
                      },
                      [_vm._v("导出复核 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              (_vm.eventReserve.status == 0 &&
                                _vm.isNeedApproval == 1) ||
                              (_vm.eventReserve.status == 4 &&
                                _vm.isNeedApproval == 1),
                            expression:
                              "(eventReserve.status == 0 && isNeedApproval == 1) || (eventReserve.status == 4 && isNeedApproval == 1)",
                          },
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["admin", "reserv/audit"],
                            expression: "['admin', 'reserv/audit']",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: { click: _vm.approval },
                      },
                      [_vm._v("发起审批 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.eventReserve.status == 1,
                            expression: "eventReserve.status == 1",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: { click: _vm.cancelApproval },
                      },
                      [_vm._v("撤回审批 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.eventReserve.status != 4 &&
                              _vm.eventReserve.status != 0 &&
                              _vm.isNeedApproval == 1,
                            expression:
                              "eventReserve.status != 4 && eventReserve.status != 0 && isNeedApproval == 1",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: { click: _vm.edit },
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.isNeedApproval == 1 &&
                              _vm.eventReserve.status == 3,
                            expression:
                              "isNeedApproval == 1 && eventReserve.status == 3",
                          },
                        ],
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: { click: _vm.seeReason },
                      },
                      [_vm._v("查看审批驳回原因 ")]
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.tableData,
                      "header-cell-style": { background: "#fafafa" },
                      height: _vm.tableHeight,
                      border: "",
                      size: "medium",
                    },
                    on: { "selection-change": _vm.selectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        type: "selection",
                        width: "55",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "序号",
                        type: "index",
                        width: "55",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "justify",
                        label: "建筑类型",
                        prop: "cate",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.cate == 1
                                      ? "房源"
                                      : _vm.customSetting.villa_setting
                                          .house_name
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.customSetting.custom_data.house_num,
                        align: "justify",
                        prop: "room_name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "color",
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.room_name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "登录手机号",
                        prop: "sys_tel",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "justify",
                        label: "系统姓名",
                        prop: "sys_name",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "color",
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        name: "UserDetails",
                                        query: { id: scope.row.user_id },
                                      })
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.sys_name) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "justify",
                        label: "导入姓名",
                        prop: "name",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "操作",
                        prop: "id",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "修改",
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "reserv/edit"],
                                        expression: "['admin', 'reserv/edit']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNewDiglogClick(
                                          1,
                                          scope.row
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                { attrs: { content: "解除预留" } },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "reserv/unlock"],
                                        expression:
                                          "['admin', 'reserv/unlock']",
                                      },
                                    ],
                                    attrs: {
                                      placement: "top",
                                      "class-name": "greens",
                                      "icon-class": "relieve",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.unLock(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "删除",
                                    effect: "dark",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.eventReserve.status == 0 ||
                                          _vm.isNeedApproval == 0,
                                        expression:
                                          "eventReserve.status == 0 || isNeedApproval == 0",
                                      },
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "reserv/delete"],
                                        expression:
                                          "['admin', 'reserv/delete']",
                                      },
                                    ],
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delItems(scope.row.id)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.currentPage,
                    layout: _vm.layouts,
                    "page-size": _vm.pageSize,
                    "page-sizes": _vm.sizes,
                    total: _vm.total,
                  },
                  on: {
                    "current-change": _vm.handleCurrentChange,
                    "size-change": _vm.handleSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.afterUpMesDialog,
              center: "",
              "destroy-on-close": "",
              title: "导入完毕",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.afterUpMesDialog = $event
              },
            },
          },
          [
            _c("div", { staticClass: "dialog" }, [
              _c("div", { staticClass: "detail-item" }, [
                _c("span", { staticClass: "name green" }, [_vm._v("导入成功")]),
                _c("span", { staticClass: "text green" }, [
                  _vm._v(_vm._s(_vm.afterUpMes.count[0]) + "条"),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.afterUpMes.count[1] != 0,
                      expression: "afterUpMes.count[1] != 0",
                    },
                  ],
                  staticClass: "detail-item red",
                },
                [
                  _c("span", { staticClass: "name red" }, [_vm._v("导入失败")]),
                  _c("span", { staticClass: "text red" }, [
                    _vm._v(_vm._s(_vm.afterUpMes.count[1]) + "条"),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.afterUpMes.count[2] != 0,
                      expression: "afterUpMes.count[2] != 0",
                    },
                  ],
                  staticClass: "detail-item red",
                },
                [
                  _c("span", { staticClass: "name red" }, [_vm._v("重复导入")]),
                  _c("span", { staticClass: "text red" }, [
                    _vm._v(_vm._s(_vm.afterUpMes.count[2]) + "条"),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "orange-btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.afterUpMesDialog = false
                      },
                    },
                  },
                  [_vm._v("关 闭")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.addNewDiglogTitle,
              visible: _vm.addNewDiglog,
              width: _vm.isMobile ? "100%" : "470px",
              "close-on-click-modal": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.addNewDiglog = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "addnew-diglog-content" },
              [
                _c(
                  "el-form",
                  {
                    attrs: { "label-width": "90px", "label-position": "left" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "客户手机号", "label-width": "120px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "addnew-search-wrapper" },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "11",
                                placeholder:
                                  "姓名\\手机号\\" +
                                  _vm.$adminList.user_code_name +
                                  "\\身份证号",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchUsersByPhone($event)
                                },
                              },
                              model: {
                                value: _vm.userTel,
                                callback: function ($$v) {
                                  _vm.userTel = $$v
                                },
                                expression: "userTel",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "greens-btn addnew-search-btn",
                                attrs: { size: "small" },
                                on: { click: _vm.searchUsersByPhone },
                              },
                              [_vm._v("查找 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.searchByPhoneList.length != 0
                      ? _c(
                          "div",
                          { staticClass: "user-detail" },
                          _vm._l(_vm.searchByPhoneList, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "search-buy-phone-box",
                                on: {
                                  click: function ($event) {
                                    _vm.selectIndex = i
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "user-detail-header-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "user-detail-header" },
                                      [
                                        _vm.reserverUserInfo.avatar &&
                                        _vm.reserverUserInfo.avatar != ""
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.reserverUserInfo
                                                  .avatar,
                                              },
                                            })
                                          : _c("svg-icon", {
                                              attrs: {
                                                "class-name": "avater-default",
                                                "icon-class": "avater0",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "user-detail-box" }, [
                                  _c(
                                    "div",
                                    { staticClass: "user-detail-mes" },
                                    [
                                      _c("div", { staticClass: "user_text" }, [
                                        _c("p", [
                                          _vm._v(
                                            "客 户 名：" + _vm._s(item.name)
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "手 机 号：" + _vm._s(item.tel)
                                          ),
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.code != "",
                                                expression: "item.code != ''",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$adminList.user_code_name
                                              ) +
                                                "：" +
                                                _vm._s(item.code)
                                            ),
                                          ]
                                        ),
                                        _c("p", [_vm._v(_vm._s(item.level))]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: i == _vm.selectIndex,
                                      expression: "i == selectIndex",
                                    },
                                  ],
                                  staticClass: "right-select-icon",
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.searchByPhoneList.length == 0 && _vm.isShowSearchNomes
                      ? _c("div", { staticClass: "user-detail-default" }, [
                          _vm._v("抱歉没有找到此用户"),
                        ])
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-top": "15px" },
                        attrs: {
                          label: "预留" + _vm.customSetting.title,
                          "label-position": "left",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "no-match-text":
                                    "没有找到相关" + _vm.customSetting.title,
                                  placeholder:
                                    _vm.customSetting.custom_data.house_num +
                                    "搜索或选择",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.selectedRoom,
                                  callback: function ($$v) {
                                    _vm.selectedRoom = $$v
                                  },
                                  expression: "selectedRoom",
                                },
                              },
                              _vm._l(_vm.roomResources, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      disabled: Boolean(item.is_reserv),
                                      label: item.room,
                                      value: item.room,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "select-item-wrapper" },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.room))]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.is_reserv == 1
                                                ? "已预留"
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.addNewDiglog = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitAddNew },
                  },
                  [_vm._v("保 存")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "下载模板",
              "destroy-on-close": "",
              visible: _vm.downloadFileDia,
              width: "400px",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.downloadFileDia = $event
              },
              close: _vm.closeDon,
            },
          },
          [
            _c("div", { staticClass: "radio-list" }, [
              _c(
                "div",
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.is_downloadFile,
                        callback: function ($$v) {
                          _vm.is_downloadFile = $$v
                        },
                        expression: "is_downloadFile",
                      },
                    },
                    [_vm._v("预留导入模板(空白)")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mT15" },
                [
                  _vm.isShowDown == 1
                    ? _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.is_downloadFile,
                            callback: function ($$v) {
                              _vm.is_downloadFile = $$v
                            },
                            expression: "is_downloadFile",
                          },
                        },
                        [_vm._v("预留导入模板(含房源)")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mT15" },
                [
                  _vm.isShowDown == 2
                    ? _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.is_downloadFile,
                            callback: function ($$v) {
                              _vm.is_downloadFile = $$v
                            },
                            expression: "is_downloadFile",
                          },
                        },
                        [_vm._v("预留导入模板(含车位)")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-box" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowGreen",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadtemp(
                              _vm.is_downloadFile,
                              _vm.is_downloadFile === 0
                            )
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "default-img" }, [
      _c("img", {
        staticClass: "d-img",
        attrs: {
          alt: "还没有创建预留名单哦~",
          src: require("../../assets/image/icon-qs.png"),
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-title-left" }, [
      _c(
        "div",
        { staticClass: "name", staticStyle: { "word-wrap": "normal" } },
        [_vm._v("预留名单")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }