import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @author yang
 * @time 2021/6/22
 * 大屏显示 通用设置大屏预览组件
 * */
import { mapState } from 'vuex';
export default {
  props: {
    // 房源数据
    itemDetail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 卡片类型显示 0 只显示房号 1 显示房号以及其他
    cardType: {
      type: Number,
      default: 0
    },
    // 头部颜色
    headerColor: {
      type: String,
      default: '#ff8d37'
    },
    // 字体颜色
    fontColor: {
      type: String,
      default: '#ffffff'
    },
    // 配置
    ledConfInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    projectRoomInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    //
    projectInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {// ...mapState({
    //     projectInfo: state => state.screen.allDatas.projectInfo // 所有数据 包括右边侧栏
    // })
  },
  mounted: function mounted() {// console.log(this.projectInfo)
  }
};