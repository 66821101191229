import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
import "core-js/modules/es.array.concat.js";

/**
 * @author WangChao
 * @version 2021年4月26日09:28:58
 */
import moment from 'moment';
import { Message } from 'element-ui';
import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';
import { getrsaPublickey, getToken } from '@/utils/auth';
export default {
  // 时间提示语 --- 早上好
  timeTips: function timeTips() {
    var timeTip = '';
    var hours = moment().hours();

    if (hours < 5) {
      timeTip = '凌晨';
    } else if (hours < 7) {
      timeTip = '清晨';
    } else if (hours < 9) {
      timeTip = '早上';
    } else if (hours < 12) {
      timeTip = '上午';
    } else if (hours < 14) {
      timeTip = '中午';
    } else if (hours < 18) {
      timeTip = '下午';
    } else if (hours < 19) {
      timeTip = '傍晚';
    } else if (hours < 24) {
      timeTip = '晚上';
    }

    return timeTip;
  },

  /**
   * @param {String} url 下载完整地址
   * @param {String} fileName 下载文件名称
   * @param {Object} msgConfig 下载提示配置
   * msgConfig: { // 以下是默认值
   *   showMessage: true, // 显示提示消息
   *   success: '导出成功！', // 成功提示文本
   *   error: '导出失败！', // 失败提示文本
   * }
   * @returns Promise
   */
  download_files: function download_files(url) {
    var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : moment().format('YYYY-MM-DD HH:mm:ss');
    var msgConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _msgConfig$showMessag = msgConfig.showMessage,
        showMessage = _msgConfig$showMessag === void 0 ? true : _msgConfig$showMessag,
        _msgConfig$success = msgConfig.success,
        success = _msgConfig$success === void 0 ? '导出成功！' : _msgConfig$success,
        _msgConfig$error = msgConfig.error,
        error = _msgConfig$error === void 0 ? '导出失败！' : _msgConfig$error;
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          // 响应成功 并且 响应文件类型不能是html (url不存在的话会下载html文件)
          if (xhr.status === 200 && !xhr.response.type.includes('htm')) {
            xhr.onload = function (e) {
              var blob = xhr.response;

              if ('msSaveOrOpenBlob' in navigator) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
              } else {
                var a = document.createElement('a');
                a.download = fileName;
                a.href = URL.createObjectURL(blob);
                a.click();
              }

              showMessage && Message.success(success);
              resolve();
            };
          } else {
            reject();
          }
        }
      };

      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.send();
    }).catch(function () {
      // 错误处理
      showMessage && Message.error(error);
    });
  },
  //  判断undefined
  is_undef: function is_undef(value) {
    if (value === undefined || value === 'undefined') {
      return true;
    }
  },
  //  判断手机还pc
  //  判断是否手机
  isPC: function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    var flag = true;

    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }

    return flag;
  },
  // 价格精准到元、角、分
  toUnit: function toUnit(price) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var retainNum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var unit = Number(type);
    var rNum = Number(retainNum);

    if (isNaN(price) || isNaN(unit) || isNaN(rNum)) {
      return price;
    }

    var powNum = Math.pow(10, unit - 1);
    return (Math.round(price * powNum) / powNum).toFixed(rNum);
  },
  numFormat: function numFormat(price) {
    var n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
    var retainNum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    if (isNaN(Number(price))) return price;
    var num = Number(price).toFixed(retainNum);
    var integer = ''; // 整数部分

    var decimals = ''; // 小数部分

    var patten = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g; // 校验正负带小数点的数字

    if (!patten.test(num)) {
      return num;
    }

    if (num.indexOf('.') !== -1) {
      integer = num.split('.')[0];
      decimals = num.split('.')[1];
    } else {
      integer = num;
      decimals = '0'.repeat(retainNum);
    }

    var len = integer.length;

    if (len <= n) {
      return num;
    } else {
      var temp = '';
      var remainder = len % n;
      decimals ? temp = '.' + decimals : temp;

      if (remainder > 0) {
        // 分隔位数不是数值的整数倍
        return integer.slice(0, remainder) + ',' + integer.slice(remainder, len).match(new RegExp("\\d{".concat(n, "}"), 'g')).join(',') + temp;
      }

      return integer.slice(0, len).match(new RegExp("\\d{".concat(n, "}"), 'g')).join(',') + temp;
    }
  },
  //	input 验证错误！ 跳转到对应位置
  jumpFun: function jumpFun(className) {
    var checkCard = document.querySelectorAll(".".concat(className));
    checkCard[0].scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  },

  /**
   * 时间格式化
   * @param date  时间
   * @param format  格式
   * @returns {Promise<*>}
   */
  formatDate: function formatDate(date, format) {
    var o = {
      // 月
      'M+': date.getMonth() + 1,
      // 天
      'd+': date.getDate(),
      // 小时
      'h+': date.getHours(),
      // 分钟
      'm+': date.getMinutes(),
      // 秒
      's+': date.getSeconds(),
      // 季度
      'q+': Math.floor((date.getMonth() + 3) / 3),
      // 毫秒
      S: date.getMilliseconds()
    };
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }

    return format;
  },

  /**
   * 根据base64下载文件
   * @param base64
   * @param fileName  文件名
   * @param suffix    文件后缀
   */
  download_files_base64: function download_files_base64(base64, fileName) {
    var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'xlsx';
    return new Promise(function (resolve, reject) {
      try {
        // base64转换
        var base64ToArrayBuffer = function base64ToArrayBuffer(base64) {
          var binaryString = window.atob(base64);
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen);

          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          return bytes;
        };

        var saveByteArray = function saveByteArray(reportName, byte) {
          var link = document.createElement('a'); // 创建a标签

          var blob = new Blob([byte], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          }); // response就是接口返回的文件流

          var objectUrl = URL.createObjectURL(blob);
          link.href = objectUrl;
          link.download = reportName;
          link.click(); // 下载文件

          URL.revokeObjectURL(objectUrl);
          resolve();
        }; // 下载


        saveByteArray(fileName + '.' + suffix, base64ToArrayBuffer(base64));
      } catch (e) {
        reject(e);
      }
    });
  },
  // 随机生成密钥
  generateAesKey: function generateAesKey(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  },
  parameterJSEncrypt: function parameterJSEncrypt(data) {
    var rsaPublickey = getrsaPublickey() || "-----BEGIN RSA PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA77+HJDJotpDdOyktIVAsNMaK4zs6iBWK8ICDbxYekwlbnLVZcslGWe+5XDabXSUNNTbiIWPZlV0Xqa5W31MGddURxFZhibCFKw77wb0jwRjglJooMMY3JwGD9l8rUAw7tSYBSm+hNiA+XpAXg9dl7cSq8cvmytGFtisIUq4zSUqrQ8P3XksSipHzj3j5npZ0b3izecuuGqVP6ZLhDmBe2DKoQqnYmjKJECy46dDeMyO6IXDaTWHl00jNw1sw0DTHnVk4SSJUqfBxWVyl90EYjNC+CttOscP7SEVD7ZUV3K/Po04yYUzXs1uIMxzZAJhPORQw2lju5oZyOi7EIyhNEwIDAQAB-----END RSA PUBLIC KEY-----";

    if (!getToken()) {
      rsaPublickey = "-----BEGIN RSA PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA77+HJDJotpDdOyktIVAsNMaK4zs6iBWK8ICDbxYekwlbnLVZcslGWe+5XDabXSUNNTbiIWPZlV0Xqa5W31MGddURxFZhibCFKw77wb0jwRjglJooMMY3JwGD9l8rUAw7tSYBSm+hNiA+XpAXg9dl7cSq8cvmytGFtisIUq4zSUqrQ8P3XksSipHzj3j5npZ0b3izecuuGqVP6ZLhDmBe2DKoQqnYmjKJECy46dDeMyO6IXDaTWHl00jNw1sw0DTHnVk4SSJUqfBxWVyl90EYjNC+CttOscP7SEVD7ZUV3K/Po04yYUzXs1uIMxzZAJhPORQw2lju5oZyOi7EIyhNEwIDAQAB-----END RSA PUBLIC KEY-----";
    }

    var encryptor = new JSEncrypt(); // 创建加密对象实例

    encryptor.setPublicKey(rsaPublickey); //设置公钥

    if (data) {
      var regex = /.{1,200}/g;
      data = data.match(regex);
      var encrypted = '';

      for (var i = 0; i < data.length; i++) {
        data[i] = encryptor.encrypt(data[i]);
      }

      encrypted = data.join('?');
      return encrypted;
    }
  },
  decrypt: function decrypt(word, keyStr) {
    // 假设你有一个base64编码的字符串，其中包含了iv和ciphertext
    if (word) {
      // word = word.replace(/[\n\r]+/g, "");
      try {
        word.replace(/\s/g, '');
        var base64String = word; // 将base64字符串解码为UTF-8字符串

        var decodedString = CryptoJS.enc.Base64.parse(base64String);
        decodedString = decodedString.toString(); // 分离出iv和ciphertext

        var iv = CryptoJS.enc.Hex.parse(decodedString.substr(0, 32)); // 假设IV长度是32个字符，根据实际情况调整

        var ciphertext = decodedString.substr(32); // 需要提供的密钥

        var key = CryptoJS.enc.Utf8.parse(keyStr); // 示例密钥，请使用实际密钥替换
        // 解密

        var decrypted = CryptoJS.AES.decrypt({
          ciphertext: CryptoJS.enc.Hex.parse(ciphertext)
        }, key, {
          iv: iv
        }); // 将解密后的数据转换为UTF-8字符串

        var decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
      } catch (err) {
        console.log('解密失败', err);
        return word;
      }
    }
  },
  concatParams: function concatParams(params) {
    var paramsStr = '';

    for (var k in params) {
      if (params[k] !== undefined && params[k] !== null) {
        paramsStr += "".concat(k, "=").concat(params[k], "&");
      }
    }

    paramsStr = '?' + paramsStr.substr(0, paramsStr.length - 1);
    return paramsStr;
  }
};