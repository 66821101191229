var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters.currentForm
    ? _c("div", { staticClass: "edit" }, [
        _c("div", { staticClass: "tops" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$store.getters.currentForm) + " "),
            _vm.$store.getters.subCurrentForm
              ? _c("span", [
                  _vm._v("-" + _vm._s(_vm.$store.getters.subCurrentForm)),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticStyle: { height: "35px" } },
            [
              _vm.$store.getters.currentForm != "品牌水印"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.deltCommont },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.currentForm.length
          ? _c(
              "div",
              { staticClass: "edit-content" },
              [
                _c(
                  "el-form",
                  _vm._l(_vm.currentForm, function (item, index) {
                    return _c("el-form-item", { key: index }, [
                      _c("div", { staticClass: "parent-title" }, [
                        _vm._v(" " + _vm._s(_vm.seTltle(item.maxTitle)) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-center" },
                        _vm._l(item.style, function (list, key, index2) {
                          return _c(
                            "div",
                            { key: index2, staticClass: "form-list" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "sub-title",
                                  staticStyle: { "margin-right": "5px" },
                                },
                                [_vm._v(_vm._s(_vm.subtitle(key)) + ": ")]
                              ),
                              key.includes("size")
                                ? [
                                    _c("el-input-number", {
                                      staticClass: "inputCls",
                                      attrs: {
                                        step: 1,
                                        min: 1,
                                        max: 100,
                                        label: "描述文字",
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.handleCheckAllChange(
                                            val,
                                            key
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.style[key],
                                        callback: function ($$v) {
                                          _vm.$set(item.style, key, $$v)
                                        },
                                        expression: "item.style[key]",
                                      },
                                    }),
                                  ]
                                : [
                                    _c("el-color-picker", {
                                      attrs: { "color-format": "rgb" },
                                      on: {
                                        change: function (val) {
                                          return _vm.handleCheckAllChange(
                                            val,
                                            key
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.style[key],
                                        callback: function ($$v) {
                                          _vm.$set(item.style, key, $$v)
                                        },
                                        expression: "item.style[key]",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("div", [
        _c("div", { staticClass: "no-data" }, [
          _vm._v("请在画布选择编辑的组件"),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }