var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rounds" }, [
    _vm.numberList.length
      ? _c(
          "div",
          {
            staticClass: "rounds-content flex",
            class: { flicker: _vm.flicker },
            style: _vm.labelStyle,
          },
          [
            _vm.ranking
              ? _c("div", { staticClass: "rounds-title" }, [
                  _vm._v(_vm._s(_vm.ranking)),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  width: "calc(100% - 40px)",
                  display: "flex",
                  "justify-content": "start",
                },
              },
              _vm._l(_vm.numberList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "btn-card",
                    class: {
                      existing: item.is_repeat,
                      valid: true,
                      abandon: item.is_waste,
                      flicker:
                        _vm.flickerChild &&
                        item.is_repeat &&
                        !item.is_waste &&
                        (_vm.newClass.indexOf(item.user_class) >= 0 ||
                          item.user_class.indexOf(_vm.newClass) >= 0),
                    },
                    style: {
                      width:
                        "calc(100% / " +
                        (_vm.user_class === 3 ? 20 : 10) +
                        " - 15px)",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.code) + " ")]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }