import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
import api from '@/api/lotteryEntry';
import { pingPongList, pingPongExport, pingEdit } from '@/api/formulaLottery';
import { adminParameter } from '@/api/public';
import { mapGetters } from 'vuex';
export default {
  name: 'formulaLotteryRecord',
  data: function data() {
    return {
      params: {
        page: 1,
        limit: 10,
        status: 0,
        search: ''
      },
      loading: false,
      config: {
        count: 20,
        // 总页数
        list: [],
        // 列表
        edition: [],
        round_list: [] // 轮次列表

      },
      round_list: [],
      appHeight: '',
      topHeight: '',
      dialogVisible: false,
      id: '',
      newName: '',
      newCode: '',
      list: {
        user_code_name: '诚意单号'
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 130;
    }
  },
  created: function created() {
    var _this = this;

    this.getData(true); //  项目配置

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this.list = list;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      console.log();
      _this2.appHeight = _this2.$refs.app.offsetHeight;
      _this2.topHeight = _this2.$refs.apptop.offsetHeight;
    };
  },
  methods: {
    editresult: function editresult(row) {
      console.log(row);
      this.id = row.id;
      this.newName = row.name;
      this.newCode = row.code;
      this.dialogVisible = true;
    },
    dialogConfirm: function dialogConfirm() {
      var _this3 = this;

      pingEdit({
        id: this.id,
        new_code: this.newCode
      }).then(function (res) {
        console.log(res);

        if (res.status == 200) {
          _this3.$message({
            type: 'success',
            message: '修改成功'
          });

          _this3.dialogVisible = false;

          _this3.getData(true);
        }
      });
    },
    // 获取数据
    getData: function getData(isQuery) {
      var _this4 = this;

      if (isQuery) {
        this.params.limit = 10;
        this.params.page = 1;
      }

      this.loading = true;
      pingPongList(this.params).then(function (result) {
        _this4.config.count = result.data.count;
        _this4.config.list = result.data.list;
      }).finally(function () {
        _this4.loading = false;
      });
    },
    // 重置表单
    resetForm: function resetForm() {
      this.params = {
        page: 1,
        limit: 10,
        search: ''
      };
      this.getData(true);
    },
    // 选择框修改
    changeRound: function changeRound(e) {
      this.round_list = this.config.edition[e - 1].round;
    },
    // 导出房源表
    handleExport: function handleExport() {
      var params = this.params;
      var exportloading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // 导出接口

      pingPongExport(params).then(function (res) {
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
      }).finally(function () {
        exportloading.close();
      });
    },
    getURLName: function getURLName(url) {
      return url.replace(/(.*\/)*([^.]+).*/gi, '$2');
    },
    suffix: function suffix(url) {
      return url.replace(/.+\./, '');
    },
    handleSizeChange: function handleSizeChange(s) {
      this.params.limit = s;
      this.getData(false);
    },
    handleCurrentChange: function handleCurrentChange(s) {
      this.params.page = s;
      this.getData(false);
    },
    doTimeToDate: function doTimeToDate(time) {
      return utils.formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    }
  }
};