import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import inputText from '@/components/inputText';
import { parameterInfo, preserveSave } from '../../api/openMaintenance';
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/debounce';
import utils from '@/utils/utils'; // 环境设置

import linkSettings from './components/linkSettings.vue'; // 房源&车位详情 预览

import preview from './components/preview.vue';
import printOrderDialog from './components/printOrderDialog';
import youhuiKneelingDialog from './components/youhuiKneelingDialog.vue';
export default {
  name: 'Parameter',
  components: {
    inputText: inputText,
    linkSettings: linkSettings,
    preview: preview,
    printOrderDialog: printOrderDialog,
    youhuiKneelingDialog: youhuiKneelingDialog
  },
  data: function data() {
    return {
      you_hui_price: 0,
      //
      you_hui_rule: [],
      //优惠规则
      sp: {},
      prinVisible: false,
      KneelVisible: false,
      checkList: [],
      pay_info_checkList: [],
      payName: ['一次性', '按揭', '分期1', '分期2', '分期3'],
      //类型命名
      checked: true,
      style: {
        width: '100%',
        'text-align': 'center'
      },
      isPC: utils.isPC(),
      // 预览弹框
      preview: false,
      //  页面初始 数据
      info: {},
      //  在线签约时长限制
      online_sign: 0,
      //  在线交定金时长限制
      online_pay: 0,
      // 是否展示 代码状态
      end_switch: true,
      // 提交的数据
      data: {
        buy_start_all_time_min: 10 //全屏倒计时默认秒数

      },
      // 预约时间操作
      timing: {
        is_time_limit: 0,
        timing_name: '',
        timing_list: []
      },
      allPay: [],
      //全部付款信息参数
      pay_info: [],
      //删选后的付款信息参数
      timingOper: {
        show: false,
        key: '',
        data: {
          start_time: '',
          end_time: '',
          user_count: ''
        },
        rules: {
          user_count: [{
            required: true,
            message: '请输入限制人数',
            trigger: 'blur'
          }],
          start_time: [{
            required: true,
            message: '请选择开始时间',
            trigger: 'blur'
          }],
          end_time: [{
            required: true,
            message: '请选择结束时间',
            trigger: 'blur'
          }]
        },
        index: 0
      },
      timingTableKey: Math.random()
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    isShowPrice: function isShowPrice() {
      var _this$info, _this$info2;

      // 1、凡是SAAS端“建发项目ID”不为空时，代表是建发项目，应该要展示付款方式
      if ((_this$info = this.info) !== null && _this$info !== void 0 && _this$info.jf_id) {
        console.log(2);
        return true;
      } // 2、如果是“建发项目ID”为空，且“付款信息填写触发时间”不是 不启用时，应该要展示付款方式
      else if (!((_this$info2 = this.info) !== null && _this$info2 !== void 0 && _this$info2.jf_id) && this.sp.pay_info_set_time != 0) {
        console.log(1);
        return true;
      }

      console.log(1);
      return false;
    }
  }),
  // 初始化
  created: function created() {
    if (this.roles.includes('project/index') || this.roles.includes('admin')) {
      this.getDataInfo();
    }
  },
  methods: {
    // 优惠规则设置弹窗
    handYhuilEedit: function handYhuilEedit() {
      console.log(this.you_hui_price, 'this.you_hui_price');
      this.$refs.youhuiKneeRef.order_price = this.you_hui_price;
      this.KneelVisible = true;
    },
    closeKneelDialog: function closeKneelDialog() {
      this.KneelVisible = false;
    },
    checkListVal: function checkListVal(val) {
      console.log(val);
      this.pay_info = this.allPay.filter(function (item) {
        if (val.includes(item.name)) {
          return item;
        }
      });
      console.log(this.pay_info);
    },
    closeDialog: function closeDialog(val) {
      this.prinVisible = val;
    },
    //打印订单设置弹窗
    handlEedit: function handlEedit() {
      //
      // if(this.checkList.length){
      //     this.prinVisible = true
      //     this.$refs.orderEedit.wacthPay()
      // }else{
      //     this.$message({
      //         type: 'info',
      //         message: '请至少选择一个选择付款方式类型'
      //     })
      // }
      this.$router.push({
        name: 'paymentPage'
      });
    },
    // 跳转页面！
    jumpFun: function jumpFun(className) {
      // 跳转去填写
      var checkCard = document.querySelectorAll(".".concat(className));
      checkCard[0].scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    },

    /**
     * 预约时间段的操作
     * @param row 当前行数据
     * @param index  下标
     * @param key  标识
     */
    onTimingOper: function onTimingOper(row, index, key) {
      var _this = this;

      // 添加
      if (key === 'add') {
        // let timing_list = [];
        // for(let i = 0; i < this.timing.timing_list.length; i++){
        //   timing_list.push(this.timing.timing_list[i])
        //   if(i === index){
        //     timing_list.push({
        //       start_time:"",
        //       end_time:"",
        //       user_count:1
        //     })
        //   }
        // }
        // this.timing.timing_list = timing_list;
        if (this.timing.timing_list && this.timing.timing_list.length) {
          this.timing.timing_list.splice(index + 1, 0, {
            start_time: '',
            end_time: '',
            user_count: 1
          });
        } else {
          this.timing.timing_list = [{
            start_time: '',
            end_time: '',
            user_count: 1
          }];
        } // this.timingOper = {
        //   show:true,
        //   key:"add",
        //   data:{
        //     start_time:"",
        //     end_time:"",
        //     user_count:1
        //   },
        //   rules: {
        //     user_count: [{ required: true, message: '请输入限制人数', trigger: 'blur' }],
        //     start_time: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
        //     end_time: [{ required: true, message: '请选择结束时间', trigger: 'blur' }]
        //   },
        //   index:0
        // }

      } // 修改
      else if (key === 'set') {
        this.timingOper = {
          show: true,
          key: 'set',
          data: {
            id: row.id,
            start_time: row.start_time * 1000,
            end_time: row.end_time * 1000,
            user_count: row.user_count
          },
          rules: {
            user_count: [{
              required: true,
              message: '请输入限制人数',
              trigger: 'blur'
            }],
            start_time: [{
              required: true,
              message: '请选择开始时间',
              trigger: 'blur'
            }],
            end_time: [{
              required: true,
              message: '请选择结束时间',
              trigger: 'blur'
            }]
          },
          index: index
        };
      } // 删除
      else if (key === 'del') {
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.timing.timing_list.splice(index, 1);
        });
      } // 修改开始时间
      else if (key === 'set_start_time') {
        if (row.end_time && utils.formatDate(new Date(row.start_time), 'yyyy-MM-dd') !== utils.formatDate(new Date(row.end_time), 'yyyy-MM-dd')) {
          this.$message({
            type: 'info',
            message: '开始时间和结束时间必须在同一天！'
          });
          row.start_time = '';
        }

        if (row.start_time && row.end_time && row.start_time >= row.end_time) {
          this.$message({
            type: 'info',
            message: '开始时间不能大于结束时间！'
          });
          row.start_time = '';
        } else if (row.start_time && index !== 0) {
          if (row.start_time < this.timing.timing_list[index - 1].end_time) {
            this.$message({
              type: 'info',
              message: '不能小于上一段结束时间！'
            });
            row.start_time = '';
          }
        }

        if (!row.start_time) {
          this.timing.timing_list[index] = row;
        }
      } // 修改结束时间
      else if (key === 'set_end_time') {
        if (row.start_time && utils.formatDate(new Date(row.start_time), 'yyyy-MM-dd') !== utils.formatDate(new Date(row.end_time), 'yyyy-MM-dd')) {
          this.$message({
            type: 'info',
            message: '开始时间和结束时间必须在同一天！'
          });
          row.end_time = '';
        }

        if (row.end_time && row.start_time && row.start_time >= row.end_time) {
          this.$message({
            type: 'info',
            message: '开始时间不能大于结束时间！'
          });
          row.end_time = '';
        } else if (row.end_time && index !== this.timing.timing_list.length - 1) {
          if (row.end_time > this.timing.timing_list[index + 1].start_time) {
            this.$message({
              type: 'info',
              message: '不能大于下一段开始时间！'
            });
            row.end_time = '';
          }
        }

        if (!row.end_time) {
          this.timing.timing_list[index] = row;
        }
      } // 添加或者修改保存
      else if (key === 'submit') {
        this.$refs['timingOperDialog'].validate(function (valid) {
          if (valid) {
            var data = JSON.parse(JSON.stringify(_this.timingOper.data));

            if (utils.formatDate(new Date(data.start_time), 'yyyy-MM-dd') !== utils.formatDate(new Date(data.end_time), 'yyyy-MM-dd')) {
              _this.$message({
                type: 'info',
                message: '开始时间和结束时间必须在同一天！'
              });

              _this.timingOper.data.start_time = '';
              _this.timingOper.data.end_time = '';
              return;
            }

            data.start_time = parseInt(data.start_time / 1000);
            data.end_time = data.end_time / 1000;

            if (data.start_time >= data.end_time) {
              _this.$message({
                type: 'info',
                message: '开始时间不能大于结束时间！'
              });

              _this.timingOper.data.start_time = '';
              return;
            } else if (_this.timingOper.key === 'add' && _this.timing.timing_list && _this.timing.timing_list.length && data.start_time < _this.timing.timing_list[_this.timing.timing_list.length - 1].end_time) {
              _this.$message({
                type: 'info',
                message: '开始时间不能小于上一条时间段的结束时间！'
              });

              _this.timingOper.data.start_time = '';
              return;
            } else if (_this.timingOper.index && _this.timingOper.key !== 'add' && _this.timing.timing_list && data.start_time < _this.timing.timing_list[_this.timingOper.index - 1].end_time) {
              _this.$message({
                type: 'info',
                message: '开始时间不能小于上一条时间段的结束时间！'
              });

              _this.timingOper.data.start_time = '';
              return;
            } else if (_this.timingOper.key !== 'add' && _this.timing.timing_list && _this.timing.timing_list.length - 1 !== _this.timingOper.index && data.end_time > _this.timing.timing_list[_this.timingOper.index + 1].start_time) {
              _this.$message({
                type: 'info',
                message: '结束时间不能大于下一条时间段的开始时间！'
              });

              _this.timingOper.data.end_time = '';
              return;
            }

            if (_this.timingOper.key === 'add') {
              if (_this.timing.timing_list) {
                _this.timing.timing_list.push(data);
              } else {
                _this.timing.timing_list = [data];
              }
            } else if (_this.timingOper.key === 'set') {
              _this.timing.timing_list[_this.timingOper.index] = data;
            }

            _this.timingTableKey = Math.random();
            _this.timingOper.show = false;
          }
        });
      }
    },
    handleIscheckbox: function handleIscheckbox(e) {
      if (e === 1) {
        this.data.rand_time_min = '';
      }
    },
    handleRandTime: function handleRandTime(e) {
      this.data.rand_time_min = e;
      console.log(e);
    },
    handleMatching_time_min: function handleMatching_time_min(e) {
      this.data.matching_time_min = e;
    },
    handleMatching_time_type: function handleMatching_time_type(e) {
      if (e === 1) {
        this.data.matching_time_min = 0;
      }
    },
    handleOnline_sign_type: function handleOnline_sign_type(e) {
      if (e === 1) {
        this.data.online_sign = 0;
      }
    },
    // 判断
    handelInput: function handelInput(value) {
      if (value === 0) {// this.$message({
        //     type: 'error',
        //     message: '定金金额不能小于零'
        // })
      }
    },
    // 点击价格  默认选中操作
    handelRoomPrice: function handelRoomPrice(e) {
      console.log(e);

      switch (e) {
        case 1:
          this.data.room_info_pin_yuan_jia_scribe = 0;
          this.data.room_info_pin_you_hui_jia_scribe = 0;
          this.data.room_info_pin_biao_zhun_jia_scribe = 0;
          this.data.room_info_pin_an_jie_jia_scribe = 0;
          this.data.room_info_pin_yi_ci_jia_scribe = 0;

          if (this.data.this_order_price === 3) {
            this.data.order_price = this.data.this_order_price;
            this.data.room_list_price_type_way = this.data.this_room_list_price_type_way;
          } else {
            this.data.order_price = 3;
            this.data.room_list_price_type_way = 3;
          }

          break;

        case 2:
          this.data.room_info_pin_xian_jia_scribe = 0;
          this.data.room_info_pin_biao_zhun_jia_scribe = 0;
          this.data.room_info_pin_an_jie_jia_scribe = 0;
          this.data.room_info_pin_yi_ci_jia_scribe = 0;

          if (this.data.this_order_price === 3 || this.data.this_order_price === 4) {
            this.data.order_price = this.data.this_order_price;
            this.data.room_list_price_type_way = this.data.this_room_list_price_type_way;
          } else {
            this.data.order_price = 3;
            this.data.room_list_price_type_way = 3;
          }

          break;

        case 3:
          this.data.room_info_pin_xian_jia_scribe = 0;
          this.data.room_info_pin_yuan_jia_scribe = 0;
          this.data.room_info_pin_you_hui_jia_scribe = 0;

          if (this.data.this_order_price === 5 || this.data.this_order_price === 2 || this.data.this_order_price === 1) {
            this.data.order_price = this.data.this_order_price;
            this.data.room_list_price_type_way = this.data.this_room_list_price_type_way;
          } else {
            this.data.order_price = 5;
            this.data.room_list_price_type_way = 5;
          }

          break;
      }
    },
    // 操作签约排号
    handleSignUPRand: function handleSignUPRand(e) {
      if (e) {
        this.data.sign_up = e;
      }
    },
    // 操作签约
    handleSignUP: function handleSignUP(e) {
      if (!e) {
        this.data.sign_up_rand = e;
      }
    },
    handlePriceRand: function handlePriceRand(e) {
      if (e) {
        this.data.price = e;
      }
    },
    // 操作交定金
    handlePrice: function handlePrice(e) {
      if (!e) {
        this.data.price_rand = e;
      }
    },
    // 操作选房摇号
    chaoseBuy: function chaoseBuy(e) {
      if (e) {
        this.data.buy = e;
      }
    },
    // 操作选房
    chooseRoom: function chooseRoom(e) {
      if (!e) {
        this.data.buy_rand = e;
      }
    },
    // 打开预览 弹框
    handlePreview: function handlePreview() {
      this.preview = true;
    },
    // 在线交定金时长限制
    handleOnlinePay: function handleOnlinePay(e) {
      this.data.online_pay = e;
    },
    // 在线签约时长限制
    handleOnlineSign: function handleOnlineSign(e) {
      this.data.online_sign = e;
    },
    //  定金金额
    handlePriceMoney: function handlePriceMoney(e) {
      this.data.price_money = e;
    },
    // 协助选房限制时间
    handleBuyOtherTime: function handleBuyOtherTime(e) {
      this.data.buy_other_time = e;
    },
    // 允许签到时间
    handleSignUpTime: function handleSignUpTime(e) {
      this.data.sign_up_time = e;
    },
    //  意向单次数量上限
    handleCollectCount: function handleCollectCount(e) {
      if (e > 100) {
        this.data.collect_count = '';
        return;
      }

      this.data.collect_count = e;
    },
    // 选房开始全屏倒计时开启时间
    handleBuyTimeMin: function handleBuyTimeMin(e) {
      // 下面这个输入框不允许输入57、33、13，如果输入了就自动头减1，比如输入57就自动改成56。
      console.log(e);
      this.data.buy_start_all_time_min = e == 57 ? 56 : e == 33 ? 32 : e == 13 ? 12 : e;
    },
    // 获取页面 数据
    getDataInfo: function getDataInfo() {
      var _this2 = this;

      parameterInfo().then(function (res) {
        // 页面数据
        var _res$data = res.data,
            list = _res$data.list,
            timing = _res$data.timing,
            pay_info = _res$data.pay_info,
            sp = _res$data.sp,
            you_hui_rule = _res$data.you_hui_rule;
        if (list.is_tag_sale !== 0) list.room_info_select = 2;
        _this2.info = list;
        _this2.sp = sp;
        _this2.data = _objectSpread({}, list);
        console.log(res.data);

        if (timing.timing_list && timing.timing_list.length) {
          timing.timing_list.forEach(function (item) {
            item.start_time = item.start_time * 1000;
            item.end_time = item.end_time * 1000;
          });
        } else {
          timing.timing_list = [{
            start_time: '',
            end_time: '',
            user_count: 1
          }];
        }

        _this2.timing = timing;
        _this2.you_hui_price = list.you_hui_price;
        _this2.you_hui_rule = you_hui_rule || []; //   || [
        //   {
        //     id:0,
        //     prie_type:0, //价格类型
        //     type_name:'',// 方式名称
        //     way:0,//优惠方式,0减额,1折扣
        //     derate:null,//减额
        //     discount:null,//折扣小数
        //     sort:1
        //   }
        // ]//优惠规则设置优惠规则设置

        if (pay_info && pay_info.length) {
          _this2.allPay = JSON.parse(JSON.stringify(pay_info));
          _this2.pay_info = [];
          _this2.pay_info_checkList = pay_info.map(function (item) {
            return {
              show_name: item.name,
              id: item.id
            };
          });
          _this2.checkList = pay_info.map(function (item) {
            if (item.status == 1) {
              _this2.pay_info.push(item);

              return item.name;
            }
          });
          _this2.payName = pay_info.map(function (item) {
            return {
              show_name: item.show_name,
              id: item.id
            };
          });
        }

        _this2.data.show_is_sign_in = list.is_display.includes('sign_in');
        _this2.data.show_is_buy = list.is_display.includes('buy');
        _this2.data.show_is_price = list.is_display.includes('price');
        _this2.data.show_is_sign_up = list.is_display.includes('sign_up');
        _this2.data.show_is_buy_rand = list.is_display.includes('buy_rand');
        _this2.data.show_is_price_rand = list.is_display.includes('price_rand');
        _this2.data.show_is_sign_up_rand = list.is_display.includes('sign_up_rand');
        _this2.data.show_audit_in = list.is_display.includes('audit_in');
        _this2.data.show_data_file = list.is_display.includes('data_file');
        _this2.data.rand_time_min = _this2.data.rand_time_min === 0 ? '' : list.rand_time_min;
      });
    },
    //   修改保存
    handleSave: debounce(function (order_price) {
      var _this3 = this;

      var data = this.data,
          timing = JSON.parse(JSON.stringify(this.timing));
      var is_timing_listRef_ok = true;

      if (this.$refs['timing_listRef']) {
        this.$refs['timing_listRef'].validate(function (valid) {
          if (!valid) {
            is_timing_listRef_ok = false;
          }
        });
      }

      if (!is_timing_listRef_ok) {
        this.$message({
          type: 'error',
          message: '请正确填写所有的预约时间段！'
        });
        return false;
      }

      if (timing.timing_list && timing.timing_list.length) {
        var timing_list = [];
        timing.timing_list.forEach(function (item) {
          if (item.start_time && item.end_time) {
            item.start_time = parseInt(item.start_time / 1000);
            item.end_time = parseInt(item.end_time / 1000);
            timing_list.push(item);
          }
        });
        timing.timing_list = timing_list;
      }

      if (isNaN(data.sign_up_time)) {
        this.$message({
          type: 'error',
          message: '允许开始签到时间不能小于0'
        });
        this.jumpFun('jump');
        return;
      }

      if (this.info.buy_type === 2) {
        if (isNaN(data.buy_other_time)) {
          this.$message({
            type: 'error',
            message: '认购时间提醒（协助选房）不能小于0'
          });
          this.jumpFun('jump2');
          return;
        }
      } else {
        if (!data.rand_time_type && data.show_is_buy_rand) {
          if (isNaN(data.rand_time_min) || data.rand_time_min === '') {
            this.$message({
              type: 'error',
              message: '认购时长限制（自主选房+摇号模式）不能小于0'
            });
            this.jumpFun('jump3');
            return;
          }
        }
      }

      if (isNaN(data.online_pay)) {
        this.$message({
          type: 'error',
          message: '在线交定金时长限制不能小于0'
        });
        this.jumpFun('jump4');
        return;
      }

      if (isNaN(data.online_sign) && data.online_sign_type !== 1) {
        this.$message({
          type: 'error',
          message: '在线签约时长限制不能小于0'
        });
        this.jumpFun('jump5');
        return;
      } // 定金模式
      // if (data.list?.online_sign_pay_type === 0) {
      //     if (data.cate_room === 1 && data.price === 1) {
      //         if (data.price_money <= 0) {
      //             this.$message({
      //                 type: 'error',
      //                 message: '住宅定金金额不能小于0'
      //             })
      //             this.jumpFun('jump6')
      //             return
      //         }
      //     }
      //     if (data.cate_car === 1 && data.price === 1) {
      //         if (data.car_price <= 0) {
      //             this.$message({
      //                 type: 'error',
      //                 message: '车位定金金额不能小于0'
      //             })
      //             this.jumpFun('jump6')
      //             return
      //         }
      //     }
      // }


      if (data.cate_villa === 1 && data.price === 1) {
        if (data.villa_price <= 0) {
          this.$message({
            type: 'error',
            message: '别墅定金金额不能小于0'
          });
          this.jumpFun('jump6');
          return;
        }
      }

      if (data.cate_shops === 1 && data.price === 1) {
        if (data.shops_price <= 0) {
          this.$message({
            type: 'error',
            message: '商铺定金金额不能小于0'
          });
          this.jumpFun('jump6');
          return;
        }
      }

      if (!data.matching_time_min && data.matching_time_type !== 1) {
        this.$message({
          type: 'error',
          message: '组合订单认购时限不能小于等于0'
        });
        this.jumpFun('jump7');
        return;
      }

      this.allPay = this.allPay.map(function (item) {
        var ob = _this3.payName.find(function (ol) {
          return ol.id == item.id;
        });

        var obServ = _this3.pay_info.find(function (datas) {
          return datas.id == item.id;
        });

        if (obServ) {
          item = obServ;
        }

        if (ob) {
          //赋值修改的名字
          item.show_name = ob.show_name;
        }

        if (_this3.checkList.includes(item.name)) {
          //找到启用的类型
          item.status = 1;
        } else {
          item.status = 0;
        }

        return item;
      });
      var dataInfo = {
        project: {
          id: data.id,
          status: data.status,
          user_code_name: data.user_code_name
        },
        project_rule: {
          sign_in: data.sign_in,
          buy_rand: data.buy_rand,
          price: data.price,
          price_rand: data.price_rand,
          sign_up: data.sign_up,
          sign_up_rand: data.sign_up_rand,
          audit_in: data.audit_in,
          data_file: data.data_file
        },
        project_room: {
          room_info_preference: data.room_info_preference,
          room_info_room: data.room_info_room,
          room_info_tag: data.room_info_tag,
          room_info_cate: data.room_info_cate,
          room_info_price: data.room_info_price,
          room_info_area: data.room_info_area,
          room_info_gift: data.room_info_gift,
          room_info_desc: data.room_info_desc,
          room_info_hot: data.room_info_hot,
          room_info_total_price_prec: data.room_info_total_price_prec,
          room_info_price_type: data.room_info_price_type,
          room_info_pin_xian_jia: data.room_info_pin_xian_jia,
          room_info_pin_yuan_jia: data.room_info_pin_yuan_jia,
          room_info_pin_you_hui_jia: data.room_info_pin_you_hui_jia,
          room_info_pin_biao_zhun_jia: data.room_info_pin_biao_zhun_jia,
          room_info_pin_an_jie_jia: data.room_info_pin_an_jie_jia,
          room_info_pin_yi_ci_jia: data.room_info_pin_yi_ci_jia,
          room_info_price_prec: data.room_info_price_prec,
          room_list_room: data.room_list_room,
          room_list_price: data.room_list_price,
          room_list_area: data.room_list_area,
          room_list_cate: data.room_list_cate,
          room_list_type: data.room_list_type,
          room_list_icon: data.room_list_icon,
          room_list_price_type: data.room_list_price_type,
          room_list_price_type_way: data.room_list_price_type_way,
          room_list_area_type: data.room_list_area_type,
          order_price: this.sp.pin_you_hui && typeof order_price == 'number' ? order_price : data.order_price,
          order_area: data.order_area,
          room_info_prec_status: data.room_info_prec_status,
          room_info_select: data.room_info_select,
          room_publish: data.room_publish,
          room_info_pin_xian_jia_scribe: data.room_info_pin_xian_jia_scribe,
          room_info_pin_yuan_jia_scribe: data.room_info_pin_yuan_jia_scribe,
          room_info_pin_you_hui_jia_scribe: data.room_info_pin_you_hui_jia_scribe,
          room_info_pin_biao_zhun_jia_scribe: data.room_info_pin_biao_zhun_jia_scribe,
          room_info_pin_an_jie_jia_scribe: data.room_info_pin_an_jie_jia_scribe,
          room_info_pin_yi_ci_jia_scribe: data.room_info_pin_yi_ci_jia_scribe,
          room_info_area_type: data.room_info_area_type,
          pin_cheng_jiao_jia: data.pin_cheng_jiao_jia,
          room_info_gift_price: data.room_info_gift_price,
          you_hui_price: typeof order_price == 'number' ? order_price : data.order_price
        },
        project_order: {
          collect_count: data.collect_count,
          all_btn: data.all_btn,
          sign_up_time: data.sign_up_time,
          buy_other: data.buy_other_time,
          rand_time_min: data.rand_time_min == '' ? 0 : data.rand_time_min,
          rand_time_type: data.rand_time_type,
          rand_start_time_type: data.rand_start_time_type,
          rand_start_time_min: data.rand_start_time_min,
          matching_time_min: data.matching_time_min || 0,
          matching_time_type: data.matching_time_type,
          is_formal_rand: data.is_formal_rand,
          buy_count_collect: data.buy_count_collect,
          buy_start_all_time_type: data.buy_start_all_time_type,
          buy_start_all_time_min: data.buy_start_all_time_min
        },
        project_pay: {
          online_pay: data.online_pay,
          online_sign: data.online_sign,
          online_sign_type: data.online_sign_type,
          price_money: parseFloat(data.price_money),
          test_room_price: parseFloat(data.test_room_price),
          wx_appid: data.wx_appid,
          wx_appsecret: data.wx_appsecret,
          wx_mchid: data.wx_mchid,
          wx_key: data.wx_key,
          project_room: data.project_room,
          car_price: parseFloat(data.car_price),
          test_car_price: parseFloat(data.test_car_price),
          villa_price: parseFloat(data.villa_price),
          shops_price: parseFloat(data.shops_price),
          is_online_rule: data.is_online_rule,
          online_sign_pay_base: data.online_sign_pay_base,
          pay_info_car_rule: data.pay_info_car_rule,
          pay_info_room_rule: data.pay_info_room_rule
        },
        project_timing: {
          is_time_limit: timing.is_time_limit,
          timing_name: timing.timing_name,
          timing: timing.timing_list || []
        },
        pay_info: this.allPay,
        you_hui_rule: this.you_hui_rule
      }; // 保存接口

      preserveSave(dataInfo).then(function (res) {
        if (res.status == 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.getDataInfo();
        }
      });
    }, 200, true)
  }
};