var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: require("../../../assets/report/logo.png") },
    }),
    _c("img", {
      staticClass: "banner-img",
      attrs: { src: require("../../../assets/report/banner-bg2.png") },
    }),
    _c("div", { staticClass: "top-btn-wrapper" }, [
      _c("div", { staticClass: "top-btn" }, [
        _c("div", { staticClass: "bop-btn-text" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
    ]),
    _c("div", { staticClass: "bottom-text-wrapper" }, [
      _c("div", { staticClass: "success-text" }, [
        _vm._v(_vm._s(_vm.subtitle)),
      ]),
      _c("div", { staticClass: "time-text" }, [
        _vm._v(_vm._s(_vm._f("dateFormat")(_vm.time * 1000, "YYYY年MM月DD日"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }