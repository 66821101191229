var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail_page-wrapper" },
    [
      _c("div", { staticClass: "header_search" }, [
        _c("div", { staticClass: "detail_page-header" }, [
          _c("div", { staticClass: "detail_page-header-title" }, [
            _c("span", { attrs: { title: _vm.$parent.parameter.list.name } }, [
              _vm._v(_vm._s(_vm.$parent.parameter.list.name)),
            ]),
            _c("div", { staticClass: "active-name" }, [
              _vm._v(_vm._s(_vm.$parent.activity_name)),
            ]),
          ]),
          _c("div", { staticClass: "detail_page-header-oper" }, [
            _c(
              "div",
              { staticClass: "search-room" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "detail_page-header-oper-search-input",
                    attrs: {
                      remote: "",
                      clearable: "",
                      filterable: "",
                      "reserve-keyword": "",
                      "loading-text": "搜索中...",
                      "remote-method": _vm.remoteSearch,
                      loading: _vm.$parent.searchRoomLoading,
                      placeholder:
                        "搜索" + _vm.$parent.parameter.template.template,
                    },
                    on: { change: _vm.searchRoomSelect },
                    model: {
                      value: _vm.$parent.searchRoomParams.room,
                      callback: function ($$v) {
                        _vm.$set(_vm.$parent.searchRoomParams, "room", $$v)
                      },
                      expression: "$parent.searchRoomParams.room",
                    },
                  },
                  _vm._l(_vm.$parent.searchRoomList, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: item.id,
                        attrs: {
                          label: item.room,
                          value: item.id,
                          disabled: item.room_status < 0 || item.del == 1,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "search-room-item-label" },
                          [
                            _c(
                              "span",
                              { staticClass: "search-room-item-label-text" },
                              [_vm._v(_vm._s(item.room))]
                            ),
                            item.room_status < 0 || item.del == 1
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "search-room-item-notSale" },
                                    [_vm._v(_vm._s(item.room_desc || "非售"))]
                                  ),
                                ]
                              : (item.room_status > 0 && item.user_id > 0) ||
                                (item.room_status > 0 &&
                                  item.user_id <= 0 &&
                                  item.reserv_user_id !=
                                    _vm.$parent.userInfo.info.user_info.id)
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "search-room-item-sold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parent.parameter.list
                                            .pin_gao_qing
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "search-room-item-checked",
                            style: {
                              visibility: item.checked ? "visible" : "hidden",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-check" })]
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c("img", {
                  staticClass: "sousuo_img",
                  attrs: { src: require("@/assets/image/sousuo.png"), alt: "" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "header_right" },
            [
              _c(
                "div",
                {
                  staticClass: "refresh-room",
                  on: { click: _vm.refreshRoomHandle },
                },
                [
                  _c("img", {
                    class: [
                      "refresh-room-btn",
                      { refresh_loading: _vm.$parent.refreshRoomLoading },
                    ],
                    attrs: {
                      src: require("@/assets/image/refresh-room-img.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("screenfull", {
                staticClass: "screen-btn",
                attrs: { issvg: false },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "content_box" }, [
        _c("div", { staticClass: "houseCar" }, [
          _c("div", { staticClass: "card_lr" }, [
            _vm.$parent.residenceBuildList.length
              ? _c("div", { staticClass: "house houseCar_list" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "listContent" },
                    _vm._l(
                      _vm.$parent.residenceBuildList,
                      function (build, index) {
                        return _c(
                          "div",
                          {
                            key: build.id,
                            class:
                              _vm.activeClass == "1" + index
                                ? "detail_page-content-oper-item active-oper-item"
                                : "detail_page-content-oper-item",
                            on: {
                              click: function ($event) {
                                return _vm.selectBuild(
                                  { build: build },
                                  "1",
                                  index
                                )
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(build.name))])]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.$parent.carTypeArr.length
              ? _c("div", { staticClass: "house houseCar_list" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "listContent" },
                    [
                      _vm._l(_vm.$parent.carTypeArr, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.onlyid,
                              class:
                                _vm.activeClass == "2" + index
                                  ? "detail_page-content-oper-item active-oper-item"
                                  : "detail_page-content-oper-item",
                              on: {
                                click: function ($event) {
                                  return _vm.selectBuild(item, "2", index)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.name || "无楼层")),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._m(2),
        ]),
        _c("div", { staticClass: "detail_page-left" }, [
          _c("div", { staticClass: "detail_page-content" }, [
            _c(
              "div",
              {
                ref: "detail_page-content-box",
                staticClass: "detail_page-content-box",
              },
              [
                _vm.$parent.searchBuildParams.cate == 1
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "detail_page-content-room-floor-wrapper",
                        },
                        [
                          _c("div", {
                            staticClass: "detail_page-content-unit-title",
                            staticStyle: { "padding-bottom": "37px" },
                          }),
                          _c(
                            "div",
                            { staticClass: "detail_page-content-room-floor" },
                            [
                              _vm._l(
                                _vm.$parent.residenceFloorList,
                                function (item) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass:
                                          "detail_page-content-floor-item",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "floor-item-content" },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "detail_page-content-unit-wrapper" },
                        [
                          _vm._l(_vm.$parent.residenceList, function (item) {
                            return [
                              _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "detail_page-content-unit",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "detail_page-content-unit-title",
                                      style: !item.name
                                        ? "padding-bottom: 37px"
                                        : "",
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.name))])]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "detail_page-content-room-wrapper",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "detail_page-content-room-list",
                                        },
                                        [
                                          _vm._l(item.floors, function (_item) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: _item.id,
                                                  staticClass:
                                                    "detail_page-content-floor-item",
                                                },
                                                [
                                                  _vm._l(
                                                    _item.rooms,
                                                    function (_item_, _index_) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: _index_,
                                                            ref:
                                                              "scroll-item-" +
                                                              item.id +
                                                              "-" +
                                                              _item.id +
                                                              "-" +
                                                              _index_,
                                                            refInFor: true,
                                                            class: [
                                                              "detail_page-content-room-item",
                                                              {
                                                                backgroundRed:
                                                                  (_item_.room_status >
                                                                    0 &&
                                                                    _item_.user_id >
                                                                      0) ||
                                                                  (_item_.room_status >
                                                                    0 &&
                                                                    _item_.user_id <=
                                                                      0 &&
                                                                    _item_.reserv_user_id !=
                                                                      _vm
                                                                        .$parent
                                                                        .userInfo
                                                                        .info
                                                                        .user_info
                                                                        .id),
                                                                room_unSold_checked:
                                                                  _item_.checked &&
                                                                  (_item_.room_status ==
                                                                    0 ||
                                                                    (_item_.room_status >
                                                                      0 &&
                                                                      _item_.user_id <=
                                                                        0 &&
                                                                      _item_.reserv_user_id ==
                                                                        _vm
                                                                          .$parent
                                                                          .userInfo
                                                                          .info
                                                                          .user_info
                                                                          .id)),
                                                                room_sold_checked:
                                                                  _item_.checked &&
                                                                  ((_item_.room_status >
                                                                    0 &&
                                                                    _item_.user_id >
                                                                      0) ||
                                                                    (_item_.room_status >
                                                                      0 &&
                                                                      _item_.user_id <=
                                                                        0 &&
                                                                      _item_.reserv_user_id !=
                                                                        _vm
                                                                          .$parent
                                                                          .userInfo
                                                                          .info
                                                                          .user_info
                                                                          .id)),
                                                                room_notSale_checked:
                                                                  _item_.checked &&
                                                                  (_item_.room_status <
                                                                    0 ||
                                                                    _item_.del ==
                                                                      1),
                                                                room_notSale_item:
                                                                  _item_.room_status <
                                                                    0 ||
                                                                  _item_.del ==
                                                                    1,
                                                              },
                                                            ],
                                                            style: {
                                                              width:
                                                                "calc(" +
                                                                100 /
                                                                  item.max_room_num +
                                                                "% - " +
                                                                (item.max_room_num >
                                                                1
                                                                  ? 10 /
                                                                    item.max_room_num
                                                                  : 0) +
                                                                "px)",
                                                              visibility:
                                                                _item_.id
                                                                  ? "visible"
                                                                  : "hidden",
                                                              opacity:
                                                                _item_.room_status ==
                                                                  -1 &&
                                                                _item_.not_desc ===
                                                                  "不显示"
                                                                  ? 0
                                                                  : 1,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.chooseRoom(
                                                                  _item_
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _item_.no_name ||
                                                                    ""
                                                                )
                                                              ),
                                                            ]),
                                                            _item_.room_status <
                                                              0 ||
                                                            _item_.del == 1
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "room-status-notSale",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .$parent
                                                                            .parameter
                                                                            .sp
                                                                            .pin_fei_shou ||
                                                                            "非售"
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          }),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "detail_page-content-villa-wrapper" },
                        [
                          _vm._l(_vm.$parent.carList, function (item) {
                            return [
                              _c(
                                "div",
                                {
                                  key: item.id,
                                  ref: "scroll-item-" + item.id,
                                  refInFor: true,
                                  class: [
                                    "detail_page-content-room-item villa-room-item",
                                    {
                                      backgroundRed:
                                        (item.room_status > 0 &&
                                          item.user_id > 0) ||
                                        (item.room_status > 0 &&
                                          item.user_id <= 0 &&
                                          item.reserv_user_id !=
                                            _vm.$parent.userInfo.info.user_info
                                              .id),
                                      room_unSold_checked:
                                        item.checked &&
                                        (item.room_status == 0 ||
                                          (item.room_status > 0 &&
                                            item.user_id <= 0 &&
                                            item.reserv_user_id ==
                                              _vm.$parent.userInfo.info
                                                .user_info.id)),
                                      room_sold_checked:
                                        item.checked &&
                                        ((item.room_status > 0 &&
                                          item.user_id > 0) ||
                                          (item.room_status > 0 &&
                                            item.user_id <= 0 &&
                                            item.reserv_user_id !=
                                              _vm.$parent.userInfo.info
                                                .user_info.id)),
                                      room_notSale_checked:
                                        item.checked &&
                                        (item.room_status < 0 || item.del == 1),
                                      room_notSale_item:
                                        item.room_status < 0 || item.del == 1,
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseRoom(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.no_name || "")),
                                  ]),
                                  item.room_status < 0 || item.del == 1
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "room-status-notSale",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$parent.parameter.sp
                                                    .pin_fei_shou || "非售"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]),
        _c("div", { staticClass: "detail_page-right" }, [
          _c(
            "div",
            { ref: "detailPageContent", staticClass: "detail_page-content" },
            [
              _c(
                "div",
                {
                  staticClass: "user-info-wrapper-box",
                  style: !_vm.$parent.userInfo.log_id ? "padding:0 12px" : "",
                },
                [
                  _vm.$parent.userInfo.log_id
                    ? _c(
                        "div",
                        { staticClass: "user-info-wrapper" },
                        [
                          _c("id-card-drive", {
                            ref: "cardDrive",
                            staticStyle: { "margin-bottom": "20px" },
                            on: { pushCard: _vm.queryuser },
                          }),
                          _c("div", { staticClass: "user-info" }, [
                            _c("div", { staticClass: "left-head-info" }, [
                              _c(
                                "div",
                                { staticClass: "user-avatar" },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "user-avatar",
                                      attrs: {
                                        fit: "fill",
                                        src: _vm.$parent.userInfo.info.user_info
                                          .avatar,
                                        "preview-src-list": [
                                          _vm.$parent.userInfo.info.user_info
                                            .avatar,
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "default-avatar-img",
                                          attrs: { slot: "placeholder" },
                                          slot: "placeholder",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "user-avatar",
                                            attrs: {
                                              src: require("@/assets/image/avatar_icon.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "default-avatar-img",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "user-avatar",
                                            attrs: {
                                              src: require("@/assets/image/avatar_icon.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "user-content" }, [
                                _c("div", { staticClass: "name" }, [
                                  _c("span", { staticClass: "name-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$parent.userInfo.info.user_info.name
                                      )
                                    ),
                                  ]),
                                  _vm.$parent.userInfo.info.user_info.code
                                    ? _c("div", { staticClass: "name-bg" }, [
                                        _c("img", {
                                          staticClass: "name-img",
                                          attrs: {
                                            src: require("@/assets/image/namebg.png"),
                                            alt: "",
                                          },
                                        }),
                                        _c("div", { staticClass: "mz" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.userInfo.info
                                                .user_info.code
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c("div", { staticClass: "user-round" }, [
                                  _vm._v(
                                    _vm._s(_vm.$parent.userInfo.info.title)
                                  ),
                                ]),
                                _c("div", { staticClass: "phone-vip" }, [
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/image/phoneIcon.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parent.userInfo.info.user_info
                                            .tel
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "vip-box" }, [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/image/userIcon.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parent.userInfo.info.admin_info
                                            .name || "无置业顾问"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "xw-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.openDrawerShow("user")
                                  },
                                },
                              },
                              [
                                _vm._v("详情 "),
                                _c("img", {
                                  staticClass: "fhIcon",
                                  attrs: {
                                    src: require("@/assets/image/fanhui.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "detail_page-header-oper-toggle-btn-box",
                              on: { click: _vm.checkUserHandle },
                            },
                            [
                              _c("img", {
                                staticClass: "toggle-user-img",
                                attrs: {
                                  src: require("@/assets/image/toggle-user-img.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("切换客户")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "user-card-wrapper" }, [
                !_vm.$parent.userInfo.log_id
                  ? _c(
                      "div",
                      { staticClass: "selectCustomers" },
                      [
                        _c("id-card-drive", {
                          ref: "cardDrive",
                          staticStyle: { "margin-bottom": "20px" },
                          on: { pushCard: _vm.queryuser },
                        }),
                        _c(
                          "div",
                          { staticClass: "dialog-3-content-img-wrapper" },
                          [
                            _c("img", {
                              staticClass: "dialog-3-content-img",
                              attrs: {
                                src: require("@/assets/image/IDCard-img.png"),
                                alt: "",
                              },
                            }),
                            _c(
                              "div",
                              {
                                class: [
                                  "search-img",
                                  {
                                    "searching-wrapper":
                                      _vm.$parent.searchLoading,
                                  },
                                ],
                              },
                              [
                                _c("img", {
                                  class: [
                                    "search-img",
                                    { searching: _vm.$parent.searchLoading },
                                  ],
                                  attrs: {
                                    alt: "",
                                    src: require("@/assets/image/searching-img.png"),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._m(3),
                        _c(
                          "div",
                          { staticClass: "dialog-3-content-form-wrapper" },
                          [
                            _c("el-input", {
                              ref: "userInput",
                              staticClass: "dialog-3-content-form-input",
                              attrs: {
                                clearable: "",
                                placeholder:
                                  "或输入产权人姓名\\手机号\\身份证\\诚意单号查询",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.$parent.getUserList($event)
                                },
                              },
                              model: {
                                value: _vm.$parent.searchParams.tel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.$parent.searchParams, "tel", $$v)
                                },
                                expression: "$parent.searchParams.tel",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "dialog-3-content-form-btn",
                                attrs: {
                                  round: "",
                                  type: "primary",
                                  loading: _vm.$parent.searchLoading,
                                },
                                on: { click: _vm.$parent.getUserList },
                              },
                              [_vm._v("查询 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$parent.userInfo.log_id
                  ? _c(
                      "div",
                      { staticClass: "schedule-box customerOrders cardList" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("认购订单"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "house-box",
                            staticStyle: {
                              "padding-bottom": "0px",
                              "border-bottom": "none",
                              "padding-top": "0px",
                            },
                          },
                          [
                            _vm.$parent.userInfo.info.orders &&
                            _vm.$parent.userInfo.info.orders.length
                              ? _c(
                                  "div",
                                  { staticStyle: { width: "100%" } },
                                  [
                                    _vm.$parent.userInfo.group_list &&
                                    _vm.$parent.userInfo.group_list.length &&
                                    _vm.$parent.parameter.matching_order !== 0
                                      ? _c(
                                          "div",
                                          { staticClass: "ordersConten" },
                                          _vm._l(
                                            _vm.$parent.userInfo.group_list,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.id,
                                                  staticClass: "orderList",
                                                },
                                                _vm._l(
                                                  item.orders,
                                                  function (items) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: items.id,
                                                        staticClass:
                                                          "sub-orders",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(items.room)
                                                          ),
                                                        ]),
                                                        _vm.$parent.parameter
                                                          .pc_help_print == 1 &&
                                                        _vm.$parent.parameter
                                                          .pc_help_word
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "print-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$parent.doPrint(
                                                                        items.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 打印确认单 "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : [
                                          _c(
                                            "div",
                                            { staticClass: "ordersConten" },
                                            _vm._l(
                                              _vm.$parent.userInfo.info.orders,
                                              function (item) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "orderList",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sub-orders",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.room)
                                                          ),
                                                        ]),
                                                        _vm.$parent.parameter
                                                          .pc_help_print == 1 &&
                                                        _vm.$parent.parameter
                                                          .pc_help_word
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "print-btn",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$parent.doPrint(
                                                                        item.id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 打印确认单 "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.$parent.userInfo.info.orders ||
                            !_vm.$parent.userInfo.info.orders.length
                              ? _c("div", { staticClass: "no_prderList" }, [
                                  _c("span", [_vm._v("此客户暂无订单")]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "residue_content" }, [
                              _c("div", { staticClass: "left-text" }, [
                                _vm._v(
                                  " 已选" +
                                    _vm._s(
                                      _vm.$parent.userInfo.info.user_info
                                        .buy_count - _vm.residue
                                    ) +
                                    "套还剩"
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#22a271" } },
                                  [_vm._v(_vm._s(_vm.residue))]
                                ),
                                _vm._v("个名额 "),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.$parent.reserveList.length
                  ? _c(
                      "div",
                      { staticClass: "tabs-card-wrapper cardList" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("预留信息"),
                        ]),
                        _vm._l(_vm.$parent.reserveList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: [
                                "tabs-room-item house-box",
                                {
                                  room_unSold_checked:
                                    item.checked &&
                                    (item.room_status == 0 ||
                                      (item.room_status > 0 &&
                                        item.user_id <= 0 &&
                                        item.reserv_user_id ==
                                          _vm.$parent.userInfo.info.user_info
                                            .id)),
                                  room_sold_checked:
                                    item.checked &&
                                    ((item.room_status > 0 &&
                                      item.user_id > 0) ||
                                      (item.room_status > 0 &&
                                        item.user_id <= 0 &&
                                        item.reserv_user_id !=
                                          _vm.$parent.userInfo.info.user_info
                                            .id)),
                                  room_notSale_checked:
                                    item.checked &&
                                    (item.room_status < 0 || item.del == 1),
                                  room_notSale_item:
                                    item.room_status < 0 || item.del == 1,
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.chooseRoom(item)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.room))]),
                              (item.room_status > 0 && item.user_id > 0) ||
                              (item.room_status > 0 &&
                                item.user_id <= 0 &&
                                item.reserv_user_id !=
                                  _vm.$parent.userInfo.info.user_info.id)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "statsuRg",
                                      staticStyle: { color: "#999999" },
                                    },
                                    [
                                      _vm._v(
                                        " 该客户已" +
                                          _vm._s(
                                            _vm.$parent.userInfo.pin_ren_gou
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "statsuRg",
                                      staticStyle: { color: "#ff3434" },
                                    },
                                    [
                                      _vm._v(
                                        "该客户未" +
                                          _vm._s(
                                            _vm.$parent.userInfo.pin_ren_gou
                                          )
                                      ),
                                    ]
                                  ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.$parent.collectList.length &&
                !_vm.$parent.reserveList.length
                  ? _c(
                      "div",
                      { staticClass: "tabs-card-wrapper cardList" },
                      [
                        _c("div", { staticClass: "title" }, [_vm._v("意向单")]),
                        _vm._l(_vm.$parent.collectList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: [
                                "tabs-room-item house-box",
                                {
                                  room_unSold_checked:
                                    item.checked &&
                                    (item.room_status == 0 ||
                                      (item.room_status > 0 &&
                                        item.user_id <= 0 &&
                                        item.reserv_user_id ==
                                          _vm.$parent.userInfo.info.user_info
                                            .id)),
                                  room_sold_checked:
                                    item.checked &&
                                    ((item.room_status > 0 &&
                                      item.user_id > 0) ||
                                      (item.room_status > 0 &&
                                        item.user_id <= 0 &&
                                        item.reserv_user_id !=
                                          _vm.$parent.userInfo.info.user_info
                                            .id)),
                                  room_notSale_checked:
                                    item.checked &&
                                    (item.room_status < 0 || item.del == 1),
                                  room_notSale_item:
                                    item.room_status < 0 || item.del == 1,
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.chooseRoom(item)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.room))]),
                              (item.room_status > 0 && item.user_id > 0) ||
                              (item.room_status > 0 &&
                                item.user_id <= 0 &&
                                item.reserv_user_id !=
                                  _vm.$parent.userInfo.info.user_info.id)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "statsuRg",
                                      staticStyle: { color: "#ff3434" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$parent.parameter.list
                                              .pin_gao_qing
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.room_status == 0 || item.del == 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "statsuRg",
                                      staticStyle: { color: "#22a271" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parent.parameter.sp.pin_dai_shou
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.room_status < 0 || item.del == 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "statsuRg",
                                      staticStyle: { color: "#999999" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$parent.parameter.sp.pin_fei_shou
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm.$parent.selectRoomInfo.id
                ? _c("div", { staticClass: "zw" })
                : _vm._e(),
              _vm.$parent.selectRoomInfo.id
                ? _c("div", { staticClass: "room-detail-card-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "houseStatus",
                        style:
                          _vm.houseStatus == 1
                            ? "background:#22A271"
                            : _vm.houseStatus == 2
                            ? "background:#999999"
                            : _vm.houseStatus == 3
                            ? "background:#FF3434"
                            : "--",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.houseStatus == 1
                                ? _vm.$parent.parameter.sp.pin_dai_shou ||
                                    "待售"
                                : _vm.houseStatus == 2
                                ? _vm.$parent.selectRoomInfo.room_desc || "非售"
                                : _vm.houseStatus == 3
                                ? _vm.$parent.parameter.list.pin_gao_qing ||
                                  "已售"
                                : "--"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "details_title" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "xqicon_box" }, [
                          _vm.$parent.selectRoomInfo.cate == 1
                            ? _c("img", {
                                staticClass: "xqicon",
                                attrs: {
                                  src: require("@/assets/image/houseImg.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.$parent.selectRoomInfo.cate == 2
                            ? _c("img", {
                                staticClass: "xqicon",
                                attrs: {
                                  src: require("@/assets/image/carImg.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "roomOrprice" }, [
                          _c("div", { staticClass: "room" }, [
                            _vm._v(_vm._s(_vm.$parent.selectRoomInfo.room)),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$parent.selectRoomInfo.old_total_price > 0
                                    ? "¥" +
                                        _vm.$parent.selectRoomInfo
                                          ._old_total_price +
                                        "元"
                                    : "--"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "xw-btn",
                          on: {
                            click: function ($event) {
                              return _vm.openDrawerShow("house")
                            },
                          },
                        },
                        [
                          _vm._v("详情"),
                          _c("img", {
                            staticClass: "fhIcon",
                            attrs: {
                              src: require("@/assets/image/fanhui.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "schedule-box room-detail" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("户型面积")]),
                      _c("div", { staticClass: "hx-mj" }, [
                        _c("div", { staticClass: "hx" }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$parent.selectRoomInfo.cate == 2 &&
                                  _vm.$parent.parameter.list.cate_car == 1
                                  ? "类型"
                                  : "户型"
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "lr" }, [
                            _vm._v(
                              _vm._s(_vm.$parent.selectRoomInfo.room_cate_name)
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "mj" }, [
                          _c("span", { staticClass: "lable" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$parent.selectRoomInfo.cate == 2 &&
                                  _vm.$parent.parameter.list.cate_car == 1
                                  ? "建面"
                                  : "面积"
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "lr" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$parent.selectRoomInfo.build_area) +
                                "㎡ "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "schedule-box room-price" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("价格")]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "content_lsit" }, [
                          _c("span", { staticClass: "list_title" }, [
                            _vm._v("总价 "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "price-box" },
                            [
                              _vm.$parent.parameter.list.room_info_price_type ==
                              3
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_biao_zhun_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_biao_zhun_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_total_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_total_price +
                                                    "元"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yi_ci_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_yi_ci_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .one_off_total_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._one_off_total_price +
                                                    "元"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_an_jie_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_an_jie_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .pin_an_jie_total_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._pin_an_jie_total_price +
                                                      "元"
                                                  : "--"
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              2
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_you_hui_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_you_hui_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .discount_total_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._discount_total_price +
                                                    "元"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yuan_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_yuan_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_total_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_total_price +
                                                    "元"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              1
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_xian_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_xian_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_total_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_total_price +
                                                    "元"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "content_lsit" }, [
                          _c("span", { staticClass: "list_title" }, [
                            _vm._v("单价"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "price-box" },
                            [
                              _vm.$parent.parameter.list.room_info_price_type ==
                              3
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_biao_zhun_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_biao_zhun_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_unit_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_unit_price +
                                                    "元/㎡"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yi_ci_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_yi_ci_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .one_off_unit_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._one_off_unit_price +
                                                    "元/㎡"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_an_jie_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_an_jie_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .pin_an_jie_unit_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._pin_an_jie_unit_price +
                                                      "元/㎡"
                                                  : "--"
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              2
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_you_hui_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_you_hui_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .discount_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._discount_price +
                                                    "元/㎡"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yuan_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_yuan_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_unit_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_unit_price +
                                                    "元/㎡"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              1
                                ? [
                                    _c("div", { staticClass: "price_list" }, [
                                      _c(
                                        "span",
                                        { staticClass: "priceTitle" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_xian_jia
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "moneyColor",
                                          class: _vm.$parent.parameter.list
                                            .room_info_pin_xian_jia_scribe
                                            ? "room-price-del"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .old_unit_price > 0
                                                ? "¥" +
                                                    _vm.$parent.selectRoomInfo
                                                      ._old_unit_price +
                                                    "元/㎡"
                                                : "--"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "room-detail-card-empty-wrapper" }, [
                    _c("div", { staticClass: "room-detail-card-empty" }, [
                      _vm.$parent.userInfo.log_id
                        ? _c("span", [
                            _vm._v(
                              "请用鼠标单击要下单的" +
                                _vm._s(
                                  _vm.$parent.parameter.template.template
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("请先搜索要下单的客户")]),
                    ]),
                  ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "detail_page-footer" },
            [
              this.$parent.parameter.matching_order === 0 &&
              _vm.$parent.userInfo.log_id
                ? [
                    _vm.$parent.userInfo.total_sec >=
                    (_vm.$parent.userInfo.project_order.buy_other || 0) * 60
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "user-info-time",
                              staticStyle: { color: "#f5222d" },
                            },
                            [
                              _c("span", [_vm._v("已超时 ")]),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    "" +
                                      (_vm.user_hours
                                        ? _vm.user_hours < 10
                                          ? "0" + _vm.user_hours + "时"
                                          : _vm.user_hours + "时"
                                        : "") +
                                      (_vm.user_minutes < 10
                                        ? "0" + _vm.user_minutes
                                        : _vm.user_minutes) +
                                      "分" +
                                      (_vm.user_seconds < 10
                                        ? "0" + _vm.user_seconds
                                        : _vm.user_seconds) +
                                      "秒"
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "user-info-time",
                              staticStyle: { color: "#22a271" },
                            },
                            [
                              _c("span", [_vm._v("已持续 ")]),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    "" +
                                      (_vm.user_hours
                                        ? _vm.user_hours < 10
                                          ? "0" + _vm.user_hours + "时"
                                          : _vm.user_hours + "时"
                                        : "") +
                                      (_vm.user_minutes < 10
                                        ? "0" + _vm.user_minutes
                                        : _vm.user_minutes) +
                                      "分" +
                                      (_vm.user_seconds < 10
                                        ? "0" + _vm.user_seconds
                                        : _vm.user_seconds) +
                                      "秒"
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                  ]
                : _vm._e(),
              _c(
                "el-button",
                {
                  class: [
                    "detail_page-footer-btn",
                    {
                      "is-disabled":
                        _vm.is_disabled ||
                        _vm.$parent.dialogConfig.visible ||
                        !_vm.$parent.selectRoomInfo.id ||
                        !_vm.$parent.userInfo.log_id,
                    },
                  ],
                  attrs: {
                    round: "",
                    plain: "",
                    hidden: _vm.$parent.selectRoomInfo,
                  },
                  on: { click: _vm.chooseConfirm },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$parent.parameter.pc_help_print == 1 &&
                        _vm.$parent.parameter.pc_help_word
                        ? "确认下单并打印"
                        : "确认下单"
                    ) + " "
                  ),
                ]
              ),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.drawerShow,
            direction: "rtl",
            size: 478,
            "with-header": false,
          },
          on: {
            close: function ($event) {
              _vm.drawerShow = false
            },
          },
        },
        [
          _c("div", { staticClass: "drawerContent" }, [
            _c("div", { staticClass: "header" }, [
              _vm.drawerTyp == "user"
                ? _c("div", { staticClass: "header_user" }, [
                    _c("div", { staticClass: "left-head-info" }, [
                      _c(
                        "div",
                        { staticClass: "user-avatar" },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "user-avatar",
                              attrs: {
                                fit: "fill",
                                src: _vm.$parent.userInfo.info.user_info.avatar,
                                "preview-src-list": [
                                  _vm.$parent.userInfo.info.user_info.avatar,
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "default-avatar-img",
                                  attrs: { slot: "placeholder" },
                                  slot: "placeholder",
                                },
                                [
                                  _c("img", {
                                    staticClass: "user-avatar",
                                    attrs: {
                                      src: require("@/assets/image/avatar_icon.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "default-avatar-img",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    staticClass: "user-avatar",
                                    attrs: {
                                      src: require("@/assets/image/avatar_icon.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-content" }, [
                        _c("div", { staticClass: "name" }, [
                          _c("span", { staticClass: "name-title" }, [
                            _vm._v(
                              _vm._s(_vm.$parent.userInfo.info.user_info.name)
                            ),
                          ]),
                          _vm.$parent.userInfo.info.user_info.code
                            ? _c("div", { staticClass: "name-bg" }, [
                                _c("img", {
                                  staticClass: "name-img",
                                  attrs: {
                                    src: require("@/assets/image/namebg.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "mz" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$parent.userInfo.info.user_info.code
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "phone-vip" }, [
                          _c("div", [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("@/assets/image/phoneIcon.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$parent.userInfo.info.user_info.tel)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vip-box" }, [
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("@/assets/image/userIcon.png"),
                                alt: "",
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$parent.userInfo.info.admin_info.name ||
                                    "无置业顾问"
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.drawerTyp == "house"
                ? _c("div", { staticClass: "header_user header_house" }, [
                    _c("div", { staticClass: "left-head-info" }, [
                      _c("div", { staticClass: "user-avatar" }, [
                        _vm.$parent.selectRoomInfo.cate == 1
                          ? _c("img", {
                              staticClass: "xqicon",
                              attrs: {
                                src: require("@/assets/image/houseImg.svg"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm.$parent.selectRoomInfo.cate == 2
                          ? _c("img", {
                              staticClass: "xqicon",
                              attrs: {
                                src: require("@/assets/image/carImg.svg"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "user-content" }, [
                        _c("div", { staticClass: "room" }, [
                          _vm._v(_vm._s(_vm.$parent.selectRoomInfo.room)),
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$parent.selectRoomInfo.old_total_price > 0
                                  ? "¥" +
                                      _vm.$parent.selectRoomInfo
                                        ._old_total_price +
                                      "元"
                                  : "--"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "status-box" },
                      [
                        _vm.houseStatus == 3
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/image/ysImg.png"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "status-text",
                                  staticStyle: { color: "#c00909" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$parent.parameter.list.pin_gao_qing
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.houseStatus == 2
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/image/fsImg.png"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "status-text",
                                  staticStyle: { color: "#8d8d8d" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$parent.selectRoomInfo.room_desc ||
                                        "非售"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.houseStatus == 1
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/image/dsImg.png"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "status-text",
                                  staticStyle: { color: "#22a271" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$parent.parameter.sp.pin_dai_shou ||
                                        "待售"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "lr" },
              [
                _vm.drawerTyp == "user"
                  ? [
                      _c("div", { staticClass: "user-info-wrapper" }, [
                        _c("div", { staticClass: "schedule-box cardList" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("认购进度"),
                          ]),
                          _c("div", { staticClass: "house-box" }, [
                            _vm.$parent.userInfo.info.user_info.buy_count <= 10
                              ? _c("div", { staticClass: "left-radio" }, [
                                  _c(
                                    "div",
                                    { staticClass: "radio-box" },
                                    _vm._l(
                                      _vm.$parent.userInfo.info.user_info
                                        .buy_count,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "radio-list",
                                          },
                                          [
                                            _vm.$parent.userInfo.info.user_info
                                              .buy_count -
                                              _vm.residue >=
                                            index + 1
                                              ? _c("img", {
                                                  staticClass: "checkedIcon",
                                                  attrs: {
                                                    src: require("@/assets/image/xz-img.png"),
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "residue" }, [
                              _vm._v(" 还剩"),
                              _c(
                                "span",
                                { staticStyle: { color: "#22a271" } },
                                [_vm._v(_vm._s(_vm.residue))]
                              ),
                              _vm._v("个名额 "),
                            ]),
                            _vm.$parent.userInfo.info.user_info.buy_count > 10
                              ? _c("div", { staticClass: "left-text" }, [
                                  _vm._v(
                                    " 已选" +
                                      _vm._s(
                                        _vm.$parent.userInfo.info.user_info
                                          .buy_count - _vm.residue
                                      ) +
                                      "还剩"
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#22a271" } },
                                    [_vm._v(_vm._s(_vm.residue))]
                                  ),
                                  _vm._v("个名额 "),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.$parent.userInfo.handled_group_list
                            ? _c("div", { staticClass: "house-box" }, [
                                _c("div", { staticClass: "left-radio" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lable",
                                      staticStyle: { color: "#ff3434" },
                                    },
                                    [_vm._v("待完成订单:")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "handleContent" },
                                    _vm._l(
                                      _vm.$parent.userInfo.handled_group_list,
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "handleList",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.room)),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.$parent.reserveList.length
                          ? _c(
                              "div",
                              { staticClass: "tabs-card-wrapper cardList" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("预留信息"),
                                ]),
                                _vm._l(
                                  _vm.$parent.reserveList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        class: [
                                          "tabs-room-item house-box",
                                          {
                                            room_unSold_checked:
                                              item.checked &&
                                              (item.room_status == 0 ||
                                                (item.room_status > 0 &&
                                                  item.user_id <= 0 &&
                                                  item.reserv_user_id ==
                                                    _vm.$parent.userInfo.info
                                                      .user_info.id)),
                                            room_sold_checked:
                                              item.checked &&
                                              ((item.room_status > 0 &&
                                                item.user_id > 0) ||
                                                (item.room_status > 0 &&
                                                  item.user_id <= 0 &&
                                                  item.reserv_user_id !=
                                                    _vm.$parent.userInfo.info
                                                      .user_info.id)),
                                            room_notSale_checked:
                                              item.checked &&
                                              (item.room_status < 0 ||
                                                item.del == 1),
                                            room_notSale_item:
                                              item.room_status < 0 ||
                                              item.del == 1,
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.chooseRoom(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.room))]),
                                        (item.room_status > 0 &&
                                          item.user_id > 0) ||
                                        (item.room_status > 0 &&
                                          item.user_id <= 0 &&
                                          item.reserv_user_id !=
                                            _vm.$parent.userInfo.info.user_info
                                              .id)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "statsuRg",
                                                staticStyle: {
                                                  color: "#999999",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " 该客户已" +
                                                    _vm._s(
                                                      _vm.$parent.userInfo
                                                        .pin_ren_gou
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass: "statsuRg",
                                                staticStyle: {
                                                  color: "#ff3434",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "该客户未" +
                                                    _vm._s(
                                                      _vm.$parent.userInfo
                                                        .pin_ren_gou
                                                    )
                                                ),
                                              ]
                                            ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.$parent.collectList.length
                          ? _c(
                              "div",
                              { staticClass: "tabs-card-wrapper cardList" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("意向单"),
                                ]),
                                _vm._l(
                                  _vm.$parent.collectList,
                                  function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        class: [
                                          "tabs-room-item house-box",
                                          {
                                            room_unSold_checked:
                                              item.checked &&
                                              (item.room_status == 0 ||
                                                (item.room_status > 0 &&
                                                  item.user_id <= 0 &&
                                                  item.reserv_user_id ==
                                                    _vm.$parent.userInfo.info
                                                      .user_info.id)),
                                            room_sold_checked:
                                              item.checked &&
                                              ((item.room_status > 0 &&
                                                item.user_id > 0) ||
                                                (item.room_status > 0 &&
                                                  item.user_id <= 0 &&
                                                  item.reserv_user_id !=
                                                    _vm.$parent.userInfo.info
                                                      .user_info.id)),
                                            room_notSale_checked:
                                              item.checked &&
                                              (item.room_status < 0 ||
                                                item.del == 1),
                                            room_notSale_item:
                                              item.room_status < 0 ||
                                              item.del == 1,
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.chooseRoom(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v(_vm._s(item.room))]),
                                        (item.room_status > 0 &&
                                          item.user_id > 0) ||
                                        (item.room_status > 0 &&
                                          item.user_id <= 0 &&
                                          item.reserv_user_id !=
                                            _vm.$parent.userInfo.info.user_info
                                              .id)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "statsuRg",
                                                staticStyle: {
                                                  color: "#ff3434",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.parameter.list
                                                        .pin_gao_qing
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.room_status == 0 || item.del == 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "statsuRg",
                                                staticStyle: {
                                                  color: "#22a271",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.sp
                                                      .pin_dai_shou
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.room_status < 0 || item.del == 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "statsuRg",
                                                staticStyle: {
                                                  color: "#22a271",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.sp
                                                      .pin_fei_shou
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.$parent.userInfo.info.orders &&
                        _vm.$parent.userInfo.info.orders.length
                          ? _c(
                              "div",
                              { staticClass: "customerOrders cardList" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("订单"),
                                ]),
                                _vm.$parent.userInfo.group_list &&
                                _vm.$parent.userInfo.group_list.length &&
                                _vm.$parent.parameter.matching_order !== 0
                                  ? _c(
                                      "div",
                                      { staticClass: "ordersConten" },
                                      _vm._l(
                                        _vm.$parent.userInfo.group_list,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticClass: "orderList",
                                            },
                                            _vm._l(
                                              item.orders,
                                              function (items) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: items.id,
                                                    staticClass: "sub-orders",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(items.room)
                                                      ),
                                                    ]),
                                                    _vm.$parent.parameter
                                                      .pc_help_print == 1 &&
                                                    _vm.$parent.parameter
                                                      .pc_help_word
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "print-btn",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$parent.doPrint(
                                                                  items.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 打印确认单 "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : [
                                      _c(
                                        "div",
                                        { staticClass: "ordersConten" },
                                        _vm._l(
                                          _vm.$parent.userInfo.info.orders,
                                          function (item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass: "orderList",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "sub-orders" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.room)),
                                                    ]),
                                                    _vm.$parent.parameter
                                                      .pc_help_print == 1 &&
                                                    _vm.$parent.parameter
                                                      .pc_help_word
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "print-btn",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$parent.doPrint(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 打印确认单 "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]
                  : _vm._e(),
                _vm.drawerTyp == "house"
                  ? [
                      _c(
                        "div",
                        { staticClass: "user-info-wrapper house-info-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "contenType1" },
                            [
                              _vm.$parent.parameter.list.room_info_price_type ==
                              3
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("总价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_biao_zhun_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_biao_zhun_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .old_total_price > 0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._old_total_price +
                                                            "元"
                                                        : "--"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "bot" }, [
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yi_ci_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_yi_ci_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .one_off_total_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._one_off_total_price +
                                                      "元"
                                                  : "--"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_an_jie_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_an_jie_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$parent.selectRoomInfo
                                                    .pin_an_jie_total_price > 0
                                                    ? "¥" +
                                                        _vm.$parent
                                                          .selectRoomInfo
                                                          ._pin_an_jie_total_price +
                                                        "元"
                                                    : "--"
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              2
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("总价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_you_hui_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_you_hui_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .discount_total_price >
                                                        0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._discount_total_price +
                                                            "元"
                                                        : "--"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "bot" }, [
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yuan_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_yuan_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .old_total_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._old_total_price +
                                                      "元"
                                                  : "--"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              1
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("总价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_xian_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_xian_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .old_total_price > 0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._old_total_price +
                                                            "元"
                                                        : "--"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "contenType1" },
                            [
                              _vm.$parent.parameter.list.room_info_price_type ==
                              3
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("单价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_biao_zhun_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_biao_zhun_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .old_unit_price > 0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._old_unit_price +
                                                            "元/㎡"
                                                        : "--"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "bot" }, [
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yi_ci_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_yi_ci_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .one_off_unit_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._one_off_unit_price +
                                                      "元/㎡"
                                                  : "--"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_an_jie_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_an_jie_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$parent.selectRoomInfo
                                                    .pin_an_jie_unit_price > 0
                                                    ? "¥" +
                                                        _vm.$parent
                                                          .selectRoomInfo
                                                          ._pin_an_jie_unit_price +
                                                        "元/㎡"
                                                    : "--"
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              2
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("单价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_you_hui_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_you_hui_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .discount_price > 0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._discount_price +
                                                            "元/㎡"
                                                        : "--"
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "bot" }, [
                                      _c("div", { staticClass: "lists" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.parameter.list
                                                .room_info_pin_yuan_jia
                                            ) + ": "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "countPice",
                                            class: _vm.$parent.parameter.list
                                              .room_info_pin_yuan_jia_scribe
                                              ? "room-price-del"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$parent.selectRoomInfo
                                                  .old_unit_price > 0
                                                  ? "¥" +
                                                      _vm.$parent.selectRoomInfo
                                                        ._old_total_price +
                                                      "元/㎡"
                                                  : "--"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.$parent.parameter.list.room_info_price_type ==
                              1
                                ? [
                                    _c("div", { staticClass: "top" }, [
                                      _c("span", { staticClass: "lable" }, [
                                        _vm._v("单价: "),
                                      ]),
                                      _c("p", [
                                        _c(
                                          "span",
                                          { staticClass: "countPice" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "price2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$parent.parameter.list
                                                      .room_info_pin_xian_jia
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class: _vm.$parent.parameter
                                                  .list
                                                  .room_info_pin_xian_jia_scribe
                                                  ? "room-price-del"
                                                  : "",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$parent.selectRoomInfo
                                                        .old_unit_price > 0
                                                        ? "¥" +
                                                            _vm.$parent
                                                              .selectRoomInfo
                                                              ._old_unit_price +
                                                            "元/㎡"
                                                        : "--"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "area" }, [
                            _vm.$parent.selectRoomInfo.build_area != 0
                              ? _c("div", { staticClass: "areaList" }, [
                                  _c("div", { staticClass: "square" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$parent.selectRoomInfo.build_area
                                      ) + "㎡"
                                    ),
                                  ]),
                                  _c("span", { staticClass: "countPice" }, [
                                    _vm._v("建筑面积"),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.$parent.selectRoomInfo.room_area != 0
                              ? _c("div", { staticClass: "areaList" }, [
                                  _c("div", { staticClass: "square" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$parent.selectRoomInfo.room_area
                                      ) + "㎡"
                                    ),
                                  ]),
                                  _c("span", { staticClass: "countPice" }, [
                                    _vm._v("套内面积"),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "areaList" }, [
                              _c("div", { staticClass: "square" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$parent.selectRoomInfo.room_cate_name
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "countPice" }, [
                                _vm._v("户型/类型"),
                              ]),
                            ]),
                            _vm.$parent.selectRoomInfo.room_cate_type
                              ? _c("div", { staticClass: "areaList" }, [
                                  _c("div", { staticClass: "square" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$parent.selectRoomInfo
                                          .room_cate_type
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "countPice" }, [
                                    _vm._v("结构"),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          (_vm.$parent.parameter.list.room_info_gift == 1 ||
                            _vm.$parent.parameter.list.room_info_gift_price ==
                              1) &&
                          (_vm.$parent.selectRoomInfo.gift_desc ||
                            _vm.$parent.selectRoomInfo.gift_price)
                            ? _c("div", { staticClass: "contenType1" }, [
                                _c("div", { staticClass: "top top2" }, [
                                  _c("span", { staticClass: "lable" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$parent.parameter.list.pin_fu_zen
                                      ) + "信息: "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "countPice" },
                                    [void 0],
                                    2
                                  ),
                                ]),
                                _vm.$parent.selectRoomInfo.gift_desc
                                  ? _c("div", { staticClass: "bot bot2" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$parent.parameter.list
                                              .pin_fu_zen_product || "描述"
                                          ) +
                                            "：" +
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .gift_desc
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.$parent.parameter.list
                                  .room_info_gift_price &&
                                _vm.$parent.selectRoomInfo.gift_price
                                  ? _c("div", { staticClass: "bot bot2" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$parent.parameter.list
                                              .pin_fu_zen_price || "价值"
                                          ) + "： "
                                        ),
                                      ]),
                                      _c("span", { staticClass: "countPice" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$parent.selectRoomInfo
                                                .gift_price
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.$parent.parameter.list.room_info_desc == 1 &&
                          _vm.$parent.selectRoomInfo.desc
                            ? _c("div", { staticClass: "contenType1" }, [
                                _c("div", { staticClass: "top top2" }, [
                                  _c("span", { staticClass: "lable" }, [
                                    _vm._v("描述: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(_vm.$parent.selectRoomInfo.desc)
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.houseStatus == 3
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tabs-card-wrapper cardList orderingInfo",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tabs-room-item house-box'",
                                    },
                                    [
                                      _c("span", [_vm._v("下单客户: ")]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "statsuRg",
                                          staticStyle: { color: "#999999" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$parent.orderRoomInfo.user
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tabs-room-item house-box'",
                                    },
                                    [
                                      _c("span", [_vm._v("下单时间: ")]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "statsuRg",
                                          staticStyle: { color: "#999999" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$parent.orderRoomInfo.time >
                                                  0
                                                  ? _vm.$parent
                                                      .moment(
                                                        _vm.$parent
                                                          .orderRoomInfo.time *
                                                          1000
                                                      )
                                                      .format(
                                                        "YYYY/MM/DD HH:mm"
                                                      )
                                                  : _vm.$parent.orderRoomInfo
                                                      .time
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tabs-room-item house-box'",
                                    },
                                    [
                                      _c("span", [_vm._v("操作人: ")]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "statsuRg",
                                          staticStyle: { color: "#999999" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$parent.orderRoomInfo.admin
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "drawer-footer" },
              [
                _vm.drawerTyp == "user"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "drawer-footer-btn",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.drawerShow = false
                          },
                        },
                      },
                      [_vm._v("关闭")]
                    )
                  : _vm._e(),
                _vm.drawerTyp == "house"
                  ? _c(
                      "div",
                      { staticClass: "houseBtn" },
                      [
                        _vm.houseStatus == 3 &&
                        _vm.$parent.parameter.pc_help_print == 1 &&
                        _vm.$parent.parameter.pc_help_word
                          ? _c(
                              "el-button",
                              {
                                staticClass: "drawer-footer-print-btn",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$parent.doPrint(
                                      _vm.$parent.orderRoomInfo.order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("打印确认单")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            class:
                              _vm.houseStatus == 3
                                ? "drawer-footer-close-btn"
                                : "drawer-footer-btn",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.drawerShow = false
                              },
                            },
                          },
                          [_vm._v("关闭")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "listTitle" }, [
      _c("img", {
        staticClass: "titleBg",
        attrs: { src: require("@/assets/image/titleBg.png"), alt: "" },
      }),
      _vm._v(" 房源 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "listTitle" }, [
      _c("img", {
        staticClass: "titleBg",
        attrs: { src: require("@/assets/image/titleBg2.png"), alt: "" },
      }),
      _vm._v(" 车位 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "xfLog" }, [
      _c("img", {
        staticClass: "xfLog-img",
        attrs: { src: require("@/assets/image/logo2.png"), alt: "" },
      }),
      _c("div", { staticClass: "log-text" }, [_vm._v("地产开盘优选品牌")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "prompt" }, [
      _vm._v("请将"),
      _c("span", { staticStyle: { color: "rgb(34, 162, 113)" } }, [
        _vm._v("客户身份证放在读卡器上"),
      ]),
      _vm._v("，或手动查询"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }