import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Api from '../../../api/deposit';
import { villaAreaList } from '@/api/roomCar';
import { adminParameter, authGetEvent, projectConfigure } from '@/api/public';
import { getAdminParameter } from '@/api/houseHistory';
import mixins from '../../assistHouse/mixins/card_socket';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import searchUserList from '../../../components/searchUserList/index.vue';
import utils from '@/utils/utils';
export default {
  name: 'Deposit',
  components: {
    eventSelect: eventSelect,
    searchUserList: searchUserList
  },
  // mixins: [mixins],
  data: function data() {
    return {
      event: {},
      // 切换活动
      type: 1,
      // 参数配置项
      adminList: {},
      // 页面高度 以及表格计算
      appHeight: '',
      topHeight: '',
      // 展开更多
      moreText: '展开',
      // 是否展开风度哦
      is_more: false,
      // 展开箭头图标
      moreIcon: 'el-icon-arrow-down',
      // 表格配置
      total: 1,
      // 总数
      // 表格数据
      tableData: [],
      // 表格loading
      tableLoading: false,
      // 单元数组
      unitArray: [],
      // 当前页
      page: 1,
      // 每页条数
      pageSize: 10,
      // 区域数据
      areaList: [],
      // 线上交定金
      onLine: [{
        value: '0,1,-1',
        label: '全部'
      }, {
        value: '1',
        label: '已交'
      }, {
        value: '-1',
        label: '已退款'
      }],
      // 线下交定金
      offline: [{
        value: '0,1,2,-1',
        label: '全部'
      }, {
        value: '1',
        label: '已交'
      }, {
        value: '2',
        label: '已交部分'
      }, {
        value: '-1',
        label: '标记已退款'
      }],
      value: '',
      // 查询列表提交数据
      from: {
        room_type: 0,
        type: 2,
        handled: '1',
        //定金状态默认改为为已交
        order_status: '0' //订单状态默认查询全部

      },
      // 楼栋信息
      activity: {},
      // 订单 头 前缀
      code_top: '',
      //  初始id 用来计算
      start_id: 0,
      //  活动 数组
      event_start: [],
      ieduction: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '有抵扣款'
      }, {
        value: 2,
        label: '无抵扣款'
      }],
      sortRoom: [],
      select_event: {},
      // 选择的活动和场次id集
      eventList: [],
      // 项目活动列表
      // 影藏 房源车位单选
      is_radio: false,
      // 客户列表弹窗配置
      dialogConfig: {
        visible: false
      },
      // 身份证识别器搜索参数
      searchParams: {
        card: ''
      },
      userList: [],
      dialogVisible: false,
      log_list: [],
      selectPrice: '',
      // 选择付款
      // 订单状态下拉选项
      orderStatusOptions: [{
        value: '0,-1,1,2,-2,3',
        label: '全部'
      }, {
        value: 0,
        label: '有效订单'
      }, {
        value: -1,
        label: '无效订单'
      }]
    };
  },
  computed: _objectSpread({
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 160;
    }
  }, mapGetters(['roles', 'customSetting'])),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  // 初始化
  created: function created() {
    this.getConfigure();
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.top.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    this.getAdmin(); // 读卡设备socket

    if (!this.socket || !this.socketConnect) {
      this.$refs.cardDrive.wsConnect();
    }
  },
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 获取项目配置
    getAdmin: function getAdmin() {
      var _this2 = this;

      getAdminParameter().then(function (res) {
        _this2.list = res.data.list;
        _this2.event = res.data.event;
      });
    },
    // 身份证识别器搜索客户
    getUserList: function getUserList() {
      var _this3 = this;

      if (!this.searchParams.card) {
        this.$message({
          type: 'error',
          message: '未识别到该身份证号'
        });
        return;
      }

      this.searchLoading = true;
      Api.getPayUserList(_objectSpread({}, this.searchParams)).then(function (res) {
        if (res.data.status_code == 200) {
          var users = res.data.data.users;

          if (!users) {
            _this3.dialogConfig = {
              visible: true,
              type: 2
            };
            return;
          }

          for (var i = 0; i < users.length; i++) {
            var user = users[i];
            user.uao_list = user.uao_name.split(',');
          }

          _this3.userList = users || [];

          if (_this3.userList.length) {
            _this3.userList[0].checked = true;
            _this3.dialogConfig = {
              visible: true,
              type: 1
            };
          } // 查找到用户之后关闭socket （只有选完房或主动切换客户才能再开启扫身份证功能）

        } else {
          _this3.$message({
            type: 'error',
            message: res.data.msg
          });
        }

        _this3.$forceUpdate();
      }).finally(function () {
        _this3.searchLoading = false;
      });
    },
    // 弹窗确认
    dialogConfirm: function dialogConfirm(userList) {
      var dialogConfig = this.dialogConfig;

      if (dialogConfig.type == 1) {
        this.userList = JSON.parse(JSON.stringify(userList));

        if (this.userList.some(function (item) {
          return item.checked;
        })) {
          for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].checked) {
              this.from.no = this.userList[i].tel;
              this.searchClick();
              this.dialogConfig = {
                visible: false
              };
              this.send('open');
              break;
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: '请先选择一个客户'
          });
        }
      }
    },
    // 关闭弹窗
    dialogCancel: function dialogCancel() {
      if (this.dialogConfig.type == 1) {
        if (this.firstIn) {
          this.send('open');
        } else {
          this.send('close');
        }
      } else if (this.dialogConfig.type == 2) {
        if (this.firstIn) {
          this.send('open');
        }
      } else if (this.dialogConfig.type == 3) {
        this.send('close');
      }

      this.dialogConfig = {
        visible: false
      };
      this.printdialog = false;
      this.searchParams = {};
    },
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      var _this4 = this;

      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };
      this.from.type = e.event.type == 1 ? 2 : 1;
      this.type = e.event.type == 1 ? 2 : 1; // 获取 参数配置项

      adminParameter().then(function (res) {
        var list = res.data.list; // 订单 头 前缀

        _this4.code_top = list.order_prefix;
        _this4.adminList = list;

        if (list.cate_room == 1 && list.cate_car == 1) {
          _this4.is_radio = true;
        } else {
          // 房源等1
          if (list.cate_room == 1) {
            _this4.from.room_type = 1;
          } else if (list.cate_car == 1) {
            _this4.from.room_type = 2;
          }

          _this4.is_radio = false;
        }

        if (_this4.roles.includes('pay/list') || _this4.roles.includes('admin')) {
          _this4.getTableList();
        }
      });
    },
    sortChange: function sortChange(column) {
      this.sortRoom = []; // 房号排序

      if (column.prop === 'room') {
        if (column.order === 'descending') {
          this.sortRoom.push('order_room.room desc');
        } else if (column.order === 'ascending') {
          this.sortRoom.push('order_room.room asc');
        }
      } // 下单时间排序
      else if (column.prop === 'pay_time') {
        if (column.order === 'descending') {
          this.sortRoom.push('pay.pay_time desc');
        } else if (column.order === 'ascending') {
          this.sortRoom.push('pay.pay_time asc');
        }
      } // 金额排序
      else if (column.prop === 'price') {
        if (column.order === 'descending') {
          this.sortRoom.push('pay.price desc');
        } else if (column.order === 'ascending') {
          this.sortRoom.push('pay.price asc');
        }
      }

      this.getTableList();
    },
    handleExport: function handleExport() {
      var _this5 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        sort: this.sortRoom.length > 0 ? this.sortRoom.join(',') : 'pay.pay_time desc'
      }, this.from);

      Api.getPayExport(params).then(function (res) {
        if (res.status === 200) {
          _this5.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.msg


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      }).catch(function (err) {
        loading.close();
      });
    },
    // 计算 订单号
    getNumber: function getNumber(num) {
      var new_num = null;

      if (num > 9 && num < 100) {
        //  俩位数
        new_num = '00' + num;
      } else if (num > 0 && num < 10) {
        // 三位数
        new_num = '000' + num;
      } else if (num > 99 && num < 1000) {
        // 一位数
        new_num = '0' + num;
      } else {
        new_num = num;
      }

      return new_num;
    },
    // 切换 住宅别墅
    handleCate: function handleCate(e) {
      this.page = 1;
      this.from = {
        room_type: e
      };
      this.getTableList();
      this.getConfigure();
    },
    // 获取楼栋单元户型
    getConfigure: function getConfigure() {
      var _this6 = this;

      // 获取楼栋信息
      projectConfigure().then(function (res) {
        var list = res.data.list;
        _this6.activity = list;
      }); //  获取别墅区域

      villaAreaList().then(function (res) {
        var list = res.data.list;
        _this6.areaList = list;
      });
    },
    // 获取单元列表
    getUnit: function getUnit(value) {
      if (this.activity.build !== null) {
        var index = this.activity.build.findIndex(function (d) {
          return d.id === value;
        });
        this.unitArray = this.activity.build[index].unit;
      }
    },
    hanmarkdleRefund: function hanmarkdleRefund(row, postId) {
      //标记退款
      this.handleRefund(row, postId, 'mark');
    },
    // 退款接口
    handleRefund: function handleRefund(row, postId, ismark) {
      var _this7 = this;

      // sign_status:签约状态0待签约;1签约中;2签约完毕
      //this.admin.sign_up_type:1线上签约;2线下签约
      var sign_status = row.sign_status;
      var text = '';

      if (row.matching_id > 0) {
        text = '由于配比关系，会将此配比单中的所有定金同时退款，是否确定退款？';
      } else if (Number(row.deduction) > 0) {
        text = '诚意金和定金将一同返还，是否确认？';
      } else {
        text = '是否确认退款?';
      }

      var text2 = '';

      if (this.adminList.sign_up_type) {
        if (this.adminList.sign_up_type == 2) {
          //线下签约
          if (sign_status == 2) {
            text2 = '订单已签约，是否继续退款?';
          } else {
            text2 = '订单在签约中，是否继续退款?';
          }
        } else {
          if (sign_status == 2) {
            text2 = '订单已签约，是否继续退款?';
          } else {
            text2 = '订单在签约中，是否继续退款?';
          }
        }
      } // if(ismark){ //点击标记退款时
      //     text=text.indexOf('退')>-1 ?  text.slice(0,text.indexOf('退'))+'(标记)'+text.slice(text.indexOf('退')):text
      //     text2 = text2.indexOf('退')>-1 ?  text2.slice(0,text2.indexOf('退'))+'(标记)'+text2.slice(text2.indexOf('退')):text2
      // }


      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(function () {
        if (!_this7.adminList.sign_up_type || !sign_status) {
          //未开启签约 或者 未发起签约
          _this7.postDepositRefund(postId, ismark);
        } else if (_this7.adminList.sign_up_type && sign_status) {
          //打开签约模式二次提示
          _this7.$confirm(text2, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true,
            type: 'warning'
          }).then(function () {
            _this7.postDepositRefund(postId, ismark);
          }).catch(function () {
            _this7.$message({
              type: 'info',
              message: '已取消退款'
            });
          });
        } else {
          _this7.postDepositRefund(postId, ismark);
        }
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消退款'
        });
      });
    },
    postDepositRefund: function postDepositRefund(postId, ismark) {
      var _this8 = this;

      var apiStr = !ismark ? 'depositRefund' : 'depositSignRefund';
      Api[apiStr]({
        id: postId
      }).then(function (res) {
        if (res.status === 200) {
          _this8.$message({
            type: 'success',
            message: res.data.msg
          });

          _this8.getTableList();
        }
      });
    },
    // 获取定金记录
    getTableList: function getTableList() {
      var _this9 = this;

      var params = _objectSpread(_objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page,
        limit: this.pageSize
      }, this.from), {}, {
        sort: this.sortRoom.length > 0 ? this.sortRoom.join(',') : 'pay.pay_time desc'
      });

      this.tableLoading = true;
      Api.depositList(params).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              list = _res$data2.list,
              count = _res$data2.count,
              event_start = _res$data2.event_start;
          _this9.tableData = list || [];
          _this9.total = count;

          if (event_start != null && event_start && event_start.length > 0) {
            _this9.event_start = event_start;
          } else {
            _this9.event_start = [];
          }
        }
      }).finally(function () {
        _this9.tableLoading = false;
      });
    },
    // 点击跳转 电子订单
    handleOrder: function handleOrder(row) {
      var _this10 = this;

      var l = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        order_id: row.order_id,
        pay_order_id: row.id
      };
      Api.signDetails(params).then(function (res) {
        var _res$data$data = res.data.data,
            log_list = _res$data$data.log_list,
            count = _res$data$data.count; // 多条记录

        if (count > 1) {
          _this10.log_list = log_list || [];
          _this10.dialogVisible = true;
        } else {
          _this10.openPage(log_list[0]);
        }
      }).finally(function () {
        l.close();
      });
    },
    // 打开页面
    openPage: function openPage(e) {
      var log = e.log,
          type = e.type,
          value = e.value,
          word_url = e.word_url;
      var url = type === 1 ? value.result.pageUrl : "https://view.officeapps.live.com/op/view.aspx?src=".concat(this.host, "/").concat(word_url);

      if (!url) {
        this.$message.error('文档地址异常');
        return;
      }

      window.open(url);
    },
    // 查询 重置
    searchClick: function searchClick() {
      this.page = 1;
      this.getTableList();
    },
    // 重置
    resetClick: function resetClick() {
      this.page = 1;
      this.from = {
        type: this.type,
        room_type: 1,
        handled: '1',
        order_status: 0 //订单状态默认查询全部

      };
      this.unitArray = [];
      this.getTableList();
    },
    // 表格 房源详情  用户详情
    toRoomDetail: function toRoomDetail(row) {
      var id = row.room_id;

      if (row.cate == 2) {
        this.$router.push({
          path: '/roomSite/buildingInfo',
          query: {
            id: id,
            round: this.event.is_round
          }
        });
      } else {
        this.$router.push({
          path: '/roomSite/houseInfo',
          query: {
            id: id,
            round: this.event.is_round
          }
        });
      }
    },
    toUserDeatil: function toUserDeatil(id) {
      this.$router.push({
        path: '/userSite/userDetails',
        query: {
          id: id,
          round: this.event.is_round
        }
      });
    },
    // 展开更多
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 切换每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 切换页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    }
  }
};