var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["project-bottom", { "no-tip": !_vm.isShowTip }] },
    [
      _c(
        "div",
        {
          staticClass: "btn-box",
          style: { "--flex": _vm.flexPos, "--margin": _vm.sMargin },
        },
        [_vm._t("default")],
        2
      ),
      _vm.isShowTip
        ? _c(
            "div",
            { staticClass: "bottom-tip-wrapper" },
            _vm._l(_vm.showTextList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "tip" },
                [
                  _c("notice-nav", {
                    attrs: {
                      title: item.title,
                      text: item.content,
                      outview: true,
                      "bg-color": "rgba(0, 0, 0, 0.2)",
                      duration:
                        100 / _vm.editFormatList.host_json.notice_duration,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      false
        ? _c(
            "div",
            { staticClass: "bottom-tip-wrapper" },
            _vm._l(_vm.showTextList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  ref: "tip-" + index,
                  refInFor: true,
                  staticClass: "tip",
                },
                [
                  _c("div", { staticClass: "tip-content" }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                  _c("div", { staticClass: "tip-swiper-content" }, [
                    _c(
                      "div",
                      {
                        class: ["inner-text", { "tip-text": _vm.isMove }],
                        staticStyle: { "margin-left": "-320px" },
                        style: {
                          "--trans": _vm.trans,
                          "text-align": _vm.isMove ? "" : "center",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            ref: "tip-swiper-content-" + index,
                            refInFor: true,
                          },
                          [_vm._v(_vm._s(item.content))]
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }