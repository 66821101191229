import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Api from '../../api/activityData';
import { adminParameter, authGetEvent } from '@/api/public';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
import utils from '@/utils/utils';
export default {
  name: 'UserTable',
  components: {
    eventSelect: eventSelect
  },
  data: function data() {
    return {
      // 是否展示存档
      data_file: false,
      // 存档状态
      archive: [{
        title: '全部',
        id: 0
      }, {
        title: '已存档',
        id: 1
      }, {
        title: '未存档',
        id: 2
      }],
      controlList: [{
        name: '全部',
        id: 0
      }, {
        name: '销控',
        id: 1
      }, {
        name: '无',
        id: 2
      }],
      // 预留状态
      reserv_type: '',
      reservList: [{
        name: '全部',
        id: 0
      }, {
        name: '预留',
        id: 1
      }, {
        name: '解除预留',
        id: 2
      }, {
        name: '无',
        id: 3
      }],
      sell: [{
        label: '全部',
        value: 0
      }, {
        label: '在售',
        value: 1
      }, {
        label: '非售',
        value: 2
      }],
      // 列表总数
      total: 0,
      // 第一个页
      page: 1,
      // 分页 一页几条
      pageSize: 10,
      // 切换区域
      tab: 0,
      // 楼栋数组 单元 户型数据
      activity: [],
      // 楼层
      unitList: [],
      // 区域
      floorList: [],
      //  别墅 区域 数组
      areaList: [],
      // 搜索数据
      search: {
        cate: 1,
        build_id: '',
        unit_id: '',
        cate_id: '',
        floor_id: 0,
        no: '',
        room_printing: 0,
        order_status: 0,
        room_type: 0,
        sign_status: 0,
        control_type: 0,
        is_archive: 0,
        reserv_type: 0,
        pay_status: 0
      },
      // 打印认购书
      printing: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '未打印'
      }, {
        value: 2,
        label: '已打印'
      }],
      // 单元数组
      unitArray: '',
      // 签约 状态数组
      // 签约 线上
      onLine: [{
        value: 0,
        label: '全部'
      }, {
        value: 2,
        label: '未签约'
      }, {
        value: 1,
        label: '已签约'
      }],
      // 线上交定金
      onLineDeposit: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '已交'
      }, {
        value: 2,
        label: '已交部分'
      }, {
        value: 3,
        label: '未交'
      }],
      room_type: [{
        name: '全部',
        id: 0
      }, {
        name: '销控房源',
        id: 1
      }, {
        name: '预留房源',
        id: 2
      }, {
        name: '不看销控',
        id: 3
      }, {
        name: '不看预留',
        id: 4
      }],
      // 选房 状态数组
      order: [{
        name: '全部',
        id: 0
      }, {
        name: '真实认购',
        id: 1
      }, {
        name: '未认购',
        id: 2
      }, {
        name: '未认购仅变红',
        id: 3
      }],
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      // 是否展开
      is_more: false,
      // 是否展开text
      moreText: '展开',
      // 是否展开 icon
      moreIcon: 'el-icon-arrow-down',
      tableData: [],
      // 表格loading
      tableLoading: false,
      adminList: {},
      sign_up: true,
      is_price: true,
      is_radio: false,
      select_event: {},
      // 选择的活动和场次id集
      eventList: [] // 项目活动列表

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 138;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.top.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    this.getConfigure();
    this.projectSetData();
    this.search.cate = this.customSetting.cate_room === 0 ? 2 : 1;
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };

      if (this.roles.includes('roomstatus/index') || this.roles.includes('admin')) {
        this.getTableInfo();
      }
    },
    // 跳转详情
    handleInfo: function handleInfo(row) {
      // 住宅
      if (this.search.cate === 1) {
        this.$router.push({
          name: 'HouseInfo',
          query: {
            id: row.id,
            round: this.event.is_round
          }
        });
      } else {
        // 别墅
        this.$router.push({
          name: 'BuildingInfo',
          query: {
            id: row.id,
            round: this.event.is_round
          }
        });
      }
    },
    // 导出房源表
    handleExport: function handleExport() {
      var _this2 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page,
        limit: this.pageSize
      }, this.search);

      params.build_id = params.build_id === '' ? 0 : params.build_id;
      params.unit_id = params.unit_id === '' ? 0 : params.unit_id;
      params.reserv_type = params.reserv_type === '' ? 0 : params.reserv_type; // 导出接口

      Api.roomExport(params).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.url


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    // 重置 搜索框
    handleReset: function handleReset() {
      this.page = 1;
      this.search = {
        cate: 1,
        build_id: '',
        unit_id: '',
        cate_id: '',
        no: '',
        order_status: 0,
        room_type: 0,
        reserv_type: 0,
        control_type: 0,
        is_archive: 0,
        room_printing: 0,
        pay_status: 0,
        sign_status: 0
      };
      this.unitArray = [];
      this.getTableInfo();
    },
    // 切换 别墅住宅
    handleCate: function handleCate(e) {
      this.page = 1;
      this.search = {
        cate: e,
        build_id: 0,
        unit_id: 0,
        cate_id: '',
        floor_id: 0,
        no: '',
        order_status: 0,
        room_type: 0,
        reserv_type: '',
        control_type: 0,
        is_archive: 0
      };
      this.getTableInfo();
      this.getConfigure();
    },
    // 获取单元列表
    getUnit: function getUnit(value) {
      if (value === 0) {
        this.unitArray = [];
        return;
      }

      if (this.activity.build !== null) {
        var index = this.activity.build.findIndex(function (d) {
          return d.id === value;
        });
        this.unitArray = this.activity.build[index].units;
      }
    },
    getCarUnit: function getCarUnit(e, item) {
      var _this3 = this;

      this.search.unit_id = 0;
      this.search.floor_id = 0;
      this.unitList = [];
      this.floorList = [];
      item && item.forEach(function (element) {
        if (e == element.id) {
          _this3.unitList = element.unit;
        }
      });
    },
    getCarFloor: function getCarFloor(e, item) {
      var _this4 = this;

      this.search.floor_id = 0;
      this.floorList = [];
      item && item.forEach(function (element) {
        if (e == element.id) {
          _this4.floorList = element.floor;
        }
      });
    },
    // 获取 参数权限
    projectSetData: function projectSetData() {
      var _this5 = this;

      adminParameter().then(function (res) {
        var _res$data2 = res.data,
            event = _res$data2.event,
            list = _res$data2.list; // 活动场次批次控制

        _this5.event = event;
        _this5.adminList = list;

        if (list.cate_room == 1 && list.cate_car == 1) {
          _this5.is_radio = true;
        } else {
          // 房源等1
          if (list.cate_room == 1) {
            _this5.search.room_type = 1;
          } else if (list.cate_car == 1) {
            _this5.search.room_type = 2;
          }

          _this5.is_radio = false;
        } // 是否显示签约 相关


        _this5.sign_up = list.is_display.includes('sign_up'); // 是否显示定金 相关

        _this5.is_price = list.is_display.includes('price'); // 是否打开存档

        _this5.data_file = list.is_display.includes('data_file');
      });
    },
    // 获取楼栋单元户型
    getConfigure: function getConfigure() {
      var _this6 = this;

      Api.configure().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this6.activity = list;
        }
      }); //  获取别墅区域

      Api.villaArea().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this6.areaList = list;
        }
      });
    },
    // 列表 搜索
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getTableInfo();
    },
    //  获取列表数据
    getTableInfo: function getTableInfo() {
      var _this7 = this;

      var params = _objectSpread(_objectSpread({}, this.select_event), {}, {
        page: this.page,
        limit: this.pageSize
      }, this.search);

      params.floor_id = 0;
      params.build_id = params.build_id === '' ? 0 : params.build_id;
      params.unit_id = params.unit_id === '' ? 0 : params.unit_id;
      params.reserv_type = params.reserv_type === '' ? 0 : params.reserv_type;
      this.tableLoading = true;
      Api.roomIndex(params).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
              list = _res$data3.list,
              count = _res$data3.count;
          _this7.tableData = list || [];
          _this7.total = count;
        }
      }).finally(function () {
        _this7.tableLoading = false;
      });
    },
    // 切换 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableInfo();
    },
    // 切换页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableInfo();
    },
    // 展开& 收起
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    }
  }
};