var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-box",
      staticStyle: { height: "100vh", "overflow-y": "hidden", display: "flex" },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            height: "100vh",
            overflow: "auto",
            position: "relative",
          },
          style: { width: !_vm.isHide ? "calc(100vw - 235px)" : "100vw" },
        },
        [
          _vm.ledInfo.is_map !== 1
            ? _c("div", { staticClass: "set-position-tip" }, [
                _vm._v("双击列表卡片,自定义单元格大小,户数。"),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              ref: "wrapper",
              staticClass: "wrapper",
              style: {
                "background-color": _vm.allColor.left.background_color,
                height: "100vh",
                overflow: "auto",
                position: "relative",
              },
            },
            [
              _vm.ledInfo.is_map !== 1
                ? _c(
                    "div",
                    _vm._l(_vm.houseRes, function (houseItems, i) {
                      return _c("screen-item", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.carousels.length && _vm.ledInfo.is_banner == 1
                                ? _vm.carousels[
                                    _vm.theCarousel
                                  ].carousels.indexOf(houseItems.id) >= 0
                                : true,
                            expression:
                              "carousels.length && ledInfo.is_banner == 1 ? carousels[theCarousel].carousels.indexOf(houseItems.id) >= 0 : true",
                          },
                        ],
                        key: i,
                        ref: "item_" + houseItems.id,
                        refInFor: true,
                        staticClass: "houseItems",
                        style: _vm.doGetScreenItemStyle(houseItems),
                        attrs: {
                          "card-type": _vm.ledConfInfo.show_type,
                          "font-color": _vm.ledConfInfo.first_size,
                          "header-color": _vm.ledConfInfo.first_back,
                          "is-drag": true,
                          "item-detail": houseItems,
                          "led-conf-info": _vm.ledConfInfo,
                          index: i,
                          houseResLength: _vm.houseRes.length,
                          "project-room-info": _vm.projectRoomInfo,
                          allColor: _vm.allColor.left,
                          allSize: _vm.allSize[houseItems.id + ""] || {},
                        },
                        on: {
                          doGetCellWidth: _vm.doGetCellWidth,
                          ondblClickDialog: _vm.ondblClickDialog,
                          onSelectIds: _vm.onSelectIds,
                        },
                      })
                    }),
                    1
                  )
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "calc(100vw - 256px)",
                          height: "calc(100vh - 20px)",
                          padding: "0 20px 20px",
                          margin: "10px",
                          "border-radius": "20px",
                        },
                        style: {
                          backgroundColor:
                            _vm.allColor.left.carPlan.border_color,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "40px",
                              padding: "0 20px",
                              "line-height": "40px",
                              "text-align": "center",
                              "font-size": "25px",
                            },
                            style: {
                              color: _vm.allColor.left.carPlan.title_color,
                            },
                          },
                          [_vm._v(" 标题 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "calc(100vh - 80px)",
                              "text-align": "center",
                              "font-size": "40px",
                              "font-weight": "bold",
                              "line-height": "calc(100vh - 80px)",
                            },
                            style: {
                              backgroundColor:
                                _vm.allColor.left.carPlan.background_color,
                            },
                          },
                          [_vm._v(" 内是平面图 ")]
                        ),
                      ]
                    ),
                  ]),
            ]
          ),
        ]
      ),
      !_vm.isHide
        ? _c(
            "div",
            {
              staticStyle: {
                width: "226px",
                height: "100vh",
                "background-color": "black",
                "margin-left": "9px",
              },
            },
            [
              _c("div", { staticClass: "save-position" }, [
                _vm.ledInfo.show_cate == 1 ||
                _vm.ledInfo.show_count == 1 ||
                _vm.ledInfo.show_online == 1 ||
                _vm.ledInfo.show_event_time == 1 ||
                _vm.ledInfo.show_sale == 1 ||
                _vm.ledInfo.show_amount == 1 ||
                _vm.ledInfo.title == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "right-box",
                        style: {
                          backgroundColor: _vm.allColor.right.background_color,
                        },
                      },
                      [
                        _vm.ledInfo.is_map !== 1
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "line-height": "25px",
                                },
                              },
                              [
                                _vm.boxSelectData.show
                                  ? _c("span", [_vm._v("点击屏幕取消框选！")])
                                  : _c("span", [
                                      _vm._v("建议在全屏模式下"),
                                      _c("br"),
                                      _vm._v("调整尺寸&对齐！"),
                                    ]),
                              ]
                            )
                          : _c(
                              "p",
                              {
                                staticStyle: {
                                  padding: "0 15px",
                                  "text-align": "center",
                                  "line-height": "35px",
                                },
                              },
                              [_vm._v("平面图模式无法配置位置，只能配置颜色")]
                            ),
                        _c(
                          "div",
                          {
                            staticClass: "bottom-logo",
                            style: {
                              backgroundColor:
                                _vm.allColor.right.bottom.background_color,
                            },
                          },
                          [
                            _vm._m(0),
                            _c("div", { staticClass: "point" }),
                            _c(
                              "div",
                              {
                                staticClass: "logo-text",
                                style: {
                                  color: _vm.allColor.right.bottom.color,
                                },
                              },
                              [_vm._v("数字化开盘专家")]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "0", top: "0" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: !_vm.isHide ? "隐藏右侧区域！" : "显示右侧区域！",
                placement: "bottom",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-view",
                staticStyle: {
                  color: "white",
                  position: "absolute",
                  top: "20px",
                  right: "190px",
                  "z-index": "100",
                  "font-size": "20px",
                },
                on: { click: _vm.on_is_show_right },
              }),
            ]
          ),
          _c("div", { staticClass: "save-position" }, [
            _c("span", { staticClass: "my-screen" }, [_c("my-screen")], 1),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                top: "50px",
                right: "0",
                width: "226px",
                "z-index": "100",
              },
            },
            [
              _vm.ledInfo.is_banner == 1 && _vm.ledInfo.is_map !== 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "led/setcoordinate"],
                              expression: "['admin', 'led/setcoordinate']",
                            },
                          ],
                          staticClass: "btnHollowGreen right-button-green",
                          staticStyle: { width: "calc(100% - 25px)" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onCarousel("carousel")
                            },
                          },
                        },
                        [_vm._v("轮播屏幕配置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ledInfo.is_map !== 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        display: "flex",
                        "justify-content": "space-evenly",
                      },
                    },
                    [
                      _vm.houseRes.length > 0
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "led/setcoordinate"],
                                  expression: "['admin', 'led/setcoordinate']",
                                },
                              ],
                              staticClass: "btnHollowGreen right-button-green",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.onAlignClick("alignOper")
                                },
                              },
                            },
                            [_vm._v("快速对齐 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "鼠标按住移动进行框选！",
                            placement: "top",
                          },
                        },
                        [
                          _vm.houseRes.length > 0
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "led/setcoordinate"],
                                      expression:
                                        "['admin', 'led/setcoordinate']",
                                    },
                                  ],
                                  staticClass:
                                    "btnHollowGreen right-button-green",
                                  attrs: { size: "small" },
                                  on: { click: _vm.onBoxSelect },
                                },
                                [_vm._v("框选选中 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    display: "flex",
                    "justify-content": "space-evenly",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "led/setcoordinate"],
                          expression: "['admin', 'led/setcoordinate']",
                        },
                      ],
                      staticClass: "orange-btn",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.ondblClickDialog("left", "左侧展示配置")
                        },
                      },
                    },
                    [_vm._v("左侧颜色 ")]
                  ),
                  _vm.ledInfo.is_map !== 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "led/setcoordinate"],
                              expression: "['admin', 'led/setcoordinate']",
                            },
                          ],
                          staticClass: "orange-btn",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.savePos("position")
                            },
                          },
                        },
                        [_vm._v("保存位置 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.ledInfo.is_map !== 1
        ? _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                placement: "top",
                content:
                  "选中部件移动位置，输入移动距离。点击按钮即可移动位置，也可使用键盘移动位置",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "0",
                    bottom: "50px",
                    width: "226px",
                    "text-align": "center",
                    "z-index": "100",
                  },
                },
                [
                  _vm.houseRes.length > 0
                    ? _c("el-button", {
                        staticClass: "btnHollowGreen right-button-green",
                        attrs: { size: "small", icon: "el-icon-top" },
                        on: {
                          click: function ($event) {
                            return _vm.onKeydown("top")
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _vm.houseRes.length > 0
                        ? _c("el-button", {
                            staticClass: "btnHollowGreen right-button-green",
                            attrs: { size: "small", icon: "el-icon-back" },
                            on: {
                              click: function ($event) {
                                return _vm.onKeydown("left")
                              },
                            },
                          })
                        : _vm._e(),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.keydownSize,
                            expression: "keydownSize",
                          },
                        ],
                        staticStyle: {
                          width: "45px",
                          "text-align": "center",
                          padding: "0",
                          height: "32px",
                        },
                        attrs: { type: "number" },
                        domProps: { value: _vm.keydownSize },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.keydownSize = $event.target.value
                          },
                        },
                      }),
                      _vm.houseRes.length > 0
                        ? _c("el-button", {
                            staticClass: "btnHollowGreen right-button-green",
                            attrs: { size: "small", icon: "el-icon-right" },
                            on: {
                              click: function ($event) {
                                return _vm.onKeydown("right")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.houseRes.length > 0
                    ? _c("el-button", {
                        staticClass: "btnHollowGreen right-button-green",
                        attrs: { size: "small", icon: "el-icon-bottom" },
                        on: {
                          click: function ($event) {
                            return _vm.onKeydown("bottom")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogData.show,
            width: _vm.dialogData.key === "alignOper" ? "1200px" : "700px",
            title: _vm.dialogData.title,
            top: "0",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogData, "show", $event)
            },
          },
        },
        [
          _vm.dialogData.key === "alignOper"
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: { "margin-top": "-30px" },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("请选择需要调整宽高\\位置的部件："),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "calc(100vh - 360px)",
                                    "overflow-y": "auto",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "5px",
                                        "background-color":
                                          "rgb(250, 250, 250)",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v(" 部件 ")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                              attrs: { span: 9 },
                                            },
                                            [
                                              _vm._v(" 选中 "),
                                              _c(
                                                "el-checkbox",
                                                {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onAlignClick(
                                                        "allCheck"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.dialogData
                                                        .houseResIsAllCheck,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dialogData,
                                                        "houseResIsAllCheck",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "dialogData.houseResIsAllCheck",
                                                  },
                                                },
                                                [_vm._v("全选 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                              attrs: { span: 7 },
                                            },
                                            [_vm._v(" 顺序 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.dialogData.houseRes,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "hover-list",
                                          staticStyle: {
                                            "border-bottom":
                                              "1px solid rgb(235, 238, 245)",
                                            padding: "0 20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 9 },
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "20px",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.onAlignClick(
                                                            "checkbox"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.isCheck,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isCheck",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isCheck",
                                                      },
                                                    },
                                                    [_vm._v("选中 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { span: 7 },
                                                },
                                                [
                                                  index !== 0
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content: "向上换位",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-top",
                                                            staticStyle: {
                                                              "margin-right":
                                                                "15px",
                                                              color: "#22a271",
                                                              "font-size":
                                                                "18px",
                                                              "margin-top":
                                                                "20px",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onAlignClick(
                                                                  "top",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c("span", {
                                                        staticStyle: {
                                                          padding: "15px",
                                                        },
                                                      }),
                                                  index !==
                                                  _vm.dialogData.houseRes
                                                    .length -
                                                    1
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content: "向下换位",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-bottom",
                                                            staticStyle: {
                                                              "margin-right":
                                                                "15px",
                                                              color: "#22a271",
                                                              "font-size":
                                                                "18px",
                                                              "margin-top":
                                                                "20px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onAlignClick(
                                                                  "bottom",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              staticStyle: { "margin-top": "10px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "120px",
                                    "margin-top": "10px",
                                    "line-height": "20px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "font-size": "18px",
                                        margin: "5px 0",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "已选中部件：" +
                                          _vm._s(_vm.dialogData.checkLength) +
                                          "个"
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.dialogData.houseRes,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        _vm._v(
                                          _vm._s(
                                            item.isCheck ? item.name + "，" : ""
                                          )
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 14 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "10px",
                              "margin-top": "-30px",
                              "margin-left": "10px",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("el-form", [
                                  _c(
                                    "div",
                                    { staticStyle: { height: "30px" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "83px" } },
                                        [
                                          _c("template", { slot: "label" }, [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "red",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [_vm._v("基准部件：")]
                                            ),
                                          ]),
                                          _c(
                                            "el-select",
                                            {
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onAlignClick(
                                                    "houseResIndex"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.dialogData.houseResIndex,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dialogData,
                                                    "houseResIndex",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dialogData.houseResIndex",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                { attrs: { value: "" } },
                                                [_vm._v("请选择")]
                                              ),
                                              _vm._l(
                                                _vm.dialogData.houseRes,
                                                function (item, index) {
                                                  return item.isCheck
                                                    ? _c(
                                                        "el-option",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            value: index,
                                                            label: item.name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      model: _vm.dialogData,
                                      "label-width": "60px",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "工具1和工具2将清除每列手动设置的宽度，工具3则不会清除！"
                                      ),
                                    ]),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: {
                                          "margin-left": "-10px",
                                          "margin-top": "-5px",
                                        },
                                        attrs: {
                                          header: "工具1：屏幕自适应计算",
                                        },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 6 } }, [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-top": "-5px",
                                              },
                                            },
                                            [_vm._v("选中部件均衡计算：")]
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 18 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { "label-width": "0" } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "-30px",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onAlignClick(
                                                          "checkbox"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dialogData
                                                          .computeType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogData,
                                                          "computeType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogData.computeType",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "width",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 以屏幕宽度均分 " +
                                                                _vm._s(
                                                                  _vm.dialogData
                                                                    .computeType ===
                                                                    "width"
                                                                    ? "（宽度：" +
                                                                        _vm
                                                                          .dialogData[
                                                                          _vm
                                                                            .dialogData
                                                                            .computeType
                                                                        ] +
                                                                        "）"
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "height",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 以屏幕高度均分 " +
                                                                _vm._s(
                                                                  _vm.dialogData
                                                                    .computeType ===
                                                                    "height"
                                                                    ? "（高度：" +
                                                                        _vm
                                                                          .dialogData[
                                                                          _vm
                                                                            .dialogData
                                                                            .computeType
                                                                        ] +
                                                                        "）"
                                                                    : ""
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                "text-align": "right",
                                                "margin-top": "-30px",
                                                height: "50px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "btnHollowGreen",
                                                  staticStyle: {
                                                    height: "38px",
                                                    width: "150px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !_vm.dialogData[
                                                        _vm.dialogData
                                                          .computeType
                                                      ],
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAlignClick(
                                                        "use"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 使用此" +
                                                      _vm._s(
                                                        _vm.dialogData
                                                          .computeType ===
                                                          "width"
                                                          ? "宽度"
                                                          : "高度"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "btnHollowGreen",
                                                  staticStyle: {
                                                    height: "38px",
                                                    width: "150px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !_vm.dialogData[
                                                        _vm.dialogData
                                                          .computeType
                                                      ],
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAlignClick(
                                                        "putCompute"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.dialogData
                                                          .computeType ===
                                                          "width"
                                                          ? "使用宽度且顶端对齐"
                                                          : "使用高度且左对齐"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: {
                                          "margin-top": "5px",
                                          "margin-left": "-10px",
                                        },
                                        attrs: {
                                          header: "工具2：基准部件同步",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "-5px",
                                                    },
                                                  },
                                                  [_vm._v("以基准部件对齐：")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "-15px",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dialogData.isSyncXY,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogData,
                                                          "isSyncXY",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogData.isSyncXY",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "top",
                                                            },
                                                          },
                                                          [_vm._v("顶端对齐")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "left",
                                                            },
                                                          },
                                                          [_vm._v("左对齐")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                                attrs: { span: 8 },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "-20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticClass:
                                                          "btnHollowGreen",
                                                        staticStyle: {
                                                          height: "38px",
                                                          width: "150px",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            !_vm.dialogData
                                                              .isSyncXY,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onAlignClick(
                                                              "isSyncSize"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dialogData
                                                                .isSyncXY
                                                                ? _vm.dialogData
                                                                    .isSyncXY ===
                                                                  "top"
                                                                  ? "同步顶端对齐"
                                                                  : "同步左对齐"
                                                                : "同步对齐"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "以基准部件统一宽高："
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "13px",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dialogData
                                                              .isSyncSize.width,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.dialogData
                                                                .isSyncSize,
                                                              "width",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "dialogData.isSyncSize.width",
                                                        },
                                                      },
                                                      [_vm._v("同步宽度 ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "13px",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.dialogData
                                                              .isSyncSize
                                                              .height,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.dialogData
                                                                .isSyncSize,
                                                              "height",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "dialogData.isSyncSize.height",
                                                        },
                                                      },
                                                      [_vm._v("同步高度 ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                                attrs: { span: 8 },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass:
                                                      "btnHollowGreen",
                                                    staticStyle: {
                                                      height: "38px",
                                                      "margin-top": "5px",
                                                      width: "150px",
                                                    },
                                                    attrs: {
                                                      disabled: !(
                                                        _vm.dialogData
                                                          .isSyncSize.width ||
                                                        _vm.dialogData
                                                          .isSyncSize.height
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onAlignClick(
                                                          "isSyncSize"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.dialogData.isSyncSize
                                                      .width &&
                                                    _vm.dialogData.isSyncSize
                                                      .height
                                                      ? _c("span", [
                                                          _vm._v("同步宽高"),
                                                        ])
                                                      : _vm.dialogData
                                                          .isSyncSize.width
                                                      ? _c("span", [
                                                          _vm._v("同步宽度"),
                                                        ])
                                                      : _vm.dialogData
                                                          .isSyncSize.height
                                                      ? _c("span", [
                                                          _vm._v("同步高度"),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v("同步宽高"),
                                                        ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: {
                                          "margin-top": "5px",
                                          "margin-left": "-10px",
                                        },
                                        attrs: {
                                          header:
                                            "工具3：手动设置部件内单元格的宽高",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 9 } },
                                          [
                                            _c("el-form-item", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "forItem-content",
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("el-checkbox", {
                                                    model: {
                                                      value: _vm.checkedWidth,
                                                      callback: function ($$v) {
                                                        _vm.checkedWidth = $$v
                                                      },
                                                      expression:
                                                        "checkedWidth",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 5px",
                                                      },
                                                    },
                                                    [_vm._v("宽度")]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "150px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.checkedWidth,
                                                      min: 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dialogData.cell
                                                          .width,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogData.cell,
                                                          "width",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogData.cell.width",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 9 } },
                                          [
                                            _c("el-form-item", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "forItem-content",
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("el-checkbox", {
                                                    model: {
                                                      value: _vm.checkedHeight,
                                                      callback: function ($$v) {
                                                        _vm.checkedHeight = $$v
                                                      },
                                                      expression:
                                                        "checkedHeight",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        margin: "0 5px",
                                                      },
                                                    },
                                                    [_vm._v("高度")]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "150px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.checkedHeight,
                                                      min: 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.dialogData.cell
                                                          .height,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogData.cell,
                                                          "height",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogData.cell.height",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                            attrs: { span: 6 },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "btnHollowGreen",
                                                staticStyle: {
                                                  width: "150px",
                                                  height: "38px",
                                                },
                                                attrs: {
                                                  disabled:
                                                    !(
                                                      _vm.dialogData
                                                        .houseResIndex ||
                                                      _vm.dialogData
                                                        .houseResIndex === 0
                                                    ) ||
                                                    (!_vm.checkedHeight &&
                                                      !_vm.checkedWidth),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAlignClick(
                                                      "cellSize"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("保存宽高 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "quickAlign addbc-btn",
                                        attrs: { type: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.savePos("position")
                                          },
                                        },
                                      },
                                      [_vm._v("保存关闭")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.dialogData.key === "carousel"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 3.5 } },
                                [
                                  _vm._t("label", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "el-form-item__label",
                                        staticStyle: { color: "#22a271" },
                                      },
                                      [_vm._v("当前屏幕")]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.dialogData.theCarousel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dialogData,
                                            "theCarousel",
                                            $$v
                                          )
                                        },
                                        expression: "dialogData.theCarousel",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dialogData.carousels,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label:
                                              "第" + (index + 1) + "块屏幕",
                                            value: index,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.dialogData.carousels, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            attrs: { label: "第" + (index + 1) + "块屏幕" },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 22 } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onCarousel(
                                              "change",
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.carousels,
                                          callback: function ($$v) {
                                            _vm.$set(item, "carousels", $$v)
                                          },
                                          expression: "item.carousels",
                                        },
                                      },
                                      _vm._l(
                                        _vm.houseRes,
                                        function (itemDetail) {
                                          return _c("el-option", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.allSelectedCarousels.includes(
                                                    itemDetail.id
                                                  ) ||
                                                  item.carousels.includes(
                                                    itemDetail.id
                                                  ),
                                                expression:
                                                  "!allSelectedCarousels.includes(itemDetail.id) || item.carousels.includes(itemDetail.id)",
                                              },
                                            ],
                                            key: itemDetail.id,
                                            attrs: {
                                              label:
                                                itemDetail.build_name +
                                                " - " +
                                                itemDetail.unit_room,
                                              value: itemDetail.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("el-col", { attrs: { span: 2 } }, [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus-outline",
                                    staticStyle: {
                                      "margin-left": "10px",
                                      "font-size": "23px",
                                      color: "#22a271",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCarousel("add", index)
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btnHollowGreen",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.onCarousel("submit")
                                },
                              },
                            },
                            [_vm._v(" 确定修改 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "calc(100vh - 170px)",
                      "overflow-y": "auto",
                    },
                  },
                  [
                    _vm.dialogData.key === "right"
                      ? _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.dialogData.allColor.right,
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "title.color",
                                  label: "右侧背景颜色",
                                },
                              },
                              [
                                _c("el-color-picker", {
                                  attrs: { "show-alpha": "" },
                                  model: {
                                    value:
                                      _vm.dialogData.allColor.right
                                        .background_color,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogData.allColor.right,
                                        "background_color",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dialogData.allColor.right.background_color",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.ledInfo.title == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("项目名称")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "title.color",
                                            label: "字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .title.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .title,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.title.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ledInfo.show_now_time == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("当前时间")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_now_time.title_color",
                                            label: "标题颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_now_time.title_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_now_time,
                                                  "title_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_now_time.title_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_now_time.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_now_time
                                                  .background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_now_time,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_now_time.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_now_time.time_color",
                                            label: "时间字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_now_time.time_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_now_time,
                                                  "time_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_now_time.time_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_now_time.time_background_color",
                                            label: "时间背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_now_time
                                                  .time_background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_now_time,
                                                  "time_background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_now_time.time_background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ledInfo.show_event_time == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("项目倒计时")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_event_time.title_color",
                                            label: "标题颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_event_time.title_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_event_time,
                                                  "title_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_event_time.title_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_event_time.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_event_time
                                                  .background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_event_time,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_event_time.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_event_time.time_color",
                                            label: "时间字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_event_time.time_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_event_time,
                                                  "time_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_event_time.time_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_event_time.time_background_color",
                                            label: "时间背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_event_time
                                                  .time_background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_event_time,
                                                  "time_background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_event_time.time_background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ledInfo.show_amount == 1 &&
                            _vm.ledInfo.show_cate == 0
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("销售额")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_amount.title_color",
                                            label: "标题颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_amount.title_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_amount,
                                                  "title_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_amount.title_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_amount.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_amount.background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_amount,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_amount.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_amount.time_color",
                                            label: "字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_amount.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_amount,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_amount.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ledInfo.show_batch_time == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("批次倒计时")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_batch_time.title_color",
                                            label: "标题颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_batch_time.title_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_batch_time,
                                                  "title_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_batch_time.title_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_batch_time.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_batch_time
                                                  .background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_batch_time,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_batch_time.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_batch_time.time_color",
                                            label: "时间字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_batch_time.time_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_batch_time,
                                                  "time_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_batch_time.time_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_batch_time.time_background_color",
                                            label: "时间背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_batch_time
                                                  .time_background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_batch_time,
                                                  "time_background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_batch_time.time_background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            (_vm.ledInfo.show_online == 1 ||
                              _vm.ledInfo.show_sale == 1) &&
                            _vm.ledInfo.show_cate != 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("可选房源")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_online.title_color",
                                            label: "字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_online.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_online,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_online.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_online.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_online.background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_online,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_online.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.ledInfo.show_count == 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("选房记录")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_count.title_color",
                                            label: "字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_count.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_count,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_count.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_count.background_color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_count.background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_count,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_count.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "show_count.user_background_color",
                                            label: "用户背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.right
                                                  .show_count
                                                  .user_background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.right
                                                    .show_count,
                                                  "user_background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.right.show_count.user_background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "allColor_right_name" },
                                [_vm._v("底部云销控")]
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "title.color",
                                        label: "背景颜色",
                                      },
                                    },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.right.bottom
                                              .background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.right
                                                .bottom,
                                              "background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.right.bottom.background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "title.color",
                                        label: "字体颜色",
                                      },
                                    },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.right.bottom
                                              .color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.right
                                                .bottom,
                                              "color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.right.bottom.color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.dialogData.key === "left"
                      ? _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.dialogData.allColor.right,
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "allColor_right_item" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "title.color",
                                      label: "左侧背景颜色",
                                    },
                                  },
                                  [
                                    _c("el-color-picker", {
                                      attrs: { "show-alpha": "" },
                                      model: {
                                        value:
                                          _vm.dialogData.allColor.left
                                            .background_color,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dialogData.allColor.left,
                                            "background_color",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dialogData.allColor.left.background_color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            this.ledInfo.is_map !== 1
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "-20px" } },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "部件：" } },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.dialogData
                                                            .isAllCover,
                                                        placeholder: "请选择",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dialogData
                                                            .leftShowId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dialogData,
                                                            "leftShowId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dialogData.leftShowId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.dialogData.size,
                                                      function (item, index) {
                                                        return _c(
                                                          "el-option",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  index !==
                                                                    "all" &&
                                                                  (_vm.carousels
                                                                    .length &&
                                                                  _vm.ledInfo
                                                                    .is_banner ==
                                                                    1
                                                                    ? _vm.carousels[
                                                                        _vm
                                                                          .theCarousel
                                                                      ].carousels.indexOf(
                                                                        +index
                                                                      ) >= 0
                                                                    : true),
                                                                expression:
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\tindex !== 'all' &&\n\t\t\t\t\t\t\t\t\t\t\t\t\t(carousels.length && ledInfo.is_banner == 1\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t? carousels[theCarousel].carousels.indexOf(+index) >= 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t: true)\n\t\t\t\t\t\t\t\t\t\t\t\t",
                                                              },
                                                            ],
                                                            key: index,
                                                            attrs: {
                                                              value: index,
                                                              label: item.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: " " } },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.dialogData
                                                            .isAllCover,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dialogData,
                                                            "isAllCover",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dialogData.isAllCover",
                                                      },
                                                    },
                                                    [_vm._v("统一数据覆盖")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "allColor_right_name",
                                        staticStyle: {
                                          "margin-bottom": "40px",
                                          "font-size": "25px",
                                          "text-align": "center",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dialogData.size[
                                                _vm.dialogData.isAllCover
                                                  ? "all"
                                                  : _vm.dialogData.leftShowId
                                              ].name
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "allColor_right_name" },
                                        [_vm._v("颜色设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "allColor_right_item" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "边框背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].border_background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "border_background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\tdialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].border_background_color\n\t\t\t\t\t\t\t\t\t\t\t",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "边框字体颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].border_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "border_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].border_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "内部背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].background_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "allColor_right_item" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "表头背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].title_background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "title_background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].title_background_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "表头字体颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].title_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "title_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].title_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "待售背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].forSale_background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "forSale_background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\tdialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].forSale_background_color\n\t\t\t\t\t\t\t\t\t\t\t",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "待售字体颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].forSale_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "forSale_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].forSale_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "allColor_right_item" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "非售背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ]
                                                      .notForSale_background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "notForSale_background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\tdialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].notForSale_background_color\n\t\t\t\t\t\t\t\t\t\t\t",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "非售字体颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].notForSale_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "notForSale_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].notForSale_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "已售背景颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].sold_background_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "sold_background_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].sold_background_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "已售字体颜色" },
                                            },
                                            [
                                              _c("el-color-picker", {
                                                attrs: { "show-alpha": "" },
                                                model: {
                                                  value:
                                                    _vm.dialogData.allColor
                                                      .left[
                                                      _vm.dialogData.isAllCover
                                                        ? "all"
                                                        : _vm.dialogData
                                                            .leftShowId
                                                    ].sold_color,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dialogData.allColor
                                                        .left[
                                                        _vm.dialogData
                                                          .isAllCover
                                                          ? "all"
                                                          : _vm.dialogData
                                                              .leftShowId
                                                      ],
                                                      "sold_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dialogData.allColor.left[dialogData.isAllCover ? 'all' : dialogData.leftShowId].sold_color",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_name" },
                                    [_vm._v("平面图颜色设置")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "allColor_right_item" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "title.color",
                                            label: "边框颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.left
                                                  .carPlan.border_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.left
                                                    .carPlan,
                                                  "border_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.left.carPlan.border_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "title.color",
                                            label: "背景颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.left
                                                  .carPlan.background_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.left
                                                    .carPlan,
                                                  "background_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.left.carPlan.background_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "title.color",
                                            label: "标题字体颜色",
                                          },
                                        },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.dialogData.allColor.left
                                                  .carPlan.title_color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dialogData.allColor.left
                                                    .carPlan,
                                                  "title_color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogData.allColor.left.carPlan.title_color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                          ]
                        )
                      : _vm.dialogData.key === "itemDetailId"
                      ? _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.dialogData,
                              "label-width": "120px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "allColor_right_name" },
                                [_vm._v("颜色设置")]
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "边框背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].border_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "border_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].border_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "边框字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].border_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "border_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].border_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "内部背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "表头背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].title_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "title_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].title_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "表头字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].title_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "title_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].title_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "待售背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].forSale_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "forSale_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].forSale_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "待售字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].forSale_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "forSale_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].forSale_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "非售背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].notForSale_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "notForSale_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].notForSale_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "非售字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].notForSale_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "notForSale_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].notForSale_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "已售背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].sold_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "sold_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].sold_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "已售字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ].sold_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ],
                                              "sold_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id].sold_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_name" },
                                [_vm._v("宽高设置")]
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "宽度" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "195px" },
                                        attrs: { min: 0 },
                                        model: {
                                          value: _vm.dialogData.size.width,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.size,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression: "dialogData.size.width",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "高度" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "195px" },
                                        attrs: { min: 0 },
                                        model: {
                                          value: _vm.dialogData.size.height,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.size,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression: "dialogData.size.height",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm.dialogData.key === "column"
                      ? _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.dialogData,
                              "label-width": "120px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "allColor_right_name" },
                                [_vm._v("颜色设置")]
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "表头背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ][_vm.dialogData.column]
                                              .title_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ][_vm.dialogData.column],
                                              "title_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id][dialogData.column].title_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "表头字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ][_vm.dialogData.column]
                                              .title_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ][_vm.dialogData.column],
                                              "title_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id][dialogData.column].title_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "待售背景颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ][_vm.dialogData.column]
                                              .forSale_background_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ][_vm.dialogData.column],
                                              "forSale_background_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id][dialogData.column].forSale_background_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "待售字体颜色" } },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { "show-alpha": "" },
                                        model: {
                                          value:
                                            _vm.dialogData.allColor.left[
                                              _vm.dialogData.id
                                            ][_vm.dialogData.column]
                                              .forSale_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.allColor.left[
                                                _vm.dialogData.id
                                              ][_vm.dialogData.column],
                                              "forSale_color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogData.allColor.left[dialogData.id][dialogData.column].forSale_color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_name" },
                                [
                                  _vm._v(" 宽高设置 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "将宽度设置为 0 可以清除列宽度设置，将高度设置为 0 可以清除列高度设置！",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tips el-icon-question",
                                        staticStyle: { "margin-left": "10px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "allColor_right_item" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "宽度" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "195px" },
                                        attrs: {
                                          min: 0,
                                          max: _vm.dialogData.size.maxWidth,
                                        },
                                        model: {
                                          value: _vm.dialogData.size.width,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.size,
                                              "width",
                                              $$v
                                            )
                                          },
                                          expression: "dialogData.size.width",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "高度" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "195px" },
                                        attrs: { min: 0 },
                                        model: {
                                          value: _vm.dialogData.size.height,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dialogData.size,
                                              "height",
                                              $$v
                                            )
                                          },
                                          expression: "dialogData.size.height",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "20px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "greens-btn",
                        on: {
                          click: function ($event) {
                            return _vm.ondblClickDialog("submit")
                          },
                        },
                      },
                      [_vm._v("确定修改")]
                    ),
                  ],
                  1
                ),
              ]),
        ]
      ),
      _vm.boxSelectData.show
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100vw",
                height: "100vh",
                position: "absolute",
                top: "0",
                left: "0",
                "background-color": "rgba(0, 0, 0, 0.1)",
                "z-index": "11000000",
              },
            },
            [
              _vm.boxSelectData.width || _vm.boxSelectData.height
                ? _c("div", {
                    staticStyle: {
                      position: "absolute",
                      "z-index": "11000000",
                      border: "3px solid red",
                    },
                    style: _vm.boxSelectData,
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("../../assets/image/logo-black.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }