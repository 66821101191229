import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";

var confing = require('../../views/tool/rightSetting/common/componentLibrary');

import store from '../index';
var state = {
  isChange: false,
  //开盘前后不同组件
  isHide: 0,
  //1隐藏 0不隐藏
  cardBg: 'rgb(26, 70, 178)',
  //蓝色卡片默认色
  background: 'rgb(22, 20, 20)',
  //整个右边背景色
  colorType: 0,
  //0黑色 1科技蓝
  commontList: [],
  // 开盘后
  afterCommontLIst: [],
  currentConfing: '1',
  //1开盘前 2开盘后 //默认开盘前
  parentIndex: -1,
  // 默认颜色配置
  defaultColor: {
    background: '#454343',
    //组件整体背景
    title_Color: '#fff',
    //组件标题字体颜色
    title_size: 14,
    //标题字体大小,
    time_Color: 'rgb(255, 49, 49)',
    //时间字体颜色
    time_size: 17,
    //时间字体大小
    time_Bg: '#fff',
    //时间背景
    timeJd_Color: '#fff',
    //时间进度字体颜色
    timeJd_size: 14,
    //进度字体大小
    timeJd_Bbatch_Color: 'rgb(255, 49, 49)',
    brand_Color: 'rgba(255, 255, 255, 0.3);'
  },
  timeConfing: {
    //时间组件颜色配置
    background: '',
    title_size: '',
    title_Color: '',
    time_Color: '',
    time_size: '',
    time_Bg: '',
    timeJd_Bbatch_Color: '#fff',
    timeJd_size: '',
    timeJd_Color: ''
  },
  amountConfing: {
    //指标组件颜色配置
    background: '',
    name_size: 14,
    name_Color: '#fff',
    data_size: 20,
    data_Color: '#fff'
  },
  // 其他组件颜色配置
  name_Confing: {
    //项目名称
    title_size: 20,
    title_Color: '#fff'
  },
  watermark_Confing: {
    //品牌水印
    background: 'rgb(22, 20, 20)',
    brand_Color: ''
  },
  houseType_Confing: {
    //户型
    background: '',
    record_bg: '#3d3c3c',
    houseType_size: 14,
    houseType_Color: '#fff'
  },
  flow_Confing: {
    //选房流水
    background: '',
    record_bg: '#3d3c3c',
    record_house_size: 14,
    record_house_Color: '#fff',
    record_name_size: 14,
    record_name_Color: '#fff'
  },
  batch_Confing: {
    //批次默认颜色配置
    background: '',
    title_size: 14,
    title_Color: '',
    data_size: 14,
    data_Color: '#fff'
  },
  afterBatch_Confing: {
    //批次默认颜色配置
    background: '',
    title_size: 14,
    title_Color: '',
    data_size: 14,
    data_Color: '#fff'
  },
  saleMoney_Confing: {
    //销售额
    background: 'rgb(245, 34, 45)',
    title_size: 14,
    title_Color: 'rgb(255, 255, 255)',
    data_size: 26,
    data_Color: '#fff'
  },
  currentForm: '',
  //当前编辑组件名字
  subCurrentForm: '' //容器组件子组件名字
  // 编辑颜色配置

};
var mutations = {
  SET_CHANGE: function SET_CHANGE(state, val) {
    state.isChange = val;
  },
  SET_RIGHT_HIDE: function SET_RIGHT_HIDE(state, val) {
    state.isHide = val;
  },
  // 同步开盘前后
  SYNCSETTING: function SYNCSETTING(state) {
    var currentConfing = state.currentConfing,
        commontList = state.commontList;

    if (currentConfing == 1) {
      state.afterCommontLIst = JSON.parse(JSON.stringify(state.commontList));
    } else {
      state.commontList = JSON.parse(JSON.stringify(state.afterCommontLIst));
    }
  },
  SET_DEFALUT_COLOR: function SET_DEFALUT_COLOR(state, color_type) {
    //默认颜色 颜色配置 黑色 科技蓝 默认黑色
    state.colorType = color_type;

    if (color_type == 1) {
      //科技蓝颜色配置
      state.defaultColor.background = 'rgb(26, 70, 178)'; //组件背景

      state.background = 'rgb(21, 47, 140)'; //整体背景

      state.flow_Confing.record_bg = state.cardBg;
      state.watermark_Confing.background = 'rgb(21, 47, 140)';
      state.houseType_Confing.record_bg = state.cardBg;
      state.defaultColor.title_Color = '#fff';
      state.defaultColor.timeJd_Color = '#fff';
    }
  },
  SETBACKGROUND: function SETBACKGROUND(state, data) {
    state.background = data;
  },
  //接口返回值
  SET_ACTIVE_DATA: function SET_ACTIVE_DATA(state, _ref) {
    var start_json = _ref.start_json,
        end_json = _ref.end_json,
        background = _ref.background;
    var _store$state$user$adm = store.state.user.adminParameter,
        _store$state$user$adm2 = _store$state$user$adm.sp,
        cate_car_name = _store$state$user$adm2.cate_car_name,
        cate_room_name = _store$state$user$adm2.cate_room_name,
        _store$state$user$adm3 = _store$state$user$adm.list,
        cate_car = _store$state$user$adm3.cate_car,
        cate_room = _store$state$user$adm3.cate_room;

    if (cate_car && !cate_room) {
      confing.defalutCommont[3].children[0].title = '可选' + cate_car_name;
      confing.defalutCommont[3].children[0].unit = 'car';
    } else {
      confing.defalutCommont[3].children[0].title = '可选' + cate_room_name;
    }

    state.commontList = start_json ? JSON.parse(start_json) : JSON.parse(JSON.stringify(confing.defalutCommont));
    state.afterCommontLIst = end_json ? JSON.parse(end_json) : JSON.parse(JSON.stringify(confing.defalutCommont));

    if (background) {
      state.background = background;
    }
  },
  // 排序
  DRAG_SORT: function DRAG_SORT(state, _ref2) {
    var value = _ref2.value;
    var list = state.currentConfing == 1 ? 'commontList' : 'afterCommontLIst';
    state[list] = value;
  },
  // 切换开盘前 开盘后
  SWICTH_COMMONT: function SWICTH_COMMONT(state, status) {
    state.currentConfing = status;
  },
  // 重置回复默认
  REST_COMMONT: function REST_COMMONT(state, data) {
    if (state.currentConfing == 1) {
      state.commontList = JSON.parse(JSON.stringify(confing.defalutCommont));
    } else {
      state.afterCommontLIst = JSON.parse(JSON.stringify(confing.defalutCommont));
    }

    state.background = state.colorType == 1 ? 'rgb(21, 47, 140)' : 'rgb(22, 20, 20)';
  },
  // 编辑组件
  EDIT_COMMONT: function EDIT_COMMONT(state, _ref3) {
    var currentForm = _ref3.currentForm,
        parentIndex = _ref3.parentIndex;
    state.currentForm = currentForm;
    state.parentIndex = parentIndex;

    if (!currentForm.includes('指标容器')) {
      state.subCurrentForm = '';
    }
  },
  // 编辑容器组件
  EDIT_SUB_COMMONT: function EDIT_SUB_COMMONT(state, _ref4) {
    var parentIndex = _ref4.parentIndex,
        title = _ref4.title;
    state.parentIndex = parentIndex;
    state.subCurrentForm = title;
  },
  // 添加组件
  ADD_COMMONT: function ADD_COMMONT(state, _ref5) {
    var data = _ref5.data,
        isAmount = _ref5.isAmount,
        _ref5$parentIndex = _ref5.parentIndex,
        parentIndex = _ref5$parentIndex === void 0 ? -1 : _ref5$parentIndex;
    var list = state.currentConfing == 1 ? 'commontList' : 'afterCommontLIst';

    if (isAmount) {
      //指标子组件添加
      if (parentIndex === -1) {
        //只有一个的时候添加
        state[list].map(function (item) {
          if (item.title.includes('指标容器')) {
            item.children.push(data);
          }
        });
        state[list];
      } else {
        state[list][parentIndex].children.push(data);
      }

      return;
    }

    if (data.title == '品牌水印') {
      state[list].push(data);
    } else if (data.title == '认购流水' || data.title.indexOf('户型') != -1) {
      state[list].splice(state[list].length - 1, 0, data);
    } else {
      state[list].splice(state[list].length - 2, 0, data);
    }
  },
  // 删除组件
  DELTE_COMMONT: function DELTE_COMMONT(state, dome) {
    //正常组件删除
    var list = state.currentConfing == 1 ? 'commontList' : 'afterCommontLIst';

    for (var i = 0; i < state[list].length; i++) {
      if (dome.amountIndex >= 0 && state[list][dome.amountIndex].children.length > 1) {
        state[list][dome.amountIndex].children.splice(dome.subIndex, 1);
        break;
      } else if (state[list][i].title == state.currentForm) {
        state[list].splice(i, 1);
        break;
      }
    }

    state.currentForm = '';
  },
  // 更改后更新vuex值
  SET_COMMONTLIST: function SET_COMMONTLIST(state, _ref6) {
    var name = _ref6.name,
        key = _ref6.key,
        val = _ref6.val,
        iscontainer = _ref6.iscontainer;
    var list = state.currentConfing == 1 ? 'commontList' : 'afterCommontLIst';
    var commontList = JSON.parse(JSON.stringify(state[list]));

    for (var i = 0; i < commontList.length; i++) {
      if (iscontainer) {
        commontList[state.parentIndex].styleForm[key] = val;
        commontList[state.parentIndex].children.map(function (item) {
          if (item.title == state.subCurrentForm) {
            item.styleForm[key] = val;
          }
        });
      } else {
        if (commontList[i].title == name) {
          commontList[i].styleForm[key] = val;
          break;
        }
      }
    }

    state[list] = commontList;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};