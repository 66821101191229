import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// project/syncpayinfo
import { parameterInfo, preserveSave, getPayInfoRule as _getPayInfoRule, syncPayInfo } from '../../api/openMaintenance';
import utils from "@/utils/utils";
import { mapGetters } from 'vuex';
export default {
  name: "paymentPage",
  data: function data() {
    return {
      data: {},
      dataInfo: {},
      pay_info: [],
      info: {},
      isPC: utils.isPC(),
      radio: '',
      pay_info_room_rule: '',
      pay_info_car_rule: "",
      payInfoRule: {},
      //付款方式规则
      collectionId: []
    };
  },
  created: function created() {
    this.getDataInfo();
  },
  computed: _objectSpread({}, mapGetters(['roles', 'customSetting'])),
  mounted: function mounted() {},
  methods: {
    lateChange: function lateChange(val, index, index2) {
      if (val === 1) {
        this.pay_info[index].pay_by_stages[index2].payment_amount = 0;
        this.$forceUpdate();
      }
    },
    romeSelectAll: function romeSelectAll(val) {
      if (val) {}
    },
    // 同步付款方式
    samePayInfo: function samePayInfo() {
      var _this = this;

      syncPayInfo().then(function (res) {
        if (res.status == 200) {
          _this.getDataInfo();

          _this.$message({
            type: 'success',
            message: '同步成功'
          });
        }
      });
    },
    save: function save() {
      //保存
      var flage = true;
      var errMSg = '';
      console.log(this.pay_info);
      var is_default = this.info.jf_id ? false : true; // return

      try {
        this.pay_info.forEach(function (item, index) {
          if (item.show_name == '') {
            flage = false;
            throw {
              msg: '付款方式名称不能为空'
            };
          }

          if (item.is_default) {
            is_default = true;
          }

          if (!item.roomRule.length && !item.carRule.length && !item.business_type_id) {
            //一次性
            flage = false;
            throw {
              msg: item.show_name + '的适用范围不能为空'
            };
          }

          item.room_range_id = item.roomRule.join(',');
          item.car_range_id = item.carRule.join(',');

          if (item.type == 0) {
            if (item.reservation_time === '') {
              flage = false;
              throw {
                msg: '天数不能为空'
              };
            }
          } else if (item.type == 1) {
            if (item.reservation_time === '') {
              flage = false;
              throw {
                msg: '天数不能为空'
              };
            }

            if (item.pay_proportion === '' || item.pay_proportion == 0) {
              flage = false;
              throw {
                type: 2,
                msg: '首付款比例不能为0或空'
              };
            }
          } else if (item.type == 2) {
            if (item.by_stages_number === '') {
              flage = false;
              throw {
                msg: item.show_name + "分期数不能为空"
              };
            }

            var per = 0;
            item.pay_by_stages.map(function (items, indexs) {
              items.sort = indexs + 1;
              per += items.pay_proportion;

              if (items.reservation_time === '') {
                //时间不能为空
                flage = false;
                throw {
                  msg: item.show_name + '第' + (indexs + 1) + '期' + '时间不能为空'
                };
              }

              if (items.pay_proportion === '') {
                items.pay_proportion = 0;
              }

              if (items.payment_amount === '') {
                items.payment_amount = 0;
              }
            });

            if (per != 100 && !item.payment_template_id && !item.by_stages_amount_type) {
              flage = false;
              throw {
                type: 1,
                msg: item.show_name + "比例不等于百分之百,请重新填写"
              };
            }
          }

          if ((item === null || item === void 0 ? void 0 : item.reservation_time) === '') {
            item.reservation_time = 0;
          }

          if ((item === null || item === void 0 ? void 0 : item.pay_proportion) === '') {
            console.log('78888');
            item.pay_proportion = 0;
          }
        });
      } catch (err) {
        errMSg = err.msg;
      }

      if (flage && is_default) {
        this.handleSave();
      } else {
        this.$message({
          type: 'error',
          message: errMSg ? errMSg : '请勾选默认付款方式！'
        });
      }
    },
    payTypeChange: function payTypeChange(index) {
      //付款方式类型选择
      console.log(this.pay_info[index]);
      var ispay_by_stages = this.pay_info[index].pay_by_stages;
      var _this$pay_info$index = this.pay_info[index],
          carRule = _this$pay_info$index.carRule,
          car_range_id = _this$pay_info$index.car_range_id,
          roomRule = _this$pay_info$index.roomRule,
          room_range_id = _this$pay_info$index.room_range_id,
          show_name = _this$pay_info$index.show_name,
          pay_time_type = _this$pay_info$index.pay_time_type;

      if (this.pay_info[index].type != 2) {
        this.pay_info[index].pay_by_stages.length = 0;
      }

      if (this.pay_info[index].type == 2 && (!ispay_by_stages || !ispay_by_stages.length)) {
        this.pay_info[index].by_stages_number = 0; // this.pay_info[index].pay_by_stages= []

        this.pay_info[index].pay_by_stages.push({
          pay_info_id: this.pay_info[index].id,
          pay_proportion: '',
          reservation_time: '',
          sort: 0
        });
        this.$forceUpdate();
      }
    },
    dleatByStages: function dleatByStages(index, deltIndex) {
      //删除分期
      this.pay_info[index].pay_by_stages.splice(deltIndex, 1);
      this.$forceUpdate();
    },
    addByStages: function addByStages(index) {
      //新增分期
      var late_stage = this.pay_info[index].pay_by_stages.findIndex(function (item) {
        return item.late_stage_amount === 1;
      });

      if (this.pay_info[index].pay_by_stages.length < this.pay_info[index].by_stages_number && late_stage < 0) {
        //最大期数 列表不能大于最大期数
        this.pay_info[index].pay_by_stages.push({
          pay_info_id: this.pay_info[index].id,
          pay_proportion: 0,
          reservation_time: 0,
          sort: 0
        });
        this.$forceUpdate();
      }
    },
    dleatPay: function dleatPay(index) {
      var _this2 = this;

      //删除付款方式
      var data = this.pay_info[index]; // 有模板id
      // #6850 1、建发项目下同步的付款方式原来不允许删除，取消这个控制，允许删除
      // if (!!data.payment_template_id) {
      //   this.$message.error('接口同步的付款方式,不允许删除！')
      //   return
      // }

      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.pay_info.splice(index, 1);

        _this2.$forceUpdate();

        _this2.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    copyPay: function copyPay(index) {
      //复制付款方式
      var add = JSON.parse(JSON.stringify(this.pay_info[index]));
      add.id = 0;
      this.pay_info.push(add);
    },
    addPay: function addPay() {
      //新增付款方式
      var add = JSON.parse(JSON.stringify(this.pay_info[0]));
      add.name = '';
      add.status = 1;
      add.id = 0;
      add.type = 0;
      add.show_name = '';
      add.reservation_time = 0;
      add.pay_time_type = 1;
      add.by_stages_type = 0;
      add.payment_template_id = '';
      add.roomRule = [];
      add.carRule = [];
      this.pay_info.push(add);
    },
    handleSave: function handleSave() {
      var _this3 = this;

      console.log('通过验证');
      console.log(this.pay_info);
      var data = this.data;
      var timing = JSON.parse(JSON.stringify(this.timing));

      if (timing.timing_list && timing.timing_list.length) {
        var timing_list = [];
        timing.timing_list.forEach(function (item) {
          if (item.start_time && item.end_time) {
            item.start_time = parseInt(item.start_time / 1000);
            item.end_time = parseInt(item.end_time / 1000);
            timing_list.push(item);
          }
        });
        timing.timing_list = timing_list;
      }

      var dataInfo = {
        project: {
          id: data.id,
          status: data.status
        },
        project_rule: {
          sign_in: data.sign_in,
          buy_rand: data.buy_rand,
          price: data.price,
          price_rand: data.price_rand,
          sign_up: data.sign_up,
          sign_up_rand: data.sign_up_rand,
          audit_in: data.audit_in,
          data_file: data.data_file
        },
        project_room: {
          room_info_preference: data.room_info_preference,
          room_info_room: data.room_info_room,
          room_info_tag: data.room_info_tag,
          room_info_cate: data.room_info_cate,
          room_info_price: data.room_info_price,
          room_info_area: data.room_info_area,
          room_info_gift: data.room_info_gift,
          room_info_desc: data.room_info_desc,
          room_info_hot: data.room_info_hot,
          room_info_total_price_prec: data.room_info_total_price_prec,
          room_info_price_type: data.room_info_price_type,
          room_info_pin_xian_jia: data.room_info_pin_xian_jia,
          room_info_pin_yuan_jia: data.room_info_pin_yuan_jia,
          room_info_pin_you_hui_jia: data.room_info_pin_you_hui_jia,
          room_info_pin_biao_zhun_jia: data.room_info_pin_biao_zhun_jia,
          room_info_pin_an_jie_jia: data.room_info_pin_an_jie_jia,
          room_info_pin_yi_ci_jia: data.room_info_pin_yi_ci_jia,
          room_info_price_prec: data.room_info_price_prec,
          room_list_room: data.room_list_room,
          room_list_price: data.room_list_price,
          room_list_area: data.room_list_area,
          room_list_cate: data.room_list_cate,
          room_list_type: data.room_list_type,
          room_list_icon: data.room_list_icon,
          room_list_price_type: data.room_list_price_type,
          room_list_price_type_way: data.room_list_price_type_way,
          room_list_area_type: data.room_list_area_type,
          order_price: data.order_price,
          order_area: data.order_area,
          room_info_prec_status: data.room_info_prec_status,
          room_info_select: data.room_info_select,
          room_publish: data.room_publish,
          room_info_pin_xian_jia_scribe: data.room_info_pin_xian_jia_scribe,
          room_info_pin_yuan_jia_scribe: data.room_info_pin_yuan_jia_scribe,
          room_info_pin_you_hui_jia_scribe: data.room_info_pin_you_hui_jia_scribe,
          room_info_pin_biao_zhun_jia_scribe: data.room_info_pin_biao_zhun_jia_scribe,
          room_info_pin_an_jie_jia_scribe: data.room_info_pin_an_jie_jia_scribe,
          room_info_pin_yi_ci_jia_scribe: data.room_info_pin_yi_ci_jia_scribe,
          room_info_area_type: data.room_info_area_type,
          room_info_gift_price: data.room_info_gift_price,
          you_hui_price: data.you_hui_price
        },
        project_order: {
          collect_count: data.collect_count,
          all_btn: data.all_btn,
          sign_up_time: data.sign_up_time,
          buy_other: data.buy_other_time,
          rand_time_min: data.rand_time_min == '' ? 0 : data.rand_time_min,
          rand_time_type: data.rand_time_type,
          rand_start_time_type: data.rand_start_time_type,
          rand_start_time_min: data.rand_start_time_min,
          matching_time_min: data.matching_time_min || 0,
          matching_time_type: data.matching_time_type,
          is_formal_rand: data.is_formal_rand,
          buy_count_collect: data.buy_count_collect
        },
        project_pay: {
          online_pay: data.online_pay,
          online_sign: data.online_sign,
          online_sign_type: data.online_sign_type,
          price_money: parseFloat(data.price_money),
          wx_appid: data.wx_appid,
          wx_appsecret: data.wx_appsecret,
          wx_mchid: data.wx_mchid,
          wx_key: data.wx_key,
          project_room: data.project_room,
          car_price: parseFloat(data.car_price),
          villa_price: parseFloat(data.villa_price),
          shops_price: parseFloat(data.shops_price),
          is_online_rule: data.is_online_rule,
          online_sign_pay_base: data.online_sign_pay_base,
          buy_start_all_time_type: data.buy_start_all_time_type,
          buy_start_all_time_min: data.buy_start_all_time_min,
          pay_info_car_rule: this.pay_info_car_rule,
          pay_info_room_rule: this.pay_info_room_rule
        },
        project_timing: {
          is_time_limit: timing.is_time_limit,
          timing_name: timing.timing_name,
          timing: timing.timing_list || []
        },
        pay_info: this.pay_info,
        you_hui_rule: this.you_hui_rule
      };
      preserveSave(dataInfo).then(function (res) {
        if (res.status == 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.getDataInfo();
        }
      });
    },
    getDataInfo: function getDataInfo() {
      var _this4 = this;

      parameterInfo().then(function (res) {
        var _res$data = res.data,
            list = _res$data.list,
            timing = _res$data.timing,
            pay_info = _res$data.pay_info,
            sp = _res$data.sp,
            you_hui_rule = _res$data.you_hui_rule;
        _this4.data = _objectSpread({}, list);
        _this4.pay_info_room_rule = list.pay_info_room_rule;
        _this4.pay_info_car_rule = list.pay_info_car_rule;
        _this4.info = list;

        if (timing.timing_list && timing.timing_list.length) {
          timing.timing_list.forEach(function (item) {
            item.start_time = item.start_time * 1000;
            item.end_time = item.end_time * 1000;
          });
        } else {
          timing.timing_list = [];
        }

        _this4.timing = timing;
        _this4.you_hui_price = list.you_hui_price;
        _this4.you_hui_rule = you_hui_rule || [];

        _this4.getPayInfoRule();

        if (pay_info && pay_info.length) {
          for (var i = 0; i < pay_info.length; i++) {
            pay_info[i].roomRule = pay_info[i].room_range_id ? _this4.setallType(pay_info[i].room_range_id.split(',')) : [];
            pay_info[i].carRule = pay_info[i].car_range_id ? _this4.setallType(pay_info[i].car_range_id.split(',')) : [];

            if (!pay_info[i].pay_by_stages) {
              pay_info[i].pay_by_stages = [];
            }
          }

          _this4.pay_info = pay_info;
        } else {
          var add = JSON.parse(JSON.stringify({}));
          add.business_type_id = '';
          add.business_type_name = '';
          add.id = 0;
          add.type = 0;
          add.show_name = '';
          add.pay_time_type = 1;
          add.reservation_time = 0;
          add.roomRule = [];
          add.carRule = [];
          add.by_stages_number = 0;
          add.pay_by_stages = [];

          _this4.pay_info.push(add);

          _this4.pay_info = [add];
        }
      });
    },
    setallType: function setallType(all) {
      var newAll = all;
      var retunall = [];

      for (var i = 0; i < newAll.length; i++) {
        retunall.push(parseInt(newAll[i]));
      }

      return retunall;
    },
    // h获取范围
    getPayInfoRule: function getPayInfoRule(val) {
      var _this5 = this;

      console.log(val, '123');
      var pay_info_room_rule = this.pay_info_room_rule,
          pay_info_car_rule = this.pay_info_car_rule;

      _getPayInfoRule({
        pay_info_room_rule: pay_info_room_rule,
        pay_info_car_rule: pay_info_car_rule
      }).then(function (res) {
        var info = res.data.info;
        _this5.payInfoRule = info;

        if (val) {
          for (var i = 0; i < _this5.pay_info.length; i++) {
            _this5.pay_info[i].room_range_id = '';
            _this5.pay_info[i].car_range_id = '';
            _this5.pay_info[i].carRule = [];
            _this5.pay_info[i].roomRule = [];
          }

          console.log(_this5.pay_info);

          _this5.$forceUpdate();
        }
      });
    }
  }
};