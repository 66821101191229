var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "custom-table-copy" } },
    [
      _c(
        "el-table",
        {
          staticClass: "custom-table-copy-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": {
              "background-color": _vm.headerColor,
            },
          },
        },
        _vm._l(_vm.tableHead, function (item, i) {
          return _c("el-table-column", {
            key: i,
            attrs: {
              prop: item.prop,
              label: item.label,
              align: "center",
              "min-width": item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.prop == "sum_price" &&
                              scope.row[item.prop] !== "未提供"
                              ? _vm.tenThousandTrans(scope.row[item.prop])
                              : scope.row[item.prop]
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }