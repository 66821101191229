var state = {
  // row 数据
  toolReviewRow: {},
  // 是否打开弹框
  isOpenDrawer: false,
  // 类型
  drawerType: 0,
  // 审核附表图片
  attach_img_arr: [],
  // 客户登记图片
  img_arr: [],
  // 详情按钮 类型 （0客户登记表， 1审核附表）
  detailBtnType: 0,
  // 当前图片地址
  selectImg: {
    name: '',
    url: ''
  },
  // 是否显示左侧图片预览框
  isShowImgPreviewBox: false,
  // 判断是报名还是认筹  1报名 2认筹
  statusType: 1
};
var mutations = {
  // 修改row数据
  CHANGE_ROW: function CHANGE_ROW(state, payload) {
    state.toolReviewRow = payload;
  },
  // 修改弹框 状态
  OPEN_CLOSE_DRAWER: function OPEN_CLOSE_DRAWER(state, payload) {
    state.isOpenDrawer = payload;
  },
  // 修改 页面状态
  GET_STATUS_TYPE: function GET_STATUS_TYPE(state, payload) {
    state.statusType = payload;
  },
  // 修改弹框类型
  CHANGE_DRAWER_TYPE: function CHANGE_DRAWER_TYPE(state, payload) {
    state.drawerType = payload;
  },
  // 修改图片
  CHANGE_ALL_IMG: function CHANGE_ALL_IMG(state, payload) {
    state.attach_img_arr = payload[0] || [{
      name: '',
      url: ''
    }];
    state.img_arr = payload[1] || [{
      name: '',
      url: ''
    }];
    state.selectImg = payload[1] && payload[1][0];
  },
  // 修改按钮类型
  CHANGE_BTN_TYPE: function CHANGE_BTN_TYPE(state, payload) {
    if (payload == 0) {
      state.selectImg = state.img_arr[0];
    }

    if (payload == 1) {
      state.selectImg = state.attach_img_arr[0];
    }

    state.detailBtnType = payload;
  },
  // 修改当前图片
  CHANGE_CUREENT_IMG: function CHANGE_CUREENT_IMG(state, payload) {
    state.selectImg = payload;
  },
  // 右侧图片预览组件
  CHANGE_IMG_PREVIEW: function CHANGE_IMG_PREVIEW(state, payload) {
    state.isShowImgPreviewBox = payload;
  }
};
var actions = {
  // 修改row数据
  change_row: function change_row(_ref, payload) {
    var commit = _ref.commit;
    commit('CHANGE_ROW', payload);
  },
  // 修改弹框 状态
  open_close_drawer: function open_close_drawer(_ref2, payload) {
    var commit = _ref2.commit;
    commit('OPEN_CLOSE_DRAWER', payload);
  },
  //  获取 报名还是认筹
  getStatusType: function getStatusType(_ref3, payload) {
    var commit = _ref3.commit;
    commit('GET_STATUS_TYPE', payload);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};