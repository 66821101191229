var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "base-popup" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "popup-container",
      },
      [
        _c("div", { staticClass: "shade", staticStyle: { "z-index": "2001" } }),
        _c(
          "div",
          { staticClass: "popup-content", style: { height: _vm.height } },
          [
            _vm.close
              ? _c("i", {
                  staticClass: "el-icon-close close",
                  on: { click: _vm.hidePopup },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "popup-hander" },
              [
                _vm._t("title", [
                  _c("span", { staticClass: "popup-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
              ],
              2
            ),
            _c("div", { staticClass: "popup-main" }, [_vm._t("default")], 2),
            _c("div", { staticClass: "popup-footer" }, [_vm._t("footer")], 2),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }