import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
import echarts from 'echarts';
export default {
  name: 'ColumnarChart',
  props: {
    // e-chart id
    id: {
      type: String,
      default: 'columnar-chart'
    },
    // e-chart 名称
    name: {
      type: String,
      default: ''
    },
    // 是否显示 Legend
    isLegend: {
      type: Boolean,
      default: true
    },
    // x轴data
    XData: {
      type: Array,
      default: function _default() {
        return ['完美户型1', '完美户型2', '完美户型3', '完美户型4'];
      }
    },
    // y轴data 2个数组
    YDataOne: {
      type: Array,
      default: function _default() {
        return [100, 50, 50, 45];
      }
    },
    YDataTwo: {
      type: Array,
      default: function _default() {
        return [100, 50, 50, 45];
      }
    },
    ispercent: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    XData: function XData() {
      this._init_();
    }
  },
  created: function created() {
    this.$emit('setRenderState', false);
  },
  mounted: function mounted() {
    this._init_();
  },
  methods: {
    sum: function sum(arr) {
      // 数组求和
      return eval(arr.join('+'));
    },
    _init_: function _init_() {
      var _this = this;

      var _self = this;

      var chartDom = document.getElementById(this.id);
      var myChart = echarts.init(chartDom);
      var ispercent = _self.ispercent;
      var option;
      option = {
        legend: {
          show: this.isLegend,
          data: [{
            name: '落位率'
          }, {
            name: '开盘去化率'
          }]
        },
        tooltip: {
          formatter: ispercent ? '{c}' : '{c}%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            color: '#7F7F7F',
            fontSize: 20
          },
          axisLine: {
            lineStyle: {
              width: 1,
              color: '#2A2A2A'
            }
          },
          axisTick: false,
          splitLine: {
            show: false
          },
          //   ['完美户型1', '完美户型2', '完美户型3', '完美户型4']
          data: _self.XData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#2A2A2A'
            }
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: ispercent ? '{value}' : '{value}%',
            color: '#2A2A2A',
            fontSize: 15
          },
          axisTick: false,
          splitLine: {
            show: false
          },
          minInterval: ispercent ? 1 : ''
        },
        series: [{
          name: '落位率',
          type: 'bar',
          color: ['#F8C264'],
          barWidth: 30,
          label: {
            show: true,
            position: 'top',
            formatter: function formatter(params) {
              if (ispercent) {
                var percentage = Math.floor(params.value / _this.sum(_this.YDataOne) * 100); //计算百分比

                return params.value + '人' + '\t' + percentage + '%';
              } else {
                return params.value + '%';
              }
            },
            color: '#2A2A2A',
            fontSize: 15,
            fontWidth: 400,
            fontFamily: 'DIN-BoldItalic'
          },
          // [100, 50, 50, 45]
          data: _self.YDataOne
        }, {
          name: '开盘去化率',
          type: 'bar',
          color: ['#FF8D72'],
          barWidth: 30,
          label: {
            show: !ispercent ? true : false,
            position: 'top',
            formatter: '{c}%',
            color: '#2A2A2A',
            fontSize: 15,
            fontWidth: 400,
            fontFamily: 'DIN-BoldItalic'
          },
          // [10, 90, 10, 45]
          data: _self.YDataTwo
        }]
      };
      option && myChart.setOption(option);
      this.$nextTick(function () {
        _this.$emit('setRenderState', true);
      });
    }
  }
};