import "core-js/modules/es.number.constructor.js";
//
//
//
//
export default {
  name: 'ProjectTitle',
  props: {
    // 文字信息
    innertext: {
      type: String,
      default: ''
    },
    // 字体大小
    pFont: {
      type: Number | String,
      default: 50
    },
    // 字体颜色
    pColor: {
      type: String,
      default: '#FFFFFF'
    },
    // 字体位置
    pPosition: {
      type: Number | String,
      default: 50
    }
  },
  data: function data() {
    return {};
  }
};