import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import toolReviewMixin from '../toolReviewMixin';
import { transferToCustomerSingle, singleStepAudit, preliminaryAudit, reviewAudit, adminSetting, identifyAuditSubmitAgain } from '@/api/sincerityRegister';
export default {
  name: 'CustomItem',
  mixins: [toolReviewMixin],
  props: {
    // 表格高度
    tableHeight: {
      type: [Number, String],
      default: function _default() {
        return 0;
      }
    },
    // 表格配置
    columnOption: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 数据
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 二级table 状态
    chooseRadio: {
      type: Number,
      default: 1
    },
    // 是否可以填写附表
    isFullTable: {
      type: Boolean,
      default: false
    },
    // saas 配置
    saasSetting: {
      type: Object,
      default: function _default() {
        return {
          audit: {}
        };
      }
    }
  },
  data: function data() {
    return {
      // 驳回弹框
      rejectDialogVisible: false,
      // 被驳回该条数据
      rejectRowData: {},
      // 驳回理由
      rejectText: '',
      // 是否时单点审核
      singlePoint: false,
      //
      rejectType: 0 // 开启drawer
      // isOpenDrawer: false

    };
  },
  mounted: function mounted() {// this.getAdminSetting()
  },
  methods: {
    // select change
    handleSelectionChange: function handleSelectionChange(e) {
      this.$emit('checkedNum', e);
    },
    // 处理时间等
    myFilter: function myFilter(item, row) {
      if (item.type) {
        switch (item.type) {
          case 'time':
            return moment(row[item.prop] * 1000).format('YYYY-MM-DD HH:mm');

          case 'status':
            if (row[item.prop] == 0) {
              return '未发起审核';
            }

            if (row[item.prop] == 1) {
              return '待审核';
            }

            if (row[item.prop] == 2) {
              return '审核通过';
            }

            if (row[item.prop] == -2) {
              return '审核驳回';
            }

            return '';

          case 'custom':
            if (row[item.prop] == 1) {
              return '未转入';
            }

            if (row[item.prop] == 2) {
              return '已转入';
            }

            return '';

          case 'isOver':
            if (row[item.prop] == 0) {
              return '填写未完成';
            }

            if (row[item.prop] == 1) {
              return '填写完成';
            }

            return '';

          default:
            return;
        }
      } else {
        return row[item.prop];
      }
    },
    // 单个转入
    singleTransfer: function singleTransfer(row) {
      var _this = this;

      var params = {
        id: row.id
      };
      this.$confirm('是否确认把所选客户转成开盘客户？', '提示', {
        confirmButtonText: '确 认',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(function () {
        transferToCustomerSingle(params).then(function (res) {
          _this.$message({
            type: 'success',
            message: res.data.msg
          }); // 重置数据


          _this.$emit('search');
        });
      }).catch(function () {
        return;
      });
    },
    // 拒绝
    refuse: function refuse() {
      var _this2 = this;

      this.rejectType = 0;
      this.$confirm('是否确认驳回，并拒绝客户再次提交？', '提示', {
        confirmButtonText: '确 认',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(function () {
        _this2.rejectDialogVisible = true;
      }).catch(function () {
        return;
      });
    },
    // 提交驳回理由
    submitRejectReason: function submitRejectReason() {
      var _this3 = this;

      var rejectData = this.rejectRowData;
      var type = this.rejectRowData.types; // 类型

      var text = this.rejectText;
      var params = {
        id: rejectData.id,
        type: this.rejectType == 0 ? 'refuse' : 'return',
        err_note: text
      };

      if (text.trim() == '') {
        this.$message({
          type: 'warning',
          message: '驳回理由不能为空'
        });
        return;
      }

      if (type == 'status0') {
        // 单步审核
        singleStepAudit(params).then(function (res) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.rejectText = '';

          _this3.$emit('search');

          _this3.rejectDialogVisible = false;
        });
      }

      if (type == 'status1') {
        // 初步审核
        preliminaryAudit(params).then(function (res) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.rejectText = '';

          _this3.$emit('search');

          _this3.rejectDialogVisible = false;
        });
      }

      if (type == 'status2') {
        // 复审审核
        reviewAudit(params).then(function (res) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.rejectText = '';

          _this3.$emit('search');

          _this3.rejectDialogVisible = false;
        });
      }
    },
    through: function through(row) {
      var _this4 = this;

      var types = row.types; // 类型

      var audit = this.saasSetting.audit;
      var params = {
        id: row.id,
        type: 'commit'
      };
      var isOver = true;

      if (audit.register_schedule == 1) {
        if (types == 'status0' || audit.register_schedule_admin == 1 && types == 'status1' || audit.register_schedule_admin == 2 && types == 'status2') {
          if (row.is_over == 0) {
            isOver = false;
          }
        }
      }

      if (!isOver) {
        // 如果有附表没有填
        this.$confirm('通过审核前请填写附表', '提示', {
          confirmButtonText: '去填附表',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(function () {
          // 跳转到附表页
          _this4.open_close_drawer(true);

          _this4.CHANGE_DRAWER_TYPE(0);

          _this4.CHANGE_ROW(row);
        }).catch(function () {
          return;
        });
      } else {
        this.$confirm('是否确认通过审核？', '提示', {
          confirmButtonText: '通 过',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(function () {
          if (types == 'status0') {
            // 单步审核
            singleStepAudit(params).then(function (res) {
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });

              _this4.$emit('search');

              _this4.rejectDialogVisible = false;
            });
          }

          if (types == 'status1') {
            // 初步审核
            preliminaryAudit(params).then(function (res) {
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });

              _this4.$emit('search');

              _this4.rejectDialogVisible = false;
            });
          }

          if (types == 'status2') {
            // 复审审核
            reviewAudit(params).then(function (res) {
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });

              _this4.$emit('search');

              _this4.rejectDialogVisible = false;
            });
          }
        }).catch(function () {
          return;
        });
      }
    },
    // 跳转详情
    handleDetails: function handleDetails(row) {
      // row.isOpenDrawer = true
      this.open_close_drawer(true);
      this.CHANGE_DRAWER_TYPE(1);
      this.CHANGE_ROW(row);
    },
    // 允许再次提交
    submitAgain: function submitAgain(row) {
      var _this5 = this;

      var params = {
        ids: [row.id]
      };
      this.$confirm('是否确认允许客户再次提交？', {
        type: 'warning',
        confirmButtonText: '确 认',
        cancelButtonText: '取消'
      }).then(function () {
        identifyAuditSubmitAgain(params).then(function (res) {
          _this5.$emit('search');

          _this5.$message({
            type: 'success',
            message: res.data.msg
          });
        });
      }).catch(function () {
        return;
      });
    },
    // saas项目配置
    getAdminSetting: function getAdminSetting() {
      var _this6 = this;

      adminSetting().then(function (res) {
        var saasSetting = res.data;
        _this6.saasSetting = saasSetting;

        if (saasSetting.audit.examine_rule == 1) {
          // 单点审核
          // 隐藏 初审 复审 按钮
          _this6.singlePoint = true;
        }
      });
    },
    handleSchedule: function handleSchedule(row) {
      this.open_close_drawer(true);
      this.CHANGE_DRAWER_TYPE(0);
      this.CHANGE_ROW(row);
    }
  }
};