var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container", attrs: { id: "staff-ctr" } },
    [
      _c(
        "div",
        { ref: "top", staticClass: "search-box" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "staff-left-box",
                  attrs: { xs: 24, sm: 6, md: 6 },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        size: "small",
                        fill: "#22A271",
                        model: "tabText",
                      },
                      model: {
                        value: _vm.tabText,
                        callback: function ($$v) {
                          _vm.tabText = $$v
                        },
                        expression: "tabText",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "admins/list"],
                              expression: "['admin', 'admins/list']",
                            },
                          ],
                          attrs: { label: "staff" },
                        },
                        [_vm._v("人员列表 ")]
                      ),
                      _c(
                        "el-radio-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "role/list"],
                              expression: "['admin', 'role/list']",
                            },
                          ],
                          attrs: { label: "post" },
                        },
                        [_vm._v("岗位列表 ")]
                      ),
                      _c(
                        "el-radio-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "team/list"],
                              expression: "['admin', 'team/list']",
                            },
                          ],
                          attrs: { label: "sales" },
                        },
                        [_vm._v("销售团队 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 8, md: 8 } },
                [
                  _vm.tabText === "staff"
                    ? [
                        _c(
                          "div",
                          { staticClass: "check-box-wrapper" },
                          [
                            _c("el-checkbox", {
                              staticClass: "input-box",
                              attrs: {
                                "true-label": 1,
                                "false-label": 0,
                                label: "无岗位",
                              },
                              model: {
                                value: _vm.search.null_role,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "null_role", $$v)
                                },
                                expression: "search.null_role",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: {
                                "true-label": 1,
                                "false-label": 0,
                                label: "无团队",
                              },
                              model: {
                                value: _vm.search.null_team,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "null_team", $$v)
                                },
                                expression: "search.null_team",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 4, md: 4 } },
                [
                  _vm.tabText === "staff"
                    ? [
                        _c("div", { staticClass: "staff-right-box" }, [
                          _c(
                            "div",
                            { staticClass: "search-wrapper" },
                            [
                              _c("span", { staticClass: "search-title" }, [
                                _vm._v("登录状态"),
                              ]),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "8em" },
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择登录状态",
                                  },
                                  model: {
                                    value: _vm.search.is_online,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "is_online", $$v)
                                    },
                                    expression: "search.is_online",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "未登录", value: 2 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "已登录", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 6, md: 6 } },
                [
                  _vm.tabText === "staff"
                    ? [
                        _c(
                          "div",
                          { staticClass: "search-wrapper" },
                          [
                            _c("el-input", {
                              staticClass: "search-wrapper-input",
                              attrs: {
                                size: "small",
                                placeholder: "姓名\\手机",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleSearch($event)
                                },
                              },
                              model: {
                                value: _vm.search.textSearch,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.search,
                                    "textSearch",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "search.textSearch",
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "greens-btn",
                                attrs: { size: "small" },
                                on: { click: _vm.handleSearch },
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "btnHollowReset",
                                attrs: { size: "small" },
                                on: { click: _vm.handleReset },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.tabText === "sales"
            ? _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "tips" }, [
                      _vm._v(
                        " 销售团队：可选择销售经理和置业顾问两种岗位下的账号加入团队。销售经理可以查看同一团队内所有置业顾问的客户，置业顾问只能查看自己名下的客户。 "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block-bottom mT16" },
        [
          _vm.tabText === "staff"
            ? _c("staff-list", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "admins/list"],
                    expression: "['admin', 'admins/list']",
                  },
                ],
                ref: "staff",
                attrs: { search: _vm.search, "table-height": _vm.tableHeight },
              })
            : _vm._e(),
          _vm.tabText === "post"
            ? _c("post-list", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "role/list"],
                    expression: "['admin', 'role/list']",
                  },
                ],
                attrs: { "table-height": _vm.tableHeight },
              })
            : _vm._e(),
          _vm.tabText === "sales"
            ? _c("sales-team", {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "team/list"],
                    expression: "['admin', 'team/list']",
                  },
                ],
                attrs: { "table-height": _vm.tableHeight },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }