//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { pingPong, pingPongreset, setLottery as _setLottery } from '@/api/formulaLottery';
import imgUp from '@/views/editFormat/components/imgUp';
export default {
  name: 'formulaLotteryList',
  components: {
    imgUp: imgUp
  },
  data: function data() {
    return {
      info: {},
      rand: {},
      setLotteryShow: false,
      seParams: {
        roll_speed: 1,
        entry_num: 6,
        rand_name: '公示标题',
        logo: '',
        sequence: 0
      },
      rules: {
        entry_num: [{
          required: true,
          message: '请输入号码位数',
          trigger: 'blur'
        }],
        rand_name: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        roll_speed: [{
          required: true,
          message: '请输入滚动速度',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getpingPong();
  },
  methods: {
    getpingPong: function getpingPong() {
      var _this = this;

      pingPong().then(function (res) {
        console.log(res.data);
        var _res$data = res.data,
            data = _res$data.data,
            rand = _res$data.data.rand;
        _this.info = data;
        _this.rand = rand;
        _this.seParams.entry_num = rand.entry_num;
        _this.seParams.rand_name = rand.rand_name;
        _this.seParams.roll_speed = rand.roll_speed;
        _this.seParams.sequence = rand.sequence;
      });
    },
    openLotteryDialog: function openLotteryDialog() {
      this.setLotteryShow = true;
    },
    setLottery: function setLottery(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _setLottery(_this2.seParams).then(function (res) {
            _this2.$message({
              type: 'success',
              message: res.data.msg
            });

            _this2.getpingPong();

            _this2.setLotteryShow = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    upSuccess: function upSuccess(e) {
      this.seParams.logo = e.Data.url;
    },
    //摇号记录
    toRecord: function toRecord() {
      this.$router.push({
        name: 'formulaLotteryRecord'
      });
    },
    // 重置摇号
    resetting: function resetting() {
      var _this3 = this;

      this.$confirm('重置会清除摇号的记录，但不会改变摇号规则。', '提醒', {
        confirmButtonText: '继续重置',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(pingPongreset);
        pingPongreset().then(function (res) {
          if (res.status === 200) {
            _this3.getpingPong();

            _this3.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {});
    },
    getText: function getText() {
      var text2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var row = arguments.length > 1 ? arguments[1] : undefined;

      if (row.is_count > 0 && row.all_count !== row.is_count) {
        return text2 ? '摇号中' : '继续摇号';
      } else if (row.is_count > 0 && row.is_count === row.all_count) {
        return '摇号结束';
      } else {
        return text2 ? '待摇号' : '开始摇号';
      }
    },
    handleStartLottery: function handleStartLottery() {
      // 开始摇号
      // formulaStartLootery
      var _this$$router$resolve = this.$router.resolve({
        name: 'formulaStartLootery',
        query: {}
      }),
          href = _this$$router$resolve.href;

      window.open(href, '_blank');
    },
    toPublicity: function toPublicity() {
      var _this$$router$resolve2 = this.$router.resolve({
        name: 'formulaPublicity',
        query: {
          roll_speed: this.rand.roll_speed
        }
      }),
          href = _this$$router$resolve2.href;

      window.open(href, '_blank');
    }
  }
};