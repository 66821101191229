import { render, staticRenderFns } from "./rightPage.vue?vue&type=template&id=05dc8002&scoped=true&"
import script from "./rightPage.vue?vue&type=script&lang=js&"
export * from "./rightPage.vue?vue&type=script&lang=js&"
import style0 from "./rightPage.vue?vue&type=style&index=0&id=05dc8002&scoped=true&lang=scss&"
import style1 from "./rightPage.vue?vue&type=style&index=1&id=05dc8002&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05dc8002",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_24/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05dc8002')) {
      api.createRecord('05dc8002', component.options)
    } else {
      api.reload('05dc8002', component.options)
    }
    module.hot.accept("./rightPage.vue?vue&type=template&id=05dc8002&scoped=true&", function () {
      api.rerender('05dc8002', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/plane/components/rightPage.vue"
export default component.exports