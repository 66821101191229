var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container qrCodeGeneration" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("码牌生成器")]),
        _c("qrList", {
          attrs: {
            state: 1,
            "item-size": _vm.qrTypeSize,
            list: _vm.qrTypeList,
          },
        }),
        _c("div", { staticClass: "bottom_bar" }, [
          _c(
            "div",
            {
              staticClass: "download_btn",
              on: {
                click: function ($event) {
                  return _vm.showMask(2)
                },
              },
            },
            [_vm._v("下载码牌")]
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "item title" }, [_vm._v("调整")]),
      _c("div", { staticClass: "item edit" }, [
        _c("div", { staticClass: "edit_item" }, [
          _c("span", { staticClass: "name" }, [_vm._v("码牌形状")]),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.option.bgstyle,
                    callback: function ($$v) {
                      _vm.$set(_vm.option, "bgstyle", $$v)
                    },
                    expression: "option.bgstyle",
                  },
                },
                [_vm._v(" 圆形 ")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.option.bgstyle,
                    callback: function ($$v) {
                      _vm.$set(_vm.option, "bgstyle", $$v)
                    },
                    expression: "option.bgstyle",
                  },
                },
                [_vm._v(" 方形 ")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "edit_item" }, [
          _c("span", { staticClass: "name" }, [_vm._v("二维码占比大小")]),
          _c("div", { staticClass: "options" }, [
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qrs", "+")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-plus" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qrs", "-")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-minus" })]
            ),
          ]),
        ]),
        _c("div", { staticClass: "edit_item" }, [
          _c("span", { staticClass: "name" }, [_vm._v("二维码位置")]),
          _c("div", { staticClass: "options postion" }, [
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qry", "-")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-top" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qrx", "+")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-right" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qry", "+")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-bottom" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("qrx", "-")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-back" })]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "bg_list" },
          [
            _vm._l(_vm.qrTypeList, function (item, i) {
              return [
                item.bg
                  ? _c("img", {
                      key: i,
                      attrs: {
                        id: item.bg_img_id,
                        crossorigin: "Anonymous",
                        src:
                          "" + _vm.imgUrl + item.bg + "?time=" + _vm.timestamp,
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c("div", { staticClass: "edit_item" }, [
          _c("span", { staticClass: "name" }, [_vm._v("编号位置")]),
          _c("div", { staticClass: "options postion" }, [
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("fonty", "-")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-top" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("fontx", "+")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-right" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("fonty", "+")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-bottom" })]
            ),
            _c(
              "div",
              {
                staticClass: "o_btn",
                on: {
                  click: function ($event) {
                    return _vm.changeOption("fontx", "-")
                  },
                },
              },
              [_c("i", { staticClass: "icon el-icon-back" })]
            ),
          ]),
        ]),
        _c("div", { staticClass: "edit_item" }, [
          _c("span", { staticClass: "name" }, [_vm._v("编号颜色")]),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c("el-color-picker", {
                attrs: { "show-alpha": "", size: "mini" },
                model: {
                  value: _vm.option.fontc,
                  callback: function ($$v) {
                    _vm.$set(_vm.option, "fontc", $$v)
                  },
                  expression: "option.fontc",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "item bottom_option" }, [
        _c(
          "div",
          { staticClass: "btn s_btn", on: { click: _vm.setDefaultOption } },
          [_vm._v("重置为默认值")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "bg_list" },
      [
        _vm._l(_vm.qrTypeList, function (item, i) {
          return [
            item.bg
              ? _c("img", {
                  key: i,
                  attrs: {
                    id: item.bg_img_id,
                    crossorigin: "anonymous",
                    src: _vm.imgUrl + item.bg,
                  },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _vm.maskType
      ? _c("div", { staticClass: "mask" }, [
          _vm.maskType == 1
            ? _c(
                "div",
                { staticClass: "card show_type_img_card" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("预览码牌")]),
                  _c("qrList", {
                    attrs: {
                      state: 3,
                      "item-size": _vm.showTypeSize,
                      list: _vm.showTypeList,
                    },
                  }),
                  _c("div", { staticClass: "bottom_option" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn s_btn",
                        on: { click: _vm.closeMask },
                      },
                      [_vm._v("关闭")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.maskType == 2
            ? _c(
                "div",
                { staticClass: "card down_card" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("下载码牌")]),
                  _c("div", { staticClass: "s_title" }, [
                    _vm._v("选择背景图*"),
                  ]),
                  _c("qrList", {
                    attrs: {
                      state: 2,
                      "item-size": _vm.downShowSize,
                      list: _vm.qrTypeList,
                    },
                  }),
                  _c("div", { staticClass: "s_title" }, [
                    _vm._v("各类背景图下载数量*"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input_list" },
                    [
                      _vm._l(_vm.qrTypeList, function (item, i) {
                        return [
                          item.choose
                            ? _c(
                                "div",
                                { key: i, staticClass: "item" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.name) + "："),
                                  ]),
                                  _c("el-input-number", {
                                    staticClass: "input",
                                    attrs: {
                                      "controls-position": "right",
                                      step: 100,
                                      min: 0,
                                      max: 5000,
                                    },
                                    model: {
                                      value: item.create_num,
                                      callback: function ($$v) {
                                        _vm.$set(item, "create_num", $$v)
                                      },
                                      expression: "item.create_num",
                                    },
                                  }),
                                  _c("span", [_vm._v("张")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "bottom_option" }, [
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.closeMask } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn s_btn",
                        on: {
                          click: function ($event) {
                            return _vm.showMask(3)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.maskType == 3
            ? _c(
                "div",
                { staticClass: "card load_card" },
                [
                  _vm.renderNum
                    ? [
                        _c("el-progress", {
                          attrs: {
                            type: "circle",
                            percentage: _vm.getPercentage(
                              _vm.renderNum,
                              _vm.renderLength
                            ),
                          },
                        }),
                        _c("div", { staticClass: "tips" }, [
                          _vm._v("码牌下载中，请不要离开本页面"),
                        ]),
                      ]
                    : [
                        _c("i", { staticClass: "icon el-icon-loading" }),
                        _c("div", { staticClass: "tips" }, [
                          _vm._v("正在准备下载文件，请不要离开本页面"),
                        ]),
                      ],
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }