import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
import Api from '../../api/activityData';
import { adminParameter } from '@/api/public';
import { getOrderDetail, editPayInfo, editYouhuiType, smsOrder } from '@/api/houseHistory';
import printingInfoDialog from '@/components/printingInfoDialog/printingInfoDialog.vue';
export default {
  components: {
    printingInfoDialog: printingInfoDialog
  },
  data: function data() {
    return {
      formatDate: utils.formatDate,
      isPC: utils.isPC(),
      yhuiDialog: false,
      you_hui_id: '',
      printdialog: false,
      payParams: {},
      //参数
      pay_info: [],
      //
      signStatus: '',
      //签约状态
      adminList: {},
      mo: {},
      you_hui_rule: {},
      user: {},
      order: {},
      order_pay_info: '',
      order_detail: '',
      room: {},
      sp: '',
      is_price: 0,
      //是否开启交定金
      projectList: {},
      matching_order: 0,
      // 0不开启 1开启
      sign_up_rand: '',
      // 是否开启 签约排号摇号
      help: 0,
      //是否开启核控
      sign_up: 0,
      //签约状态
      you_hui_type: '',
      //优惠类型
      order_by_stages: '',
      //分期详情(期数)
      pricewayName: '' //外显价格名称

    };
  },
  computed: {
    showElse: function showElse() {
      var is = this.help && this.is_price && this.sign_up && this.adminList.sign_up_type !== 1 && this.sign_up;
      return is;
    }
  },
  created: function created() {
    this.getAdminList();

    if (this.$route.query.id) {
      this.signStatus = this.$route.query.signStatus; // this.getDetails(Number(this.$route.query.id))
    }
  },
  mounted: function mounted() {},
  methods: {
    syncOrder: function syncOrder() {
      smsOrder({
        id: Number(this.$route.query.id)
      }).then(function (res) {
        console.log(res);
      });
    },
    openPayEedit: function openPayEedit() {
      //编辑付款方式
      this.printdialog = true;
    },
    doalogClose: function doalogClose(val) {
      this.printdialog = val;
    },
    handPayEdit: function handPayEdit(parameter) {
      var _this = this;

      //确认修改付款方式
      var params = _objectSpread(_objectSpread({}, parameter), {}, {
        id: Number(this.$route.query.id)
      });

      editPayInfo(params).then(function (res) {
        _this.getDetails();

        _this.doalogClose(false);

        _this.$message({
          type: 'success',
          message: '修改成功'
        });
      });
    },
    selectYuihui: function selectYuihui(val) {
      if (val) {
        var yh = this.you_hui_rule.find(function (item) {
          return item.id == val;
        });
        this.payParams.you_hui_rule_id = val;

        if (yh.way === 0) {
          this.payParams.you_hui_price = this.room.old_total_price - yh.derate;
        } else {
          this.payParams.you_hui_price = this.room.old_total_price - Math.round(this.room.old_total_price * (yh.discount / 100));
        }

        this.countMoney(this.payParams.you_hui_price);
      }
    },
    // 付款方式金额计算
    countMoney: function countMoney(orderMoney) {
      var _this2 = this;

      if (this.payParams.pay_type != 0 && this.payParams.pay_type != 1) {
        //分期
        this.payParams.pay_by_stages.map(function (item) {
          var created_micro_time = _this2.formatDate(new Date(_this2.order.created_micro_time), 'yyyy-MM-dd');

          item.reservation_date = _this2.getEndDate(created_micro_time, item.reservation_time);
          item.pay_price = Math.floor(orderMoney * (item.pay_proportion / 100) * 100) / 100;
        });
      } else if (this.payParams.pay_type == 1) {
        this.payParams.pay_down_price = Math.floor(orderMoney * (this.payParams.pay_proportion / 100) * 100) / 100;
      }

      if (this.payParams.pay_type != 2) {
        var created_micro_time = this.formatDate(new Date(this.order.created_micro_time), 'yyyy-MM-dd');
        this.payParams.reservation_date = this.getEndDate(created_micro_time, this.payParams.reservation_time);
      }
    },
    // 传年月日 和几天计算几天后的日期
    getEndDate: function getEndDate(date, day) {
      var d = new Date(date);
      d.setDate(d.getDate() + day);
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    hanYuohuiEdit: function hanYuohuiEdit() {
      var _this3 = this;

      //修改优惠类型
      this.selectYuihui(this.you_hui_id);
      editYouhuiType(this.payParams).then(function () {
        _this3.getDetails();

        _this3.closeYuihuiDialog();

        _this3.$message({
          type: 'success',
          message: '修改成功'
        });
      });
    },
    openYhuilEdit: function openYhuilEdit() {
      this.yhuiDialog = true;
    },
    closeYuihuiDialog: function closeYuihuiDialog() {
      this.yhuiDialog = false;
    },
    // 获取置业顾问
    getAdminList: function getAdminList() {
      var _this4 = this;

      // 获取公共权限
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              help = _res$data.help,
              matching_order = _res$data.matching_order,
              event = _res$data.event,
              pay_info = _res$data.pay_info,
              sp = _res$data.sp,
              you_hui_rule = _res$data.you_hui_rule;
          _this4.adminList = list;
          var orderPriceName = '';

          switch (parseInt(list.order_price)) {
            case 1:
              orderPriceName = list.room_info_pin_yi_ci_jia;
              break;

            case 2:
              orderPriceName = list.room_info_pin_an_jie_jia;
              break;

            case 3:
              orderPriceName = list.room_info_pin_yuan_jia;
              break;

            case 4:
              orderPriceName = list.room_info_pin_you_hui_jia;
              break;

            case 5:
              orderPriceName = list.room_info_pin_biao_zhun_jia;
              break;

            default:
              console.log('未知的价格类型！');
              break;
          }

          _this4.pricewayName = orderPriceName;
          _this4.matching_order = matching_order;
          _this4.you_hui_rule = you_hui_rule;
          _this4.pay_info = pay_info || [];
          _this4.sp = sp; // 是否显示签约 相关

          _this4.sign_up = list.is_display.includes('sign_up'); //是否显示订单相关

          _this4.is_price = list.is_display.includes('price'); // 核控

          _this4.help = help;

          _this4.getDetails();
        }
      });
    },
    getDetails: function getDetails(id) {
      var _this5 = this;

      getOrderDetail({
        id: id || Number(this.$route.query.id)
      }).then(function (res) {
        console.log(res.data);
        var _res$data$data = res.data.data,
            mo = _res$data$data.mo,
            order = _res$data$data.order,
            order_detail = _res$data$data.order_detail,
            _res$data$data$pay = _res$data$data.pay,
            order_pay_info = _res$data$data$pay.order_pay_info,
            pay_info = _res$data$data$pay.pay_info,
            order_by_stages = _res$data$data$pay.order_by_stages,
            room = _res$data$data.room,
            user = _res$data$data.user,
            sign = _res$data$data.sign,
            yh = _res$data$data.yh;
        _this5.mo = mo;
        _this5.user = user;
        _this5.order = order;
        _this5.room = room;
        _this5.order_pay_info = order_pay_info;
        _this5.order_detail = order_detail;
        _this5.order_by_stages = order_by_stages;

        if (_this5.sp.pin_you_hui) {
          var _this5$you_hui_rule$f;

          //优惠类型
          _this5.you_hui_type = ((_this5$you_hui_rule$f = _this5.you_hui_rule.find(function (item) {
            return item.id == order.you_hui_rule_id;
          })) === null || _this5$you_hui_rule$f === void 0 ? void 0 : _this5$you_hui_rule$f.type_name) || '--';
          _this5.you_hui_id = order.you_hui_rule_id;
          _this5.payParams.id = id || Number(_this5.$route.query.id); //订单id,

          if (_this5.sp.pay_info_set_time != 0) {
            var pay = _this5.pay_info.find(function (item) {
              return item.id == order.pay_info_id;
            }) || '';
            _this5.payParams = {
              id: id || Number(_this5.$route.query.id),
              pay_info_id: order.pay_info_id,
              pay_type: pay.type
            };

            if (pay.type == 0) {
              _this5.payParams.reservation_time = order.reservation_time;
            } else if (pay.type == 1) {
              _this5.payParams.pay_proportion = order.pay_proportion;
              _this5.payParams.reservation_time = order.reservation_time;
              _this5.payParams.pay_down_price = order.pay_down_price;
              _this5.payParams.pay_price = order.pay_price;
            } else {
              _this5.payParams.pay_by_stages = order_by_stages;
            }
          }
        }
      });
    },
    helpStatus: function helpStatus(val) {
      var str = '';

      switch (val) {
        case -3:
          str = '已过期';
          break;

        case -2:
          str = '审核驳回';
          break;

        case -1:
          str = '已撤销';
          break;

        case 0:
          str = '未发起审核';
          break;

        case 1:
          str = '待审核';
          break;

        case 2:
          str = '审核通过';
          break;

        default:
          str = '--';
      }

      return str;
    }
  }
};