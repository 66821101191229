var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.issvg
        ? _c("svg-icon", {
            attrs: {
              "class-name": "full",
              "icon-class": _vm.isFullscreen ? "exit-fullscreen" : "fullscreen",
            },
            on: { click: _vm.click },
          })
        : _c("img", {
            staticClass: "imgFull",
            attrs: { src: require("../../assets/image//qpImg.png") },
            on: { click: _vm.click },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }