import request from '@/utils/request';
export default {
  // 查询规则
  enteryIndex: function enteryIndex(params) {
    return request({
      url: 'entry/index',
      method: 'GET',
      params: params
    });
  },
  // 配置规则
  setRand: function setRand(data) {
    return request({
      url: 'entry/setRand',
      method: 'POST',
      data: data
    });
  },
  // 确认生成
  generate: function generate(data) {
    return request({
      url: 'entry/generate',
      method: 'POST',
      data: data
    });
  },
  // 检查重复
  getRepeat: function getRepeat(data) {
    return request({
      url: 'entry/getRepeat',
      method: 'POST',
      data: data
    });
  },
  // 获取最新一轮摇号结果 （大屏和主页刷新后展示通用）
  getLast: function getLast(params) {
    return request({
      url: 'entry/getLast',
      method: 'GET',
      params: params
    });
  },
  // 公示大屏
  publicity: function publicity(data) {
    return request({
      url: 'entry/publicity',
      method: 'POST',
      data: data
    });
  },
  // 重置摇号
  reset: function reset(params) {
    return request({
      url: 'entry/reset',
      method: 'GET',
      params: params
    });
  },
  // 生成websocket 内容
  webSoket: function webSoket(data) {
    return request({
      url: '',
      method: 'POST',
      data: data
    });
  },
  // 摇号记录
  list: function list(data) {
    return request({
      url: 'entry/list',
      method: 'POST',
      data: data
    });
  },
  // 导出摇号记录
  export: function _export(data) {
    return request({
      url: 'entry/export',
      method: 'POST',
      data: data
    });
  },
  // 生成页获取最后一条记录 （注意只是生成页用）
  indexLast: function indexLast(params) {
    return request({
      url: 'entry/indexLast',
      method: 'GET',
      params: params
    });
  },
  // 版本列表
  editionList: function editionList(data) {
    return request({
      url: 'entry/edition',
      method: 'POST',
      data: data
    });
  },
  // 删除版本
  delEdition: function delEdition(data) {
    return request({
      url: 'entry/delEdition',
      method: 'POST',
      data: data
    });
  },
  // 锁定版本
  locking: function locking(data) {
    return request({
      url: 'entry/locking',
      method: 'POST',
      data: data
    });
  },
  // 锁定版本
  unlock: function unlock(data) {
    return request({
      url: 'entry/unlock',
      method: 'POST',
      data: data
    });
  },
  // 清除版本数据
  eliminate: function eliminate(data) {
    return request({
      url: 'entry/eliminate',
      method: 'POST',
      data: data
    });
  }
};