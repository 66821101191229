import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Api from '@/api/staff';
import UploadExcel from '@/components/my-UploadExcel';
import { debounce } from '@/utils/debounce';
import moment from 'moment';
import utils from '@/utils/utils';
import { getProject, getProjectName, getpName } from '@/utils/auth';
export default {
  name: 'Staff',
  components: {
    UploadExcel: UploadExcel
  },
  props: ['tableHeight'],
  data: function data() {
    // 电话号码验证
    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        var reg = /^1\d{10}$/;

        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    }; // 姓名校验


    var checkName = function checkName(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入姓名'));
      } else if (value.length > 20) {
        return callback(new Error('姓名长度不能超过20个字'));
      } else {
        callback();
      }
    };

    return {
      // 操作按钮
      is_button: false,
      // 是否手机端
      isPC: utils.isPC(),
      // 分页状态
      telLayout: 'total, prev, pager, next, sizes',
      // 新增修改数据
      form: {
        is_send: 0,
        role: [],
        remarks: '',
        fid: ''
      },
      // 表单验证
      rules: {
        name: [{
          required: true,
          trigger: 'blur',
          validator: checkName
        }],
        tel: [{
          validator: checkPhone,
          trigger: 'blur',
          required: true
        }],
        role: [{
          required: true,
          message: '请选择岗位',
          trigger: 'change'
        }]
      },
      // 新增人员 编辑人员弹框
      dialogStaff: false,
      // 弹框name
      editAddText: '新增人员',
      input: '',
      value: true,
      value1: [],
      tableData: [],
      // 表格loading
      tableLoading: false,
      // 每页固定多少数据
      pageSize: utils.isPC() ? 10 : 1000,
      // 切换到当前页
      page: 1,
      // 列表总数
      total: 0,
      // 岗位列表
      roleList: [],
      // 列表搜索
      info: {
        page: 1,
        limit: utils.isPC() ? 10 : 1000
      },
      // 删除 id集
      delArray: [],
      numbers: 0
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  // 初始化
  created: function created() {
    if (this.roles.includes('admin') || this.roles.includes('admins/list')) {
      console.log(this.info);
      this.getListData(this.info);
    }
  },
  methods: {
    // 同步置业顾问
    syanGw: function syanGw() {
      var _this = this;

      Api.syncJf().then(function (res) {
        var _res$data$info$return, _res$data$info, _res$data$info$return2;

        _this.$message.success((_res$data$info$return = (_res$data$info = res.data.info) === null || _res$data$info === void 0 ? void 0 : (_res$data$info$return2 = _res$data$info.returnData) === null || _res$data$info$return2 === void 0 ? void 0 : _res$data$info$return2.msg) !== null && _res$data$info$return !== void 0 ? _res$data$info$return : '同步成功');
      });
    },
    handleResetPasswords: function handleResetPasswords() {
      var _this2 = this;

      if (this.delArray.length === 0) {
        this.$message({
          type: 'warning',
          message: '选中数据再进行操作'
        });
        return;
      }

      this.$confirm('是否确认重置密码并发送密码？', '提醒', {
        confirmButtonText: '确定重置',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Api.postAdminSend({
          ids: _this2.delArray
        }).then(function (res) {
          if (res.status === 200) {
            _this2.$message({
              type: 'success',
              message: res.data.msg
            });

            _this2.getListData();
          }
        });
      }).catch(function () {});
    },
    // 导出人员
    getAdminExport: function getAdminExport() {
      var _this3 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      delete this.info.page;
      delete this.info.limit;
      Api.getAdminExport(this.info).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.Data


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      }).catch(function (err) {
        loading.close();
      });
    },
    // 批量删除
    handleBatchDel: function handleBatchDel() {
      var _this4 = this;

      if (this.delArray.length === 0) {
        this.$message({
          type: 'warning',
          message: '选中数据再进行操作'
        });
        return;
      }

      this.$confirm('是否确认删除此人员？', '提醒', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Api.batchDel({
          ids: _this4.delArray
        }).then(function (res) {
          if (res.status === 200) {
            _this4.$message({
              type: 'success',
              message: res.data.msg
            });

            _this4.getListData();
          }
        });
      }).catch(function () {});
    },
    // 获取table 选中
    handleSelectionChange: function handleSelectionChange(row) {
      var _this5 = this;

      var array = [];
      row.forEach(function (item) {
        if (item.led || item.is_saas) _this5.$refs.personnel.toggleRowSelection(item, false);else array.push(item.id);
      });
      this.delArray = array;
      this.numbers = array.length;
    },
    // 下载模板
    handleDown: debounce(function () {
      var url = this.host + '/template/admin/3.云销控-工作人员导入模板.xlsx';
      utils.download_files(url, "".concat(getpName(), "-\u5458\u5DE5\u8D26\u53F7\u5BFC\u5165-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
        showMessage: true
      });
    }, 200, true),
    // 修改人员状态
    handleStatus: debounce(function (row) {
      var _this6 = this;

      Api.adminStatus({
        id: row.id,
        status: row.status
      }).then(function (res) {
        if (res.status === 200) {
          _this6.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    }, 200, true),
    // 搜索事件 清空重置
    handleSearch: debounce(function (data) {
      var info = {
        search: data.textSearch,
        null_role: data.null_role,
        null_team: data.null_team,
        page: 1,
        limit: this.info.limit,
        is_online: data.is_online
      };
      this.page = 1;
      this.info = info;
      this.getListData(info);
    }, 200, true),
    // 修改人员
    handleEdit: debounce(function (row) {
      this.getAdminInfo(row.id);
      this.editAddText = '修改人员';
      this.dialogStaff = true;
    }, 200, true),
    // 获取新增修改数据
    getAdminInfo: function getAdminInfo(id) {
      var _this7 = this;

      Api.adminInfo({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              info = _res$data.info,
              roleList = _res$data.roleList;
          _this7.roleList = roleList; // 修改

          if (id !== '') {
            _this7.form = info;
          }
        }
      });
    },
    // 新增修改提交数据
    handleAddEdit: debounce(function () {
      var _this8 = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          // 关闭弹框
          _this8.dialogStaff = false;

          if (_this8.editAddText === '修改人员') {
            // 修改
            Api.adminEdit(_this8.form).then(function (res) {
              if (res.status === 200) {
                _this8.$message({
                  type: 'success',
                  message: res.data.msg
                });

                _this8.getListData(_this8.info); // 清空


                _this8.$refs['ruleForm'].resetFields();
              }
            });
          } else {
            // 新增
            Api.adminAdd(_this8.form).then(function (res) {
              if (res.status === 200) {
                _this8.$message({
                  type: 'success',
                  message: res.data.msg
                });

                _this8.getListData(_this8.info); // 清空


                _this8.$refs['ruleForm'].resetFields();
              }
            });
          }
        } else {
          _this8.$message({
            type: 'error',
            message: '请完善内容'
          });
        }
      });
    }, 200, true),
    // 删除 用户
    handleDel: function handleDel(row) {
      var _this9 = this;

      this.$confirm('是否确认删除此人员？', '提醒', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Api.adminDel({
          id: row.id
        }).then(function (res) {
          if (res.status === 200) {
            _this9.$message({
              type: 'success',
              message: res.data.msg
            });

            _this9.getListData();
          }
        });
      }).catch(function () {});
    },
    // 获取人员列表
    getListData: function getListData(data) {
      var _this10 = this;

      this.tableLoading = true;
      Api.adminList(data).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              memberList = _res$data2.memberList,
              count = _res$data2.count;
          var numberRegx = /^\d+$/gi;
          memberList === null || memberList === void 0 ? void 0 : memberList.map(function (m, i) {
            var _m$name;

            // 纯数字code 或者 名称带LED
            m.led = numberRegx.test(m.code) || ((_m$name = m.name) === null || _m$name === void 0 ? void 0 : _m$name.toLocaleUpperCase().includes('LED'));
            console.log(m.led);
          });
          _this10.tableData = memberList || [];
          _this10.total = count;
        }
      }).finally(function () {
        _this10.tableLoading = false;
      });
    },
    // 重置密码
    handleResetPassword: function handleResetPassword(row) {
      var _this11 = this;

      this.$confirm('是否确认重置密码？', '重置密码', {
        confirmButtonText: '确定重置',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Api.adminReset({
          id: row.id
        }).then(function (res) {
          if (res.status === 200) {
            _this11.$message({
              type: 'success',
              message: res.data.msg
            });

            console.log(res.data.password);
            var isCance = res.data.password.indexOf('**') > -1 ? false : true;
            console.log(isCance); // 放回初始密码

            _this11.$confirm(res.data.password, '新密码', {
              confirmButtonText: '发送短信',
              cancelButtonText: '复制',
              showCancelButton: isCance,
              type: 'warning'
            }).then(function () {
              // 发送短信
              _this11.obtainSend(row.id);
            }).catch(function () {
              var dom = document.createElement('input');
              dom.value = res.data.password;
              document.body.appendChild(dom);
              dom.select();
              document.execCommand('copy');
              document.body.removeChild(dom);
            });
          }
        });
      }).catch(function () {});
    },
    // 发送短信
    obtainSend: function obtainSend(id) {
      var _this12 = this;

      // 发送短信
      Api.postAdminSend({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          _this12.$message({
            type: 'success',
            message: '成功：' + res.data.successCount + '条，失败：' + res.data.errCount + '条'
          });
        }
      });
    },
    //  初始密码
    handleInitialPassword: function handleInitialPassword() {
      var _this13 = this;

      Api.getAdminSend().then(function (res) {
        if (res.status === 200) {
          var count = res.data.count;

          _this13.$confirm("\u8FD8\u6709".concat(count, "\u4EBA\u672A\u53D1\u9001\u521D\u59CB\u5BC6\u7801\uFF0C\u662F\u5426\u786E\u8BA4\u53D1\u9001\uFF1F"), '发送初始密码', {
            confirmButtonText: '确定发送',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            // 发送短信
            _this13.obtainSend(0);
          }).catch(function () {});
        }
      });
    },
    //  打开 新增人员 编辑人员弹框
    handleAdd: function handleAdd() {
      this.getAdminInfo(''); // 清空

      if (this.editAddText === '修改人员') {
        this.$refs['ruleForm'].resetFields();
      } else {
        // 第一次打开
        this.form = {
          is_send: 0,
          role: [],
          tel: '',
          name: '',
          fid: ''
        };
      }

      this.editAddText = '新增人员';
      this.dialogStaff = true;
    },
    // 切换每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.info.limit = val;
      this.getListData(this.info);
    },
    // 切换页数
    handleCurrentChange: function handleCurrentChange(val) {
      this.info.page = val;
      this.getListData(this.info);
    }
  }
};