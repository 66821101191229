import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  filters: {
    formatZero: function formatZero(num) {
      var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      if (String(num).length >= len) return num;
      return (Array(len).join(0) + num).slice(-len);
    }
  },
  props: {
    content: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否改变样式 大屏右侧倒计时样式
    isChangeStyle: {
      type: Boolean,
      default: false
    },
    // 是否显示文字
    isShowText: {
      type: Boolean,
      default: true
    },
    // 字体颜色
    color: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formatZero: function formatZero(num) {
        var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
        if (String(num).length >= len) return num;
        return (Array(len).join(0) + num).slice(-len);
      }
    };
  }
};