import "core-js/modules/es.number.constructor.js";
export default {
  name: 'LinkName',
  // 环节名称
  functional: true,
  props: {
    tag: {
      default: 'span',
      type: String
    },
    show: {
      default: 0,
      type: Number
    },
    front: {
      default: '',
      type: String
    },
    after: {
      default: '',
      type: String
    }
  },
  render: function render(createElement, context) {
    var _context$parent$$stor2, _context$parent$$stor3, _context$parent$$stor4;

    var props = context.props;

    var _context$parent$$stor = (_context$parent$$stor2 = context.parent.$store) === null || _context$parent$$stor2 === void 0 ? void 0 : (_context$parent$$stor3 = _context$parent$$stor2.state) === null || _context$parent$$stor3 === void 0 ? void 0 : (_context$parent$$stor4 = _context$parent$$stor3.config) === null || _context$parent$$stor4 === void 0 ? void 0 : _context$parent$$stor4.audit,
        identify_sign_name = _context$parent$$stor.identify_sign_name,
        identify_name = _context$parent$$stor.identify_name,
        raising_funds_name = _context$parent$$stor.raising_funds_name,
        register_name = _context$parent$$stor.register_name,
        identify_price_name = _context$parent$$stor.identify_price_name;

    var showList = [register_name, identify_name, identify_price_name, identify_sign_name, raising_funds_name];
    return createElement(props.tag, props.front + showList[props.show] + props.after, context.children);
  }
};