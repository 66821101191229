import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import Watermark from '@/utils/watermark';
import { adminParameter } from '@/api/public';
var watermark = null;
export default {
  data: function data() {
    return {
      watermarkImage: '',
      // 水印图片
      setting: {
        list: {}
      }
    };
  },
  mounted: function mounted() {
    watermark = new Watermark(document.createElement('canvas'), '摇号演示  结果无效');
    this.getSetting();
  },
  destroyed: function destroyed() {
    // 销毁 避免多重水印
    watermark.destroy();
    watermark = null;
  },
  methods: {
    // 绘制水印
    darwWater: function darwWater() {
      var _this = this;

      var key = localStorage.getItem(parseInt(new Date().getTime() / 86400000).toString()).split('-'); // 活动不为正式

      if (key[1] != 1) {
        var _this$setting, _this$setting$list;

        // 开启测试
        if (((_this$setting = this.setting) === null || _this$setting === void 0 ? void 0 : (_this$setting$list = _this$setting.list) === null || _this$setting$list === void 0 ? void 0 : _this$setting$list.is_test_rand_user) === 1) {
          watermark.darw().then(function (url) {
            _this.watermarkImage = url;
            watermark.darwBody(url); // this.darwWater();
          }); // 屏幕大小变化重绘

          window.onresize = function () {
            watermark.darwBody(_this.watermarkImage);
          };
        }
      }
    },
    // 获取项目设置
    getSetting: function getSetting() {
      var _this2 = this;

      adminParameter().then(function (res) {
        _this2.setting = res.data;

        _this2.darwWater();
      });
    }
  }
};