import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
export default {
  name: 'LogView',
  props: {
    log: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    name: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isPC: utils.isPC(),
      is_null: true,
      // 表格搜索 数据
      form: {
        key_word: ''
      },
      // 日期选择器
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      page: 1,
      isPageLoad: true
    };
  },
  methods: {
    // 查询
    searchClick: function searchClick() {
      var data = {};

      if (this.form.timeVal && this.form.timeVal.length > 0) {
        data.end_time = this.form.timeVal.length > 0 ? (this.form.timeVal[1] / 1000).toFixed() : 0;
        data.start_time = this.form.timeVal.length > 0 ? (this.form.timeVal[0] / 1000).toFixed() : 0;
      }

      data.key_word = this.form.key_word;
      data.page = 1;
      data.limit = 100;
      this.$emit('handleSearchClick', data); // this.$emit('searchClick',data);
    },
    // 重置
    resetClick: function resetClick() {
      this.form = {};
      this.$emit('handleSearchClick', {});
    },
    // 日志滑动到底部
    onPageLoad: function onPageLoad() {
      if (!this.isPageLoad) {
        this.page += 1;
        this.isPageLoad = true;
        var data = {};

        if (this.form.timeVal && this.form.timeVal.length > 0) {
          data.end_time = this.form.timeVal.length > 0 ? (this.form.timeVal[1] / 1000).toFixed() : 0;
          data.start_time = this.form.timeVal.length > 0 ? (this.form.timeVal[0] / 1000).toFixed() : 0;
        }

        data.key_word = this.form.key_word;
        data.page = this.page;
        data.limit = 100;
        this.$emit('handleSearchClick', data);
      }
    }
  }
};