var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "item-box",
        { "item-box-opcity": _vm.editFormatList.card_background_color == 2 },
      ],
      style: {
        "--nFont": _vm.editFormatList.card_info_code_size,
        "--nColor": _vm.editFormatList.card_info_code_color,
        "--nPosition": _vm.editFormatList.card_info_code_position,
        "--bPosition":
          _vm.editFormatList.host_json.card_info_code_positionButtom,
        "--uFont": _vm.editFormatList.card_info_user_size,
        "--uColor": _vm.editFormatList.card_info_user_color,
        "--uGap": _vm.editFormatList.card_info_user_spacing,
      },
    },
    [
      _c(
        "div",
        { style: _vm.boxContentStyle },
        [
          _vm.isShowNum
            ? _c(
                "span",
                {
                  staticClass: "mes num",
                  style: {
                    width: _vm.editFormatList.host_json.num_width + "%",
                  },
                },
                [_vm._v(_vm._s(_vm.num))]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }