import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";

/**
 * @Author: boyyang
 * @Date: 2021-12-24 11:45:55
 * @LastEditTime: 2021-12-29 15:16:22
 * @LastEditors: boyyang
 * @Description: 摇号 设置版式
 * @FilePath: \yun-xk-admin\src\views\editFormat\editFormatMixin.js
 */
import { mapState, mapMutations } from 'vuex';
import { getProject } from '@/utils/auth';
import projectTitle from './components/projectTitle.vue';
import projectRounds from './components/projectRounds.vue';
import projectBottom from './components/projectBottom.vue';
import projectContent from './components/projectContent.vue';
import itemBox from './components/itemBox.vue';
import roundsBtn from './components/roundsBtn.vue';
import { getRoundStyle, getRowStyle, getRound } from '@/api/projectRound';
import { unit } from './handleStyle';
export default {
  components: {
    projectTitle: projectTitle,
    projectRounds: projectRounds,
    projectBottom: projectBottom,
    projectContent: projectContent,
    itemBox: itemBox,
    roundsBtn: roundsBtn
  },
  data: function data() {
    return {
      // content 高度
      tbHeight: 0,
      // 定时器
      timer: null
    };
  },
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  })),
  mounted: function mounted() {
    this.getAllStyle();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('editFormat', ['SET_LIST'])), {}, {
    /**
     * @function getSequenceNumber 获取顺序号
     * @param {number} index 当前摇号下标
     * @param {number} roundNumber 轮
     */
    getSequenceNumber: function getSequenceNumber() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var roundNumber = arguments.length > 1 ? arguments[1] : undefined;
      var num = index; // 继承轮顺序号

      if (this.rand.type_round_code === 1) {
        var _roundNumber;

        // 避免错误数据
        roundNumber = ((_roundNumber = roundNumber) !== null && _roundNumber !== void 0 ? _roundNumber : 1) - 1;
        num = roundNumber * this.rand.rand_count + index;
      } // 继承分组顺序号 && 随机 && 分组


      if (this.rand.type_group_code === 1 && this.rand.type === 1 && this.rand.type_group === 1) {
        num += this.list.max_code;
      } // 开启顺序号ABC样式


      if (this.rand.code_style === 1) {
        //  将顺序号转换成ABC
        num = this.getExcelColumn(num);
      } else {
        num = "".concat(this.rand.code_start_name || '第').concat(num).concat(this.rand.code_unit);
      }

      return num;
    },
    // 获取类Excel列表
    getExcelColumn: function getExcelColumn(columnNumber) {
      var column = '';

      while (columnNumber > 0) {
        columnNumber--;
        var remainder = columnNumber % 26;
        column = String.fromCharCode(65 + remainder) + column;
        columnNumber = Math.floor(columnNumber / 26);
      }

      return column;
    },
    getAllStyle: function getAllStyle() {
      var _this = this;

      var type = this.$route.query.type;

      if (type && type == 'row') {
        getRowStyle().then(function (res) {
          var list = res.data.list; // 每一页多少个item

          var itemNum = list.row.rand_count; // 每一行多少个item

          var itemRow = list.card_row_num; // 纵向个数

          var columnNum = Math.ceil(itemNum / itemRow); // 纵向个数

          list.columnNum = columnNum;

          _this.SET_LIST(list);

          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + list.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center',
            height: '100%'
          };
        });
      } else {
        getRoundStyle().then(function (res) {
          var list = res.data.list; // 每一页多少个item

          var itemNum = list.rand.rand_count; // 每一行多少个item

          var itemRow = list.card_row_num; // 纵向个数

          var columnNum = Math.ceil(itemNum / itemRow); // 纵向个数

          list.columnNum = columnNum;
          var sub_screenDatas = list.sub_json ? JSON.parse(list.sub_json) : [];
          var sub_screenHeight = 0;

          if (list.rand.is_sub_screen === 1) {
            for (var i = 0; i < sub_screenDatas.length; i++) {
              if (!sub_screenDatas[i].other_remark) {
                sub_screenDatas[i].other_remark = {
                  fontSize: 16,
                  color: '#fff'
                };
              }
            }
          }

          list.sub_screenHeight = sub_screenHeight;
          list.sub_screenDatas = sub_screenDatas;

          _this.SET_LIST(list);

          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + list.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center',
            height: '100%'
          };
        });
      }
    },
    // 获取副屏轮次数据
    doGetRound: function doGetRound(nums, queryRound) {
      var _this2 = this;

      getRound({
        rand_id: +this.$route.query.id,
        group_id: +this.$route.query.group_id,
        nums: nums
      }).then(function (res) {
        var rounds = {};

        for (var i = 0; i < res.data.list.length; i++) {
          rounds[res.data.list[i].num + ''] = res.data.list[i].rand_list || [];
        }

        _this2.doGetData(_this2.editFormatList.sub_screenDatas, JSON.parse(JSON.stringify(rounds)), queryRound);
      });
    },
    // 将副屏数据分类
    doGetData: function doGetData(sub_screenDatas, rounds, queryRound) {
      for (var i = 0; i < sub_screenDatas.length; i++) {
        // 几轮
        var roundsData = [];

        for (var j = 0; j < sub_screenDatas[i].rounds.length; j++) {
          var round = queryRound - sub_screenDatas[i].rounds[j];

          if (rounds[round + ''] && rounds[round + ''].length) {
            var row_numData = [];

            for (var a = 0; a < rounds[round + ''].length; a++) {
              row_numData.push(rounds[round + ''][a]);
            }

            roundsData.push({
              name: "\u7B2C".concat(round, "\u8F6E"),
              datas: row_numData
            });
          }
        }

        sub_screenDatas[i].roundsData = roundsData;
      }

      this.editFormatList.sub_screenDatas = sub_screenDatas;
      this.SET_LIST(JSON.parse(JSON.stringify(this.editFormatList)));
    },
    mainStyle: function mainStyle() {
      var _this$editFormatList;

      var currentStyle = {
        position: 'absolute'
      },
          currentDOM = Object.assign({}, (_this$editFormatList = this.editFormatList) === null || _this$editFormatList === void 0 ? void 0 : _this$editFormatList.host_json);
      currentStyle.top = currentDOM.page_positionX;
      currentStyle.left = currentDOM.page_positionY;
      currentStyle.width = "calc(100vw - ".concat(currentDOM.page_width, "px)");
      currentStyle.height = "calc(100vh - ".concat(currentDOM.page_height, "px)"); // 样式

      return unit(currentStyle);
    },
    // 计算样式
    subStyle: function subStyle(index) {
      var current = this.editFormatList.sub_screenDatas[index];
      var currentDOM = Object.assign({}, current),
          currentStyle = {},
          // 样式
      xAxis = currentDOM.locationXy,
          // x坐标
      yAxis = currentDOM.locationTb; // y坐标
      // 定位

      currentStyle[xAxis] = currentDOM.x;
      currentStyle[yAxis] = currentDOM.y;
      currentStyle.width = "calc(100vw - ".concat(currentDOM.width, "px)");
      currentStyle.height = "calc(100vh - ".concat(currentDOM.height, "px)");
      currentStyle.borderColor = currentDOM.borderColor;
      return unit(currentStyle);
    }
  }),
  updated: function updated() {
    var _this3 = this;

    // 数据更新重新计算高度
    this.$nextTick(function () {
      clearTimeout(_this3.timer);
      _this3.timer = setTimeout(function () {
        var t_el = _this3.$refs['topHeight'];
        var b_el = _this3.$refs['bottomHeight']; // 之前使用相对位置，所以父级高度存在，目前改为绝对高度，所以使用轮次的上边距和字体大小判断当前元素占多高

        var titleHeight = parseInt(_this3.editFormatList.card_position);
        _this3.tbHeight = titleHeight + parseInt(b_el.offsetHeight);
      }, 500);
    });
  }
};