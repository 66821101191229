import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendList, sendExport } from '@/api/pledge';
import { getpName } from '@/utils/auth';
import moment from 'moment';
import utils from '@/utils/utils';
export default {
  name: 'Index',
  data: function data() {
    return {
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      // 第一个页
      page: 1,
      // 分页 一页几条
      pageSize: 10,
      // 分页 总数
      total: 0,
      pictLoading: true,
      //  客户列表
      tableData: [],
      // 场次列表
      sessionList: [],
      // 页面搜索
      search: {
        search: '',
        status: 1
      }
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  },
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    this.getTableList();
  },
  methods: {
    // 重置列表
    resetForm: function resetForm() {
      this.page = 1;
      this.search = {
        search: '',
        status: 0
      };
      this.getTableList();
    },
    handleSelectionChange: function handleSelectionChange() {},
    // 搜索事件
    getTableSearchList: function getTableSearchList() {
      this.page = 1;
      this.getTableList();
    },
    // 获取table 数据
    getTableList: function getTableList() {
      var _this2 = this;

      // 提交数据
      var data = _objectSpread(_objectSpread({}, this.search), {}, {
        page: this.page,
        limit: this.pageSize
      });

      sendList(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              count = _res$data.count; // 表格数据

          _this2.tableData = list || []; // 总数

          _this2.total = count; // 判断 当前页数 大于1， 当前页数，数据为0

          if (list.user === null && _this2.page > 1) {
            _this2.page = _this2.page - 1;

            _this2.getTableList();
          }

          _this2.pictLoading = false;
        }
      }).catch(function (err) {
        _this2.pictLoading = false;
      });
    },
    // 导出数据
    exportFile: function exportFile() {
      var data = _objectSpread({}, this.search);

      sendExport(data).then(function (res) {
        if (res.status === 200) {
          var name = '导出派号'; // utils.download_files(
          //    this.host + res.data.path,
          //     `${getpName()}-${name}-${moment().format('YYYYMMDDHHmmss')}.xlsx`,
          //     { showMessage: true }
          // )

          utils.download_files_base64(res.data.data, "".concat(getpName(), "-").concat(name, "-").concat(moment().format('YYYYMMDDHHmmss')), res.data.suffix);
        }
      });
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    }
  }
};