var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-switch" }, [
    _c("div", { staticClass: "switch-wrapper" }, [
      _c(
        "span",
        { class: ["l-r-text", { "l-r-text-false": !_vm.svalue }] },
        [_vm._t("label", [_vm._v(_vm._s(_vm.ltext))])],
        2
      ),
      _c(
        "div",
        {
          class: ["switch-content", { active: !_vm.svalue }],
          on: { click: _vm.switchChange },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.svalue,
                  expression: "svalue",
                },
              ],
              staticClass: "text-left",
              style: {
                "padding-left": _vm.trueLabel.length > 2 ? "5px" : "10px",
              },
            },
            [_vm._v(_vm._s(_vm.trueLabel))]
          ),
          _c("div", {
            staticClass: "circle",
            style: { "--move": !_vm.svalue ? "0px" : "40px" },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.svalue,
                  expression: "!svalue",
                },
              ],
              staticClass: "text-right",
            },
            [_vm._v(_vm._s(_vm.falseLabel))]
          ),
        ]
      ),
      _c("span", { class: ["l-r-text", { "l-r-text-false": !_vm.svalue }] }, [
        _vm._v(_vm._s(_vm.rtext)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }