import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Api from '../../../api/rowNumber';
import { authGetEvent, adminParameter } from '@/api/public';
import listItem from './components/listItem.vue';
import Api_index from '@/api/index';
import eventSelect from '@/components/eventSelect';
export default {
  name: 'RowNumber',
  components: {
    eventSelect: eventSelect,
    listItem: listItem
  },
  data: function data() {
    return {
      is_null: false,
      appHeight: 0,
      tableData: [],
      select_event: {},
      // 选择的活动和场次id集
      eventList: [] // 项目活动列表

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles'])), {}, {
    tableHeight: function tableHeight() {
      // 90
      return this.appHeight - 72;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    this.appHeight = this.$refs.app.offsetHeight;

    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
    };

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      this.select_event = {
        event_id: e.event.id,
        round_id: e.round.id
      };

      if (this.roles.includes('rownumber/index') || this.roles.includes('admin')) {
        this.getTableList();
      }
    },
    getText: function getText(row) {
      if (row.log_rand_cound > 0 && row.user_count !== row.log_rand_cound) {
        return '继续排号';
      } else if (row.log_rand_cound > 0 && row.user_count === row.log_rand_cound) {
        return '排号结束';
      } else {
        return '开始排号';
      }
    },
    // 排号列表
    getTableList: function getTableList() {
      var _this2 = this;

      var params = _objectSpread({}, this.select_event);

      Api.rowNumberList(params).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this2.tableData = list;
        }
      });
    },
    // 重置 排号
    handleReset: function handleReset(postId) {
      var _this3 = this;

      Api.numberReset({
        id: postId
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.getTableList();
        }
      });
    },
    // 活动 判断
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    handleSet: function handleSet() {
      var path = this.$router.resolve({
        path: '/EditFormat',
        query: {
          id: this.select_event.event_id,
          type: 'row'
        }
      });
      var win = window.open("".concat(path.href), '_blank'); // this.$router.push({ name: 'SetUp', query: {} })
    },
    handlePerfect: function handlePerfect() {},
    // 开始排号
    handleStart: function handleStart(postId) {
      var _this$$router$resolve = this.$router.resolve({
        name: 'NumberIndex',
        query: {
          id: postId,
          type: 'row'
        }
      }),
          href = _this$$router$resolve.href;

      window.open(href, '_blank');
    },
    // 编辑排号
    handleEdit: function handleEdit(postId) {
      this.$router.push({
        name: 'EditNumber',
        query: {
          id: postId
        }
      });
    },
    // 排号记录
    handleRecord: function handleRecord(postId) {
      this.$router.push({
        name: 'NumberAnnal',
        query: {
          id: postId
        }
      });
    },
    // 分屏
    lotterySplitScreen: function lotterySplitScreen(row) {
      var routeParams = {
        id: row.id
      };
      var path = this.$router.resolve({
        path: '/lotterySplitScreen',
        query: routeParams
      });
      var win = window.open("".concat(path.href), '_blank');
    }
  }
};