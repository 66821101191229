var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "edit-format-bg" }, [
        _c("img", { attrs: { src: _vm.imgUrl + _vm.rand.logo } }),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("screenfull"),
          _vm.rand.type === 1
            ? [
                _vm.rand.type_group === 1
                  ? [
                      _c("div", { staticClass: "title-group" }, [
                        _vm._v(_vm._s(_vm.rand.name)),
                      ]),
                      _c("div", { staticClass: "list-group" }, [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v("摇号组"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-box" },
                          _vm._l(_vm.group, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "list flex space-between",
                              },
                              [
                                _c("div", { staticClass: "list-name" }, [
                                  _vm._v(_vm._s(item.group_name)),
                                ]),
                                _c("div", { staticClass: "list-text" }, [
                                  _vm._v(_vm._s(item.status_name)),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.rand.name)),
                      ]),
                    ],
              ]
            : [
                _vm.rand.type_group === 1
                  ? [
                      _c("div", { staticClass: "title-group" }, [
                        _vm._v(_vm._s(_vm.rand.name)),
                      ]),
                      _c("div", { staticClass: "list-group" }, [
                        _c("div", { staticClass: "list-title" }, [
                          _vm._v("摇号组"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "list-box" },
                          _vm._l(_vm.group, function (item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "list flex space-between",
                              },
                              [
                                _c("div", { staticClass: "list-name" }, [
                                  _vm._v(_vm._s(item.group_name)),
                                ]),
                                _c("div", { staticClass: "list-text" }, [
                                  _vm._v(_vm._s(item.status_name)),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  : [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.rand.name)),
                      ]),
                    ],
              ],
          _c(
            "el-button",
            {
              staticClass: "button-size",
              attrs: { size: "medium" },
              on: { click: _vm.handleStartLottery },
            },
            [_vm._v(" " + _vm._s(_vm.list.start_name) + " ")]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }