var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", style: _vm.styleObject }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("screenfull"),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.rand.name)),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { staticClass: "text-center", attrs: { span: 24 } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "button-size",
                    attrs: { size: "medium" },
                    on: { click: _vm.handleStartLottery },
                  },
                  [_vm._v(_vm._s(_vm.start_name))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }