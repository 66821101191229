import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var loadingTime;
import { exportTemp, resDel, Unlock, reserveStatus, resLists, Export, resSubmit, searchReserveUser, searchReserveEdit, submitNewReserve, submitEditReserve, reservRedisCheck, exportReserve } from '@/api/reserve';
import Api from '../../api/index';
import { adminParameter } from '@/api/public';
import { getpName, getProject, getToken } from '@/utils/auth';
import UploadExcel from '@/components/my-UploadExcel';
import customSwitch from '@/components/customSwitch';
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import utils from '@/utils/utils';
import { param } from '@/utils';
export default {
  name: 'Reserved',
  components: {
    UploadExcel: UploadExcel,
    customSwitch: customSwitch
  },
  inject: ['reload'],
  data: function data() {
    return {
      // 文件上传
      // local: 'http://uuphp.cn:8012/reserv/Import',
      local: '',
      // header
      header: {},
      // 搜索关键字
      searchText: '',
      // 是否无客户房源  导入姓名和系统姓名不一致
      isNoUser: false,
      isSameName: false,
      // 表格高度
      // tableHeight: '',
      appHeight: '',
      topHeight: '',
      // 无客户房源 导入姓名不一致
      checkList: [],
      // 头部背景色
      bg: '#bfbfbf',
      // 头部字体颜色
      color: '',
      // 预留名单  是否显示缺省图片
      reserved: false,
      // 预留缺省显示一次
      isLockReserved: false,
      // 分页
      currentPage: 0,
      // 总数
      total: 0,
      // 页数 page
      page: 1,
      pageSize: 10,
      // 条数限制
      limit: 10,
      // 表格选中数量
      checkedNum: 0,
      // 表格数据
      tableData: [],
      // 导入数据版本信息
      eventReserve: '',
      // 批量删除 id集
      checkedList: [],
      // 是否需要审核 0 无需 1需要
      isNeedApproval: 1,
      // 测试活动是否生效
      switchBtn: false,
      // 公共数据
      projectList: {},
      // 导入成功失败 弹框
      afterUpMesDialog: false,
      // 导入成功失败 信息
      afterUpMes: {
        count: []
      },
      // 房源总数 用户总数
      roomCount: 0,
      userCount: 0,
      // 是否显示 新增预留弹框
      addNewDiglog: false,
      // 预留房源搜索
      roomResources: [],
      selectedRoom: '',
      // 预留弹框 title
      addNewDiglogTitle: '新增预留',
      // 新增预留弹框 电话号码
      userTel: '',
      // 通过电话搜索 预留人信息
      reserverUserInfo: {},
      // 新增预留 是否显示 预留信息
      isShowUserinfo: false,
      // 新增预留 是否显示 搜索缺省提示
      isShowSearchNomes: false,
      // type 0 新增 1 修改
      type: 0,
      // 修改预留id
      reserveId: '',
      // 预留 通过手机号搜索 列表
      searchByPhoneList: [],
      // 预留选中
      selectIndex: 0,
      // 是否有房源车位
      isCar: false,
      isRoom: false,
      downloadFileDia: false,
      isShowDown: 1,
      is_downloadFile: 0,
      // 遮罩加载
      loading: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), mapState('base', ['isMobile'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 94;
    }
  }),
  destroyed: function destroyed() {
    window.onresize = null;
  },
  mounted: function mounted() {
    var _this = this;

    // 文件上传
    var token = getToken();
    var project = JSON.parse(getProject());
    this.header = {
      token: token,
      project: project.project_id
    };
    this.header.token = token;
    this.local = this.host + '/reserv/Import'; // ------------------------

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.apptop.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.apptop.offsetHeight;
    }; // 组件挂载时获取列表数据


    if (this.roles.includes('reserv/index') || this.roles.includes('admin')) {
      this.getTableLists();
    }

    adminParameter().then(function (res) {
      var list = res.data.list;
      _this.projectList = list;
    });
  },
  methods: {
    statusFun: function statusFun(type) {
      switch (type) {
        case 0:
          this.bg = 'rgba(191, 191, 191, 0.15)';
          this.color = 'rgb(191, 191, 191)';
          return '未提交审核';

        case 1:
          this.bg = 'rgba(255, 141, 55, 0.15)';
          this.color = 'rgb(255, 141, 55)';
          return '审批中';

        case 2:
          this.bg = 'rgba(91, 209, 113, 0.15)';
          this.color = 'rgb(91, 209, 113)';
          return '审批通过';

        case 3:
          this.bg = 'rgba(245, 34, 45, 0.15)';
          this.color = 'rgb(245, 34, 45)';
          return '审批驳回';

        case 4:
          this.bg = 'rgba(191, 191, 191, 0.15)';
          this.color = 'rgb(191, 191, 191)';
          return '未提交审核';
      }
    },
    // 没有预留名单去创建
    toCreate: function toCreate() {
      this.reserved = false;
      this.isLockReserved = true;
    },
    // 搜索栏checkbox-group 改变
    checkGroupChange: function checkGroupChange(e) {
      var isNoUser = this.isNoUser;
      var isSameName = this.isSameName;
      var keyWord = '';
      var page = this.page;
      var limit = this.limit;
      this.getTableLists(isNoUser, isSameName, keyWord, page, limit);
    },
    handleError: function handleError(err, file, fileList) {
      var myError = err.toString(); // 转字符串

      myError = myError.replace('Error: ', ''); // 去掉前面的" Error: "

      myError = JSON.parse(myError); // 转对象

      this.loading.close();
      this.$message({
        type: 'error',
        message: myError.msg
      });
    },
    // 导入 改变
    uploadChange: function uploadChange(e) {},
    loadingAnimation: function loadingAnimation() {
      this.loading = this.$loading({
        lock: true,
        text: '保存中···',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return true;
    },
    // 导入成功 失败
    uploadSucess: function uploadSucess(e) {
      var h = this.$createElement;
      this.$refs.upload.clearFiles();
      this.afterUpMes = e;
      this.afterUpMesDialog = true;
      this.loading.close();
      this.getTableLists();
      this.dataReg();

      if (this.afterUpMes.url) {
        utils.download_files_base64(e.data, e.name, e.suffix); // this.downloadErrFile(this.afterUpMes.url)
      }
    },
    // 下载错误文件
    downloadErrFile: function downloadErrFile(url) {
      window.location.href = this.host + url; // window.open(`http://uuphp.cn:8012${url}`)
    },
    // uploadFail(e) {
    //   const h = this.$createElement
    //   const str = e.toString()
    //   const json = str.replace('Error: ', '')
    //   this.$refs.upload.clearFiles()
    //   this.$message({
    //     message: JSON.parse(json).msg,
    //     type: 'error',
    //     beforeClose: (action, instance, done) => {
    //       if (action == 'confirm') {
    //         window.location.href = this.host + url
    //         // window.open(`http://uuphp.cn:8012${url}`)
    //       }
    //       done()
    //       this.getTableLists()
    //     }
    //   })
    // },
    // 表格选择改变
    selectionChange: function selectionChange(e) {
      // 选中条数
      var num = e.length;
      this.checkedNum = num; // 选中的列表

      var checkList = [];
      e.forEach(function (element) {
        checkList.push(element.id);
      });
      this.checkedList = checkList;
    },
    // 解除预留
    unLock: function unLock(row) {
      var _this2 = this;

      this.$confirm("\u662F\u5426\u786E\u5B9A\u91CA\u653E".concat(row.room_name, "\u7684\u9884\u7559\uFF1F\u91CA\u653E\u540E\u4EFB\u4F55\u5BA2\u6237\u5747\u53EF\u8BA4\u8D2D\u6B64").concat(row.cate === 1 ? '房源' : '车位', "\uFF01"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Unlock(row).then(function (res) {
          _this2.$message.success(res.msg || '解除成功');

          _this2.getTableLists();
        });
      });
    },
    // 删除一条
    delItems: function delItems(id) {
      var _this3 = this;

      this.loadingAnimation();

      var _self = this;

      this.$confirm('删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var status = _this3.eventReserve.status;
        var isNeedApproval = _this3.isNeedApproval;

        if (status == 1 || status == 2 || status == 3) {
          if (isNeedApproval == 1) {
            _this3.$confirm('重新编辑后已审核名单将被覆盖且失效', '提示', {
              confirmButtonText: '重新编辑',
              cancelButtonText: '取消'
            }).then(function (res) {
              var verId = _this3.eventReserve.id;

              _self.changeApproval(verId, 'recession');
            });
          } else {
            var params = {
              ids: id
            };
            resDel(params).then(function (res) {
              var h = _this3.$createElement;

              _this3.$message({
                message: res.data.msg,
                type: 'success'
              });

              _this3.getTableLists();
            });
          }

          _this3.loading.close();
        } else {
          var _params = {
            ids: id
          };
          resDel(_params).then(function (res) {
            var h = _this3.$createElement;

            _this3.$message({
              message: res.data.msg,
              type: 'success'
            });

            _this3.getTableLists();
          });
        }
      }).catch(function () {
        _this3.loading.close();

        return;
      });
    },
    // 批量删除
    batchDelete: function batchDelete() {
      var lists = this.checkedList;

      if (lists.length == 0) {
        var h = this.$createElement;
        this.$message({
          message: '请勾选需要删除的选项',
          type: 'warning'
        });
      } else {
        // id集合
        var ids = lists.join(','); // 删除

        this.delItems(ids);
      }
    },
    closeDon: function closeDon() {
      this.downloadFileDia = false;
    },
    // 下载模板
    downloadtemp: function downloadtemp(index, down) {
      var _this4 = this;

      // 模板下载
      var templateDownload = function templateDownload() {
        var url = '';

        switch (index) {
          case 1:
            url = _this4.host + '/template/reserv/4.云销控-预留导入模板(住宅).xlsx';
            utils.download_files(url, "".concat(getpName(), "-\u9884\u7559\u5BFC\u5165(\u623F\u6E90)-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
              showMessage: true
            });
            break;

          case 2:
            url = _this4.host + '/template/reserv/4.云销控-预留导入模板(车位).xlsx';
            utils.download_files(url, "".concat(getpName(), "-\u9884\u7559\u5BFC\u5165(").concat(_this4.customSetting.villa_setting.house_name, ")-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
              showMessage: true
            });
            break;

          default:
            return;
        }

        _this4.closeDon();
      }; // 有数据的情况下


      if (index === 1 && this.isRoom || index === 2 && this.isCar || index === 0) {
        // 选择空白模板下载
        if (down) {
          index = this.isShowDown;
          templateDownload();
          return;
        } // 下载有数据模板
        else {
          // 打开模板弹窗
          if (!this.downloadFileDia) {
            this.downloadFileDia = true;
            this.isShowDown = index;
            return;
          } // 下载
          else {
            // cate为1则导出有房源数据的模板,2是车位,不传或者为0为以前的功能
            Export({
              cate: index
            }).then(function (res) {
              var url = _this4.host + res.data["cate".concat(index)];
              utils.download_files_base64(res.data["data".concat(index)], res.data["name".concat(index)], res.data["suffix".concat(index)]);
            }).finally(function () {
              _this4.closeDon();
            });
          }
        }
      } // 无数据直接下载空白模板
      else {
        templateDownload();
      }
    },
    // 发起审批
    approval: function approval() {
      if (this.tableData != undefined) {
        var verId = this.eventReserve.id;
        this.changeApproval(verId, 'commit');
      } else {
        this.$message({
          type: 'warning',
          message: '数据为空不能提交审核'
        });
      }
    },
    // 撤回审批
    cancelApproval: function cancelApproval() {
      var _self = this;

      this.$confirm('是否撤回审批?', '提示', {
        confirmButtonText: '撤回审批',
        cancelButtonText: '取消'
      }).then(function (res) {
        var verId = _self.eventReserve.id;

        _self.changeApproval(verId, 'recession');
      });
    },
    // 编辑
    edit: function edit() {
      var _this5 = this;

      var _self = this;

      this.$confirm('重新编辑后已审核名单将被覆盖且失效', '提示', {
        confirmButtonText: '重新编辑',
        cancelButtonText: '取消'
      }).then(function (res) {
        var verId = _this5.eventReserve.id;

        _self.changeApproval(verId, 'recession');
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 搜索
    subSearch: function subSearch() {
      var isNoUser = this.isNoUser;
      var isSameName = this.isSameName;
      var keyWord = this.searchText.trim();
      var page = this.page;
      var limit = this.limit;
      this.getTableLists(isNoUser, isSameName, keyWord, page, limit);
    },
    // 清空搜索内容
    clear: function clear() {
      this.searchText = '';
      this.getTableLists();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.limit = val;
      var isNoUser = this.isNoUser;
      var isSameName = this.isSameName;
      var keyWord = '';
      var page = this.page;
      var limit = val;
      this.getTableLists(isNoUser, isSameName, keyWord, page, limit);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page = val;
      var isNoUser = this.isNoUser;
      var isSameName = this.isSameName;
      var keyWord = '';
      var page = val;
      var limit = this.limit;
      this.getTableLists(isNoUser, isSameName, keyWord, page, limit);
    },
    // 获取列表数据
    getTableLists: function getTableLists() {
      var _this6 = this;

      var isHouseRes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var isSameName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var searchKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var page = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
      var limit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 10;
      this.loading = this.$loading({
        lock: true,
        text: '加载中···',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        is_no_user: isHouseRes,
        is_2_name: isSameName,
        search: searchKey,
        page: page,
        limit: limit
      };
      resLists(params).then(function (res) {
        if (res.status == 200) {
          var _res$data;

          // 导入表格数据
          _this6.tableData = res.data.eventReserveUser; // 导入数据版本信息

          _this6.eventReserve = res.data.eventReserve; // 总数

          _this6.total = res.data.count; // 是否需要审批

          _this6.isNeedApproval = res.data.ReservedAudit; // 是否有房源车位

          _this6.isCar = res.data.isCar;
          _this6.isRoom = res.data.isRoom; // 房源总数 + 车位总数

          _this6.roomCount = res.data.roomCount + ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.roomCount2) || 0; // 客户总数

          _this6.userCount = res.data.userCount; // 是否有数据

          if (!_this6.isLockReserved) {
            if (res.data.roomCount + res.data.roomCount2 != 0) {
              _this6.reserved = false;
            } else {
              _this6.reserved = true;
            }
          }
        }
      }).finally(function () {
        var _this6$loading;

        (_this6$loading = _this6.loading) === null || _this6$loading === void 0 ? void 0 : _this6$loading.close();
      });
    },
    // 提交审批 撤回审批
    changeApproval: function changeApproval(verId, type) {
      var _this7 = this;

      // 参数
      var params = {
        type: type,
        version_id: verId
      }; // 调用接口

      resSubmit(params).then(function (res) {
        if (res.status == 200) {
          var h = _this7.$createElement;

          _this7.$message({
            message: res.data.msg,
            type: 'success'
          });

          _this7.getTableLists();
        } else {
          _this7.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    // 查看审批驳回原因
    seeReason: function seeReason() {
      this.$alert(this.eventReserve.desc, '审批驳回理由', {
        confirmButtonText: '确定'
      });
    },
    // 测试活动 是否生效
    switchChange: function switchChange(e) {
      var _this8 = this;

      var switchStatus = !e;
      this.eventReserve.is_test = switchStatus ? 1 : 0;
      var params = {
        id: this.eventReserve.id,
        status: switchStatus ? 1 : 0
      };
      reserveStatus(params).then(function (res) {
        _this8.$message.success(res.data.msg);

        _this8.dataReg();
      }).catch(function (err) {
        _this8.eventReserve.is_test = !switchStatus ? 1 : 0;
      });
    },
    // 新增预留 和 修改预留
    addNewDiglogClick: function addNewDiglogClick(type, row) {
      this.type = type;

      if (type === 0) {
        // 新增预留
        this.addNewDiglogTitle = '新增预留';
        this.addNewDiglog = true;
        this.userTel = '';
        this.selectedRoom = '';
        this.reserverUserInfo = {};
        this.isShowUserinfo = false;
        this.searchByPhoneList = [];
        this.isShowSearchNomes = false;
        this.getReserveRoomList();
      } else {
        // 修改预留
        this.addNewDiglogTitle = '修改预留信息';
        this.addNewDiglog = true;
        this.userTel = row.tel;
        this.selectedRoom = row.room_name;
        this.reserveId = row.id;
        this.reserverUserInfo = row;
        this.isShowUserinfo = true;
        this.searchByPhoneList = [row];
        this.isShowSearchNomes = false;
        this.getReserveRoomList();
      }
    },
    // 新增 修改 通过电话号码搜索
    searchUsersByPhone: function searchUsersByPhone() {
      var _this9 = this;

      var tel = this.userTel;
      var params = {
        type: 'user',
        data: tel
      };

      if (tel.trim() == '') {
        this.$message({
          type: 'warning',
          message: '搜索手机号不能为空'
        });
        return;
      }

      if (this.type == 0) {
        searchReserveUser(params).then(function (res) {
          _this9.reserverUserInfo = res.data.info;
          _this9.searchByPhoneList = res.data.list || [];

          if (res.data.list && res.data.list.length != 0) {
            _this9.isShowUserinfo = true;
            _this9.isShowSearchNomes = false;
            _this9.selectIndex = 0;
          } else {
            _this9.isShowUserinfo = false;
            _this9.isShowSearchNomes = true;
          }
        });
      } else {
        searchReserveEdit(params).then(function (res) {
          _this9.reserverUserInfo = res.data.info;
          _this9.searchByPhoneList = res.data.list || [];

          if (res.data.list && res.data.list.length != 0) {
            _this9.isShowUserinfo = true;
            _this9.isShowSearchNomes = false;
            _this9.selectIndex = 0;
          } else {
            _this9.isShowUserinfo = false;
            _this9.isShowSearchNomes = true;
          }
        });
      }
    },
    // 新增预留提交
    submitAddNew: function submitAddNew() {
      var _this$searchByPhoneLi,
          _this10 = this;

      this.loadingAnimation();
      var params = {
        tel: ((_this$searchByPhoneLi = this.searchByPhoneList[this.selectIndex]) === null || _this$searchByPhoneLi === void 0 ? void 0 : _this$searchByPhoneLi.tel) || '',
        room: this.selectedRoom
      };

      if (params.room == '') {
        var _this$loading;

        this.$message({
          type: 'warning',
          message: '预留房源不能为空！'
        });
        (_this$loading = this.loading) === null || _this$loading === void 0 ? void 0 : _this$loading.close();
        return;
      }

      if (this.type == 0) {
        // 新增
        submitNewReserve(params).then(function (res) {
          var _this10$loading;

          _this10.$message({
            type: 'success',
            message: res.data.msg
          });

          _this10.getReserveRoomList();

          _this10.getTableLists();

          _this10.addNewDiglog = false;

          _this10.dataReg();

          (_this10$loading = _this10.loading) === null || _this10$loading === void 0 ? void 0 : _this10$loading.close();
        }).catch(function () {
          _this10.loading.close();
        });
      } else {
        // 修改
        params.id = this.reserveId;
        submitEditReserve(params).then(function (res) {
          _this10.$message({
            type: 'success',
            message: res.data.msg
          });

          _this10.getReserveRoomList();

          _this10.getTableLists();

          _this10.addNewDiglog = false;

          _this10.dataReg();
        }).catch(function () {
          _this10.loading.close();
        });
      }
    },
    // 数据验证
    dataReg: function dataReg() {
      var _this11 = this;

      reservRedisCheck().then(function (res) {
        var info = res.data.info; // 等于 true  异常 需要去 更改

        if (info.error) {
          _this11.$alert('<p>项目数据有异常！</p> 修复方法：请前往"开盘维护-活动计划"重新点击保存', '重要警告', {
            dangerouslyUseHTMLString: true,
            center: true,
            showClose: false,
            confirmButtonText: '去处理',
            callback: function callback(action) {
              _this11.$router.push({
                name: 'Activity',
                query: {
                  type: 'edit'
                }
              });
            }
          });
        }
      });
    },
    // 获取预留房源信息
    getReserveRoomList: function getReserveRoomList() {
      var _this12 = this;

      var params = {
        type: 'room',
        data: ''
      };

      if (this.type == 0) {
        searchReserveUser(params).then(function (res) {
          _this12.roomResources = res.data.list;
        });
      } else {
        searchReserveEdit(params).then(function (res) {
          _this12.roomResources = res.data.list;
        });
      }
    },
    // 上传格式 大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.loadingAnimation();
      var isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      var isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        var _this$loading2;

        (_this$loading2 = this.loading) === null || _this$loading2 === void 0 ? void 0 : _this$loading2.close();
        this.$message.error('上传只能是 excel 格式!');
      }

      if (!isLt2M) {
        var _this$loading3;

        (_this$loading3 = this.loading) === null || _this$loading3 === void 0 ? void 0 : _this$loading3.close();
        this.$message.error('上传大小不能超过 10MB!');
      }

      return isJPG && isLt2M;
    },
    // 导出数据
    exportAllData: function exportAllData() {
      var _this13 = this;

      this.loadingAnimation();
      var params = {
        is_no_user: this.isNoUser,
        is_2_name: this.isSameName,
        search: this.searchText
      };
      exportReserve(params).then(function (res) {
        // let urls = res.data
        // for (const key in urls) {
        //     this.downloadFile(`${this.host}${urls[key]}`)
        // }
        if (res.data.data1) utils.download_files_base64(res.data.data1, res.data.name1, res.data.suffix1);
        if (res.data.data2) utils.download_files_base64(res.data.data2, res.data.name2, res.data.suffix2);

        _this13.loading.close();
      });
    },
    downloadFile: function downloadFile(url) {
      console.log(url);
      var iframe = document.createElement('iframe');
      iframe.style.display = 'none'; // 防止影响页面

      iframe.style.height = 0; // 防止影响页面

      iframe.src = url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用）

      setTimeout(function () {
        iframe.remove();
      }, 5 * 60 * 1000);
    },
    // 房号 车位
    toDetail: function toDetail(row) {
      if (row.cate == 1) {
        this.$router.push({
          name: 'HouseInfo',
          query: {
            id: row.room_id
          }
        });
      } else {
        this.$router.push({
          name: 'BuildingInfo',
          query: {
            id: row.room_id
          }
        });
      }
    }
  }
};