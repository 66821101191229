//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'JianFa',
  functional: true,
  props: {
    // 外层嵌套标签
    tag: {
      type: String,
      default: 'div'
    }
  },
  render: function render(h, context) {
    var _context$parent$$stor;

    // 只对建发项目渲染
    if ((_context$parent$$stor = context.parent.$store) !== null && _context$parent$$stor !== void 0 && _context$parent$$stor.getters.isJianfa) {
      return h(context.props.tag, context.data, context.children);
    } else {
      return;
    }
  }
};