var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "active-report-title" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.num))]),
      _c("div", { staticClass: "title-text" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }