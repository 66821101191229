//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'linkSwicth',
  props: {
    type: {
      type: String,
      default: 'from'
    },
    audit: {
      type: Object
    },
    list: {
      type: Object
    }
  },
  data: function data() {
    return {
      linkVal: 0,
      from: [],
      pay: [],
      sign: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.setlink('from');
    }, 500);
  },
  methods: {
    swicthForm: function swicthForm(val) {
      this.$emit('swicthForm', val);
    },
    creactlinkVal: function creactlinkVal(type) {
      if (this[type][0]) {
        this.linkVal = this[type][0].type;
        this.$emit('swicthForm', this.linkVal);
      } else {
        this.$emit('swicthForm', false);
      }
    },
    setlink: function setlink(type) {
      console.log(this.audit);
      var links = [];
      var _this$audit = this.audit,
          register_name = _this$audit.register_name,
          identify_name = _this$audit.identify_name,
          raising_funds_name = _this$audit.raising_funds_name,
          register_form_name = _this$audit.register_form_name,
          identify_form_name = _this$audit.identify_form_name,
          raising_funds_form_name = _this$audit.raising_funds_form_name,
          register_price_name = _this$audit.register_price_name,
          identify_price_name = _this$audit.identify_price_name,
          raising_funds_price_name = _this$audit.raising_funds_price_name,
          register_sign_name = _this$audit.register_sign_name,
          identify_sign_name = _this$audit.identify_sign_name,
          raising_funds_sign_name = _this$audit.raising_funds_sign_name;
      var _this$list = this.list,
          is_identify = _this$list.is_identify,
          is_raising_funds = _this$list.is_raising_funds;

      if (this.type === 'from') {
        var _this$audit2 = this.audit,
            is_register_form = _this$audit2.is_register_form,
            is_identify_form = _this$audit2.is_identify_form,
            is_raising_funds_form = _this$audit2.is_raising_funds_form;

        if (is_register_form) {
          links.push({
            type: 0,
            name: register_name + register_form_name
          });
        }

        if (is_identify_form && is_identify) {
          links.push({
            type: 1,
            name: identify_name + identify_form_name
          });
        }

        if (is_raising_funds_form && is_raising_funds) {
          links.push({
            type: 2,
            name: raising_funds_name + raising_funds_form_name
          });
        }

        this.linkVal = links[0].type;
        this.$emit('swicthForm', this.linkVal);
        this.from = links;
      }

      if (this.type === 'pay') {
        var _links = [];
        var _this$audit3 = this.audit,
            is_register_price = _this$audit3.is_register_price,
            is_identify_price = _this$audit3.is_identify_price,
            is_raising_funds_price = _this$audit3.is_raising_funds_price;

        if (is_register_price) {
          _links.push({
            type: 0,
            name: register_name + register_price_name
          });
        }

        if (is_identify_price && is_identify) {
          _links.push({
            type: 1,
            name: identify_name + identify_price_name
          });
        }

        if (is_raising_funds_price && is_raising_funds) {
          _links.push({
            type: 2,
            name: raising_funds_name + raising_funds_price_name
          });
        }

        if (_links.length) {
          var _links$;

          this.linkVal = (_links$ = _links[0]) === null || _links$ === void 0 ? void 0 : _links$.type;
        }

        this.$emit('swicthForm', _links[0] ? this.linkVal : false);
        this.pay = _links;
      }

      if (this.type === 'sign') {
        var _links2 = [];
        var _this$audit4 = this.audit,
            is_register_sign = _this$audit4.is_register_sign,
            is_identify_sign = _this$audit4.is_identify_sign,
            is_raising_funds_sign = _this$audit4.is_raising_funds_sign;

        if (is_register_sign && is_identify) {
          _links2.push({
            type: 0,
            name: register_name + register_sign_name
          });
        }

        if (is_identify_sign) {
          _links2.push({
            type: 1,
            name: identify_name + identify_sign_name
          });
        }

        if (is_raising_funds_sign && is_raising_funds) {
          _links2.push({
            type: 2,
            name: raising_funds_name + raising_funds_sign_name
          });
        }

        this.linkVal = _links2[0].type;
        this.$emit('swicthForm', this.linkVal);
        this.sign = _links2;
      }
    }
  }
};