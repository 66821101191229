import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { roomCatelist } from './api';
export default {
  name: 'HouseType',
  props: {
    showPopup: Boolean,
    title: {
      default: '意向户型'
    },
    // 已选中数组
    selectData: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    // 监听显示隐藏 可以减少页面通过ref获取dom对象
    showPopup: {
      handler: function handler(newVal, odlVal) {
        // 开启
        if (newVal) {
          this.show();
        } // 开启又关闭


        if (!newVal && odlVal) {
          this.close();
        }
      },
      immediate: true // 首次

    },
    selectData: {
      handler: function handler(newVal, odlVal) {
        var d = newVal; // 如果是字符串

        if (typeof newVal === 'string') {
          try {
            // 不去报错 影响操作
            d = JSON.parse(newVal);
          } catch (e) {
            d = [];
          }
        }

        if (!d) d = [];

        if (d && typeof d === 'string') {
          d = JSON.parse(d);
        }

        this.selectList = d;
        this.checkRoomCateList = d;

        if (d.length) {
          // 有选择数据通过提交回填
          this.toStr(d);
        }
      },
      immediate: true // 首次

    }
  },
  data: function data() {
    return {
      cateList: [],
      checkRoomCateList: [],
      showRoomCate: false,
      buildTypeList: [],
      // 楼栋类型 车位 房源...
      buildTypeIndex: 1,
      selectList: [] // 选择显示列表

    };
  },
  methods: {
    setData: function setData(data, callback) {
      var _this = this;

      Object.keys(data).forEach(function (key) {
        _this[key] = data[key];
      });
      callback && callback();
    },
    toStr: function toStr() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      console.log(data);
      if (!data) return;
      data.forEach(function (item) {
        Object.keys(item).forEach(function (key) {
          // 不为不处理的
          if (!['id', 'checked'].includes(key)) {
            // 强制转字符
            item[key] = String(item[key]);
          }
        });
      });
      console.log(data);
      this.$emit('submit', data);
    },
    // 方法调用显示
    show: function show() {
      var _this2 = this;

      var l = this.$loading({
        lock: true,
        text: '加载房源数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getRoomCateList().then(function () {
        _this2.$nextTick(function () {
          _this2.showRoomCate = true;

          _this2.$emit('show');
        });
      }).finally(function () {
        l.close();
      });
    },
    // 方法调用关闭
    close: function close() {
      this.setData({
        showRoomCate: false,
        cateList: [],
        checkRoomCateList: [] // 确定后清空

      });
      this.$emit('close');
    },

    /**
     * 获取楼栋列表
     * @param id 选中的楼栋类型id
     */
    getRoomCateList: function getRoomCateList() {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        roomCatelist().then(function (res) {
          var _res$data;

          _this3.handleRoomCate((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.list);

          resolve();
        }).catch(function () {
          return reject;
        });
      });
    },
    handleRoomCate: function handleRoomCate() {
      var _this4 = this;

      var cateList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if (!cateList) cateList = []; // 数据选中

      cateList.forEach(function (item) {
        item.checked = _this4.checkRoomCateList.findIndex(function (c) {
          return c.id === item.id;
        }) !== -1;
      });
      console.log(cateList);
      this.setData({
        cateList: cateList
      });
    },
    // 选择户型
    selectCate: function selectCate(e) {
      var id = e.id;
      var cateList = this.cateList;

      for (var i = 0; i < cateList.length; i++) {
        var ci = cateList[i];

        if (ci.id === id) {
          ci.checked = !ci.checked;
        }
      }

      this.setData({
        cateList: cateList
      });
    },
    submitRoomCate: function submitRoomCate(e) {
      var _this$cateList;

      var checkList = (_this$cateList = this.cateList) === null || _this$cateList === void 0 ? void 0 : _this$cateList.filter(function (item) {
        return item.checked;
      });
      this.toStr(checkList);
      this.close();
    }
  }
};