var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "drawingContainer" } },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "top-form",
              attrs: { inline: true, model: _vm.search },
            },
            [
              _c("div", { staticClass: "form-left" }, [
                _vm.event.is_round
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "活动",
                              prop: "inxId",
                              "label-width": "2.5rem",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "全部",
                                },
                                model: {
                                  value: _vm.search.inxId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.search, "inxId", $$v)
                                  },
                                  expression: "search.inxId",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: "all", label: "全部" },
                                }),
                                _vm._l(
                                  _vm.activity.project,
                                  function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: { label: item.name, value: i },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "active" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "异常",
                          prop: "inxId",
                          "label-width": "2.5rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                              width: "18.75rem",
                            },
                            model: {
                              value: _vm.search.special,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "special", $$v)
                              },
                              expression: "search.special",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "all", label: "全部" },
                            }),
                            _c("el-option", {
                              attrs: { value: "is_not_sale", label: "非售" },
                            }),
                            _c("el-option", {
                              attrs: { value: "is_not_tag", label: "无分类" },
                            }),
                            _vm.event.is_round
                              ? _c("el-option", {
                                  attrs: {
                                    value: "is_not_round",
                                    label: "无场次",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "active" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分类",
                          prop: "inxId",
                          "label-width": "2.5rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              size: "small",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.search.tag_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "tag_id", $$v)
                              },
                              expression: "search.tag_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: 0 },
                            }),
                            _vm._l(_vm.activity.room_tag, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-right" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "楼栋",
                          prop: "build_id",
                          "label-width": "3.75rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                            },
                            on: {
                              change: function (e) {
                                return _vm.selectChange(e, _vm.areaList.build)
                              },
                            },
                            model: {
                              value: _vm.search.build_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "build_id", $$v)
                              },
                              expression: "search.build_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 0, label: "全部" },
                            }),
                            _vm._l(_vm.areaList.build, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "楼层",
                          prop: "unit_id",
                          "label-width": "3.75rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                            },
                            on: {
                              change: function (e) {
                                return _vm.selectChangeFloor(e, _vm.unitList)
                              },
                            },
                            model: {
                              value: _vm.search.unit_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "unit_id", $$v)
                              },
                              expression: "search.unit_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 0, label: "全部" },
                            }),
                            _vm._l(_vm.unitList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "区域",
                          prop: "floor_id",
                          "label-width": "3.75rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                            },
                            model: {
                              value: _vm.search.floor_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "floor_id", $$v)
                              },
                              expression: "search.floor_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 0, label: "全部" },
                            }),
                            _vm._l(_vm.floorList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "类型",
                          prop: "cate_id",
                          "label-width": "3.75rem",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "全部",
                            },
                            model: {
                              value: _vm.search.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "cate_id", $$v)
                              },
                              expression: "search.cate_id",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 0, label: "全部" },
                            }),
                            _vm._l(_vm.areaList.room_cate, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "house-num-search",
                        attrs: { prop: "no" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "车位号",
                            type: "text",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getTableSearchList($event)
                            },
                          },
                          model: {
                            value: _vm.search.no,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.search,
                                "no",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "search.no",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "search-btn-wrapper" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "greens-btn",
                        attrs: { size: "small" },
                        on: { click: _vm.getTableSearchList },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnHollowReset",
                        attrs: { size: "small" },
                        on: { click: _vm.resetForm },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block-bottom mT10" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "drawing-board",
                    {
                      ref: "drawingRef",
                      attrs: {
                        "img-src": _vm.bgImageSrc,
                        "move-list": _vm.moveList,
                      },
                      on: {
                        imgError: function ($event) {
                          _vm.occurImage = true
                        },
                        imgSuc: function ($event) {
                          _vm.occurImage = false
                        },
                        stopMoveBlock: _vm.stop,
                      },
                    },
                    [
                      _vm.occurImage
                        ? _c(
                            "div",
                            { staticClass: "showUploadImage" },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    drag: "",
                                    limit: 1,
                                    action: _vm.upload,
                                    multiple: false,
                                    "file-list": _vm.fileList,
                                    "on-success": _vm.imgupSuccess,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    accept: _vm.$imgSel,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v("将文件拖到此处，或"),
                                      _c("em", [_vm._v("点击上传")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [_vm._v("只能上传图片文件，且不超过2MB")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.selectMoveItem,
                        "label-width": "5rem",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addMoveBlock },
                            },
                            [_vm._v("新建车位")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.removeBlock },
                            },
                            [_vm._v("删除车位")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车位号码" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.selectMoveItem.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectMoveItem, "name", $$v)
                              },
                              expression: "selectMoveItem.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "售卖状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.selectMoveItem.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectMoveItem, "state", $$v)
                                },
                                expression: "selectMoveItem.state",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("在售"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("售出"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车位宽度" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.selectMoveItem.width,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectMoveItem, "width", $$v)
                              },
                              expression: "selectMoveItem.width",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车位高度" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.selectMoveItem.height,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectMoveItem, "height", $$v)
                              },
                              expression: "selectMoveItem.height",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车位角度" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              min: -180,
                              max: 180,
                              label: "设置角度",
                            },
                            model: {
                              value: _vm.selectMoveItem.transform,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectMoveItem, "transform", $$v)
                              },
                              expression: "selectMoveItem.transform",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车位边角" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              label: "左上角",
                            },
                            model: {
                              value: _vm.selectMoveItem.borderTopLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectMoveItem,
                                  "borderTopLeftRadius",
                                  $$v
                                )
                              },
                              expression: "selectMoveItem.borderTopLeftRadius",
                            },
                          }),
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              label: "右上角",
                            },
                            model: {
                              value: _vm.selectMoveItem.borderTopRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectMoveItem,
                                  "borderTopRightRadius",
                                  $$v
                                )
                              },
                              expression: "selectMoveItem.borderTopRightRadius",
                            },
                          }),
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              label: "右下角",
                            },
                            model: {
                              value: _vm.selectMoveItem.borderBottomRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectMoveItem,
                                  "borderBottomRightRadius",
                                  $$v
                                )
                              },
                              expression:
                                "selectMoveItem.borderBottomRightRadius",
                            },
                          }),
                          _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              label: "左下角",
                            },
                            model: {
                              value: _vm.selectMoveItem.borderBottomLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectMoveItem,
                                  "borderBottomLeftRadius",
                                  $$v
                                )
                              },
                              expression:
                                "selectMoveItem.borderBottomLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v("保存修改")]
                          ),
                          _c("el-button", { on: { click: _vm.cancel } }, [
                            _vm._v("取消修改"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }