var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container screen-list" },
    [
      false
        ? _c("div", { staticClass: "default-bg" }, [
            _vm._m(0),
            _c("div", { staticClass: "default-tip" }, [
              _vm._v("还未创建活动哦~"),
            ]),
          ])
        : _vm._e(),
      _c("div", { ref: "apptop", staticClass: "apptop" }, [
        _c("div", { staticClass: "page-header" }, [
          _c(
            "div",
            { staticClass: "page-header-left" },
            [
              _c("event-select", {
                attrs: { "event-list": _vm.eventList, is_round: false },
                on: { select_round: _vm.selectRoundHandle },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-header-right" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "led/conf"],
                      expression: "['admin', 'led/conf']",
                    },
                  ],
                  staticClass: "greens-btn btnHollowGreen",
                  attrs: { size: "small" },
                  on: { click: _vm.toBasic },
                },
                [_vm._v("基础设置 ")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "screen-setting space-between mT10" }, [
          _c(
            "div",
            { staticClass: "screen-left" },
            [
              _c("svg-icon", {
                attrs: { "class-name": "greens", "icon-class": "room" },
              }),
              _c(
                "span",
                { staticClass: "m-g", staticStyle: { "--m-g-r": "20" } },
                [
                  _vm._v(
                    _vm._s(_vm.customSetting.title) +
                      "总数 " +
                      _vm._s(_vm.tableData.roomCount)
                  ),
                ]
              ),
              _c("svg-icon", {
                attrs: { "class-name": "greens", "icon-class": "onlinePeople" },
              }),
              _c(
                "span",
                { staticClass: "m-g", staticStyle: { "--m-g-r": "15" } },
                [_vm._v("外显参与人数 " + _vm._s(_vm.tableData.numberOnline))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "led/number"],
                      expression: "['admin', 'led/number']",
                    },
                  ],
                  staticClass: "btnHollowGreen",
                  attrs: { size: "small" },
                  on: { click: _vm.ctrPerson },
                },
                [_vm._v("控制人数 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "screen-right" },
            [
              _c(
                "span",
                { staticClass: "m-g", staticStyle: { "--m-g-r": "15" } },
                [_vm._v("屏幕数量 " + _vm._s(_vm.screenCount))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "led/add"],
                      expression: "['admin', 'led/add']",
                    },
                  ],
                  staticClass: "btnHollowGreen",
                  attrs: { size: "small" },
                  on: { click: _vm.screenCountChange },
                },
                [_vm._v("添加屏幕 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "screen-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoad,
                  expression: "tableLoad",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                "element-loading-background": "rgba(0, 0, 0, 0.5)",
                "element-loading-text": "数据正在加载中",
                "element-loading-spinner": "el-icon-loading",
                data: _vm.tableData.ledList,
                "header-cell-style": { background: "#fafafa" },
                height: _vm.tableHeight,
                border: "",
                size: "medium",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "屏幕",
                  type: "index",
                  width: "100",
                },
              }),
              false
                ? _c("el-table-column", {
                    attrs: {
                      align: "justify",
                      label: "组件",
                      prop: "ledInfo",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.ledInfo.show_event_time == 1
                                      ? "活动倒计时 "
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    scope.row.ledInfo.title == 1
                                      ? "项目名称 "
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    scope.row.ledInfo.show_count == 1
                                      ? "选房流水 "
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    scope.row.ledInfo.show_cate == 1
                                      ? "户型展示 "
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    scope.row.ledInfo.is_banner == 1
                                      ? "轮播 "
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2620040784
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "显示" + _vm.customSetting.title,
                  align: "center",
                  prop: "ledRoomShow",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "content_isShowZK" }, [
                          _c(
                            "div",
                            {
                              class: {
                                text_isShowZK: scope.row.isShowZK === 1,
                                textContainer: true,
                              },
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                              },
                            },
                            [
                              _c("div", { staticClass: "text_isShowZK more" }),
                              scope.row.isShowZK === 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "btn_isShowZK1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onBtnIsShowZK(
                                            scope.$index,
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-arrow-down",
                                      }),
                                      _vm._v("展开 "),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(scope.row.ledRoomShow, function (item, i) {
                                return _c("span", { key: "led-" + i }, [
                                  _vm._v(
                                    _vm._s(item) +
                                      _vm._s(
                                        i !== scope.row.ledRoomShow.length - 1
                                          ? "，"
                                          : ""
                                      )
                                  ),
                                ])
                              }),
                              scope.row.isShowZK === 2
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "btn_isShowZK2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onBtnIsShowZK(
                                            scope.$index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-arrow-up",
                                      }),
                                      _vm._v("收起 "),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.customSetting.title + "总数",
                  align: "center",
                  prop: "roomCount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  prop: "lenInfo",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: "大屏显示",
                              effect: "dark",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "led/show"],
                                  expression: "['admin', 'led/show']",
                                },
                              ],
                              attrs: {
                                "class-name": "greens",
                                "icon-class": "screen",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.screenShow(scope)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: _vm.formatText + "设置",
                              effect: "dark",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "img-icon",
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                src: require("../../assets/image/fysz.svg"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.screenSetting(
                                    scope.row.ledInfo.id,
                                    scope
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown",
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _c("img", {
                                staticClass: "img-icon",
                                attrs: {
                                  src: require("../../assets/image/pmbj.svg"),
                                },
                              }),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "led/setcoordinate"],
                                        expression:
                                          "['admin', 'led/setcoordinate']",
                                      },
                                    ],
                                    on: { "": function ($event) {} },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.drag(scope)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-icon",
                                          attrs: {
                                            src: require("../../assets/image/dpzc.svg"),
                                          },
                                        }),
                                        _vm._v(" 大屏左侧布局 "),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["admin", "led/diyset"],
                                        expression: "['admin', 'led/diyset']",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.rightSetting(scope)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-icon",
                                          attrs: {
                                            src: require("../../assets/image/dpyc.svg"),
                                          },
                                        }),
                                        _vm._v(" 大屏右侧组件 "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              content: "删除屏幕",
                              effect: "dark",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "led/del"],
                                  expression: "['admin', 'led/del']",
                                },
                              ],
                              attrs: {
                                "class-name": "greens",
                                "icon-class": "delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delScreenEvent(
                                    scope.row.ledInfo.id
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "pagination" }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isCtrPersonShow,
            title: "修改参与人数",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isCtrPersonShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "alert-content" }, [
            _c(
              "div",
              {
                staticClass: "content-box m-g",
                staticStyle: { "--m-g-b": "15" },
              },
              [
                _c("div", { staticClass: "box-left wrap-center" }, [
                  _c("div", { staticClass: "top-text" }, [
                    _vm._v("实际参与人数"),
                  ]),
                  _c("div", { staticClass: "bottom-text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.onlineData.numberOnline - _vm.onlineConf.online_min
                      ) + "人"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "box-center ver-center" }, [
                  _vm._v("|"),
                ]),
                _c("div", { staticClass: "box-right wrap-center" }, [
                  _c("div", { staticClass: "top-text" }, [
                    _vm._v("外显参与人数"),
                  ]),
                  _c("div", { staticClass: "bottom-text red-text" }, [
                    _vm._v(_vm._s(_vm.outlinePerson) + "人"),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "input-box" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-position": "left",
                      "label-width": "125px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "参与人数上限", prop: "maxPerson" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "6" },
                          model: {
                            value: _vm.ruleForm.maxPerson,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "maxPerson", _vm._n($$v))
                            },
                            expression: "ruleForm.maxPerson",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "tip-text flex-end" }, [
                      _vm._v("在投屏上显示的参与人数不会超过此值"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "参与人数基数", prop: "basePerson" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-content",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tips-box",
                                staticStyle: {
                                  position: "absolute",
                                  left: "-35px",
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "该基数仅在活动开始前实时更新，活动开始后需手动刷新更新。",
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "tips el-icon-question",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("el-input", {
                              attrs: { maxlength: "6" },
                              model: {
                                value: _vm.ruleForm.basePerson,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "basePerson",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "ruleForm.basePerson",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "tip-text flex-end" }, [
                  _vm._v("在投屏上显示的参与人数=基数+实际人数"),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isCtrPersonShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("ruleForm")
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            center: "",
            visible: _vm.isScreenShow,
            width: "800px",
            top: "0",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isScreenShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "alert-content",
              staticStyle: { height: "75vh", "overflow-y": "auto" },
            },
            [
              _c(
                "div",
                { staticClass: "carousel" },
                [
                  _c(
                    "div",
                    { staticClass: "com-text" },
                    [
                      _vm._v(" 轮播 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content:
                              "开启轮播功能：系统将根据“自定义位置”中配置的轮播屏幕进行自动切换展示。",
                            effect: "dark",
                            placement: "right",
                          },
                        },
                        [_c("i", { staticClass: "tips el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "m-l-15",
                      staticStyle: { height: "36px", "line-height": "45px" },
                      model: {
                        value: _vm.radioCarousel,
                        callback: function ($$v) {
                          _vm.radioCarousel = $$v
                        },
                        expression: "radioCarousel",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("关闭"),
                        _vm.radioCarousel === 1
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "30px" } },
                              [_vm._v("轮播时长")]
                            )
                          : _vm._e(),
                      ]),
                      _vm.radioCarousel === 1
                        ? _c("el-input-number", {
                            staticStyle: {
                              "margin-left": "-20px",
                              width: "150px",
                            },
                            attrs: { placeholder: "请输入轮播时长", min: 1 },
                            model: {
                              value: _vm.carousel_num,
                              callback: function ($$v) {
                                _vm.carousel_num = $$v
                              },
                              expression: "carousel_num",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.screenDetail.show_batch_time === 1
                ? _c(
                    "div",
                    { staticClass: "carousel" },
                    [
                      _c(
                        "div",
                        { staticClass: "com-text" },
                        [
                          _vm._v(" 批次时间规则 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content:
                                  "开启批次倒计时功能：请先勾选批次倒计时，在进行选择本选项",
                                effect: "dark",
                                placement: "right",
                              },
                            },
                            [_c("i", { staticClass: "tips el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "m-l-15",
                          model: {
                            value: _vm.show_time_type,
                            callback: function ($$v) {
                              _vm.show_time_type = $$v
                            },
                            expression: "show_time_type",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled:
                                  _vm.screenDetail.show_batch_time === 0,
                                label: 1,
                              },
                            },
                            [_vm._v("以间隔时长为准")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                disabled:
                                  _vm.screenDetail.show_batch_time === 0,
                                label: 0,
                              },
                            },
                            [_vm._v("以持续时长为准")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.projectList.cate_car == 1 &&
              _vm.projectList.car_list_show_type !== 1
                ? _c(
                    "div",
                    { staticClass: "house-res" },
                    [
                      _c("div", { staticClass: "com-text" }, [
                        _vm._v("平面图模式"),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              { staticClass: "checkd-all" },
                              [
                                _c("el-checkbox", {
                                  attrs: { label: "开启" },
                                  model: {
                                    value: _vm.isMap,
                                    callback: function ($$v) {
                                      _vm.isMap = $$v
                                    },
                                    expression: "isMap",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.isMap
                            ? _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        size: "small",
                                        multiple: "",
                                        placeholder: "请选择平面图",
                                      },
                                      model: {
                                        value: _vm.mapIds,
                                        callback: function ($$v) {
                                          _vm.mapIds = $$v
                                        },
                                        expression: "mapIds",
                                      },
                                    },
                                    _vm._l(_vm.allMaplist, function (item) {
                                      return _c("el-option", {
                                        key: item.id + "",
                                        attrs: {
                                          label:
                                            (item.build_name || "") +
                                            (item.unit_name || "") +
                                            (item.floor_name || ""),
                                          value: item.id + "",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isMap
                ? _c(
                    "div",
                    { staticClass: "house-res" },
                    [
                      _c("div", { staticClass: "com-text" }, [
                        _vm._v(_vm._s(_vm.customSetting.title) + " *"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkd-all" },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "全部" },
                            on: { change: _vm.checkAllChange },
                            model: {
                              value: _vm.isCheckAll,
                              callback: function ($$v) {
                                _vm.isCheckAll = $$v
                              },
                              expression: "isCheckAll",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "c-header" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.customSetting.custom_data.hosue_car_num
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "villa-setting-btn",
                          staticStyle: {
                            float: "right",
                            "margin-top": "-33px",
                            "margin-right": "20px",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isShowVilaSetting = true
                            },
                          },
                        },
                        [_vm._v("每行户数")]
                      ),
                      _c(
                        "el-table",
                        {
                          ref: "alertTable",
                          staticClass: "house-res-table",
                          staticStyle: {
                            width: "100%",
                            "background-color": "rgb(250, 250, 250)",
                          },
                          attrs: {
                            data: _vm.screenHouseRes,
                            "show-header": false,
                            border: "",
                            height: "300",
                            "highlight-current-row": "",
                            size: "medium",
                          },
                        },
                        [
                          _c("el-table-column", {
                            staticClass: "my-cell",
                            attrs: {
                              align: "justify",
                              height: "auto",
                              prop: "name",
                              width: "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { value: scope.row.isChecked },
                                          on: {
                                            change: function ($event) {
                                              return _vm.screenSettingUnitChange(
                                                scope.row.id,
                                                scope.row.build_id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.show_name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              286782371
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.customSetting.custom_data.house_num,
                              align: "justify",
                              prop: "noList",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "content_isShowZK" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                text_isShowZK:
                                                  scope.row.isShowZK === 1,
                                                textContainerRoom: true,
                                              },
                                              staticStyle: {
                                                width: "100%",
                                                "text-align": "left",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "text_isShowZK moreRoom",
                                              }),
                                              scope.row.isShowZK === 1
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "btn_isShowZK1",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onBtnRoomIsShowZK(
                                                            scope.$index,
                                                            2
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down",
                                                      }),
                                                      _vm._v("展开 "),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                scope.row.noList,
                                                function (item, i) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: i,
                                                      attrs: {
                                                        value: item.isChecked,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          item.isChecked =
                                                            !item.isChecked
                                                          _vm.roomNumClick(
                                                            scope,
                                                            item.isChecked
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.name) + " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              scope.row.isShowZK === 2
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "btn_isShowZK2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onBtnRoomIsShowZK(
                                                            scope.$index,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-up",
                                                      }),
                                                      _vm._v("收起 "),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              76919540
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              width: "70",
                              label: "每行户数",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: scope.row.isChecked,
                                              expression: "scope.row.isChecked",
                                            },
                                          ],
                                          staticClass: "villa-setting-wrapper",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "villa-setting-btn",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isShowVilaSetting = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.cate === 1 &&
                                                    !scope.row.floor_num
                                                    ? "默认"
                                                    : scope.row.floor_num
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2284270753
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isScreenShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.screenSave(0)
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.isShowVilaSetting,
            width: "40%",
            top: "0",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowVilaSetting = $event
            },
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm._v(" 设置区域户数 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    content: "即设置大屏上每区域每行显示的房源数量。",
                    effect: "dark",
                    placement: "right",
                  },
                },
                [_c("i", { staticClass: "tips el-icon-question" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "settingVila-content",
              staticStyle: { height: "75vh", "overflow-y": "auto" },
            },
            [
              _c(
                "div",
                { staticStyle: { padding: "10px", background: "#fafafa" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 5 },
                        },
                        [_vm._v(" 楼栋区域 ")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 5 },
                        },
                        [_vm._v(" 数量 ")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 9 },
                        },
                        [
                          _vm._v(" 每行数量 "),
                          _vm.floorNumCount && _vm.floorNumCount !== "0"
                            ? _c("span", [
                                _vm._v("（" + _vm._s(_vm.floorNumCount) + "）"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { span: 5 },
                        },
                        [_vm._v(" 预计行数 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.screenHouseRes, function (item, i) {
                return _c(
                  "span",
                  { key: i },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "vilaSetting",
                        refInFor: true,
                        attrs: { model: item },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isChecked,
                                expression: "item.isChecked",
                              },
                            ],
                            attrs: {
                              rules: [
                                {
                                  required: true,
                                  message: "数值不能为空",
                                  trigger: "blur",
                                },
                              ],
                              prop: "floor_num",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hover-list",
                                staticStyle: {
                                  padding: "0 10px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 5 } }, [
                                      _vm._v(
                                        " " + _vm._s(item.show_name) + " "
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 5 } }, [
                                      _vm._v(
                                        " " + _vm._s(item.noList.length) + " "
                                      ),
                                    ]),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 9 } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            disabled: item.is_floor_num0,
                                            clearable: "",
                                            min: "1",
                                            size: "small",
                                            type: "number",
                                          },
                                          model: {
                                            value: item.floor_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "floor_num",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "item.floor_num",
                                          },
                                        }),
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            model: {
                                              value: item.is_floor_num0,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "is_floor_num0",
                                                  $$v
                                                )
                                              },
                                              expression: "item.is_floor_num0",
                                            },
                                          },
                                          [_vm._v("默认")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 5 } }, [
                                      _c(
                                        "span",
                                        {
                                          style:
                                            item.floor_num === ""
                                              ? "color:red"
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.floor_num === ""
                                                  ? "异常"
                                                  : item.floor_num === 0
                                                  ? "默认"
                                                  : Math.ceil(
                                                      item.noList.length /
                                                        item.floor_num
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.screenSave(1)
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "default-img" }, [
      _c("img", {
        staticClass: "d-img",
        attrs: {
          alt: "还未创建活动哦~",
          src: require("../../assets/image/icon-qs.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }