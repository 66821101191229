var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-header-left" },
        [
          _c("event-select", {
            attrs: { "event-list": _vm.eventList },
            on: { select_round: _vm.selectRoundHandle },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "page-header-right" }),
    ]),
    _c(
      "div",
      { staticClass: "page-content" },
      [
        _vm._l(_vm.customerStatus, function (item) {
          return [
            item.enable
              ? _c("div", { key: item.key, staticClass: "room-status-item" }, [
                  _c("div", { staticClass: "room-status-item-header" }, [
                    _c("div", { staticClass: "room-status-item-header-left" }, [
                      _c("img", {
                        staticClass: "room-status-item-header-left-icon",
                        attrs: { src: item.icon, alt: "" },
                      }),
                      _c("span", [_vm._v(_vm._s(item.title))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "room-status-item-header-right" },
                      [
                        item.isDone
                          ? _c("div", { staticClass: "room-status-isDone" }, [
                              _c("span", [_vm._v(_vm._s(item.tips))]),
                              _c("i", {
                                staticClass:
                                  "el-icon-success room-status-item-header-right-icon",
                              }),
                            ])
                          : _c("div", { staticClass: "room-status-notDone" }, [
                              _c("span", [_vm._v(_vm._s(item.tips))]),
                              _c("i", {
                                staticClass:
                                  "el-icon-error room-status-item-header-right-icon",
                              }),
                            ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "room-status-item-content" },
                    [
                      item.key === "login_status" && item.isDone
                        ? [
                            _c("div", { staticClass: "order-item" }, [
                              _c("span", [
                                _vm._v(
                                  "最近登录时间：" +
                                    _vm._s(
                                      _vm
                                        .moment(
                                          _vm.eventData.login_info.login_time *
                                            1000
                                        )
                                        .format("YYYY/MM/DD HH:mm")
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        : item.key === "participate_status" && item.isDone
                        ? void 0
                        : item.key === "sign_in_status" && item.isDone
                        ? [
                            _c("div", { staticClass: "order-item" }, [
                              _c("span", [
                                _vm._v(
                                  "签到时间：" +
                                    _vm._s(
                                      _vm
                                        .moment(
                                          _vm.eventData.sign_in_info.sign_time *
                                            1000
                                        )
                                        .format("YYYY/MM/DD HH:mm")
                                    )
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "操作人：" +
                                    _vm._s(_vm.eventData.sign_in_info.sign_user)
                                ),
                              ]),
                            ]),
                          ]
                        : item.key === "rand_batch_status" && item.isDone
                        ? [
                            _c(
                              "div",
                              { staticClass: "order-item" },
                              [
                                _vm.eventData.rand_batch_type == 1
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.eventData.batch_info.batch_code
                                          )
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "到号时间：" +
                                            _vm._s(
                                              _vm
                                                .moment(
                                                  _vm.eventData.batch_info
                                                    .batch_time * 1000
                                                )
                                                .format("YYYY/MM/DD HH:mm")
                                            )
                                        ),
                                      ]),
                                    ]
                                  : [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.eventData.rand_info.rand_level
                                              ? _vm.eventData.rand_info
                                                  .rand_level + "-"
                                              : ""
                                          ) +
                                            _vm._s(
                                              _vm.eventData.rand_info.rand_round
                                                ? _vm.eventData.rand_info
                                                    .rand_round + "-"
                                                : ""
                                            ) +
                                            _vm._s(
                                              _vm.eventData.rand_info.rand_code
                                            )
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "到号时间：" +
                                            _vm._s(
                                              _vm
                                                .moment(
                                                  _vm.eventData.rand_info
                                                    .rand_time * 1000
                                                )
                                                .format("YYYY/MM/DD HH:mm")
                                            )
                                        ),
                                      ]),
                                    ],
                              ],
                              2
                            ),
                          ]
                        : item.key === "order_status" && item.isDone
                        ? [
                            _vm._l(_vm.eventData.order_list, function (_item) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: _item.id,
                                    staticClass: "order-item",
                                    style: {
                                      color:
                                        _item.handled == -1 ? "#ccc" : "#000",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("订单：" + _vm._s(_item.order_sn)),
                                    ]),
                                    _c("span", [_vm._v(_vm._s(_item.room))]),
                                    _item.created_micro_time
                                      ? _c("span", [
                                          _vm._v(
                                            "下单时间：" +
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    _item.created_micro_time
                                                  )
                                                  .format("YYYY/MM/DD HH:mm")
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        "下单人：" +
                                          _vm._s(
                                            _item.admin_name ||
                                              _item.next_person_user
                                          )
                                      ),
                                    ]),
                                    _item.expires_type
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.order_expires[
                                                  _item.expires_type
                                                ]
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _item.handled == -1
                                      ? _c("img", {
                                          staticClass: "order-item-status-img",
                                          attrs: {
                                            src: require("@/assets/image/shixiao.png"),
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            }),
                          ]
                        : item.key === "pay_status" && item.isDone
                        ? [
                            _vm._l(_vm.eventData.pay_list, function (_item) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: _item.id,
                                    class: [
                                      "order-item",
                                      _item.pay_time
                                        ? "success-icon"
                                        : "error-icon",
                                    ],
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("订单：" + _vm._s(_item.order_sn)),
                                    ]),
                                    _c("span", [_vm._v(_vm._s(_item.room))]),
                                    _item.pay_time
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _item.handled === 2
                                                    ? "red"
                                                    : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "已付定金：" +
                                                  _vm._s(_item.price) +
                                                  " " +
                                                  _vm._s(
                                                    _item.deduction
                                                      ? "(诚意金已抵" +
                                                          _item.deduction +
                                                          ")"
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              "交定时间：" +
                                                _vm._s(
                                                  _vm
                                                    .moment(
                                                      _item.pay_time * 1000
                                                    )
                                                    .format("YYYY/MM/DD HH:mm")
                                                )
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "操作人：" +
                                                _vm._s(_item.next_person_user)
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            }),
                          ]
                        : item.key === "row_status" && item.isDone
                        ? [
                            _c("div", { staticClass: "order-item" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.eventData.row_info.rand_level
                                      ? _vm.eventData.row_info.rand_level + "-"
                                      : ""
                                  ) +
                                    _vm._s(
                                      _vm.eventData.row_info.rand_round
                                        ? _vm.eventData.row_info.rand_round +
                                            "-"
                                        : ""
                                    ) +
                                    _vm._s(_vm.eventData.row_info.rand_code)
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  "到号时间：" +
                                    _vm._s(
                                      _vm
                                        .moment(
                                          _vm.eventData.row_info.rand_time *
                                            1000
                                        )
                                        .format("YYYY/MM/DD HH:mm")
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        : item.key === "printing_status" && item.isDone
                        ? [
                            _vm._l(
                              _vm.eventData.printing_list,
                              function (_item) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: _item.id,
                                      class: [
                                        "order-item",
                                        _item.is_printing == 2
                                          ? "success-icon"
                                          : "error-icon",
                                      ],
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "订单：" + _vm._s(_item.order_sn)
                                        ),
                                      ]),
                                      _c("span", [_vm._v(_vm._s(_item.room))]),
                                      _item.is_printing == 2
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                "打印时间：" +
                                                  _vm._s(
                                                    _vm
                                                      .moment(
                                                        _item.printing_time *
                                                          1000
                                                      )
                                                      .format(
                                                        "YYYY/MM/DD HH:mm"
                                                      )
                                                  )
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "操作人：" +
                                                  _vm._s(_item.printing_name)
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              }
                            ),
                          ]
                        : item.key === "sgs_status" && item.isDone
                        ? [
                            _vm._l(
                              _vm.eventData.sgs_list,
                              function (_item, _index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: _index,
                                      class: [
                                        "order-item",
                                        _item.status == 2
                                          ? "success-icon"
                                          : "error-icon",
                                      ],
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("产权人" + _vm._s(_index + 1)),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_item.real_name) +
                                            " " +
                                            _vm._s(_item.tel)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ]
                        : item.key === "contract_status" && item.isDone
                        ? [
                            _vm.parameter.list &&
                            _vm.parameter.list.sign_up_type == 1
                              ? [
                                  _vm._l(
                                    _vm.eventData.contract_list,
                                    function (_item) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: _item.id,
                                            class: [
                                              "order-item",
                                              _item.sign_time
                                                ? "success-icon"
                                                : "error-icon",
                                            ],
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "订单：" +
                                                  _vm._s(_item.order_sn)
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v(_vm._s(_item.room)),
                                            ]),
                                            _item.sign_time
                                              ? _c("span", [
                                                  _vm._v(
                                                    "签约时间：" +
                                                      _vm._s(
                                                        _vm
                                                          .moment(
                                                            _item.sign_time *
                                                              1000
                                                          )
                                                          .format(
                                                            "YYYY/MM/DD HH:mm"
                                                          )
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _item.user_name
                                              ? _c("span", [
                                                  _vm._v(
                                                    "操作人：" +
                                                      _vm._s(_item.user_name)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _item.contract_user &&
                                        _item.contract_user.length
                                          ? [
                                              _vm._l(
                                                _item.contract_user,
                                                function (_item_, _index_) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        key: _index_,
                                                        class: [
                                                          "order-item",
                                                          _item_.status == 1
                                                            ? "success-icon"
                                                            : "error-icon",
                                                        ],
                                                        staticStyle: {
                                                          "padding-left":
                                                            "20px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "产权人" +
                                                              _vm._s(
                                                                _index_ + 1
                                                              )
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _item_.real_name
                                                            ) +
                                                              "，" +
                                                              _vm._s(_item_.tel)
                                                          ),
                                                        ]),
                                                        _item_.times
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "签约时间：" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .moment(
                                                                        _item_.times *
                                                                          1000
                                                                      )
                                                                      .format(
                                                                        "YYYY/MM/DD HH:mm"
                                                                      )
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm.parameter.list &&
                                _vm.parameter.list.sign_up_type == 2
                              ? [
                                  _vm._l(
                                    _vm.eventData.contract_list,
                                    function (_item) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: _item.id,
                                            class: [
                                              "order-item",
                                              _item.sign_time
                                                ? "success-icon"
                                                : "error-icon",
                                            ],
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "订单：" +
                                                  _vm._s(_item.order_sn)
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v(_vm._s(_item.room)),
                                            ]),
                                            _item.sign_time
                                              ? _c("span", [
                                                  _vm._v(
                                                    "签约时间：" +
                                                      _vm._s(
                                                        _vm
                                                          .moment(
                                                            _item.sign_time *
                                                              1000
                                                          )
                                                          .format(
                                                            "YYYY/MM/DD HH:mm"
                                                          )
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _item.admin_name
                                              ? _c("span", [
                                                  _vm._v(
                                                    "操作人：" +
                                                      _vm._s(_item.admin_name)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : item.key === "archive_status" && item.isDone
                        ? [
                            _vm._l(
                              _vm.eventData.archive_list,
                              function (_item) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: _item.id,
                                      class: [
                                        "order-item",
                                        _item.time
                                          ? "success-icon"
                                          : "error-icon",
                                      ],
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "订单：" + _vm._s(_item.order_sn)
                                        ),
                                      ]),
                                      _c("span", [_vm._v(_vm._s(_item.room))]),
                                      _item.time
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                "存档时间：" +
                                                  _vm._s(
                                                    _vm
                                                      .moment(_item.time * 1000)
                                                      .format(
                                                        "YYYY/MM/DD HH:mm"
                                                      )
                                                  )
                                              ),
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                "操作人：" +
                                                  _vm._s(_item.next_person_user)
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              }
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }