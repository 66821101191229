//
//
//
//
//
//
//
//
import pdf from 'vue-pdf';
import Api from '../../../api/deposit';
export default {
  name: 'Contract',
  components: {
    pdf: pdf
  },
  data: function data() {
    return {
      infoData: {}
    };
  },
  created: function created() {
    this.getContract();
  },
  methods: {
    getContract: function getContract() {
      var _this = this;

      Api.getContract({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var info = res.data.info;
          _this.infoData = info.result;
        }
      });
    }
  }
};