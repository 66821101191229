import request from '@/utils/request';
export function getUserList(data) {
  // 搜索用户
  return request({
    url: '/admission/Search',
    method: 'post',
    data: data
  });
}
export function admissionIndex(data) {
  return request({
    url: '/admission/Index',
    method: 'post',
    data: data
  });
}
export function SelectToNo(data) {
  return request({
    url: '/admission/SelectToNo',
    method: 'post',
    data: data
  });
}
export function admissionCancel(data) {
  return request({
    url: '/admission/Cancel',
    method: 'post',
    data: data
  });
}
export function ReleaseExport(data) {
  return request({
    url: '/admission/ReleaseExport',
    method: 'post',
    data: data
  });
}
export function ReleaseLog(data) {
  return request({
    url: '/admission/ReleaseLog',
    method: 'post',
    data: data
  });
} // 入场记录

export function Index(data) {
  return request({
    url: '/admission/Index',
    method: 'post',
    data: data
  });
}