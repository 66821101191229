import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    labelWidth: {
      type: String,
      default: ''
    },
    labelAlign: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      defualt: true
    }
  },
  inject: ['groupWidth', 'groupAlign'],
  computed: {
    cellItemWidth: function cellItemWidth() {
      // 优先使用自己的
      if (!!this.labelWidth) return this.labelWidth; // 没有使用父级的

      if (!!this.groupWidth) return this.groupWidth; // 最后使用默认的

      return 'auto';
    },
    align: function align() {
      // 优先使用自己的
      if (!!this.labelAlign) return this.labelAlign; // 没有使用父级的

      if (!!this.groupAlign) return this.groupAlign; // 最后使用默认的

      return 'left';
    }
  },
  methods: {
    clickValue: function clickValue() {
      this.$emit('clickValue');
    }
  }
};