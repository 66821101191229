import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
import echarts from 'echarts';
export default {
  name: 'PieChart',
  props: {
    // e-chart id
    id: {
      type: String,
      default: 'pie-chart'
    },
    // e-chart 数据
    chartData: {
      type: Array,
      default: function _default() {
        return [{
          value: 735,
          name: 'Direct'
        }, {
          value: 580,
          name: 'Email'
        }, {
          value: 484,
          name: 'Union Ads'
        }, {
          value: 1048,
          name: 'Search Engine'
        }, {
          value: 580,
          name: 'Email'
        }];
      }
    },
    // e-chart 名称
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    picHeight: function picHeight() {
      var height = 550;

      if (this.chartData.length > 5) {
        var addrows = Math.floor(this.chartData.length / 5);
        height += addrows * 30;
      }

      return height;
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    chartData: function chartData() {
      this._init_();
    }
  },
  created: function created() {
    this.$emit('setRenderState', false);
  },
  mounted: function mounted() {
    this._init_();
  },
  methods: {
    _init_: function _init_() {
      var _this = this;

      var chartDom = document.getElementById(this.id);
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '2%',
          left: 'center',
          icon: 'circle',
          itemGap: 20,
          itemWidth: 13,
          textStyle: {
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.5)'
          },
          // type: 'scroll',
          pageIconColor: '#666',
          pageIconSize: 10,
          pageTextStyle: {
            color: '#999',
            fontSize: 20
          }
        },
        series: [{
          name: this.name,
          type: 'pie',
          center: ['50%', '46%'],
          radius: ['30%', '60%'],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          color: ['#21C5DA', '#69DEB1', '#FFBF7D', '#FF757E ', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
          label: {
            formatter: '{d}%({b})',
            // {a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}
            color: '#2A2A2A',
            fontSize: 16,
            fontFamily: 'DIN'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            smooth: 0.2,
            length: 20,
            lineStyle: {
              type: 'dashed'
            }
          },
          data: this.chartData
        }]
      };
      option && myChart.setOption(option);
      this.$nextTick(function () {
        _this.$emit('setRenderState', true);
      });
    }
  }
};