import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    payInfo: {
      type: Array,
      default: function _default() {
        return [{}, {}, {
          by_stages_number: 3
        }];
      }
    }
  },
  data: function data() {
    return {
      decPay: {}
    };
  },
  created: function created() {// 监听分期期数信息填写
    // this.wacthPay()
  },
  watch: {},
  methods: {
    wacthPay: function wacthPay() {
      var _this2 = this;

      var _this = this;

      var _loop = function _loop(i) {
        if (_this2.payInfo[i].type != 0 && _this2.payInfo[i].type != 1) {
          console.log(_this2.payInfo[i]);

          _this2.$watch(function () {
            var _this$payInfo$i;

            // 第一个函数就是处理你要监听的属性，只要将其return出去就行
            return (_this$payInfo$i = _this.payInfo[i]) === null || _this$payInfo$i === void 0 ? void 0 : _this$payInfo$i.by_stages_number;
          }, function (val, oldVal) {
            if (val === '') {
              _this.payInfo[i].pay_by_stages = [];
            }

            var arrlenght = _this.payInfo[i].pay_by_stages ? _this.payInfo[i].pay_by_stages.length : 0;

            if (!_this.payInfo[i].pay_by_stages) {
              _this.payInfo[i].pay_by_stages = [];
            }

            if (val > arrlenght) {
              var leng = val - arrlenght;
              console.log(leng);

              for (var j = 1; j <= leng; j++) {
                var obj = {
                  pay_info_id: _this.payInfo[i].id,
                  pay_proportion: 0,
                  reservation_time: 0,
                  sort: 0
                };

                _this.payInfo[i].pay_by_stages.push(obj);
              }
            } else {
              _this.payInfo[i].pay_by_stages.length = val;
            }
          });
        }
      };

      for (var i = 0; i < this.payInfo.length; i++) {
        _loop(i);
      }
    },
    deltstages: function deltstages(index, deltIndex) {
      this.payInfo[index].pay_by_stages.splice(deltIndex, 1);
      this.$forceUpdate();
    },
    addstages: function addstages(index) {
      if (this.payInfo[index].pay_by_stages.length < this.payInfo[index].by_stages_number) {
        //最大期数 列表不能大于最大期数
        this.payInfo[index].pay_by_stages.push({
          pay_info_id: this.payInfo[index].id,
          pay_proportion: 0,
          reservation_time: 0,
          sort: 0
        });
      }

      this.$forceUpdate();
    },
    onInput: function onInput() {
      this.$forceUpdate();
    },
    save: function save() {
      var errIndex = 0;
      var flag = true;
      var errInfo = {};

      try {
        //表单填写认证
        this.payInfo.map(function (item, index) {
          if (item.type == 0) {
            //一次性
            if (item.reservation_time === '') {
              flag = false;
              throw {
                type: 1,
                msg: item.show_name + '天数不能为空'
              };
            }
          } else if (item.type == 1) {
            //按揭
            if (item.reservation_time === '') {
              flag = false;
              throw {
                type: 1,
                msg: item.show_name + '天数不能为空'
              };
            }

            if (item.pay_proportion === '' || item.pay_proportion == 0) {
              flag = false;
              throw {
                type: 2,
                msg: item.show_name + '付款比例不能为0或空'
              };
            }
          } else if (item.type != 0 && item.type != 1) {
            if (item.by_stages_number === '') {
              flag = false;
              throw {
                type: 1,
                msg: item.show_name + '分期数不能为空'
              };
            }

            var per = 0;
            item.pay_by_stages.map(function (items, indexs) {
              per += items.pay_proportion;

              if (items.reservation_time === '') {
                //时间不能为空
                flag = false;
                throw {
                  type: 1,
                  msg: item.show_name + '第' + (indexs + 1) + '期' + '时间不能为空'
                };
              }
            });

            if (per != 100) {
              errIndex = index;
              flag = false;
              throw {
                type: 1,
                msg: item.show_name + '比例不等于百分之百,请重新填写'
              };
            }
          }
        });
      } catch (err) {
        errInfo = err;
      }

      console.log(errInfo);

      if (flag) {
        this.close();
        this.$emit('save');
      } else {
        this.$message({
          type: 'error',
          message: errInfo.msg
        });
      }
    },
    cance: function cance() {
      //取消
      this.close();
    },
    close: function close() {
      this.payInfo.map(function (item) {
        //期数sort赋值
        if (item.pay_by_stages && item.pay_by_stages.length > 0) {
          item.pay_by_stages.map(function (items, index) {
            items.sort = index + 1;
          });
        }
      });
      this.$emit('close', false);
    }
  }
};