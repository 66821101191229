import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'searchUserList',
  data: function data() {
    return {
      uao_list: {},
      userList: [],
      checkedName: ''
    };
  },
  props: {
    users: {
      type: Array,
      default: []
    },
    // 是否默认选中
    isDefaultSelect: {
      type: Boolean,
      default: true
    },
    // 是否多选
    isCheckbox: {
      type: Boolean,
      default: false
    },
    // 类型  0：协助选房  1：订单
    type: {
      type: Number,
      default: 0
    }
  },
  created: function created() {
    this.userList = this.users || [];
    var isChecked = false;

    if (this.isDefaultSelect) {
      this.checkedName = this.userList.length ? this.userList[0].name : '';
    }

    for (var i = 0; i < this.userList.length; i++) {
      if (!this.isDefaultSelect) {
        this.userList[i].checked = false;
      }

      var status = 'succeed';

      if (this.type === 0 || this.type === 1) {
        var key = this.type === 0 ? 'rand_time' : 'xf_time';
        this.userList[i].tips = this.type === 0 ? '可选房' : '有订单';

        if (this.userList[i][key] == 0) {
          if (i === 0 && this.isDefaultSelect) {
            isChecked = true;
            this.userList[i].checked = false;
            this.checkedName = '';
          }

          this.userList[i].tips = this.type === 0 ? '未到号' : '无订单';
          status = 'error';
        } else if (isChecked) {
          this.checkedName = this.userList[i].name;
          this.userList[i].checked = true;
          isChecked = false;
        }
      }

      this.userList[i][status] = true; // this.userList[i].TEL = this.iphone(this.userList[i].tel);
      // this.userList[i].CARD = this.card(this.userList[i].card);

      this.uao_list[this.userList[i].id] = [];
      var names = this.userList[i].uao_name.split(',');
      var cards = this.userList[i].uao_card.split(',');
      var tels = this.userList[i].uao_tel.split(',');

      for (var l = 0; l < names.length; l++) {
        this.uao_list[this.userList[i].id + ''].push({
          name: names[l],
          card: cards[l],
          tel: tels[l]
        });
      }
    }
  },
  methods: {
    // 选择用户
    selectUser: function selectUser(user) {
      var _this = this;

      if (!user.succeed) {
        return;
      }

      this.userList = this.userList.map(function (item) {
        if (item.id == user.id) {
          if (!item.checked) {
            _this.checkedName = item.name;
            item.checked = true;
          } else if (_this.isCheckbox) {
            item.checked = false;
          }
        } else if (!_this.isCheckbox) {
          item.checked = false;
        }

        return item;
      });
    },
    dialogConfirm: function dialogConfirm() {
      var ownerList = this.userList.filter(function (item) {
        return item.checked;
      });
      this.$emit('dialogConfirm', this.userList, null, ownerList, this.uao_list);
    },
    // 手机号脱敏
    iphone: function iphone(num) {
      var pho = /(\d{3})\d*(\d{4})/;
      return num.replace(pho, '$1****$2');
    },
    // 身份证脱敏
    card: function card(num) {
      var pho = /(\d{6})\d*(\d{4})/;
      return num.replace(pho, '$1****$2');
    }
  }
};