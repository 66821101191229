import _typeof from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import inputBox from '../components/input-box';
import { exportRaiStatus0, exportRaiStatus1, exportRaiStatus2, RaiStatusEdit0, RaiStatusEdit1, RaiStatusEdit2 } from '@/api/audit';
import { userDelList } from '@/api/clientSet';
import toolReviewMixin from '../toolReviewMixin';
export default {
  name: 'EditReviewDetails',
  components: {
    inputBox: inputBox
  },
  mixins: [toolReviewMixin],
  data: function data() {
    return {
      activeName: '0',
      // 审核内容
      statusInfo: [],
      collect: '',
      // 意向房源
      collect_room_cate: '',
      // 审核信息
      auditInfo: [],
      type: 1,
      userCreateInfoId: 0
    };
  },
  created: function created() {
    var _this = this;

    // let types = this.$route.query.status;
    // this.type = parseInt(this.$route.query.types);
    var row = this.toolReviewRow;
    var types = this.toolReviewRow.types;
    this.type = parseInt(this.toolReviewRow.index);

    if (types === 'rai0') {
      exportRaiStatus0({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              statusInfo = _res$data.statusInfo,
              auditInfo = _res$data.auditInfo,
              info = _res$data.info,
              collect = _res$data.collect,
              collect_room_cate = _res$data.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;

          _this.doShowParent();
        }
      });
    } else if (types === 'rai1') {
      exportRaiStatus1({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              statusInfo = _res$data2.statusInfo,
              auditInfo = _res$data2.auditInfo,
              info = _res$data2.info,
              collect = _res$data2.collect,
              collect_room_cate = _res$data2.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;

          _this.doShowParent();
        }
      });
    } else {
      exportRaiStatus2({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
              statusInfo = _res$data3.statusInfo,
              auditInfo = _res$data3.auditInfo,
              info = _res$data3.info,
              collect = _res$data3.collect,
              collect_room_cate = _res$data3.collect_room_cate;
          _this.statusInfo = _this.getInfoData(info.dataList, collect, collect_room_cate);
          _this.auditInfo = auditInfo;
          _this.userCreateInfoId = info.createInfo.id;

          _this.doShowParent();
        }
      });
    }
  },
  methods: {
    // 其他格式单选时间
    handleRadio: function handleRadio(index, type, val) {
      for (var i = 0; i < this.statusInfo.length; i++) {
        if (this.statusInfo[i].type === 0) {
          for (var j = 0; j < this.statusInfo[i].forms.length; j++) {
            // 付款方式
            if (type === 6 && this.statusInfo[i].forms[j].owner_type === 6) {
              var inx = this.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });

              for (var k = 0; k < this.statusInfo.length; k++) {
                if (this.statusInfo[k].type === 0) {
                  for (var n = 0; n < this.statusInfo[k].forms.length; n++) {
                    if (this.statusInfo[k].forms[n].owner_type === 8) {
                      this.statusInfo[k].forms[n].is_show_tp = this.statusInfo[i].forms[j].children[inx].name === '未婚';
                      this.statusInfo = JSON.parse(JSON.stringify(this.statusInfo));
                    }
                  }
                }
              } //   婚姻状态

            } else if (type === 7 && this.statusInfo[i].forms[j].owner_type === 7) {
              var _inx = this.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });

              for (var _k = 0; _k < this.statusInfo.length; _k++) {
                if (this.statusInfo[_k].type === 0) {
                  for (var _n = 0; _n < this.statusInfo[_k].forms.length; _n++) {
                    if (this.statusInfo[_k].forms[_n].owner_type === 9) {
                      this.statusInfo[_k].forms[_n].is_show_tp = this.statusInfo[i].forms[j].children[_inx].name === '全款' || this.statusInfo[i].forms[j].children[_inx].name === '一次性付款';
                      this.statusInfo = JSON.parse(JSON.stringify(this.statusInfo));
                    }
                  }
                }
              } //     户籍所在

            } else if (type === 10 && this.statusInfo[i].forms[j].owner_type === 10) {
              var _inx2 = this.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id === val;
              });

              for (var _k2 = 0; _k2 < this.statusInfo.length; _k2++) {
                if (this.statusInfo[_k2].type === 0) {
                  for (var _n2 = 0; _n2 < this.statusInfo[_k2].forms.length; _n2++) {
                    if (this.statusInfo[_k2].forms[_n2].owner_type === 12) {
                      this.statusInfo[_k2].forms[_n2].is_show_tp = this.statusInfo[i].forms[j].children[_inx2].owner_type === 11;
                      this.statusInfo = JSON.parse(JSON.stringify(this.statusInfo)); // return
                    }
                  }
                }
              }
            } else if (type === 103 && this.statusInfo[i].forms[j].owner_type === 103) {
              // 100 首付金额 101 首付金额230万  102首付金额350万 103认筹金是否本人支付  104本人支付 105他人支付 106代付承诺书
              var _index = this.statusInfo[i].forms[j].children.findIndex(function (d) {
                return d.id == val;
              });

              for (var _k3 = 0; _k3 < this.statusInfo.length; _k3++) {
                if (this.statusInfo[_k3].type === 0) {
                  for (var _n3 = 0; _n3 < this.statusInfo[_k3].forms.length; _n3++) {
                    if (this.statusInfo[_k3].forms[_n3].owner_type === 106) {
                      this.statusInfo[_k3].forms[_n3].is_show_tp = this.statusInfo[i].forms[j].children[_index].owner_type == 104 ? 1 : 0;
                      this.statusInfo = JSON.parse(JSON.stringify(this.statusInfo));
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    // 删除 产权人或者银行资料
    handleDelete: function handleDelete(index, inx, key) {
      var _this2 = this;

      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        if (key === 'owners') {
          _this2.statusInfo[index].owners.splice(inx, 1);
        } else if (key === 'banks') {
          _this2.statusInfo[index].banks.splice(inx, 1);
        }
      }).catch(function () {});
    },
    // 添加 产权人
    addPropertyOwner: function addPropertyOwner(index) {
      var data = JSON.parse(JSON.stringify(this.statusInfo[index].owners[0]));
      delete data.audit_register_form_id;
      delete data.id;
      data.forms.map(function (form) {
        delete form.owner_result.id;
        delete form.owner_result.audit_register_form_owner_id;
        delete form.owner_result.is_admin_eidt;
        form.owner_result.value = '';

        if (form.form_type === 'select') {
          form.owner_result.value2 = [];
        } else if (form.form_type === 'file') {
          form.owner_result.value2 = [];
        } else if (form.form_type === 'radio') {
          form.owner_result.value2 = [];
        }
      });
      this.statusInfo[index].owners.push(data);
    },
    // 添加 银行资料
    addPropertyBank: function addPropertyBank(index) {
      var data = JSON.parse(JSON.stringify(this.statusInfo[index].banks[0]));
      delete data.audit_register_form_id;
      delete data.id;
      data.forms.map(function (form) {
        delete form.bank_result.id;
        delete form.bank_result.audit_register_form_owner_id;
        delete form.bank_result.is_admin_eidt;
        form.bank_result.value = '';

        if (form.form_type === 'select') {
          form.bank_result.value2 = [];
        } else if (form.form_type === 'file') {
          form.bank_result.value2 = [];
        } else if (form.form_type === 'radio') {
          form.bank_result.value2 = [];
        }
      });
      this.statusInfo[index].banks.push(data);
    },
    // 处理数据
    getInfoData: function getInfoData(data, collect, collect_room_cate) {
      var _this3 = this;

      // 修改多选，图片格式
      data.forEach(function (item, index) {
        item.typeNumber = 0;
        item.typeUserNumber = 0; // 其他类型

        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.fill_in_type === 2) {
              item.typeNumber = item.typeNumber + 1;
            } else if (forms.fill_in_type === 1) {
              item.typeUserNumber = item.typeUserNumber + 1;
            }

            if (forms.form_type === 'select') {
              if (forms.normal_result.value !== '') {
                var array = [];
                forms.normal_result.value.split(',').map(function (num) {
                  array.push(parseInt(num));
                });
                forms.normal_result.value2 = array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'file') {
              if (forms.normal_result.value !== '') {
                var _array = [];
                forms.normal_result.value.split(',').map(function (img) {
                  _array.push({
                    url: _this3.imgUrl + img,
                    name: img.split('/')[img.split('/').length - 1]
                  });
                }); // 组装新数组

                forms.normal_result.value2 = _array;
              } else {
                forms.normal_result.value2 = [];
              }
            } else if (forms.form_type === 'radio') {
              if (forms.normal_result.value !== '') {
                forms.normal_result.value2 = parseInt(forms.normal_result.value); // 婚姻状态

                if (forms.owner_type === 6) {
                  var _index2 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改
                        if (_forms_.owner_type === 8 && forms.children[_index2].name === '未婚') {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  }); //   付款方式
                } else if (forms.owner_type === 7) {
                  var _index3 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改 首付比例
                        if (_forms_.owner_type === 9 && (forms.children[_index3].name === '全款' || forms.children[_index3].name === '一次性付款')) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 10) {
                  var _index4 = forms.children.findIndex(function (d) {
                    return d.id === forms.normal_result.value2;
                  });

                  data.forEach(function (_item_, _index_) {
                    if (_item_.type === 0) {
                      _item_.forms.forEach(function (_forms_, _i_) {
                        //  找出下标作为修改 首付比例
                        if (_forms_.owner_type === 12 && forms.children[_index4].owner_type === 11) {
                          _forms_.is_show_tp = true;
                        }
                      });
                    }
                  });
                } else if (forms.owner_type === 103) {
                  // 100 首付金额 101 首付金额230万  102首付金额350万 103认筹金是否本人支付  104本人支付 105他人支付 106代付承诺书
                  var _index5 = forms.children.findIndex(function (d) {
                    return d.id == forms.normal_result.value;
                  });

                  item.forms.forEach(function (_forms_) {
                    if (_forms_.owner_type === 106) _forms_.is_show_tp = forms.children[_index5].owner_type == 104 ? 1 : 0;
                  });
                }
              } else {
                forms.normal_result.value2 = '';
              }
            } else if (forms.form_type === 'popup') {
              var _item$forms;

              if (collect) {
                forms.normal_result.auto_value = JSON.parse(collect !== null && collect !== void 0 ? collect : '');
              } // 显示意向房源 隐藏回填项


              (_item$forms = item.forms) === null || _item$forms === void 0 ? void 0 : _item$forms.forEach(function (_item) {
                //   80 意向楼栋 81意向户型 82 意向面积 87 意向楼层
                if ([80, 81, 82, 87].includes(_item.owner_type)) {
                  _item.hidde = true;
                }
              });
            } else if (forms.form_type === '意向户型') {
              if (collect_room_cate) {
                forms.normal_result.auto_value = JSON.parse(collect_room_cate !== null && collect_room_cate !== void 0 ? collect_room_cate : '');
              }
            }
          }); //  产权人和银行资料
        } else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type == 1 ? 'owner' : 'bank';
          item[ownerBankKey + 's'].forEach(function (owners) {
            owners.forms.map(function (forms) {
              if (forms.fill_in_type === 2) {
                item.typeNumber = item.typeNumber + 1;
              } else {
                item.typeUserNumber = item.typeUserNumber + 1;
              }

              if (forms.form_type === 'select') {
                if (forms[ownerBankKey + '_result'].value !== '') {
                  var array = [];
                  forms[ownerBankKey + '_result'].value.split(',').map(function (num) {
                    array.push(parseInt(num));
                  });
                  forms[ownerBankKey + '_result'].value2 = array;
                } else {
                  forms[ownerBankKey + '_result'].value2 = [];
                }
              } else if (forms.form_type === 'file') {
                if (forms[ownerBankKey + '_result'].value !== '') {
                  var _array2 = [];
                  forms[ownerBankKey + '_result'].value.split(',').map(function (img) {
                    _array2.push({
                      url: _this3.imgUrl + img,
                      name: img.split('/')[img.split('/').length - 1]
                    });
                  }); // 组装新数组

                  forms[ownerBankKey + '_result'].value2 = _array2;
                } else {
                  forms[ownerBankKey + '_result'].value2 = [];
                }
              } else if (forms.form_type === 'radio') {
                if (forms[ownerBankKey + '_result'].value !== '') {
                  forms[ownerBankKey + '_result'].value2 = parseInt(forms[ownerBankKey + '_result'].value);
                } else {
                  forms[ownerBankKey + '_result'].value2 = '';
                }
              }
            });
          });
        }
      });
      return data;
    },
    // 产权人传回的图片
    handleProperty: function handleProperty(url, index) {
      var _this4 = this;

      this.statusInfo[index[0]].owners[index[1]].forms[index[2]].owner_result.value = url;
      this.$nextTick(function () {
        _this4.removeUploadImage();
      });
    },
    // 银行资料传回的图片
    handlePropertyBank: function handlePropertyBank(url, index) {
      var _this5 = this;

      this.statusInfo[index[0]].banks[index[1]].forms[index[2]].bank_result.value = url;
      this.$nextTick(function () {
        _this5.removeUploadImage();
      });
    },
    // 其他类型传回的图片
    handleOtherImg: function handleOtherImg(url, index) {
      var _this6 = this;

      this.statusInfo[index[0]].forms[index[1]].normal_result.value = url;
      this.$nextTick(function () {
        _this6.removeUploadImage();
      });
    },
    handleSchedule: function handleSchedule() {
      this.$router.push({
        name: 'Schedule'
      });
    },
    // 处理数据 提交给后台
    postData: function postData() {
      // 修改多选，图片格式
      this.statusInfo.forEach(function (item, index) {
        // 其他类型
        if (item.type === 0) {
          item.forms.map(function (forms) {
            if (forms.form_type === 'select') {
              if (typeof forms.normal_result.value2 !== 'string') {
                forms.normal_result.value = forms.normal_result.value2.join(',');
                forms.normal_result.form_id = forms.id;
              }
            } else if (forms.form_type === 'file') {
              if (typeof forms.normal_result.value !== 'string') {
                // 组装新数组
                forms.normal_result.value = forms.normal_result.value.join(',');
                forms.normal_result.form_id = forms.id;
              }
            } else if (forms.form_type === 'radio') {
              forms.normal_result.value = forms.normal_result.value2 + '';
              forms.normal_result.form_id = forms.id;
            } else {
              forms.normal_result.form_id = forms.id;
            }
          }); //  产权人
        } //  产权人和银行资料
        else if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type === 1 ? 'owner' : 'bank';
          item[ownerBankKey + 's'].forEach(function (owners) {
            owners.forms.map(function (forms) {
              forms.normal_result.form_id = forms.id;

              if (forms.form_type === 'select') {
                if (typeof forms[ownerBankKey + '_result'].value2 !== 'string') {
                  forms[ownerBankKey + '_result'].value = forms[ownerBankKey + '_result'].value2.join(',');
                }
              } else if (forms.form_type === 'file') {
                if (typeof forms[ownerBankKey + '_result'].value !== 'string') {
                  // 组装新数组
                  forms[ownerBankKey + '_result'].value = forms[ownerBankKey + '_result'].value.join(',');
                }
              } else if (forms.form_type === 'radio') {
                forms[ownerBankKey + '_result'].value = forms[ownerBankKey + '_result'].value2 + '';
              }
            });
          });
        }
      });
      return this.statusInfo;
    },
    // 字符串替换
    getString: function getString(str) {
      return str.replace(new RegExp(this.imgUrl, 'g'), '');
    },
    // 取消返回 详情页面
    handleCancel: function handleCancel() {
      var row = this.toolReviewRow;
      row.id = this.auditInfo.id;
      this.CHANGE_ROW(row);
      this.CHANGE_DRAWER_TYPE(1); // this.$router.push({
      //   name: 'ReviewDetails',
      //   query: { id: this.auditInfo.id, status: this.$route.query.status },
      // })
    },
    // 保存验证！ 是他填完
    getVerifyData: function getVerifyData() {
      var _this7 = this;

      var data = this.postData();
      var verifyType = true;

      var _loop = function _loop(s) {
        var item = data[s]; // 产权人类型或者银行资料

        if (item.type === 1 || item.type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = item.type === 1 ? 'owner' : 'bank';
          item[ownerBankKey + 's'].forEach(function (owners, ownInx) {
            var _loop2 = function _loop2(i) {
              var ownersItem = owners.forms[i];

              if (ownersItem.fill_in_type === 1 && ownersItem.parent_cause_show) {
                // 多选
                if (ownersItem.form_type === 'select') {
                  if (ownersItem[ownerBankKey + '_result'].value === '') {
                    _this7.$message({
                      type: 'error',
                      message: "\u7B2C".concat(ownInx + 1, "\u4EA7\u6743\u4EBA").concat(ownersItem.name, "\u672A\u9009\u62E9")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  } // 单选

                } else if (ownersItem.form_type === 'radio') {
                  if (ownersItem[ownerBankKey + '_result'].value === '') {
                    _this7.$message({
                      type: 'error',
                      message: "\u7B2C".concat(ownInx + 1, "\u4EA7\u6743\u4EBA").concat(ownersItem.name, "\u672A\u9009\u62E9")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  } else {
                    // 证件类型
                    if (ownersItem.owner_type === 4) {
                      var index = ownersItem.children.findIndex(function (d) {
                        return d.id === ownersItem[ownerBankKey + '_result'].value2;
                      });

                      if (ownersItem.children[index].name === '身份证') {
                        var cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
                        var HKReg = /[A-Za-z]{1}\d{6}\([0-9A-Za-z]\)/;
                        var TWReg = /[A-Z]{1}\d{9}/;
                        var AMReg = /[1|5|7]\d{6}\([0-9A-Za-z]\)/; // 找出 证件号！ value

                        for (var n = 0; n < owners.forms.length; n++) {
                          if (owners.forms[n].owner_type === 3) {
                            if (!cardReg.test(owners.forms[n][ownerBankKey + '_result'].value) && !HKReg.test(owners.forms[n][ownerBankKey + '_result'].value) && !TWReg.test(owners.forms[n][ownerBankKey + '_result'].value) && !AMReg.test(owners.forms[n][ownerBankKey + '_result'].value)) {
                              _this7.$message({
                                type: 'error',
                                message: "\u7B2C".concat(ownInx + 1, "\u4EA7\u6743\u4EBA").concat(owners.forms[n].name, "\u683C\u5F0F\u4E0D\u5BF9")
                              });

                              verifyType = false;
                              return {
                                v: void 0
                              };
                            }
                          }
                        }
                      }
                    }
                  } // 上传图片

                } else if (owners.forms[i].form_type === 'file') {
                  if (owners.forms[i][ownerBankKey + '_result'].value !== '') {
                    if (owners.forms[i][ownerBankKey + '_result'].value.split(',').length < owners.forms[i].img_number) {
                      _this7.$message({
                        type: 'error',
                        message: "\u7B2C".concat(ownInx + 1, "\u4EA7\u6743\u4EBA").concat(owners.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(owners.forms[i].img_number, "\u5F20")
                      });

                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  } else {
                    _this7.$message({
                      type: 'error',
                      message: "\u7B2C".concat(ownInx + 1, "\u4EA7\u6743\u4EBA\u8BF7\u4E0A\u4F20").concat(owners.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(owners.forms[i].img_number, "\u5F20")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  } // 输入框

                } else {
                  if (owners.forms[i][ownerBankKey + '_result'].value === '') {
                    _this7.$message({
                      type: 'error',
                      message: "\u7B2C".concat(ownInx + 1 + (item.type === 1 ? '产权人' : '银行资料')).concat(owners.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  } else {
                    var reg = /^1\d{10}$/; // 手机号

                    if (ownersItem.owner_type === 2) {
                      if (!reg.test(ownersItem[ownerBankKey + '_result'].value)) {
                        _this7.$message({
                          type: 'error',
                          message: "\u7B2C".concat(ownInx + 1 + (item.type === 1 ? '产权人' : '银行资料')).concat(ownersItem.name, "\u683C\u5F0F\u4E0D\u5BF9")
                        });

                        verifyType = false;
                        return {
                          v: void 0
                        };
                      }
                    }
                  }
                }
              }
            };

            for (var i = 0; i < owners.forms.length; i++) {
              var _ret2 = _loop2(i);

              if (_typeof(_ret2) === "object") return _ret2.v;
            }
          });
        } else {
          // 其他规则
          for (var i = 0; i < item.forms.length; i++) {
            if (item.forms[i].form_type === 'popup') {
              var newArr = item.forms[i].normal_result.auto_value;
              item.forms[i].normal_result.auto_value = typeof newArr === 'string' ? newArr : JSON.stringify(newArr);
            }

            if (item.forms[i].form_type === '意向户型') {
              var _newArr = item.forms[i].normal_result.auto_value;
              item.forms[i].normal_result.auto_value = typeof _newArr === 'string' ? _newArr : JSON.stringify(_newArr);
            }

            if (item.forms[i].fill_in_type === 1 && item.forms[i].parent_cause_show) {
              if (item.forms[i].form_type === 'select') {
                if (item.forms[i].normal_result.value === '') {
                  _this7.$message({
                    type: 'error',
                    message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                  });

                  verifyType = false;
                  return {
                    v: void 0
                  };
                }
              } else if (item.forms[i].form_type === 'radio') {
                // 不显示就 不验证
                if (!item.forms[i].is_show_tp) {
                  if (item.forms[i].normal_result.value === '') {
                    _this7.$message({
                      type: 'error',
                      message: "".concat(item.forms[i].name, "\u672A\u9009\u62E9")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                }
              } else if (item.forms[i].form_type === 'file') {
                // 隐藏不判断 --- 不验证
                if (!item.forms[i].is_show_tp) {
                  if (item.forms[i].normal_result.value !== '') {
                    if (item.forms[i].normal_result.value.split(',').length < item.forms[i].img_number) {
                      _this7.$message({
                        type: 'error',
                        message: "".concat(item.forms[i].name, "\u56FE\u7247\u81F3\u5C11\u4F20").concat(item.forms[i].img_number, "\u5F20")
                      });

                      verifyType = false;
                      return {
                        v: void 0
                      };
                    }
                  } else {
                    _this7.$message({
                      type: 'error',
                      message: "\u8BF7\u4E0A\u4F20".concat(item.forms[i].name, "\u56FE\u7247")
                    });

                    verifyType = false;
                    return {
                      v: void 0
                    };
                  }
                }
              } else {
                if (item.forms[i].normal_result.value === '' && !item.forms[i].hidde) {
                  _this7.$message({
                    type: 'error',
                    message: "".concat(item.forms[i].name, "\u4E0D\u80FD\u4E3A\u7A7A")
                  });

                  verifyType = false;
                  return {
                    v: void 0
                  };
                }
              }
            }
          }
        }
      };

      for (var s = 0; s < data.length; s++) {
        var _ret = _loop(s);

        if (_typeof(_ret) === "object") return _ret.v;
      }

      return verifyType;
    },
    // 保存数据
    handleSave: function handleSave() {
      var _this8 = this;

      if (!this.getVerifyData()) {
        return;
      } // 执行一下修改;
      // let types = this.$route.query.status


      var row = this.toolReviewRow;
      var types = this.toolReviewRow.types;

      if (types === 'rai0') {
        RaiStatusEdit0({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      } else if (types === 'rai1') {
        RaiStatusEdit1({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      } else if (types === 'rai2') {
        RaiStatusEdit2({
          id: parseInt(row.id),
          data: this.postData(),
          collect: this.collect,
          collect_room_cate: this.collect_room_cate
        }).then(function (res) {
          if (res.status === 200) {
            _this8.$message({
              type: 'success',
              message: res.data.msg
            });
          }
        });
      }
    },

    /**
     * 父级影响显示隐藏
     * @param parent_id  当前id（父级）
     * @param parent_values 当前选项ids
     * @param isShowParent 是否开始判断父级隐藏
     * @param fromItem 是否是单选传的所有数据
     */
    doShowParent: function doShowParent(isShowParent, parent_id, parent_values, fromItem) {
      var _this9 = this;

      if (isShowParent) {
        var forms = JSON.parse(JSON.stringify(this.statusInfo));
        var ids = [];

        for (var i = 0; i < forms.length; i++) {
          // 产权人或者银行资料
          if (forms[i].type === 1 || forms[i].type === 2) {
            // 1为产权人 2为银行资料
            var ownerBankKey = forms[i].type == 1 ? 'owner' : 'bank';

            for (var l = 0; l < forms[i][ownerBankKey + 's'].length; l++) {
              for (var a = 0; a < forms[i][ownerBankKey + 's'][l].forms.length; a++) {
                var item = forms[i][ownerBankKey + 's'][l].forms[a];

                if (parent_id === item.parent_pid && item.parent_pid !== 0) {
                  var parent_ids = [];

                  if (item.parent_id) {
                    parent_ids = item.parent_id.split(',');
                  }

                  var parent_cause_show = false;

                  for (var c = 0; c < parent_ids.length; c++) {
                    if (parent_values.indexOf(parent_ids[c]) >= 0) {
                      parent_cause_show = true;
                    }
                  } // 隐藏时清空之前的值


                  if (!parent_cause_show) {
                    item.normal_result.value = '';
                    item[ownerBankKey + '_result'].value = '';
                    item.normal_result.value2 = '';
                    item[ownerBankKey + '_result'].value2 = '';
                    ids.push(item.id);
                  }

                  item.parent_cause_show = parent_cause_show;
                } else if (item.parent_pid === 0) {
                  item.parent_cause_show = true;
                }

                forms[i][ownerBankKey + 's'][l].forms[a] = item;
              }
            }
          } // 其他类型
          else {
            for (var _a = 0; _a < forms[i].forms.length; _a++) {
              var _item2 = forms[i].forms[_a];

              if (parent_id === _item2.parent_pid && _item2.parent_pid !== 0) {
                var _parent_ids = [];

                if (_item2.parent_id) {
                  _parent_ids = _item2.parent_id.split(',');
                }

                var _parent_cause_show = false;

                for (var _c = 0; _c < _parent_ids.length; _c++) {
                  if (parent_values.indexOf(_parent_ids[_c]) >= 0) {
                    _parent_cause_show = true;
                  }
                } // 隐藏时清空之前的值


                if (!_parent_cause_show) {
                  _item2.normal_result.value = '';
                  _item2.normal_result.value2 = '';
                  ids.push(_item2.id);
                }

                _item2.parent_cause_show = _parent_cause_show;
              } else if (_item2.parent_pid === 0) {
                _item2.parent_cause_show = true;
              }

              forms[i].forms[_a] = _item2;
            }
          }
        }

        this.statusInfo = JSON.parse(JSON.stringify(forms));
        if (ids.length) this.doShowParentChild(ids);

        if (fromItem) {
          this.handleRadio(fromItem.down_index, fromItem.owner_type, fromItem.normal_result.value2);
        }
      } else {
        var formData = JSON.parse(JSON.stringify(this.statusInfo));
        formData.forEach(function (item) {
          if (item.type != 1 && item.type != 2) {
            item.forms.forEach(function (_item) {
              var selectId = [];

              if (_item.form_type == '置业顾问') {
                // 置业顾问
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  // 默认选择
                  selectId = _item.admins[0].id.toString().split(',');
                }

                _this9.doShowParent(true, _item.id, selectId);
              } else if (_item.form_type == 'radio' || _item.form_type == 'select') {
                // 单选 || 多选
                if (_item.normal_result.value) {
                  selectId = _item.normal_result.value.toString().split(',');
                } else if (_item.value) {
                  selectId = _item.value.toString().split(',');
                } else if (_item.select_value == 1) {
                  if (_item.form_type == 'radio') {
                    selectId = _item.children[0].id.toString().split(',');
                  } else {
                    _item.normal_result.value = selectId.join(',');
                  }
                }

                _this9.doShowParent(true, _item.id, selectId);
              }
            });
          }
        });
      }
    },
    onRoomList: function onRoomList(limit) {
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 ? void 0 : forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms;

          // 普通表单
          (_form$forms = form.forms) === null || _form$forms === void 0 ? void 0 : _form$forms.forEach(function (_item) {
            // 遍历表单问题项
            if (_item.form_type === 'popup') {
              // 意向房源
              _item.hidde = !limit;
              _item.is_required = 1;
            } else if (_item.form_type === '意向户型') {
              _item.hidde = !!limit;
              _item.is_required = 1;
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    handlePopup: function handlePopup(id, data) {
      var _this10 = this;

      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 ? void 0 : forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms2;

          // 普通表单
          (_form$forms2 = form.forms) === null || _form$forms2 === void 0 ? void 0 : _form$forms2.forEach(function (formItem) {
            // 遍历表单问题项
            if (formItem.form_type === 'popup') {
              // 意向房源
              if (formItem.id === id) {
                var _data$map;

                // 当前表单
                _this10.collect = JSON.stringify(data);
                formItem.normal_result.auto_value = JSON.stringify(data); // 修改

                var a = data.map(function (dataItem) {
                  return dataItem.room;
                });
                formItem.normal_result.value = data === null || data === void 0 ? void 0 : (_data$map = data.map(function (item) {
                  return item.name;
                })) === null || _data$map === void 0 ? void 0 : _data$map.join(); // 修改
              }
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    handleHouse: function handleHouse(id, data) {
      var _this11 = this;

      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      forms === null || forms === void 0 ? void 0 : forms.forEach(function (form) {
        // 遍历表单
        if (form.type === 0) {
          var _form$forms3;

          // 普通表单
          (_form$forms3 = form.forms) === null || _form$forms3 === void 0 ? void 0 : _form$forms3.forEach(function (formItem) {
            // 遍历表单问题项
            if (formItem.form_type === '意向户型') {
              // 意向房源
              if (formItem.id === id) {
                var _data$map2;

                // 当前表单
                _this11.collect_room_cate = JSON.stringify(data);
                formItem.normal_result.auto_value = JSON.stringify(data); // 修改

                formItem.normal_result.value = data === null || data === void 0 ? void 0 : (_data$map2 = data.map(function (item) {
                  return item.name;
                })) === null || _data$map2 === void 0 ? void 0 : _data$map2.join(); // 修改
              }
            }
          });
        }
      });
      this.statusInfo = forms;
    },
    // 根据父级id找到旗下的所有子级，包括子级的子级
    doShowParentChild: function doShowParentChild(parent_ids) {
      var forms = JSON.parse(JSON.stringify(this.statusInfo));
      var ids = [];

      for (var i = 0; i < forms.length; i++) {
        // 产权人或者银行资料
        if (forms[i].type === 1 || forms[i].type === 2) {
          // 1为产权人 2为银行资料
          var ownerBankKey = forms[i].type == 1 ? 'owner' : 'bank';

          for (var l = 0; l < forms[i][ownerBankKey + 's'].length; l++) {
            for (var a = 0; a < forms[i][ownerBankKey + 's'][l].forms.length; a++) {
              var item = forms[i][ownerBankKey + 's'][l].forms[a];

              if (parent_ids.indexOf(item.parent_pid) >= 0 && item.parent_pid !== 0 && item.parent_cause_show) {
                item.parent_cause_show = false;
                item.normal_result.value = '';
                item[ownerBankKey + '_result'].value = '';
                ids.push(item.id);
                forms[i][ownerBankKey + 's'][l].forms[a] = item;
              }
            }
          }
        } else {
          for (var _a2 = 0; _a2 < forms[i].forms.length; _a2++) {
            var _item3 = forms[i].forms[_a2];

            if (parent_ids.indexOf(_item3.parent_pid) >= 0 && _item3.parent_pid !== 0 && _item3.parent_cause_show) {
              _item3.parent_cause_show = false;
              _item3.normal_result.value = '';
              ids.push(_item3.id);
              forms[i].forms[_a2] = _item3;
            }
          }
        }
      }

      this.statusInfo = JSON.parse(JSON.stringify(forms));
      if (ids.length) this.doShowParentChild(ids);
    },
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'dxf', 'ufo', 'eps', 'raw', 'WMF', 'webp', 'avif', 'apng'];
      return images.indexOf(suffix) < 0 ? name : '';
    },
    // 删除因为上传的是文件而不显示的图片
    removeUploadImage: function removeUploadImage() {
      var images = document.getElementsByClassName('el-upload-list__item-thumbnail');

      for (var i = 0; i < images.length; i++) {
        if (this.fileName(images[i].src)) {
          images[i].parentNode.style['padding-left'] = '10px';
          images[i].remove();
        }
      }
    }
  }
};