import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Api_index from '@/api/index';
import { adminParameter } from '@/api/public';
import { getEventData } from '@/api/clientSet';
import eventSelect from '@/components/eventSelect';
import customActive_mixin from '../mixins/customActive_mixin';
import moment from 'moment';
export default {
  components: {
    eventSelect: eventSelect
  },
  mixins: [customActive_mixin],
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      order_expires: ['', '取消选房作废', '定金超时作废', '签约超时作废'],
      parameter: {},
      // 项目配置
      eventList: [],
      // 项目活动列表
      eventData: {},
      // 活动数据
      moment: moment // 时间格式化工具

    };
  },
  mounted: function mounted() {
    var _this = this;

    Api_index.projectLists().then(function (res) {
      var _res$data;

      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    adminParameter().then(function (res) {
      _this.parameter = _objectSpread({}, res.data);
    });
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      var _this2 = this;

      var params = {
        id: this.user.id,
        event_id: e.event.id,
        round_id: e.round.id
      };
      getEventData(params).then(function (res) {
        _this2.resHandle(res.data.data);
      });
    },
    // 返回数据处理
    resHandle: function resHandle(info) {
      if (!info) return;
      info.archive_list = info.archive_list || []; // 存档列表

      info.contract_list = info.contract_list || []; // 签约列表

      info.login_info = info.login_info || {}; // 登录信息

      info.order_list = info.order_list || []; // 订单列表

      info.pay_list = info.pay_list || []; // 定金列表

      info.printing_list = info.printing_list || []; // 打印列表

      info.rand_info = info.rand_info || {}; // 摇号记录

      info.sgs_list = info.sgs_list || []; // 认证列表

      info.sign_in_info = info.sign_in_info || {}; // 签到信息

      info.row_info = info.row_info || {}; // 签约排号信息

      var list = this.parameter.list;
      this.customerStatus.forEach(function (item) {
        if (item.key === 'login_status') {
          // 登录
          item.enable = true;

          if (info.login_status == 1) {
            item.tips = '登录过';
            item.isDone = true;
          } else {
            item.tips = '未登录过';
            item.isDone = false;
          }
        } else if (item.key === 'participate_status') {
          // 参与情况
          item.enable = list.buy_type == 1;

          if (info.participate_status == 1) {
            item.tips = '有参与';
            item.isDone = true;
          } else {
            item.tips = '无参与';
            item.isDone = false;
          }
        } else if (item.key === 'sign_in_status') {
          // 签到
          item.enable = list.sign_in == 1;

          if (info.sign_in_status == 1) {
            item.tips = '已签到';
            item.isDone = true;
          } else {
            item.tips = '未签到';
            item.isDone = false;
          }
        } else if (item.key === 'rand_batch_status') {
          // 选房排号/摇号/批次
          item.enable = info.rand_batch_type > 0;

          if (info.rand_batch_type == 1) {
            item.title = '选房批次';
          } else if (info.rand_batch_type == 2) {
            item.title = '选房摇号';
          } else if (info.rand_batch_type == 3) {
            item.title = '选房排号';
          }

          if (info.rand_batch_status == 1) {
            item.tips = '已到';
            item.isDone = true;
          } else {
            item.tips = '未到';
            item.isDone = false;
          }
        } else if (item.key === 'order_status') {
          // 选房
          item.enable = list.buy == 1;
          item.title = list.cate_car == 1 ? list.cate_room == 1 ? '选房&车位' : '选车位' : '选房'; // 改为只要存在过订单，不管是否是有效订单都显示为已选     薛

          if (info.order_list.length) {
            if (info.order_count < info.buy_count) {
              item.tips = "\u5DF2\u9009(".concat(info.order_count || 0, "/").concat(info.buy_count, ")");
            } else {
              item.tips = '已选完';
            }

            item.isDone = true;
          } else {
            item.tips = '未选';
            item.isDone = false;
          } // if (parseInt(info.order_count)) {
          //     if (info.order_count < info.buy_count) {
          //         item.tips = `已选(${info.order_count}/${info.buy_count})`
          //     } else {
          //         item.tips = '已选完'
          //     }
          //     item.isDone = true
          // } else {
          //     item.tips = '未选'
          //     item.isDone = false
          // }

        } else if (item.key === 'pay_status') {
          // 交定金
          item.enable = list.price == 1;

          if (parseInt(info.pay_count)) {
            if (info.pay_count < info.order_count) {
              item.tips = "\u5DF2\u4EA4\u5B9A(".concat(info.pay_count, "/").concat(info.order_count, ")");
            } else {
              item.tips = '交定完毕';
            }

            item.isDone = true;
          } else {
            item.tips = '未交定';
            item.isDone = false;
          }
        } else if (item.key === 'row_status') {
          // 签约排号
          item.enable = list.sign_up == 1 && list.sign_up_rand == 1;

          if (info.row_status == 1) {
            item.tips = '已排到号';
            item.isDone = true;
          } else {
            item.tips = '未排到号';
            item.isDone = false;
          }
        } else if (item.key === 'printing_status') {
          // 打印认购书
          item.enable = list.sign_up == 1;

          if (parseInt(info.printing_count)) {
            if (info.printing_count < info.order_count) {
              item.tips = "\u5DF2\u6253\u5370(".concat(info.printing_count, "/").concat(info.order_count, ")");
            } else {
              item.tips = '打印完毕';
            }

            item.isDone = true;
          } else {
            item.tips = '未打印';
            item.isDone = false;
          }
        } else if (item.key === 'sgs_status') {
          // 实名认证
          item.enable = list.sign_up == 1 && list.sign_up_type == 1;

          if (parseInt(info.sgs_count)) {
            if (info.sgs_count < info.sgs_all_count) {
              item.tips = "\u5DF2\u8BA4\u8BC1(".concat(info.sgs_count, "/").concat(info.sgs_all_count, ")");
            } else {
              item.tips = '认证完毕';
            }

            item.isDone = true;
          } else {
            item.tips = '未认证';
            item.isDone = false;
          }
        } else if (item.key === 'contract_status') {
          // 签约
          item.enable = list.sign_up == 1;

          if (parseInt(info.contract_count)) {
            if (info.contract_count < info.order_count) {
              item.tips = "\u5DF2\u7B7E\u7EA6(".concat(info.contract_count, "/").concat(info.order_count, ")");
            } else {
              item.tips = '签约完毕';
            }

            item.isDone = true;
          } else {
            item.tips = '未签约';
            item.isDone = false;
          }
        } else if (item.key === 'archive_status') {
          // 资料存档
          item.enable = list.data_file == 1;

          if (parseInt(info.archive_count)) {
            if (info.archive_count < info.order_count) {
              item.tips = "\u5DF2\u5B58\u6863(".concat(info.archive_count, "/").concat(info.order_count, ")");
            } else {
              item.tips = '存档完毕';
            }

            item.isDone = true;
          } else {
            item.tips = '未存档';
            item.isDone = false;
          }
        }
      });
      this.eventData = _objectSpread({}, info);
    }
  }
};