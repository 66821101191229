var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { width: _vm.width, height: _vm.height, minWidth: _vm.minWidth } },
    [
      _vm.disabled
        ? _c("div", { staticClass: "disabled" })
        : _c(
            "div",
            {
              staticClass: "input-block",
              style: { background: _vm.bgc, border: _vm.brc },
            },
            [
              _vm.fixedValue != ""
                ? _c("div", { staticClass: "fixed-value" }, [
                    _vm._v(_vm._s(_vm.fixedValue)),
                  ])
                : _c(
                    "div",
                    { staticClass: "value", on: { click: _vm.activeInput } },
                    [
                      _c("input", {
                        ref: "input",
                        staticClass: "inputValue",
                        attrs: {
                          type: _vm.type,
                          max: _vm.max,
                          min: _vm.min,
                          maxlength: "1",
                          minlength: "1",
                          matInput: "1",
                        },
                        on: {
                          input: _vm.inputValue,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("onKeyupEnter")
                          },
                          blur: function (e) {
                            _vm.$emit("blur", e)
                          },
                        },
                      }),
                    ]
                  ),
            ]
          ),
      _vm.hintText != ""
        ? _c("div", { staticClass: "hint-text" }, [
            _vm._v(_vm._s(_vm.hintText)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }