var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "top-fixed" }, [
        _c("div", { class: _vm.isPC ? "title-top-box" : "title-hon" }, [
          _c("div", { staticClass: "title flex" }, [
            _c("div", { staticClass: "title-h1" }, [_vm._v("参数设置")]),
            _c("div", { staticClass: "title-tips" }, [
              _c("span", { staticClass: "mL20" }, [
                _vm._v("客户端项目名称："),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.info.name) },
                }),
              ]),
              _c("span", { staticClass: "mL40" }, [
                _vm._v("项目编码："),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.info.code) },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "project-status title-tips" },
              [
                _vm.data.status
                  ? [
                      _vm.info.invalid
                        ? _c("span", { staticClass: "col1" }, [
                            _vm._v("项目状态: 已失效"),
                          ])
                        : _c("span", { staticClass: "col2" }, [
                            _vm._v("项目状态: 进行中"),
                          ]),
                    ]
                  : [
                      _vm.info.invalid
                        ? _c("span", { staticClass: "col1" }, [
                            _vm._v("项目状态: 已失效"),
                          ])
                        : _c("span", { staticClass: "col3" }, [
                            _vm._v("项目状态: 关闭"),
                          ]),
                    ],
                !_vm.info.invalid
                  ? _c("el-switch", {
                      staticClass: "mL10",
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.data.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "status", $$v)
                        },
                        expression: "data.status",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "title-status flex align-items" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "project/preserve"],
                      expression: "['admin', 'project/preserve']",
                    },
                  ],
                  staticClass: "greens-btn mL10",
                  attrs: { size: "small" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "detail-box mT10" },
        [
          _c("link-settings", { attrs: { data: _vm.data, info: _vm.info } }),
          _c("div", { staticClass: "block-box mT10" }, [
            _c("div", { staticClass: "title-h2" }, [
              _vm._v(_vm._s(_vm.customSetting.title) + "参数设置"),
            ]),
            _c(
              "div",
              { staticClass: "bottom-top" },
              [
                _c("div", { staticClass: "row flex align-items" }, [
                  _c("div", { staticClass: "row-title-left room-title" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.customSetting.title) + "详情信息"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "orange-btn-hollows",
                          attrs: { size: "mini" },
                          on: { click: _vm.handlePreview },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _vm._m(0),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-box",
                          on: { change: _vm.handelRoomPrice },
                          model: {
                            value: _vm.data.room_info_price_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "room_info_price_type", $$v)
                            },
                            expression: "data.room_info_price_type",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("一个现价"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("优惠价+原价"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("标准价+一次性价+按揭价"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-row",
                        [
                          _vm.data.room_info_price_type == 1
                            ? _c(
                                "el-col",
                                { staticClass: "mT15", attrs: { span: 23 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 7 : 24 } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [_vm._v("“现价”命名：")]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data.room_info_pin_xian_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_xian_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_xian_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.data.room_info_price_type == 2
                            ? _c(
                                "el-col",
                                { staticClass: "mT15", attrs: { span: 23 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 7 : 24 } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [
                                            _vm._v("“优惠价”命名："),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data
                                                  .room_info_pin_you_hui_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_you_hui_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_you_hui_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        span: _vm.isPC ? 7 : 24,
                                        offset: _vm.isPC ? 1 : 0,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [_vm._v("“原价”命名：")]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data.room_info_pin_yuan_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_yuan_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_yuan_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.data.room_info_price_type == 3
                            ? _c(
                                "el-col",
                                { staticClass: "mT15", attrs: { span: 23 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 7 : 24 } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [
                                            _vm._v("“标准价”命名："),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data
                                                  .room_info_pin_biao_zhun_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_biao_zhun_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_biao_zhun_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        span: _vm.isPC ? 7 : 24,
                                        offset: _vm.isPC ? 1 : 0,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [
                                            _vm._v("“一次性”命名："),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data
                                                  .room_info_pin_yi_ci_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_yi_ci_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_yi_ci_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        span: _vm.isPC ? 7 : 24,
                                        offset: _vm.isPC ? 1 : 0,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-suffix" },
                                        [
                                          _c("label", [
                                            _vm._v("“按揭价”命名："),
                                          ]),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "12",
                                              "show-word-limit": "",
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.data
                                                  .room_info_pin_an_jie_jia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.data,
                                                  "room_info_pin_an_jie_jia",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "data.room_info_pin_an_jie_jia",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _vm._m(1),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.data.room_info_price_type == 1
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_xian_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_xian_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_xian_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 一个现价 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.data.room_info_price_type == 2
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_yuan_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_yuan_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_yuan_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 原价 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_you_hui_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_you_hui_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_you_hui_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 优惠价 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.data.room_info_price_type == 3
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_biao_zhun_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_biao_zhun_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_biao_zhun_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 标准价 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_yi_ci_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_yi_ci_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_yi_ci_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 一次性价 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                          label: "1",
                                        },
                                        model: {
                                          value:
                                            _vm.data
                                              .room_info_pin_an_jie_jia_scribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_info_pin_an_jie_jia_scribe",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_info_pin_an_jie_jia_scribe",
                                        },
                                      },
                                      [_vm._v(" 按揭价 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _vm._m(2),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c("el-col", { attrs: { span: _vm.isPC ? 7 : 24 } }, [
                        _c(
                          "div",
                          { staticClass: "input-suffix" },
                          [
                            _c("label", [_vm._v("3.“成交价”命名：")]),
                            _c("el-input", {
                              attrs: {
                                maxlength: "12",
                                "show-word-limit": "",
                                size: "small",
                              },
                              model: {
                                value: _vm.data.pin_cheng_jiao_jia,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.data,
                                    "pin_cheng_jiao_jia",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "data.pin_cheng_jiao_jia",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _vm._m(3),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right operate-model flex" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-box",
                          model: {
                            value: _vm.data.room_info_area_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "room_info_area_type", $$v)
                            },
                            expression: "data.room_info_area_type",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("两位小数"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("三位小数"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _vm._m(4),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right operate-model flex" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "radio-box",
                          model: {
                            value: _vm.data.room_info_prec_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "room_info_prec_status", $$v)
                            },
                            expression: "data.room_info_prec_status",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("整数"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("两位小数"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.data.room_info_prec_status === 1
                  ? [
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _vm._m(5),
                      ]),
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right operate-model flex" },
                          [
                            _c(
                              "span",
                              { staticClass: "span-left flex-shrink" },
                              [_vm._v("单价")]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "radio-box",
                                model: {
                                  value: _vm.data.room_info_price_prec,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "room_info_price_prec",
                                      $$v
                                    )
                                  },
                                  expression: "data.room_info_price_prec",
                                },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("精确到元"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("精确到角"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("精确到分"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row flex align-items mT15" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right operate-model flex" },
                          [
                            _c(
                              "span",
                              { staticClass: "span-left flex-shrink" },
                              [_vm._v("总价")]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "radio-box",
                                model: {
                                  value: _vm.data.room_info_total_price_prec,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "room_info_total_price_prec",
                                      $$v
                                    )
                                  },
                                  expression: "data.room_info_total_price_prec",
                                },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("精确到元"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("精确到角"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("精确到分"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c("div", { staticClass: "row-title-right" }, [
                    _c(
                      "div",
                      { staticClass: "title-box" },
                      [
                        _vm._v(" 7.显示元素 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "itemtooltip",
                            attrs: { effect: "dark", placement: "right" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(
                                  " 热度: 控制客户端是否展示房源&车位热度"
                                ),
                                _c("br"),
                                _vm._v(
                                  "附赠: 影响客户端、助手端、pc端房源详情页面附赠板块的显示与隐藏 "
                                ),
                              ]
                            ),
                            _c("i", { staticClass: "tips el-icon-question" }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: "",
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_room,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_room", $$v)
                                    },
                                    expression: "data.room_info_room",
                                  },
                                },
                                [_vm._v(" 房号(车位号) ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: "",
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_price", $$v)
                                    },
                                    expression: "data.room_info_price",
                                  },
                                },
                                [_vm._v(" 价格 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: "",
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_area,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_area", $$v)
                                    },
                                    expression: "data.room_info_area",
                                  },
                                },
                                [_vm._v("面积 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_hot,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_hot", $$v)
                                    },
                                    expression: "data.room_info_hot",
                                  },
                                },
                                [_vm._v("热度 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_gift,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_gift", $$v)
                                    },
                                    expression: "data.room_info_gift",
                                  },
                                },
                                [_vm._v(" 附赠内容 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_gift_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data,
                                        "room_info_gift_price",
                                        $$v
                                      )
                                    },
                                    expression: "data.room_info_gift_price",
                                  },
                                },
                                [_vm._v(" 附赠价值 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: "",
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_info_desc,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_info_desc", $$v)
                                    },
                                    expression: "data.room_info_desc",
                                  },
                                },
                                [_vm._v(" 描述 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._m(6),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c("div", { staticClass: "row-title-right title-box" }, [
                    _vm._v("1.显示元素"),
                  ]),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    disabled: "",
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_list_room,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_list_room", $$v)
                                    },
                                    expression: "data.room_list_room",
                                  },
                                },
                                [_vm._v(" 房号(车位号) ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_list_price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_list_price", $$v)
                                    },
                                    expression: "data.room_list_price",
                                  },
                                },
                                [_vm._v(" 价格 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_list_area,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_list_area", $$v)
                                    },
                                    expression: "data.room_list_area",
                                  },
                                },
                                [_vm._v("面积 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_list_cate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_list_cate", $$v)
                                    },
                                    expression: "data.room_list_cate",
                                  },
                                },
                                [_vm._v("户型(类型)名称 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  model: {
                                    value: _vm.data.room_list_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "room_list_type", $$v)
                                    },
                                    expression: "data.room_list_type",
                                  },
                                },
                                [_vm._v(" 户型(类型)结构 ")]
                              ),
                            ],
                            1
                          ),
                          _vm.data.cate_car === 1
                            ? _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 3 : 12 } },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                        label: "1",
                                      },
                                      model: {
                                        value: _vm.data.room_list_icon,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "room_list_icon",
                                            $$v
                                          )
                                        },
                                        expression: "data.room_list_icon",
                                      },
                                    },
                                    [_vm._v(" 车位图标 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.data.room_list_price === 1
                  ? [
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right title-box" },
                          [_vm._v("2.价格显示")]
                        ),
                      ]),
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "radio-box",
                                model: {
                                  value: _vm.data.room_list_price_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "room_list_price_type",
                                      $$v
                                    )
                                  },
                                  expression: "data.room_list_price_type",
                                },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("单价"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("总价"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right" },
                          [
                            _vm.data.room_list_price_type == 1 ||
                            _vm.data.room_list_price_type == 2
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "mT15 radios-box",
                                    attrs: { span: _vm.isPC ? 12 : 24 },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "radio-box",
                                        model: {
                                          value:
                                            _vm.data.room_list_price_type_way,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "room_list_price_type_way",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.room_list_price_type_way",
                                        },
                                      },
                                      [
                                        _vm.data.room_info_price_type == 1
                                          ? [
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 3 } },
                                                    [_vm._v("现价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm.data.room_info_price_type == 2
                                          ? [
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 3 } },
                                                    [_vm._v("原价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 4 } },
                                                    [_vm._v("优惠价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : [
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 5 } },
                                                    [_vm._v("标准价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 2 } },
                                                    [_vm._v("按揭价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  attrs: {
                                                    span: _vm.isPC ? 6 : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("一次性价")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm.data.room_list_area === 1
                  ? [
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right title-box" },
                          [_vm._v("2.面积显示")]
                        ),
                      ]),
                      _c("div", { staticClass: "row flex align-items" }, [
                        _vm.isPC
                          ? _c("div", { staticClass: "row-title-left" })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-title-right" },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "radio-box",
                                    model: {
                                      value: _vm.data.room_list_area_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "room_list_area_type",
                                          $$v
                                        )
                                      },
                                      expression: "data.room_list_area_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("建筑面积")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [_vm._v("套内面积")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "row flex align-items mT40" }, [
                  _c("div", { staticClass: "row-title-left room-title" }, [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _vm._v(" 订单外显 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "推荐、订单、意向单卡片的显示元素",
                              placement: "right",
                            },
                          },
                          [_c("i", { staticClass: "tips el-icon-question" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right title-box" },
                    [
                      _vm._v(" 1.价格显示 "),
                      _vm.sp.pin_you_hui
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "项目已开启优惠价模式，优惠价将作为订单外显价。",
                                placement: "right",
                              },
                            },
                            [_c("i", { staticClass: "tips el-icon-question" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "radio-box",
                              attrs: { disabled: _vm.sp.pin_you_hui === 1 },
                              model: {
                                value: _vm.data.order_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "order_price", $$v)
                                },
                                expression: "data.order_price",
                              },
                            },
                            [
                              _vm.data.room_info_price_type == 1
                                ? [
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 3 } },
                                          [_vm._v("现价")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm.data.room_info_price_type == 2
                                ? [
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 3 } },
                                          [_vm._v("原价")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 4 } },
                                          [_vm._v("优惠价")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 5 } },
                                          [_vm._v("标准价")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [_vm._v("按揭价")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: _vm.isPC ? 3 : 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("一次性价")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c("div", { staticClass: "row-title-right title-box" }, [
                    _vm._v("2.面积显示"),
                  ]),
                ]),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "radio-box",
                              model: {
                                value: _vm.data.order_area,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "order_area", $$v)
                                },
                                expression: "data.order_area",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 3 : 12 } },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("建筑面积"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 3 : 12 } },
                                [
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("套内面积"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._m(7),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.data.is_tag_sale == 0
                            ? _c(
                                "el-radio-group",
                                {
                                  staticClass: "radio-box",
                                  model: {
                                    value: _vm.data.room_info_select,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data,
                                        "room_info_select",
                                        $$v
                                      )
                                    },
                                    expression: "data.room_info_select",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 3 : 12 } },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("启用"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: _vm.isPC ? 3 : 12 } },
                                    [
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("不启用"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: "已开启分类认购，不允许开启此功能",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "radio-box",
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.data.room_info_select,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "room_info_select",
                                            $$v
                                          )
                                        },
                                        expression: "data.room_info_select",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.isPC ? 3 : 12 } },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("启用")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.isPC ? 3 : 12 } },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [_vm._v("不启用")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._m(8),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "radio-box",
                              model: {
                                value: _vm.data.room_publish,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "room_publish", $$v)
                                },
                                expression: "data.room_publish",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 3 : 12 } },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("公布"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 3 : 12 } },
                                [
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("不公布"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "block-box mT10" }, [
            _c("div", { staticClass: "title-h2" }, [_vm._v("认购设置")]),
            _c(
              "div",
              { staticClass: "bottom-top" },
              [
                _vm._m(9),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c("div", { staticClass: "row-title-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "operate-model",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("input-text", {
                          attrs: {
                            max: 100,
                            "text-value": _vm.data.collect_count,
                            "input-w": 150,
                            "input-text": "1~100",
                          },
                          on: { handleInputValue: _vm.handleCollectCount },
                        }),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.data.buy_count_collect,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "buy_count_collect", $$v)
                              },
                              expression: "data.buy_count_collect",
                            },
                          },
                          [_vm._v("按限购套数*意向单数量控制上限")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._m(10),
                _c("div", { staticClass: "row flex align-items" }, [
                  _vm.isPC
                    ? _c("div", { staticClass: "row-title-left" })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "row-title-right align-center" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { label: 1 },
                          model: {
                            value: _vm.data.buy_start_all_time_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "buy_start_all_time_type", $$v)
                            },
                            expression: "data.buy_start_all_time_type",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c("input-text", {
                        attrs: {
                          disabled: _vm.data.buy_start_all_time_type === 0,
                          "text-value": _vm.data.buy_start_all_time_min,
                          "input-w": 80,
                          "input-text": "秒",
                        },
                        on: { handleInputValue: _vm.handleBuyTimeMin },
                      }),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.data.buy_start_all_time_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "buy_start_all_time_type", $$v)
                            },
                            expression: "data.buy_start_all_time_type",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.info.buy_type === 1 && _vm.data.show_is_buy_rand
                  ? _c("div", { staticClass: "row flex align-items mT35" }, [
                      _c("div", { staticClass: "row-title" }, [
                        _vm._v("客户到号是否延迟选房（自主选房+摇号模式）"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.info.buy_type === 1 && _vm.data.show_is_buy_rand
                  ? _c("div", { staticClass: "row flex align-items" }, [
                      _vm.isPC
                        ? _c("div", { staticClass: "row-title-left" })
                        : _vm._e(),
                      _c("div", { staticClass: "row-title-right" }, [
                        _c(
                          "div",
                          { staticClass: "operate-model" },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "radio-box",
                                        model: {
                                          value: _vm.data.rand_start_time_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "rand_start_time_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.rand_start_time_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 0 } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("是")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c("input-text", {
                                      staticStyle: { "margin-top": "-10px" },
                                      attrs: {
                                        disabled:
                                          _vm.data.rand_start_time_type != 1,
                                        "text-value":
                                          _vm.data.rand_start_time_min,
                                        "input-w": 80,
                                        prefix: "延迟",
                                        "input-text": "分钟",
                                      },
                                      on: {
                                        handleInputValue: function (v) {
                                          _vm.data.rand_start_time_min = v
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.show_is_buy_rand
                  ? _c(
                      "div",
                      { staticClass: "row flex align-items mT35 jump3" },
                      [
                        _c("div", { staticClass: "row-title" }, [
                          _vm._v("正式活动摇号结果"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.data.show_is_buy_rand
                  ? _c("div", { staticClass: "row flex align-items" }, [
                      _vm.isPC
                        ? _c("div", { staticClass: "row-title-left" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 6 : 12 } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "radio-box",
                                  model: {
                                    value: _vm.data.is_formal_rand,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "is_formal_rand", $$v)
                                    },
                                    expression: "data.is_formal_rand",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("显示"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("隐藏"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.data.show_is_sign_in
                  ? [
                      _vm.data.sign_in
                        ? [
                            _vm.data.sign_type === 1
                              ? [
                                  _vm._m(11),
                                  _c(
                                    "div",
                                    { staticClass: "row flex align-items" },
                                    [
                                      _vm.isPC
                                        ? _c("div", {
                                            staticClass: "row-title-left",
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "row-title-right" },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 21 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "operate-model flex align-items",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "span-left",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "每次活动/场次开始前"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("input-text", {
                                                    attrs: {
                                                      "text-value":
                                                        _vm.data.sign_up_time,
                                                      "input-w": 80,
                                                      "input-text":
                                                        "分钟开始签到",
                                                    },
                                                    on: {
                                                      handleInputValue:
                                                        _vm.handleSignUpTime,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.info.buy_type === 2 && !_vm.info.matching_order
                  ? _c(
                      "div",
                      { staticClass: "row flex align-items mT35 jump2" },
                      [
                        _c(
                          "div",
                          { staticClass: "row-title" },
                          [
                            _vm._v(" 认购时间提醒（协助选房） "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "到达限制时长系统会提示顾问尽快结束。",
                                  placement: "right",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "tips el-icon-question",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.info.buy_type === 2 && !_vm.info.matching_order
                  ? _c("div", { staticClass: "row flex align-items" }, [
                      _vm.isPC
                        ? _c("div", { staticClass: "row-title-left" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c("el-col", { attrs: { span: 21 } }, [
                            _c(
                              "div",
                              { staticClass: "operate-model flex align-items" },
                              [
                                _c("input-text", {
                                  attrs: {
                                    "text-value": _vm.data.buy_other_time,
                                    "input-w": 150,
                                    "input-text": "分钟",
                                  },
                                  on: {
                                    handleInputValue: _vm.handleBuyOtherTime,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.info.buy_type === 1 && _vm.data.show_is_buy_rand
                  ? _c(
                      "div",
                      { staticClass: "row flex align-items mT35 jump3" },
                      [
                        _c(
                          "div",
                          { staticClass: "row-title" },
                          [
                            _vm._v(" 认购时长限制（自主选房+摇号模式） "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "限制时长：限制客户到号后的选房时长。",
                                  placement: "right",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "tips el-icon-question",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.info.buy_type === 1 && _vm.data.show_is_buy_rand
                  ? _c("div", { staticClass: "row flex align-items" }, [
                      _vm.isPC
                        ? _c("div", { staticClass: "row-title-left" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("input-text", {
                                attrs: {
                                  disabled: _vm.data.rand_time_type
                                    ? true
                                    : false,
                                  "text-value": _vm.data.rand_time_min,
                                  "input-w": 80,
                                  "input-text": "分钟",
                                },
                                on: { handleInputValue: _vm.handleRandTime },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "line-h",
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 0,
                                    label: "1",
                                  },
                                  on: { change: _vm.handleIscheckbox },
                                  model: {
                                    value: _vm.data.rand_time_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "rand_time_type", $$v)
                                    },
                                    expression: "data.rand_time_type",
                                  },
                                },
                                [_vm._v(" 不限制 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.info.matching_order && _vm.data.is_online_rule == 0
                  ? _c(
                      "div",
                      { staticClass: "row flex align-items mT35 jump7" },
                      [
                        _c(
                          "div",
                          { staticClass: "row-title" },
                          [
                            _vm._v(" 组合订单认购时限 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "到达限制时间还未按比例认购完成的组合订单，将整体自动失效。",
                                  placement: "right",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "tips el-icon-question",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.info.matching_order && _vm.data.is_online_rule == 0
                  ? _c("div", { staticClass: "row flex align-items" }, [
                      _vm.isPC
                        ? _c("div", { staticClass: "row-title-left" })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c("input-text", {
                                attrs: {
                                  disabled: _vm.data.matching_time_type === 1,
                                  "text-value": _vm.data.matching_time_min,
                                  "input-w": 80,
                                  "input-text": "分钟",
                                },
                                on: {
                                  handleInputValue: _vm.handleMatching_time_min,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: _vm.isPC ? 3 : 12 } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass: "line-h",
                                  attrs: {
                                    "true-label": 1,
                                    "false-label": 2,
                                    label: "2",
                                  },
                                  on: { change: _vm.handleMatching_time_type },
                                  model: {
                                    value: _vm.data.matching_time_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data,
                                        "matching_time_type",
                                        $$v
                                      )
                                    },
                                    expression: "data.matching_time_type",
                                  },
                                },
                                [_vm._v(" 客户可认购时段 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.sp.pin_you_hui
                  ? _c("div", { staticClass: "row flex align-items mT35" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row-title-left0 row-title",
                          staticStyle: { width: "132px" },
                        },
                        [_vm._v(_vm._s(_vm.sp.pin_you_hui_name) + "规则设置")]
                      ),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "orange-btn-hollows",
                              staticStyle: { "margin-top": "2px" },
                              attrs: { size: "mini" },
                              on: { click: _vm.handYhuilEedit },
                            },
                            [_vm._v("编辑")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.isShowPrice
                  ? _c("div", { staticClass: "row flex align-items mT35" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row-title-left0 row-title",
                          staticStyle: { width: "132px" },
                        },
                        [_vm._v("付款方式设置")]
                      ),
                      _c(
                        "div",
                        { staticClass: "row-title-right" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "orange-btn-hollows",
                              staticStyle: { "margin-top": "2px" },
                              attrs: { size: "mini" },
                              on: { click: _vm.handlEedit },
                            },
                            [_vm._v("编辑")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm.data.price === 1 ||
          (_vm.data.sign_up === 1 &&
            _vm.info.sign_up_type === 1 &&
            ((_vm.data.price === 1 && _vm.data.price_lose == 2) ||
              _vm.data.sign_up_lose == 0)) ||
          _vm.data.online_sign_pay === 1
            ? _c("div", { staticClass: "block-box mT10" }, [
                _c("div", { staticClass: "title-h2" }, [
                  _vm._v("支付/签约设置"),
                ]),
                _c(
                  "div",
                  { staticClass: "bottom-top" },
                  [
                    (((_vm.data.price === 1 &&
                      _vm.data.price_type === 1 &&
                      _vm.data.price_lose === 2) ||
                      _vm.data.price === 0) &&
                      ((_vm.data.sign_up === 1 &&
                        _vm.info.sign_up_type === 1 &&
                        _vm.data.sign_up_lose === 0) ||
                        _vm.data.sign_up === 0)) ||
                    (_vm.info.matching_order &&
                      _vm.data.sign_up === 0 &&
                      _vm.data.price === 0)
                      ? [
                          _c("div", [
                            _vm._m(12),
                            _c("div", { staticClass: "row flex align-items" }, [
                              _vm.isPC
                                ? _c("div", { staticClass: "row-title-left" })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "row-title-right" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "radio-box",
                                          model: {
                                            value: _vm.data.is_online_rule,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.data,
                                                "is_online_rule",
                                                $$v
                                              )
                                            },
                                            expression: "data.is_online_rule",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                span: _vm.isPC ? 3 : 12,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("启用")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                span: _vm.isPC ? 3 : 12,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("不启用")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticStyle: { height: "20px" } }),
                        ]
                      : _vm._e(),
                    _vm.data.price === 1 &&
                    _vm.data.price_type === 1 &&
                    _vm.data.is_online_rule == 0 &&
                    _vm.data.price_lose == 2
                      ? [
                          _c(
                            "div",
                            { staticClass: "row flex align-items jump4" },
                            [
                              _c(
                                "div",
                                { staticClass: "row-title" },
                                [
                                  _vm._v(" 在线交定金时长限制 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "以认购时间开始计算，提醒客户尽快支付。",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tips el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "row flex align-items" }, [
                            _vm.isPC
                              ? _c("div", { staticClass: "row-title-left" })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "row-title-right" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("input-text", {
                                      attrs: {
                                        "text-value": _vm.data.online_pay,
                                        "input-w": 80,
                                        "input-text": "秒",
                                      },
                                      on: {
                                        handleInputValue: _vm.handleOnlinePay,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.data.sign_up === 1 &&
                    _vm.info.sign_up_type === 1 &&
                    _vm.data.is_online_rule == 0 &&
                    _vm.data.sign_up_lose == 0
                      ? [
                          _c(
                            "div",
                            { staticClass: "row flex align-items mT35 jump5" },
                            [
                              _c(
                                "div",
                                { staticClass: "row-title" },
                                [
                                  _vm._v(" 在线签约时长限制 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "若启用交定金环节，则以交定金后开始计算；未启用则以认购时间开始计算。",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "tips el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "row flex align-items" }, [
                            _vm.isPC
                              ? _c("div", { staticClass: "row-title-left" })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "row-title-right" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("input-text", {
                                      attrs: {
                                        "text-value": _vm.data.online_sign,
                                        "input-w": 80,
                                        "input-text": "分钟",
                                        disabled:
                                          _vm.data.online_sign_type === 1,
                                      },
                                      on: {
                                        handleInputValue: _vm.handleOnlineSign,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "line-h",
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        on: {
                                          change: _vm.handleOnline_sign_type,
                                        },
                                        model: {
                                          value: _vm.data.online_sign_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "online_sign_type",
                                              $$v
                                            )
                                          },
                                          expression: "data.online_sign_type",
                                        },
                                      },
                                      [_vm._v(" 客户可认购时段 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    (_vm.data.price === 1 || _vm.data.online_sign_pay === 1) &&
                    _vm.data.online_sign_pay_type === 0
                      ? [
                          _vm._m(13),
                          _vm._m(14),
                          _c("div", { staticClass: "row flex align-items" }, [
                            _vm.isPC
                              ? _c("div", { staticClass: "row-title-left" })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "row-title-right" },
                              [
                                _c("el-col", { attrs: { span: 3 } }, [
                                  _vm.data.cate_room === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items mB10",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-shrink span-yuan",
                                            },
                                            [_vm._v("住宅：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex align-items" },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  type: "number",
                                                  controls: false,
                                                },
                                                on: { change: _vm.handelInput },
                                                model: {
                                                  value: _vm.data.price_money,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "price_money",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "data.price_money",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "span-yuan" },
                                                [_vm._v("元")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.data.cate_car === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items mB10",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-shrink span-yuan",
                                            },
                                            [_vm._v("车位：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex align-items" },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  type: "number",
                                                  controls: false,
                                                },
                                                on: { change: _vm.handelInput },
                                                model: {
                                                  value: _vm.data.car_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "car_price",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "data.car_price",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "span-yuan" },
                                                [_vm._v("元")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._m(15),
                          _c("div", { staticClass: "row flex align-items" }, [
                            _vm.isPC
                              ? _c("div", { staticClass: "row-title-left" })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "row-title-right" },
                              [
                                _c("el-col", { attrs: { span: 3 } }, [
                                  _vm.data.cate_room === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items mB10",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-shrink span-yuan",
                                            },
                                            [_vm._v("住宅：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex align-items" },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  type: "number",
                                                  controls: false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.test_room_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "test_room_price",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "data.test_room_price",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "span-yuan" },
                                                [_vm._v("元")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.data.cate_car === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items mB10",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-shrink span-yuan",
                                            },
                                            [_vm._v("车位：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex align-items" },
                                            [
                                              _c("el-input-number", {
                                                attrs: {
                                                  min: 0,
                                                  type: "number",
                                                  controls: false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.test_car_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.data,
                                                      "test_car_price",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "data.test_car_price",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "span-yuan" },
                                                [_vm._v("元")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    (_vm.data.price === 1 || _vm.data.online_sign_pay === 1) &&
                    (_vm.data.online_sign_pay_type === 1 ||
                      _vm.data.online_sign_pay_type === 2)
                      ? [
                          _vm._m(16),
                          _c("div", { staticClass: "row flex align-items" }, [
                            _vm.isPC
                              ? _c("div", { staticClass: "row-title-left" })
                              : _vm._e(),
                            _c("div", { staticClass: "row-title-right" }, [
                              _c(
                                "div",
                                { staticClass: "flex align-items mB10" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "flex-shrink span-yuan",
                                      staticStyle: { "margin-right": "0.4rem" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.data.online_sign_pay_type === 1
                                            ? "订单外显总价"
                                            : "订单成交总价"
                                        ) + " * "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items" },
                                    [
                                      _c("el-input-number", {
                                        attrs: { min: 1, max: 100 },
                                        model: {
                                          value: _vm.data.online_sign_pay_base,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "online_sign_pay_base",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "data.online_sign_pay_base",
                                        },
                                      }),
                                      _c("span", { staticClass: "span-yuan" }, [
                                        _vm._v("%"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "block-box mT10" }, [
            _c("div", { staticClass: "title-h2" }, [_vm._v("其他设置")]),
            _c(
              "div",
              { staticClass: "bottom-top" },
              [
                _c("div", [
                  _vm._m(17),
                  _c("div", { staticClass: "row flex align-items" }, [
                    _vm.isPC
                      ? _c("div", { staticClass: "row-title-left" })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "row-title-right" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 7 : 24 } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-suffix" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "12",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.data.user_code_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "user_code_name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "data.user_code_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                [
                  _vm._m(18),
                  _c("div", { staticClass: "row flex align-items" }, [
                    _vm.isPC
                      ? _c("div", { staticClass: "row-title-left" })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "row-title-right" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "radio-box",
                                model: {
                                  value: _vm.timing.is_time_limit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.timing, "is_time_limit", $$v)
                                  },
                                  expression: "timing.is_time_limit",
                                },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("开启"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 3 : 12 } },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("不启用"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row flex align-items" }, [
                    _vm.timing.is_time_limit === 1
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "row flex align-items",
                              staticStyle: { "margin-top": "20px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row-title row-title-left",
                                  staticStyle: {
                                    "line-height": "36px",
                                    margin: "0",
                                    "text-align": "right",
                                    "padding-right": "20px",
                                  },
                                },
                                [_vm._v("预约活动名称")]
                              ),
                              _c(
                                "div",
                                { staticClass: "row-title-right" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: _vm.isPC ? 6 : 12 } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.timing.timing_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.timing,
                                                  "timing_name",
                                                  $$v
                                                )
                                              },
                                              expression: "timing.timing_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "90%",
                                "margin-top": "20px",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "timing_listRef",
                                  attrs: { model: _vm.timing },
                                },
                                _vm._l(
                                  _vm.timing.timing_list,
                                  function (item, i) {
                                    return _c(
                                      "el-row",
                                      { key: i },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 7 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                key:
                                                  "timing_list." +
                                                  i +
                                                  ".start_time",
                                                attrs: {
                                                  label:
                                                    "时间段-" + (i + 1) + "：",
                                                  prop:
                                                    "timing_list." +
                                                    i +
                                                    ".start_time",
                                                  rules:
                                                    _vm.timingOper.rules
                                                      .start_time,
                                                  "label-width": "135px",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    format:
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    "value-format": "timestamp",
                                                    size: "small",
                                                    type: "datetime",
                                                    placeholder: "选择日期",
                                                    placement: "bottom-start",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onTimingOper(
                                                        item,
                                                        i,
                                                        "set_start_time"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.start_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "start_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.start_time",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                key:
                                                  "timing_list." +
                                                  i +
                                                  ".end_time",
                                                attrs: {
                                                  label: "至:",
                                                  prop:
                                                    "timing_list." +
                                                    i +
                                                    ".end_time",
                                                  rules:
                                                    _vm.timingOper.rules
                                                      .end_time,
                                                  "label-width": "40px",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    format:
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    "value-format": "timestamp",
                                                    size: "small",
                                                    type: "datetime",
                                                    placeholder: "选择日期",
                                                    placement: "bottom-start",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onTimingOper(
                                                        item,
                                                        i,
                                                        "set_end_time"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.end_time,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "end_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.end_time",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "限制人数：",
                                                  prop:
                                                    "timing_list." +
                                                    i +
                                                    ".user_count",
                                                  rules:
                                                    _vm.timingOper.rules
                                                      .user_count,
                                                  "label-width": "150px",
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: { min: 1 },
                                                  model: {
                                                    value: item.user_count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "user_count",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.user_count",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 1 } },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "添加",
                                                  placement: "top-end",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-plus-outline",
                                                  staticStyle: {
                                                    "margin-top": "8px",
                                                    "font-size": "23px",
                                                    color: "#22a271",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onTimingOper(
                                                        item,
                                                        i,
                                                        "add"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 1 } },
                                          [
                                            _vm.timing.timing_list.length !==
                                              1 && !item.t_count
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "删除",
                                                      placement: "top-end",
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        "margin-top": "8px",
                                                      },
                                                      attrs: {
                                                        "class-name": "greens",
                                                        "icon-class": "delete",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.onTimingOper(
                                                            item,
                                                            i,
                                                            "del"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticStyle: { height: "50px" } }),
                  ]),
                ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "dialog" } },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "预览",
                visible: _vm.preview,
                width: _vm.isPC ? "405px" : "320px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.preview = $event
                },
              },
            },
            [
              _c("preview", {
                attrs: { data: _vm.data, "custom-setting": _vm.customSetting },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.sp.pay_info_set_time != 0
        ? _c("printOrderDialog", {
            ref: "orderEedit",
            attrs: { visible: _vm.prinVisible, payInfo: _vm.pay_info },
            on: { close: _vm.closeDialog, save: _vm.handleSave },
          })
        : _vm._e(),
      _vm.data
        ? _c("youhuiKneelingDialog", {
            ref: "youhuiKneeRef",
            attrs: {
              sp: _vm.sp,
              data: _vm.data,
              you_hui_rule: _vm.you_hui_rule,
              visible: _vm.KneelVisible,
            },
            on: { close: _vm.closeKneelDialog, save: _vm.handleSave },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }, [_vm._v("1.价格组成")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }, [_vm._v("2.划线价")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }, [_vm._v("4.面积取值")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }, [_vm._v("5.金额取整")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-title-right" }, [
      _c("div", { staticClass: "title-box" }, [_vm._v("6.金额精确单位")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT40" }, [
      _c("div", { staticClass: "row-title-left room-title" }, [
        _c("div", { staticClass: "title" }, [_vm._v("房源(车位)列表显示")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT40" }, [
      _c("div", { staticClass: "row-title-left room-title" }, [
        _c("div", { staticClass: "title" }, [_vm._v("房源(车位)列表筛选功能")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT40" }, [
      _c("div", { staticClass: "row-title-left room-title" }, [
        _c("div", { staticClass: "title" }, [_vm._v("公布房源")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("意向单数量上限")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("认购开始全屏倒计时")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35 jump" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("允许开始签到时间")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35 jump3" }, [
      _c("div", { staticClass: "row-title" }, [
        _vm._v("选房/签约/交定金统一时长"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35 jump6" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("定金金额")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items" }, [
      _c("div", { staticClass: "row-title-left" }),
      _c("div", { staticClass: "row-title-right" }, [
        _c("div", { staticClass: "title-box" }, [_vm._v("正式活动")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items" }, [
      _c("div", { staticClass: "row-title-left" }),
      _c("div", { staticClass: "row-title-right" }, [
        _c("div", { staticClass: "title-box" }, [_vm._v("测试活动")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35 jump6" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("定金百分比")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items jump3" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("诚意单号命名")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row flex align-items mT35 jump3" }, [
      _c("div", { staticClass: "row-title" }, [_vm._v("线下时间预约功能")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }