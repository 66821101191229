import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    max: {
      type: [Number, String],
      default: 1
    },
    min: {
      type: [Number, String],
      default: 1
    },
    value: {
      type: String,
      default: ''
    },
    bgc: {
      type: String,
      default: 'linear-gradient(360deg, #f5f5f5 0%, #ffffff 100%)'
    },
    brc: {
      type: String,
      default: '1px solid #262b29'
    },
    textStyle: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fixedValue: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '300px'
    },
    height: {
      type: String,
      default: '490px'
    },
    hintText: {
      type: String,
      default: ''
    },
    minWidth: {
      type: String,
      default: '200px'
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  created: function created() {},
  methods: {
    activeInput: function activeInput() {
      this.$refs.input.focus();
    },
    inputValue: function inputValue(e) {
      if (!parseInt(this.$refs.input.value) && this.$refs.input.value != 0) {
        this.$refs.input.value = '';
      }

      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
    }
  }
};