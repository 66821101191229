// 项目配置
var state = {};
var mutations = {
  SET: function SET(state, v) {
    Object.assign(state, v);
  }
};
var actions = {
  set: function set(_ref, v) {
    var commit = _ref.commit;
    commit('SET', v);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};