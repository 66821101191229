import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { varList, varDel, selVar, varAdd, varEdit } from '@/api/template';
import { mapGetters } from "vuex";
export default {
  name: 'custom-table',
  data: function data() {
    return {
      tableData: [],
      reserve: [{
        label: '整数',
        value: 0
      }, {
        label: '保留1位',
        value: 1
      }, {
        label: '保留2位',
        value: 2
      }, {
        label: '保留3位',
        value: 3
      }],
      cn: [{
        label: '是',
        value: 1,
        radioLabel: '是'
      }, {
        label: '否',
        value: 0,
        radioLabel: '否'
      }],
      //  0加 1减 2乘 3除 4首付
      operator: [{
        label: '+',
        value: 0
      }, {
        label: '-',
        value: 1
      }, {
        label: '*',
        value: 2
      }, {
        label: '/',
        value: 3
      }, {
        label: '首付',
        value: 4
      }],
      countType: [{
        label: '变量',
        value: 0
      }, {
        label: '数值',
        value: 1
      }],
      loading: {},
      loadingSarch: {
        search: ''
      },
      two_tips: '录入需要参与计算的具体数值',
      statusverify: {
        key_one: [{
          required: true,
          message: '计算公式变量必填',
          trigger: 'chage'
        }],
        operator: [{
          required: true,
          message: '运算符必选',
          trigger: 'chage'
        }],
        key_two: [{
          required: true,
          message: '计算公式变量必填',
          trigger: 'chage'
        }]
      },
      verify: {
        new_key: [{
          required: true,
          message: '变量名称必填',
          trigger: 'chage'
        }],
        meaning: [{
          required: true,
          message: '变量含义必填',
          trigger: 'chage'
        }],
        count_one_type: [{
          required: true,
          message: '计算类型必填',
          trigger: 'chage'
        }],
        key_one: [{
          required: true,
          message: '变量必填',
          trigger: 'chage'
        }],
        operator: [{
          required: true,
          message: '运算符必选',
          trigger: 'chage'
        }],
        count_two_type: [{
          required: true,
          message: '计算类型必填',
          trigger: 'chage'
        }],
        key_two: [{
          required: true,
          message: '变量名必填',
          trigger: 'chage'
        }],
        status: [{
          required: true,
          message: '表单类型必填',
          trigger: 'chage'
        }]
      },
      timeout: null,
      constant: [] // 常用
      // searchList: [] // 搜索列表

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['adminParameter'])), {}, {
    newKey: function newKey() {
      var new_key = this.loading.new_key;
      if (!new_key) return '';
      return this.addSymbol(new_key);
    },
    toUP: function toUP() {
      var new_key = this.loading.new_key;
      if (!new_key) return '';
      return this.addSymbol(new_key + '_str');
    }
  }),
  created: function created() {
    this.getTableData();
  },
  methods: {
    countOneChange: function countOneChange(val) {
      var obj = this.statusverify.key_one[0];
      obj.message = val ? '计算公式数值必填' : '计算公式变量必填';
      this.statusverify.key_one[0] = obj;
    },
    countTwoChange: function countTwoChange(val) {
      var obj = this.statusverify.key_two[0];
      obj.message = val ? '计算公式数值必填' : '计算公式变量必填';
      this.statusverify.key_two[0] = obj;
    },
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var constant = this.constant;
      var results = queryString ? constant.filter(this.createStateFilter(queryString)) : constant;
      cb(results);
    },
    createStateFilter: function createStateFilter(queryString) {
      return function (state) {
        if (state.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1) {
          return true;
        }

        if (state.meaming.indexOf(queryString) !== -1) {
          return true;
        }
      };
    },
    changeL: function changeL(e) {
      if (e === 4 || e === 5) {
        this.loading.count_two_type = 1;

        if (e === 5) {
          this.loading.key_two = '1.09';
        } else {
          this.loading.key_two = '';
        }

        this.two_tips = '录入具体首付比例数值';
      } else {
        this.two_tips = '录入需要参与计算的具体数值';
      }
    },
    // 获取表单数据
    getTableData: function getTableData() {
      var _this = this;

      varList().then(function (result) {
        var _result$data = result.data,
            list = _result$data.list,
            info = _result$data.info;
        _this.tableData = list;
      });
    },
    // 显示内容
    showContent: function showContent(val, dataKey) {
      if (!dataKey) return '';
      var content = this[dataKey].find(function (item) {
        return item.value === val;
      });
      if (!content) return '';
      return content.label;
    },
    // 移除符号
    removeSymbol: function removeSymbol() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var texts = text.split(',');
      var newText = texts.map(function (t) {
        // 删除掉 $ { }
        return t.replace(/\$|{|}/gi, '');
      });
      return newText.join(',');
    },
    // 添加符号
    addSymbol: function addSymbol() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var sign_up_type = this.adminParameter.list.sign_up_type; //1线上，2线下

      var texts = text.split(',');
      var newText = texts.map(function (t) {
        if (sign_up_type === 1) {
          //线上
          return "{{".concat(t, "}}");
        } else {
          //线下
          return "${".concat(t, "}");
        }
      });
      return newText.join(',');
    },
    showLoading: function showLoading(type) {
      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.getVar();
      var title = type === 'add' ? '添加变量' : '修改变量';
      this.loading = _objectSpread(_objectSpread({
        decimal: 0,
        is_cn: 1,
        status: 1,
        count_one_type: 0,
        count_two_type: 0,
        operator: ''
      }, row), {}, {
        type: type,
        title: title,
        width: window.innerWidth > 470 ? '850px' : '100%',
        visible: true
      });
    },
    getVar: function getVar() {
      var _this2 = this;

      var params = _objectSpread({}, this.loadingSarch);

      selVar(params).then(function (result) {
        var cy = result.data.cy;
        cy && cy.forEach(function (c) {
          c.show_name = _this2.removeSymbol(c.name);
        });
        _this2.constant = cy;
      });
    },
    deleteData: function deleteData(row) {
      var _this3 = this;

      this.$confirm('该操作无法撤回，是否确认删除？', '提示').then(function () {
        varDel({
          id: row.id
        }).then(function (result) {
          var _result$data2;

          _this3.$message.success(((_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : _result$data2.msg) || '删除成功');

          _this3.getTableData();
        });
      });
    },
    copy: function copy(content) {
      // 创建输入框元素
      var input = document.createElement('textarea');
      input.value = content; // 页面底部追加输入框

      document.body.appendChild(input); // 选中输入框

      input.select(); // 执行浏览器复制命令

      document.execCommand('Copy', true); // 弹出复制成功信息
      //this.$message.success('复制成功');
      // 复制后移除输入框

      input.remove();
      this.$message.success('复制成功');
    },
    addSymbolCopy: function addSymbolCopy(content, isTO) {
      content = content.toString();
      if (isTO) content = "".concat(content, "_str");
      this.copy(this.addSymbol(content));
    },
    closeDialog: function closeDialog() {
      this.loadingSarch = {};
      this.loading.visible = false;
    },
    submitData: function submitData() {
      var _this4 = this;

      var falg = false;
      this.$refs.formVerify.validate(function (verify) {
        falg = verify; // if (!verify) return;

        _this4.$refs.statusFormVerify.validate(function (verify) {
          if (!falg || !verify) return;

          var params = _objectSpread(_objectSpread({}, _this4.loading), {}, {
            status: 1 // 0 添加变量 1变量计算

          });

          var api = null;

          if (_this4.loading.type === 'add') {
            api = varAdd;
          } else {
            api = varEdit;
          }

          api(params).then(function (result) {
            var _result$data3;

            _this4.$message.success(((_result$data3 = result.data) === null || _result$data3 === void 0 ? void 0 : _result$data3.msg) || '保存成功');

            _this4.closeDialog();

            _this4.getTableData();
          });
        });
      });
    }
  }
};