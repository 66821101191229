/**
 * @author yang
 * @description {开盘报告接口}
 * @time {2021/9/28}
 * */
import request from '@/utils/request'; // 开盘报告接口

export function activityReport(params) {
  return request({
    url: '/report/data',
    method: 'get',
    params: params
  });
}