var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "650px",
        top: "6vh",
        "close-on-click-modal": false,
        center: "",
        "custom-class": "prinitinfoDialog",
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "dialog_content" }, [
        _c("div", { staticClass: "custom" }, [_vm._t("orderInfo")], 2),
        _c(
          "div",
          { staticClass: "form_body" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: { model: _vm.ruleForm, rules: _vm.rules },
              },
              [
                _vm._t("formList"),
                _vm.isEnable
                  ? [
                      _vm.payInfo.length
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "付款方式:", prop: "id" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.selectChange },
                                  model: {
                                    value: _vm.ruleForm.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "id", $$v)
                                    },
                                    expression: "ruleForm.id",
                                  },
                                },
                                _vm._l(_vm.payInfo, function (item) {
                                  return _c(
                                    "el-radio",
                                    { key: item.id, attrs: { label: item.id } },
                                    [_vm._v(_vm._s(item.show_name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.ruleForm.pay_type == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "首付比例:",
                                prop: "pay_proportion",
                              },
                            },
                            [
                              _c("div", { staticClass: "house-type-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "pat_pay main_input" },
                                  [
                                    _c("el-input", {
                                      ref: "downblur",
                                      attrs: {
                                        disabled:
                                          _vm.sp.pay_info_mortgage_rule === 0,
                                        type: "number",
                                        max: 100,
                                        placeholder: "请输入付款比例",
                                      },
                                      on: { blur: _vm.setproPortion },
                                      model: {
                                        value: _vm.ruleForm.pay_proportion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "pay_proportion",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "ruleForm.pay_proportion",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticStyle: { width: "20px" } }, [
                                  _vm._v("%"),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.ruleForm.pay_type === 0 || _vm.ruleForm.pay_type == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.ruleForm.pay_type == 0
                                    ? "付款时间:"
                                    : "首付款时间:",
                                prop: "reservation_time",
                              },
                            },
                            [
                              _c("div", { staticClass: "house-type-wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pat_pay main_input",
                                    staticStyle: { width: "188px" },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        disabled:
                                          _vm.selectedpayInfo.pay_time_type ==
                                          2,
                                        placeholder: "选择日期",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                      model: {
                                        value: _vm.reservation_date,
                                        callback: function ($$v) {
                                          _vm.reservation_date = $$v
                                        },
                                        expression: "reservation_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.ruleForm.pay_type === 0 || _vm.ruleForm.pay_type == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.ruleForm.pay_type == 1
                                    ? "首付金额:"
                                    : "付款金额:",
                              },
                            },
                            [
                              _c("div", { staticClass: "house-type-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "pat_pay main_input" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: Boolean(
                                          _vm.ruleForm.pay_type == 1 &&
                                            _vm.sp.pay_info_mortgage_rule == 1
                                        ),
                                        placeholder:
                                          _vm.ruleForm.pay_type == 1
                                            ? "请输入首付金额"
                                            : "请输入付款金额",
                                      },
                                      on: { blur: _vm.setPayment },
                                      model: {
                                        value: _vm.payment,
                                        callback: function ($$v) {
                                          _vm.payment = _vm._n($$v)
                                        },
                                        expression: "payment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticStyle: { width: "20px" } }, [
                                  _vm._v("元"),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.ruleForm.pay_type == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "按揭金额:" } },
                            [
                              _c("div", { staticClass: "house-type-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "pat_pay main_input" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入按揭金额",
                                        disabled: Boolean(
                                          _vm.ruleForm.pay_type == 1 &&
                                            _vm.sp.pay_info_mortgage_rule
                                        ),
                                      },
                                      on: { blur: _vm.setmortgagePayment },
                                      model: {
                                        value: _vm.mortgagePayment,
                                        callback: function ($$v) {
                                          _vm.mortgagePayment = _vm._n($$v)
                                        },
                                        expression: "mortgagePayment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticStyle: { width: "20px" } }, [
                                  _vm._v("元"),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm.ruleForm.pay_type != 0 &&
            _vm.ruleForm.pay_type != 1 &&
            _vm.ruleForm.pay_type != null &&
            _vm.isEnable
              ? _c("div", { staticClass: "fq" }, [
                  _c("div", { staticClass: "fq_title" }, [
                    _c("span", [_vm._v("分期付款时间和金额:")]),
                    _vm.selectedpayInfo.by_stages_type == 1
                      ? _c(
                          "div",
                          {
                            staticClass: "xw-btn",
                            on: { click: _vm.addstages },
                          },
                          [_vm._v("新增分期+")]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "fq_table" }, [
                    _c(
                      "table",
                      {
                        staticStyle: {
                          width: "100%",
                          "border-collapse": "collapse",
                          "margin-top": "5px",
                        },
                        attrs: { border: "1" },
                      },
                      [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "110px",
                              },
                            },
                            [_vm._v("期数")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "250px",
                              },
                            },
                            [_vm._v("付款日期")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "250px",
                              },
                            },
                            [
                              _vm._v(" 付款金额 "),
                              !_vm.selectedpayInfo.by_stages_amount_type
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "tiop",
                                      on: { click: _vm.switchType },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moneyType == 1
                                            ? "按金额"
                                            : "按比例"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.selectedpayInfo.by_stages_type == 1
                            ? _c(
                                "th",
                                {
                                  staticStyle: {
                                    height: "30px",
                                    "line-height": "30px",
                                    "text-align": "center",
                                    width: "20px",
                                  },
                                },
                                [_vm._v(" 操作 ")]
                              )
                            : _vm._e(),
                        ]),
                        _vm._l(_vm.pay_by_stages, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  height: "30px",
                                  "line-height": "30px",
                                  "text-align": "center",
                                  width: "110px",
                                },
                              },
                              [_vm._v("第" + _vm._s(index + 1) + "期")]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  height: "30px",
                                  "line-height": "30px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    disabled:
                                      _vm.selectedpayInfo.pay_time_type == 2,
                                    placeholder: "选择日期",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                    change: _vm.pickerChange,
                                  },
                                  model: {
                                    value: item.reservation_date,
                                    callback: function ($$v) {
                                      _vm.$set(item, "reservation_date", $$v)
                                    },
                                    expression: "item.reservation_date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  height: "30px",
                                  "line-height": "30px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c("div", { staticClass: "flex" }, [
                                  _vm.moneyType == 1 &&
                                  !_vm.selectedpayInfo.by_stages_amount_type
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "house-type-wrapper center mr-left20",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "main_input" },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "60px" },
                                                attrs: {
                                                  disabled:
                                                    _vm.selectedpayInfo
                                                      .pay_time_type == 2,
                                                },
                                                on: { blur: _vm.moneyCompunt },
                                                model: {
                                                  value: item.pay_proportion,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "pay_proportion",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "item.pay_proportion",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { width: "20px" } },
                                            [_vm._v("%")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "house-type-wrapper center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "main_input" },
                                        [
                                          !_vm.selectedpayInfo
                                            .by_stages_amount_type
                                            ? [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      (_vm.selectedpayInfo
                                                        .pay_time_type == 2 ||
                                                        _vm.moneyType == 1) &&
                                                      !_vm.selectedpayInfo
                                                        .by_stages_amount_type,
                                                  },
                                                  on: {
                                                    input: function (v) {
                                                      item.pay_price = v
                                                        ? v.replace(
                                                            /[^\d.]/g,
                                                            ""
                                                          )
                                                        : ""
                                                      _vm.$forceUpdate()
                                                    },
                                                    blur: _vm.moneyCompunt,
                                                  },
                                                  model: {
                                                    value: item.pay_price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "pay_price",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.pay_price",
                                                  },
                                                }),
                                              ]
                                            : [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      (_vm.selectedpayInfo
                                                        .pay_time_type == 2 ||
                                                        _vm.moneyType == 1) &&
                                                      !_vm.selectedpayInfo
                                                        .by_stages_amount_type,
                                                  },
                                                  on: {
                                                    input: function (v) {
                                                      item.payment_amount = v
                                                        ? v.replace(
                                                            /[^\d.]/g,
                                                            ""
                                                          )
                                                        : ""
                                                      _vm.$forceUpdate()
                                                    },
                                                    blur: _vm.moneyCompunt,
                                                  },
                                                  model: {
                                                    value: item.payment_amount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "payment_amount",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.payment_amount",
                                                  },
                                                }),
                                              ],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { width: "20px" } },
                                        [_vm._v("元")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm.selectedpayInfo.by_stages_type == 1
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      height: "30px",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "删除",
                                          placement: "top-end",
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "class-name": "greens",
                                            "icon-class": "delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deltstages(index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        }),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "100px",
                              },
                            },
                            [_vm._v("合计")]
                          ),
                          _c("td", {
                            staticStyle: {
                              height: "30px",
                              "line-height": "30px",
                              "text-align": "center",
                            },
                          }),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "100px",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.toThousandsNum(_vm.amount)) + " 元"
                                ),
                              ]),
                              _vm.difference != 0
                                ? _c("div", { staticStyle: { color: "red" } }, [
                                    _c("span", [_vm._v("差额:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.toThousandsNum(_vm.difference)
                                        ) +
                                        " 元"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "110px",
                              },
                            },
                            [_vm._v("订单金额")]
                          ),
                          _c("td", {
                            staticStyle: {
                              height: "30px",
                              "line-height": "30px",
                              "text-align": "center",
                            },
                          }),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                height: "30px",
                                "line-height": "30px",
                                "text-align": "center",
                                width: "100px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.toThousandsNum(_vm.orderMoney)) +
                                  " 元"
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.btnText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }