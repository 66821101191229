import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { pingPong, pingPongSearch } from '@/api/formulaLottery';
import EnterThenNumber from '@/views/tool/ formulaLottery/components/enterThenNumber.vue';
import { debounce } from '@/utils/debounce';
export default {
  name: 'startLootery',
  components: {
    EnterThenNumber: EnterThenNumber
  },
  data: function data() {
    return {
      searchParams: {
        tel: '',
        //输入框查询客户
        card: '' //身份证识别

      },
      userList: [],
      imgUrl: 'https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/',
      entry_num: 0,
      sequence: 0,
      rand: {},
      type: 1,
      userInfo: {}
    };
  },
  created: function created() {
    this.getpingPong();
  },
  methods: {
    getpingPong: function getpingPong() {
      var _this = this;

      pingPong().then(function (res) {
        var _res$data = res.data,
            data = _res$data.data,
            rand = _res$data.data.rand;
        _this.info = data;
        _this.rand = rand;
        _this.entry_num = rand.entry_num;
        _this.sequence = rand.sequence;
      });
    },
    customConfirm: function customConfirm() {
      if (this.type == 1) {
        this.getUserList();
      } else if (this.type == 2) {
        for (var i = 0; i < this.userList.length; i++) {
          if (this.userList[i].checked) {
            this.userInfo = this.userList[i];
          }
        }

        this.type = 3;
      } else {
        this.userInfo = '';
        this.type = 1;
      }
    },
    getUserList: function getUserList() {
      var _this2 = this;

      //查询
      // 避免因为不是搜索客户时刷身份证
      if (this.searchParams.card && this.type !== 1) return;

      if (!this.searchParams.card) {
        if (!this.searchParams.tel || !this.searchParams.tel.trim()) {
          this.$message({
            type: 'error',
            message: '请输入客户的手机号、身份证、姓名查询'
          });
          return;
        }
      } else {
        this.searchParams.tel = '';
      }

      pingPongSearch(_objectSpread({}, this.searchParams)).then(function (res) {
        var users = res.data.data.users;

        if (users && users.length) {
          for (var i = 0; i < users.length; i++) {
            if (users[i].other_remark) {
              users[i].houseall = users[i].other_remark.split(';');
            }
          }
        }

        _this2.userList = users || [];

        if (_this2.userList.length === 1) {
          _this2.userInfo = users[0];
          _this2.type = 3;
        } else if (_this2.userList.length === 0) {
          _this2.$message({
            type: 'info',
            message: '没有搜索到客户！'
          });
        } else {
          _this2.userList[0].checked = true;
          _this2.type = 2;
        }

        _this2.searchParams = {
          tel: '',
          card: ''
        };
      });
    },
    // 点击客户
    onCheckedUser: debounce(function (index) {
      var userList = JSON.parse(JSON.stringify(this.userList));

      for (var i = 0; i < userList.length; i++) {
        userList[i].checked = i === index;
      }

      this.userList = userList;
    }, 500, true)
  }
};