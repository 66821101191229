var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100vw",
        height: "100vh",
        background: "linear-gradient(to bottom, #f6f6f6 0%, #d1e6dd 100%)",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "48%",
                height: "calc(100vh - 200px)",
                margin: "20px 50px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "right-name" },
                [
                  _vm._v(" 录入分类 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "itemtooltip",
                      attrs: { effect: "dark", placement: "right" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("录入值与客户分类相匹配的客户集中展示！")]
                      ),
                      _c("i", { staticClass: "tips el-icon-question" }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    height: "calc(100% - 130px)",
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.num,
                        expression: "num",
                      },
                    ],
                    ref: "input",
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.num },
                    on: {
                      blur: function ($event) {
                        return _vm.onEntryUser()
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.num = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "48%",
                height: "calc(100vh - 200px)",
                margin: "20px 50px",
                position: "relative",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "right-name",
                  staticStyle: { position: "absolute", right: "0" },
                },
                [_vm._v("已录结果")]
              ),
              _c(
                "div",
                { staticClass: "right-box" },
                _vm._l(_vm.user_list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        padding: "10px 20px",
                        height: "40px",
                        "border-radius": "10px",
                        margin: "8px 12px",
                        "background-color": "white",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.level) + " ")]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            bottom: "30px",
            "margin-left": "50px",
            "margin-right": "50px",
            width: "calc(100% - 100px)",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { color: "#262b29", "font-size": "20px" } },
            [
              _vm._v(" 号码展示 "),
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-left": "10px" },
                  model: {
                    value: _vm.showType,
                    callback: function ($$v) {
                      _vm.showType = $$v
                    },
                    expression: "showType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("姓名(脱敏)"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("姓名(全显)"),
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("登录手机号"),
                  ]),
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v(_vm._s(_vm.$adminList.user_code_name)),
                  ]),
                  _c("el-radio", { attrs: { label: 5 } }, [
                    _vm._v("身份证号（脱敏）"),
                  ]),
                  _c("el-radio", { attrs: { label: 6 } }, [
                    _vm._v("身份证号（全显）"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-top": "30px",
              },
            },
            [
              !_vm.newList.length
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0.2)",
                        width: "calc(100% - 300px)",
                        height: "110px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                        "border-radius": "10px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "82px", height: "84px" },
                        attrs: {
                          src: require("../../../assets/page/lack.png"),
                        },
                      }),
                      _c("span", [_vm._v("还未生成号码～")]),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0.2)",
                        width: "calc(100% - 300px)",
                        height: "110px",
                        display: "flex",
                        "justify-content": "space-between",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _vm._l(_vm.newList, function (item, index) {
                        return [
                          _vm._l(item, function (_item, _index) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "55px",
                                    "line-height": "55px",
                                    "text-align": "center",
                                  },
                                  style: { width: 100 / item.length + "%" },
                                },
                                [
                                  !_item
                                    ? _c("span")
                                    : _vm.showType === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.nickName(_item.name) || "无"
                                          )
                                        ),
                                      ])
                                    : _vm.showType === 2
                                    ? _c("span", [
                                        _vm._v(_vm._s(_item.name || "无")),
                                      ])
                                    : _vm.showType === 3
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.getTel(_item.tel) || "无")
                                        ),
                                      ])
                                    : _vm.showType === 4
                                    ? _c("span", [
                                        _vm._v(_vm._s(_item.code || "无")),
                                      ])
                                    : _vm.showType === 5
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCard(_item.card) || "无"
                                          )
                                        ),
                                      ])
                                    : _vm.showType === 6
                                    ? _c("span", [
                                        _vm._v(_vm._s(_item.card || "无")),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "eventrand/entry"],
                      expression: "['admin', 'eventrand/entry']",
                    },
                  ],
                  staticStyle: {
                    width: "250px",
                    height: "110px",
                    "background-color": "#22a271",
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    color: "white",
                    "font-size": "30px",
                    "border-radius": "10px",
                    cursor: "pointer",
                  },
                  on: { click: _vm.onEntry },
                },
                [_vm._v(" 确定生成 ")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }