// toolbar配置
var items = [// "source", // HTML代码
// "|",
'undo', // 后退
'redo', // 前进
'|', 'preview', // 预览
// "print", // 打印
// "template", // 插入模板
// "code", // 插入程序代码
'cut', // 剪切
'copy', // 复制
'paste', // 粘贴
'plainpaste', // 粘贴为无格式文本
'wordpaste', // 从Word粘贴
'|', 'justifyleft', // 左对齐
'justifycenter', // 居中
'justifyright', // 右对齐
// 'justifyfull', // 两端对齐 --- 失效
'insertorderedlist', // 编号
'insertunorderedlist', // 项目符号
'indent', // 增加缩进
'outdent', // 减少缩进
'subscript', // 下标
'superscript', // 上标
'clearhtml', // 清理HTML代码
'quickformat', // 一键排版
// "selectall", // 全选
'|', 'fullscreen', // 全屏显示
'/', 'formatblock', // 段落
'fontname', // 字体
'fontsize', // 文字大小
'|', 'forecolor', // 文字颜色
// 'hilitecolor', // 文字背景
'bold', // 粗体
'italic', // 斜体
'underline', // 下划线
'strikethrough', // 删除线
'lineheight', // 行距
'removeformat', // 删除格式
// 'image', // 图片
// "multiimage", // 多图上传
// "flash", // Flash
// "media", // 视音频
// "insertfile", // 插入文件
// "table", // 表格
'hr', // 插入横线
// 'emoticons', // 插入表情
// "map", // Google地图
// "baidumap", // 百度地图
'pagebreak', // 插入分页符
'anchor', // 插入锚点
'link', // 超级链接
'unlink' // 取消超级链接
// '|',
// 'about' // 关于
];
export default items;