var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          ref: "noticeBar",
          staticClass: "notice-nav notice-bar",
          style: _vm.style,
          on: { click: _vm.handleClick },
        },
        [
          _c(
            "div",
            {
              staticClass: "left-icon",
              on: {
                click: function ($event) {
                  return _vm.handleIconClick("LeftIcon")
                },
              },
            },
            [
              _vm._t("LeftIcon", [
                _c("i", {
                  staticClass: "iconfont",
                  class: _vm.leftIcon,
                  style: { color: _vm.color },
                }),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "left-title", style: _vm.titleStyles },
            [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
            2
          ),
          _c(
            "div",
            { ref: "textContainer", staticClass: "text-container text-swiper" },
            [
              _c(
                "div",
                {
                  ref: "textWrapper",
                  staticClass: "text-wrapper",
                  style: _vm.textWrapStyle,
                },
                _vm._l(_vm.textList, function (item, index) {
                  return _c("div", { key: index, staticClass: "text" }, [
                    _vm._v(" " + _vm._s(item) + " "),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "right-icon",
              on: {
                click: function ($event) {
                  return _vm.handleIconClick("RightIcon")
                },
              },
            },
            [
              _vm._t("RightIcon", [
                _c("i", {
                  staticClass: "iconfont",
                  class: _vm.RightIcon,
                  style: { color: _vm.color, fontSize: "20px" },
                }),
              ]),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }