import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.get-own-property-names.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBaseSetting, setBaseSetting, DelConfColor } from '@/api/screen';
import screenItem from '@/components/screenItem/components/item.vue';
import screenRight from './components/screenRight';
import countDown from '@/components/countDownb';
import { debounce } from '@/utils';
import { mapGetters } from 'vuex';
import setLedActivityEnd from '../setLedActivityEnd/index';
import customSwitch from '@/components/customSwitch/index';
import Oss from '@/utils/oss';
import moment from 'moment';
export default {
  name: 'Basicsetting',
  components: {
    screenItem: screenItem,
    screenRight: screenRight,
    countDown: countDown,
    setLedActivityEnd: setLedActivityEnd,
    customSwitch: customSwitch
  },
  data: function data() {
    var _this = this;

    var NotZeroOne = function NotZeroOne(rule, value, callback) {
      if (_this.timeValidataForm.hour_c + _this.timeValidataForm.min_c + _this.timeValidataForm.sec_c == 0) {
        return callback('显示总时长不能为0');
      } else {
        _this.$refs['timeValidataForm_one'].clearValidate();

        callback();
      }
    };

    var NotZeroTwo = function NotZeroTwo(rule, value, callback) {
      if (_this.timeValidataForm.hour_r + _this.timeValidataForm.min_r + _this.timeValidataForm.sec_r == 0) {
        return callback('显示总时长不能为0');
      } else {
        _this.$refs['timeValidataForm_two'].clearValidate();

        callback();
      }
    };

    return {
      boldArray: [],
      //加粗列表
      //   显示房间 显示多元素
      houseResRadio: 1,
      //   基本信息
      baseMesCheck: [],
      //   面积
      areaCheck: [],
      //   价格 单价 是否勾选
      unitChecked: true,
      //   价格 -单价
      unitPrice: [],
      //   价格 -单价 是否显示
      unitDisable: false,
      //   价格 -总价  是否勾选
      totalChecked: true,
      //   价格 -总价
      totalPrice: [],
      //   价格  -总价 是否显示
      totalDisable: false,
      //   单元格狩猎颜色 背景色
      bgColor: 'rgba(255, 141, 55, 1)',
      bgc: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', 'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)', 'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)', '#c7158577'],
      //   单元格 字体颜色
      fontC: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', 'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)', 'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)', '#c7158577'],
      fontColor: 'rgba(255, 255, 255, 1)',
      //   告罄显示
      saleOver: 0,
      //   销售统计组件设置
      saleCompList: [],
      //   标题组件设置
      titelCompList: [],
      //   倒计时 显示 不显示
      display: 0,
      //   为开售提示文字
      saleNotBeginText: '',
      //   小时 分钟 秒
      hour: '',
      min: '',
      sec: '',
      //   选房结束 是否显示
      isShow: 0,
      //   选房结束 显示内容
      chouseHouseOver: [],
      //   房源售罄 显示内容
      saleHouseOver: [],
      //   屏幕设置详细信息  假数据勿删  当项目初始化时ledConfInfo 为空对象 按钮无法点击
      ledConfInfo: {},
      projectInfo: {
        pin_gao_qing: '告罄'
      },
      // --------------------------
      //   价格配置
      projectRoom: {},
      //   未开售等待文字
      saleValidataForm: {
        saleText: ''
      },
      //   倒计时时间
      timeValidataForm: {
        hour_c: 48,
        min_c: 0,
        sec_c: 0,
        hour_r: 72,
        min_r: 0,
        sec_r: 0
      },
      // 倒计时校验
      timeValRule_one: {
        hour_c: [{
          validator: NotZeroOne,
          trigger: 'blur'
        }],
        min_c: [{
          validator: NotZeroOne,
          trigger: 'blur'
        }],
        sec_c: [{
          validator: NotZeroOne,
          trigger: 'blur'
        }]
      },
      timeValRule_two: {
        hour_r: [{
          validator: NotZeroTwo,
          trigger: 'blur'
        }],
        min_r: [{
          validator: NotZeroTwo,
          trigger: 'blur'
        }],
        sec_r: [{
          validator: NotZeroTwo,
          trigger: 'blur'
        }]
      },
      // 开售设置 预览弹框
      basePreview: false,
      // 倒计时设置 预览弹框
      countDownPreview: false,
      // 房源假数据 勿删 供预览使用
      itemDetail: {
        build: 1,
        build_name: '1栋',
        created_at: 1620790576,
        deleted_at: null,
        id: 433,
        led_id: 212,
        no: '1',
        room: {
          3: [{
            build_area: '300',
            build_id: '1',
            build_name: '1栋',
            build_sort: '85',
            cate: '1',
            collect_count: '0',
            created_at: '0',
            deleted_at: '',
            desc: '这是房间303的描述',
            discount_price: '0',
            discount_total_price: '0',
            first_collect_count: '0',
            floor_id: '4',
            floor_name: '3',
            floor_sort: '3',
            gift_desc: '',
            gift_price: '0',
            hot_count: '0',
            id: '1101',
            images: '',
            no_id: '345',
            no_name: '03',
            no_sort: '3',
            not_desc: '是样板间',
            not_status: '-1',
            old_total_price: '10000',
            old_unit_price: '3000000',
            one_off_total_price: '0',
            one_off_unit_price: '0',
            ordered_time: '',
            pin_an_jie_total_price: '0',
            pin_an_jie_unit_price: '0',
            project_id: '1',
            reserv_user_id: '',
            room: '1栋-1单元-303',
            room_area: '276',
            room_cate_id: '39',
            room_cate_name: '户型999',
            room_desc: '是样板间',
            room_status: '-1',
            room_tag_id: '0',
            round_id: '1',
            sort: '0',
            test: '',
            unit_id: '1',
            unit_name: '1单元',
            unit_sort: '1',
            updated_at: '1620788869',
            user_id: ''
          }],
          4: [{
            build_area: '300',
            build_id: '1',
            build_name: '1栋',
            build_sort: '85',
            cate: '1',
            collect_count: '0',
            created_at: '0',
            deleted_at: '',
            desc: '这是房间303的描述',
            discount_price: '0',
            discount_total_price: '0',
            first_collect_count: '0',
            floor_id: '4',
            floor_name: '4',
            floor_sort: '3',
            gift_desc: '',
            gift_price: '0',
            hot_count: '0',
            id: '1101',
            images: '',
            no_id: '345',
            no_name: '03',
            no_sort: '3',
            not_desc: '是样板间',
            not_status: '-1',
            old_total_price: '10000',
            old_unit_price: '3000000',
            one_off_total_price: '0',
            one_off_unit_price: '0',
            ordered_time: '',
            pin_an_jie_total_price: '0',
            pin_an_jie_unit_price: '0',
            project_id: '1',
            reserv_user_id: '',
            room: '1栋-1单元-303',
            room_area: '276',
            room_cate_id: '39',
            room_cate_name: '户型999',
            room_desc: '是样板间',
            room_status: '1',
            room_tag_id: '0',
            round_id: '1',
            sort: '0',
            test: '',
            unit_id: '1',
            unit_name: '1单元',
            unit_sort: '1',
            updated_at: '1620788869',
            user_id: ''
          }],
          5: [{
            build_area: '300',
            build_id: '1',
            build_name: '1栋',
            build_sort: '85',
            cate: '1',
            collect_count: '0',
            created_at: '0',
            deleted_at: '',
            desc: '这是房间303的描述',
            discount_price: '0',
            discount_total_price: '0',
            first_collect_count: '0',
            floor_id: '4',
            floor_name: '5',
            floor_sort: '3',
            gift_desc: '',
            gift_price: '0',
            hot_count: '0',
            id: '1101',
            images: '',
            no_id: '345',
            no_name: '03',
            no_sort: '3',
            not_desc: '是样板间',
            not_status: '-1',
            old_total_price: '10000',
            old_unit_price: '3000000',
            one_off_total_price: '0',
            one_off_unit_price: '0',
            ordered_time: '',
            pin_an_jie_total_price: '0',
            pin_an_jie_unit_price: '0',
            project_id: '1',
            reserv_user_id: '',
            room: '1栋-1单元-303',
            room_area: '276',
            room_cate_id: '39',
            room_cate_name: '户型999',
            room_desc: '是样板间',
            room_status: '-1',
            room_tag_id: '0',
            round_id: '1',
            sort: '0',
            test: '',
            unit_id: '1',
            unit_name: '1单元',
            unit_sort: '1',
            updated_at: '1620788869',
            user_id: ''
          }]
        },
        unit: 1,
        unit_room: '1单元',
        updated_at: 1620790576,
        x: '',
        y: '',
        z_index: ''
      },
      // 倒计时组件
      countDown: '',
      // 等待json
      waitJson: {
        imgSrc: 'public/img/rand/c549b67a-90bd-11ed-9e8d-00163e01ac31.png',
        title: {
          show: true,
          text: '王鑫成项目在线选房',
          opacity: 0.1
        },
        wait: {
          show: true,
          text: '即将开盘,敬请期待',
          opacity: 0.1
        },
        unit_isround: 0,
        // 单价是否取整 1,取整 2,两位小数
        unit_isunit: 0,
        //单价是否显示单位 1 显示 2隐藏
        total_isround: 0,
        // 总价价是否取整 1,取整 2,两位小数
        total_isunit: 0,
        //总价是否显示单位 1 显示 2隐藏
        boldList: [],
        //字体加粗已选列表
        buildingSize: 20
      },
      fileList: [],
      imgPreviewDialogVisible: false,
      ledActivityEndList: [],
      ledActivityEndTemList: []
    };
  },
  computed: _objectSpread({}, mapGetters(['customSetting', 'adminParameter'])),
  watch: {
    // 房源只显示房间号 显示多元素
    houseResRadio: function houseResRadio(newValue, oldValue) {
      if (newValue == 0) {
        this.ledConfInfo.show_room = 0; // 房间号

        this.ledConfInfo.show_floor = 0; // 楼层

        this.ledConfInfo.show_room_cate = 0; // 户型

        this.ledConfInfo.show_room_other = 0; //其他信息

        this.ledConfInfo.show_build_area = 0; // 建筑面积

        this.ledConfInfo.show_room_area = 0; // 套内面积

        this.unitChecked = false; // 单价

        this.totalChecked = false; // 总价

        this.bgColor = '#FF8D37';
        this.fontColor = '#FFFFFF'; // 单价

        this.ledConfInfo.droom_info_pin_an_jie_jia = 0;
        this.ledConfInfo.droom_info_pin_biao_zhun_jia = 0;
        this.ledConfInfo.droom_info_pin_xian_jia = 0;
        this.ledConfInfo.droom_info_pin_yi_ci_jia = 0;
        this.ledConfInfo.droom_info_pin_you_hui_jia = 0;
        this.ledConfInfo.droom_info_pin_yuan_jia = 0; // 总价

        this.ledConfInfo.zroom_info_pin_an_jie_jia = 0;
        this.ledConfInfo.zroom_info_pin_biao_zhun_jia = 0;
        this.ledConfInfo.zroom_info_pin_xian_jia = 0;
        this.ledConfInfo.zroom_info_pin_yi_ci_jia = 0;
        this.ledConfInfo.zroom_info_pin_you_hui_jia = 0;
        this.ledConfInfo.zroom_info_pin_yuan_jia = 0;
      }
    },
    // 是否显示倒计时
    display: function display(newValue, oldValue) {
      if (newValue == 1) {
        this.timeValidataForm.hour_c = 0;
        this.timeValidataForm.min_c = 20;
        this.timeValidataForm.sec_c = 0;
      }
    },
    // 选房结束
    isShow: function isShow(newValue, oldValue) {
      if (newValue == 1) {
        this.ledConfInfo.show_end_price = 0; // 选房结束显示价格

        this.ledConfInfo.show_end_sale = 0; // 选房结束显示销量
      }
    }
  },
  mounted: function mounted() {
    this.doGetBaseSetting();
  },
  methods: {
    fontsizeDeafult: function fontsizeDeafult() {
      for (var i = 0; i < this.boldArray.length; i++) {
        this.boldArray[i].headSize = 14;
        this.boldArray[i].contentSize = 13;
      }

      this.waitJson.fhSize = 16;
      this.waitJson.buildingSize = 20;
      this.$forceUpdate();
    },
    getelementsize: function getelementsize(key, position) {
      var obj = this.size.find(function (item) {
        return item.type == key;
      });

      if (this.size && obj) {
        return obj[position];
      } else {
        return position == 'headSize' ? 14 : 13;
      }
    },
    setBoldArray: function setBoldArray(type, val, name) {
      var boldArray = this.boldArray;
      var obj = {
        type: type,
        name: name,
        headSize: this.getelementsize(type, 'headSize'),
        contentSize: this.getelementsize(type, 'contentSize')
      };

      switch (type) {
        case 'show_floor':
          obj.name = '房源楼层';
          obj.name2 = '楼层';
          break;

        case 'show_room_cate':
          obj.name = this.customSetting.custom_data.house_type;
          obj.name2 = '户型';
          break;

        case 'show_room_other':
          obj.name = '其他信息';
          break;

        case 'show_build_area':
          obj.name = '建筑面积';
          obj.name2 = '建面';
          break;

        case 'show_room_area':
          obj.name = '套内面积';
          obj.name2 = '套面';
          break;

        case 'show_price':
          obj.name = '单价';
          break;

        case 'show_price2':
          obj.name = '总价';
          break;
      }

      if (!boldArray.some(function (item) {
        return item.type == type;
      }) && val) {
        if (type == 'show_room_other' || !boldArray.find(function (item) {
          return item.type == 'show_room_other';
        })) {
          //保证其他信息在最后
          boldArray.push(obj);
        } else {
          boldArray.splice(boldArray.length - 1, 0, obj);
        }
      } else if (boldArray.some(function (item) {
        return item.type == type;
      }) && !val) {
        var dleltIndex = boldArray.findIndex(function (item) {
          return item.type == type;
        });
        boldArray.splice(dleltIndex, 1);
      }

      this.boldArray = boldArray;
      this.$forceUpdate();
    },
    // 处理文件上传
    handleFileUploadOss: function handleFileUploadOss(file) {
      console.log(file);
      Oss.onUploadFile(file, "public/pcimg/$id/".concat(new Date().getTime(), "-").concat(file.file.name), file.file.name);
    },
    doGetBaseSetting: function doGetBaseSetting(isEndList) {
      var _this2 = this;

      //   获取配置
      getBaseSetting().then(function (res) {
        var ledConfInfo = res.data.ledConfInfo;
        var projectRoom = res.data.projectRoom;

        if (!isEndList) {
          var newobj = JSON.parse(res.data.ledConfInfo.json);
          _this2.size = newobj.size || [];
          console.log(newobj, 'newobjnewobj');
          var _ledConfInfo = res.data.ledConfInfo;
          _this2.boldArray = [{
            type: 'room_number',
            name2: '房号',
            name: _this2.customSetting.custom_data.hosue_car_num,
            headSize: _this2.getelementsize('room_number', 'headSize'),
            contentSize: _this2.getelementsize('room_number', 'contentSize')
          }]; //房号默认勾选

          for (var key in _ledConfInfo) {
            if (key == 'show_floor' || key == 'show_room_cate' || key == 'show_room_other' || key == 'show_price' || key == 'show_price2' || key == 'show_room_area' || key == 'show_build_area') {
              if (!_ledConfInfo[key]) continue;
              var obj = {
                type: key,
                headSize: _this2.getelementsize(key, 'headSize'),
                contentSize: _this2.getelementsize(key, 'contentSize')
              };

              switch (key) {
                case 'show_floor':
                  obj.name = '房源楼层';
                  obj.name2 = '楼层';
                  break;

                case 'show_room_cate':
                  obj.name = _this2.customSetting.custom_data.house_type;
                  obj.name2 = '户型';
                  break;

                case 'show_room_other':
                  obj.name = '其他信息';
                  break;

                case 'show_build_area':
                  obj.name = '建筑面积';
                  obj.name2 = '建面';
                  break;

                case 'show_room_area':
                  obj.name = '套内面积';
                  obj.name2 = '套面';
                  break;

                case 'show_price':
                  obj.name = '单价';
                  break;

                case 'show_price2':
                  obj.name = '总价';
                  break;
              }

              _this2.boldArray.push(obj);
            }
          } // 设置默认值


          if (!newobj.hasOwnProperty('unit_isround')) {
            newobj.unit_isround = 0;
          }

          if (!newobj.hasOwnProperty('unit_isunit')) {
            newobj.unit_isunit = 0;
          }

          if (!newobj.hasOwnProperty('total_isround')) {
            newobj.total_isround = 0;
          }

          if (!newobj.hasOwnProperty('total_isunit')) {
            newobj.total_isunit = 0;
          }

          if (!newobj.boldList) {
            newobj.boldList = [];
          }

          if (!newobj.buildingSize) {
            newobj.buildingSize = 20;
          }

          if (!newobj.fhSize) {
            newobj.fhSize = 16;
          }

          _this2.waitJson = newobj;
          var arr = Object.getOwnPropertyNames(_ledConfInfo);

          if (arr.length != 0) {
            // 判断参数设置中是否显示该价格
            var room_info_price_type_jia = [// 一个现价
            ['zroom_info_pin_xian_jia', 'droom_info_pin_xian_jia'], // 优惠价+原价
            ['zroom_info_pin_yuan_jia', 'zroom_info_pin_you_hui_jia', 'droom_info_pin_yuan_jia', 'droom_info_pin_you_hui_jia'], // 标准价+一次性价+按揭价
            ['zroom_info_pin_an_jie_jia', 'zroom_info_pin_biao_zhun_jia', 'zroom_info_pin_yi_ci_jia', 'droom_info_pin_an_jie_jia', 'droom_info_pin_biao_zhun_jia', 'droom_info_pin_yi_ci_jia']];

            for (var i = 0; i < room_info_price_type_jia.length; i++) {
              for (var l = 0; l < room_info_price_type_jia[i].length; l++) {
                if (projectRoom.room_info_price_type != i + 1) {
                  _ledConfInfo[room_info_price_type_jia[i][l]] = 0;
                }
              }
            }

            _this2.ledConfInfo = _ledConfInfo;
            _this2.saleOver = _ledConfInfo.show_out;
            _this2.bgColor = _ledConfInfo.first_back == '' ? 'rgba(255, 141, 55, 1)' : _ledConfInfo.first_back;
            _this2.fontColor = _ledConfInfo.first_size == '' ? 'rgba(255, 255, 255, 1)' : _ledConfInfo.first_size;
            _this2.saleValidataForm.saleText = _ledConfInfo.show_word; // let hour = Math.floor(( time / 36 000 00) % 24) ms
            // let min = Math.floor(( time / 60000) % 60) ms
            // let sec = Math.floor(( time / 1000) % 60) ms

            _this2.timeValidataForm = {
              hour_c: Math.floor(Number(_ledConfInfo.show_time_sec) / 3600),
              min_c: Math.floor(Number(_ledConfInfo.show_time_sec) / 60 % 60),
              sec_c: Math.floor(Number(_ledConfInfo.show_time_sec) % 60),
              hour_r: Math.floor(Number(_ledConfInfo.show_room_sec) / 3600),
              min_r: Math.floor(Number(_ledConfInfo.show_room_sec) / 60 % 60),
              sec_r: Math.floor(Number(_ledConfInfo.show_room_sec) % 60)
            };
            console.log(_ledConfInfo.show_time_sec);
          }

          _this2.projectRoom = projectRoom;
        }

        _this2.ledConfInfo.img_id = ledConfInfo.img_id;
        _this2.ledActivityEndList = res.data.imgList || [];
        _this2.ledActivityEndTemList = res.data.imgTemList || [];
      });
    },
    //  只显示房间号 显示多元素
    houseResChange: function houseResChange(e) {
      this.houseResRadio = e;
    },
    //  价格 单价
    unitPriceChange: function unitPriceChange(e) {},
    //  价格 总价
    totalPriceChange: function totalPriceChange(e) {},
    // 倒计时设置 预览
    toPreview: function toPreview(index) {
      if (index == 0) {
        this.basePreview = true;
      } else {
        this.countDownPreview = true;
        this.countDown = 'countDown';
      }
    },
    // 通用设置保存 提交
    saleOverChange: function saleOverChange(e) {
      this.saleOver = e;
      this.ledConfInfo.show_out = e;
    },
    submit: debounce(function () {
      var _this3 = this;

      var ledConfInfo = this.ledConfInfo;
      var timeForm = this.timeValidataForm;

      var params = _objectSpread({}, ledConfInfo);

      params.show_word = this.saleValidataForm.saleText;
      params.show_time_sec = Number(timeForm.hour_c) * 3600 + Number(timeForm.min_c) * 60 + Number(timeForm.sec_c);
      params.show_room_sec = Number(timeForm.hour_r) * 3600 + Number(timeForm.min_r) * 60 + Number(timeForm.sec_r); // 字体颜色 背景颜色

      params.first_size = this.fontColor;
      params.first_back = this.bgColor; // 单价总价 必须必选一个

      var isPricePass = true;

      if (params.show_type == 1) {
        if (params.show_price == 1 || params.show_price2 == 1) {
          if (params.show_price == 1) {
            if (this.projectRoom.room_info_price_type == 1) {
              // 一个价格
              if (params.droom_info_pin_xian_jia == 0) {
                isPricePass = false;
              }
            } else if (this.projectRoom.room_info_price_type == 2) {
              // 2个价格
              if (params.droom_info_pin_yuan_jia == 0 && params.droom_info_pin_you_hui_jia == 0) {
                isPricePass = false;
              }
            } else {
              // 3个价格
              if (params.droom_info_pin_biao_zhun_jia == 0 && params.droom_info_pin_an_jie_jia && params.droom_info_pin_yi_ci_jia) {
                isPricePass = false;
              }
            }
          }

          if (params.show_price2 == 1) {
            if (this.projectRoom.room_info_price_type == 1) {
              // 一个价格
              if (params.zroom_info_pin_xian_jia == 0) {
                isPricePass = false;
              }
            } else if (this.projectRoom.room_info_price_type == 2) {
              // 2个价格
              if (params.zroom_info_pin_yuan_jia == 0 && params.zroom_info_pin_you_hui_jia == 0) {
                isPricePass = false;
              }
            } else {
              // 3个价格
              if (params.zroom_info_pin_biao_zhun_jia == 0 && params.zroom_info_pin_an_jie_jia && params.zroom_info_pin_yi_ci_jia) {
                isPricePass = false;
              }
            }
          }
        }
      } else {
        isPricePass = true;
      }

      if (!isPricePass) {
        this.$message({
          type: 'warning',
          message: '价格至少选择一项'
        });
        return;
      } // 车位展示必选一个
      //   timeValidataForm


      var timeValidataForm_one = 'timeValidataForm_one';
      var timeValidataForm_two = 'timeValidataForm_two';

      if (!this.waitJson.imgSrc) {
        this.$message({
          type: 'error',
          message: '请上传背景图片！'
        });
        return;
      } else if (!this.waitJson.title.text) {
        this.$message({
          type: 'error',
          message: '未开售的标题等待文字不可为空！'
        });
        return;
      } else if (!this.waitJson.wait.text) {
        this.$message({
          type: 'error',
          message: '未开售的等待文字不可为空！'
        });
        return;
      }

      var p1 = new Promise(function (resolve, reject) {
        if (params.show_time == 1 && _this3.$refs[timeValidataForm_one]) {
          _this3.$refs[timeValidataForm_one].validate(function (valid_one) {
            if (!valid_one) {
              reject();
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
      var p2 = new Promise(function (resolve, reject) {
        if (params.show_room_switch == 1 && _this3.$refs[timeValidataForm_two]) {
          _this3.$refs[timeValidataForm_two].validate(function (valid_two) {
            if (!valid_two) {
              reject();
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
      Promise.all([p1, p2]).then(function () {
        _this3.waitJson.size = _this3.boldArray;
        params.json = JSON.stringify(_this3.waitJson);
        setBaseSetting(params).then(function (res) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });

          _this3.$router.push('/tool/screen');
        });
      }).catch(function () {
        _this3.$message({
          type: 'error',
          message: '倒计时时间显示总时长不能为0'
        });
      });
    }, 1000, true),
    // 处理返回给后端的数据格式
    numHandle: function numHandle(item) {
      if (item == true) {
        return 1;
      }

      if (item == false) {
        return 0;
      }

      if (typeof item === 'number') {
        return item;
      }
    },
    cehckboxChange: function cehckboxChange(e) {
      console.log(e);
    },
    // 单价点击
    unitCheckedChange: function unitCheckedChange(type, e, name) {
      if (e == 0) {
        this.ledConfInfo.droom_info_pin_xian_jia = 0;
        this.ledConfInfo.droom_info_pin_yuan_jia = 0;
        this.ledConfInfo.droom_info_pin_you_hui_jia = 0;
        this.ledConfInfo.droom_info_pin_biao_zhun_jia = 0;
        this.ledConfInfo.droom_info_pin_an_jie_jia = 0;
        this.ledConfInfo.droom_info_pin_yi_ci_jia = 0;
      }

      this.setBoldArray(type, e, name);
    },
    // 总价点击
    totalCheckedChange: function totalCheckedChange(type, e, name) {
      if (e == 0) {
        this.ledConfInfo.zroom_info_pin_xian_jia = 0;
        this.ledConfInfo.zroom_info_pin_yuan_jia = 0;
        this.ledConfInfo.zroom_info_pin_you_hui_jia = 0;
        this.ledConfInfo.zroom_info_pin_biao_zhun_jia = 0;
        this.ledConfInfo.zroom_info_pin_an_jie_jia = 0;
        this.ledConfInfo.zroom_info_pin_yi_ci_jia = 0;
      }

      this.setBoldArray(type, e, name);
    },
    // 倒计时设置预览弹框 关闭事件
    dialogClose: function dialogClose() {
      this.countDown = '';
    },
    // 默认颜色
    backDeafult: function backDeafult() {
      this.bgColor = 'rgba(255, 141, 55, 1)';
      this.fontColor = 'rgba(255, 255, 255, 1)';
    },
    // 删除图片
    handleRemove: function handleRemove() {
      this.fileList = [];
      this.waitJson.imgSrc = '';
    },
    // 文件上传
    imgupSuccess: function imgupSuccess(e) {
      this.waitJson.imgSrc = e.Data.url;
      this.fileList = [];
    },
    // 预览图片
    handlePictureCardPreview: function handlePictureCardPreview() {
      this.imgPreviewDialogVisible = true;
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // 限制大小
      var isLt2M = file.size / 1024 / 1024 < 4;

      if (!isLt2M) {
        this.$message.error('上传大小不能超过 4MB!');
      }

      return isLt2M;
    },
    // 跳转到修改活动后页面
    onSetLedActivityEnd: function onSetLedActivityEnd(id) {
      var path = this.$router.resolve({
        path: '/setLedActivityEnd?id=' + id
      });
      window.open("".concat(path.href), '_blank');
    },
    // 删除活动后页面
    onDelLedActivityEnd: function onDelLedActivityEnd(id) {
      var _this4 = this;

      if (this.ledConfInfo.img_id === id) {
        this.$message({
          type: 'error',
          message: '不能删除已选中的模板！'
        });
        return;
      }

      this.$confirm('确定删除吗？', '提示!', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(function () {
        var loading = _this4.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        DelConfColor({
          id: id
        }).then(function (res) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });

          _this4.doGetBaseSetting();
        }).catch(function (res) {
          _this4.$message({
            type: 'error',
            message: res.data.msg
          });
        }).finally(function (res) {
          loading.close();
        });
      });
    },
    titleSwitchChange: function titleSwitchChange(value) {
      this.waitJson.title.show = !value;
    },
    opacitySwitchChange: function opacitySwitchChange(value) {
      this.waitJson.title.opacity = !value ? 0.1 : 0;
    },
    waitTitleSwitchChange: function waitTitleSwitchChange(value) {
      this.waitJson.wait.show = !value;
    },
    waitOpacitySwitchChange: function waitOpacitySwitchChange(value) {
      this.waitJson.wait.opacity = !value ? 0.1 : 0;
    }
  }
};