module.exports = {
  // 全部组件
  list: [// 时间组件
  {
    title: '时间组件',
    type: 'time',
    children: [{
      title: '当前时间',
      type: 'currentTime'
    }, {
      title: '开盘时间',
      type: 'startTime'
    }, {
      title: '签到截止时间',
      type: 'singEnd'
    }, {
      title: '距离开始(结束)还',
      subTitle: '活动开始&结束倒计时',
      value: '21天12时78分01秒',
      type: 'eventTime'
    }, {
      subTitle: '批次开始倒计时',
      title: '距离第X批次开始还有',
      value: '78分01秒',
      type: 'batchEnd'
    }, // type 当时定义的时候定义反了无需理会
    {
      subTitle: '批次结束倒计时',
      title: '距离第X批次结束还有',
      type: 'batchStart',
      value: '78分01秒'
    }]
  }, // 指标组件
  {
    title: '指标组件',
    type: 'amount',
    children: [{
      title: '指标容器1',
      type: 'container',
      type2: 'true'
    }, {
      title: '指标容器2',
      type: 'container',
      type2: 'true'
    }, {
      title: '指标容器3',
      type: 'container',
      type2: 'true'
    }, {
      title: '参与人数',
      unit: 'people'
    }, {
      title: '房源&总数',
      subTitle: '开盘房源&总数',
      unit: 'room'
    }, {
      title: '车位&总数',
      subTitle: '开盘车位&总数',
      unit: 'car'
    }, {
      title: '可选房源&',
      subTitle: '可选房源&数量',
      unit: 'room'
    }, {
      title: '可选车位&',
      subTitle: '可选车位&数量',
      unit: 'car'
    }, {
      title: '已选房源&',
      subTitle: '已选房源&数量',
      unit: 'room'
    }, {
      title: '已选车位&',
      subTitle: '已选车位&数量',
      unit: 'car'
    }, {
      title: '已签到人数',
      unit: 'people'
    }, {
      title: '未签到人数',
      unit: 'people'
    }]
  }, // 其他组件
  {
    title: '其他组件',
    type: 'other',
    children: [{
      title: '项目名称',
      value: 'XXX项目',
      type: 'name'
    }, {
      title: '销售额',
      value: '3,884,272',
      type: 'saleMoney'
    }, {
      title: '认购流水',
      type: 'flow'
    }, {
      title: '品牌水印',
      type: 'watermark'
    }, {
      title: '当前批次',
      type: 'batch',
      value: '第xxx批',
      lineBreak: true,
      lineBreaTitle: '当前批次'
    }, {
      title: '后3批客户',
      type: 'afterBatch',
      lineBreak: true,
      value: '第2批\xa0\xa0第3批\xa0\xa0第4批',
      lineBreaTitle: '请以下客户准备'
    }, {
      title: '户型展示',
      type: 'houseType'
    }]
  }],
  // 默认组件
  defalutCommont: [{
    title: '项目名称',
    value: 'XXX项目',
    type: 'other',
    type2: 'name'
  }, {
    subTitle: '活动开始&结束倒计时',
    title: '距离开始(结束)还有',
    value: '21天12时78分01秒',
    type: 'time',
    type2: 'eventTime'
  }, {
    title: '销售额',
    value: '3,884,272',
    type: 'other',
    type2: 'saleMoney'
  }, {
    title: '指标容器1',
    type: 'amount',
    type2: 'container',
    children: [{
      title: '可选房源',
      value: '16',
      unit: 'room'
    }, {
      title: '参与人数',
      value: '16',
      unit: 'people'
    }]
  }, {
    title: '认购流水',
    type: 'other',
    type2: 'flow'
  }, // {
  // 	title: '户型&类型展示',
  // 	type:'other',
  // 	type2: 'houseType',
  // },
  {
    title: '品牌水印',
    type: 'other',
    type2: 'watermark'
  }]
};