/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-12 20:29,
 */
import request from '@/utils/request';
import qs from 'qs'; // 房源列表

export function roomIndex(data) {
  return request({
    url: 'Room/index',
    method: 'post',
    data: data
  });
} // 导出房源

export function roomExport(data) {
  return request({
    url: 'Room/export',
    method: 'post',
    data: data
  });
} // 删除房源

export function roomDelList(data) {
  return request({
    url: 'Room/delAll',
    method: 'post',
    data: data
  });
} // 房源详情

export function roomDetails(params) {
  return request({
    url: 'Room/details',
    method: 'get',
    params: params
  });
} // 重置关联房源

export function roomDelConfirm(data) {
  return request({
    url: 'Room/delConfirm',
    method: 'post',
    data: data
  });
} // 重置关联房源

export function roomVillaDelConfirm(data) {
  return request({
    url: 'car/delConfirm',
    method: 'post',
    data: data
  });
} // 修改房源

export function roomEdit(data) {
  return request({
    url: 'Room/edit',
    method: 'post',
    data: data
  });
} // 批量上传房源图片

export function roomSetAllImg(data) {
  return request({
    url: 'Room/setAllImg',
    method: 'post',
    data: data
  });
} // 批量删除房源图片

export function roomdelAllImg(data) {
  return request({
    url: 'Room/delAllImg',
    method: 'post',
    data: data
  });
} // 获取建发楼栋

export function getBuild(data) {
  return request({
    url: 'Room/getBuild',
    method: 'post',
    data: data
  });
} // 同步建发房间

export function syncRoom(data) {
  return request({
    url: 'Room/syncRoom',
    method: 'post',
    data: data
  });
} // 获取建发户型（房间车位通用）

export function getRoomCate(data) {
  return request({
    url: 'Room/getRoomCate',
    method: 'post',
    data: data
  });
} // 同步建发户型（房间车位通用）

export function syncRoomCate(data) {
  return request({
    url: 'Room/syncRoomCate',
    method: 'post',
    data: data
  });
} // 别墅列表

export function roomVillaIndex(data) {
  return request({
    // url: 'RoomVilla/index',
    url: '/car/index',
    method: 'post',
    data: data
  });
} // 导出别墅

export function roomVillaExport(data) {
  return request({
    url: '/car/export',
    method: 'post',
    data: data
  });
} // 获取建发车位

export function getCarBuild(data) {
  return request({
    url: 'car/getBuild',
    method: 'post',
    data: data
  });
} // 同步建发车位

export function syncCar(data) {
  return request({
    url: '/car/syncCar',
    method: 'post',
    data: data
  });
} // 删除别墅

export function roomVillaDelList(data) {
  return request({
    url: '/car/delAll',
    method: 'post',
    data: data
  });
} // 获取别墅 区域

export function villaAreaList(params) {
  return request({
    url: 'project/villaArea',
    method: 'get',
    params: params
  });
} // 别墅详情

export function roomVillaInfo(params) {
  return request({
    url: 'car/details',
    method: 'get',
    params: params
  });
} // 修改别墅

export function roomVillaEdit(data) {
  return request({
    url: '/car/edit',
    method: 'post',
    data: data
  });
} // 住宅关联列表

export function roomRelation(params) {
  return request({
    url: 'Room/relationRound',
    method: 'get',
    params: params
  });
} // 房间分组

export function groupRoom(data) {
  return request({
    url: 'Room/groupRoom',
    method: 'POST',
    data: data
  });
} // 别墅关联列表

export function RoomVillaRelation(params) {
  return request({
    url: 'car/relationRound',
    method: 'get',
    params: params
  });
} // 别墅分组

export function groupRoomVilla(data) {
  return request({
    url: 'car/groupRoom',
    method: 'POST',
    data: data
  });
} // 房源关联场次 导出

export function RoomEventExport(params) {
  return request({
    url: 'Room/eventExport',
    method: 'get',
    params: params
  });
} // 别墅关联场次 导出

export function villaEventExport(params) {
  return request({
    url: 'car/eventExport',
    method: 'get',
    params: params
  });
} // 住宅关联场次 提交

export function roomConfirm(data) {
  return request({
    url: 'Room/confirm',
    method: 'post',
    data: data
  });
} // 别墅关联场次 提交

export function roomVillaConfirm(data) {
  return request({
    url: 'car/confirm',
    method: 'post',
    data: data
  });
} // ------ 终端显示 ------
// 获取楼层房间

export function roomTerminal(params) {
  return request({
    url: 'Room/terminal',
    method: 'get',
    params: params
  });
} //  别墅获取楼层房间

export function roomVillaTerminal(params) {
  return request({
    url: 'car/terminal',
    method: 'get',
    params: params
  });
} // 住宅 获取排序列表

export function roomSortList(params) {
  return request({
    url: 'Room/sortList',
    method: 'get',
    params: params
  });
} // 别墅 获取排序列表

export function roomVillaSortList(params) {
  return request({
    url: 'car/sortList',
    method: 'get',
    params: params
  });
} // 住宅 排序保存

export function roomSortSave(data) {
  return request({
    url: 'Room/sort',
    method: 'post',
    data: data
  });
} // 别墅 排序保存

export function roomVillaSortSave(data) {
  return request({
    url: 'car/sort',
    method: 'post',
    data: data
  });
} //  意向单查询

export function intention(params) {
  return request({
    url: '/Room/getIntention',
    method: 'get',
    params: params
  });
} //  意向单查询

export function getBrowse(params) {
  return request({
    url: '/Room/browse',
    method: 'get',
    params: params
  });
} //  住宅  关注情况

export function getPreference(params) {
  return request({
    url: 'Room/heat',
    method: 'get',
    params: params
  });
} //  住宅  活动数据

export function getEventData(params) {
  return request({
    url: 'Room/eventData',
    method: 'get',
    params: params
  });
} //  住宅  日志

export function getLogList(params) {
  return request({
    url: 'Room/logList',
    method: 'get',
    params: params
  });
} //  别墅  关注情况

export function getVillaPreference(params) {
  return request({
    url: 'car/heat',
    method: 'get',
    params: params
  });
} //  别墅  活动数据

export function getVillaEventData(params) {
  return request({
    url: 'car/eventData',
    method: 'get',
    params: params
  });
} //  别墅  日志

export function getVillaLogList(params) {
  return request({
    url: 'car/logList',
    method: 'get',
    params: params
  });
} //  房源 终端排序

export function sortValue(params) {
  return request({
    url: "Room/sortValue",
    method: 'get',
    params: params
  });
} //  车位 终端排序

export function carSortValue(params) {
  return request({
    url: "car/sortValue",
    method: 'get',
    params: params
  });
} // 平面图
//  平面图层级结构

export function carMapIndex(data, cate) {
  return request({
    url: (cate === 1 ? 'Room' : 'car') + '/mapIndex',
    method: 'post',
    data: data
  });
}
export function villaArea(params, cate) {
  return request({
    url: "project/" + (cate === 1 ? 'configure' : 'villaArea'),
    method: 'get',
    params: params
  });
}
export function carSetMap(data, cate) {
  return request({
    url: (cate === 1 ? 'Room' : 'car') + '/setMap',
    method: 'post',
    data: data
  });
}
export function carMapList(data, cate) {
  return request({
    url: (cate === 1 ? 'Room' : 'car') + '/mapList',
    method: 'post',
    data: data
  });
}
export function carMapCarUp(data, cate) {
  return request({
    url: (cate === 1 ? 'Room' : 'car') + '/mapCarUp',
    method: 'post',
    data: data
  });
}
export function carMapSort(data, cate) {
  return request({
    url: (cate === 1 ? 'Room' : 'car') + '/mapSort',
    method: 'post',
    data: data
  });
} // 批量上传车位图片

export function carSetAllImg(data) {
  return request({
    url: 'car/setAllImg',
    method: 'post',
    data: data
  });
} // 批量删除车位图片

export function cardelAllImg(data) {
  return request({
    url: 'car/delAllImg',
    method: 'post',
    data: data
  });
} // 修改楼栋单元楼层名称

export function roomSetBUF(data) {
  return request({
    url: '/Room/setBUF',
    method: 'post',
    data: data
  });
} //

export function carSetBUF(data) {
  return request({
    url: '/car/setBUF',
    method: 'post',
    data: data
  });
}
export function mapDel(data) {
  return request({
    url: '/car/mapDel',
    method: 'post',
    data: data
  });
}
export function fastSort(data) {
  return request({
    url: '/car/fastSort',
    method: 'post',
    data: data
  });
}
export function RoomFastSort(data) {
  return request({
    url: '/Room/fastSort',
    method: 'post',
    data: data
  });
}