import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { EventRandExport, eventRandrandList, ResetLog, syncUser } from '@/api/lottery';
import { adminParameter } from '@/api/public';
import { debounce } from '@/utils/debounce';
import utils from '@/utils/utils';
export default {
  name: 'LotteryRecord',
  data: function data() {
    return {
      // 数据走哪条数
      total: 0,
      // 当前每页条数
      pageSize: 10,
      // 当前页
      page: 1,
      // 组别数组
      levelInfo: [],
      // 轮次数组
      roundInfo: [],
      // 是否有组别
      is_level: false,
      // 搜索提交
      search: {
        is_set: 0,
        is_admission: 0,
        search: '',
        level_id: 0,
        is_order: 0
      },
      // table 高度
      appHeight: 0,
      // 搜索烂高度
      topHeight: 0,
      // 表格数据
      tableData: [],
      // 打开分组弹框
      isGrouping: false,
      // 分组列表
      selGroup: [],
      // 页面 活动数据
      event: {},
      list: {},
      activity: {
        project: {},
        batch: {}
      },
      handleSelectionIds: [],
      isDialog: false,
      info: {},
      transferDialog: false,
      rand_type: 0
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 145;
    }
  },
  mounted: function mounted() {
    var _this = this;

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化页面
  created: function created() {
    var _this2 = this;

    this.getTableInfo(); //  项目配置

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this2.list = list;
      }
    });
  },
  methods: {
    // 转入分批摇号弹窗打开
    openTransfer: function openTransfer() {
      this.transferDialog = true;
    },
    confimtransfer: function confimtransfer() {
      var _this3 = this;

      var msg = this.rand_type === 0 ? '轮次' : '序号';
      this.$confirm("\u786E\u8BA4\u6309".concat(msg, "\u987A\u5E8F\u5199\u5165\u5206\u6279\u5E8F\u53F7\u5417\uFF1F\u6B64\u64CD\u4F5C\u4E0D\u53EF\u6062\u590D!"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        syncUser({
          id: Number(_this3.$route.query.id),
          rand_type: _this3.rand_type
        }).then(function (res) {
          if (res.status === 200) {
            _this3.$message.success(res.data.msg);

            _this3.transferDialog = false;
          }
        });
      });
    },
    // 导出摇号记录
    handleExport: function handleExport() {
      var _this4 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var data = {
        id: this.$route.query.id
      }; // 导出数据

      EventRandExport(Object.assign(this.search, data)).then(function (res) {
        if (res.status === 200) {
          _this4.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.msg


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    // 获取button 文字
    getButtonText: function getButtonText(type) {
      var text = null;

      switch (type) {
        case 1:
          text = '正式';
          break;

        case 2:
          text = '公测';
          break;

        case 3:
          text = '内测';
          break;

        case 4:
          text = '拟测';
          break;
      }

      return text;
    },
    //  获取表格数据  分组 轮次
    getTableInfo: debounce(function () {
      var _this5 = this;

      var data = {
        id: this.$route.query.id,
        page: this.page,
        limit: this.pageSize
      };
      this.handleSelectionIds = [];
      eventRandrandList(Object.assign(this.search, data)).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              level = _res$data.level,
              rand_round = _res$data.rand_round,
              count = _res$data.count,
              event = _res$data.event;
          _this5.total = count;
          _this5.tableData = list;
          _this5.event = event;
          _this5.levelInfo = level;
          _this5.is_level = level === null ? false : !(level.length <= 0);
          _this5.roundInfo = rand_round;
        }
      });
    }, 200, true),
    // 重置搜索项
    resetForm: function resetForm() {
      this.$refs['formData'].resetFields();
      this.getTableInfo();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableInfo();
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableInfo();
    },
    doTimeToDate: function doTimeToDate(time) {
      return utils.formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss');
    },
    // 选中记录
    handleSelectionChange: function handleSelectionChange(event) {
      var handleSelectionIds = [];

      for (var i = 0; i < event.length; i++) {
        handleSelectionIds.push(event[i].id);
      }

      this.handleSelectionIds = handleSelectionIds;
    },
    // 作废记录
    onResetlog: function onResetlog() {
      var _this6 = this;

      this.$confirm('确定要作废记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        ResetLog({
          ids: _this6.handleSelectionIds
        }).then(function (result) {
          var _list$length, _list, _list$length2, _list2, _list3;

          var list = result.data.list;
          if (!list) list = [];
          console.log(list);
          _this6.isDialog = true;
          var suc = _this6.handleSelectionIds.length - ((_list$length = (_list = list) === null || _list === void 0 ? void 0 : _list.length) !== null && _list$length !== void 0 ? _list$length : 0);
          var err = (_list$length2 = (_list2 = list) === null || _list2 === void 0 ? void 0 : _list2.length) !== null && _list$length2 !== void 0 ? _list$length2 : 0;
          var user = (_list3 = list) === null || _list3 === void 0 ? void 0 : _list3.map(function (item) {
            return item.name;
          }).join(',');
          var err_user = user;
          _this6.info = {
            suc: suc,
            err: err,
            err_user: err_user
          };

          _this6.getTableInfo(); // }

        });
      }).catch(function () {});
    },
    // 查看区块链
    lookBaas: function lookBaas(row) {
      window.open("https://chaininsight.antdigital.com/tx/".concat(row.baas_hash, "?bizId=a00e36c5&unionId=2647&projectName=TZDKBPWN"));
    }
  }
};