//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'LinkSettings',
  props: {
    data: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    info: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      style: {
        width: '100%',
        'text-align': 'center'
      }
    };
  },
  methods: {
    handlePriceRand: function handlePriceRand(e) {
      if (e) {
        this.data.price = e;
      }
    },
    // 操作选房
    chooseRoom: function chooseRoom(e) {
      if (!e) {
        this.data.buy_rand = e;
      }
    },
    // 操作交定金
    handlePrice: function handlePrice(e) {
      if (!e) {
        this.data.price_rand = e;
      }
    },
    // 操作签约
    handleSignUP: function handleSignUP(e) {
      if (!e) {
        this.data.sign_up_rand = e;
      }
    },
    // 操作选房摇号
    chaoseBuy: function chaoseBuy(e) {
      if (e) {
        this.data.buy = e;
      }
    },
    // 允许签到时间
    handleSignUpTime: function handleSignUpTime(e) {
      this.data.sign_up_time = e;
    }
  }
};