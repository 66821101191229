var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-header", staticStyle: { height: "68px" } },
        [
          _c("div", { staticClass: "page-header-left" }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "pingpong/setlottery"],
                  expression: "['admin', 'pingpong/setlottery']",
                },
              ],
              staticClass: "page-header-right",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "greens-btn mL10",
                  attrs: { size: "small" },
                  on: { click: _vm.openLotteryDialog },
                },
                [_vm._v("规则设置 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "block-bottom mT10" }, [
        _c("div", { staticClass: "list-item" }, [
          _c("div", { staticClass: "list-item-header" }, [
            _c("div", { staticClass: "el-tooltip header-left-status" }, [
              _vm._v(" " + _vm._s(_vm.getText(true, _vm.info)) + " "),
            ]),
          ]),
          _c("div", { staticClass: "list-item-box" }, [
            _c("div", { staticClass: "item-content" }, [
              _c("div", { staticClass: "content-left" }, [
                _c("span", { staticClass: "content-text" }, [
                  _vm._v("已摇号 " + _vm._s(_vm.info.is_count) + " 人"),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "pingpong/list"],
                        expression: "['admin', 'pingpong/list']",
                      },
                    ],
                    staticClass: "btn",
                    on: { click: _vm.toRecord },
                  },
                  [_vm._v("摇号记录")]
                ),
              ]),
              _c("div", { staticClass: "content-right content-text" }, [
                _c("span", [
                  _vm._v("待摇号 " + _vm._s(_vm.info.not_count) + " 人"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "item-content" }, [
              _c("div", { staticClass: "content-left" }, [
                _c("span", { staticClass: "content-text" }, [
                  _vm._v(
                    "客户总数: 全部客户 -（" +
                      _vm._s(_vm.info.all_count) +
                      "人）"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "list-item-operation" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["pingpong/generate"],
                    expression: "['pingpong/generate']",
                  },
                ],
                on: { click: _vm.handleStartLottery },
              },
              [
                _c("img", {
                  staticClass: "icon_image",
                  attrs: { src: require("@/assets/icon/bofang.png") },
                }),
                _c("span", [_vm._v(_vm._s(_vm.getText(false, _vm.info)))]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "pingpong/publicity"],
                    expression: "['admin', 'pingpong/publicity']",
                  },
                ],
                on: { click: _vm.toPublicity },
              },
              [
                _c("img", {
                  staticClass: "icon_image",
                  attrs: { src: require("@/assets/icon/daping.png") },
                }),
                _c("span", [_vm._v("公示大屏")]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["admin", "pingpong/reset"],
                    expression: "['admin', 'pingpong/reset']",
                  },
                ],
                on: { click: _vm.resetting },
              },
              [
                _c("img", {
                  staticClass: "icon_image",
                  attrs: { src: require("@/assets/icon/reset.png") },
                }),
                _c("span", [_vm._v("重置摇号")]),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.setLotteryShow,
            title: "摇号规则设置",
            width: "550px",
          },
          on: {
            close: function ($event) {
              _vm.setLotteryShow = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "center" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    "label-width": "110px",
                    model: _vm.seParams,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "rand_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.seParams.rand_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.seParams, "rand_name", $$v)
                          },
                          expression: "seParams.rand_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "号码位数", prop: "entry_num" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 10 },
                        model: {
                          value: _vm.seParams.entry_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.seParams, "entry_num", $$v)
                          },
                          expression: "seParams.entry_num",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "摇号录入顺序", prop: "entry_num" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.seParams.sequence,
                            callback: function ($$v) {
                              _vm.$set(_vm.seParams, "sequence", $$v)
                            },
                            expression: "seParams.sequence",
                          },
                        },
                        [_vm._v("从左到右")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.seParams.sequence,
                            callback: function ($$v) {
                              _vm.$set(_vm.seParams, "sequence", $$v)
                            },
                            expression: "seParams.sequence",
                          },
                        },
                        [_vm._v("从右到左")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "公示滚动速度", prop: "roll_speed" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 10 },
                        model: {
                          value: _vm.seParams.roll_speed,
                          callback: function ($$v) {
                            _vm.$set(_vm.seParams, "roll_speed", $$v)
                          },
                          expression: "seParams.roll_speed",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "背景图片", prop: "log" } },
                    [_c("img-up", { on: { upSuccess: _vm.upSuccess } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setLotteryShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setLottery("ruleForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }