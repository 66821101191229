/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-07-30 11:27,
 */
import request from '@/utils/request'; // import qs from 'qs'

export default {
  //	住宅列表
  roomList: function roomList(params) {
    return request({
      url: '/roomHot/roomList',
      method: 'get',
      params: params
    });
  },
  //	住宅列表
  buildList: function buildList(params) {
    return request({
      url: '/roomHot/buildList',
      method: 'get',
      params: params
    });
  },
  //	别墅列表
  villaList: function villaList(params) {
    return request({
      url: '/roomHot/villaList',
      method: 'get',
      params: params
    });
  },
  //	干预热度
  intervene: function intervene(data) {
    return request({
      url: '/roomHot/intervene',
      method: 'post',
      data: data
    });
  },
  //	取消干预
  cancelHot: function cancelHot(data) {
    return request({
      url: '/roomHot/cancelHot',
      method: 'post',
      data: data
    });
  },
  exportCancelHot: function exportCancelHot(data) {
    return request({
      url: '/collect/export',
      method: 'post',
      data: data
    });
  }
};