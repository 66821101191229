var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _vm.rand.show_rand_code &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("顺序号横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.num_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList.host_json, "num_width", $$v)
                  },
                  expression: "editFormatList.host_json.num_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_name &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("姓名横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.name_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList.host_json, "name_width", $$v)
                  },
                  expression: "editFormatList.host_json.name_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_tel &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("手机号横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.tel_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList.host_json, "tel_width", $$v)
                  },
                  expression: "editFormatList.host_json.tel_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_audit_order &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("诚意单号横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.code_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList.host_json, "code_width", $$v)
                  },
                  expression: "editFormatList.host_json.code_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_card == 1 &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("身份证号横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.card_width,
                  callback: function ($$v) {
                    _vm.$set(_vm.editFormatList.host_json, "card_width", $$v)
                  },
                  expression: "editFormatList.host_json.card_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.rand.show_other_remark &&
    _vm.editFormatList.host_json.card_content_mode === "row"
      ? _c("div", { staticClass: "setting-wrapper" }, [
          _c("div", { staticClass: "left" }, [_vm._v("其他信息横向间距")]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-input-number", {
                staticClass: "input-number__style",
                model: {
                  value: _vm.editFormatList.host_json.other_remark_width,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editFormatList.host_json,
                      "other_remark_width",
                      $$v
                    )
                  },
                  expression: "editFormatList.host_json.other_remark_width",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }