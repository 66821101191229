//
//
//
//
//
//
//
export default {
  name: 'SearchUser',
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    bgStyle: function bgStyle() {
      return {
        width: this.width,
        height: this.height
      };
    },
    searchStyle: function searchStyle() {
      return {
        width: '50%',
        height: '50%'
      };
    }
  }
};