var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", attrs: { id: "userDatails" } }, [
    _vm._m(0),
    _c("div", { staticClass: "detail-box" }, [
      _c(
        "div",
        { staticClass: "block" },
        [
          _vm._m(1),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("房源：")]),
                  _c("div", {
                    staticClass: "text",
                    domProps: { textContent: _vm._s(_vm.info.room) },
                  }),
                ]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("定金金额：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "实付 " +
                        _vm._s(_vm.moneyData(_vm.info.price)) +
                        " (诚意金抵扣 " +
                        _vm._s(_vm.moneyData(_vm.info.deduction)) +
                        ")"
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("客户信息：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(_vm.info.user_name) +
                        "," +
                        _vm._s(_vm.info.user_tel)
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "detail-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("付款信息：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(_vm.info.handled === 1 ? "线下付款" : "线上付款") +
                        " " +
                        _vm._s(_vm._f("dateFormat")(_vm.info.pay_time * 1000))
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("el-row", { attrs: { gutter: 20 } }, [
            _c("div", { staticClass: "img-bx" }, [
              _c(
                "div",
                { staticClass: "img-border" },
                [
                  _vm.path
                    ? _c("el-image", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.imgUrl + _vm.path },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-fixed" }, [
      _c("div", { staticClass: "title-top-box align-items" }, [
        _c("div", { staticClass: "title flex align-items" }, [
          _c("div", [_vm._v("电子收据")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-h2" }, [
      _c("div", { staticClass: "flex space-between" }, [
        _c("div", [_vm._v("收据信息")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }