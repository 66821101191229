import _defineProperty from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'editCommon',
  computed: _objectSpread(_objectSpread({}, mapGetters(['commontList', 'parentIndex'])), {}, {
    currentForm: {
      get: function get() {
        var _this$$store,
            _this = this;

        // let obj = this.$store?.getters.commontList.find(item=>item.title == this.$store?.getters.currentForm);
        var obj = (_this$$store = this.$store) === null || _this$$store === void 0 ? void 0 : _this$$store.getters.commontList[this.parentIndex];
        var styleForm = obj === null || obj === void 0 ? void 0 : obj.styleForm;

        if (!styleForm) {
          return [];
        }

        var newStyleForm = [],
            iscontainer = obj.type == 'amount',
            subObj;
        this.iscontainer = iscontainer;

        if (iscontainer) {
          subObj = obj.children.find(function (item) {
            var _this$$store2;

            return item.title == ((_this$$store2 = _this.$store) === null || _this$$store2 === void 0 ? void 0 : _this$$store2.getters.subCurrentForm);
          });

          if (!subObj) {
            styleForm = {
              background: styleForm.background
            };
          } else {
            styleForm = _objectSpread(_objectSpread({}, subObj.styleForm), {}, {
              background: styleForm.background
            });
          }
        }

        for (var styleFormKey in styleForm) {
          if (!styleFormKey.includes('_')) {
            newStyleForm.push({
              maxTitle: iscontainer ? 'rq-bg' : 'bg',
              style: _defineProperty({}, styleFormKey, styleForm[styleFormKey])
            });
          } else {
            (function () {
              var type = styleFormKey.split('_')[0];
              var pushIndex = newStyleForm.findIndex(function (items) {
                return items.maxTitle == type;
              });
              pushIndex > -1 ? newStyleForm[pushIndex].style = _objectSpread(_defineProperty({}, styleFormKey, styleForm[styleFormKey]), newStyleForm[pushIndex].style) : newStyleForm.push({
                maxTitle: type,
                style: _defineProperty({}, styleFormKey, styleForm[styleFormKey])
              });
            })();
          }
        }

        return newStyleForm;
      },
      set: function set(val) {}
    }
  }),
  watch: {
    currentForm: function currentForm(to) {// console.log(	to)
    }
  },
  mounted: function mounted() {},
  data: function data() {
    return {
      iscontainer: false,
      show: true
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('rightSetting', ['SET_COMMONTLIST', 'DELTE_COMMONT'])), {}, {
    handleCheckAllChange: function handleCheckAllChange(val, key) {
      console.log(val, key);
      this.SET_COMMONTLIST({
        name: this.$store.getters.currentForm,
        key: key,
        val: val,
        iscontainer: this.iscontainer
      });
      this.$forceUpdate();
    },
    submit: function submit() {
      console.log(this.currentForm);
    },
    seTltle: function seTltle(str) {
      switch (str) {
        case 'bg':
          return '背景';

        case 'rq-bg':
          return '容器背景';

        case 'title':
          return '标题';

        case 'time':
          return '时间';

        case 'timeJd':
          return '进度提示';

        case 'timeJd':
          return '进度提示';

        case 'name':
          return '指标名称';

        case 'data':
          return '结果数据';

        case 'brand':
          return '文字';

        case 'record':
          return '记录卡片';

        case 'houseType':
          return '户型名称';

        default:
          return '文字';
      }
    },
    subtitle: function subtitle(str) {
      if (str == 'record_name_Color') {
        return '姓名颜色';
      } else if (str == 'record_name_size') {
        return '姓名字号';
      } else if (str == 'record_house_Color') {
        return '房号颜色';
      } else if (str == 'record_house_size') {
        return '房号字号';
      } else if (str == 'record_bg') {
        return '卡片背景';
      }

      if (str.includes('size')) {
        return '字号';
      } else if (str.includes('Bg') || str.includes('background')) {
        return '背景颜色';
      } else if (str.includes('timeJd_Bbatch')) {
        return '批次颜色';
      } else {
        return '字体颜色';
      }
    },
    deltCommont: function deltCommont() {
      var _this2 = this;

      //删除组件
      // {$store.getters.subCurrentForm
      this.$confirm('是否删除该组件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var amountIndex, subIndex;

        if (_this2.$store.getters.currentForm.includes('指标容器')) {
          amountIndex = _this2.commontList.findIndex(function (item) {
            return item.title == _this2.$store.getters.currentForm;
          }); //指标容器的下标

          subIndex = _this2.commontList[amountIndex].children.findIndex(function (item) {
            return item.title == _this2.$store.getters.subCurrentForm;
          }); //子元素下标
        }

        console.log(amountIndex, subIndex);

        _this2.DELTE_COMMONT({
          amountIndex: amountIndex,
          subIndex: subIndex
        });

        _this2.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  })
};