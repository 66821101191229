var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-format" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            "show-close": false,
            "with-header": false,
            modal: false,
            visible: _vm.setShow,
            size: 400,
          },
          on: {
            "update:visible": function ($event) {
              _vm.setShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-body" },
            [
              _c(
                "item",
                {
                  attrs: { title: "背景图", "sub-title": "使用默认图" },
                  on: { subTitleClick: _vm.resetImage },
                },
                [
                  _c("div", { staticClass: "img-up" }, [
                    _c(
                      "div",
                      { staticClass: "img-up-icon" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "pictureUp",
                            attrs: {
                              action: _vm.action,
                              "before-upload": _vm.uploadBefore,
                              "http-request": _vm.handleFileUploadOss,
                              limit: 1,
                              "on-change": _vm.uploadChange,
                              "on-error": _vm.uploadFail,
                              "on-success": _vm.uploadSucess,
                              "show-file-list": false,
                              accept: _vm.$imgSel,
                              "list-type": "picture-card",
                            },
                          },
                          [
                            !_vm.setData.logo
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/page/bg.png"),
                                  },
                                })
                              : _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.imgUrl + _vm.setData.logo },
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", [
                      _c("div", { staticClass: "img-up-text" }, [
                        _c("span", { staticClass: "text-top" }, [
                          _vm._v("背景图片最佳尺寸为:"),
                        ]),
                        _c("span", { staticClass: "text-bottom" }, [
                          _vm._v(
                            "（1920*1080）px，不大于" +
                              _vm._s(_vm.imgSizeLimit) +
                              "M。"
                          ),
                        ]),
                        _c("div", { staticClass: "tip" }, [_vm._v("上传替换")]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "item",
                { attrs: { title: "摇号名称" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { type: "text", placeholder: "请输入该摇号的名称" },
                    model: {
                      value: _vm.setData.rand_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.setData, "rand_name", $$v)
                      },
                      expression: "setData.rand_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "item",
                { attrs: { title: "客户类别" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.setData.user_class,
                        callback: function ($$v) {
                          _vm.$set(_vm.setData, "user_class", $$v)
                        },
                        expression: "setData.user_class",
                      },
                    },
                    _vm._l(_vm.user_class, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "item",
                { attrs: { title: "号码球位数" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.setData.ball_num,
                        callback: function ($$v) {
                          _vm.$set(_vm.setData, "ball_num", $$v)
                        },
                        expression: "setData.ball_num",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "3", value: 3 } }),
                      _c("el-option", { attrs: { label: "4", value: 4 } }),
                      _c("el-option", { attrs: { label: "5", value: 5 } }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "unit" }, [_vm._v("位")]),
                ],
                1
              ),
              _c(
                "item",
                { attrs: { title: "最大抽取数" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "unit",
                      staticStyle: { "justify-content": "flex-start" },
                    },
                    [_vm._v("数量")]
                  ),
                  _c("el-input-number", {
                    attrs: { size: "small", min: 0, label: "数量" },
                    model: {
                      value: _vm.setData.max_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.setData, "max_num", $$v)
                      },
                      expression: "setData.max_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "item",
                { attrs: { title: "公示屏幕数" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "unit",
                      staticStyle: { "justify-content": "flex-start" },
                    },
                    [_vm._v("数量")]
                  ),
                  _c("el-input-number", {
                    attrs: { size: "small", min: 1, label: "数量" },
                    model: {
                      value: _vm.setData.show_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.setData, "show_num", $$v)
                      },
                      expression: "setData.show_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "item",
                { attrs: { title: "公示屏展示号数" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.setData.show_num_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.setData, "show_num_code", $$v)
                        },
                        expression: "setData.show_num_code",
                      },
                    },
                    _vm._l(_vm.show_num_code, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { height: "20px" } }),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("保存当前设置")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }