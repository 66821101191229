var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "screenBox", staticClass: "screen-left-wrapper" }, [
    !_vm.isShow
      ? _c(
          "div",
          { ref: "screenCarousel", staticClass: "screen-carousel" },
          [
            _c(
              "el-carousel",
              { attrs: { height: "97%", "indicator-position": "outside" } },
              _vm._l(1, function (item, j) {
                return _c("el-carousel-item", { key: j }, [
                  _c(
                    "div",
                    { staticClass: "item-box" },
                    _vm._l(_vm.houseList, function (items, i) {
                      return _c("item", {
                        key: i,
                        ref: "docItems",
                        refInFor: true,
                        staticClass: "houseItems",
                        attrs: {
                          "card-type": _vm.ledConfInfo.show_type,
                          "header-color": _vm.ledConfInfo.first_back,
                          "item-detail": items,
                          "led-conf-info": _vm.ledConfInfo,
                          "project-info": _vm.projectInfo,
                          "project-room-info": _vm.projectRoomInfo,
                        },
                      })
                    }),
                    1
                  ),
                ])
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { ref: "screenCarousel", staticClass: "screen-carousel" },
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  autoplay: true,
                  interval: 6000,
                  height: "97%",
                  "indicator-position": "outside",
                },
              },
              _vm._l(_vm.carouselList, function (item, j) {
                return _c("el-carousel-item", { key: j }, [
                  _c(
                    "div",
                    { staticClass: "item-box" },
                    _vm._l(item, function (houseItems, i) {
                      return _c("item", {
                        key: i,
                        ref: "docItems",
                        refInFor: true,
                        staticClass: "houseItems",
                        attrs: {
                          "card-type": _vm.ledConfInfo.show_type,
                          "font-color": _vm.ledConfInfo.first_size,
                          "header-color": _vm.ledConfInfo.first_back,
                          "item-detail": houseItems,
                          "led-conf-info": _vm.ledConfInfo,
                          "project-info": _vm.projectInfo,
                          "project-room-info": _vm.projectRoomInfo,
                        },
                      })
                    }),
                    1
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }