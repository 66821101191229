var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-box mT10" }, [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c("div", { staticClass: "top-box" }, [
          _c(
            "div",
            { staticClass: "top-block flex space-between align-items" },
            [
              _c(
                "div",
                { staticClass: "top-left flex space-between align-items" },
                [
                  _c("span", { staticClass: "tips" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.type == "build"
                            ? _vm.customSetting.villa_setting.house_num
                            : "房号"
                        )
                    ),
                  ]),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.room.room)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "top-right flex" }, [
                _c("div", { staticClass: "item-list" }, [
                  _c("span", [_vm._v("真实热度值：")]),
                  _vm.heat.hot_status === 1
                    ? _c("span", [_vm._v("低")])
                    : _vm.heat.hot_status === 2
                    ? _c("span", [_vm._v("中低")])
                    : _vm.heat.hot_status === 3
                    ? _c("span", [_vm._v("中高")])
                    : _vm.heat.hot_status === 4
                    ? _c("span", [_vm._v("高")])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item-list" }, [
                  _c("span", [_vm._v("干预热度值：")]),
                  _vm.room.hot_count === 1
                    ? _c("span", [_vm._v("低")])
                    : _vm._e(),
                  _vm.room.hot_count === 0
                    ? _c("span", [_vm._v("无干预")])
                    : _vm.room.hot_count === 2
                    ? _c("span", [_vm._v("中低")])
                    : _vm.room.hot_count === 3
                    ? _c("span", [_vm._v("中高")])
                    : _vm.room.hot_count === 4
                    ? _c("span", [_vm._v("高")])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ]),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "tab-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    class: _vm.tabIndex === 0 ? "on" : "",
                    on: {
                      click: function ($event) {
                        return _vm.handleTab(0)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("第一意向数（人）"),
                    ]),
                    _c("div", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.heat.one_count)),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "tab-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    class: _vm.tabIndex === 1 ? "on" : "",
                    on: {
                      click: function ($event) {
                        return _vm.handleTab(1)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("其余意向数（人）"),
                    ]),
                    _c("div", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.heat.other_count)),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "tab-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    class: _vm.tabIndex === 2 ? "on" : "",
                    on: {
                      click: function ($event) {
                        return _vm.handleTab(2)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("浏览记录（次）"),
                    ]),
                    _c("div", { staticClass: "num" }, [
                      _vm._v(_vm._s(_vm.heat.browse_count)),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _vm.tabIndex === 0
              ? _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, height: _vm.tableHeight },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "name", label: "姓名" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "tel",
                        label: "登录手机号",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "admin_name",
                        label: "置业顾问",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "收藏时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scoped) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        scoped.row.created_at * 1000
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3663609523
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "coll_count",
                        label: "其余意向数",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "b_count",
                        label: "浏览次数",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.tabIndex === 1
              ? _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData1, height: _vm.tableHeight },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "name", label: "姓名" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "tel",
                        label: "登录手机号",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "admin_name",
                        label: "置业顾问",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "收藏时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scoped) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        scoped.row.created_at * 1000
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3663609523
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "sort",
                        label: "意向单排序",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "b_count",
                        label: "浏览次数",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.tabIndex === 2
              ? _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData2, height: _vm.tableHeight },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "name", label: "姓名" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "tel",
                        label: "登录手机号",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "admin_name",
                        label: "置业顾问",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "created_at",
                        label: "浏览时间",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scoped) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        scoped.row.created_at * 1000
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3663609523
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _vm.pagin
              ? _c("el-pagination", {
                  attrs: {
                    "page-sizes": _vm.sizes,
                    "page-size": _vm.pageSize,
                    layout: _vm.layouts,
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }