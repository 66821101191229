var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("轮次字号")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.round_size,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "round_size", $$v)
              },
              expression: "editFormatList.round_size",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("颜色")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-color-picker", {
            attrs: { size: "mini" },
            model: {
              value: _vm.editFormatList.round_color,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "round_color", $$v)
              },
              expression: "editFormatList.round_color",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("上下位置")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.round_position,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "round_position", $$v)
              },
              expression: "editFormatList.round_position",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("待摇号字号")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.host_json.await_round_size,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList.host_json, "await_round_size", $$v)
              },
              expression: "editFormatList.host_json.await_round_size",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }