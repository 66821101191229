import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import utils from '@/utils/utils'; // 权限判断指令

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '导入'
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data: function data() {
    return {
      // 请求头
      headers: {
        token: this.getToken(),
        project: JSON.parse(this.getProject()).project_id
      },
      isDialog: false,
      num: '1',
      info: {},
      // 导入弹框
      isRadio: false,
      // 导入方式
      radio: '1',
      // 800毫秒过后开始出现 loading
      hm: 800
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    if (this.roles.includes('admin')) {
      this.radio = '1';
    } else if (this.roles.includes('user/import')) {
      this.radio = '1';
    } else if (this.roles.includes('user/addimport')) {
      this.radio = '2';
    } else if (this.roles.includes('user/importprice')) {
      this.radio = '3';
    } else if (this.roles.includes('user/sleepimport')) {
      this.radio = '4';
    } else {
      this.radio = '';
    }
  },
  methods: {
    // 打开 导入模式
    handleShowDialog: function handleShowDialog() {
      this.isRadio = true;
    },
    // 上传格式 大小限制
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // 倒计时 800 毫秒 出现 loading
      this.loading = this.$loading({
        lock: true,
        text: '努力上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // excel

      var isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
      var isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传只能是 excel 格式!');
        this.loading.close();
      }

      if (!isLt2M) {
        this.$message.error('上传大小不能超过 10MB!');
        this.loading.close();
      }

      return isJPG && isLt2M;
    },
    // 上传成回调
    handelSuccess: function handelSuccess(response, file, fileList) {
      this.$message({
        type: 'success',
        message: response.msg
      });

      if (response.info !== undefined) {
        this.info = response.info;
        this.isDialog = true;
      }

      if (response.path !== '' && response.path !== null && response.path !== undefined) {
        // window.location.href = this.host + response.path
        utils.download_files_base64(response.data, response.name, response.suffix);
      }

      this.$emit('uploadSuccess', {
        radio: this.radio
      });
      this.loading.close();
      this.isRadio = false;
    },
    // 上传失败
    handelError: function handelError(err, file, fileList) {
      var _this = this;

      var myError = err.toString(); // 转字符串

      myError = myError.replace('Error: ', ''); // 去掉前面的" Error: "

      myError = JSON.parse(myError); // 转对象

      this.$message({
        type: 'error',
        message: myError.msg
      });
      this.loading.close();
      this.$emit('uploadSuccess');
      this.isRadio = false;

      if (myError.msg !== '项目已失效无权操作~') {
        if (myError.is_audit && myError.is_audit) {
          this.$confirm(myError.msg + '请审核完成再进行导入操作~', '提示', {
            confirmButtonText: '去处理',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(function () {
            _this.$router.push({
              name: 'UserAudit'
            });
          }).catch(function (err) {});
        } else {
          if (myError.path) {
            window.location.href = this.host + myError.path;
          }
        }
      }
    },
    // 监听导入方法
    handleCommand: function handleCommand(e) {
      this.num = e;
    }
  }
};