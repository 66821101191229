var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-setting" }, [
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("字号")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.name_size,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "name_size", $$v)
              },
              expression: "editFormatList.name_size",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("颜色")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-color-picker", {
            attrs: { size: "mini" },
            model: {
              value: _vm.editFormatList.name_color,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "name_color", $$v)
              },
              expression: "editFormatList.name_color",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "setting-wrapper" }, [
      _c("div", { staticClass: "left" }, [_vm._v("上下位置")]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("el-input-number", {
            staticClass: "input-number__style",
            model: {
              value: _vm.editFormatList.name_position,
              callback: function ($$v) {
                _vm.$set(_vm.editFormatList, "name_position", $$v)
              },
              expression: "editFormatList.name_position",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }