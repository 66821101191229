var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pie-chart-wrapper" }, [
    _c("div", {
      staticClass: "pie-chart",
      style: "height:" + _vm.picHeight + "px;",
      attrs: { id: _vm.id },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }