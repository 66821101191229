// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#475852",
	"menuActiveText": "#ffffff",
	"subMenuActiveText": "#019b81",
	"menusonhoverBg": "#cfeae1",
	"menusonhoverText": "#019b81",
	"menuBg": "#ffffff",
	"menuHoverText": "#019b81",
	"menuHoverBg": "rgba(34, 162, 113, 0.2)",
	"subMenuBg": "#ffffff",
	"subMenuHover": "#019b81",
	"sideBarWidth": "210px",
	"sonHoverText": "#019b81",
	"sonHoverBg": "rgba(34, 162, 113, 0.2)"
};
module.exports = exports;
