var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting" },
    [
      _c("div", { staticClass: "component-library" }, [_c("commonLibrary")], 1),
      _c("div", { staticClass: "right-content" }, [
        _c(
          "div",
          { staticClass: "canvas-content" },
          [
            _c("canvasContent", {
              on: { save: _vm.save, copyChange: _vm.copyLed },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "edit-content" }, [_c("editCommon")], 1),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复制屏幕",
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.ledIds,
                            callback: function ($$v) {
                              _vm.ledIds = $$v
                            },
                            expression: "ledIds",
                          },
                        },
                        _vm._l(_vm.ledList, function (led, index) {
                          return _c(
                            "el-checkbox",
                            { key: led.id, attrs: { label: led.id } },
                            [_vm._v("屏幕" + _vm._s(index + 1))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.copyLedSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }