var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time" }, [
    _c("span", { staticClass: "time-wrapper" }, [
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.times.year))]
      ),
      _c(
        "span",
        {
          staticClass: "text",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v("/")]
      ),
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.addZero(_vm.times.month)))]
      ),
      _c(
        "span",
        {
          staticClass: "text",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v("/")]
      ),
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.addZero(_vm.times.day)))]
      ),
      _c(
        "span",
        {
          staticClass: "text",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v("/")]
      ),
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.addZero(_vm.times.hour)))]
      ),
      _c(
        "span",
        {
          staticClass: "text",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(":")]
      ),
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.addZero(_vm.times.minute)))]
      ),
      _c(
        "span",
        {
          staticClass: "text",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(":")]
      ),
      _c(
        "span",
        {
          staticClass: "num",
          style: { color: _vm.color, fontSize: _vm.fontSize },
        },
        [_vm._v(_vm._s(_vm.addZero(_vm.times.second)))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }