/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-06-17 09:33,
 */
import request from '@/utils/request';
export default {
  // 获取房源列表
  roomIndex: function roomIndex(data) {
    return request({
      url: '/identifySign/index',
      method: 'post',
      data: data
    });
  },
  export: function _export(data) {
    return request({
      url: '/identifySign/export',
      method: 'post',
      data: data
    });
  },
  getContract: function getContract(params) {
    return request({
      url: '/identifySign/getContract',
      method: 'get',
      params: params
    });
  },
  cancel: function cancel(params) {
    return request({
      url: '/identifySign/cancel',
      method: 'get',
      params: params
    });
  },
  del: function del(data) {
    return request({
      url: '/identifySign/del',
      method: 'POST',
      data: data
    });
  },
  //报名签约
  regsingList: function regsingList(data) {
    return request({
      url: '/regSign/index',
      method: 'POST',
      data: data
    });
  },
  //认筹签约
  idesingList: function idesingList(data) {
    return request({
      url: '/ideSign/index',
      method: 'POST',
      data: data
    });
  },
  //认筹签约
  raisingList: function raisingList(data) {
    return request({
      url: '/raiSign/index',
      method: 'POST',
      data: data
    });
  },
  // 作废
  regcancel: function regcancel(params) {
    return request({
      url: '/regSign/cancel',
      method: 'get',
      params: params
    });
  },
  idecancel: function idecancel(params) {
    return request({
      url: '/ideSign/cancel',
      method: 'get',
      params: params
    });
  },
  raicancel: function raicancel(params) {
    return request({
      url: '/raiSign/cancel',
      method: 'get',
      params: params
    });
  },
  // 	查看合同
  getRegContract: function getRegContract(params) {
    return request({
      url: '/regSign/getContract',
      method: 'get',
      params: params
    });
  },
  getIdeContract: function getIdeContract(params) {
    return request({
      url: '/ideSign/getContract',
      method: 'get',
      params: params
    });
  },
  getRaiContract: function getRaiContract(params) {
    return request({
      url: '/raiSign/getContract',
      method: 'get',
      params: params
    });
  },
  // 	删除
  regDel: function regDel(data) {
    return request({
      url: '/regSign/del',
      method: 'POST',
      data: data
    });
  },
  ideDel: function ideDel(data) {
    return request({
      url: '/ideSign/del',
      method: 'POST',
      data: data
    });
  },
  raiDel: function raiDel(data) {
    return request({
      url: '/raiSign/del',
      method: 'POST',
      data: data
    });
  },
  // 	导出
  regexport: function regexport(data) {
    return request({
      url: '/regSign/export',
      method: 'post',
      data: data
    });
  },
  ideexport: function ideexport(data) {
    return request({
      url: '/ideSign/export',
      method: 'post',
      data: data
    });
  },
  raiexport: function raiexport(data) {
    return request({
      url: '/raiSign/export',
      method: 'post',
      data: data
    });
  },
  // 	线下签约确认
  regSignOffline: function regSignOffline(data) {
    return request({
      url: '/regSign/sign',
      method: 'post',
      data: data
    });
  },
  ideSignOffline: function ideSignOffline(data) {
    return request({
      url: '/ideSign/sign',
      method: 'post',
      data: data
    });
  },
  raiSignOffline: function raiSignOffline(data) {
    return request({
      url: '/raiSign/sign',
      method: 'post',
      data: data
    });
  },
  // 	线上签约确定
  regSignOnline: function regSignOnline(data) {
    return request({
      url: '/regSign/SignConfirm',
      method: 'post',
      data: data
    });
  },
  ideSignOnline: function ideSignOnline(data) {
    return request({
      url: '/ideSign/SignConfirm',
      method: 'post',
      data: data
    });
  },
  raiSignOnline: function raiSignOnline(data) {
    return request({
      url: '/raiSign/SignConfirm',
      method: 'post',
      data: data
    });
  },
  // 	打印
  regSignprint: function regSignprint(data) {
    return request({
      url: '/regSign/print',
      method: 'post',
      data: data
    });
  },
  ideSignprint: function ideSignprint(data) {
    return request({
      url: '/ideSign/print',
      method: 'post',
      data: data
    });
  },
  raiSignprint: function raiSignprint(data) {
    return request({
      url: '/raiSign/print',
      method: 'post',
      data: data
    });
  }
};