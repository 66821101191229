var state = {
  // 大屏显示 房源数据
  houseRes: '',
  // 大屏显示 房源是否售罄
  isSaleOver: false,
  // 大屏显示 右侧选房记录
  allDatas: {
    cateList: [],
    floorMax: '',
    ledConfInfo: {},
    // led 配置
    ledInfo: {},
    // led 信息
    projectInfo: {},
    // 项目信息
    projectRoomInfo: {},
    // 项目房间信息
    roomList: [],
    // 房间列表
    roomLog: [],
    // 选房记录
    timeBar: []
  },
  // 大屏显示 监听页面刷新
  refresh: 0,
  // 大屏显示单元格高度
  cellHeight: 30,
  // 大屏显示单元格宽度
  cellWidth: 80,
  // 更新倒计时
  refreshCountDown: false,
  waitJson: {
    boldList: [] //添加默认值

  } // 销售大屏json配置参数

};
var mutations = {
  SETWAITJSON: function SETWAITJSON(state, payload) {
    state.waitJson = payload;
  },
  // 刷新房源状态
  REFLESH: function REFLESH(state, payload) {
    state.houseRes = payload;
  },
  // 改变房源是否售罄的状态
  CHANGESALESTATUS: function CHANGESALESTATUS(state, payload) {
    state.isSaleOver = payload;
  },
  // 更新大屏右侧选房记录
  CHANGEALLDATA: function CHANGEALLDATA(state, payload) {
    state.allDatas = payload;
  },
  // 监听页面数据刷新
  LISTENPAGEREFRESH: function LISTENPAGEREFRESH(state, payload) {
    state.reflesh = payload;
  },
  // 更新单元格高度
  CHANGECELLH: function CHANGECELLH(state, payload) {
    state.cellHeight = payload;
  },
  // 更新单元格宽度
  CHANGECELLW: function CHANGECELLW(state, payload) {
    state.cellWidth = payload;
  },
  // 更新倒计时
  REFLESHCOUNTDOWN: function REFLESHCOUNTDOWN(state, payload) {
    state.refreshCountDown = !state.refreshCountDown;
  }
};
var actions = {
  // 刷新房源状态
  reflesh: function reflesh(_ref, payload) {
    var commit = _ref.commit;
    commit('REFLESH', payload);
  },
  // 改变房源是否售罄的状态
  changeSaleStatus: function changeSaleStatus(_ref2, payload) {
    var commit = _ref2.commit;
    commit('CHANGESALESTATUS', payload);
  },
  // 更新大屏右侧选房记录
  changeAllData: function changeAllData(_ref3, payload) {
    var commit = _ref3.commit;
    commit('CHANGEALLDATA', payload);
  },
  // 监听页面数据刷新
  listenPageRefresh: function listenPageRefresh(_ref4, payload) {
    var commit = _ref4.commit;
    commit('LISTENPAGEREFRESH', payload);
  },
  // 更新单元格高度
  changeCellH: function changeCellH(_ref5, payload) {
    var commit = _ref5.commit;
    commit('CHANGECELLH', payload);
  },
  // 更新单元格宽度
  changeCellW: function changeCellW(_ref6, payload) {
    var commit = _ref6.commit;
    commit('CHANGECELLW', payload);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};