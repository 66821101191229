import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { auditDetails, userAdopt, userReject } from '@/api/clientSet';
import { adminParameter } from '@/api/public'; // 权限判断指令

export default {
  name: 'UserAuditInfo',
  data: function data() {
    return {
      // 是否 新增修改
      is_add: true,
      // 新的产权人
      new_owner: [],
      // 旧的修改产权人
      old_owner: [],
      // 新的客户详情
      new_user: {},
      // 旧的客户详情
      old_user: {},
      projectList: {},
      // 新的诚意金
      new_price: {},
      // 旧的诚意金
      old_price: {}
    };
  },
  // 初始化
  created: function created() {
    this.getInfoData();
    this.getAdminList();
  },
  methods: {
    getAdminList: function getAdminList() {
      var _this = this;

      // 获取公共权限
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var data = res.data,
              _res$data = res.data,
              event = _res$data.event,
              event_id = _res$data.event_id;
          _this.projectList = data.list;
        }
      });
    },
    // 通过审核
    handlePassAudit: function handlePassAudit() {
      var _this2 = this;

      userAdopt({
        ids: [Number(this.$route.query.id)]
      }).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 驳回审核
    handleTurnAudit: function handleTurnAudit() {
      var _this3 = this;

      userReject({
        ids: [Number(this.$route.query.id)]
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      });
    },
    // 获取详情
    getInfoData: function getInfoData() {
      var _this4 = this;

      auditDetails({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data$data = res.data.data,
              new_owner = _res$data$data.new_owner,
              new_user = _res$data$data.new_user,
              old_owner = _res$data$data.old_owner,
              old_user = _res$data$data.old_user,
              is_add = _res$data$data.is_add,
              new_price = _res$data$data.new_price,
              old_price = _res$data$data.old_price;
          _this4.is_add = is_add;
          _this4.new_owner = new_owner;
          _this4.old_owner = old_owner;
          _this4.new_user = new_user;
          _this4.old_user = old_user;
          _this4.new_price = new_price;
          _this4.old_price = old_price;
        }
      });
    }
  }
};