var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "userDatails" } },
    [
      _vm._m(0),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基本信息", name: "0" } }, [
            _c(
              "div",
              {
                staticClass: "tab-pane-container",
                style: { height: _vm.height + "px" },
              },
              [_c("info", { attrs: { data: _vm.data } })],
              1
            ),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { label: "填单审核", name: "1" } },
            [
              _vm.setting
                ? _c("linkSwicth", {
                    ref: "from",
                    attrs: { audit: _vm.setting.audit, list: _vm.setting.list },
                    on: { swicthForm: _vm.swicthForm },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "tab-pane-container",
                  style: { height: _vm.height + "px" },
                },
                [
                  _c("form-data", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formVal == 0,
                        expression: "formVal == 0",
                      },
                    ],
                    key: "reg",
                    ref: "reg",
                    attrs: { data: _vm.data, keyStr: "reg" },
                  }),
                  _c("form-data", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formVal == 1,
                        expression: "formVal == 1",
                      },
                    ],
                    key: "ide",
                    ref: "ide",
                    attrs: { data: _vm.data, keyStr: "ide" },
                  }),
                  _c("form-data", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formVal == 2,
                        expression: "formVal == 2",
                      },
                    ],
                    key: "rai",
                    ref: "rai",
                    attrs: { data: _vm.data, keyStr: "rai" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.setting.audit.is_register_price ||
          (_vm.setting.audit.is_identify_price &&
            _vm.setting.list.is_identify) ||
          (_vm.setting.audit.is_raising_funds_price &&
            _vm.setting.list.is_raising_funds)
            ? _c(
                "el-tab-pane",
                { attrs: { label: "交款", name: "2" } },
                [
                  _vm.setting
                    ? _c("linkSwicth", {
                        ref: "pay",
                        attrs: {
                          audit: _vm.setting.audit,
                          list: _vm.setting.list,
                          type: "pay",
                        },
                        on: { swicthForm: _vm.swicthForm },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane-container",
                      style: { height: _vm.height + "px" },
                    },
                    [
                      _c("price-view", {
                        attrs: {
                          pay: _vm.data[
                            _vm.payVal == 0
                              ? "reg_pay"
                              : _vm.payVal == 1
                              ? "ide_pay"
                              : "rai_pay"
                          ],
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.setting.audit.is_register_sign ||
          (_vm.setting.audit.is_identify_sign &&
            _vm.setting.list.is_identify) ||
          (_vm.setting.audit.is_raising_funds_sign &&
            _vm.setting.list.is_raising_funds)
            ? _c(
                "el-tab-pane",
                { attrs: { label: "签约", name: "4" } },
                [
                  _vm.setting
                    ? _c("linkSwicth", {
                        ref: "sign",
                        attrs: {
                          audit: _vm.setting.audit,
                          list: _vm.setting.list,
                          type: "sign",
                        },
                        on: { swicthForm: _vm.swicthForm },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane-container",
                      style: { height: _vm.height + "px" },
                    },
                    [
                      _c("sign-view", {
                        attrs: {
                          sign: _vm.data[
                            _vm.signVal == 0
                              ? "reg_sign"
                              : _vm.signVal == 1
                              ? "ide_sign"
                              : "rai_sign"
                          ],
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-tab-pane", { attrs: { label: "日志", name: "3" } }, [
            _c(
              "div",
              {
                staticClass: "tab-pane-container",
                style: { height: _vm.height + "px" },
              },
              [
                _c("logView", {
                  ref: "logList",
                  attrs: { log: _vm.logList, name: _vm.data.name },
                  on: { handleSearchClick: _vm.doGetInfo },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-fixed" }, [
      _c("div", { staticClass: "title-top-box align-items" }, [
        _c("div", { staticClass: "title flex align-items" }, [
          _c("div", [_vm._v("登记客户详情")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }