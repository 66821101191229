//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CustomSwitch',
  props: {
    // 绑定值
    svalue: {
      type: Boolean,
      default: true
    },
    // true label
    trueLabel: {
      type: String,
      default: '生效'
    },
    // false label
    falseLabel: {
      type: String,
      default: '不生效'
    },
    // left text
    ltext: {
      type: String,
      default: ''
    },
    rtext: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {// svalue: false
    };
  },
  methods: {
    switchChange: function switchChange() {
      this.$emit('change', this.svalue);
    }
  }
};