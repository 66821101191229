//
//
//
//
//
//
import echarts from 'echarts';
export default {
  name: 'HistogramChart',
  props: {
    // e-chart id
    id: {
      type: String,
      default: 'pie-chart'
    },
    // e-chart 数据
    YData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    XData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // e-chart 名称
    name: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  watch: {
    XData: function XData() {
      this._init_();
    }
  },
  created: function created() {
    this.$emit('setRenderState', false);
  },
  mounted: function mounted() {
    this._init_();
  },
  methods: {
    _init_: function _init_() {
      var _this = this;

      var chartDom = document.getElementById(this.id);
      var autoHeight = this.YData.length * 35 + 150;
      var myChart = echarts.init(chartDom);
      myChart.resize({
        height: autoHeight
      });
      var option;
      option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          axisTick: {
            // y轴刻度线
            show: false
          },
          splitLine: {
            // 网格线
            show: false
          },
          axisLine: {
            // y轴
            show: false
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.5)',
            fontWeight: 400,
            fontSize: 20,
            fontFamily: 'DIN'
          },
          data: this.YData
        },
        series: [{
          type: 'bar',
          barWidth: 30,
          showBackground: true,
          label: {
            show: true,
            position: 'right',
            color: '#000000',
            fontWeight: 400,
            fontSize: 20
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
              offset: 0,
              color: '#FFFFFF'
            }, // { offset: 0.5, color: '#188df0' },
            {
              offset: 1,
              color: '#F34429'
            }])
          },
          name: '',
          data: this.XData
        }]
      };
      option && myChart.setOption(option);
      this.$nextTick(function () {
        _this.$emit('setRenderState', true);
      });
    }
  }
};