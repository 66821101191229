var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flexClass" }, [
    _c(
      "div",
      {
        staticClass: "project-rounds",
        style: {
          "--font": _vm.pFont,
          "--color": _vm.pColor,
          "--pos": _vm.pPosition,
        },
      },
      [
        _vm.editFormatList.round_show === 0
          ? _c("span", [_vm._v(" " + _vm._s(_vm.innertext))])
          : _vm._e(),
        _c(
          "p",
          {
            staticClass: "number",
            style: {
              "--nFont": _vm.editFormatList.host_json.await_round_size,
              "--nColor": _vm.editFormatList.card_info_code_color,
              "--uFont": _vm.editFormatList.card_info_user_size,
              "--uColor": _vm.editFormatList.card_info_user_color,
              "--uGap": _vm.editFormatList.card_info_user_spacing,
            },
          },
          [
            _vm.laveNumber > 0 && _vm.isStayUser == 0
              ? _c("span", { staticClass: "dyhclass" }, [
                  _vm._v("待摇号:" + _vm._s(_vm.laveNumber) + "组"),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }