//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Api from '../../../api/deposit';
import { adminParameter } from '@/api/public';
export default {
  name: 'ElectronicOrder',
  data: function data() {
    return {
      path: '',
      info: {}
    };
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    // 获取详情数据
    getInfo: function getInfo() {
      var _this = this;

      Api.smallTicket({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              info = _res$data.info,
              path = _res$data.path;
          _this.info = info;
          _this.path = path;
        }
      });
    }
  }
};