import _toConsumableArray from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  model: {
    prop: 'ModelValue',
    event: 'changeValue'
  },
  props: {
    lable: {
      type: String,
      default: '操作人'
    },
    size: {
      type: String,
      default: 'small'
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    ModelValue: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      ren: ''
    };
  },
  watch: {
    ren: function ren(_ren) {
      this.$emit('changeValue', _ren);
    },
    ModelValue: function ModelValue(v) {
      if (v !== this.ren) this.ren = v;
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState('config', ['admins'])), {}, {
    option: function option() {
      var arr = _toConsumableArray(this.admins);

      var newData = [];

      for (var i = 0; i < arr.length; i++) {
        var item = arr[i];
        newData.push({
          label: item[this.labelKey],
          value: item[this.valueKey]
        });
      }

      return newData;
    }
  })
};