import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getEventData as _getEventData, getLogList as _getLogList, getPreference as _getPreference, userDetails } from '@/api/clientSet';
import { adminParameter } from '@/api/public';
import CustomFocus from './components/customFocus.vue';
import CustomActive from './components/customActive.vue';
import logView from './components/log-view.vue';
import utils from '@/utils/utils';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'UserDetails',
  components: {
    CustomFocus: CustomFocus,
    CustomActive: CustomActive,
    logView: logView
  },
  data: function data() {
    return {
      // 页面全部数据
      isPC: utils.isPC(),
      // 头像默认图片
      avatar_src: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      // 用户信息
      user: '',
      // 产权人信息
      user_audit_owner: [],
      autonymStatus: ['未认证', '认证中', '已认证'],
      // 项目配置
      project: '',
      // 活动场次批次
      event: {
        is_round: false,
        is_batch: false,
        is_event: false
      },
      // 活动列表
      eventList: [],
      // tab active
      activeName: 0,
      // 偏好
      preference: {},
      // 关注情况 表格
      tableData: [],
      // 测试活动下拉选项
      selectOptions: '',
      // 是否开启登录
      isSign: '',
      // 是否开启 签约排号摇号
      sign_up_rand: '',
      //  日志
      log: [],
      projectList: {},
      signUp: false,
      XAndQ: false,
      // 最新活动 id
      eventId: 0,
      // 是否开启 批次
      is_batch: '',
      price: {},
      // 重置 活动数据 组件
      isData: false,
      // 0不开启 1开启
      matching_order: 0,
      is_must_buy_room: 0 // 1 启用 0 不

    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    moment.locale('zh-cn');
    this.getAdminList();
  },
  methods: {
    getCardId: function getCardId(value) {
      if (value) {
        var cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
        var HKReg = /[A-Za-z]{1}\d{6}\([0-9A-Za-z]\)/;
        var TWReg = /[A-Z]{1}\d{9}/;
        var AMReg = /[1|5|7]\d{6}\([0-9A-Za-z]\)/;

        if (cardReg.test(value) || HKReg.test(value) || TWReg.test(value) || AMReg.test(value)) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return 1;
      }
    },
    // 获取置业顾问
    getAdminList: function getAdminList() {
      var _this = this;

      // 获取公共权限
      adminParameter().then(function (res) {
        if (res.status === 200) {
          var data = res.data,
              _res$data = res.data,
              event = _res$data.event,
              event_id = _res$data.event_id;
          console.log(data);
          _this.project = data; // 活动场次批次控制

          _this.event = event; // 是否开启登录

          _this.isSign = res.data.list.sign_in; // 是否开启 签约排号摇号

          _this.sign_up_rand = res.data.list.buy_rand; // 是否开启配比认购

          _this.matching_order = data.matching_order;
          _this.is_must_buy_room = data.is_must_buy_room;
          _this.is_batch = event.is_batch;
          _this.projectList = data.list; // 只要SAAS端开启了协助选房模式，或者开启了签约环节（无论在线还是线下）

          _this.signUp = data.list.buy_type === 2 || data.list.is_display.includes('sign_up'); // 线上 + 契约锁 或 线下
          // this.XAndQ = data.list.sign_up_type === 1 && data.list.ide_sign_status === 0 || data.list.sign_up_type === 2

          _this.XAndQ = data.list.user_owner_real == 1;

          _this.getInfo();

          _this.eventId = event_id;
        }
      });
    },
    // 获取详情数据
    getInfo: function getInfo() {
      var _this2 = this;

      userDetails({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var _res$data2 = res.data,
              list = _res$data2.list,
              log = _res$data2.log;
          _this2.eventList = list.event;
          _this2.user = list.user;
          _this2.price = list.price;
          _this2.user_audit_owner = list.user_audit_ower == null ? [] : list.user_audit_ower;
        }
      });
    },
    // 跳转修改页面
    handleEdit: function handleEdit() {
      this.$router.push({
        name: 'EditUser',
        query: {
          id: this.user.id,
          round: this.event.is_round
        }
      });
    },
    // 意向房源
    getPreference: function getPreference() {
      var _this3 = this;

      _getPreference({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          _this3.preference = res.data.preference;

          if (res.data.collect != null && res.data.collect.length > 0) {
            _this3.tableData = res.data.collect;
          } else {
            _this3.tableData = [];
          }
        }
      });
    },
    //  活动数据
    getEventData: function getEventData() {
      var _this4 = this;

      _getEventData({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          _this4.selectOptions = res.data.event_data.test_event;
        }
      });
    },
    // 日志列表
    getLogList: function getLogList() {
      var _this5 = this;

      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.id = this.$route.query.id;
      this.$nextTick(function () {
        _this5.$refs.logList.pageLoad(true);
      });

      _getLogList(data).then(function (res) {
        if (res.status === 200) {
          var _this5$log;

          var _res$data3 = res.data,
              list = _res$data3.list,
              count = _res$data3.count;
          var log = [];
          var dataInfo = {};
          list.forEach(function (item, index) {
            var times = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD'),
                time = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD HH:mm:ss');

            if (!dataInfo[times]) {
              dataInfo[times] = {
                times: times,
                day_name: moment(time).fromNow(),
                log_list: []
              };
            }

            dataInfo[times].log_list.push(Object.assign(item, {
              key: index
            }));
          });
          Object.values(dataInfo);
          Object.keys(dataInfo).forEach(function (item) {
            log.push(dataInfo[item]);
          });
          if (data.page > 1) (_this5$log = _this5.log).push.apply(_this5$log, log);else _this5.log = log;
          _this5.$refs['logList'].more = count >= 100;

          if (list.length === 100) {
            _this5.$nextTick(function () {
              _this5.$refs['logList'].isPageLoad = false;
            });
          }
        }
      }).finally(function () {
        _this5.$refs.logList.pageLoad(false);
      });
    },
    // tab切换
    tabClick: function tabClick(tab, event) {
      var _this6 = this;

      this.activeName = tab.name;

      switch (tab.name) {
        case '0':
          this.getInfo();
          break;

        case '1':
          this.getPreference();
          break;

        case '2':
          this.isData = false;
          this.$nextTick(function () {
            _this6.isData = true;
          }); // this.getEventData();

          break;

        case '3':
          this.getLogList();
          break;
      }
    },
    handleSearchClick: function handleSearchClick(data) {
      this.getLogList(data);
    }
  }
};