var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c("img", {
          staticClass: "assistHouse-entry-img",
          attrs: {
            src: require("@/assets/image/assistHouse-entry-img.png"),
            alt: "",
          },
        }),
        _vm.projectInfo.buy_type == 2
          ? [
              _c(
                "el-button",
                {
                  staticClass: "assistHouse-entry-btn",
                  attrs: { round: "", type: "primary" },
                  on: { click: _vm.startChoose },
                },
                [_vm._v("开始协助选房")]
              ),
            ]
          : [_c("span", [_vm._v("未启用协助选房~")])],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }