import request from '@/utils/request'; // 意向房源筛选列表

export function type(params) {
  return request({
    url: 'Room/type',
    method: 'GET',
    params: params
  });
} // 意向房源房间列表

export function list(params) {
  return request({
    url: 'Room/list',
    method: 'GET',
    params: params
  });
}