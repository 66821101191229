var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen-right" }, [
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.ledConfInfo.show_title == 1 ||
                _vm.ledConfInfo.show_title == true,
              expression:
                "ledConfInfo.show_title == 1 || ledConfInfo.show_title == true",
            },
          ],
          staticClass: "title",
        },
        [_vm._v("项目名称")]
      ),
      _vm.ledConfInfo.show_event_time == 1 ||
      _vm.ledConfInfo.show_event_time == true
        ? _c("div", { staticClass: "count-down" }, [
            _c("span", { staticClass: "count-down-text" }, [
              _vm._v("距离结束还有"),
            ]),
            _c(
              "div",
              { staticClass: "count-down-box" },
              [
                _c(_vm.countDown, {
                  tag: "component",
                  attrs: {
                    "begin-time": _vm.beginTime,
                    "end-time": _vm.endTime,
                  },
                  on: { countDownOver: _vm.countDownOver },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", [
      _vm.ledConfInfo.show_amount == 1 || _vm.ledConfInfo.show_amount == true
        ? _c("div", { staticClass: "sales-volume" }, [
            _c("div", { staticClass: "sales-title" }, [_vm._v("销售额")]),
            _vm._m(0),
          ])
        : _vm._e(),
      _vm.ledConfInfo.show_sale == 1 || _vm.ledConfInfo.show_online == 1
        ? _c("div", { staticClass: "online-person" }, [
            _vm.ledConfInfo.show_sale == 1
              ? _c("div", { staticClass: "online-person-left" }, [
                  _c("div", { staticClass: "online-top-text" }, [
                    _vm._v("可选房源"),
                  ]),
                  _c("div", { staticClass: "online-bottom-text" }, [
                    _vm._v("100套"),
                  ]),
                ])
              : _vm._e(),
            _vm.ledConfInfo.show_online == 1 && _vm.ledConfInfo.show_sale == 1
              ? _c("div", { staticClass: "online-person-center" }, [
                  _vm._v("/"),
                ])
              : _vm._e(),
            _vm.ledConfInfo.show_online == 1
              ? _c("div", { staticClass: "online-person-right" }, [
                  _c("div", { staticClass: "online-top-text" }, [
                    _vm._v("在线人数"),
                  ]),
                  _c("div", { staticClass: "online-bottom-text" }, [
                    _vm._v("100人"),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.ledConfInfo.show_order == 1
        ? _c("div", { staticClass: "choice-history" }, [
            _c("div", { staticClass: "choice-history-title" }, [
              _vm._v("选房记录"),
            ]),
            _c(
              "div",
              { staticClass: "choice-history-lists" },
              [
                _c(
                  "vue-seamless-scroll",
                  { attrs: { "class-option": _vm.classOption } },
                  _vm._l(10, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "choice-history-items" },
                      [
                        _c("div", { staticClass: "choice-history-header" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                              alt: "",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "choice-history-mes" }, [
                          _c("div", { staticClass: "choice-history-name" }, [
                            _vm._v("张三"),
                          ]),
                          _c("div", { staticClass: "choice-history-house" }, [
                            _vm._v("选房成功 A栋1单元"),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _vm.ledInfo.show_cate == 1
      ? _c("div", { staticClass: "house-type" }, [
          _c(
            "div",
            { staticClass: "house-type-wrapper" },
            [
              _c(
                "vue-seamless-scroll",
                { attrs: { "class-option": _vm.classOption, data: 10 } },
                _vm._l(10, function (item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "house-type-items" },
                    [
                      _c("div", { staticClass: "house-type-title" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "14px" } },
                          [_vm._v("张三")]
                        ),
                      ]),
                      _c("div", { staticClass: "house-type-img" }, [
                        _c("div", { staticClass: "img-wrapper" }, [
                          _c("img", {
                            staticClass: "house-img",
                            attrs: {
                              src: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                              alt: "",
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "house-type-num" }, [
                        _vm._v("10/20套"),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sales-price" }, [
      _c("span", { staticClass: "price-unit" }, [_vm._v("¥")]),
      _c("span", { staticClass: "price-num" }, [_vm._v("1000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }