var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drawing",
      attrs: { id: "drawing" },
      on: { mousemove: _vm.MonitorMouseMovement },
    },
    [
      _c(
        "div",
        { staticClass: "bg-img" },
        [
          _c("img", {
            ref: "imgRef",
            attrs: { src: _vm.imgSrc, alt: "" },
            on: { error: _vm.imgError },
          }),
          _vm.imgError ? _vm._t("default") : _vm._e(),
        ],
        2
      ),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            class: [item.state ? "sell" : "sell-out"],
            style:
              "\n        left: " +
              item.left +
              "px;top: " +
              item.top +
              "px;\n        width: " +
              item.width +
              "px;height: " +
              item.height +
              "px;\n        transform: rotate(" +
              (item.transform || 0) +
              "deg);\n        border-radius: " +
              (item.borderTopLeftRadius || 2) +
              "px " +
              (item.borderTopRightRadius || 2) +
              "px " +
              (item.borderBottomRightRadius || 2) +
              "px " +
              (item.borderBottomLeftRadius || 2) +
              "px; \n      ",
            on: {
              mouseup: function ($event) {
                return _vm.onmouseup($event, item, index)
              },
              mousedown: function ($event) {
                return _vm.onmousedown($event, item, index)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }