import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { entryIndex, entry, entryUser } from '@/api/lottery';
export default {
  name: 'index',
  data: function data() {
    return {
      rand_id: '',
      rand_count: '',
      num: '',
      eventName: '',
      projectName: '',
      user_list: [],
      newList: [],
      showType: 1
    };
  },
  created: function created() {
    this.rand_id = +this.$route.query.rand_id;
    this.rand_count = this.$route.query.rand_count;
    this.doGetEntryIndex();
  },
  mounted: function mounted() {
    this.$refs['input'].focus();
  },
  methods: {
    doGetEntryIndex: function doGetEntryIndex() {
      var _this = this;

      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      entryIndex({
        rand_id: this.rand_id
      }).then(function (res) {
        _this.eventName = res.data.list.event.name;
        _this.projectName = res.data.list.project.project_name;
        var arrayTwo = [];
        var newarr = [];

        if (res.data.list.user_list && res.data.list.user_list.length) {
          var arr = res.data.list.user_list;
          arrayTwo = Object.values(arr.reduce(function (datas, item) {
            datas[item.level] ? datas[item.level].push(item) : datas[item.level] = [item];
            return datas;
          }, {}));

          for (var i = 0; i < arrayTwo.length; i++) {
            var level = arrayTwo[i][0].level;
            var obj = {
              level: level
            };
            var num = Math.ceil(arrayTwo[i].length / _this.rand_count);

            for (var j = 0; j < num; j++) {
              newarr.push(obj);
            }
          } // this.user_list=  res.data.list.user_list


          _this.user_list = newarr;
        } else {
          _this.user_list = [];
        }
      }).finally(function (res) {
        _this.loading.close();
      });
    },
    // 预览
    onEntryUser: function onEntryUser() {
      var _this2 = this;

      this.newList = [];
      if (!this.num) return;
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      entryUser({
        rand_id: this.rand_id,
        num: this.num + ''
      }).then(function (res) {
        _this2.loading.close();

        if (!res.data.list.user || !res.data.list.user.length) {
          _this2.$message({
            type: 'error',
            message: '不存在用户！'
          });

          return;
        }

        var list = res.data.list.user || [],
            newList = [];
        newList[0] = list.slice(0, Math.ceil(list.length / 2)) || [];
        newList[1] = list.slice(newList[0].length, list.length) || [];

        if (newList[0].length !== newList[1].length) {
          newList[1].push(null);
        }

        _this2.newList = newList;
      }).catch(function (res) {
        _this2.loading.close();
      });
    },
    // 确认生成
    onEntry: function onEntry() {
      var _this3 = this;

      if (!this.num) {
        this.$message({
          type: 'error',
          message: '请输入号码！'
        });
        return;
      }

      this.loading = this.$loading({
        lock: true,
        text: '生成中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      entryUser({
        rand_id: this.rand_id,
        num: this.num + ''
      }).then(function (res) {
        if (_this3.newList.length) {
          entry({
            rand_id: _this3.rand_id,
            num: _this3.num + ''
          }).then(function (res) {
            var list = res.data.list.newList || [],
                newList = [];
            newList[0] = list.slice(0, Math.ceil(list.length / 2)) || [];
            newList[1] = list.slice(newList[0].length, list.length) || [];

            if (newList[0].length !== newList[1].length) {
              newList[1].push(null);
            }

            _this3.newList = newList;

            _this3.doGetEntryIndex();
          }).finally(function (res) {
            _this3.num = '';

            _this3.loading.close();
          });
        } else {
          _this3.$message({
            type: 'error',
            message: '不存在用户！'
          });

          _this3.num = '';

          _this3.loading.close();
        }
      }).catch(function (res) {
        _this3.num = '';

        _this3.loading.close();
      });
    },
    nickName: function nickName(name) {
      if (name != null) {
        if (name.length < 3) {
          return name[0] + '*';
        }

        if (name.length > 2) {
          return name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
        }
      }
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      if (card != null && card.length > 15) {
        return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
      } else {
        return card;
      }
    }
  }
};