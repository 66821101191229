import request from '@/utils/request';
export function pingPong(params) {
  return request({
    url: 'pingPong/index',
    method: 'post',
    params: params
  });
} // 查找客户

export function pingPongSearch(params) {
  return request({
    url: 'pingPong/Search',
    method: 'post',
    params: params
  });
} // 检查是号码否重复

export function getRepeat(data) {
  return request({
    url: 'pingPong/getRepeat',
    method: 'post',
    data: data
  });
} // 生成摇号结果

export function generate(data) {
  return request({
    url: 'pingPong/generate',
    method: 'post',
    data: data
  });
} // 设置规则

export function setLottery(data) {
  return request({
    url: 'pingPong/setLottery',
    method: 'post',
    data: data
  });
} // 重置摇号

export function pingPongreset(params) {
  return request({
    url: 'pingPong/reset',
    method: 'get',
    params: params
  });
} // 公示屏列表

export function pingPongPublicity(data) {
  return request({
    url: 'pingPong/publicity',
    method: 'post',
    data: data
  });
} // 摇号记录

export function pingPongList(params) {
  return request({
    url: 'pingPong/list',
    method: 'post',
    params: params
  });
} // 导出

export function pingPongExport(params) {
  return request({
    url: 'pingPong/export',
    method: 'post',
    params: params
  });
}
export function pingEdit(data) {
  return request({
    url: 'pingPong/edit',
    method: 'post',
    data: data
  });
}