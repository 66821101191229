import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '/src/utils/utils';
export default {
  name: 'LoginTable',
  props: {
    tableHeight: {
      type: Number,
      default: 0
    },
    tableData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPC: utils.isPC()
    };
  }
};