var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "link-contetn" }, [
    _c(
      "div",
      { staticClass: "links" },
      [
        _c("div", { staticClass: "linktitle" }, [
          _vm._v(_vm._s(_vm.audit.register_name) + "环节设置"),
        ]),
        _c(
          "el-form",
          {
            staticClass: "form-card",
            attrs: {
              "label-position": _vm.isM ? "left" : "right",
              "label-width": "160px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "" } },
              [
                _c("template", { slot: "label" }, [
                  _c(
                    "div",
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.audit.register_form_name) +
                            "起止时间:"
                        ),
                      ]),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content:
                              "活动开始前客户不允许登录，活动结束后不允许再提交登记表。",
                          },
                        },
                        [_c("i", { staticClass: "tips el-icon-question" })]
                      ),
                    ],
                    1
                  ),
                ]),
                !_vm.type
                  ? _c("div", { staticClass: "left-content" }, [
                      _c(
                        "div",
                        { staticClass: "flex-row" },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              format: "yyyy-MM-dd HH:mm:ss",
                              "value-format": "timestamp",
                              size: "small",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleEndTime(
                                  "start_time",
                                  "end_time"
                                )
                              },
                            },
                            model: {
                              value: _vm.auditEventInfo.start_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.auditEventInfo, "start_time", $$v)
                              },
                              expression: "auditEventInfo.start_time",
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                "font-weight": "normal",
                              },
                            },
                            [_vm._v(" 至 ")]
                          ),
                          _c("el-date-picker", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              format: "yyyy-MM-dd HH:mm:ss",
                              "value-format": "timestamp",
                              size: "small",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleEndTime(
                                  "start_time",
                                  "end_time"
                                )
                              },
                            },
                            model: {
                              value: _vm.auditEventInfo.end_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.auditEventInfo, "end_time", $$v)
                              },
                              expression: "auditEventInfo.end_time",
                            },
                          }),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.auditEventInfo.reg_time_show,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "reg_time_show",
                                    $$v
                                  )
                                },
                                expression: "auditEventInfo.reg_time_show",
                              },
                            },
                            [_vm._v(" 在客户端隐藏时间")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.auditEventInfo.zs_reg_time_show,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "zs_reg_time_show",
                                    $$v
                                  )
                                },
                                expression: "auditEventInfo.zs_reg_time_show",
                              },
                            },
                            [_vm._v(" 在助手端隐藏时间")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "left-content" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateFormat")(_vm.auditEventInfo.start_time)
                          ) +
                          " 至 " +
                          _vm._s(
                            _vm._f("dateFormat")(_vm.auditEventInfo.end_time)
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "mL20" }, [
                        _vm._v(
                          _vm._s(
                            _vm.auditEventInfo.reg_time_show
                              ? "(在客户端隐藏时间)"
                              : ""
                          ) + " "
                        ),
                      ]),
                      _c("span", { staticClass: "mL20" }, [
                        _vm._v(
                          _vm._s(
                            _vm.auditEventInfo.zs_reg_time_show
                              ? "(在助手端隐藏时间)"
                              : ""
                          ) + " "
                        ),
                      ]),
                    ]),
              ],
              2
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.audit.register_form_name + "复提截止时间:",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "left-content" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.audit.register_form_name) + "截止后 "
                    ),
                    _c("el-input", {
                      staticStyle: { width: "60px" },
                      attrs: {
                        disabled: !!_vm.type,
                        type: "number",
                        placeholder: "请输入小时",
                      },
                      model: {
                        value: _vm.auditEventInfo.reg_repeat_hour,
                        callback: function ($$v) {
                          _vm.$set(_vm.auditEventInfo, "reg_repeat_hour", $$v)
                        },
                        expression: "auditEventInfo.reg_repeat_hour",
                      },
                    }),
                    _vm._v("小时 "),
                    _c("el-input", {
                      staticStyle: { width: "60px" },
                      attrs: {
                        disabled: !!_vm.type,
                        type: "number",
                        placeholder: "请输入小时",
                      },
                      model: {
                        value: _vm.auditEventInfo.reg_repeat_min,
                        callback: function ($$v) {
                          _vm.$set(_vm.auditEventInfo, "reg_repeat_min", $$v)
                        },
                        expression: "auditEventInfo.reg_repeat_min",
                      },
                    }),
                    _vm._v("分钟 "),
                  ],
                  1
                ),
              ]
            ),
            _vm.audit.is_register_price
              ? _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.audit.register_price_name) +
                              "起止时间:"
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.type
                      ? _c("div", { staticClass: "left-content" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "timestamp",
                                  size: "small",
                                  type: "datetime",
                                  placeholder: "选择日期",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleEndTime(
                                      "reg_price_start_time",
                                      "reg_price_end_time"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.auditEventInfo.reg_price_start_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "reg_price_start_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "auditEventInfo.reg_price_start_time",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    "font-weight": "normal",
                                  },
                                },
                                [_vm._v(" 至 ")]
                              ),
                              _c("el-date-picker", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "timestamp",
                                  size: "small",
                                  type: "datetime",
                                  placeholder: "选择日期",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleEndTime(
                                      "reg_price_start_time",
                                      "reg_price_end_time"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.auditEventInfo.reg_price_end_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "reg_price_end_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "auditEventInfo.reg_price_end_time",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value:
                                      _vm.auditEventInfo.reg_price_time_show,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "reg_price_time_show",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "auditEventInfo.reg_price_time_show",
                                  },
                                },
                                [_vm._v(" 在客户端隐藏时间")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value:
                                      _vm.auditEventInfo.zs_reg_price_time_show,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "zs_reg_price_time_show",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "auditEventInfo.zs_reg_price_time_show",
                                  },
                                },
                                [_vm._v(" 在助手端隐藏时间")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "left-content" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.auditEventInfo.reg_price_start_time
                                )
                              ) +
                              " 至 " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.auditEventInfo.reg_price_end_time
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mL20" }, [
                            _vm._v(
                              _vm._s(
                                _vm.auditEventInfo.reg_price_time_show
                                  ? "(在客户端隐藏时间)"
                                  : ""
                              ) + " "
                            ),
                          ]),
                          _c("span", { staticClass: "mL20" }, [
                            _vm._v(
                              _vm._s(
                                _vm.auditEventInfo.zs_reg_price_time_show
                                  ? "(在助手端隐藏时间)"
                                  : ""
                              ) + " "
                            ),
                          ]),
                        ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm.audit.is_register_price
              ? _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.audit.register_price_name + "金额：" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "left-content",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "money_time" },
                          [
                            _vm._v(" 住宅："),
                            _c("el-input", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.reg_room_price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "reg_room_price",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.reg_room_price",
                              },
                            }),
                            _vm._v("元 "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "money_time" },
                          [
                            _vm._v(" 车位："),
                            _c("el-input", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.reg_car_price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "reg_car_price",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.reg_car_price",
                              },
                            }),
                            _vm._v("元 "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "money_time" },
                          [
                            _vm._v(" 商业："),
                            _c("el-input", {
                              staticStyle: { width: "160px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.reg_bus_price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "reg_bus_price",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.reg_bus_price",
                              },
                            }),
                            _vm._v("元 "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.audit.is_register_sign
              ? _c(
                  "el-form-item",
                  {
                    attrs: { label: _vm.audit.register_sign_name + "起止时间" },
                  },
                  [
                    !_vm.type
                      ? _c("div", { staticClass: "left-content" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "timestamp",
                                  size: "small",
                                  type: "datetime",
                                  placeholder: "选择日期",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleEndTime(
                                      "reg_sign_start_time",
                                      "reg_sign_end_time"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.auditEventInfo.reg_sign_start_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "reg_sign_start_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "auditEventInfo.reg_sign_start_time",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    "font-weight": "normal",
                                  },
                                },
                                [_vm._v(" 至 ")]
                              ),
                              _c("el-date-picker", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "timestamp",
                                  size: "small",
                                  type: "datetime",
                                  placeholder: "选择日期",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleEndTime(
                                      "reg_sign_start_time",
                                      "reg_sign_end_time"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.auditEventInfo.reg_sign_end_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "reg_sign_end_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "auditEventInfo.reg_sign_end_time",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value:
                                      _vm.auditEventInfo.reg_sign_time_show,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "reg_sign_time_show",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "auditEventInfo.reg_sign_time_show",
                                  },
                                },
                                [_vm._v(" 在客户端隐藏时间")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value:
                                      _vm.auditEventInfo.zs_reg_sign_time_show,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "zs_reg_sign_time_show",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "auditEventInfo.zs_reg_sign_time_show",
                                  },
                                },
                                [_vm._v(" 在助手端隐藏时间")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "left-content" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.auditEventInfo.reg_sign_start_time
                                )
                              ) +
                              " 至 " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.auditEventInfo.reg_sign_end_time
                                )
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "mL20" }, [
                            _vm._v(
                              _vm._s(
                                _vm.auditEventInfo.reg_sign_time_show
                                  ? "(在客户端隐藏时间)"
                                  : ""
                              ) + " "
                            ),
                          ]),
                          _c("span", { staticClass: "mL20" }, [
                            _vm._v(
                              _vm._s(
                                _vm.auditEventInfo.zs_reg_sign_time_show
                                  ? "(在助手端隐藏时间)"
                                  : ""
                              ) + " "
                            ),
                          ]),
                        ]),
                  ]
                )
              : _vm._e(),
            _vm.audit.is_register_sign
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.audit.register_sign_name + "时长限制:",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left-content" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 0 },
                            model: {
                              value: _vm.auditEventInfo.reg_is_sign_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.auditEventInfo,
                                  "reg_is_sign_time",
                                  $$v
                                )
                              },
                              expression: "auditEventInfo.reg_is_sign_time",
                            },
                          },
                          [_vm._v("不限制")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.auditEventInfo.reg_is_sign_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.auditEventInfo,
                                  "reg_is_sign_time",
                                  $$v
                                )
                              },
                              expression: "auditEventInfo.reg_is_sign_time",
                            },
                          },
                          [_vm._v("限制")]
                        ),
                        _vm.auditEventInfo.reg_is_sign_time === 1
                          ? [
                              _c("el-input", {
                                staticStyle: {
                                  width: "100px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  disabled: !!_vm.type,
                                  type: "number",
                                  placeholder: "请输入分钟数",
                                },
                                model: {
                                  value: _vm.auditEventInfo.reg_sign_time_min,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "reg_sign_time_min",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "auditEventInfo.reg_sign_time_min",
                                },
                              }),
                              _vm._v("分钟 "),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm.rule.is_identify
      ? _c(
          "div",
          { staticClass: "links" },
          [
            _c("div", { staticClass: "linktitle" }, [
              _vm._v(_vm._s(_vm.audit.identify_name) + "环节设置"),
            ]),
            _c(
              "el-form",
              {
                staticClass: "form-card",
                attrs: {
                  "label-position": _vm.isM ? "left" : "right",
                  "label-width": "160px",
                },
              },
              [
                _vm.audit.is_identify_form
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c(
                            "div",
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.audit.identify_form_name) +
                                    "起止时间:"
                                ),
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content:
                                      "活动开始前客户不允许登录，活动结束后不允许再提交登记表。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "tips el-icon-question",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_start_time",
                                          "ide_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.auditEventInfo.ide_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_start_time",
                                          "ide_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.auditEventInfo.ide_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_end_time",
                                          $$v
                                        )
                                      },
                                      expression: "auditEventInfo.ide_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value: _vm.auditEventInfo.ide_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "ide_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.ide_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo.zs_ide_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_ide_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_ide_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_start_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.ide_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_ide_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.audit.is_identify_form
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.identify_form_name + "复提截止时间:",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left-content" },
                          [
                            _vm._v(" 填单截止后 "),
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.ide_repeat_hour,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "ide_repeat_hour",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.ide_repeat_hour",
                              },
                            }),
                            _vm._v("小时 "),
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.ide_repeat_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "ide_repeat_min",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.ide_repeat_min",
                              },
                            }),
                            _vm._v("分钟 "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_identify_price
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.audit.identify_price_name || "交款"
                                  ) +
                                  "起止时间:"
                              ),
                            ]),
                          ]),
                        ]),
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_price_start_time",
                                          "ide_price_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.ide_price_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_price_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_price_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_price_start_time",
                                          "ide_price_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.ide_price_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_price_end_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_price_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .ide_price_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "ide_price_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.ide_price_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .zs_ide_price_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_ide_price_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_ide_price_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_price_start_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_price_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.ide_price_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_ide_price_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.audit.is_identify_price
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.identify_price_name + "金额：",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left-content",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 住宅："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.ide_room_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "ide_room_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.ide_room_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 车位："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.ide_car_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "ide_car_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.ide_car_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 商业："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.ide_bus_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "ide_bus_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.ide_bus_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_identify_sign
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.identify_sign_name + "起止时间",
                        },
                      },
                      [
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_sign_start_time",
                                          "ide_sign_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.ide_sign_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_sign_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_sign_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "ide_sign_end_time",
                                          "ide_sign_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.ide_sign_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_sign_end_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_sign_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo.ide_sign_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "ide_sign_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.ide_sign_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .zs_ide_sign_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_ide_sign_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_ide_sign_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_sign_end_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.ide_sign_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.ide_sign_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_ide_sign_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_identify_sign
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.identify_sign_name + "时长限制:",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left-content" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.auditEventInfo.ide_is_sign_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "ide_is_sign_time",
                                      $$v
                                    )
                                  },
                                  expression: "auditEventInfo.ide_is_sign_time",
                                },
                              },
                              [_vm._v("不限制")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.auditEventInfo.ide_is_sign_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "ide_is_sign_time",
                                      $$v
                                    )
                                  },
                                  expression: "auditEventInfo.ide_is_sign_time",
                                },
                              },
                              [_vm._v("限制")]
                            ),
                            _vm.auditEventInfo.ide_is_sign_time === 1
                              ? [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "100px",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      disabled: !!_vm.type,
                                      type: "number",
                                      placeholder: "请输入分钟数",
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.ide_sign_time_min,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "ide_sign_time_min",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.ide_sign_time_min",
                                    },
                                  }),
                                  _vm._v("分钟 "),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.rule.is_raising_funds
      ? _c(
          "div",
          { staticClass: "links" },
          [
            _c("div", { staticClass: "linktitle" }, [
              _vm._v(_vm._s(_vm.audit.raising_funds_name) + "环节设置"),
            ]),
            _c(
              "el-form",
              {
                staticClass: "form-card",
                attrs: {
                  "label-position": _vm.isM ? "left" : "right",
                  "label-width": "160px",
                },
              },
              [
                _vm.audit.is_raising_funds_form
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c(
                            "div",
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.audit.raising_funds_form_name) +
                                    "起止时间:"
                                ),
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content:
                                      "活动开始前客户不允许登录，活动结束后不允许再提交登记表。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "tips el-icon-question",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_start_time",
                                          "rai_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.auditEventInfo.rai_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_start_time",
                                          "rai_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.auditEventInfo.rai_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_end_time",
                                          $$v
                                        )
                                      },
                                      expression: "auditEventInfo.rai_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value: _vm.auditEventInfo.rai_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "rai_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.rai_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo.zs_rai_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_rai_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_rai_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_start_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.rai_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_rai_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.audit.is_raising_funds_form
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.audit.raising_funds_form_name + "复提截止时间:",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left-content" },
                          [
                            _vm._v(" 填单截止后 "),
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.rai_repeat_hour,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "rai_repeat_hour",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.rai_repeat_hour",
                              },
                            }),
                            _vm._v("小时 "),
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              attrs: {
                                disabled: !!_vm.type,
                                type: "number",
                                placeholder: "请输入小时",
                              },
                              model: {
                                value: _vm.auditEventInfo.rai_repeat_min,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "rai_repeat_min",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "auditEventInfo.rai_repeat_min",
                              },
                            }),
                            _vm._v("分钟 "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_raising_funds_price
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("template", { slot: "label" }, [
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.audit.raising_funds_price_name) +
                                  "起止时间:"
                              ),
                            ]),
                          ]),
                        ]),
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_price_start_time",
                                          "rai_price_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.rai_price_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_price_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_price_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_price_start_time",
                                          "rai_price_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.rai_price_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_price_end_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_price_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .rai_price_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "rai_price_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.rai_price_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .zs_rai_price_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_rai_price_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_rai_price_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_price_start_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_price_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.rai_price_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_rai_price_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.audit.is_raising_funds_price
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.raising_funds_price_name + "金额：",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left-content",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 住宅："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.rai_room_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "rai_room_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.rai_room_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 车位："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.rai_car_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "rai_car_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.rai_car_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "money_time" },
                              [
                                _vm._v(" 商业："),
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    disabled: !!_vm.type,
                                    type: "number",
                                    placeholder: "请输入小时",
                                  },
                                  model: {
                                    value: _vm.auditEventInfo.rai_bus_price,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auditEventInfo,
                                        "rai_bus_price",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "auditEventInfo.rai_bus_price",
                                  },
                                }),
                                _vm._v("元 "),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_raising_funds_sign
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.audit.raising_funds_sign_name + "起止时间",
                        },
                      },
                      [
                        !_vm.type
                          ? _c("div", { staticClass: "left-content" }, [
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_sign_start_time",
                                          "rai_sign_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.rai_sign_start_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_sign_start_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_sign_start_time",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        "font-weight": "normal",
                                      },
                                    },
                                    [_vm._v(" 至 ")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "timestamp",
                                      size: "small",
                                      type: "datetime",
                                      placeholder: "选择日期",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleEndTime(
                                          "rai_sign_start_time",
                                          "rai_sign_end_time"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.rai_sign_end_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_sign_end_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_sign_end_time",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo.rai_sign_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "rai_sign_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.rai_sign_time_show",
                                      },
                                    },
                                    [_vm._v(" 在客户端隐藏时间")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 0,
                                      },
                                      model: {
                                        value:
                                          _vm.auditEventInfo
                                            .zs_rai_sign_time_show,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.auditEventInfo,
                                            "zs_rai_sign_time_show",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "auditEventInfo.zs_rai_sign_time_show",
                                      },
                                    },
                                    [_vm._v(" 在助手端隐藏时间")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "left-content" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_sign_start_time
                                    )
                                  ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.auditEventInfo.rai_sign_end_time
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.rai_sign_time_show
                                      ? "(在客户端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                              _c("span", { staticClass: "mL20" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.auditEventInfo.zs_rai_sign_time_show
                                      ? "(在助手端隐藏时间)"
                                      : ""
                                  ) + " "
                                ),
                              ]),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _vm.audit.is_raising_funds_sign
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.audit.raising_funds_sign_name + "时长限制:",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left-content" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.auditEventInfo.rai_is_sign_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "rai_is_sign_time",
                                      $$v
                                    )
                                  },
                                  expression: "auditEventInfo.rai_is_sign_time",
                                },
                              },
                              [_vm._v("不限制")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.auditEventInfo.rai_is_sign_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.auditEventInfo,
                                      "rai_is_sign_time",
                                      $$v
                                    )
                                  },
                                  expression: "auditEventInfo.rai_is_sign_time",
                                },
                              },
                              [_vm._v("限制")]
                            ),
                            _vm.auditEventInfo.rai_is_sign_time === 1
                              ? [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "100px",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      disabled: !!_vm.type,
                                      type: "number",
                                      placeholder: "请输入分钟数",
                                    },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.rai_sign_time_min,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "rai_sign_time_min",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.rai_sign_time_min",
                                    },
                                  }),
                                  _vm._v("分钟 "),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }