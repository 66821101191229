export default {
  data: function data() {
    return {
      // 房源状态
      roomStatus: [{
        key: 'order_status',
        title: '认购状态',
        icon: require('@/assets/image/order-status-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'pay_status',
        title: '定金',
        icon: require('@/assets/image/deposit-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'printing_status',
        title: '打印认购书',
        icon: require('@/assets/image/print-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'contract_status',
        title: '签约',
        icon: require('@/assets/image/sign-no-icon.svg'),
        isDone: false,
        enable: true
      }, {
        key: 'archive_status',
        title: '资料存档',
        icon: require('@/assets/image/archive-icon.svg'),
        isDone: false,
        enable: true
      }]
    };
  }
};