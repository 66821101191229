import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: 'SimpleSetting',
  computed: _objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  }))
};