var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "诚意登记表单复制",
            visible: _vm.value,
            width: "500",
            "before-close": _vm.closeShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.value = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "从项目", prop: "old_id" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.old_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "old_id", $$v)
                        },
                        expression: "form.old_id",
                      },
                    },
                    _vm._l(_vm.ppp.list, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.project_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "复制到" } },
                [
                  _c("el-select", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ppp.old.project_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ppp.old, "project_name", $$v)
                      },
                      expression: "ppp.old.project_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "复制表单类型", prop: "types" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.reg_copy,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reg_copy", $$v)
                        },
                        expression: "form.reg_copy",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.audit.register_name) + "表单")]
                  ),
                  _vm.rule.is_identify
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.ide_copy,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ide_copy", $$v)
                            },
                            expression: "form.ide_copy",
                          },
                        },
                        [_vm._v(_vm._s(_vm.audit.identify_name) + "表单")]
                      )
                    : _vm._e(),
                  _vm.rule.is_raising_funds
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.rai_copy,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "rai_copy", $$v)
                            },
                            expression: "form.rai_copy",
                          },
                        },
                        [_vm._v(_vm._s(_vm.audit.raising_funds_name) + "表单")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeShow } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitCopy } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }