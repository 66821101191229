import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GMainUpload from '@/components/my-upload';
import IntentionRoom from '@/components/IntentionRoom/index.vue';
import HouseType from '@/components/HouseType/index.vue';
export default {
  name: 'InputBox',
  components: {
    GMainUpload: GMainUpload,
    IntentionRoom: IntentionRoom,
    HouseType: HouseType
  },
  props: {
    statusInfo: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1
    },
    userCreateInfoId: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      activeName: '0',
      form: {},
      radio: 3,
      checkList: ['选中且禁用', '复选框 A'],
      listTypes: 'picture',
      buildList: []
    };
  },
  methods: {
    getType: function getType(item) {
      if (this.type === 1) {
        return item.typeUserNumber > 0;
      } else {
        return item.typeNumber > 0;
      }
    },
    // 反回产权人上传的图片
    handleProperty: function handleProperty(url, index) {
      this.$emit('handleProperty', url, index);
    },
    // 反回银行资料上传的图片
    handlePropertyBank: function handlePropertyBank(url, index) {
      this.$emit('handlePropertyBank', url, index);
    },
    // 反回其他上传的图片
    handleOtherImg: function handleOtherImg(url, index) {
      this.$emit('handleOtherImg', url, index);
    },
    handleBuildOperation: function handleBuildOperation(type) {},
    // 添加产权人
    addPropertyOwner: function addPropertyOwner(index) {
      this.$emit('addPropertyOwner', index);
    },
    // 添加银行资料
    addPropertyBank: function addPropertyBank(index) {
      this.$emit('addPropertyBank', index);
    },
    // 删除产权人
    handleDelete: function handleDelete(index, inx, key) {
      this.$emit('handleDelete', index, inx, key);
    },
    // 单选选中事件
    handleRadio: function handleRadio(fromItem) {
      // this.$emit('handleRadio', fromItem.down_index,fromItem.owner_type,fromItem.normal_result.value2)
      this.$emit('doShowParent', true, fromItem.id, [fromItem.normal_result.value2 + ''], fromItem); // console.log(index,i,name,val);
    },
    onRoomList: function onRoomList(limit) {
      this.$emit('limit', limit);
    },
    handlePopup: function handlePopup(fromItem, data) {
      this.$emit('handlePopup', fromItem.id, data);
    },
    handleHouse: function handleHouse(fromItem, data) {
      this.$emit('handleHouse', fromItem.id, data);
    },
    onShowParentChange: function onShowParentChange(fromItem) {
      var value = [];

      for (var i = 0; i < fromItem.normal_result.value2.length; i++) {
        value.push(fromItem.normal_result.value2[i] + '');
      }

      this.$emit('doShowParent', true, fromItem.id, value);
    }
  }
};