var _this = this;

import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
export default {
  name: 'youhuiKneelingDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sp: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    you_hui_rule: {
      type: Array,
      default: function _default() {
        return [{
          id: 0,
          prie_type: _this.data.order_price,
          //价格类型
          type_name: '',
          // 方式名称
          way: 0,
          //优惠方式,0减额,1折扣
          derate: null,
          //减额
          discount: null,
          //折扣小数
          sort: 1
        }];
      }
    },
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      yhui_id: 0,
      order_price: 0,
      //价格类型
      // you_hui_rule:[
      //   {
      //     id:0,
      //     prie_type:this.data.order_price, //价格类型
      //     type_name:'',// 方式名称
      //     way:0,//优惠方式,0减额,1折扣
      //     derate:null,//减额
      //     discount:null,//折扣小数
      //     sort:1
      //   },
      // ],
      isPC: utils.isPC()
    };
  },
  methods: {
    save: function save() {
      var _this2 = this;

      var flag = true;
      var msg = '';
      console.log(this.order_price); // 表单验证

      try {
        if (!this.order_price) {
          msg = "\u8BF7\u9009\u62E9\u4F18\u60E0\u57FA\u7840";
          throw false;
        }

        this.you_hui_rule.map(function (item, index) {
          item.sort = index + 1; //排序赋值

          item.price_type = _this2.order_price;

          if (item.type_name == '') {
            msg = "\u8BF7\u8F93\u5165\u7C7B\u578B".concat(index + 1, "\u540D\u79F0");
            throw false;
          } else if (item.way == '') {
            if (item.derate === '') {
              msg = "\u8BF7\u8F93\u5165\u7C7B\u578B".concat(index + 1, "\u51CF\u989D\u91D1\u989D");
              throw false;
            }

            if (item.derate.toString().indexOf('.') != -1) {
              msg = "\u7C7B\u578B".concat(index + 1, "\u51CF\u989D\u8BF7\u8F93\u5165\u6B63\u6574\u6570");
              throw false;
            }
          } else if (item.way == 1) {
            if (item.discount === '') {
              msg = "\u8BF7\u8F93\u5165\u7C7B\u578B".concat(index + 1, "\u6298\u6263");
              throw false;
            }
          }
        });
      } catch (err) {
        flag = err;
      }

      if (flag) {
        this.$emit('save', this.order_price);
        this.close();
      } else {
        this.$message({
          type: 'error',
          message: msg
        });
      }
    },
    addType: function addType() {
      var len = this.you_hui_rule.length;
      var ids = [1, 2, 3, 4, 5, 6];

      if (len < 6) {
        this.you_hui_rule.map(function (items) {
          if (ids.includes(items.id)) {
            var deltIndex = ids.findIndex(function (datas) {
              return items.id == datas;
            });
            ids.splice(deltIndex, 1);
          }
        }); // return

        var obj = {
          id: ids[0],
          type_name: '',
          // 方式名称
          prie_type: this.order_price,
          //价格类型
          way: 0,
          //优惠方式,0减额,1折扣
          derate: null,
          //减额
          discount: null,
          //折扣小数
          sort: this.you_hui_rule.length + 1
        };
        this.you_hui_rule.push(obj);
      } else {
        this.$message({
          type: 'error',
          message: '最多添加6个优惠类型'
        });
      }
    },
    deltstages: function deltstages(deltIndex) {
      this.you_hui_rule.splice(deltIndex, 1);
    },
    close: function close() {
      var _this3 = this;

      this.you_hui_rule.map(function (item, index) {
        if (item.type_name == '' || item.way == 1 && item.discount === '' || item.way === 0 && item.derate === '') {
          _this3.you_hui_rule.splice(index, 1);
        }
      });
      this.$emit('close', false);
    }
  }
};