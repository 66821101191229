var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-title",
      style: {
        "--font": _vm.pFont,
        "--color": _vm.pColor,
        "--pos": _vm.pPosition,
      },
    },
    [_vm._v(_vm._s(_vm.innertext))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }