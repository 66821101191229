import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/utils/utils';
import api from '@/api/lotteryEntry';
import { mapGetters } from "vuex";
import { adminParameter } from "@/api/public";
export default {
  name: 'LotteryPreset',
  data: function data() {
    return {
      params: {
        page: 1,
        limit: 10,
        search: ''
      },
      loading: false,
      config: {
        count: 20,
        // 总页数
        list: [],
        // 列表
        edition: [],
        round_list: [] // 轮次列表

      },
      round_list: [],
      appHeight: 0,
      topHeight: 0,
      list: {}
    };
  },
  computed: {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 220;
    }
  },
  created: function created() {
    var _this = this;

    this.getData(true); //  项目配置

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list;
        _this.list = list;
      }
    });
  },
  mounted: function mounted() {
    var _this2 = this;

    this.appHeight = this.$refs.app.offsetHeight; // this.topHeight = this.$refs.apptop.offsetHeight

    window.onresize = function () {
      _this2.appHeight = _this2.$refs.app.offsetHeight; // this.topHeight = this.$refs.apptop.offsetHeight
    };
  },
  methods: {
    // 获取数据
    getData: function getData(isQuery) {
      var _this3 = this;

      if (isQuery) {
        this.params.limit = 10;
        this.params.page = 1;
      }

      this.loading = true;
      api.list(this.params).then(function (result) {
        _this3.config = result.data.data;
      }).finally(function () {
        _this3.loading = false;
      });
    },
    // 重置表单
    resetForm: function resetForm() {
      this.params = {
        page: 1,
        limit: 10,
        search: ''
      };
      this.getData(true);
    },
    // 选择框修改
    changeRound: function changeRound(e) {
      this.round_list = this.config.edition[e - 1].round;
    },
    // 导出房源表
    handleExport: function handleExport() {
      var _this4 = this;

      var params = this.params;
      var exportloading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // 导出接口

      api.export(params).then(function (res) {
        var name = "".concat(_this4.getURLName(res.data.path), ".").concat(_this4.suffix(res.data.path));
        utils.download_files_base64(res.data.data, name, res.data.suffix); // utils.download_files(this.host + res.data.path, name, {
        //   showMessage: true
        // })
      }).finally(function () {
        exportloading.close();
      });
    },
    getURLName: function getURLName(url) {
      return url.replace(/(.*\/)*([^.]+).*/gi, '$2');
    },
    suffix: function suffix(url) {
      return url.replace(/.+\./, '');
    },
    handleSizeChange: function handleSizeChange(s) {
      this.params.limit = s;
      this.getData(false);
    },
    handleCurrentChange: function handleCurrentChange(s) {
      this.params.page = s;
      this.getData(false);
    },
    doTimeToDate: function doTimeToDate(time) {
      return utils.formatDate(new Date(time), "yyyy-MM-dd hh:mm:ss");
    }
  }
};