import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { priceExport, sleepExport, setAdminAll, userAdmin, userDelList, CaptchaInfo, userExport, userList, userSessionList, userStatusList, userType, userRandAll as _userRandAll, autoAuth, syncFid, updateLevels, login, SetBuyCountAll, syncSignCode as _syncSignCode, syncQrCode, syncNoUserFid } from '@/api/clientSet';
import { adminParameter, projectConfigure } from '@/api/public';
import { mapGetters } from 'vuex';
import moment from 'moment';
import UploadExcel from '@/components/user-UploadExcel';
import { getpName, getProject } from '@/utils/auth';
import utils from '@/utils/utils';
import { set } from 'nprogress';
import store from "@/store";
export default {
  name: 'Index',
  components: {
    UploadExcel: UploadExcel
  },
  comments: _objectSpread({}, mapGetters(['roles'])),
  data: function data() {
    return {
      sp: {},
      list: {},
      //pc配置
      randList: [{
        name: '全部',
        id: 0
      }, {
        name: '显示',
        id: 2
      }, {
        name: '隐藏',
        id: 1
      }],
      assortArray: [],
      is_exportFile: '1',
      exportFile: false,
      // 下载模板方式
      is_downloadFile: '1',
      // 下载模板弹窗
      downloadFile: false,
      telLayout: 'total, prev, pager, next, sizes',
      isTelMore: false,
      isPC: utils.isPC(),
      pictLoading: false,
      adminDisabled: false,
      // 岗位 form
      postForm: {
        role: [],
        is_adminList: 0
      },
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      //  选中的数量
      selectedNum: 0,
      // 第一个页
      page: 1,
      // 分页 一页几条
      pageSize: utils.isPC() ? 10 : 1000,
      // 分页 总数
      total: 0,
      // 导入路径
      url: '',
      downloadName: '',
      // 项目配置
      project: {},
      // 活动场次批次
      event: {
        is_round: false,
        is_batch: false,
        is_event: false
      },
      // 活动批次
      activity: {},
      is_search: false,
      //  客户列表
      tableData: [],
      // 场次列表
      sessionList: [],
      // 页面搜索
      search: {
        inxId: 'all',
        round_id: 0,
        batch: 0,
        no_admin: 0,
        no_level: 0,
        no_round: 0,
        no_batch: 0,
        search: '',
        is_card: 0,
        is_code: 0,
        is_disable: 0,
        special: '',
        level_id: ''
      },
      delArray: '',
      // 分配职业顾问弹框
      is_distribute: false,
      // 职业顾问列表
      adminList: [],
      // 是否展开text
      moreText: '展开',
      // 是否展开 icon
      moreIcon: 'el-icon-arrow-down',
      // 是否展开
      is_more: false,
      // 是否显示测试摇号结果
      show_is_buy_rand: false,
      // 批量修改客户分类
      levelData: {
        show: false,
        data: {
          level: ''
        }
      },
      // 批量增加客户限购数量
      addQuota: {
        show: false,
        data: {}
      },
      addQuotarules: {
        types: [{
          required: true,
          message: '请选择客户范围',
          trigger: 'change'
        }],
        buy_status: [{
          required: true,
          message: '请选择认购状态',
          trigger: 'change'
        }],
        // level: [
        //   { required: true, message: '请选择客户分类', trigger: 'change' }
        // ],
        buy_count: [{
          required: true,
          message: '请输入增加限购数量',
          trigger: 'blur'
        }]
      },
      syncShow: false,
      syncuserType: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 142;
    }
  }),
  watch: {
    is_more: function is_more() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    if (this.roles.includes('admin')) {
      this.is_exportFile = '1';
    } else if (this.roles.includes('user/export')) {
      this.is_exportFile = '1';
    } else if (this.roles.includes('user/priceexport')) {
      this.is_exportFile = '2';
    } else if (this.roles.includes('user/sleepexport')) {
      this.is_exportFile = '3';
    } else {
      this.is_exportFile = '';
    }

    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.isPC ? this.$refs.top.offsetHeight : '';

    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.isPC ? _this.$refs.top.offsetHeight : '';
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    var query = this.$route.query;

    for (var key in query) {
      query[key] = parseInt(query[key]);
    }

    Object.assign(this.search, query);

    if (this.roles.includes('user/index') || this.roles.includes('admin')) {
      this.getTableList();
    }

    if (this.roles.includes('user/relationround') || this.roles.includes('admin')) {
      this.getSessionList();
    }

    this.projectSetData(); // 获取职业顾问

    this.getUserAdmin();
  },
  methods: {
    syncUserDialog: function syncUserDialog() {
      this.syncShow = true;
    },
    // 同步签到编码
    syncSignCode: function syncSignCode() {
      var _this2 = this;

      _syncSignCode().then(function (res) {
        _this2.$message({
          type: 'success',
          message: res.data.msg
        });

        _this2.getTableList();
      });
    },
    // 同步码牌编码
    syncPlateCode: function syncPlateCode() {
      var _this3 = this;

      syncQrCode().then(function (res) {
        _this3.$message({
          type: 'success',
          message: res.data.msg
        });
      });
      this.getTableList();
    },
    onaddQuota: function onaddQuota(type) {
      var _this4 = this;

      if (type == 'close') {
        this.addQuota = {
          show: false,
          data: {}
        };
      } else if (type == 'submit') {
        //提交表单
        this.$refs.addQuotarulesForm.validate(function (valid) {
          if (valid) {
            if (_this4.addQuota.data.types === 0) {
              _this4.addQuota.data.ids = _this4.delArray.split(',').map(function (item) {
                return item = parseInt(item);
              });
            }

            _this4.addQuota.data.level = _this4.addQuota.data.level ? _this4.addQuota.data.level : [];
            SetBuyCountAll(_this4.addQuota.data).then(function (res) {
              _this4.$message({
                type: 'success',
                message: res.data.msg
              });

              _this4.onaddQuota('close');
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 导入成功后
    uplsuc: function uplsuc(e) {
      // 替换导入 同步客户认证状态
      if ((e === null || e === void 0 ? void 0 : e.radio) === '1') {
        var _this$project$list = this.project.list,
            sign_up_type = _this$project$list.sign_up_type,
            user_owner_real = _this$project$list.user_owner_real,
            sign_up_type_status = _this$project$list.sign_up_type_status; // 线下签约 并且 实名认证 或 线上签约使用契约锁

        if (sign_up_type === 2 && user_owner_real === 1 || sign_up_type === 1 && sign_up_type_status == 0) {
          autoAuth();
        }
      } // 获取数据


      this.getTableList();
    },
    syncUserFID: function syncUserFID() {
      var _this5 = this;

      if (this.syncuserType === '') {
        this.$message.error('请先选择同步方式!');
        return;
      }

      if (this.syncuserType === 0) {
        syncFid().then(function (res) {
          var _res$data$info$return, _res$data, _res$data$info, _res$data$info$return2;

          _this5.$message.success((_res$data$info$return = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$info = _res$data.info) === null || _res$data$info === void 0 ? void 0 : (_res$data$info$return2 = _res$data$info.returnData) === null || _res$data$info$return2 === void 0 ? void 0 : _res$data$info$return2.msg) !== null && _res$data$info$return !== void 0 ? _res$data$info$return : '同步成功');

          _this5.syncShow = false;
        });
      } else {
        syncNoUserFid().then(function (res) {
          var _res$data$info$return3, _res$data2, _res$data2$info, _res$data2$info$retur;

          _this5.$message.success((_res$data$info$return3 = (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : (_res$data2$info = _res$data2.info) === null || _res$data2$info === void 0 ? void 0 : (_res$data2$info$retur = _res$data2$info.returnData) === null || _res$data2$info$retur === void 0 ? void 0 : _res$data2$info$retur.msg) !== null && _res$data$info$return3 !== void 0 ? _res$data$info$return3 : '同步成功');

          _this5.syncShow = false;
        });
      }
    },
    // 同步实名认证
    syncAutoAuth: function syncAutoAuth() {
      var _this6 = this;

      this.$confirm('是否确认将已有产权人认证同步?', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (_) {
        autoAuth().then(function (result) {
          _this6.$message.success(result.data.msg);
        });
      }).catch(function () {});
    },
    // 测试摇号结果
    handelRand: function handelRand(row) {
      this.userRandAll(row.id, row.is_rand);
    },
    // 封装测试摇号结果显示
    userRandAll: function userRandAll(postId, status) {
      var _this7 = this;

      _userRandAll({
        ids: postId,
        status: status
      }).then(function (res) {
        if (res.status === 200) {
          _this7.$message({
            type: 'success',
            message: res.data.msg
          });

          _this7.getTableList();
        }
      });
    },
    // 展开& 收起
    bindMore: function bindMore() {
      this.is_more = !this.is_more;
      this.moreText = this.is_more ? '收起' : '展开';
      this.moreIcon = this.is_more ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    // 打开更多按钮
    showTelMore: function showTelMore() {
      this.isTelMore = true;
    },
    handelAdminId: function handelAdminId(e) {
      if (e) {
        this.postForm.role = '';
        this.adminDisabled = true;
      } else {
        this.adminDisabled = false;
      }
    },
    // 提交 设置顾问
    handleDistribute: function handleDistribute() {
      var _this8 = this;

      if (this.postForm.role.length === 0 && this.postForm.is_adminList === 0) {
        this.$message({
          type: 'warning',
          message: '还未选择顾问'
        });
        return;
      }

      var data = {
        ids: this.userIdArray,
        admin_id: this.postForm.is_adminList === 0 ? this.postForm.role : 0
      };
      setAdminAll(data).then(function (res) {
        if (res.status === 200) {
          _this8.$message({
            type: 'success',
            message: res.data.msg
          });

          _this8.getTableList();

          _this8.is_distribute = false;
        }
      });
    },
    getUserAdmin: function getUserAdmin() {
      var _this9 = this;

      userAdmin().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this9.adminList = list;
        }
      });
    },
    // 批量操作
    handleCommand: function handleCommand(type) {
      var _this10 = this;

      switch (type) {
        case 'delAll':
          this.handleDelAll();
          break;

        case 'enable':
          this.handleStatusList(1);
          break;

        case 'disable':
          this.handleStatusList(0);
          break;

        case 'distribute':
          // 打开 分配职业顾问弹框
          if (this.delArray === '') {
            this.$message({
              type: 'warning',
              message: '选中数据再进行操作'
            });
            return;
          }

          this.is_distribute = true;
          break;

        case 'hideRond':
          if (this.delArray === '') {
            this.$message({
              type: 'warning',
              message: '选中数据再进行操作'
            });
            return;
          }

          this.$confirm('所选客户会隐藏测试活动的摇号情况，是否确认？', '提醒', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this10.userRandAll(_this10.delArray, 0);
          }).catch(function (err) {});
          break;

        case 'showRond':
          if (this.delArray === '') {
            this.$message({
              type: 'warning',
              message: '选中数据再进行操作'
            });
            return;
          }

          this.$confirm('所选客户会显示测试活动的摇号情况，是否确认？', '提醒', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this10.userRandAll(_this10.delArray, 1);
          }).catch(function (err) {});
          break;

        case 'updateLevels':
          if (this.delArray === '') {
            this.$message({
              type: 'warning',
              message: '选中数据再进行操作'
            });
            return;
          }

          this.levelData = {
            data: {
              level: ''
            },
            show: true
          };
          break;

        case 'login':
          this.loginUser();
          break;

        case 'batchaddQuota':
          this.addQuota = {
            show: true,
            data: {
              level: '',
              types: this.delArray === '' ? 1 : 0,
              buy_status: 0
            }
          };
          break;
      }
    },
    // 登录用户
    loginUser: function loginUser() {
      var _this11 = this;

      var ids = this.delArray;

      if (!ids) {
        this.$message.error('请选择后操作');
        return;
      }

      login({
        ids: ids
      }).then(function (res) {
        _this11.getTableList();

        _this11.$message.success('操作成功');
      }).catch(function (_) {
        _this11.$message.error('操作失败');
      });
    },
    handelAudit: function handelAudit() {
      this.$router.push({
        name: 'UserAudit'
      });
    },
    // 批量禁用启用
    handleStatusList: function handleStatusList(e) {
      var _this12 = this;

      if (this.delArray === '') {
        this.$message({
          type: 'warning',
          message: '选中数据再进行操作'
        });
        return;
      }

      if (e === 0) {
        this.$alert('禁用将清空客户的登录数据，请谨慎操作！', '是否确定禁用客户？', {
          callback: function callback(action) {
            if (action === 'confirm') {
              _this12.checkHandleStatus(e);
            }
          }
        });
      } else {
        this.checkHandleStatus(e);
      }
    },
    checkHandleStatus: function checkHandleStatus(e) {
      var _this13 = this;

      userStatusList({
        ids: this.delArray,
        status: e
      }).then(function (res) {
        if (res.status === 200) {
          _this13.$message({
            type: 'success',
            message: res.data.msg
          });

          _this13.getTableList();
        }
      }).catch(function (err) {
        var _err$response = err.response,
            data = _err$response.data,
            status = _err$response.status;

        if (status === 400 && data.is_audit) {
          _this13.$confirm(data.msg + '禁止修改~', '提示', {
            confirmButtonText: '去处理',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(function () {
            _this13.$router.push({
              name: 'UserAudit'
            });
          }).catch(function (err) {});
        }
      });
    },
    // 搜索事件
    getTableSearchList: function getTableSearchList() {
      this.page = 1;
      this.getTableList();
    },
    // 关联场次
    handelMerge: function handelMerge() {
      this.$router.push({
        name: 'MergeUser'
      });
    },
    // 客户详情
    handelDetails: function handelDetails(row) {
      this.$router.push({
        name: 'UserDetails',
        query: {
          id: row.id,
          round: this.event.is_round
        }
      });
    },
    // 修改客户
    handelEditUser: function handelEditUser(row) {
      this.$router.push({
        name: 'EditUser',
        query: {
          id: row.id,
          round: this.event.is_round
        }
      });
    },
    // 新增客户
    handelAddUser: function handelAddUser() {
      this.$router.push({
        name: 'AddUser',
        query: {
          round: this.event.is_round
        }
      });
    },
    // 账号状态 修改
    handelSwitch: function handelSwitch(e, index) {
      var _this14 = this;

      if (e.status === 0) {
        this.$alert('禁用将清空客户的登录数据，请谨慎操作！', '是否确定禁用客户？', {
          callback: function callback(action) {
            if (action === 'confirm') {
              _this14.handelSwitchStatus(e);
            } else {
              _this14.tableData[index].status = 1;
            }
          }
        });
      } else {
        this.handelSwitchStatus(e);
      }
    },
    handelSwitchStatus: function handelSwitchStatus(e) {
      var _this15 = this;

      userType({
        id: e.id,
        status: e.status
      }).then(function (res) {
        if (res.status == 200) {
          _this15.$message({
            type: 'success',
            message: res.data.msg
          });
        }
      }).catch(function (err) {
        var _err$response2 = err.response,
            data = _err$response2.data,
            status = _err$response2.status;

        if (status === 400 && data.is_audit) {
          _this15.$confirm(data.msg + '禁止修改~', '提示', {
            confirmButtonText: '去处理',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(function () {
            _this15.$router.push({
              name: 'UserAudit'
            });
          }).catch(function (err) {
            _this15.getTableList();
          });
        }
      });
    },
    // 导出模板
    handelModel: function handelModel() {
      var url = null;
      var name = null;

      if (this.is_downloadFile === '1') {
        name = '客户导入';
        url = this.host + '/template/user/2.云销控-客户信息导入模板.xlsx';
      } else if (this.is_downloadFile === '2') {
        name = '客户诚意金导入';
        url = this.host + '/template/user/云销控-客户诚意金导入模板.xlsx';
      } else if (this.is_downloadFile === '3') {
        name = '特殊客户导入';
        url = this.host + '/template/user/云销控-特殊客户信息导入模板.xlsx';
      }

      utils.download_files(url, "".concat(getpName(), "-").concat(name, "-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
        showMessage: true
      });
      this.downloadFile = false;
    },
    // 根据搜索条件导出数据
    handelExport: function handelExport() {
      var _this16 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); //  活动id 场次id 获取

      var event_id = '';
      var round_id = '';
      var inxId = this.search.inxId; // 选择全部

      if (inxId === 'all') {
        event_id = 0;
        round_id = 0; //	活动场次
      } else {
        var project = this.activity.project;

        if (project[inxId].round === 1) {
          round_id = project[inxId].round_id;
        } else {
          event_id = project[inxId].id;
        }
      } // this.search.special.map((item, i) => {


      switch (this.search.special) {
        case 'no_admin':
          this.search.no_admin = 1;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_level':
          this.search.no_admin = 0;
          this.search.no_level = 1;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_round':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 1;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_batch':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 1;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'is_card':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 1;
          this.search.is_code = 0;
          break;

        case 'is_code':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 1;
          break;
      } // })
      // 提交数据


      var data = {
        event_id: event_id,
        round_id: round_id,
        batch_id: this.search.batch,
        no_admin: this.search.no_admin,
        no_level: this.search.no_level,
        no_event: this.search.no_event,
        no_round: this.search.no_round,
        no_batch: this.search.no_batch,
        is_card: this.search.is_card,
        admin_id: this.search.admin_id,
        is_disable: this.search.is_disable,
        search: this.search.search,
        is_code: this.search.is_code,
        rand_group: this.search.rand_group,
        level: this.search.level_id,
        is_rand: this.search.is_rand
      };

      if (this.is_exportFile === '1') {
        // 导出接口
        userExport(data).then(function (res) {
          if (res.status === 200) {
            _this16.$message({
              type: 'success',
              message: '导出成功'
            }); // 下载
            // window.location.href = this.host + res.data.msg


            utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
            _this16.exportFile = false;
            loading.close();
          }
        }).catch(function (err) {
          loading.close();
        });
      } else if (this.is_exportFile === '2') {
        priceExport(data).then(function (res) {
          if (res.status === 200) {
            _this16.$message({
              type: 'success',
              message: '导出成功'
            }); // 下载
            // window.location.href = this.host + res.data.msg


            utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
            _this16.exportFile = false;
            loading.close();
          }
        }).catch(function (err) {
          loading.close();
        });
      } else if (this.is_exportFile === '3') {
        sleepExport(data).then(function (res) {
          if (res.status === 200) {
            _this16.$message({
              type: 'success',
              message: '导出成功'
            }); // 下载
            // window.location.href = this.host + res.data.path


            utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
            _this16.exportFile = false;
            loading.close();
          }
        }).catch(function (err) {
          loading.close();
        });
      }
    },
    // 重置 form 表单
    resetForm: function resetForm() {
      this.search = {
        inxId: 'all',
        round_id: 0,
        batch: 0,
        no_admin: 0,
        no_level: 0,
        no_round: 0,
        no_batch: 0,
        is_card: 0,
        search: '',
        is_disable: 0,
        is_code: 0,
        admin_id: 0,
        special: '',
        level_id: ''
      };
      this.getTableList();
    },
    // 批量删除
    handleDelAll: function handleDelAll() {
      this.handelDelList(this.delArray);
    },
    // 删除 用户列表
    handelDelList: function handelDelList(ids) {
      var _this17 = this;

      if (ids === '') {
        this.$message({
          type: 'warning',
          message: '选中数据再进行操作'
        });
        return;
      }

      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        userDelList({
          ids: ids
        }).then(function (res) {
          if (res.status === 200) {
            _this17.$message({
              type: 'success',
              message: res.data.msg
            });

            _this17.getTableList();
          }
        }).catch(function (err) {
          var _err$response3 = err.response,
              data = _err$response3.data,
              status = _err$response3.status;

          if (status === 400 && data.is_audit) {
            _this17.$confirm(data.msg + '删除修改~', '提示', {
              confirmButtonText: '去处理',
              cancelButtonText: '关闭',
              type: 'warning'
            }).then(function () {
              _this17.$router.push({
                name: 'UserAudit'
              });
            }).catch(function (err) {});
          }
        });
      }).catch(function () {});
    },
    // 登录口令
    handelCaptchaInfo: function handelCaptchaInfo(id) {
      var _this18 = this;

      this.$confirm('是否确认为客户生成登录口令？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        CaptchaInfo({
          id: id
        }).then(function (res) {
          _this18.$confirm(res.data.code, '口令已生成', {
            confirmButtonText: '复制',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(function () {
            // 创建输入框元素
            var oInput = document.createElement('input'); // 将想要复制的值

            oInput.value = res.data.code; // 页面底部追加输入框

            document.body.appendChild(oInput); // 选中输入框

            oInput.select(); // 执行浏览器复制命令

            document.execCommand('Copy'); // 弹出复制成功信息

            _this18.$message.success('复制成功'); // 复制后移除输入框


            oInput.remove();
          });
        });
      });
    },
    //  获取场次列表
    getSessionList: function getSessionList() {
      var _this19 = this;

      userSessionList().then(function (res) {
        if (res.status === 200) {
          var event = res.data.list.event;
          _this19.sessionList = event;
        }
      });
    },
    // 获取table 数据
    getTableList: function getTableList() {
      var _this20 = this;

      //  活动id 场次id 获取
      var event_id = '';
      var round_id = '';
      var inxId = this.search.inxId;
      this.pictLoading = true; // 选择全部

      if (inxId === 'all') {
        event_id = 0;
        round_id = 0; //	活动场次
      } else {
        var project = this.activity.project;

        if (project[inxId].round === 1) {
          round_id = project[inxId].round_id;
        } else {
          event_id = project[inxId].id;
        }
      } // this.search.special.map((item, i) => {


      switch (this.search.special) {
        case 'no_admin':
          this.search.no_admin = 1;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_level':
          this.search.no_admin = 0;
          this.search.no_level = 1;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_round':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 1;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'no_batch':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 1;
          this.search.is_card = 0;
          this.search.is_code = 0;
          break;

        case 'is_card':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 1;
          this.search.is_code = 0;
          break;

        case 'is_code':
          this.search.no_admin = 0;
          this.search.no_level = 0;
          this.search.no_round = 0;
          this.search.no_batch = 0;
          this.search.is_card = 0;
          this.search.is_code = 1;
          break;
      } // })
      // 提交数据


      var data = {
        page: this.page,
        limit: this.pageSize,
        event_id: event_id,
        round_id: round_id,
        batch_id: this.search.batch,
        no_admin: this.search.no_admin,
        no_level: this.search.no_level,
        no_event: this.search.no_event,
        no_round: this.search.no_round,
        no_batch: this.search.no_batch,
        is_card: this.search.is_card,
        is_disable: this.search.is_disable,
        admin_id: this.search.admin_id,
        search: this.search.search,
        is_code: this.search.is_code,
        level: this.search.level_id,
        rand_group: this.search.rand_group,
        is_rand: this.search.is_rand
      };
      userList(data).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
              list = _res$data3.list,
              count = _res$data3.count; // 表格数据

          _this20.tableData = list.user || []; // 总数

          _this20.total = count; // 判断 当前页数 大于1， 当前页数，数据为0

          if (list.user === null && _this20.page > 1) {
            _this20.page = _this20.page - 1;

            _this20.getTableList();
          }

          _this20.pictLoading = false;
        }
      }).catch(function (err) {
        _this20.pictLoading = false;
      });
    },
    // table 选中的数据
    handleSelectionChange: function handleSelectionChange(row) {
      this.selectedNum = row.length;
      var array = [];
      row.forEach(function (item) {
        array.push(item.id);
      });
      this.delArray = array.join(',');
      this.userIdArray = array;
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    },
    // 获取 项目配置 批次 活动
    projectSetData: function projectSetData() {
      var _this21 = this;

      adminParameter().then(function (res) {
        console.log(res);
        var data = res.data,
            _res$data4 = res.data,
            event = _res$data4.event,
            list = _res$data4.list,
            sp = _res$data4.sp;
        _this21.sp = sp;
        _this21.list = list;
        _this21.project = data; // 活动场次批次控制

        _this21.event = event; // 是否显示 测试摇号结果

        _this21.show_is_buy_rand = list.is_display.includes('buy_rand');
      });
      projectConfigure().then(function (res) {
        var list = res.data.list;
        _this21.activity = list;
      });
    },

    /**
     * 批量修改客户分类
     * @param key
     */
    onLevelData: function onLevelData(key) {
      var _this22 = this;

      if (key === 'submit') {
        if (!this.levelData.data.level) {
          this.$message({
            type: 'warning',
            message: '请输入分类！'
          });
          return;
        }

        updateLevels({
          ids: this.userIdArray,
          level: this.levelData.data.level
        }).then(function (res) {
          _this22.$message({
            type: 'success',
            message: res.data.msg
          });

          _this22.onLevelData('close');
        });
      } else if (key === 'close') {
        this.levelData = {
          data: {
            level: ''
          },
          show: false
        };
      }
    }
  }
};