import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Oss from '@/utils/oss';
import moment from 'moment';
export default {
  name: 'QrList',
  props: {
    // 显示的列表状态（1编辑状态  2下载状态  3预览状态
    state: {
      type: Number,
      default: 0
    },
    // 码牌渲染宽高/px
    itemSize: {
      type: Number,
      default: 210
    },
    // 码牌渲染列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // 码牌列表
      qrTypeList: []
    };
  },
  watch: {
    list: function list(val) {
      this.qrTypeList = val;
    }
  },
  created: function created() {
    this.qrTypeList = this.list;
  },
  methods: {
    // 选中要下载的码牌
    chooseItem: function chooseItem(index) {
      if (this.state != 2) return;
      this.qrTypeList[index].choose = !this.qrTypeList[index].choose;
    },
    // 处理文件上传
    handleFileUploadOss: function handleFileUploadOss(file) {
      Oss.onUploadFile(file, "public/pcimg/$id/".concat(new Date().getTime(), "-").concat(file.file.name), file.file.name);
    }
  }
};