/**
 * @author: lm,
 * @date: 2021-12-30 10:23:20,
 */
import request from '@/utils/request'; // 搜索登记用户

export function getTimingList(data) {
  return request({
    url: '/signUser/TimingList',
    method: 'post',
    data: data
  });
}
export function TimingExport(data) {
  return request({
    url: '/signUser/TimingExport',
    method: 'post',
    data: data
  });
}
export function TimingCancel(data) {
  return request({
    url: '/signUser/TimingCancel',
    method: 'post',
    data: data
  });
}