var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "list-item-header" }, [
      _c("div", { staticClass: "list-item-header-left" }, [
        _c(
          "div",
          {
            class: [
              "header-left-status",
              { "header-left-status-active": _vm.row.event_type == 1 },
            ],
          },
          [_vm._v(_vm._s(_vm.getButtonText(_vm.row.event_type)))]
        ),
        _c("div", { staticClass: "header-left-name" }, [
          _vm._v(_vm._s(_vm.row.event_name)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list-item-header-right" },
        [
          [
            _vm.row.log_rand_cound <= 0
              ? _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: "编辑规则",
                      effect: "dark",
                      placement: "top-end",
                    },
                  },
                  [
                    _c("svg-icon", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "eventrand/setrule"],
                          expression: "['admin', 'eventrand/setrule']",
                        },
                      ],
                      attrs: { "class-name": "greens", "icon-class": "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("handleEdit", _vm.row.id)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  content: _vm.getText(_vm.row),
                  effect: "dark",
                  placement: "top-end",
                },
              },
              [
                _c("svg-icon", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "eventrand/randindex"],
                      expression: "['admin', 'eventrand/randindex']",
                    },
                  ],
                  attrs: { "class-name": "greens", "icon-class": "yaohao" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleStartLottery", _vm.row.id)
                    },
                  },
                }),
              ],
              1
            ),
            false
              ? _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      content: "排号分屏",
                      effect: "dark",
                      placement: "top-end",
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "class-name": "greens",
                        "icon-class": "spreadScreen",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("lotterySplitScreen", _vm.row)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  content: "重置",
                  effect: "dark",
                  placement: "top-end",
                },
              },
              [
                _c("svg-icon", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "eventrand/reset"],
                      expression: "['admin', 'eventrand/reset']",
                    },
                  ],
                  attrs: { "class-name": "greens", "icon-class": "reset" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleReset", _vm.row.id)
                    },
                  },
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "list-item-box" }, [
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left content-text" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v("排号名称: " + _vm._s(_vm.row.name)),
          ]),
        ]),
        _c("div", { staticClass: "content-right content-text" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(
              "轮次人数: " +
                _vm._s(_vm.row.type === 1 ? "随机摇号" : "按序排号") +
                "-" +
                _vm._s(_vm.row.rand_count) +
                "人每轮"
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 已排号: "),
            _c("span", [_vm._v(_vm._s(_vm.row.log_group_number) + "轮")]),
            _c("span", [_vm._v("- " + _vm._s(_vm.row.log_rand_cound) + "人")]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.row.log_rand_cound > 0,
                  expression: "row.log_rand_cound > 0",
                },
              ],
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("handleRecord", _vm.row.id)
                },
              },
            },
            [_vm._v("排号记录")]
          ),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 待排号: "),
            _c("span", [_vm._v(_vm._s(_vm.row.not_group_number) + "轮")]),
            _c("span", [_vm._v("- " + _vm._s(_vm.row.not_rand_count) + "人")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "item-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("span", { staticClass: "content-text" }, [
            _vm._v(" 客户总数: "),
            _vm.row.type_rand_user === 1
              ? _c("span", [
                  _vm._v("全部客户 -（" + _vm._s(_vm.row.user_count) + "人）"),
                ])
              : _c("span", [
                  _vm._v("签到客户 -（" + _vm._s(_vm.row.user_count) + "人）"),
                ]),
          ]),
        ]),
        false
          ? _c("div", { staticClass: "content-right" }, [
              _c("span", { staticClass: "content-text" }, [
                _vm._v(" 分组: "),
                _vm.row.type_group === 1
                  ? _c("span", [_vm._v(_vm._s(_vm.row.level_count) + "组")])
                  : _c("span", [_vm._v("无组")]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.row.type_group === 1,
                      expression: "row.type_group === 1",
                    },
                  ],
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleGrouping", _vm.row.id)
                    },
                  },
                },
                [_vm._v("查看分组")]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }