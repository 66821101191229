import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import newTabele from './newTabele';
import { debounce } from '@/utils/debounce';
export default {
  name: 'MergeTable',
  components: {
    newTabele: newTabele
  },
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dialogData: {
      type: [Object, Array],
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: 'room'
    },
    ids: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      cellIndex: null,
      // 两层合并的数据
      isCell: false,
      isRoom: false,
      build: {},
      activeName: [],
      checked: false,
      // 弹框搜索
      form: {
        search: '',
        no: '',
        build_id: '',
        unit_id: '',
        cate_id: ''
      },
      // 单元数组
      unitArray: [],
      // 未售房源
      is_event: 0,
      // table 单条数据
      rowData: {},
      isOpen: false
    };
  },
  computed: _objectSpread({}, mapGetters(['customSetting'])),
  methods: {
    // 打开详情弹窗
    handleInfoMerge: function handleInfoMerge(row) {
      this.event_id = row.event_id;
      this.round_id = row.round_id; //  组装数据

      var data = {
        event_id: row.event_id,
        round_id: row.round_id
      };
      this.rowData = row;
      this.$emit('handleMerge', data);
      this.isOpen = true;
    },
    handleSearchData: function handleSearchData(data) {
      var datas = _objectSpread({
        event_id: this.event_id,
        round_id: this.round_id
      }, data);

      this.$emit('handleMerge', datas);
    },
    // 提交选中
    handleSaveUser: debounce(function () {
      //  没有选中的数据不能提交
      if (this.dialogData.round_room_list == null || this.dialogData.round_room_list.length === 0) {
        // this.$message({
        //     type: 'warning',
        //     message: '还没选择关联房源'
        // });
        this.handleReset(this.rowData);
        return;
      } // 存储 id


      var ids = [];
      this.dialogData.round_room_list.forEach(function (item) {
        ids.push(item.id);
      });
      var data = {
        room_ids: ids,
        event_id: this.event_id,
        round_id: this.round_id
      };
      this.$emit('handleSaveId', data);
      this.isRoom = false;
    }, 200, true),
    //  重置关联
    handleReset: function handleReset(row) {
      //  组装数据
      var data = {
        event_id: row.event_id,
        round_id: row.round_id
      };
      this.$emit('handleReset', data);
      this.isRoom = false;
    },
    // 打开弹框
    handleMerge: function handleMerge(row) {
      this.isRoom = true;
      this.event_id = row.event_id;
      this.round_id = row.round_id; //  组装数据

      var data = {
        event_id: row.event_id,
        round_id: row.round_id
      };
      this.rowData = row;
      this.$emit('handleMerge', data);
    },
    // 获取单元列表
    getUnit: function getUnit(value) {
      if (value === 0) {
        this.unitArray = [];
        this.form.unit_id = 0;
        return;
      }

      if (this.dialogData.build !== null) {
        var index = this.dialogData.build.findIndex(function (d) {
          return d.id === value;
        });
        this.unitArray = this.dialogData.build[index].unit;
      }
    },
    // 搜索 清空事件
    handleSearch: function handleSearch(type) {
      if (type === 1) {
        // this.form.no  = '';
        var data = {
          round_id: this.round_id,
          build_id: 0,
          unit_id: 0,
          cate_id: 0,
          no: '',
          event_id: 0
        };
        this.is_event = 0;
        this.$refs['ruleForm'].resetFields();
        this.$emit('handleSearch', data);
      } else {
        var _data = {
          round_id: this.round_id,
          build_id: this.form.build_id,
          unit_id: this.form.unit_id,
          cate_id: this.form.cate_id,
          no: this.form.no,
          event_id: this.is_event ? this.event_id : 0
        };
        this.$emit('handleSearch', _data);
      }
    },
    // 提交 内容
    handleSaveRoom: function handleSaveRoom() {
      var _this = this;

      // 存储 id
      var ids = []; // 获取选中id

      this.dialogData.group_room.forEach(function (item) {
        item.rooms.forEach(function (info) {
          if (info.is_check) {
            ids.push(info.id);
          }
        });
      });
      var newArr = ids.concat(this.ids);
      var postArr = [];

      for (var i = 0; i < newArr.length; i++) {
        if (postArr.indexOf(newArr[i]) === -1) {
          postArr.push(newArr[i]);
        }
      } //  没有选中


      if (postArr.length <= 0) {
        this.$message({
          type: 'warning',
          message: '必须选择一个关联房源'
        });
        return;
      }

      var data = {
        ids: postArr.join(','),
        event_id: this.event_id,
        round_id: this.round_id
      };
      this.$confirm('确认保存吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _this.$emit('handleSaveId', data);

        _this.isRoom = false;
      }).catch(function () {});
    },
    // 父列表选中事件  全选 为全选
    handleItemCheck: function handleItemCheck(index) {
      this.$emit('handleItemCheck', index);
    },
    // 子列表 选中事件
    handleInfoCheck: function handleInfoCheck(index, inx) {
      var _this2 = this;

      if (!this.dialogData.group_room[index].rooms[inx].is_check) {
        this.ids.map(function (item, i) {
          if (item === _this2.dialogData.group_room[index].rooms[inx].id) {
            _this2.ids.splice(i, 1);
          }
        });
      } else {
        this.ids.push(this.dialogData.group_room[index].rooms[inx].id);
      }

      this.$emit('handleInfoCheck', index);
    },
    // 表格合并方法(两层数据)
    arraySpanMethod: function arraySpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0 || columnIndex === 1) {
        if (row.typeIndex) {
          // 如果有值,说明需要合并
          return [row.typeIndex, 1];
        } else return [0, 0];
      }
    },
    // 单元格移入事件
    handleMouse: function handleMouse(row, column, cell, event) {
      if (!row.typeIndex) {
        // 不符合合并行的条件手动加class
        // 只能给第一行加才有效, 把之前保存的第一行的索引存起来
        this.cellIndex = row.firstIndex;
        this.isCell = true;
      }
    },
    // 单元格移除事件
    handleMouseLeve: function handleMouseLeve() {
      this.isCell = false;
    },
    // 设置行样式
    setCellClass: function setCellClass(_ref2) {
      var row = _ref2.row,
          column = _ref2.column,
          rowIndex = _ref2.rowIndex,
          columnIndex = _ref2.columnIndex;

      // 给当前鼠标移入的合并行第一行加上样式名
      if (row.index === this.cellIndex) {
        return 'cellClass';
      }
    }
  }
};